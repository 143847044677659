import { fetchAnalysisSetting, toggleCustomCostPreference, toggleDetailedReportPreference } from "../services/analysisSettings";

const initialState = {
  detailedReport: false,
  customCost: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type){
    case "TOGGLE_DETAILED_ANALYSIS_REPORT":
      state.detailedReport = action.payload;
      return {
        ...state
      };
    case "TOGGLE_CUSTOM_COST":
      state.customCost = action.payload;
      return {
        ...state
      };
    case "FETCH_ANALYSIS_SETTING":
      return action.payload
    default:
      return state;
  }
};

export const fetchAnalysisReportSettings = () => {
  return async (dispatch) => {
    try {
      const response = await fetchAnalysisSetting();
      let preferences = {
        detailedReport: false,
        customCost: false,
      };
      response.data.forEach((val) => {
        if (val.name === 'detailed-analysis-report-preference'){
          preferences.detailedReport = val.value;
        } else if (val.name === 'custom-cost-preference'){
          preferences.customCost = val.value;
        }
      })
      dispatch({
        type: 'FETCH_ANALYSIS_SETTING',
        payload: preferences
      });
    } catch (error) {
      console.error('Failed to fetch Analysis Report Settings', error);
    }
  }
}

export const toggleProfitAnalysisReportSetting = (type, value) => {
  return async (dispatch) => {
    try {
      const response = await toggleDetailedReportPreference(type, value);
      dispatch({
        type: "TOGGLE_DETAILED_ANALYSIS_REPORT",
        payload: value
      });
    } catch (error) {
      console.log('Failed to toggle Profit Analysis Report Setting', error);
    }
  };
};

export const toggleCustomCostSetting = (type, value) => {
  return async (dispatch) => {
    try {
      const response = await toggleCustomCostPreference(type, value);
      dispatch({
        type: "TOGGLE_CUSTOM_COST",
        payload: value
      });
    } catch (error) {
      console.log('Failed to toggle Custom Cost Setting', error);
    }
  }
}

export default reducer;