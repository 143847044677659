import { Outlet } from 'react-router-dom';

const UtilitiesHome = () => {
	return (
		<div className="main-content">
			<Outlet />
		</div>
	);
};

export default UtilitiesHome;
