import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../../../components/PageTitle";

import Select from "react-select";
import {
  adminStyles,
  tpStyles,
} from "../../../../public/stylesheets/selectStyles";
import serialise, {
  generateSelectData,
} from "../../../../../utils/serialiseResponse";
import {
  clearStates,
  setStates,
} from "../../../../../reducers/locations/stateReducer";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../../reducers/locations/hqReducer";
import { useNavigate } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";
import {
  AddDWPL,
  deleteDWPL,
  updateDWPL,
  viewDWPL,
  viewTargets,
} from "../../../../../services/targets";
import Table from "../../../../../components/Table";
import { RiCloseCircleLine, RiEdit2Fill } from "react-icons/ri";
import { viewProducts, viewProductV1 } from "../../../../../services/products";
import customToast from "../../../../../components/CustomToast";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";

const typeOptions = [
  { value: 1, label: "Doctor" },
  { value: 2, label: "Chemist" },
  { value: 3, label: "Stockist" },
];

const CreationDWPL = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [docs, setDocs] = useState([]);

  const [doctor, setDoctor] = useState();
  const [prod, setProd] = useState([]);
  const [products, setProducts] = useState();
  const [dwplList, setDwplList] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [editProducts, setEditProducts] = useState([]);
  const [editTwpl, setEditTwpl] = useState(null);
  const [isEdited, setIsEdited] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  useEffect(() => {
    viewTargets("doctor", { dcr: true, showApproved:true }).then((response) => {
      setDocs(response?.data);
    });
  }, []);
  useEffect(() => {
    viewProductV1("").then((response) => {
      setProd(response?.data);
    });
  }, []);
  useEffect(() => {
    viewDWPL().then((response) => {
      setDwplList(response.data);
    });
  }, [isEdited, isCreated, isDeleted]);

  const EditPopup = ({ data }) => {
    const handleEdit = async (e) => {
      try {
        e.preventDefault();
        if (editProducts.length === 0)
          return customToast.error("Field cannot be empty");
        let productIds = [];

        editProducts.forEach((el) => {
          productIds.push(el._id);
        });
        let data = await updateDWPL({ productIds, twplId: editTwpl });
        customToast.success("Products updated successfully");
        setIsEdited(!isEdited);
        setShowEdit(false);
      } catch (err) {
        customToast.error("Sorry, Unable to update details");
      }
    };
    const handleDelete = async () => {
      try {
        await deleteDWPL({dwplId:editTwpl});
        setShowEdit(false);
        customToast.success("Deleted successfully");
        setIsDeleted(!isDeleted);
      } catch (err) {
        customToast.error("Sorry, Unable to delete");
      }
    };
    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Edit Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form onSubmit={handleEdit}>
            <div className="edit-form">
              <div>
                <label htmlFor="state">
                  Edit Products <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  isMulti
                  name="products"
                  id="DWPLproducts"
                  value={editProducts}
                  options={productsSelect}
                  onChange={(e) => setEditProducts([...e])}
                  styles={{ ...adminStyles }}
                  placeholder="Select Products"
                  className="multi-select-dropdown w-100"
                />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-submit-green mt-4">
                <span className="button-submit-green__icon-container">
                  <MdOutlineEdit className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Edit</span>
              </button>

              <button
                type="button"
                className="button-delete mt-4"
                onClick={handleDelete}
              >
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "doctor.name",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Products",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 140,
        maxWidth: 140,
        Cell: (props) =>
         props?.row?.original?.products.map((e) => (
          <span key={e._id}>
            {e.name} <br />
          </span>
        ))
      },
      {
        Header: "Actions",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 40,
        maxWidth: 40,
        Cell: (props) => (
          <span
            onClick={() => {
              setEditProducts(
                props?.row?.original?.products.map((e) => ({
                  ...e,
                  label: e.name,
                  value: e._id,
                }))
              );
              setEditTwpl(props?.row?.original._id);
              setShowEdit(true);
            }}
            className="react-table-view-link"
          >
            <RiEdit2Fill className="icon-color-green" />
          </span>
        ),
      },
    ],
    []
  );
  const doctorSelect = useMemo(() => generateSelectData(docs, "name"), [docs]);
  const productsSelect = useMemo(
    () => generateSelectData(prod, "name"),
    [prod]
  );

  
  
  const SubmitHandler = async (e) => {

   var index = dwplList.findIndex((p) => p.doctor === doctor._id);

   if(index === -1){
    try {
      e.preventDefault();
      if (!doctor || products.length === 0)
        return customToast.error("Please fill all the fields");
      let data = await AddDWPL({ doctor: doctor._id, products });
      setIsCreated(!isCreated);

      if (data) {
        setDoctor(null);
        setProducts([]);
      }
      return customToast.success("List successfully added");
    } catch (err) {
      return customToast.error("An unexpected error occured");
    }
   }
   else{
    customToast.error("Doctor Already Exists");
   }
    
  };

  //   const tableData = useMemo(() => serialise(dcsData), [dcsData]);

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Doctor Wise Products List" />

        <section className="area-creation-content__info">
          <form onSubmit={SubmitHandler}>
            <div className="row">
              <div className="col-sm-12 col-md-3 col-lg-3">
                <label htmlFor="doctor">Select Doctor</label>
                <br />
                <Select
                  name="doctor"
                  id="doctor"
                  value={doctor}
                  options={doctorSelect}
                  onChange={(e) => setDoctor({ ...e })}
                  styles={adminStyles}
                  placeholder="Select Doctor"
                />
              </div>

              <div className="col-sm-12 col-md-3 col-lg-3">
                <label htmlFor="DWPLproducts">Select Products</label>
                <br />
                <Select
                  isMulti
                  name="products"
                  id="DWPLproducts"
                  value={products}
                  options={productsSelect}
                  onChange={(e) => setProducts([...e])}
                  styles={{ ...adminStyles }}
                  placeholder="Select Products"
                  className="multi-select-dropdown"
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 mt-4 admin-creation-content__form-submit mt-5">
              <button type="submit" className="button-blue-gradient">
                ADD
              </button>
            </div>
          </form>

          <div className="filter-table">
            {dwplList.length > 0 && (
              <h2 className="web-app__heading mb-4 ms-2">
                Showing ({dwplList.length}) Entries
              </h2>
            )}
            <Table columns={columns} data={dwplList} />
          </div>
        </section>
        {showEdit ? <EditPopup /> : null}
      </div>
    </main>
  );
};

export default CreationDWPL;
