import { useState,useEffect, useRef, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { adminStyles } from "../../../../public/stylesheets/selectStyles";
import customToast from "../../../../../components/CustomToast";
import PageTitle from "../../../../../components/PageTitle";
import {
  generateSelectData
} from "../../../../../utils/serialiseResponse";
import { setUsers } from "../../../../../reducers/users/employeeReducer";
import * as XLSX from "xlsx";
import { uploadUserTargets} from "../../../../../services/targets" ;
import Table from "../../../../../components/Table";
import { viewProductV1 } from "../../../../../services/products";
import { clearHeadquarters, setHeadquarters } from "../../../../../reducers/locations/hqReducer";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const targetTypeOptions = [
  { value: 1, label: "Qty * Amount" },
  { value: 2, label: "Lump-Sum" },
];

const dropdownOptions =
  [{ label: "MRP", value: "mrp" }, { label: "PTS", value: "pts" }, { label: "PTR", value: "ptr" }, { label: "CUSTOM", value: "custom" }]

const headerSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "none",
    color: "#9e9ad0",
    fontSize: "1.5rem",
    fontWeight: "500",
    width: "100%",
    cursor: "pointer",
    outline: "none",
    border: "none"
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",
    fontFamily: "inherit",
    fontSize: "1.5rem",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",

  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",

  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
    outline: "none",
    border: "none",

  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",
    "&:hover": {
      color: "#9E9AD1",
    },
    backgroundColor: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "5px",
    height: "5px",
    padding: "0",
    "&:first-child": {
      width: "100% !important",
      height: "100% !important"
    }
  }),
};

const AdminUploadTarget = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const headquarterWise = useSelector((state) => state?.settings["hq-target"]);
  const hqList = useSelector(({headquarter}) => headquarter?.data);
  
  const [uploadData, setUploadData] = useState([]);
  const users = useSelector(({employee}) => employee);
  const [user, setUser] = useState(null);
  const [targetType, setTargetType] = useState();
  const [priceHeader, setPriceHeader] = useState({ label: "PTR", value: "ptr" });
  const [prodArray, setProdArray] = useState([]);
  const inputRef = useRef(null); 
  const userSelect = useMemo(
    () => generateSelectData(users,"fullName"),
    [users]
  );
  useEffect(()=>{
    dispatch(setUsers("user"));
    viewProductV1("").then((response) => {
      setProdArray(response?.data);
    });
  },[dispatch]);
  const handlePriceHeaderChange = (e)=>{
    setPriceHeader(e);
  };

  useEffect(() => {
    dispatch(setHeadquarters());

    return () => {
      dispatch(clearHeadquarters());
    };
  },[dispatch]);

  
  const resetForm = () => {
    setUploadData([]);
  };
  const finalAmtCalc = useCallback((data, qty)=>{
    if(!data || !qty)return 0 ;
    if(priceHeader?.label === "PTR")
      return Number((Number(data?.ptr)*Number(qty)).toFixed(2)) ;
    else if(priceHeader?.label === "PTS")
      return Number((Number(data?.pts)*Number(qty)).toFixed(2)) ;
    else if(priceHeader?.label === "MRP")
      return Number((Number(data?.mrp)*Number(qty)).toFixed(2)) ;
      
    return Number((Number(data?.cus)*Number(qty)).toFixed(2)) ;
  },[priceHeader]);
  const handleSubmit =  (e) => {
    e.preventDefault();
    
    if (uploadData.length === 0)
      return customToast.error("Please fill all the fields");
    if ((headquarterWise === 'true' && !uploadData[0]?.hasOwnProperty("HQ UID"))
    ||(headquarterWise === 'false'&& targetType?.value === 2 && !uploadData[0]?.hasOwnProperty("User UID"))
    ) {
      return customToast.error("Invalid excel format");
    }
    
    if(headquarterWise === 'true'){
      uploadUserTargets("hq-target", uploadData).then((data)=>{
        exportEXCEL(data?.data);
        customToast.success(`Please open the excel file just downloaded`);
      }).catch((err)=>{
        customToast.error("Sorry Something Went Wrong!");
      })
    }else if(targetType?.value === 2){
      uploadUserTargets("lumpsum",{targetType, table:uploadData}).then((data)=>{
        exportEXCEL(data?.data);
        customToast.success(`Please open the excel file just downloaded`);
      }).catch((err)=>{
        customToast.error("Sorry Something Went Wrong!");
      })
    }else if(targetType?.value === 1){
     
      if(!user)return customToast.error("Please Select a User") ;
      for(let i = 0 ; i < uploadData.length ; i++){
        const obj = uploadData[i] ;
        obj.Jan = finalAmtCalc(obj , obj.January);
        obj.Feb = finalAmtCalc(obj , obj.February);
        obj.Mar = finalAmtCalc(obj, obj.March);
        obj.Apr = finalAmtCalc(obj, obj.April);
        obj.Ma = finalAmtCalc(obj, obj.May);
        obj.Jun = finalAmtCalc(obj, obj.June);
        obj.Jul = finalAmtCalc(obj, obj.July);
        obj.Aug = finalAmtCalc(obj, obj.August);
        obj.Sep = finalAmtCalc(obj, obj.September);
        obj.Oct = finalAmtCalc(obj, obj.October);
        obj.Nov = finalAmtCalc(obj, obj.November);
        obj.Dec = finalAmtCalc(obj, obj.December);
      }
      uploadUserTargets(user.uid ,{targetType, table:uploadData}).then((data)=>{
        // exportEXCEL(data?.data);
        customToast.success(`target upload successfull`);
      }).catch((err)=>{
        customToast.error("Sorry Something Went Wrong!");
      })
    }
    inputRef.current.value = null; // resetting the excel input field
    resetForm();
  };
  const exportEXCEL = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Unsuccessful.xlsx");
  }
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      if (file) fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const workbook = XLSX.read(bufferArray, { type: "buffer" });
        const wsName = workbook.SheetNames[0];
        const ws = workbook.Sheets[wsName];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (err) => {
        reject(err);
      };
    });

    promise.then((d) => {
      if (d.length === 0) {
        inputRef.current.value = null;
        return customToast.error("Please upload a filled excel file");
      }

      setUploadData(d);
      
    });
    promise.catch((err) => {
      return customToast.error("Encountered an error");
    });
  };

  const exportUsers = (e) => {
    e.preventDefault();
    const emplList = users?.map((item, idx) => {
      const tempObj = {};
      tempObj["Employee Name"] = `${item?.firstName} ${item?.lastName}`;
      tempObj["User UID"] = item?.uid;
      tempObj.April = '';
      tempObj.May = '';
      tempObj.June = '';
      tempObj.July = '';
      tempObj.August = '';
      tempObj.September = '';
      tempObj.October = '';
      tempObj.November = '';
      tempObj.December = '';
      tempObj.January = '' ;
      tempObj.February = '' ;
      tempObj.March = '' ;
      return tempObj;
    });
    const worksheet = XLSX.utils.json_to_sheet(emplList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "UserTargetUploadFormat.xlsx");
  }

  const exportHQ = (e) => {
    e.preventDefault();
    let hqReports = hqList.map((item, idx) => {
      let tempObj = {};
      tempObj["Headquarter Name"] = item?.name;
      tempObj["HQ UID"] = item?.uid;
      tempObj.April = '';
      tempObj.May = '';
      tempObj.June = '';
      tempObj.July = '';
      tempObj.August = '';
      tempObj.September = '';
      tempObj.October = '';
      tempObj.November = '';
      tempObj.December = '';
      tempObj.January = '' ;
      tempObj.February = '' ;
      tempObj.March = '' ;
      return tempObj
    });

    const worksheet = XLSX.utils.json_to_sheet(hqReports);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    XLSX.writeFile(workbook, "HqTargetUploadFormat.xlsx");
  }
  
  const exportProducts = (e) => {
    e.preventDefault();
    const processedReports = prodArray.map((report,idx) => {
      const processedReport = {};
      processedReport.productName = report.name ;
      processedReport.ptr = report.ptr ;
      processedReport.pts = report.pts ;
      processedReport.mrp = report.mrp ;
      processedReport.cus = 0 ;
      processedReport.uid = report.uid ; 
      processedReport.April = '';
      processedReport.May = '';
      processedReport.June = '';
      processedReport.July = '';
      processedReport.August = '';
      processedReport.September = '';
      processedReport.October = '';
      processedReport.November = '';
      processedReport.December = '';
      processedReport.January = '' ;
      processedReport.February = '' ;
      processedReport.March = '' ;
      return processedReport;
    });
    
    const worksheet = XLSX.utils.json_to_sheet(processedReports);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "report.xlsx");
  }
  const amountCalc = useCallback((props, qty)=>{
    if(!props || !qty)return 0 ;
    if(priceHeader?.label === "PTR")
      return Number((Number(props?.row?.original?.ptr)*Number(qty)).toFixed(2)) ;
    else if(priceHeader?.label === "PTS")
      return Number((Number(props?.row?.original?.pts)*Number(qty)).toFixed(2)) ;
    else if(priceHeader?.label === "MRP")
      return Number((Number(props?.row?.original?.mrp)*Number(qty)).toFixed(2)) ;
      
    return Number((Number(props?.row?.original?.cus)*Number(qty)).toFixed(2)) ;
  },[priceHeader]);
  const columns = useMemo(
    () => [
      {
        Header:"Product Name",
        accessor: "productName",
        minWidth:120,
        maxWidth:120,
        disableFilters:true,
        disableSortBy:true
      },
      {
        Header: (
          <Select 
          styles={headerSelectStyles}
          options={dropdownOptions}
          placeholder={priceHeader?.label}
          onChange={handlePriceHeaderChange}
          components={{IndicatorSeparator : () => null}}/>
        ),
        accessor:"mrp",
        minWidth:120,
        maxWidth:120,
        disableFilters:true,
        disableSortBy:true,
        Cell:(props) =>{
         if(priceHeader?.label === "PTR")
         {
          return <div>
            {props?.row?.original?.ptr}
          </div>
         }
         else if(priceHeader?.label === "PTS")
         {
          return <div>
            {props?.row?.original?.pts}
          </div>
         }
         else if(priceHeader?.label === "MRP")
         {
          return <div>
            {props?.row?.original?.mrp}
          </div>
         }
         else {
          return <div>
            {props?.row?.original?.cus}
          </div>
         }
      },
    },
    {
      Header:"April",
      accessor:"apr",
      minWidth:120,
      maxWidth:120,
      disableFilters:true,
      disableSortBy:true,
      Cell: (props)=>{
        return <div >
          <span>{props?.row?.original?.April}</span><br/>
          <span className="tp__activity-types-icon-9 upload-amount">₹{amountCalc(props,props?.row?.original?.April)}</span>
        </div>
      }
    },
    {
      Header:"May",
      accessor:"may",
      minWidth:120,
      maxWidth:120,
      disableFilters:true,
      disableSortBy:true,
      Cell: (props)=>{
        return <div>
          <span>{props?.row?.original?.May}</span><br/>
          <span className="tp__activity-types-icon-9 upload-amount">₹{amountCalc(props,props?.row?.original?.May)}</span>
        </div>
      }
    },
    {
      Header:"June",
      accessor:"jun",
      minWidth:120,
      maxWidth:120,
      disableFilters:true,
      disableSortBy:true,
      Cell: (props)=>{
        return <div>
          <span>{props?.row?.original?.June}</span><br/>
          <span className="tp__activity-types-icon-9 upload-amount">₹{amountCalc(props,props?.row?.original?.June)}</span>

        </div>
      }
    },
    {
      Header:"July",
      accessor:"juyl",
      minWidth:120,
      maxWidth:120,
      disableFilters:true,
      disableSortBy:true,
      Cell: (props)=>{
        return <div>
          <span>{props?.row?.original?.July}</span><br/>
          <span className="tp__activity-types-icon-9 upload-amount">₹{amountCalc(props,props?.row?.original?.July)}</span>

        </div>
      }
    },
    {
      Header:"August",
      accessor:"aug",
      minWidth:120,
      maxWidth:120,
      disableFilters:true,
      disableSortBy:true,
      Cell: (props)=>{
        return <div>
          <span>{props?.row?.original?.August}</span><br/>
          <span className="tp__activity-types-icon-9 upload-amount">₹{amountCalc(props,props?.row?.original?.August)}</span>

        </div>
      }
    },
    {
      Header:"September",
      accessor:"sept",
      minWidth:120,
      maxWidth:120,
      disableFilters:true,
      disableSortBy:true,
      Cell: (props)=>{
        return <div>
          <span>{props?.row?.original?.September}</span><br/>
          <span className="tp__activity-types-icon-9 upload-amount">₹{amountCalc(props,props?.row?.original?.September)}</span>

        </div>
      }
    },
    {
      Header:"October",
      accessor:"oct",
      minWidth:120,
      maxWidth:120,
      disableFilters:true,
      disableSortBy:true,
      Cell: (props)=>{
        return <div>
          <span>{props?.row?.original?.October}</span><br/>
          <span className="tp__activity-types-icon-9 upload-amount">₹{amountCalc(props,props?.row?.original?.October)}</span>

        </div>
      }
    },
    {
      Header:"November",
      accessor:"nov",
      minWidth:120,
      maxWidth:120,
      disableFilters:true,
      disableSortBy:true,
      Cell: (props)=>{
        return <div>
          <span>{props?.row?.original?.November}</span><br/>
          <span className="tp__activity-types-icon-9 upload-amount">₹{amountCalc(props,props?.row?.original?.November)}</span>

        </div>
      }
    },
    {
      Header:"December",
      accessor:"dec",
      minWidth:120,
      maxWidth:120,
      disableFilters:true,
      disableSortBy:true,
      Cell: (props)=>{
        return <div>
          <span>{props?.row?.original?.December}</span><br/>
          <span className="tp__activity-types-icon-9 upload-amount">₹{amountCalc(props,props?.row?.original?.December)}</span>

        </div>
      }
    },
    {
      Header:"January",
      accessor:"jan",
      minWidth:120,
      maxWidth:120,
      disableFilters:true,
      disableSortBy:true,
      Cell: (props)=>{
        return <div>
          <span>{props?.row?.original?.January}</span><br/>
          <span className="tp__activity-types-icon-9 upload-amount">₹{amountCalc(props,props?.row?.original?.January)}</span>

        </div>
      }
    },
    {
      Header:"February",
      accessor:"feb",
      minWidth:120,
      maxWidth:120,
      disableFilters:true,
      disableSortBy:true,
      Cell: (props)=>{
        return <div>
          <span>{props?.row?.original?.February}</span><br/>
          <span className="tp__activity-types-icon-9 upload-amount">₹{amountCalc(props,props?.row?.original?.February)}</span>

        </div>
      }
    },
    {
      Header:"March",
      accessor:"mar",
      minWidth:120,
      maxWidth:120,
      disableFilters:true,
      disableSortBy:true,
      Cell: (props)=>{
        return <div>
          <span>{props?.row?.original?.March}</span><br/>
          <span className="tp__activity-types-icon-9 upload-amount">₹{amountCalc(props,props?.row?.original?.March)}</span>

        </div>
      }
    },
    ],[priceHeader, amountCalc]
  );
  return (
    <div className="main-content admin-content w-75">
      <div className="area-creation-content">
        
        <section className="admin-creation-content__heading">
          <MdArrowBackIos
          className="cursor-pointer"
          onClick={() => navigate(-1)}/>
          <h2 className="web-app__heading">Upload Target</h2>
          <a href="#" 
          className="ms-auto"
          onClick={
            targetType?.value !== 1 ?
            (headquarterWise === 'true' ? exportHQ : exportUsers)
          :
           targetType?.value === 1 ? 
            exportProducts
            :
            null
        }>
            Download Format
            </a>
        </section>

        <section className="area-creation-content__form">
        <div class="admin-panel__alert mt-0 mb-5 d-flex">
          <h2 style={{marginBottom:"0px"}}>
            Note:   </h2>
              <p>
                 If for a particular user the target already exist for that month, then by uploading the targets for same user for the same month, the targets will get overridden.     
              </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="row">
              {headquarterWise === 'false'  && <div className="col-sm-12 col-md-4 col-lg-4">
                
                <label htmlFor="state">Select Target Type</label>
                <br />
                <Select
                  options={targetTypeOptions}
                  value={targetType}
                  onChange={(e) => setTargetType({ ...e })}
                  styles={adminStyles}
                />
              
              </div>}
              {headquarterWise === 'false'&& targetType?.value === 1 && <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="type">
                  Employee  <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="type"
                  id="type"
                  value={user}
                  options={userSelect}
                  onChange={(e) => setUser({ ...e })}
                  styles={adminStyles}
                  placeholder="Select User"
                />
              </div>}
              

              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="employee">
                  Upload Excel <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="file"
                  placeholder="Upload File"
                  ref={inputRef}
                  onChange={(e) => {
                    setUploadData([]);
                    const file = e.target.files[0];
                    readExcel(file);
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap mt-5">
              <div className="area-creation-content__form-submit">
                {targetType?.value !== 1 &&
                  <button type="submit" className="button-blue-gradient">
                    Upload List
                  </button> 
                }
                
              </div>
              {/* <div className="area-creation-content__form-submit">
                {targetType?.value !== 1 && 
                  <button
                    type="button"
                    onClick={headquarterWise === 'true' ? exportHQ : exportUsers}
                    className="button-blue-gradient"
                  >
                    Download Format
                  </button>
                }
                {targetType?.value === 1 && 
                  <button
                    type="button"
                    onClick={exportProducts}
                    className="button-blue-gradient"
                  >
                    Download Format
                  </button>
                }
              </div> */}
            </div>
          </form>
        </section>
      </div>
      
        {targetType?.value === 1 && <>
        <div className="filter-table pe-4"  >
          <Table columns={columns} data={uploadData}/>
        </div>
        <button type="button" onClick={handleSubmit} className="button-blue-gradient">
          Upload List
        </button> </>
        }
      
      
    </div>
  );
};

export default AdminUploadTarget;
