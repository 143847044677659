import { getNotifications } from "../../services/notifications";

const initialState = {
  _id:null,
  userId:null,
  alerts:[]
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "VIEW_NOTIFICATION":
      return {...action.payload};
    case "ADD_NOTIFICATION":
      return {...state, alerts:[action.payload, ...state.alerts]};
    case "CREATE_REMINDER":
      return action.payload;
    case "CLEAR_REMINDER":
      return initialState;
    case "DELETE_REMINDER":
      return initialState;

    default:
      return state;
  }
};

export const viewNotifications = () => {
  return async (dispatch) => {
    const { data } = await getNotifications();
    
    dispatch({
      type: "VIEW_NOTIFICATION",
      payload: data || null,
    });
  };
};

export const addNotification = (data) => {
  return async (dispatch) => {
    
    dispatch({
      type: "ADD_NOTIFICATION",
      payload: data,
    });
    
  };
};

// export const deleteReminder = (data) => {
//   return async (dispatch) => {
//     try {
//       data = await deleteReminderById(data);
//     } catch (e) {
//       if (data.error) throw data.error;
//     }
//     dispatch({
//       type: "DELETE_REMINDER",
//       payload: { data: data },
//     });
//     dispatch(viewReminder());
//   };
// };

// export const clearReminder = () => {
//   return {
//     type: "CLEAR_REMINDER",
//   };
// };

export default reducer;
