import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURL = `${apiURL}/api/ssg/`;

export const newGiftManagement = async (details) => {
  const { data } = axios.post(baseURL + "gift-management", details, {
    headers: setAuth(),
  });

  return data;
};

export const newSampleManagement = async (details) => {
  const { data } = axios.post(baseURL + "sample-management", details, {
    headers: setAuth(),
  });

  return data;
};

export const newStockManagement = async (details) => {
  const { data } = axios.post(baseURL + "stock-management", details, {
    headers: setAuth(),
  });

  return data;
};

export const getGiftManagement = async () => {
  const { data } = await axios.get(baseURL + "gift-management", {
    headers: setAuth(),
  });

  return data;
};

export const getSampleManagement = async () => {
  const { data } = await axios.get(baseURL + "sample-management", {
    headers: setAuth(),
  });

  return data;
};

export const getStockManagement = async () => {
  const { data } = await axios.get(baseURL + "stock-management", {
    headers: setAuth(),
  });

  return data;
};
