import { useEffect, useState, useMemo } from "react";
import PageTitle from "../../../../components/PageTitle";
import Select from "react-select";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import serialise, { generateSelectData } from "../../../../utils/serialiseResponse";
import { LinkSC, ViewLinkedSC, deleteDWPL, deleteLinkedSC, updateLinkedSC, viewTargets } from "../../../../services/targets";
import Table from "../../../../components/Table";
import { RiCloseCircleLine, RiEdit2Fill } from "react-icons/ri";
import customToast from "../../../../components/CustomToast";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";

const StokistChemist = () => {
  const [chemists, setChemists] = useState([]);
  const [stockists, setStockists] = useState([]);
  const [selectedChemists, setSelectedChemists] = useState([]);
  const [selectedStockist, setSelectedStockist] = useState();
  const [prod, setProd] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [editChemists, setEditChemists] = useState([]);
  const [editStocChem, setEditStocChem] = useState(null);
  const [isEdited, setIsEdited] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [stocChemList, setStocChemList] = useState([]);

  useEffect(() => {
    viewTargets("chemist", { showApproved: true }).then((response) => {
      setChemists(response?.data);
    }).catch(e => console.log(e));

    viewTargets("stockist", { showApproved: true }).then((response) => {
      setStockists(response?.data);
    }).catch(e => console.log(e));

  }, []);

  useEffect(() => {
    ViewLinkedSC().then((response) => {
      setStocChemList(response.data);
    });
  }, [isEdited, isCreated, isDeleted]);

  const EditPopup = ({ data }) => {
    const handleEdit = async (e) => {
      try {
        e.preventDefault();
        if (editChemists.length === 0)
          return customToast.error("Field cannot be empty");
        let chemistIds = [];

        editChemists.forEach((el) => {
          chemistIds.push(el._id);
        });
        let data = await updateLinkedSC({ chemistIds, stocChemId: editStocChem });
        customToast.success("Chemists updated successfully");
        setIsEdited(!isEdited);
        setShowEdit(false);
      } catch (err) {
        customToast.error("Sorry, Unable to update details");
      }
    };
    const handleDelete = async () => {
      try {
        await deleteLinkedSC({ stocChemId: editStocChem });
        setShowEdit(false);
        customToast.success("Deleted successfully");
        setIsDeleted(!isDeleted);
      } catch (err) {
        customToast.error("Sorry, Unable to delete");
      }
    };
    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Edit Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form onSubmit={handleEdit}>
            <div className="edit-form">
              <div>
                <label htmlFor="state">
                  Edit Chemists <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  isMulti
                  name="chemists"
                  id="chemists"
                  value={editChemists}
                  options={chemistSelect}
                  onChange={(e) => setEditChemists([...e])}
                  styles={{ ...adminStyles }}
                  placeholder="Select Products"
                  className="multi-select-dropdown w-100"
                />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-submit-green mt-4">
                <span className="button-submit-green__icon-container">
                  <MdOutlineEdit className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Edit</span>
              </button>

              <button
                type="button"
                className="button-delete mt-4"
                onClick={handleDelete}
              >
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Stockist",
        accessor: "name",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Chemists",
        accessor: "chemistArr",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 140,
        maxWidth: 140,
        Cell: (props) => {
          // console.log(props?.row?.original);
          return props?.row?.original?.chemistArr?.map((e) => (
            <span key={e._id}>
              {e.name} <br />
            </span>
          ));
        },
      },
      // {
      //   Header: "Percentage",
      //   accessor: "percentage",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Actions",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 40,
        maxWidth: 40,
        Cell: (props) => (
          <span
            onClick={() => {
              setEditChemists(
                props?.row?.original?.chemistArr?.map((e) => ({
                  ...e,
                  label: e.name,
                  value: e._id,
                }))
              );
              setEditStocChem(props?.row?.original._id);
              setShowEdit(true);
            }}
            className="react-table-view-link"
          >
            <RiEdit2Fill className="icon-color-green" />
          </span>
        ),
      },
    ],
    []
  );

  // const doctorSelect = useMemo(() => generateSelectData(docs, "name"), [docs]);
  const chemistSelect = useMemo(() => generateSelectData(chemists, "businessName"), [chemists]);
  const stockistSelect = useMemo(() => generateSelectData(stockists, "businessName"), [stockists]);
  const productsSelect = useMemo(() => generateSelectData(prod, "name"), [prod]);

  const SubmitHandler = async (e) => {
    try {
      e.preventDefault();
      if (!selectedStockist || selectedChemists.length === 0)
        return customToast.error("Please fill all the fields");
      let data = await LinkSC({ stockist: selectedStockist._id, chemists: selectedChemists});
      // console.log(data)
      if (data) {
        setIsCreated(!isCreated);
        setSelectedStockist(null);
        setSelectedChemists([]);
        return customToast.success("List successfully added");
      }
    } catch (err) {
      return err.response.status === 403
      ? customToast.error("Stockist already exists!")
      : customToast.error("An unexpected error occured");
    }
  };

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Stockist Chemists List" isCRM={true} />

        <section className="area-creation-content__info">
          <form onSubmit={SubmitHandler}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-3">
                <label htmlFor="stockist">Select Stockist</label>
                <br />
                <Select
                  name="stockist"
                  id="stockist"
                  value={selectedStockist}
                  options={stockistSelect}
                  onChange={(e) => setSelectedStockist({ ...e })}
                  styles={adminStyles}
                  placeholder="Select stockist"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3">
                <label htmlFor="chemists">Select Chemist</label>
                <br />
                <Select
                  isMulti
                  name="chemists"
                  id="chemists"
                  value={selectedChemists}
                  options={chemistSelect}
                  onChange={(e) => setSelectedChemists([...e])}
                  styles={adminStyles}
                  placeholder="Select Chemists"
                  className=""
                />
              </div>
            <div className="col-sm-12 col-md-4 col-lg-3 admin-creation-content__form-submit mt-5">
              <button type="submit" className="button-blue-gradient">
                ADD
              </button>
            </div>
            </div>
          </form>

          <div className="filter-table">
            {stocChemList?.length > 0 && (
              <h2 className="web-app__heading mb-4 ms-2">
                Showing ({stocChemList?.length}) Entries
              </h2>
            )}
            <Table columns={columns} data={stocChemList} />
          </div>
        </section>
        {showEdit ? <EditPopup /> : null}
      </div>
    </main>
  );
};

export default StokistChemist;
