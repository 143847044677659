import { useSelector,useDispatch } from "react-redux";
import { useEffect, useMemo } from "react";
import Table from "../../../components/Table";
import serialise from "../../../utils/serialiseResponse";
import { viewInventoryAlerts } from "../../../reducers/products/MinimumStockReducer";

const MinimumStock = () => {
  const alerts = useSelector(({inventoryAlerts})=>inventoryAlerts.data);
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(viewInventoryAlerts());
  },[dispatch]);
  
  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr no.",
      //   accessor: "sr",
      //   disableFilters:true,
      //   disableSortBy:true,
      //   minWidth:40,
      //   maxWidth:40
      // },
      {
        Header: "Product Name",
        accessor: "productName",
        disableFilters:true,
        disableSortBy:true,
      },
      {
        Header: "Minimum Stock / Expiry Date",
        accessor: "minStock",
        disableFilters:true,
        disableSortBy:true,
        Cell: (props) => {
          return props?.row?.original?.expiryDate ? (
            <>
              <span className="asterisk">{props?.row?.original?.expiryDate }</span>
              {/* <span className="asterisk">inventory: {(props?.row?.original?.date).toString().substring(0,10)}</span> */}
            </>
          ):(
            <span>{props?.row?.original?.minStock}</span>
          )
        },
      },
      {
        Header: "Current Stock / Batch Number",
        accessor: "currentStock",
        disableFilters:true,
        disableSortBy:true,
        Cell: (props) => {
          return <span className={props?.row?.original?.currentStock < 0 && "asterisk"}>{props?.row?.original?.currentStock || props?.row?.original?.batchNo}</span>;
        },
      },
      {
        Header: "Category",
        accessor: "category",
        disableFilters:true,
        disableSortBy:true,
      },
    ],
    []
  );
  
  const tableData = useMemo(() =>{
    return serialise(alerts);
  } , [alerts]);

  
  return (
    <div className="main-content">
      <div className="expense">
        <h2 className="web-app__heading mb-5">Minimum Stock Alerts</h2>
        <div className="filter-table">
          <Table columns={columns} data={tableData}></Table>
        </div>
      </div>
    </div>
  );
};

export default MinimumStock;
