import { MdArrowBackIosNew } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCallback, useState, useMemo, useEffect } from "react";
import {
  serialiseChemStk,
} from "../../../../../../utils/serialiseResponse";
import Table from "../../../../../../components/Table";
import { FaEye } from "react-icons/fa";
import customToast from "../../../../../../components/CustomToast";
import { viewSTKWisePrimarySales } from "../../../../../../services/utilities";
import { useSelector } from "react-redux";
import { formatDate } from "../../../../../../utils/helper";

const StockistPS = ({hideHeader,render}) => {
  
  const [reports, setReports] = useState([]);
  const [toggle ,  setToggle] = useState(false);
  const [loading , setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.data ;//this is the id of headquarter
  let dateRange = useSelector(({primarySalesReports})=>primarySalesReports.dateRange);
  if(location?.state?.dateRange){
    dateRange = location?.state?.dateRange;
  }
  const fetchStk = useCallback(
    async()=>{
      setLoading(true);
      try {
        const filter = {
          id,
          type:toggle?"date":"sales",
          startCdt: new Date(dateRange?.startDate).getTime(),
          endCdt: new Date(dateRange?.endDate).getTime(),
          startDate: new Date(dateRange?.startDate).toISOString(),
          endDate: new Date(dateRange?.endDate).toISOString()
        };
        const {data} = await viewSTKWisePrimarySales(filter);
        setReports(data);
      } catch (error) {
        customToast.error("error occured Please Try Again");
      }
      setLoading(false)
    },[id,dateRange,toggle]
  )
  
  useEffect(()=>{
    if(!loading){
      fetchStk();
    }
  },[fetchStk,toggle,render]);
  
  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   disableFilters:true,
      //   disableSortBy:true,
      //   minWidth:50,
      //   maxWidth:50
      // },
      {
        Header:"Date",
        accessor:"date",
        minWidth:100,
        maxWidth:100,
        disableFilters:true,
        Cell:(props)=>(
          <span>{formatDate(props?.row?.original?.date)}</span>
        )
      },
      {
        Header: "Stockist",
        accessor: "stockist",
        disableSortBy: true,
        showTotal:true,
        placeholderTotal:"Total",
      },
      {
        Header: "Headquarter",
        accessor: "headquarter",
        disableSortBy: true,
      },
      {
        Header: "Total Primary Sales",
        accessor: "salesValue",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"salesValue",
        Cell:(props) => {
          return <div>
            {`₹ ${Number(props?.row?.original?.salesValue).toFixed(2)}`}
          </div>
        }
      },
      {
        Header: "View",
        accessor: "details",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
            
          return (
            <Link
              to={`./${props?.row?.original?._id}`}
              state={{ data: props?.row?.original?._id, 
                dateRange:{startDate:props?.row?.original?.date,endDate:props?.row?.original?.date},
                stockist:props?.row?.original?.stockist
              }}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    []
  );
  const columns2 = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   disableFilters:true,
      //   disableSortBy:true,
      //   minWidth:50,
      //   maxWidth:50
      // },
      {
        Header: "Stockist",
        accessor: "stockist",
        disableSortBy: true,
        showTotal:true,
        placeholderTotal:"Total",
      },
      {
        Header: "Headquarter",
        accessor: "headquarter",
        disableSortBy: true,
      },
      {
        Header: "Total Primary Sales",
        accessor: "salesValue",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"salesValue",
        Cell:(props) => {
          return <div>
            {`₹ ${Number(props?.row?.original?.salesValue).toFixed(2)}`}
          </div>
        }
      },
      {
        Header: "View",
        accessor: "details",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          
          return (
            <Link
              to={`./${props?.row?.original?._id}`}
              state={{ data: props?.row?.original?._id, dateRange:dateRange , stockist:props?.row?.original?.stockist}}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    [dateRange]
  );
  const tableData = useMemo(() => serialiseChemStk(reports), [reports]);
  
  
  return (
    <div className={!hideHeader?"expense":""}>
      {!hideHeader && (<h2
        className="web-app__heading"
        style={{ display: "flex", alignItems: "center" }}
      >
        <MdArrowBackIosNew
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        <span style={{ display: "inline-block", margin: "0 1rem" }}>
          Stockist Wise Sales Report
        </span>
      </h2>)}
      <div className="filter-table">
        { loading && <h3 className="mt-2 fetching-results-message">Hold on fetching reports for you...</h3>}
        {tableData.length > 0 && (
          <>
          <h2 className="web-app__heading mb-4 ps-3 d-flex justify-content-between">
              Showing ({reports.length}) Entries
              {
                !(location?.state?.type === "date") &&
              <div className="form__control pe-3" style={{ width: "fit-content" }}>
                <label className="toggle-label me-3">
                  <input
                    type="checkbox"
                    defaultChecked={toggle}
                    onClick={(e)=>setToggle(!toggle)}
                  />
                  <span/>
                </label>
                <span>View Date Wise</span>
              </div>
              }
          </h2>
          <Table columns={toggle ? columns : columns2} data={tableData} fileSrc="Primary Sales Report (Stockist)" /> 
          </>
          ) 
        }
        {
          !loading && tableData.length === 0 && (<h3 className="mt-2 no-results-message">No results found</h3>)
        }
      </div> 
    </div>
  );
};

export default StockistPS ;
