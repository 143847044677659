import {
  viewTargets,
  newTarget,
  editTarget,
  deleteTarget,
  viewTargetsForApproval,
  viewAllTargets,
  newTargetForApproval,
  approveTarget,
  rejectTarget,
  uploadTarget,
  viewTargetsV1,
  newTargetV1,
} from "../../services/targets.js";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_STOCKISTS":
      return action.payload;

    case "SET_STOCKIST_FOR_APPROVAL":
      return action.payload;

    case "SET_STOCKIST_ALL":
      return action.payload;

    case "CREATE_STOCKIST":
      return { data: [...state.data, action.payload.data.data] };

    case "UPLOAD_STOCKIST":
      return { data: [...state.data, action.payload.data.data] };

    case "CREATE_STOCKIST_FOR_APPROVAL":
      return { data: [...state.data, action.payload.data.data] };

    case "CLEAR_STOCKISTS":
      return initialState;

    case "EDIT_STOCKISTS":
      return { ...state, newState: action.payload };

    case "DELETE_STOCKISTS":
      return action.payload;

    default:
      return state;
  }
};

export const setStockists = (filters) => {
  return async (dispatch) => {
    const { data } = await viewTargets("stockist", filters);
    dispatch({
      type: "SET_STOCKISTS",
      payload: { data: data },
    });
  };
};

export const setStockistForApproval = (filters) => {
  return async (dispatch) => {
    const { data } = await viewTargetsForApproval("stockist", filters);

    dispatch({
      type: "SET_STOCKIST_FOR_APPROVAL",
      payload: { data: data },
    });
  };
};

export const setAllStockists = (filters) => {
  return async (dispatch) => {
    const { data } = await viewTargets("stockist", filters);
    // const { data } = await viewTargetsV1("stockist", filters);
      
    dispatch({
      type: "SET_STOCKIST_ALL",
      payload: { data: data },
    });
  };
};

export const addStockist = (data) => {
  return async (dispatch) => {
    try {
      // data = await newTarget("stockist", data);
      data = await newTargetV1("stockist", data);
      if (data.data.error) throw data.data.error;
    } catch (e) {
      if (data.data.error) throw data.data.error;
      if (data.error) throw data.error;
      
    }

    dispatch({
      type: "CREATE_STOCKIST",
      payload: { data: data },
    });
  };
};

export const uploadStockist = (data, userId) => {
  return async (dispatch) => {
    try {
      data = await uploadTarget("stockist", data, userId);     
    } catch (e) {
      throw e.response
    }

    dispatch({
      type: "UPLOAD_STOCKIST",
      payload: { data: data },
    });
    return data ;
  };
};

export const addStockistForApproval = (data) => {
  return async (dispatch) => {
    try {
      data.isApproval = true;
      data = await newTargetV1("stockist", data);

    } catch (e) {
      if (e) throw e;
    }

    dispatch({
      type: "CREATE_STOCKIST_FOR_APPROVAL",
      payload: { data: data },
    });
  };
};

export const clearStockists = () => {
  return {
    type: "CLEAR_STOCKISTS",
  };
};

export const editStockist = (id, updatedTarget) => {
  return async (dispatch) => {
    try {
      updatedTarget = await editTarget("stockist", id, updatedTarget);
    } catch (e) {
      if (updatedTarget.error) throw updatedTarget.error;
    }

    dispatch({
      type: "EDIT_STOCKISTS",
      payload: { data: updatedTarget },
    });
    dispatch(setStockists());
  };
};

export const deleteStockist = (id,data) => {
  return async (dispatch) => {
    try {
      data = await deleteTarget("stockist",id, data);
    } catch (e) {
      if (data.error) throw data.error;
    }
    dispatch({
      type: "DELETE_STOCKISTS",
      payload: { data: data },
    });
    dispatch(setStockists());
  };
};

export const approveStockist = (id, data) => {
  return async (dispatch) => {
    try {
      data = await approveTarget("stockist", id, data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "APPROVE_STOCKIST",
      payload: { data: data },
    });
    dispatch(setStockistForApproval());
  };
};

export const rejectStockist = (id, data) => {
  return async (dispatch) => {
    try {
      data = await rejectTarget("stockist", id, data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "REJECT_STOCKIST",
      payload: { data: data },
    });
    dispatch(setStockistForApproval());
  };
};

export default reducer;
