import {
  deleteTarget,
  fetchHqYearlyTargets,
  fetchMonthTargets,
  fetchYearlyTargets2,
  newHqTarget,
  newTarget,
} from "../../services/reports.js";

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_TARGET":
      return action.payload;

    case "CREATE_HQ_TARGET":
      return action.payload;

    case "FETCH_MONTH_TARGETS":
      return action.payload;
      
    case "FETCH_YEAR_TARGETS":
      return action.payload;

    case "FETCH_HQ_YEAR_TARGETS":
      return action.payload;

    case "DELETE_TARGET":
      return action.payload;

    case "CLEAR_TARGET":
      return initialState;

    default:
      return state;
  }
};

export const addUserTarget = (targetData) => {
  return async (dispatch) => {
    let data;
    
    try {
      data = await newTarget(targetData);
    } catch (e) {
       throw e;
    }
  };
};

export const addHqTarget = (targetData) => {
  return async (dispatch) => {
    let data;

    try {
      data = await newHqTarget(targetData);
    } catch (e) {
       throw e;
    }
  };
};

export const monthTargets = (type, targetData) => {
  return async (dispatch) => {
    let data;

    try {
      data = await fetchMonthTargets(type, targetData);
    } catch (e) {
       throw e;
    }

    dispatch({
      type: "FETCH_MONTH_TARGETS",
      payload: data,
    });
  };
};

export const yearlyTargets = (targetData) => {
  return async (dispatch) => {
    let data;

    try {
      data = await fetchYearlyTargets2(targetData);
    } catch (e) {
       throw e;
    }
    dispatch({
      type: "FETCH_YEAR_TARGETS",
      payload: data,
    });
  };
};

export const hqYearlyTargets = (targetData) => {
  return async (dispatch) => {
    let data;
    try {
      data = await fetchHqYearlyTargets(targetData);
    } catch (e) {
       throw e;
    }
    dispatch({
      type: "FETCH_HQ_YEAR_TARGETS",
      payload: data,
    });
  };
};

export const deleteTargets = (type, targetData) => {
  return async (dispatch) => {
    let data;

    try {
      data = await deleteTarget(type, targetData);
    } catch (e) {
       throw e;
    }
  };
};

export const clearTarget = () => {
  return {
    type: "CLEAR_TARGET",
  };
};

export default reducer;
