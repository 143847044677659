import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";
import {
  addDesignation,
  clearDesignations,
  deleteDesignation,
  editDesignation,
  setDesignations,
} from "../../../../reducers/users/designationReducer";
import serialise from "../../../../utils/serialiseResponse";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import Select from "react-select";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import { RiCloseCircleLine, RiEdit2Fill } from "react-icons/ri";

const priorityOptions = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10,label: "10" },
  { value: 11,label: "11" },
  { value: 12,label: "12" },
  { value: 13,label: "13" },
  { value: 14,label: "14" },
  { value: 15,label: "15" },
  { value: 16,label: "16" },
  { value: 17,label: "17" },
  { value: 18,label: "18" },
  { value: 19,label: "19" },
  { value: 20,label: "20" },
];

const AdminCity = () => {
  const dispatch = useDispatch();
  const designationData = useSelector(({ designation }) => designation);

  const [designation, setDesignation] = useState("");
  const [priority, setPriority] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [desigData, setDesigData] = useState();

  const EditPopup = ({ data }) => {
    const dispatch = useDispatch();
    const [designation, setDesignation] = useState("");

    let updatedData = { ...data, name: designation };
    const handleEdit = async (e) => {
      e.preventDefault();
      
      try{
        await dispatch(editDesignation(updatedData?._id, updatedData));
        setShowEdit(false);
        return customToast.success("Edited successfully");
      }
      catch(err){
        return customToast.error(err?.response?.data?.data ? `${err?.response?.data?.data}` : "Unable to edit");
      }
    };

    const handleDelete = () => {
      
      try{
        dispatch(deleteDesignation(updatedData?._id));
        setShowEdit(false);
        return customToast.success("Deleted successfully");
      }
      catch(err){
        return customToast.err("Unable to delete");
      }
    };

    useEffect(() => {
      setDesignation(data.name);
    }, [data]);

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Edit Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form onSubmit={handleEdit}>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  Edit Designation Name <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="division"
                  className="me-5"
                  placeholder="Enter Division"
                  value={designation}
                  onChange={({ target }) => setDesignation(target.value)}
                />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-submit-green mt-4">
                <span className="button-submit-green__icon-container">
                  <MdOutlineEdit className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Edit</span>
              </button>

              <button
                type="submit"
                className="button-delete mt-4"
                onClick={() => handleDelete()}
              >
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const setEditForm = (data) => {
    setShowEdit(false);
    setDesignation(data?.name);
    setDesigData(data);
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Designation",
        accessor: "name",
      },
      {
        Header: "Level",
        accessor: "priority",
        disableFilters: true,
        // disableSortBy: true,
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: (props) => {
          return (
            <span
              className="react-table-view-link"
              onClick={() => {
                setEditForm(props.row.original);
                setShowEdit(true);
              }}
            >
              <RiEdit2Fill className="icon-color-green" />
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(setDesignations());
    return ()=>{ dispatch(clearDesignations());}
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!designation) return customToast.error("Please Enter Designation Name");

    var index = tableData.findIndex(
      (p) => p.name.toLocaleLowerCase() == designation.toLocaleLowerCase()
    );

    if (index === -1) {
      let error = false;
      try {
        let data = { name: designation, priority: priority?.value };
        await dispatch(addDesignation(data));
        customToast.success("Designation Successfully Created");
        setDesignation("");
      } catch (e) {
        error = true;
        customToast.error("We Encountered an Error");
      }
    } else {
      customToast.error("Designation Already Exists");
    }
    setPriority("");
  };

  const tableData = useMemo(() => serialise(designationData), [designationData]);

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Create Designation" />

        <section className="area-creation-content__form">
          <div className="admin-panel__alert mt-0 mb-5">
            <h2>Usage Instructions</h2>
            <p>The designation Level is based on the lowest to the highest rank of your company hierarchy. So please create "Field Manager" or "MR" (Medical Representative) with Level '1', and then create all the other designations, thereby moving upwards in your company's hierarchy chain.</p>
            <p className="text-decoration-underline">For Example --</p>
            <div className="w-25">
            <div className="d-flex justify-content-between"> <p>MR</p>  <p>1</p></div>
            <div className="d-flex justify-content-between"> <p>ASM</p> <p>2</p></div>
            <div className="d-flex justify-content-between"> <p>RSM</p> <p>3</p></div>
            <div className="d-flex justify-content-between"> <p>ZSM</p> <p>4</p></div>
            <div className="d-flex justify-content-between"> <p>NSM</p> <p>5</p></div>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="designation-form">
              <div>
                <label htmlFor="designation">
                  Enter Designation <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="designation"
                  className="me-5"
                  placeholder="Enter Designation"
                  value={designation}
                  onChange={({ target }) => setDesignation(target.value)}
                />
              </div>

              <div>
                <label htmlFor="designation">
                  Select Level <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  options={priorityOptions}
                  value={priority}
                  onChange={(e) => setPriority({ ...e })}
                  styles={adminStyles}
                  placeholder="Select Level"
                />
              </div>
            </div>

            <button type="submit" className="button-blue-gradient mt-4">
              Add Designation
            </button>
          </form>
        </section>

        <section className="area-creation-content__info">
          {tableData.length > 0 && (
            <h2 className="web-app__heading mb-4 ms-2">
              Showing ({tableData.length}) Entries
            </h2>
          )}
          <div className="filter-table">
            <Table columns={columns} data={tableData} />
          </div>
          {showEdit ? <EditPopup data={desigData} /> : null}
        </section>
      </div>
    </div>
  );
};

export default AdminCity;
