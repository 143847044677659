import axios from "axios";
import { retrieveToken } from "../utils/authStorage";
import { apiURL } from "../utils/helper";
const baseUrl = `${apiURL}/client/`;

let token = retrieveToken() ? `bearer ${retrieveToken()}` : null;

export const setAuth = () => ({ Authorization: token });

export const setToken = (newToken) => (token = `bearer ${newToken}`);

export const login = async (credentials) => {
 
  const { data } = await axios.post(baseUrl + "v1/auth/login", credentials, );
  
  return data.data;
};

export const SuperAdminlogin = async (credentials) => {
  const { data } = await axios.post("http://stageapi.medorn.com/admin/login", credentials);

  return data;
};

export const register = async (credentials) => {
  const { data } = await axios.post(baseUrl + "auth/register", credentials);

  return data;
};

export const passwordRequest = async (email) => {
  const { data } = await axios.post(baseUrl + "auth/password-request", {
    email,
  });

  return data;
};

export const passwordReset = async (token, id, newPassword) => {
  const { data } = await axios.post(
    baseUrl + "auth/password-reset",
    { password: newPassword },
    { params: { token, id } }
  );

  return data;
};

export const getUserData =  async (id) => {
  
    const { data } = await axios.get(baseUrl + "auth/user-info", {
      params: { id },
      headers: setAuth()
  });

  return data.data;
};
