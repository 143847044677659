import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import customToast from "../../../../components/CustomToast";
import {
  addHeadquarter,
  clearHeadquarters,
  setHeadquarters,
  editHeadquarters,
  deleteHeadquarters,
} from "../../../../reducers/locations/hqReducer";
import serialise, {
  generateSelectData,
} from "../../../../utils/serialiseResponse";
import {
  clearStates,
  setStates,
} from "../../../../reducers/locations/stateReducer";
import PageTitle from "../../../../components/PageTitle";
import { MdDelete, MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import Table from "../../../../components/Table";
import { RiCloseCircleLine, RiEdit2Fill } from "react-icons/ri";
import { fetchDependents, viewLocations } from "../../../../services/locations";

const AdminHQ = () => {
  const dispatch = useDispatch();
  const states = useSelector(({ state }) => state);
  const headquarters = useSelector(({ headquarter }) => headquarter);

  const [state, setState] = useState(null);
  const [hq, setHQ] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [hqData, setHqData] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [dependents, setDependents] = useState({})
  const [loading, setLoading] = useState(true)
  const [showConfirmDelete, setShowConfrimDelete] = useState(false)

  const EditPopup = ({ data, type }) => {
    const dispatch = useDispatch();
    const [hq, setHq] = useState("");

    let updatedData = { ...data, name: hq };
    const handleEdit = async (e) => {
      e.preventDefault();
      try {
        await dispatch(editHeadquarters(updatedData?._id, updatedData));
        customToast.success("Headquarter Successfully Updated")
      } catch (error) {
        customToast.error(error?.response?.data?.data || "Something went wrong !")
      }
      setShowEdit(false)
    };

    const handleDelete = () => {
      // console.log(showConfirmDelete)
      setShowConfrimDelete(true)
    };

    const handleConfirmDelete = () => {
      dispatch(deleteHeadquarters(updatedData?._id));
      customToast.success("Headquarter Successfully Deleted")
      setShowDelete(false);
      setShowConfrimDelete(false)
    };

    useEffect(() => {
      setHq(data.name);
    }, [data]);

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">{type} Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => type === 'edit' ? setShowEdit(false) : setShowDelete(false)}
            />
          </div>

          {type === 'edit' ? (<form onSubmit={handleEdit}>
            <div className="edit-form">
              <div>
                <label htmlFor="hq">
                  Edit Headquarter <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="hq"
                  className="me-5"
                  placeholder="Enter hq"
                  value={hq}
                  onChange={({ target }) => setHq(target.value)}
                />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-submit-green mt-4">
                <span className="button-submit-green__icon-container">
                  <MdOutlineEdit className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Edit</span>
              </button>
            </div>
          </form>) :
            (<div>
              <h1>{hq}</h1>
              {/* {dependents.length > 0 ? dependents.map((item, index) => {
                dependentsCount += item.data.value.length
                return (
                  <div key={index} className="d-flex align-items-center">
                    <select id={item.name} className="w-50 my-2 selectOptions">
                      <option defaultValue={item.name} >{item.name}: {item.data.value.length}</option>
                      {item.data.value.map((itemm, indexx) => {
                        return (
                          <option disabled key={indexx} value={itemm.name}>{itemm.name}</option>
                        );
                      })}
                    </select>
                  </div>
                );
              }) : <h3>No Dependencies</h3>} */}
              {
                loading ? <div>Loading...</div> : 
                <>
                {
                dependents?.totalCount > 0 ?
                <div>
                  <h2 className="text-danger my-4">{dependents?.totalCount} Dependencies</h2>
                  <p>Please delete or edit the following entries in order to delete  this HQ.</p>
                  <div>Cities ({dependents.cityCount})</div>
                  <div>Stockist ({dependents.stockistCount})</div>
                  <div>Chemist ({dependents.chemistCount})</div>
                  <div>Doctors ({dependents.doctorCount})</div>
                </div>
                : <h2 className="my-4" style={{color: "#2ecc71"}}>No Dependencies</h2>
              }
                </>
              }
              {showConfirmDelete ? <section className="edit-popup" style={{ position: "absolute", top: "50%"}}>
                <div className="edit-content" style={{width: "40%"}}>
                  <div className="edit-popup__heading" style={{display: "flex", justifyContent: "flex-end"}}>
                    {/* <h2 className="web-app__heading">Details</h2> */}
                    <RiCloseCircleLine
                      className="edit-popup__close"
                      onClick={() => {
                        setShowDelete(false)
                        setShowConfrimDelete(false)
                      }}
                    />
                  </div>
                  <div>Are you sure want to delete <span className="text-danger"><br />{dependents?.totalCount} dependencies </span>?</div>
                  
               <div className="edit-form-button-container">
               <button
                  type="submit"
                  className="button-delete mt-4"
                  onClick={handleConfirmDelete}
                >
                  <span className="button-delete__icon-container">
                    <MdDeleteOutline className="button-delete__icon" />
                  </span>
                  <span className="button-delete__text">Delete</span>
                </button>
                <button
                  className="button-submit-green mt-4"
                  onClick={() => {
                    setShowDelete(false)
                    setShowConfrimDelete(false)
                  }}
                >
                  <span className="button-submit-green__icon-container">
                  <RiCloseCircleLine className="button-submit-green__icon" style={{fontSize: "1.5em"}}/>
                  </span>
                  <span className="button-submit-green__text">Cancel</span>
                </button>
               </div>
                </div> 
                </section> : null}
            </div>
            )}
            {!loading && showDelete === true && (<button
            type="submit"
            className="button-delete mt-4"
            onClick={handleDelete}
          >
            <span className="button-delete__icon-container">
              <MdDeleteOutline className="button-delete__icon" />
            </span>
            <span className="button-delete__text">Delete</span>
          </button>)}
        </div>
      </section>
    );
  };

  const setEditForm = (data) => {
    setShowEdit(false);
    setHQ(data?.name);
    setHqData(data);
  };

  const selectData = useMemo(
    () => generateSelectData(states.data, "name"),
    [states]
  );

  useEffect(() => {
    dispatch(setStates());
    dispatch(setHeadquarters());
    return () => {
      dispatch(clearStates());
      dispatch(clearHeadquarters());
    };
  }, [dispatch]);

  const fetchDependencies = async (data) => {
    try {
      const response = await fetchDependents(data._id, 'headquarter');
      setDependents(response?.data);
      setLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  
  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "HQ",
        accessor: "name",
      },
      {
        Header: "UID",
        accessor: "uid",
      },
      {
        Header: "State",
        accessor: "state",
        disableSortBy: true,
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: (props) => {
          return (
            <span>
              <span
                className="react-table-view-link"
                onClick={() => {
                  setEditForm(props.row.original);
                  setShowEdit(true);
                  setLoading(true)
                }}
              >
                <RiEdit2Fill className="icon-color-green" />

              </span>
              <span
                className="react-table-view-link mx-3"
                onClick={() => {
                  setEditForm(props.row.original);
                  setShowDelete(true);
                  setLoading(true)
                  fetchDependencies(props.row.original)
                }}
              >
                <MdDelete type="button"
                  className="button-delete__icon" size={25} />
              </span>
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );
  const tableData = useMemo(() => serialise(headquarters.data), [headquarters]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!state || !hq) return customToast.error("Please fill all details");

    if (!state) return customToast.error("Please Enter State Name");

    var index = tableData.findIndex(p => (p.name).toLocaleLowerCase() == hq.toLocaleLowerCase());

    if(index === -1){
      try {
        await dispatch(addHeadquarter({ name: hq, stateId: state.value }));
        customToast.success("Headquarter Successfully Created");
        setState(null);
        setHQ("");
      } catch (e) {
        customToast.error("We Encountered an Error");
      }
    }
    else{
      customToast.error("Headquarter Already Exists!");
    }


  };


  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Create HeadQuarter" />

        <section className="area-creation-content__form">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">
                  Select State <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="state"
                  id="state"
                  value={state}
                  options={selectData}
                  onChange={({ value, label }) => setState({ value, label })}
                  styles={adminStyles}
                  placeholder="Select State"
                />
              </div>

              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">
                  Enter Headquarter <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  value={hq}
                  onChange={({ target }) => setHQ(target.value)}
                  type="text"
                  className="me-5"
                  id="state"
                  placeholder="Enter HQ Name"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 area-creation-content__form-submit">
                <button type="submit" className="button-blue-gradient">
                  Add Headquarter
                </button>
              </div>
            </div>
          </form>
        </section>

        <section className="area-creation-content__info">
          {tableData.length > 0 && (
            <h2 className="web-app__heading mb-4 ms-2">
              Showing ({tableData.length}) Entries
            </h2>
          )}
          <div className="filter-table">

            <Table columns={columns} data={tableData} />
          </div>
          {showEdit ? <EditPopup data={hqData} type={'edit'} /> : null}
          {showDelete ? <EditPopup data={hqData} type={'delete'} /> : null}
        </section>
      </div>
    </div>
  );
};

export default AdminHQ;
