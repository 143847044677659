import { getSampleManagement, newSampleManagement } from "../../services/ssg";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "VIEW_SAMPLE_MANAGEMENT":
      return action.payload;
    case "CREATE_SAMPLE_MANAGEMENT":
      return action.payload;
    case "CLEAR_SAMPLE_MANAGEMENT":
      return initialState;

    default:
      return state;
  }
};

export const viewSampleManagement = () => {
  return async (dispatch) => {
    const { data } = await getSampleManagement();

    dispatch({
      type: "VIEW_SAMPLE_MANAGEMENT",
      payload: { data: data },
    });
  };
};

export const addSampleManagement = (data) => {
  return async (dispatch) => {
    try {
      data = await newSampleManagement(data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "CREATE_SAMPLE_MANAGEMENT",
      payload: data,
    });
  };
};

export const clearSampleManagement = () => {
  return {
    type: "CLEAR_SAMPLE_MANAGEMENT",
  };
};

export default reducer;
