import { MdArrowBackIosNew} from "react-icons/md";
import {useLocation, useNavigate } from "react-router-dom";
import { useState, useMemo, useEffect } from "react";
import {
  addIndex,
  serialiseChemStk,
} from "../../../../../../utils/serialiseResponse";
import Table from "../../../../../../components/Table";
import { viewProductWisePrimarySales } from "../../../../../../services/utilities";
import customToast from "../../../../../../components/CustomToast";
import { formatDate } from "../../../../../../utils/helper";

const ProductPS = () => {
  const [reports, setReports] = useState([]);
  const [toggle ,  setToggle] = useState(false);
  const [loading , setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.data ;
  const dateRange = location?.state?.dateRange ;//any component redirecting must pass these in state
  const stockist = location?.state?.stockist ;//these values are used in backend
    
  useEffect(()=>{
    const fetch  = async()=>{
      setLoading(true);
      try {
        const filter = {
          id,
          type:toggle?"date":"sales",
          startCdt: new Date(dateRange?.startDate).getTime(),
          endCdt: new Date(dateRange?.endDate).getTime(),
          startDate: new Date(dateRange?.startDate).toISOString(),
          endDate: new Date(dateRange?.endDate).toISOString()
        };
        const {data} = await viewProductWisePrimarySales(filter);
        
        setReports(data);
      } catch (error) {
        customToast.error("error occured Please Try Again");
      }
      setLoading(false);
    }
    if(!loading)fetch();
  },[id,dateRange,toggle]);

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   disableFilters:true,
      //   disableSortBy:true,
      //   minWidth:50,
      //   maxWidth:50
      // },
      {
        Header: "Product",
        accessor: "product",
        disableSortBy: true,
        showTotal:true,
        placeholderTotal:"Total",
        Cell: (props) => {
          return <span>{props?.row?.original.product?.name}</span>
        },
      },
      {
        Header: "Total Primary Sales",
        accessor: "salesValue",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"salesValue",
        Cell:(props) => {
          return <div>
            {`₹ ${Number(props?.row?.original?.salesValue).toFixed(2)}`}
          </div>
        }
      },
    ],
    []
  );
  const columns2 = useMemo(
    () => [
      {
        Header:"Date",
        accessor:"date",
        minWidth:100,
        maxWidth:100,
        disableFilters:true,
        disableSortBy:true,
        Cell:(props)=>(
          <span>{formatDate(props?.row?.original?.date)}</span>
        )
      },
      {
        Header: "Product",
        accessor: "product",
        showTotal:true,
        placeholderTotal:"Total",
        Cell: (props) => {
          return <span>{props?.row?.original.product?.name}</span>
        },
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"quantity"
      },
      {
        Header: "Average Price",
        accessor: "averagePrice",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Total Sales",
        accessor: "salesValue",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"salesValue",
        Cell:(props) => {
          return <div>
            {`₹ ${Number(props?.row?.original?.salesValue).toFixed(2)}`}
          </div>
        }
      },
    ],
    []
  );

  const tableData = useMemo(() => addIndex(reports), [reports]);
  
  // useMemo(() => serialiseChemStk(reports), [reports]);
  

  return (
    <div className="expense">
      <h2
        className="web-app__heading"
        style={{ display: "flex", alignItems: "center" }}
      >
        <MdArrowBackIosNew
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        <span style={{ display: "inline-block", margin: "0 1rem" }}>
          Product Wise Sales Reports
        </span>
      </h2>
      <hr className="text-white" />
      <br></br>
      <div className="filter-table">
          { !loading ?
            (tableData.length > 0 ? (
              <>
              <h2 className="web-app__heading mb-4 ps-3 d-flex justify-content-between">
                  Showing results for Stockist: {stockist}
                  <div className="form__control pe-3" style={{ width: "fit-content" }}>
                    <label className="toggle-label me-3">
                      <input
                        type="checkbox"
                        defaultChecked={toggle}
                        onClick={(e)=>setToggle(!toggle)}
                      />
                      <span/>
                    </label>
                    <span>View Date Wise</span>
                  </div>
              </h2>
              <Table columns={toggle ? columns2 : columns} data={tableData} fileSrc="Product wise Primary Sales Report" />
              </>
            ) : (
              <h3 className="mt-2 no-results-message">No results found</h3>
            )) :
            <h3 className="mt-2 fetching-results-message">Hold on fetching reports for you...</h3>
          }
      </div> 
    </div>
  );
};

export default ProductPS ;
