import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import customToast from "../../../../../components/CustomToast";
import PageTitle from "../../../../../components/PageTitle";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../../reducers/locations/hqReducer";
import {
  clearDivisions,
  setDivisions,
} from "../../../../../reducers/users/divisionReducer";
import {
  clearUsers,
  setUserDuringCreation,
} from "../../../../../reducers/users/employeeReducer";
import {
  deleteUser,
  deleteUserV1,
  editUser,
  editUserV1,
  viewSpecificUser,
} from "../../../../../services/users";
import {
  generateSelectData,
  getDesignation,
} from "../../../../../utils/serialiseResponse";
import { adminStyles } from "../../../../public/stylesheets/selectStyles";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "../../../../../utils/helper";
import { RiCloseCircleLine } from "react-icons/ri";
import { BsCheck2Circle, BsXCircle } from "react-icons/bs";
import { DESIGNATIONS } from "../../../../../constants/enums";

const genderOptions = [
  { value: 0, label: "Male" },
  { value: 1, label: "Female" },
];

const AdminUserEditDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const divisionData = useSelector(({ division }) => division);
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const userData = useSelector(({ employee }) => employee);
  const [employee, setEmployee] = useState("");
  const divisionSelect = useMemo(
    () => generateSelectData(divisionData, "name"),
    [divisionData]
  );
  const designationSelect = useMemo(
    () => generateSelectData(DESIGNATIONS, "name"),
    [DESIGNATIONS]
  );
  const headquarterSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );
  const employeeSelect = useMemo(
    () => generateSelectData(userData, "fullName"),
    [userData]
  );

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState(null);

  const [dob, setDob] = useState("");
  const [doj, setDoj] = useState("");

  const [headquarter, setHeadquarter] = useState(null);
  const [division, setDivision] = useState(null);
  const [employeeId, setEmployeeId] = useState("");
  const [designation, setDesignation] = useState(null);

  const [reportingManager, setReportingManager] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [dailyAllowance, setDailyAllowance] = useState("");
  const [exStationAllowance, setexStationAllowance] = useState("");
  const [outStationAllowance, setOutStationAllowance] = useState("");

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    viewSpecificUser(location?.state._id)
      .then(({ data }) => {
        setEmployee(data);
      })
      .catch((err) => customToast.error("Sorry! failed to load user details"));
  }, [location]);

  useEffect(() => {
    if (employee) {
      setFirstName(employee?.firstName);
      setMiddleName(employee?.middleName);
      setLastName(employee?.lastName);
      setGender({
        label: employee?.gender,
        value: employee?.gender === "Female" ? 1 : 2,
      });

      setEmail(employee?.email);
      setPhone(employee?.phone);
      // setPassword(employee?.);

      setDob(employee?.birthDate?.substring(0, 10));
      setDoj(employee?.joiningDate?.substring(0, 10));

      setHeadquarter({
        value: employee?.headquarter._id,
        label: employee?.headquarter.name,
      });
      setDivision({
        label: employee?.division.name,
        value: employee?.division._id,
      });
      setEmployeeId(employee?.employeeId);
      setDesignation({
        label: getDesignation(employee?.des),
        value: employee?.des,
      });

      // let fullName = employee?.reportingManager[0]?.firstName + " " + employee?.reportingManager[0]?.middleName + " "+ employee?.reportingManager[0]?.lastName
      setReportingManager({
        label: employee?.reportingManager?.name,
        value: employee?.reportingManager?._id,
      });
      setAadharNumber(employee?.aadharNumber);
      setPanNumber(employee?.panNumber);

      setAddress1(employee?.address.street1);
      setAddress2(employee?.address.street2);
      setCity(employee?.address.city);
      setState(employee?.address.state);
      setexStationAllowance(employee?.exStationAllowance);
      setOutStationAllowance(employee?.outStationAllowance);
      setDailyAllowance(employee?.dailyAllowance);
    }
  }, [employee]);

  useEffect(() => {
    dispatch(setDivisions());
    dispatch(setHeadquarters());
    return () => {
      dispatch(clearDivisions());
      dispatch(clearHeadquarters());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      setUserDuringCreation({
        divisionId: division?.value,
        priority: designation?.value ? designation?.value : designation,
      })
    );
    return () => {
      dispatch(clearUsers());
    };
  }, [dispatch, division, designation]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      firstName,
      middleName,
      lastName,
      gender: gender?.label,
      email,
      phone,
      password,
      dob,
      doj,
      headquarterId: headquarter?.value,
      divisionId: division?.value,
      employeeId,
      designationId: designation?.value,
      designationPriority: designation?.priority,
      reportingManagerId: reportingManager?.value,
      aadharNumber,
      panNumber,
      dailyAllowance,
      exStationAllowance,
      outStationAllowance,
      address1,
      address2,
      city,
      state,
    };

    let error = false;
    try {
      // await editUser({ id: employee?._id }, data);
      await editUserV1({ id: employee?._id }, data);
    } catch (e) {
      error = true;
      customToast.error(e?.response?.data?.message || "We Encountered an Error");
    }

    if (!error) {
      customToast.success("User Successfully Edited");

      navigate(-1);
    }
  };

  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const deleteUserHandler = async () => {
    try {
      // await deleteUser({ id: employee?._id });
      await deleteUserV1({ id: employee?._id });
      customToast.success("User deleted successfully");
      navigate(-1);
    } catch (err) {
      return customToast.error("An unexpected error occured");
    }
  };
  const EditPopup = () => {
    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  Are you sure you want to delete?
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button
                type="button"
                onClick={deleteUserHandler}
                className="button-delete mt-4"
              >
                <span className="button-delete__icon-container">
                  <BsXCircle className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>
              <button
                type="button"
                onClick={() => setShowEdit(false)}
                className="button-submit-green mt-4"
              >
                <span className="button-submit-green__icon-container">
                  <BsCheck2Circle className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Cancel</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Edit User" hierarchy={true} />

        <section className="area-creation-content__form">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="fName">First Name</label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="fName"
                  placeholder="Enter First Name"
                  value={firstName}
                  onChange={({ target }) => setFirstName(target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="mName">Middle Name</label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="mName"
                  placeholder="Enter Middle Name"
                  value={middleName}
                  onChange={({ target }) => setMiddleName(target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="lName">Last Name</label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="lName"
                  placeholder="Enter Last Name"
                  value={lastName}
                  onChange={({ target }) => setLastName(target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                <label htmlFor="lName">Gender</label>
                <br />
                <Select
                  options={genderOptions}
                  styles={adminStyles}
                  value={gender}
                  placeholder={`${gender}`}
                  onChange={(e) => setGender({ ...e })}
                />
              </div>
            </div>

            <div className="row create-user__form-row">
              <h2 className="web-app__heading py-5">Login Credentials</h2>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="email">Email</label>
                <br />
                <input
                  type="email"
                  className="me-5"
                  id="email"
                  placeholder="Enter Email Address"
                  value={email}
                  onChange={({ target }) =>
                    setEmail(target.value.toLowerCase())
                  }
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="mobile">Phone</label>
                <br />
                <input
                  type="tel"
                  maxLength={10}
                  pattern="[0-9]*"
                  className="me-5"
                  id="mobile"
                  placeholder="Enter Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 password-input">
                <label htmlFor="password">Password</label>
                <br />
                <input
                  type={passwordType}
                  className="me-5"
                  id="password"
                  placeholder="Enter Password"
                  value={password}
                  onChange={({ target }) => setPassword(target.value.trim())}
                />

                <button
                  type="button"
                  className="show-password-button"
                  onClick={togglePassword}
                >
                  {passwordType === "password" ? (
                    <AiFillEyeInvisible className="password-icon password-icon-show" />
                  ) : (
                    <AiFillEye className="password-icon password-icon-hide" />
                  )}
                </button>
              </div>
            </div>

            <div className="row">
              <h2 className="web-app__heading py-5">Employee Details</h2>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="dob">DOB</label>
                <br />
                <input
                  type="date"
                  className="me-5"
                  id="dob"
                  placeholder="Date of Birth"
                  value={dob}
                  onChange={({ target }) => setDob(target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="doj">DOJ</label>
                <br />
                <input
                  type="date"
                  className="me-5"
                  id="doj"
                  placeholder="Date of Joining"
                  value={doj}
                  onChange={({ target }) => setDoj(target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="hq">Headquarter</label>
                <br />
                <Select
                  name="headquarter"
                  id="headquarter"
                  value={headquarter}
                  options={headquarterSelect}
                  onChange={({ value, label }) =>
                    setHeadquarter({ value, label })
                  }
                  styles={adminStyles}
                  placeholder={`${headquarter}`}
                />
              </div>
            </div>

            <div className="row py-5">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="division">Division</label>
                <br />
                <Select
                  name="division"
                  id="division"
                  value={division}
                  options={divisionSelect}
                  onChange={({ value, label }) => setDivision({ value, label })}
                  styles={adminStyles}
                  placeholder={`${division}`}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="employee-id">Employee ID</label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="employee-id"
                  placeholder="Enter ID"
                  value={employeeId}
                  onChange={({ target }) => setEmployeeId(target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="designation">Designation</label>
                <br />
                <Select
                  name="designation"
                  id="designation"
                  value={designation}
                  options={designationSelect}
                  onChange={(e) => setDesignation({ ...e })}
                  styles={adminStyles}
                  placeholder={`${designation}`}
                />
              </div>
            </div>

            <div className="row create-user__form-row-two">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="rep-manager">Reporting Manager</label>
                <br />
                <Select
                  name="rep-manager"
                  id="rep-manager"
                  value={reportingManager}
                  options={employeeSelect}
                  onChange={({ value, label }) => {
                    return setReportingManager({ value, label });
                  }}
                  styles={adminStyles}
                  placeholder={`${reportingManager}`}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="aadhaar">Aadhar Number</label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="aadhaar"
                  placeholder="Enter Middle Name"
                  value={aadharNumber}
                  maxLength={12}
                  onChange={({ target }) => setAadharNumber(target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="pan">Pan Number</label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="pan"
                  placeholder="Enter Middle Name"
                  value={panNumber}
                  maxLength={10}
                  onChange={({ target }) => setPanNumber(target.value)}
                />
              </div>
            </div>

            <div className="row create-user__form-row-two mt-5">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="dailyAllowance">Daily Allowance</label>
                <br />
                <input
                  type="number"
                  className="me-5"
                  id="dailyAllowance"
                  placeholder="Enter Daily Allowance"
                  value={dailyAllowance}
                  onChange={({ target }) => setDailyAllowance(target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="exStationAllowance">Ex-Station Allowance</label>
                <br />
                <input
                  type="number"
                  className="me-5"
                  id="exStationAllowance"
                  placeholder="Enter Ex-Station Allowance"
                  value={exStationAllowance}
                  onChange={({ target }) => setexStationAllowance(target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="outStationAllowance">
                  Out-Station Allowance
                </label>
                <br />
                <input
                  type="number"
                  className="me-5"
                  id="outStationAllowance"
                  placeholder="Enter Out-Station Allowance"
                  value={outStationAllowance}
                  onChange={({ target }) =>
                    setOutStationAllowance(target.value)
                  }
                />
              </div>
            </div>

            <div className="row">
              <h2 className="web-app__heading py-5">Address</h2>
              <div className="col-sm-12 col-md-8 col-lg-8">
                <label htmlFor="addr">Street Address 1</label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="addr1"
                  placeholder="Enter Street Address"
                  value={address1}
                  onChange={({ target }) => setAddress1(target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="city">City</label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="city"
                  placeholder="Enter City Name"
                  value={city}
                  onChange={({ target }) => setCity(target.value)}
                />
              </div>
            </div>

            <div className="row py-5">
              <div className="col-sm-12 col-md-8 col-lg-8">
                <label htmlFor="addr">Street Address 2</label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="addr2"
                  placeholder="Enter Street Address"
                  value={address2}
                  onChange={({ target }) => setAddress2(target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">State</label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="state"
                  placeholder="Enter State"
                  value={state}
                  onChange={({ target }) => setState(target.value)}
                />
              </div>
            </div>

            <div className="d-flex flex-wrap justify-content-between">
              <button type="submit" className="button-submit-green me-5 mt-5">
                <span className="button-submit-green__icon-container">
                  <BsCheck2Circle className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Edit</span>
              </button>
              <button
                type="button"
                onClick={() => setShowEdit(true)}
                className="button-delete mt-5"
              >
                <span className="button-delete__icon-container">
                  <BsXCircle className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>
            </div>
          </form>
        </section>
        {showEdit ? <EditPopup /> : null}
      </div>
    </main>
  );
};

export default AdminUserEditDetails;
