const initialState = {
  trigger:{
    index:0,
    data:[]
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_TRIGGER":
      return { ...state, trigger: action.data };
    default:
      return state;
  }
};

export const updateTrigger = (data) => {
  return {
    type: "UPDATE_TRIGGER",
    data: data,
  };
};


export default reducer;
