import { Calendar, DateRange } from "react-date-range";

const StyledCalendar = (props) => {
  if (props?.ranges && props?.user?.des === 101) {
    return <DateRange {...props} moveRangeOnFirstSelection={false}/>;
  }

  if (props.blockPreviousDate) {
    return (
      <DateRange
        {...props}
        moveRangeOnFirstSelection={false}
        minDate={new Date()}
      />
    );
  }

  if (props.blockPreviousDateSingleSelection) {
    return <Calendar minDate={new Date()} {...props} />;
  }

  return <Calendar {...props} />;
};

export default StyledCalendar;
