import {
  newLocation,
  viewLocations,
  deleteLocation,
  editLocation,
  viewLocationsForReporting,
  fetchLocationV1,
  editLocationV1,
  deleteLocationV1,
  addLocationV1,
} from "../../services/locations.js";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOCATION_HQ":
      return action.payload;

    case "SET_LOCATION_HQ_REPORTING":
      return action.payload;

    case "CREATE_LOCATION_HQ":
      return { data: [...state.data, action.payload.data.data] };

    case "CLEAR_LOCATION_HQ":
      return initialState;

    case "EDIT_LOCATION_HQ":
      // return { ...state, newState: action.payload };
      return {data:state.data};

    case "DELETE_LOCATION_HQ":
      // return action.payload;
      return {data:state.data};

    default:
      return state;
  }
};

export const setHeadquarters = (parentId) => {
  return async (dispatch) => {
    // const { data } = await viewLocations("headquarter", parentId);
    const { data } = await fetchLocationV1("headquarter", parentId);

    dispatch({
      type: "SET_LOCATION_HQ",
      payload: { data: data },
    });
  };
};

export const setHeadquartersForReporting = (filter) => {
  return async (dispatch) => {
    try{
    const { data } = await viewLocationsForReporting("headquarter", filter);

    dispatch({
      type: "SET_LOCATION_HQ_REPORTING",
      payload: { data: data },
    });
    }catch(err){
      
    }
  };
};

export const addHeadquarter = (data) => {
  return async (dispatch) => {
    try {
      // data = await newLocation("headquarter", data);
      data = await addLocationV1("headquarter", data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "CREATE_LOCATION_HQ",
      payload: { data: data },
    });
  };
};

export const clearHeadquarters = () => {
  return {
    type: "CLEAR_LOCATION_HQ",
  };
};

export const editHeadquarters = (id, data) => {
  return async (dispatch) => {
    try {
      // data = await editLocation("headquarter", id, data);
      data = await editLocationV1("headquarter", id, data);
    } catch (e) {
      if (e) throw e;
    }

    dispatch({
      type: "EDIT_LOCATION_HQ",
      payload: { data: data },
    });
    dispatch(setHeadquarters());
  };
};

export const deleteHeadquarters = (data) => {
  return async (dispatch) => {
    try {
      // data = await deleteLocation("headquarter", data);
      data = await deleteLocationV1("headquarter", data);
    } catch (e) {
      if (data.error) throw data.error;
    }
    dispatch({
      type: "DELETE_LOCATION_HQ",
      payload: { data: data },
    });
    dispatch(setHeadquarters());
  };
};
export default reducer;
