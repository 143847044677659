import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {GiTireIronCross} from 'react-icons/gi'

const Announcement = () => {
    const [isAnnouncementVisible, setIsAnnouncementVisible] = useState(true);
    const announcement = useSelector((state) => state.settings["announcement"]);
    const announcementText = useSelector((state) => state.announcement['announcementMessage']);
    const announcementType = useSelector((state) => state.announcement['announcementType']);
    const announcementColor = useSelector((state) => state.announcement['announcementColor']);
    let bg = null;
    if (announcementColor === 'red') {
        bg = 'bg-danger';
    } else if (announcementColor === 'green') {
        bg = 'bg-success';
    } else if (announcementColor === 'yellow') {
        bg = 'bg-warning';
    } else if (announcementColor === 'blue') {
        bg = 'bg-primary';
    }

    const handleCloseAnnouncement = () => {
        setIsAnnouncementVisible(false);
    };
    const showAnnouncement = announcement === "true" ? true: false

    return (
        <div className=''>
            {isAnnouncementVisible && showAnnouncement && announcementText && (
                <div className={`${bg} ${announcementColor=== 'yellow' ? 'text-black': 'text-white' } d-flex justify-content-center`}>
                    <div className=''><span className='fw-bold'> {announcementType}: </span>{announcementText}</div>
                    <button
                        className='bg-transparent border-0 px-5 mx-5 fw-bold'
                        onClick={handleCloseAnnouncement}
                    >
                        <GiTireIronCross/>
                    </button>
                </div>
            )}
        </div>
    );
};

export default Announcement;
