import { Outlet } from "react-router-dom";
import CreationNav from "../CreationSidenav";
import { title, links } from "./navData.js";

import "./../../../public/stylesheets/admin-locations.css";

const CreationLocations = () => {
  return (
    <>
      <CreationNav title={title} links={links} />

      <Outlet />
    </>
  );
};

export default CreationLocations;
