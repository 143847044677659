import { useEffect, useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";
import serialise from "../../../../utils/serialiseResponse";
import {
  clearUsers,
  setUsers,
} from "../../../../reducers/users/employeeReducer";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";

const LiveTracking = () => {
  const dispatch = useDispatch();
 
  const userData = useSelector(({ employee }) => employee);

  useEffect(() => {
    dispatch(setUsers("user"));
    return () => {
      dispatch(clearUsers());
    };
  }, [dispatch]);

  
  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "View",
        accessor: "details",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 60,
        maxWidth: 60,
        Cell: (props) => {
          return (
            <Link
              to={`./${props?.row?.original?._id}`}
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    []
  );
  const tableData = useMemo(() => serialise(userData), [userData]);

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="User Controls" />

        <section className="area-creation-content__info">
          {tableData.length > 0 && (
            <h2 className="web-app__heading mb-4 ms-2">
              Showing ({tableData.length}) Entries
            </h2>
          )}
          <div className="filter-table">
            <Table columns={columns} data={tableData} />
          </div>
        </section>
      </div>
    </div>
  );
};

export default LiveTracking;
