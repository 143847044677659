import {
  newHoliday,
  viewHolidays,
  editAttendance,
  deleteAttendance,
  viewHolidaysV1,
  newHolidayV1,
  editAttendanceV1,
  deleteAttendanceV1,
} from "../services/attendance.js";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "VIEW_HOLIDAY_LIST":
      return action.payload;
    case "CREATE_HOLIDAY_LIST":
      return { data: [...state.data, action.payload.data.data] };
    case "EDIT_HOLIDAY_LIST":
      return { ...state, newHoliday: action.payload };
    case "DELETE_HOLIDAY_LIST":
      return {data:state.data};

    case "CLEAR_HOLIDAY_LIST":
      return initialState;
    default:
      return state;
  }
};

export const setHoliday = () => {
  return async (dispatch) => {
    const { data } = await viewHolidays();
    dispatch({
      type: "VIEW_HOLIDAY_LIST",
      payload: { data: data },
    });
  };
};

export const addHoliday = (data) => {
  return async (dispatch) => {
    try {
      data = await newHoliday("holiday", data);
    } catch (e) {
      if (data.error) throw data.error;
    }
    
    dispatch({
      type: "CREATE_HOLIDAY_LIST",
      payload: { data: data },
    });

    dispatch(setHoliday());
  };
};

export const clearHolidays = () => {
  return {
    type: "CLEAR_HOLIDAY_LIST",
  };
};

export const editHoliday = (id, updatedHoliday) => {
  return async (dispatch) => {
    try {
      updatedHoliday = await editAttendance("holiday", id, updatedHoliday);
    } catch (error) {
      if (updatedHoliday.error) throw updatedHoliday.error;
    }
    dispatch({
      type: "EDIT_HOLIDAY_LIST",
      payload: { data: updatedHoliday },
    });
    dispatch(setHoliday());
  };
};

export const deleteHoliday = (data) => {
  return async (dispatch) => {
    try {
      data = await deleteAttendance("holiday", data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "DELETE_HOLIDAY_LIST",
      payload: { data: data },
    });
    dispatch(setHoliday());
  };
};

export default reducer;
