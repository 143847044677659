import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURL = apiURL + "/client/v1/tickets"
export const addTicket = async(details)=>{
  
  const {data} = await axios.post(baseURL , details, {
    headers:{
      ...setAuth(),
      'Content-Type': 'multipart/form-data',
    }
  })
  return data ;
}

export const viewTickets = async(params)=>{
  const {data} = await axios.get(baseURL, {
    params,
    headers: setAuth()
  })

  return data ;
}

export const addComment = async(details)=>{

  const {data} = await axios.post(baseURL+"/comment", details,{
    headers:setAuth(),
  })

  return data ;
}

export const viewComments = async(params)=>{
  const {data} = await axios.get(baseURL+"/comment", {
    params,
    headers:setAuth()
  })

  return data ;
}