import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Select from "react-select"
import { dcrStyles } from '../../../public/stylesheets/selectStyles';
import { MdArrowBackIosNew } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { monthOptions, yearOptions } from '../../../../utils/helper';
import Table from '../../../../components/Table';
import { viewMonthlyExpenseReport } from '../../../../services/expenses';
import customToast from '../../../../components/CustomToast';

const MonthyExpenseReport = () => {

  const navigate = useNavigate();
  const [expenseData, setExpenseData] = useState([]);
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [loading, setLoading] = useState(false);

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        setExpenseData([]);
        setLoading(true);
        let payload = {
          month: Number(month?.value),
          year: Number(year?.label)
        };

        const res = await viewMonthlyExpenseReport(payload);
        setExpenseData(res?.data);
        setLoading(false);
      } catch (error) {
        customToast.error("error occurred !");
        console.log(error)
      }
    };
    if (month && year) {
      fetchData();
    }
  }, [month, year]);


  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name"
      },
      {
        Header: "Designation",
        accessor: "des"
      },
      {
        Header: "Reporting Manager",
        accessor: "reportingManager"
      },
      {
        Header: "Doc. Coverage (%)",
        accessor: "totalDoc"
      },
      {
        Header: "Total Working Days",
        accessor: "workingDays"
      },
      {
        Header: "Sundays",
        accessor: "sundays"
      },
      {
        Header: "Holiday",
        accessor: "holidays"
      },
      {
        Header: "No. Of Days Worked",
        accessor: "totalDaysWorked"
      },
      {
        Header: "Leaves",
        accessor: "leaves"
      },
      {
        Header: "Absent Days",
        accessor: "absent"
      },
      {
        Header: "Travel",
        accessor: "travelAllowance"
      },
      {
        Header: "Food",
        accessor: "foodAllowance"
      },
      {
        Header: "Hotel",
        accessor: "hotelAllowance"
      },
      {
        Header: "Ticket",
        accessor: "ticketAllowance"
      },
      {
        Header: "Daily",
        accessor: "dailyAllowance"
      },
      {
        Header: "Misc.",
        accessor: "misExpense"
      },
      {
        Header: "Total",
        accessor: "total"
      },
    ],
    []
  );

  return (
    <div className='p-3'>
      <div className='row'>
        <h2 className='web-app__heading d-flex align-items-center'>
          <MdArrowBackIosNew onClick={() => navigate(-1)} style={{ cursor: "pointer" }} />
          <span className='d-block mx-2 my-2'>
            Monthy Expense Report
          </span>
        </h2>

        <div>
          <div className='util-tp-query d-flex justify-content-between'>
            <div className='d-flex align-items-center'>
              <div className='util-tp-filter me-5'>
                <p>Select Month</p>
                <Select
                  styles={dcrStyles}
                  className='mt-2'
                  placeholder="Select Month"
                  options={monthOptions}
                  onChange={(e) => setMonth(e)} />
              </div>

              <div className='util-tp-filter me-5'>
                <p>Select Year</p>
                <Select
                  styles={dcrStyles}
                  className='mt-2'
                  placeholder="Select Year"
                  options={yearOptions}
                  onChange={(e) => setYear(e)} />
              </div>
            </div>
          </div>
        </div>

        {(loading === true && expenseData?.length === 0) ?
          <div>Loading ...</div>
          :

          <div className='filter-table' style={{ maxWidth: "119rem" }}>
            <Table columns={columns} data={expenseData} />
          </div>
        }
      </div>
    </div>
  )
}

export default MonthyExpenseReport; 