import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import customToast from "../../../../../components/CustomToast";
import { generateSelectData } from "../../../../../utils/serialiseResponse";
import {
  setProductCategories,
  clearProductCategories,
} from "../../../../../reducers/products/categoryReducer";
import {
  setProductTypes,
  clearProductTypes,
} from "../../../../../reducers/products/typeReducer";
import {
  deleteProductRange,
  editProductRange,
} from "../../../../../reducers/products/rangeReducer";
import { viewProduct } from "../../../../../services/products";
import { adminStyles } from "../../../../public/stylesheets/selectStyles";
import PageTitle from "../../../../../components/PageTitle";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import {
  clearDivisions,
  setDivisions,
} from "../../../../../reducers/users/divisionReducer";

const AdminCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const category = useSelector(({ productCategory }) => productCategory);
  const types = useSelector(({ productType }) => productType);
  const divisionData = useSelector(({ division }) => division);
  const [product, setProduct] = useState({});
  const categorySelect = useMemo(
    () => generateSelectData(category.data, "name"),
    [category]
  );
  const typeSelect = useMemo(
    () => generateSelectData(types.data, "name"),
    [types]
  );
  const divisionSelect = useMemo(
    () => generateSelectData(divisionData, "name"),
    [divisionData]
  );

  const [prodCategory, setProdCategory] = useState("");
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [composition, setComposition] = useState("");
  const [packaging, setPackaging] = useState("");
  const [mrp, setMrp] = useState(0);
  const [pts, setPts] = useState(0);
  const [ptr, setPtr] = useState(0);
  const [manufacturer, setManufacturer] = useState("");
  const [gst, setGst] = useState(0);
  const [stock, setStock] = useState(0);
  const [division, setDivision] = useState(null);
  useEffect(()=>{
    viewProduct(location.state._id).then(({data})=>{
      console.log(data);
      setProduct(data);
    }).catch((err)=> customToast.error("Failed to load product details"))

  },[location]);
  useEffect(() => {
    dispatch(setProductCategories());
    dispatch(setProductTypes());
    dispatch(setDivisions());
    
    return () => {
      dispatch(clearProductCategories());
      dispatch(clearProductTypes());
      dispatch(clearDivisions());
    };
  }, [dispatch]);
  useEffect(() => {
    setProdCategory({
      value: product?.category?._id,
      label: product?.category?.name,
    });
    setComposition(product?.composition);
    setType({
      value: product?.type?._id,
      label: product?.type?.name,
    });
    setName(product?.name);
    setPackaging(product?.packaging);
    setMrp(product?.mrp);
    setPtr(product?.ptr);
    setPts(product?.pts);
    setManufacturer(product?.manufacturer);
    setGst(product?.gst);
    setStock(product?.stock);
    setDivision({
      value: product?.division?._id,
      label: product?.division?.name,
    });
  }, [product]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !packaging || !pts || !mrp || !ptr || !composition)
      return customToast.error("Please fill all details");

    let error = false;
    try {
      let data = {
        name: name,
        categoryId: prodCategory?.value,
        typeId: type?.value,
        packaging: packaging,
        mrp: mrp,
        pts: pts,
        ptr: ptr,
        composition,
        manufacturer,
        gst,
        stock: Number(stock),
        division: division?.value,
      };
      await dispatch(editProductRange(product._id, data));
      navigate(-1);
    } catch (e) {
      error = true;
      customToast.error(e?.response?.data?.data || "We Encountered an Error");
    }

    if (!error) {
      customToast.success("Product edited successfully");
      setProdCategory(null);
      setComposition(null);
      setType(null);
      setName("");
      setPackaging("");
      setMrp(0);
      setPtr(0);
      setPts(0);
      setComposition("");
      setManufacturer("");
      setGst(0);
      setStock(0);
      setDivision(null);
    }
  };

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Edit Product.." hierarchy={true} />

        <section className="area-creation-content__form">
          <form onSubmit={handleSubmit}>
            <div className="admin-form">
              <div>
                <label htmlFor="prod-name">
                  Product Name <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="prod-name"
                  placeholder="Enter Product Name"
                  value={name}
                  onChange={({ target }) => setName(target.value)}
                />
              </div>
              <div>
                <label htmlFor="prodComposition">
                  Product Composition <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="prodComposition"
                  placeholder="Enter Product Composition"
                  value={composition}
                  onChange={({ target }) => setComposition(target.value)}
                />
              </div>
              <div>
                <label htmlFor="prod-type">Select Product Category</label>
                <br />
                <Select
                  name="prodCategory"
                  id="prodCategory"
                  value={prodCategory}
                  options={categorySelect}
                  onChange={(e) =>
                    setProdCategory({ value: e.value, label: e.label })
                  }
                  styles={adminStyles}
                  placeholder="Select Category"
                />
              </div>
              <div>
                <label htmlFor="prod-type">Select Product Type</label>
                <br />
                <Select
                  name="prod-type"
                  id="prod-type"
                  value={type}
                  options={typeSelect}
                  onChange={(e) => setType({ value: e.value, label: e.label })}
                  styles={adminStyles}
                  placeholder="Select Product Type"
                />
              </div>
              <div>
                <label htmlFor="prodManufac">Manufacturer Name</label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="prodManufac"
                  placeholder="Enter Product Manufacturer"
                  value={manufacturer}
                  onChange={({ target }) => setManufacturer(target.value)}
                />
              </div>
              <div>
                <label htmlFor="prod-pack">
                  Product Packaging <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="prod-pack"
                  placeholder="Enter Product Packaging Details"
                  value={packaging}
                  onChange={({ target }) => setPackaging(target.value)}
                />
              </div>
              <div>
                <label htmlFor="division">
                  Division <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="division"
                  id="division"
                  value={division}
                  options={divisionSelect}
                  onChange={({ value, label }) => setDivision({ value, label })}
                  styles={adminStyles}
                  placeholder="Select Division"
                />
              </div>
              <div>
                <label htmlFor="mrp">
                  Max Retail Price <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="number"
                  className="me-5"
                  id="mrp"
                  placeholder="Enter Product MRP"
                  value={mrp}
                  onChange={({ target }) => setMrp(target.value)}
                />
              </div>
              <div>
                <label htmlFor="pts">
                  Price To Stockist <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="number"
                  className="me-5"
                  id="pts"
                  placeholder="Enter Product PTS"
                  value={pts}
                  onChange={({ target }) => setPts(target.value)}
                />
              </div>
              <div>
                <label htmlFor="ptr">
                  Price To Retailers <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="number"
                  className="me-5"
                  id="ptr"
                  placeholder="Enter Product PTR"
                  value={ptr}
                  onChange={({ target }) => setPtr(target.value)}
                />
              </div>
              <div>
                <label htmlFor="gst">GST %</label>
                <br />
                <input
                  type="number"
                  className="me-5"
                  id="gst"
                  placeholder="Enter Product GST %"
                  value={gst}
                  onChange={({ target }) => setGst(target.value)}
                />
              </div>
              <div>
                <label htmlFor="stock">Stock</label>
                <br />
                <input
                  type="number"
                  className="me-5"
                  id="stock"
                  placeholder="Enter Stock"
                  value={stock}
                  onChange={(e) => {
                    setStock(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="edit-form-button-container">
              <button
                type="submit"
                className="button-submit-green mt-4"
                onClick={handleSubmit}
              >
                <span className="button-submit-green__icon-container">
                  <MdOutlineEdit className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Edit</span>
              </button>

              <button
                type="button"
                className="button-delete mt-4"
                onClick={() => {
                  dispatch(deleteProductRange(product._id));
                  navigate(-1);
                  customToast.success("Product deleted successfully");
                }}
              >
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};

export default AdminCategory;
