import { useLocation } from "react-router-dom";
import PageTitle from "../../../../../components/PageTitle";
import { formatDate } from "../../../../../utils/helper";
import { useEffect, useState } from "react";
import { getDoctorDetails } from "../../../../../services/doctor";
import customToast from "../../../../../components/CustomToast";

const AdminViewDCSDetails = () => {
  const location = useLocation();

  const [doctor, setDoctor] = useState(null);

  useEffect(() => {
    getDoctorDetails(location?.state?._id)
    .then(({data})=>{
      setDoctor(data);
      customToast.success("Doctor fetched successfully");
    })
    .catch(err => customToast.error("Sorry! failed to load doctor details"));
  }, [location]);

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Doctor Details" hierarchy={true} />
      </div>
      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Name</h4>
          <p>{doctor?.name}</p>
        </div>

        <div className="user-detail-box">
          <h4>Degree</h4>
          <p>{doctor?.degree}</p>
        </div>

        <div className="user-detail-box">
          <h4>Specialization</h4>
          <p>{doctor?.specialization}</p>
        </div>

        <div className="user-detail-box">
          <h4>Mobile Number</h4>
          <p>{doctor?.mobile}</p>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-yellowDark-border">
      <div className="user-detail-box">
          <h4>BirthDay</h4>
          <p>{formatDate(doctor?.birthday)}</p>
        </div>

        <div className="user-detail-box">
          <h4>Marriage Anniversary</h4>
          <p>{formatDate(doctor?.anniversary)}</p>
        </div>

        <div className="user-detail-box">
          <h4>HeadQuarter</h4>
          <p>{doctor?.city?.name}</p>
        </div>

        <div className="user-detail-box">
          <h4>Working Area</h4>
          <p>{doctor?.workingArea?.name}</p>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Clinic Contact Number</h4>
          <p>{doctor?.contact}</p>
        </div>

        <div className="user-detail-box">
          <h4>Doctor's Code</h4>
          <p>{doctor?.doctorCode}</p>
        </div>

        <div className="user-detail-box">
          <h4>Email</h4>
          <p>{doctor?.email}</p>
        </div>

        <div className="user-detail-box">
          <h4>Category</h4>
          <p>{doctor?.category.label}</p>
        </div>
      </section>

      <section className="user-detail-container user-detail-container-yellowDark-border">
      <div className="user-detail-box">
          <h4>Clinic's Address</h4>
          <p>{doctor?.address}</p>
      </div>

      <div className="user-detail-box">
          <h4>Extra Info</h4>
          <p>{doctor?.extraInfo}</p>
      </div>
      </section>
    </div>
  );
};

export default AdminViewDCSDetails;
