import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;

  background-color: #25253D;
  position: relative;
}
`;
