import {
    MdOutlineMonetizationOn,
  } from "react-icons/md";
  import { Link } from "react-router-dom";
  
  const AdminLink = ({ title, type, link, className }) => {
    const renderIcon = (type) => {
      if (type === "sales") return <MdOutlineMonetizationOn />;
    };
  
    return (
      <Link className="admin-panel__cards-link" to={`${link}`}>
        <div className={className}>
          {renderIcon(type)}
  
          <h3 className="web-app__heading">{title}</h3>
        </div>
      </Link>
    );
  };
  
  export default AdminLink;
  