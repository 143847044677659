import { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector , } from "react-redux";
import PageTitle from "../../../../../components/PageTitle";
import { BsCheck2Circle, BsXCircle } from "react-icons/bs";
import Table from "../../../../../components/Table";
import serialise from "../../../../../utils/serialiseResponse";
import { RiCloseCircleLine } from "react-icons/ri";
import {MdCheckBoxOutlineBlank} from "react-icons/md";

import customToast from "../../../../../components/CustomToast";
import {ImCheckboxUnchecked, ImCheckboxChecked} from "react-icons/im" ;
import { monthOptions } from "../../../../../utils/helper";
import { approveGift, clearAllotedGifts, rejectGift, viewGiftApproval } from "../../../../../reducers/reports/giftAllotmentReducer";

const ApprovalGifts = () => {
  const dispatch = useDispatch();
  const gifts = useSelector(({giftsAllotment}) => giftsAllotment);
  const [showEdit, setShowEdit] = useState(false);
  const [allSelected, setAllSelected] = useState(false);

  const SelectColumnFilter =  useCallback(
    ({ column, columns }) =>{
      return (
        <div className="search-filter__container" onClick={(e)=>{e.stopPropagation()}}>
          {<span className="d-flex gap-3 align-items-center">{column.render("Header")}
            {allSelected ? (
                <ImCheckboxChecked onClick={(e) => {
                  e.preventDefault();
                  column?.filteredRows?.forEach((el,idx)=>{
                    el.original.isSelected = false ;
                  });
                  setAllSelected(false);
                }}/>
              ):(
                <ImCheckboxUnchecked onClick={(e) => {
                  e.preventDefault();
                  column?.filteredRows?.forEach((el,idx)=>{
                    el.original.isSelected = true ;
                  });
                  setAllSelected(true);
                }}/>
              )
              }
          </span>
          }
        </div>
  );},[allSelected]);

  
  const handleApprove = async (data) => {
    if(data.length === 0)return customToast.error("Please select a City");
    const payload = {ids:data , approvalTyp:"bulk"};
    dispatch(approveGift(data?._id, payload));
    setShowEdit(false);
    setAllSelected(false);
  };
  
  const handleReject = async (data) => {
    if(data.length === 0)return customToast.error("Please select a City");
    const payload = {ids:data , rejectionTyp:"bulk"} ;
    dispatch(rejectGift(data?._id,payload));
    setShowEdit(false);
    setAllSelected(false);
  };
  
  // const tableData = useMemo(()=>serialise(gifts.data), [gifts]) ;
  const tableData = useMemo(() => {
    const data = Array.isArray(gifts.data) ? gifts.data : [];
    return serialise(data);
  }, [gifts]);

  useEffect(()=>{
    dispatch(viewGiftApproval());

    return ()=> dispatch(clearAllotedGifts());
  },[dispatch]);

  const EditPopup = () => {
    const data = [];
    tableData.forEach((el,idx)=>{
      if(el?.isSelected){
        data.push(el);
      }
    })

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Edit Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  Do you want to approve the gift?
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button
                type="button"
                className="button-submit-green mt-4"
                onClick={() => handleApprove(data)}
              >
                <span className="button-submit-green__icon-container">
                  <BsCheck2Circle className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Approve</span>
              </button>

              <button
                type="button"
                className="button-delete mt-4"
                onClick={() => handleReject(data)}
              >
                <span className="button-delete__icon-container">
                  <BsXCircle className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Reject</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };



  const columns = useMemo(
    () =>[
        // {
        //     Header: "Sr. No",
        //     accessor: "",
        //     Cell:(props) =>{
        //       return props?.row?.index + 1;
        //     },
        //     minWidth: 40,
        //     maxWidth: 40,
        // },
        {
          Header:"Alloted To",
          accessor:"user.firstName",
          minWidth: 40,
          maxWidth: 40,
        },
        {
            Header: "Month",
            accessor: "month",
            Cell: (props) =>{
              let x = props?.row?.original?.month;
              let selectedValue = monthOptions.find(it => it?.value === x);
              let selectedlabel = selectedValue?.label ? selectedValue?.label : "";
              return selectedlabel;

            },
            minWidth: 40,
            maxWidth: 40,
        },
        {
            Header: "Year",
            accessor: "year",
            minWidth: 40,
            maxWidth: 40,
        },
        {
            Header: "Gift",
            accessor: "productName",
            minWidth: 40,
            maxWidth: 40,
        },
        {
            Header: "Alloted Qty",
            accessor: "issued",
            minWidth: 40,
            maxWidth: 40,
        },
        {
            Header: "Amount",
            accessor: "amount",
            minWidth: 40,
            maxWidth: 40,
        },
        {
            Header: "Select",
            accessor: "none",
            Cell: (props) => {
              const [local,setLocal] = useState(props?.row?.original?.isSelected || false);
              return (
                <span
                  className="react-table-view-link display-6"
                >    
                  {local ? (
                    <ImCheckboxChecked onClick={() => {
                      props.row.original.isSelected = false ;
                      setLocal(false); 
                    }}/>
                  ):(
                    <MdCheckBoxOutlineBlank onClick={() => {
                      props.row.original.isSelected = true ;
                      setLocal(true);
                    }}/>
                  )
                  }
                </span>
              );
            },
            minWidth: 80,
            maxWidth: 80,
            disableFilters: false,
            disableSortBy: true,
            Filter:SelectColumnFilter
          },


  ],
  [SelectColumnFilter,tableData]);

 

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Approve Gifts" />

        <section className="area-creation-content__info">
        <div className="d-flex justify-content-end gap-5 pe-2">
            <button
              type="submit"
              className="button-submit-green"
              onClick={(e) => {
                e.preventDefault();
                setShowEdit(true);
              }}
            >
              <span className="button-submit-green__icon-container">
                <BsCheck2Circle className="button-submit-green__icon" />
              </span>
              <span className="button-submit-green__text">Actions</span>
            </button>
          </div>
        <h2 className="web-app__heading mb-4 ms-2">
              Showing ({tableData.length}) Entries
            </h2>
          <div className="filter-table">
            <Table columns={columns} data={tableData} />
          </div>
          {showEdit ? <EditPopup /> : null}
        </section>
      </div>
    </div>
  );
};

export default ApprovalGifts;
