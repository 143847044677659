import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { loginSuperAdmin } from "../../../reducers/userReducer";

import {
  SectionLogin,
  BtnBlue,
  FormInput,
  FormLabel,
  LoginCheckbox,
  LoginLeft,
  LoginRight,
  LoginForm,
  FormGroup,
  FormRemember,
  LoginImgInner,
  LoginImgOuter,
} from "../../public/stylesheets/authentication.js";
import InnerDots from "../../public/img/inner-dots.png";
import OuterDots from "../../public/img/outer-dots.png";
import customToast from "../../../components/CustomToast";
import { ToastContainer } from "react-toastify";

const SALogin = ({ loggedUser }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (loggedUser) navigate("/dashboard");
  }, [loggedUser, navigate]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();

    if (email && password) {
      let error = null;

      try {
        await dispatch(loginSuperAdmin({ email, password }, remember));
      } catch (e) {
        error = e;
        customToast.error("Invalid Email or Password");
      }

      if (!error) {
        let from = location.state?.from?.pathname || "/";
        navigate(from, { replace: true });

        customToast.success("Successfully Logged In");
      }
    }
  };

  return (
    <main>
      <SectionLogin>
        <div className="container">
          <div className="row">
            <LoginLeft className="col-md-6">
              <h1>Medorn</h1>
              <h2>Log In Page</h2>
              <p>Welcome to the Super Admin Login Page</p>

              <LoginImgInner src={InnerDots} alt="Inner Dots" />
            </LoginLeft>

            <LoginRight className="col-md-6">
              <h2>Medorn</h2>
              <p>
                This is the login page for the employees of the organization.
              </p>
              <LoginForm onSubmit={(e) => handleLogin(e)}>
                <FormGroup>
                  <FormLabel htmlFor="email">Email Address</FormLabel>
                  <br />
                  <FormInput
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email Address"
                    value={(email).toLocaleLowerCase()}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>

                <FormGroup>
                  <FormLabel htmlFor="email" className="form__label">
                    Password
                  </FormLabel>
                  <br />
                  <FormInput
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    className="form__input"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormGroup>

                <LoginCheckbox>
                  <span>
                    <FormRemember
                      type="checkbox"
                      name="remember-me"
                      id="remember-me"
                      checked={remember}
                      onChange={(e) => setRemember(e.target.checked)}
                    />
                    <p>Remember Me</p>
                  </span>
                  <Link to="/request-password">Forgot Password?</Link>
                </LoginCheckbox>

                <BtnBlue type="submit" className="mt-5">
                  Sign In
                </BtnBlue>

                {/* <FormEnd>
									Don't have an account?{' '}
									<LoginEndLink href="register">Sign Up</LoginEndLink>
								</FormEnd> */}
              </LoginForm>
            </LoginRight>
          </div>
        </div>
      </SectionLogin>
      <LoginImgOuter src={OuterDots} alt="Dots" />

      <ToastContainer />
    </main>
  );
};

export default SALogin;
