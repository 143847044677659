import { ResponsiveBar } from "@nivo/bar";
import { useEffect, useState } from "react";
import { getReports } from "../../../../services/dashboard";

const ReportsGraph = ({ month, year, selectedUser }) => {
  const [noOfDocReport, setnoOfDocReport] = useState(0);
  const [noOfChemReport, setnoOfChemReport] = useState(0);
  const [noOfStkReport, setnoOfStkReport] = useState(0);
  const [maxReportNumber, setMaxReportNumber] = useState(10);

  const themeObject = {
    textColor: "#ddd",
    axis: {
      ticks: {
        line: {
          stroke: "#fff",
        },
        text: {
          fill: "#bbb",
        },
      },
    },
    grid: {
      line: {
        // stroke: '#C7C4E9',
        stroke: "#373851",
        strokeWidth: 2,
      },
    },
    labels: {
      text: {
        fill: "#fff",
      },
    },
    crosshair: {
      line: {
        stroke: "#fff",
        strokeWidth: 1.5,
        strokeOpacity: 0.35,
      },
    },
  };

  const tooltipStyle = {
    padding: "0.5rem 1.2rem",
    backgroundColor: "#353456",
    color: "#fff",
    fontSize: "1.2rem",

    borderRadius: "5px",
  };

  useEffect(() => {
    const asyncFetch = async () => {
      let data = {
        month,
        year,
        selectedUser,
      };
      const response = await getReports(data);

      let arr = Object.values(response.data);
      setMaxReportNumber(Math.max(...arr));

      setnoOfDocReport(response.data?.doctor);
      setnoOfChemReport(response.data?.chemist);
      setnoOfStkReport(response.data?.stockist);
    };

    asyncFetch();
  }, [month, year, selectedUser]);

  const numberOfReports = [
    { type: "Doctor", value: noOfDocReport },
    { type: "Chemist", value: noOfChemReport },
    { type: "Stockist", value: noOfStkReport },
  ];

  return (
    <ResponsiveBar
      data={numberOfReports}
      keys={["value"]}
      indexBy="type"
      margin={{ top: 50, right: 0, bottom: 35, left: 25 }}
      maxValue={maxReportNumber}
      padding={0.45}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: 4,
      }}
      enableLabel={false}
      role="application"
      theme={themeObject}
      colors={["#36ABF9cd", "#2ecc71cd", "#E67E22cd"]}
      colorBy="index"
      tooltip={(input) => (
        <div style={tooltipStyle}>
          <span>Reports</span>
          <br />
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <strong>{input.data.value}
            </strong>
          </span>
        </div>
      )}
    />
  );
};

export default ReportsGraph;
