import { MdArrowBackIosNew, MdOutlineGroup } from "react-icons/md";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-select";
import { monthOptions, yearOptions } from "../../../../utils/helper";
import {
  dcrStyles,
  selectUserStyles,
} from "../../../public/stylesheets/selectStyles";
import { useCallback, useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { generateSelectData, generateSelectUser } from "../../../../utils/serialiseResponse";
import { viewUsersForUtilities } from "../../../../services/utilities";
import Table from "../../../../components/Table";
import { viewProdReports, viewUserWiseProdReports } from "../../../../services/analytics";
import { GiUpgrade } from "react-icons/gi";
import serialise from "../../../../utils/serialiseResponse";
import customToast from "../../../../components/CustomToast";

const ProdWiseSales = () => {
  const navigate = useNavigate();
  const loggedIn = useSelector(({ user }) => user);

  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [month, setMonth] = useState();
  const [year, setYear] = useState(yearOptions[yearOptions.length-1]);
  const [emonth, setEmonth] = useState();
  const [eyear, setEyear] = useState();
  const [loading,setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [type , setType] = useState({label:"Primary Vs Secondary"});
  const [advance, setAdvance] = useState(false);
  const typeOptions = [{label:"Primary Vs Secondary"},{label:"User"} , {label:"Primary Sales"} , {label:"Secondary Sales"}] ;
  const [employeeDataForExcel, setEmployeeDataForExcel] = useState();


  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
  const employeeData = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );

  useEffect(() => {
    try {
      let tempdata = employeeData.filter((empData) => {
        if (empData.label === selectedUser.label) return true;
      })
      setEmployeeDataForExcel(tempdata[0]);
    } catch (error) {
      console.log(error);
    }
  }, [selectedUser]);

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   minWidth:50,
      //   maxWidth:50,
      //   disableFilters: true,
      // },
      {
        Header: "Product",
        accessor: "product",
        showTotal:true,
        placeholderTotal:"Total"
      },
      {
        Header: "Primary Qty",
        accessor: "primaryQuantity",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"primaryQuantity"
      },
      {
        Header: "Primary Value",
        accessor: "primaryValue",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"primaryValue",
        Cell:(props) => {
          return <div>
            {`₹ ${Number(props?.row?.original?.primaryValue).toFixed(2)}`}
          </div>
        }
      },
      {
        Header: "Free Stocks",
        accessor: "freeSPQuant",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"freeSPQuant"
      },
      {
        Header: "Secondary Qty",
        accessor: "secondaryQuantity",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"secondaryQuantity"
      },
      {
        Header: "Secondary Value",
        accessor: "secondaryValue",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"secondaryValue",
        Cell:(props) => {
          return <div>
            {`₹ ${Number(props?.row?.original?.secondaryValue).toFixed(2)}`}
          </div>
        }
      },
      {
        Header: "Free Stocks",
        accessor: "freeSSQuant",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"freeSSQuant"
      },
      {
        Header: "Closing Quantity",
        accessor: "closingQuantity",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"closingQuantity"
      },
    ],
    []
  );
  const columnsUser = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   minWidth:50,
      //   disableFilters: true,
      //   maxWidth:50
      // },
      {
        Header: "Employee Name",
        accessor: "user",
        disableSortBy: true,
        showTotal:true,
        placeholderTotal:"Total"
      },
      {
        Header: "Product",
        accessor: "product",
        showTotal:true,
        placeholderTotal:"Total"
      },
      {
        Header: "Primary Qty",
        accessor: "primaryQuantity",
        showTotal:true,
        disableFilters: true,
        totalAccessor:"primaryQuantity"
      },
      {
        Header: "Primary Value",
        accessor: "primaryValue",
        showTotal:true,
        disableFilters: true,
        totalAccessor:"primaryValue"
      },
      {
        Header: "Secondary Qty",
        accessor: "secondaryQuantity",
        showTotal:true,
        disableFilters: true,
        totalAccessor:"secondaryQuantity"
      },
      {
        Header: "Secondary Value",
        accessor: "secondaryValue",
        showTotal:true,
        disableFilters: true,
        totalAccessor:"secondaryValue",
        Cell:(props) => {
          return <div>
            {Number(props?.row?.original?.secondaryValue).toFixed(2)}
          </div>
        }
      },
    ],
    []
  );
  const handleSubmit = useCallback(
    
    async (e) => {
      e.preventDefault();
      
      if(loading)return ;
      if(!month || !year)return customToast.error("Please select month and year");
      if(advance && (!eyear || !emonth))return customToast.error("Please select end month and year")
      if(type.label === "User" && !selectedUser && loggedIn?.user?.des !== 121)return customToast.error("Please select a User");
      setLoading(true);
      let data = {
        month: month?.value,
        year: year?.label,
        advance,
        endYear:eyear?.label,
        endMonth:emonth?.value
      };
      if(type.label === "Primary Vs Secondary")
        await viewProdReports(data).then((res) => setReports(res.data)).finally(()=> setLoading(false));
      else if(type.label === "User")
        if(loggedIn?.user?.des === 121)
        {
          await viewUserWiseProdReports({...data , id:loggedIn?.user?._id})
        .then((res) => {
          
          setReports(res.data)
        }).catch(err=>setLoading(false));
      setLoading(false);
        }
        else{
          await viewUserWiseProdReports({...data , id:selectedUser.value})
          .then((res) => {
            
            setReports(res.data)
          }).catch(err=>setLoading(false));
        setLoading(false);
        }
    },
    [month, year,loading,selectedUser,type,eyear,emonth,advance,loggedIn]
  );

  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
  const tableData = useMemo(() => serialise(reports), [reports]);
  return (
    <div className="expense">
      <div className="row">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MdArrowBackIosNew
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span style={{ display: "inline-block", margin: "0 1rem" }}>
            Product Wise Sales
          </span>
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="util-tp-query d-flex justify-content-between flex-wrap gap-4">
            <div className="d-flex gap-2">
              <div className="util-tp-filter me-3">
                <p>
                  Select Type <span style={{ color: "#e74c3caa" }}>*</span>
                </p>
                <Select
                  styles={dcrStyles}
                  placeholder="Select Type"
                  className="mt-3"
                  options={typeOptions}
                  value={type}
                  onChange={(e) => {
                    if(e.label === "Primary Sales")navigate("./primary-sales")
                    else if(e.label === "Secondary Sales") navigate("./secondary-sales")
                    else setType({...e})
                  }}
                />
              </div>
              <div className="util-tp-filter me-3">
                <p>
                  {advance ? "Select Start Month" : "Select Month"} <span className="asterisk-imp">*</span>
                </p>
                <Select
                  styles={dcrStyles}
                  placeholder="Select Month"
                  className="mt-3"
                  options={monthOptions}
                  value={month}
                  onChange={(e) => setMonth({ ...e })}
                />
              </div>

              <div className="util-tp-filter me-3">
                <p>
                  {advance ? "Select Start Year" : "Select Year"} <span className="asterisk-imp">*</span>
                </p>
                <Select
                  styles={dcrStyles}
                  placeholder="Select Year"
                  className="mt-3"
                  options={yearOptions}
                  value={year}
                  onChange={(e) => setYear({ ...e })}
                />
              </div>
              
            </div>
            {advance && <div className="d-flex gap-2">
              
              <div className="util-tp-filter me-3">
                <p>
                  Select End Month <span className="asterisk-imp">*</span>
                </p>
                <Select
                  styles={dcrStyles}
                  placeholder="Select Month"
                  className="mt-3"
                  options={monthOptions}
                  value={emonth}
                  onChange={(e) => setEmonth({ ...e })}
                />
              </div>

              <div className="util-tp-filter me-3">
                <p>
                  Select End Year <span className="asterisk-imp">*</span>
                </p>
                <Select
                  styles={dcrStyles}
                  placeholder="Select Year"
                  className="mt-3"
                  options={yearOptions}
                  value={eyear}
                  onChange={(e) => setEyear({ ...e })}
                />
              </div>
              
            </div>}
            <div className="d-flex gap-5">
            {(loggedIn?.user?.des !== 121 ) && type.label === "User" ? (
              <div className="util-tp-filter me-3">
                <p className="util-select-user__label">Select User</p>
                <div className="util-select-user">
                  <MdOutlineGroup className="util-select-user__icon" />
                  <Select
                    styles={selectUserStyles}
                    options={employeeSelect}
                    placeholder="Select User"
                    className="mt-3"
                    value={selectedUser}
                    onChange={(e) => setSelectedUser({ ...e })}
                  />
                </div>
              </div>
            ) : null}

            
            </div>
            <div className="util-tp-filter">
              <GiUpgrade onClick={()=> setAdvance(!advance)} className="me-3 d-inline icon-color-tertiary cursor-pointer" />
              <button type="submit" className="button-blue-gradient mt-5 d-inline">
                See Reports
              </button>
            </div>
          </div>
          
        </form>

        <div className="filter-table">
          { loading && <h3 className="mt-2 fetching-results-message">Hold on fetching reports for you...</h3>}
          {tableData.length > 0 && (
            <>
              <h2 className="web-app__heading mb-4 ps-3 d-flex justify-content-between">
                  Showing ({reports.length}) Entries
              </h2>
              {type.label === "Primary Vs Secondary" && <Table columns={columns} data={tableData} fileSrc="Product wise sales (Primary vs Secondary)" />}
              {type.label === "User" && <Table columns={columnsUser} data={tableData} empl={selectedUser ? employeeDataForExcel : loggedIn?.user} month={month} fileSrc="Product wise sales (User)" />}
            </>
            ) 
          }
          {
          !loading && tableData.length === 0 && (<h3 className="mt-2 no-results-message">No results found</h3>)
          }
        </div>
      </div>
    </div>
  );
};

export default ProdWiseSales;
