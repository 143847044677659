import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import customToast from "../../../../components/CustomToast";

import {
  addProductType,
  clearProductTypes,
  deleteProductType,
  editProductType,
  setProductTypes,
} from "../../../../reducers/products/typeReducer";
import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";
import serialise from "../../../../utils/serialiseResponse";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { RiCloseCircleLine, RiEdit2Fill } from "react-icons/ri";

const AdminCategory = () => {
  const dispatch = useDispatch();
  const types = useSelector(({ productType }) => productType);

  const [type, setType] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [prodData, setProdData] = useState();

  const EditPopup = ({ data }) => {
    const dispatch = useDispatch();
    const [type, setType] = useState("");

    let updatedData = { ...data, name: type };
    const handleEdit = async (e) => {
      try {
        e.preventDefault();
        await dispatch(editProductType(updatedData?._id, updatedData));
        setShowEdit(false) 
        customToast.success("Edited details successfully !");
      } catch (error) {
        setShowEdit(false) 
        customToast.error(error?.response?.data?.data || "Something went wrong !");
      }
    };

    const handleDelete = () => {
      dispatch(deleteProductType(updatedData?._id));
      setShowEdit(false);
    };

    useEffect(() => {
      setType(data.name);
    }, [data]);

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Edit Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form onSubmit={handleEdit}>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  Enter Division <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="division"
                  className="me-5"
                  placeholder="Enter Division"
                  value={type}
                  onChange={({ target }) => setType(target.value)}
                />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-submit-green mt-4">
                <span className="button-submit-green__icon-container">
                  <MdOutlineEdit className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Edit</span>
              </button>

              <button
                type="submit"
                className="button-delete mt-4"
                onClick={() => handleDelete()}
              >
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  useEffect(() => {
    dispatch(setProductTypes());

    return ()=> {dispatch(clearProductTypes());}
  }, [dispatch]);

  const setEditForm = (data) => {
    setShowEdit(false);
    setType(data?.name);
    setProdData(data);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Product Type",
        accessor: "name",
      },
      {
        Header: "UID",
        accessor: "uid",
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: (props) => {
          return (
            <span
              className="react-table-view-link"
              onClick={() => {
                setEditForm(props.row.original);
                setShowEdit(true);
              }}
            >
            <RiEdit2Fill className="icon-color-green" />

            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!type) return customToast.error("Please Enter Product Type");
    var index = tableData.findIndex(p => (p.name).toLocaleLowerCase() == type.toLocaleLowerCase());
    
 
  if(index === -1){
    let error = false;
    try {
      await dispatch(addProductType({ name: type }));
    } catch (e) {
      error = true;
      customToast.error("We Encountered an Error");
    }

    if (!error) {
      customToast.success("Product Type Successfully Created");
      setType("");
    }
  }
  else{
    customToast.error("Product Type Already Exists");
  }
    
  };
  const tableData = useMemo(() => serialise(types.data), [types]);

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Create Product Type" />

        <section className="area-creation-content__form">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-8">
                <label htmlFor="prod-type">
                  Product Type <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="prod-type"
                  placeholder="Enter Product Type"
                  value={type}
                  onChange={({ target }) => setType(target.value)}
                />
              </div>

              <div className="col-sm-6 col-md-6 col-lg-4 admin-creation-content__submit">
                <button type="submit" className="button-blue-gradient">
                  Add Product Type
                </button>
              </div>
            </div>
          </form>
        </section>

        <section className="area-creation-content__info">
          <h2 className="web-app__heading">Present Types</h2>
          <div className="filter-table">
            <Table columns={columns} data={tableData} />
          </div>
          {showEdit ? <EditPopup data={prodData} /> : null}
        </section>
      </div>
    </main>
  );
};

export default AdminCategory;
