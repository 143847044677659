import {
  deleteUserInfo,
  deleteUserInfoV1,
  modifyUserInfo,
  modifyUserInfoV1,
  newDivision,
  newDivisionV1,
  viewDivisions,
  viewDivisionsV1,
} from "../../services/users.js";

const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DIVISIONS":
      return action.payload.data;

    case "CREATE_DIVISION":
      return [...state, action.payload.data];

    case "CLEAR_DIVISION":
      return initialState;

    case "EDIT_DIVISION":
      return [...state];

    case "DELETE_DIVISION":
      return [...state];

    default:
      return state;
  }
};

export const setDivisions = () => {
  return async (dispatch) => {
    // const { data } = await viewDivisions();
    const {data} = await viewDivisionsV1();

    dispatch({
      type: "SET_DIVISIONS",
      payload: { data: data },
    });
  };
};

export const addDivision = (data) => {
  return async (dispatch) => {
    let response;

    try {
      // response = await newDivision(data);
      response = await newDivisionV1(data);
    } catch (e) {
      if (response.error) throw response.error;
    }

    dispatch({
      type: "CREATE_DIVISION",
      payload: { data: response },
    });
  };
};

export const clearDivisions = () => {
  return {
    type: "CLEAR_DIVISION",
  };
};

export const editDivision = (id, data) => {
  return async (dispatch) => {
    try {
      // data = await modifyUserInfo("division", id, data);
      data = await modifyUserInfoV1("division", id, data);
    } catch (e) {
      throw e;
    }

    dispatch({
      type: "EDIT_DIVISION",
      payload: { data: data },
    });
    dispatch(setDivisions());
  };
};

export const deleteDivision = (data) => {
  return async (dispatch) => {
    try {
      // data = await deleteUserInfo("division", data);
      data = await deleteUserInfoV1("division", data);
    } catch (e) {
      if (data.error) throw data.error;
    }
    dispatch({
      type: "DELETE_DIVISION",
      payload: { data: data },
    });
    dispatch(setDivisions());
  };
};

export default reducer;
