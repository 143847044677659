import { MdArrowBackIosNew, MdOutlineGroup } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import react,{ useMemo } from "react";
import serialise from "../../../../../../utils/serialiseResponse";
import Table from "../../../../../../components/Table";
import { FaEye } from "react-icons/fa";

const HeadquarterSS = ({reports , month , year}) => {
  
  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   minWidth:50,
      //   maxWidth:50,
      //   disableFilters:true,
      // },
      {
        Header: "Headquarter",
        accessor: "name",
        disableSortBy: true,
        showTotal:true,
        placeholderTotal:"Total",
      },
      {
        Header: "Total Secondary Sales",
        accessor: "salesValue",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"salesValue",
        Cell:(props) => {
          return <div>
            {`₹ ${Number(props?.row?.original?.salesValue).toFixed(2)}`}
          </div>
        }
      },
      {
        Header: "View",
        accessor: "details",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          
          return (
            <Link
              to={"./stk"}
              // to={`./${props?.row?.original?._id}`}
              state={{ data: props?.row?.original?._id, month:month , year:year }}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    [month,year]
  );
  const tableData = useMemo(() => serialise(reports), [reports]);
  
  return (
    <div className="filter-table">
        {reports.length > 0 && (
            <h2 className="web-app__heading mb-4 ps-3">
                Showing ({reports.length}) Entries
            </h2>
        )}
        {tableData.length > 0 ? (
            <Table columns={columns} data={tableData} fileSrc="Secondary Sales Reports (Headquarter)" />
        ) : (
            <h3 className="mt-2 no-results-message">No results found</h3>
        )}
    </div> 
  );
};

export default HeadquarterSS ;