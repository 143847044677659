import { viewHeadquarterSS , viewSTKWiseSales } from "../../services/utilities";
import { addDays } from "date-fns";

const initialState = {
  HQSalesData: [],
  STKSalesData :[],
  message:"",
  dateRange:{
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  },
  type:""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DATE_RANGE":
      return {...state , dateRange: action.payload} ;
    case "SET_TYPE":
      return {...state , type: action.payload} ;
    default:
      return state;
  }
};
export const setDateRangePSR = (payload)=>{
    return (dispatch)=>{
        dispatch({
            type:"SET_DATE_RANGE",
            payload:payload
        });
    }
}
export const setTypePSR = (payload)=>{
    return (dispatch)=>{
        dispatch({
            type:"SET_TYPE",
            payload:payload
        });
    }
}
export const setHeadquarterSales = (payload) => {
  return async (dispatch) => {
    try {
      const data  = await viewHeadquarterSS(payload);
      dispatch({
        type: "SET_HEADQUARTER_SALES",
        payload: data.data,
      });
      dispatch({
        type:"SET_MESSAGE",
        payload:data.message
      })
    } catch (err) {
      dispatch({
        type: "SET_MESSAGE",
        payload: err.response.data.message,
      });
    }
  };
};
export const setStockistSales = (payload) =>{
    return async(dispatch)=>{
        try {
            const data  = await viewSTKWiseSales(payload);
            dispatch({
              type: "SET_STOCKIST_SALES",
              payload: data.data,
            });
            dispatch({
              type:"SET_MESSAGE",
              payload:data.message
            })
        } catch (err) {
        dispatch({
            type: "SET_MESSAGE",
            payload: err.response.data.message,
        });
        }
    }
}

export default reducer;
