import { newProduct, viewProducts } from "../../services/products.js";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PRODUCT_GROUP":
      return action.payload;

    case "CREATE_PRODUCT_GROUP":
      return { data: [...state.data, action.payload.data.data] };

    case "CLEAR_PRODUCT_GROUP":
      return initialState;

    default:
      return state;
  }
};

export const setProductGroups = (filters) => {
  return async (dispatch) => {
    const { data } = await viewProducts("group", filters);

    dispatch({
      type: "SET_PRODUCT_GROUP",
      payload: { data: data },
    });
  };
};

export const addProductGroup = (data) => {
  return async (dispatch) => {
    try {
      data = await newProduct("group", data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "CREATE_PRODUCT_GROUP",
      payload: { data: data },
    });
  };
};

export const clearProductGroups = () => {
  return {
    type: "CLEAR_PRODUCT_GROUP",
  };
};

export default reducer;
