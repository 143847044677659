import { useLocation,useNavigate } from "react-router-dom";

import {  RiCapsuleFill, RiUser3Fill, RiGiftFill, RiNurseFill } from "react-icons/ri";
import { MdArrowBackIosNew } from "react-icons/md";
import { formatDate,formatTime, monthOptions } from "../../../../../../utils/helper";
import { useEffect, useState } from "react";
import { GoogleMap, Marker, Polyline, useJsApiLoader } from "@react-google-maps/api";
import Accordion from "react-bootstrap/Accordion";
import { viewGeoLocationData } from "../../../../../../services/geoFencing";
import DocMarker from "../../../../../public/img/DocMarker.png";
import ChemMarker from "../../../../../public/img/ChemMarker.png";
import StkMarker from "../../../../../public/img/StockMarker.png";
import userLogo from "../../../../../public/img/user-logo.png";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Lightbox from "yet-another-react-lightbox";

const AdminCallReportDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const images = [];
  const [geoLocReports, setGeoLocReports] = useState([]);
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_API_KEY
  });

  const containerStyle = {
    width: '100%',
    height: '300px',
    position: 'relative'
  };

  useEffect(() => {
    const fetchData = async() => {
      try {
        let payload = {
          month: location?.state?.month,
          year: location?.state?.year,
          userId: location?.state?.submitter?._id,
          date: location?.state?.date
        };
  
        let res = await viewGeoLocationData(payload);
        setGeoLocReports(res?.data);
      } catch (error) {
        console.log(error)
      }
    };
    fetchData();
  },[]);

  const ViewMap = ({longitude, latitude, latitude2, longitude2, type}) => {

    return isLoaded ? (
    <div style={{ width: '100%', height: '300px' }}> 
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{lat: latitude, lng: longitude}}
      zoom={16}
    >
      <Marker 
        position={{lat: latitude,  lng: longitude}}
        icon={{
          url: userLogo,
          scaledSize: { width: 40, height: 40 }
        }}
      />

      {latitude && longitude && latitude2 && longitude2 && (
        <div>
          <Marker
          position={{lat: latitude2, lng:longitude2}}
          icon={{
            url: type === "doc" ? DocMarker : type === 'stk' ? StkMarker : ChemMarker,
            scaledSize: { width: 30, height: 40 }
          }}
          />
          <Polyline
          path={[{lat:latitude, lng:longitude},{lat:latitude2, lng:longitude2}]}
          options={{ strokeColor: "red", strokeOpacity: 1.0, strokeWeight: 2 }}/>
        </div>
      )}

    </GoogleMap>
  </div>
) : null;
  }

  location?.state?.doctors?.length > 0 && location?.state?.doctors?.map((item) => (
    item.files?.length > 0 && item.files?.map((pic) => (
      images.push({ name: item.target.name, pic: pic })
    ))
  ))
  location?.state?.chemists?.length > 0 && location?.state?.chemists?.map((item) => (
    item.files?.length > 0 && item.files?.map((pic) => (
      images.push({ name: item.target.name, pic: pic })

    ))
  ))
  location?.state?.stockists?.length > 0 && location?.state?.stockists?.map((item) => (
    item.files?.length > 0 && item.files?.map((pic) => (
      images.push({ name: item.target.name, pic: pic })

    ))
  ))

  return (
    <div className="utility-inner-tp">
      <div className="row">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MdArrowBackIosNew
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span style={{ display: "inline-block", margin: "0 1rem" }}>
            Call Details
          </span>
        </h2>

        <div className="util-inner-details">
          <div className="util-inner-info-box-container">
            <div className="util-inner-info-box">
              <h3>Date</h3>
              <p>{formatDate(location?.state?.date)}</p>
            </div>

            <div className="util-inner-info-box">
              <h3>Month</h3>
              <p>
                {
                  monthOptions[new Date(`${location?.state?.date}`).getMonth()]
                    ?.label
                }
              </p>
            </div>
            <div className="util-inner-info-box">
              <h3>Area Type</h3>
              <p>
                {
                  location?.state?.areaType
                }
              </p>
            </div>
            <div className="util-inner-info-box">
              <h3>Work Areas</h3>
              <p>
                {
                  location?.state?.workAreas.map((el)=>el?.location?.label)
                }
              </p>
            </div>
          
            
          </div>
        </div>


        <div className="dcs-call-wrapper">
        <div className="w-[22rem]">
          <div className="doctor-call-div">
            <h2 className="web-app__heading mt-5">
              {location?.state?.doctors.length > 0 ? "Doctor Calls" : null}
            </h2>

            <Accordion className="mt-4" alwaysOpen>
              {location?.state.doctors.map((e, i) => (
                <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                  <Accordion.Header>
                    {" "}
                    <span className="d-flex align-items-center">
                      {<RiNurseFill className="me-3 fs-2" />} {e?.target?.name}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <h5 className="web-app__heading-blue">
                      {e?.products.length > 0 ? "POB DETAILS" : null}
                    </h5>
                    {e?.products.map((elem, i) => (
                      <div key={i} className="acc-body-card d-flex mt-4">
                        <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                        <div className="acc-body-card-content d-flex align-items-center px-4">
                          {elem?.productName} ---{" "}
                          {elem.quantity ? elem.quantity : 0} --- ₹
                          {elem.amount ? elem.amount : 0} | Sample:{" "}
                          {elem.sampleQuantity ? elem.sampleQuantity : 0}
                        </div>
                      </div>
                    ))}

                    <h5 className="web-app__heading-blue mt-5">
                      {e?.gifts.length > 0 ? "Gift Details" : null}
                    </h5>
                    {e?.gifts.map((elem, i) => (
                      <div key={i} className="acc-body-card d-flex mt-4">
                        <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                        <div className="acc-body-card-content d-flex align-items-center px-4">
                          {elem?.giftName} ---
                          {elem.giftQuantity ? elem.giftQuantity : 0} ---- ₹
                          {elem.giftAmount ? elem.giftAmount : 0}
                        </div>
                      </div>
                    ))}

                    <h5 className="web-app__heading-blue mt-5">
                      {e?.workedWith.length > 0 ? "Worked With" : null}
                    </h5>
                    {e?.workedWith.map((elem, i) => (
                      <div key={i} className="acc-body-card d-flex mt-4">
                        <RiUser3Fill className="acc-body-card-icon acc-i-light-yellow" />
                        <div className="acc-body-card-content d-flex align-items-center px-4">
                          {elem?.firstName} {elem?.lastName} 
                          {/* ({elem?.designation?.name}) */}
                        </div>
                      </div>
                    ))}

                    {e.remarks ? (
                      <>
                        <h5 className="web-app__heading-blue mt-5">Remarks</h5>
                        <h3 className="acc-body-remarks">{e?.remarks}</h3>
                      </>
                    ) : null}
                    <h5 className="web-app__heading-blue mt-5">Report Time</h5>
                        <h3 className="acc-body-remarks">{formatTime(e?.cdt)}</h3>
    
                        {!(location?.state?.isBacklog) && geoLocReports && geoLocReports?.length > 0 && (() => {
                          const element = geoLocReports?.find((item) => item?.target?.value === e?.target?._id);
                          return element ? 
                          <div>
                            <h5 className="web-app__heading-blue mt-5">Geo-tagging</h5>
                            <ViewMap latitude={element?.geolocation?.latitude} longitude={element?.geolocation?.longitude} latitude2={e?.target?.geolocation?.latitude} longitude2={e?.target?.geolocation?.longitude} type={e?.type}/>
                          </div>
                          :
                          null
                        })
                        ()
                        }
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
          <div className="chemist-call-div">
            <h2 className="web-app__heading report-heading mt-5">
              {location?.state?.chemists.length > 0 ? "Chemist Calls" : null}
            </h2>
            <Accordion className="mt-4" alwaysOpen>
              {location?.state.chemists.map((e, i) => (
                <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                  <Accordion.Header>
                    {" "}
                    <span className="d-flex align-items-center">
                      {<RiNurseFill className="me-3 fs-2" />} {e?.target?.businessName}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <h5 className="web-app__heading-blue">
                      {e?.products.length > 0 ? "POB DETAILS" : null}
                    </h5>
                    {e?.products.map((elem, i) => (
                      <div key={i} className="acc-body-card d-flex mt-4">
                        <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                        <div className="acc-body-card-content d-flex align-items-center px-4">
                          {elem?.productName} ---{" "}
                          {elem.quantity ? elem.quantity : 0} --- ₹
                          {elem.amount ? elem.amount : 0} | Sample:{" "}
                          {elem.sampleQuantity ? elem.sampleQuantity : 0}
                        </div>
                      </div>
                    ))}

                    <h5 className="web-app__heading-blue mt-5">
                      {e?.gifts.length > 0 ? "Gift Details" : null}
                    </h5>
                    {e?.gifts.map((elem, i) => (
                      <div key={i} className="acc-body-card d-flex mt-4">
                        <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                        <div className="acc-body-card-content d-flex align-items-center px-4">
                          {elem?.giftName} ---
                          {elem.giftQuantity ? elem.giftQuantity : 0} ---- ₹
                          {elem.giftAmount ? elem.giftAmount : 0}
                        </div>
                      </div>
                    ))}

                    <h5 className="web-app__heading-blue mt-5">
                      {e?.workedWith.length > 0 ? "Worked With" : null}
                    </h5>
                    {e?.workedWith.map((elem, i) => (
                      <div key={i} className="acc-body-card d-flex mt-4">
                        <RiUser3Fill className="acc-body-card-icon acc-i-light-yellow" />
                        <div className="acc-body-card-content d-flex align-items-center px-4">
                          {elem?.firstName} {elem?.lastName}
                          {/* ({elem?.designation?.name}) */}
                        </div>
                      </div>
                    ))}

                    {e.remarks ? (
                      <>
                        <h5 className="web-app__heading-blue mt-5">Remarks</h5>
                        <h3 className="acc-body-remarks">{e?.remarks}</h3>
                      </>
                    ) : null}
                    <h5 className="web-app__heading-blue mt-5">Report Time</h5>
                    <h3 className="acc-body-remarks">{formatTime(e?.cdt)}</h3>

                    {!(location?.state?.isBacklog) && geoLocReports && geoLocReports?.length > 0 && (() => {
                      const element = geoLocReports?.find((item) => item?.target?.value === e?.target?._id);
                      return element ? 
                      <div>
                        <h5 className="web-app__heading-blue mt-5">Geo-tagging</h5>
                        <ViewMap latitude={element?.geolocation?.latitude} longitude={element?.geolocation?.longitude} latitude2={e?.target?.geolocation?.latitude} longitude2={e?.target?.geolocation?.longitude} type={e?.type}/>
                      </div>
                      :
                      null
                    })
                    ()
                    }
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
          <div className="stockist-call-div">
            <h2 className="web-app__heading mt-5">
              {location?.state?.stockists.length > 0 ? "Stockist Calls" : null}
            </h2>
            <Accordion className="mt-4" alwaysOpen>
              {location?.state.stockists.map((e, i) => (
                <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                  <Accordion.Header>
                    {" "}
                    <span className="d-flex align-items-center">
                      {<RiNurseFill className="me-3 fs-2" />} {e?.target?.businessName}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <h5 className="web-app__heading-blue">
                      {e?.products.length > 0 ? "POB DETAILS" : null}
                    </h5>
                    {e?.products.map((elem, i) => (
                      <div key={i} className="acc-body-card d-flex mt-4">
                        <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                        <div className="acc-body-card-content d-flex align-items-center px-4">
                          {elem?.productName} ---{" "}
                          {elem.quantity ? elem.quantity : 0} --- ₹
                          {elem.amount ? elem.amount : 0} | Sample:{" "}
                          {elem.sampleQuantity ? elem.sampleQuantity : 0}
                        </div>
                      </div>
                    ))}

                    <h5 className="web-app__heading-blue mt-5">
                      {e?.gifts.length > 0 ? "Gift Details" : null}
                    </h5>
                    {e?.gifts.map((elem, i) => (
                      <div key={i} className="acc-body-card d-flex mt-4">
                        <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                        <div className="acc-body-card-content d-flex align-items-center px-4">
                          {elem?.giftName} ---
                          {elem.giftQuantity ? elem.giftQuantity : 0} ---- ₹
                          {elem.giftAmount ? elem.giftAmount : 0}
                        </div>
                      </div>
                    ))}

                    <h5 className="web-app__heading-blue mt-5">
                      {e?.workedWith.length > 0 ? "Worked With" : null}
                    </h5>
                    {e?.workedWith.map((elem, i) => (
                      <div key={i} className="acc-body-card d-flex mt-4">
                        <RiUser3Fill className="acc-body-card-icon acc-i-light-yellow" />
                        <div className="acc-body-card-content d-flex align-items-center px-4">
                          {elem?.firstName} {elem?.lastName}
                          {/* ({elem?.designation?.name}) */}
                        </div>
                      </div>
                    ))}

                    {e.remarks ? (
                      <>
                        <h5 className="web-app__heading-blue mt-5">Remarks</h5>
                        <h3 className="acc-body-remarks">{e?.remarks}</h3>
                      </>
                    ) : null}
                    <h5 className="web-app__heading-blue mt-5">Report Time</h5>
                    <h3 className="acc-body-remarks">{formatTime(e?.cdt)}</h3>

                    {!(location?.state?.isBacklog) && geoLocReports && geoLocReports?.length > 0 && (() => {
                      const element = geoLocReports?.find((item) => item?.target?.value === e?.target?._id);
                      return element ? 
                      <div>
                        <h5 className="web-app__heading-blue mt-5">Geo-tagging</h5>
                        <ViewMap latitude={element?.geolocation?.latitude} longitude={element?.geolocation?.longitude} latitude2={e?.target?.geolocation?.latitude} longitude2={e?.target?.geolocation?.longitude} type={e?.type}/>
                      </div>
                      :
                      null
                    })
                    ()
                    }
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
        <div className="gallery">


            <ResponsiveMasonry
              columnsCountBreakPoints={{ 300: 2, 900: 3 }}
              className="gallery-img-container"
            >
              <Masonry>
                {(images?.length > 0) ? (
                  images?.map((e, index) => (
                    (e.name.trim() !== "" && e.pic.trim() !== "") &&
                    <div className="call-report-card"
                      key={index}
                      onClick={(e) => {
                        setIndex(index);
                        setOpen(true);
                      }}
                    >

                      <img src={e.pic} alt="" loading="lazy" className="call-report-pic" />
                      <div className="img-call-wrapper d-flex justify-content-between align-items-center ">
                        <h5 className="img-name  text-white  bg-dark">{e.name}</h5>

                        {/* {loggedIn?.user?.isAdmin ? (
                          <MdDeleteOutline
                            onClick={(event) => {
                              event.stopPropagation();
                              setItemDelete({
                                prodId: e._id,
                                link: e.uri,
                                ID: e.ID,
                              });

                            // setShowEdit(true);
                            // setShowEdit(true);
                            // // handleDelete({ prodId: e._id, link: e.img,ID:e.ID })
                            //   setShowEdit(true);
                            // handleDelete({ prodId: e._id, link: e.img,ID:e.ID })
                            }}
                            style={{
                              cursor: "pointer",
                              marginTop: "-1rem",
                              fontSize: "1.4vmax",
                            }}
                            className="button-delete__icon bg-dark"
                          />
                        ) : null} */}
                      </div>
                    </div>
                  ))) : (
                  <label className="web-app__heading">No Images here </label>
                )
                }
              </Masonry>
            </ResponsiveMasonry>
            {/* // <img src={pic} alt="" className="call-report-pic" /> */}
            <Lightbox
              index={index}
              open={open}
              close={() => setOpen(false)}
              slides={images?.map(e => (e.pic.trim() !== "" && { src: e.pic }))}
            />

          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCallReportDetail;
