import React from 'react'

export default function index ({row1 , row2, row3, row4, styles, className}){
  return (
    <div className={`profit_analyse_box ${className}`}>
      <div className='row1'>{row1}</div>
      <div className='row2'>{row2}</div>
      <div className='row3'>{row3}</div>
      <div className='row4'>{row4}</div>
    </div>
  )
}