import { MdArrowBackIosNew, MdShareLocation } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";
import { RiCapsuleFill } from "react-icons/ri";
import { formatDate } from "../../../../../utils/helper";
import { monthOptions } from "../../../../../utils/helper";
import { GiMedicines } from "react-icons/gi";

const StockistDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="utility-inner-tp">
      <div className="row">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MdArrowBackIosNew
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span style={{ display: "inline-block", margin: "0 1rem" }}>
            DCR Details
          </span>
        </h2>

        <div className="util-inner-details">
          <div className="util-inner-info-box-container">
            <div className="util-inner-info-box">
              <h3>Date</h3>
              <p>{formatDate(location?.state?.date)}</p>
            </div>

            <div className="util-inner-info-box">
              <h3>Month</h3>
              <p>
                {
                  monthOptions[new Date(`${location?.state?.date}`).getMonth()]
                    ?.label
                }
              </p>
            </div>

            <div className="util-inner-info-box">
              <h3>Activity</h3>
              <p>{location?.state?.activity}</p>
            </div>

            <div className="util-inner-info-box">
              <h3>Area Type</h3>
              <p>{location?.state?.areaType}</p>
            </div>
          </div>
        </div>

        <div className="util-inner-location-details">
          {location?.state?.workAreas?.map((el) => (
            <div className="util-inner-location-box">
              <MdShareLocation className="util-inner-location-box-icon" />
              <p>{el?.location?.label}</p>
            </div>
          ))}
        </div>

        <h2 className="web-app__heading mt-5">Remarks</h2>
        <p className="util-inner-remarks-para">{location?.state?.remarks}</p>
      </div>
    </div>
  );
};

export default StockistDetails;
