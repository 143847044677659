import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import customToast from "./../../../../../components/CustomToast";
import { setCities } from "../../../../../reducers/locations/cityReducer";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../../reducers/locations/hqReducer";
import { addChemistForApproval, clearChemists, setAllChemists } from "../../../../../reducers/targets/chemist";
import serialise, {
  generateSelectData,
  generateSelectUser,
} from "../../../../../utils/serialiseResponse";
import { adminStyles } from "../../../../public/stylesheets/selectStyles";
import PageTitle from "../../../../../components/PageTitle";
import { viewMedRepresentatives, viewMedRepresentativesV1 } from "../../../../../services/utilities";

const CreateChemist = () => {
  const chemists = useSelector(({ chemist }) => chemist);

  const dispatch = useDispatch();
  const loggedIn = useSelector(({ user }) => user);

  const headquarters = useSelector(({ headquarter }) => headquarter);
  const cities = useSelector(({ city }) => city);

  const [businessName, setBusinessName] = useState("");
  const [name, setName] = useState("");
  const [certification, setCertification] = useState("");
  const [email, setEmail] = useState("");

  const [birthday, setBirthday] = useState("");
  const [chemistContact, setChemistContact] = useState("");

  const [address, setAddress] = useState("");

  const [headquarter, setHeadquarter] = useState();
  const [city, setCity] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [employees, setEmployees] = useState([]);
  const [extraInfo, setExtraInfo] = useState("");

  useEffect(() => {
    dispatch(setHeadquarters());
    return ()=> {dispatch(clearHeadquarters());}
  }, [dispatch]);

  useEffect(() => {
    if (!headquarter) return;

    dispatch(setCities(headquarter?.value));
  }, [dispatch, headquarter]);

  const hqSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );
  const citySelect = useMemo(
    () => generateSelectData(cities.data, "name"),
    [cities]
  );
  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );

  useEffect(() => {
    dispatch(setAllChemists({ type: "creation" }));

    try {
      viewMedRepresentativesV1({includeMe:true}).then((res) => {
        setEmployees([...res.data]);
      });
    } catch (error) {
      console.log(error);
    }

    return () => {
      dispatch(clearChemists());
     
    };

  }, []);

  const tableData = useMemo(() => {
    return serialise(chemists?.data)
      
  }, [chemists?.data]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !businessName || !headquarter || !city || !chemistContact)
      return customToast.error("Please fill all the Required Fields..");

      var index = tableData.findIndex(p => (p.name).toLocaleLowerCase() == name.toLocaleLowerCase() && (p.businessName).toLocaleLowerCase() == businessName.toLocaleLowerCase() && (p.workingArea)?.toLocaleLowerCase() == city?.value  && (p.isApproved === 0 || p.isApproved === 1) );
     
      if(index === -1){

        let error = false;
        try {
          await dispatch(
            addChemistForApproval({
              businessName,
              name,
              certification,
              birthday: birthday,
              email,
              chemistContact: chemistContact,
              address: address,
              city: headquarter?.value,
              workingArea: city?.value,
              medRepresentative: selectedUser?.value ? selectedUser?.value : loggedIn?.user?._id,
              extraInfo
            })
          );
        } catch (e) {
          error = true;
          e?.response?.status === 409 ? 
          customToast.error("Chemist Already Exists"):
          customToast.error("We Encountered an Error");
        }
    
        if (!error) {
          customToast.success("Chemist Added Successfully");
          setBusinessName("");
          setEmail("");
          setName("");
          setCertification("");
          setBirthday("");
          setChemistContact("");
          setAddress("");
          setHeadquarter(null);
          setCity(null);
          setSelectedUser(null);
        }

      }
      else{
        if(tableData[index].isApproved) customToast.error("Chemist Already Exists");
        else customToast.error("Chemist Awaiting Approval");
      }

   
  };

 

  return (
    <main className="main-content admin-content">
      <div className="admin-creation-content">
        <PageTitle isCreation title="Create Chemist" />

        <section className="admin-creation-content__form">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="businessName">
                  Business Name <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  type="text"
                  className="me-5"
                  id="businessName"
                  placeholder="Enter Chemist's Name"
                  name="businessName"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="name">
                  Propreitor Name <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  className="me-5"
                  id="name"
                  placeholder="Enter Chemist's Name"
                  name="name"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="certifications">
                  Certifications / Trademarks{" "}
                </label>
                <br />
                <input
                  value={certification}
                  onChange={(e) => setCertification(e.target.value)}
                  type="text"
                  className="me-5"
                  id="degree"
                  placeholder="Enter Certification"
                  name="degree"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                <label htmlFor="birthday">Birthday</label>
                <br />
                <input
                  type="date"
                  className="me-5"
                  id="birthday"
                  onChange={(e) => setBirthday(e.target.value)}
                  value={birthday}
                  name="birthday"
                />{" "}
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                <label htmlFor="email">Email</label>
                <br />
                <input
                  onChange={({ target }) => setEmail(target.value)}
                  type="email"
                  className="me-5"
                  id="email"
                  placeholder="Enter Email Address"
                  name="email"
                  value={email}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                <label htmlFor="contact">
                  Chemist's Contact Number{" "}
                  <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  value={chemistContact}
                  onChange={(e) => setChemistContact(e.target.value)}
                  type="phone"
                  maxLength={10}
                  pattern="[0-9]*"
                  className="me-5"
                  id="contact"
                  placeholder="Enter Alternate Number"
                  name="clinicContact"
                />
              </div>
              {loggedIn?.user?.des < 121 ||
              loggedIn?.user?.isAdmin ? (
                <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                  <label htmlFor="mrAllot">
                    Select MR to Allot <span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <Select
                    name="mrAllot"
                    id="mrAllot"
                    value={selectedUser}
                    options={employeeSelect}
                    onChange={(e) => setSelectedUser({ ...e })}
                    styles={adminStyles}
                    placeholder="Select MR"
                  />
                </div>
              ) : null}
              <div className="col-sm-12 col-md-12 col-lg-12 mt-5">
                <label htmlFor="address">
                  Chemist's Address <span className="asterisk-imp">*</span>
                </label>
                <br />
                <textarea
                  name="address"
                  id="address"
                  cols="30"
                  rows="5"
                  placeholder="Enter Clinic Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                ></textarea>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                  <label htmlFor="hq">
                    Select HQ <span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <Select
                    name="hq"
                    id="hq"
                    value={headquarter}
                    options={hqSelect}
                    onChange={(e) => setHeadquarter({ ...e })}
                    styles={adminStyles}
                    placeholder="Select Headquarter"
                  />
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                  <label htmlFor="city">
                    Select Working Area <span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <Select
                    name="city"
                    id="city"
                    value={city}
                    options={citySelect}
                    onChange={(e) => setCity({ ...e })}
                    styles={adminStyles}
                    placeholder="Select Working Area"
                  />
                </div>
              </div>
              <div className="mt-4">
                <label htmlFor="extra_info">Extra Information</label>
                <br />
                <textarea
                  value={extraInfo}
                  onChange={(e) => setExtraInfo(e.target.value)}
                  type="text"
                  className="me-5"
                  id="extra_info"
                  placeholder="Enter Extra Information "
                  name="extra_info"
                  cols={30}
                  rows={1}
                ></textarea>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mt-4 admin-creation-content__form-submit mt-5">
                <button type="submit" className="button-blue-gradient">
                  Add Chemist
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};

export default CreateChemist;
