import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../../public/img/SVG/magnifying-glass.svg";
import Logo from "../../../public/img/logo.png";
import { MdOutlineNotificationsActive, MdLogout } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../../../../reducers/userReducer";
import MaleAvatar from "./../../../public/img/male-avatar.png";
import { FaCartPlus, FaRupeeSign } from "react-icons/fa";
import FemaleAvatar from "./../../../public/img/female-avatar.png";
import { viewInventoryAlerts } from "../../../../reducers/products/MinimumStockReducer";
import { MdOutlineSupportAgent } from "react-icons/md";
import Faq from "../../../../components/Modal/Faq";
import {
  getPaymentStatus,
  getSubscriptionDetails,
} from "../../../../services/payment";
import { viewNotifications } from "../../../../reducers/notifications";
import { FaRunning } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { TbTrendingUp } from "react-icons/tb";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loggedIn = useSelector(({ user }) => user);
  const minStock = useSelector((state) => state.settings["min-stock"]);
  const isCompanyName = useSelector((state) => state.settings["company-name"]);
  const companyName = useSelector((state) => state.companyName.companyName);
  const inventoryAlerts = useSelector(
    ({ inventoryAlerts }) => inventoryAlerts.data
  );
  const notifications = useSelector(({ notification }) => notification);
  const [paymentStatus, setPaymentStatus] = useState({});
  const [gpsDetails, setGpsDetails] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getSubscriptionDetails();
        setGpsDetails(res?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  useEffect(() => {
    if (loggedIn.user.des === 101) {
      getPaymentStatus().then(({ data }) => setPaymentStatus(data));
    }
  }, [loggedIn]);

  const [variant, setVariant] = useState(undefined);

  useEffect(() => {
    dispatch(viewInventoryAlerts());
    dispatch(viewNotifications());
  }, [dispatch]);

  return (
    //-->
    <header className="header">
      <div className="brand" onClick={() => navigate("/", { replace: true })}>
        <img src={Logo} alt="Medorn Logo" className="logo" />
        <h1 className="brand__heading">
          {isCompanyName === "true" ? (
            companyName?.includes(" ") ? (
              <>
                <span style={{ display: "block", lineHeight: "1" }}>
                  {companyName.split(" ")[0]}
                </span>
                <span
                  style={{
                    display: "block",
                    fontSize: "10px",
                    fontWeight: "700",
                    color: "#2ecc71bb",
                    opacity: "0.7",
                  }}
                >
                  {companyName.split(" ").slice(1).join(" ")}
                </span>
              </>
            ) : (
              companyName
            )
          ) : (
            "Medorn"
          )}
        </h1>
      </div>

      <form action="#" className="search">
        <input
          type="search"
          className="search__input"
          placeholder="Search Doctors, Tour Program....."
        />
        <button className="search__button">
          <SearchIcon className="search__icon" />
        </button>
      </form>

      <nav className="user-nav">
        {loggedIn?.user?.des === 101 && <Link to="/help-&-support" className="user-nav__icon-box">
          <MdOutlineSupportAgent className="user-nav__icon" />
        </Link>}
        {paymentStatus?.status === "pending" && (
          <Link to="/admin-panel/users/billing" className="user-nav__icon-box">
            <FaRupeeSign className="user-nav__icon icon-color-error" />
            <span className="user-nav__notification">1</span>
          </Link>
        )}

        {gpsDetails?.length > 0 && gpsDetails[0]?.gps === true && (
          <Link to="/navigate-to-tracker" className="user-nav__icon-box">
            <FaLocationDot className="user-nav__icon" />
          </Link>
        )}

        {minStock === "true" && (
          <Link to="/min-stock" className="user-nav__icon-box">
            <FaCartPlus className="user-nav__icon" />
            <span className="user-nav__notification">
              {inventoryAlerts.length}
            </span>
          </Link>
        )}
        {/* started from here   */}
        <Link
          to="/consolidated-reports"
          className="user-nav__icon-box"
          onClick={() => setVariant("soft")}
        >
          <TbTrendingUp className="user-nav__icon" size={24} />
          {/* <BsQuestionCircleFill className="user-nav__icon" size={24} /> */}
        </Link>

        <Link to="/today's-activity" className="user-nav__icon-box">
          <FaRunning className="user-nav__icon" />
        </Link>
        <Link to="/notification" className="user-nav__icon-box">
          <MdOutlineNotificationsActive className="user-nav__icon" />
          <span className="user-nav__notification">
            {notifications?.alerts?.length || 0}
          </span>
        </Link>
        <div onClick={handleLogout} className="user-nav__icon-box">
          <MdLogout className="user-nav__icon" />
        </div>
        <Link to="/profile">
          <div className="user-nav__user">
            <img
              src={
                loggedIn?.user?.gender?.toLowerCase() === "male"
                  ? MaleAvatar
                  : FemaleAvatar
              }
              alt="avatar"
              className="user-nav__user-photo"
            />
            <span className="user-nav__user-name">
              {loggedIn.user.firstName}
            </span>
          </div>
        </Link>
      </nav>
      <Faq variant={variant} setVariant={setVariant} />
    </header>
  );
};

export default Header;
