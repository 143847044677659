import { useEffect, useState, useMemo } from "react";
import { viewGifts } from "../../../../../services/products";
import serialise from "../../../../../utils/serialiseResponse";
import Table from "../../../../../components/Table";
import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const GiftList = () => {
  const navigate = useNavigate();
  const [gifts, setGifts] = useState([]);

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Gift",
        accessor: "gift",
        minWidth: 80,
        maxWidth: 80,
        showTotal:true,
        placeholderTotal:"Total"
      },
      {
        Header: "Packaging",
        accessor: "packaging",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Price",
        accessor: "price",
        minWidth: 85,
        maxWidth: 85,
        showTotal:true,
        totalAccessor:"price"
      },
    ],
    []
  );

  useEffect(() => {
    viewGifts().then((response) => {
      setGifts(response?.data);
    });
  }, []);
  const tableData = useMemo(() => serialise(gifts), [gifts]);

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <section className="admin-creation-content__heading">
          <MdArrowBackIosNew
            className="admin-creation-content__back-button"
            onClick={() => navigate("/utilities")}
          />
          <h2 className="web-app__heading">Gifts List</h2>
        </section>

        <section className="area-creation-content__info" style={{ marginTop: "-4rem" }}>
          <div className="filter-table">
          {tableData.length > 0 && (
              <h2 className="web-app__heading mb-4 ms-2">
                Showing ({tableData.length}) Entries
              </h2>
            )}
            {tableData.length > 0 ? (
              <Table columns={columns} data={tableData} />
            ) : (
              <h3 className="no-results-message">No results found</h3>
            )}
          </div>
        </section>
      </div>
    </main>
  );
};

export default GiftList;
