import { useLocation } from "react-router-dom";
import PageTitle from "../../../../../components/PageTitle";
import { formatDate } from "../../../../../utils/helper";
import { useEffect, useState } from "react";
import { getStockistDetails } from "../../../../../services/doctor";
import customToast from "../../../../../components/CustomToast";

const AdminViewDCSDetailsStockist = () => {
  const location = useLocation();

  const [stockist, setStockist] = useState();

  useEffect(() => {
    getStockistDetails(location?.state?._id)
    .then(({data}) => {
      console.log(data)
      setStockist(data);
      customToast.success("Stockist data fetched successfully");
    })
    .catch(err => customToast.success("Somthing went wrong!"))
  }, [location]);

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title={`Stockist Details`} hierarchy={true} />
      </div>

      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Name</h4>
          <p>{stockist?.name}</p>
        </div>

        <div className="user-detail-box">
          <h4>Business Name</h4>
          <p>{stockist?.businessName}</p>
        </div>

        <div className="user-detail-box">
          <h4>Headquarter</h4>
          <p>{stockist?.city?.name}</p>
        </div>

        <div className="user-detail-box">
          <h4>Working Area</h4>
          <p>{stockist?.workingArea?.name}</p>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-yellowDark-border">
        <div className="user-detail-box">
          <h4>Certification</h4>
          <p>{stockist?.certification}</p>
        </div>

        <div className="user-detail-box">
          <h4>Stockist Contact</h4>
          <p>{stockist?.stockistContact}</p>
        </div>

        <div className="user-detail-box">
          <h4>E-mail</h4>
          <p>{stockist?.email}</p>
        </div>

        <div className="user-detail-box">
          <h4>Establishment Date</h4>
          <p>{formatDate(stockist?.establishmentDate)}</p>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Drug Expiry Number</h4>
          <p>{stockist?.drugExpiryNumber}</p>
        </div>

        <div className="user-detail-box">
          <h4>Drug License Number</h4>
          <p>{stockist?.drugLicenseNumber}</p>
        </div>

        <div className="user-detail-box">
          <h4>GST Number</h4>
          <p>{stockist?.gstNumber}</p>
        </div>

        <div className="user-detail-box">
          <h4>Address</h4>
          <p>{stockist?.address}</p>
        </div>
      </section>

      <section className="user-detail-container user-detail-container-yellowDark-border">
      <div className="user-detail-box">
          <h4>Extra Info</h4>
          <p>{stockist?.extraInfo}</p>
        </div>
      </section>
    </div>
  );
};

export default AdminViewDCSDetailsStockist;
