import ExtraForm from "./Form";

const Extra = () => {
  return (
    <div className="main-content">
      <h2 className="web-app__heading dcr__heading">Extra Activity DCR</h2>
      <ExtraForm />
    </div>
  );
};

export default Extra;
