
import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageTitle from "../../../../../components/PageTitle";
import {
  clearAllowance,
  deleteAllowance,
  viewOutStationAllowances,
} from "../../../../../reducers/expenses/allowance";
import serialise from "../../../../../utils/serialiseResponse";
import Table from "../../../../../components/Table";



const AdminViewOSAllowances = () => {
  const allowanceData = useSelector(({ allowance }) => allowance);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewOutStationAllowances());

    return ()=> {dispatch(clearAllowance());}
  }, [dispatch]);

  const tableData = useMemo(
    () => serialise(allowanceData.data.data),
    [allowanceData]
  );

  const columnData = useMemo(() => [
    { title: "Designation", field: "designation" },
    { title: "Category", field: "category" },
    { title: "Amount", field: "amount" },
  ], [])

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="View Out Station Allowances" />

        <section className="area-creation-content__form">
          <div className="filter-table">
            <Table
             columns={ columnData }
             data={tableData} 
            />
            {/* <MaterialTable
              editable={{
                onRowDelete: (newRow, oldRow) =>
                  new Promise((resolve, reject) => {
                    dispatch(
                      deleteAllowance({ type: "exStation" }, newRow._id)
                    );
                    setTimeout(() => resolve(), 500);
                  }),
              }}
              options={{
                exportButton: true,
                emptyRowsWhenPaging: false,
                actionsColumnIndex: -1,
                toolbar: false,
                pageSizeOptions: [5, 10, 20, 50, 100],
              }}
              columns={columnData}
              data={tableData}
            /> */}
          </div>
        </section>
      </div>
    </main>
  );
};

export default AdminViewOSAllowances;
