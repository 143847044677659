import { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../../../../components/PageTitle";
import Table from "../../../../../components/Table";
import { monthOptions, yearOptions} from "../../../../../utils/helper";
import { adminStyles } from "../../../../public/stylesheets/selectStyles";
import Select from "react-select";
import {  deleteTargets } from "../../../../../reducers/reports/targetReducer";
import { fetchMonthlyHqTargets } from "../../../../../services/reports";
import customToast from "../../../../../components/CustomToast";
import { MdCheckCircleOutline, MdDelete, MdDeleteOutline } from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";


const MonthlyHqTargets = () => {
  const [month, setMonth] = useState();
  const [year, setYear] = useState(yearOptions[2]);
  const [targetData, setTargetData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [deleteData, setDeleteData] = useState([]);


  useEffect(() => {
    const fetchData = async() => {
      setLoading(true);
      setTargetData([]);
      try {
        let payload = {
          month : Number(month?.value),
          year: Number(year?.label)
        };
  
        const res = await fetchMonthlyHqTargets(payload);
        setTargetData(res?.data);
      } catch (error) {
        customToast.error("Error Occurred !");
        console.log(error);      
      }
      finally{
        setLoading(false);
      }
    };
    if(month && year){
      fetchData();
    }
  },[month, year, isDeleted]);

  const columns2 = useMemo(
    () => [
      {
        Header: "Headquarter",
        accessor:"name"
      },
      {
        Header: "Amount",
        accessor:"amount"
      },
      {
        Header: "Delete",
        accessor:"",
        Cell:(props) => {
          let data = props?.row?.original;
          return data?._id ?
          <div>
            <MdDelete size={25} className="icon-color-error cursor-pointer"
            onClick={() => {
              setDeleteData(props?.row?.original);
              setDeletePopup(true);
            }}/>
          </div>
          :
          <div>
            <MdDelete size={20} style={{cursor:"not-allowed"}}/>
          </div>
        }
      },
    ],
    []
  );

  const DeletePopup = ({ data }) => {
    const dispatch = useDispatch();
    const handleDelete = async (e) => {
      e.preventDefault();
      try{
        dispatch(deleteTargets("hqTarget", {id:data?._id}))
        setIsDeleted(!isDeleted)
        
        setDeletePopup(false);
        customToast.success("Target deleted successfully")
      }
      catch{
        customToast.error("We encountered an error !")
      }
    };

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete Alloted Target</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setDeletePopup(false)}
            />
          </div>

          <form onSubmit={handleDelete}>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  This will PERMANENTLY delete the alloted target !
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-delete mt-4">
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Yes</span>
              </button>

              <button
                type="button"
                className="button-submit-green mt-4"
                onClick={() => setDeletePopup(false)}
              >
                <span className="button-submit-green__icon-container">
                  <MdCheckCircleOutline className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">No</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };
  
  
  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Monthly Targets" />

        <section className="area-creation-content__info">
            <div className="primarysales-filter"
            style={{marginTop:"0", padding:"0", marginBottom:"2rem"}}>

              <div className="util-tp-filter">
                <p className="mb-2">
                  Select Month
                  <span className="asterik-imp">*</span>
                </p>
                <Select
                styles={adminStyles}
                placeholder="Select Month"
                className="mt-3"
                options={monthOptions}
                value={month}
                onChange={(e) => setMonth(e)}/>
              </div>

              <div className="util-tp-filter">
              <p className="mb-2">
                Select Year <span className="asterisk-imp">*</span>
              </p>
              <Select
                styles={adminStyles}
                placeholder="Select Month"
                className="mt-3"
                options={yearOptions}
                value={year}
                onChange={(e) => setYear({ ...e })}
              />
                </div>
            </div>
              {loading && targetData?.length === 0 ? 
              <div>
                Loading ....
              </div>
            :
            <h2 className="web-app__heading mb-4 ms-2">
            Showing ({targetData  ? targetData?.length : 0}) Entries
            </h2>

              }
          <div className="filter-table" style={{width:"100%"}}>
            <Table columns={columns2} data={targetData} />
          </div>
        </section>
        {deletePopup ? <DeletePopup data={deleteData}/> : null}
      </div>
    </div>
  );
};

export default MonthlyHqTargets;
