import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { LuEqual } from "react-icons/lu";
import { viewTodaysActivity, viewUsersForUtilitiesWithTP } from "../../../services/utilities";
import './index.css';
import { ReactComponent as doctorCallSvg } from '../../public/img/SVG/icons/doctorCall.svg';
import { ReactComponent as chemCallsSvg } from '../../public/img/SVG/icons/chemCalls.svg';
import { ReactComponent as stockCallsSvg } from '../../public/img/SVG/icons/stockCalls.svg';
import { ReactComponent as totalPobSvg } from '../../public/img/SVG/icons/totalPob.svg';
import { ReactComponent as totalSamplesSvg } from '../../public/img/SVG/icons/totalSamples.svg';
import { ReactComponent as gifts } from '../../public/img/SVG/icons/gifts.svg';

const DashboardCard = ({ title, total, yesterday, percentage, Icon, bgColor, changeIcon, changeIconColor }) => (
  <div className={`dashboard-card ${bgColor}`}>
    <div className="dashboard-card-header">
      <div className="icon">
        <Icon />
      </div>
      <div className="title">{title}</div>
    </div>
    <div className="dashboard-card-content">
      <div className="total-container">
        <div className="total-text">Total</div>
        <div className="total">{total}</div>
      </div>
      <div className="stats-container">
        <div className="stats">
          <div className={`change-icon ${changeIconColor}`}>{changeIcon}</div>
          <div>{yesterday} Yesterday</div>
          <div>({percentage})</div>
        </div>
      </div>
    </div>
  </div>
);

const ConsolidateReport = () => {
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [data, setData] = useState(null);
  const [usersResponse, setUsersResponse] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentDate = new Date().toISOString().split('T')[0];
  const [isCurrentDate, setIsCurrentDate] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await viewTodaysActivity(date);
      setData(response.data);

      const usersResponse = await viewUsersForUtilitiesWithTP(date);
      const filteredUsers = usersResponse?.data.filter(user => user.chemreports > 0 || user.docreports > 0 || user.stocreports > 0);
      setUsersResponse(filteredUsers);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    setIsCurrentDate(date === currentDate);
  }, [date, currentDate]);

  const getChangeIcon = (current, previous) => {
    if (current > previous) return <IoIosArrowUp />;
    if (current < previous) return <IoIosArrowDown />;
    return <LuEqual />;
  };

  const getChangeIconColor = (current, previous) => {
    if (current > previous) return 'green';
    if (current < previous) return 'red';
    return 'primary';
  };

  const getPercentageChange = (previous, current) => {
    if (previous === 0) {
      return current === 0 ? '0%' : '-100%';
    }
  
    let difference = current > previous ? ((current - previous) / previous) * 100 : -((previous - current) / previous) * 100;
    difference = -difference; // Negate the difference
    difference = Math.abs(difference) > 100 ? 100 * Math.sign(difference) : difference;
  
    // Check if there is a fractional component
    const hasFractionalComponent = difference % 1 !== 0;
  
    return hasFractionalComponent ? `${difference.toFixed(2)}%` : `${difference.toFixed(0)}%`;
  };
  
  

  const cards = [
    { title: 'Doctor Visited', total: data?.docCalls || 0, yesterday: data?.yestReport?.docCalls || 0, percentage: getPercentageChange(data?.docCalls || 0, data?.yestReport?.docCalls || 0), Icon: doctorCallSvg, bgColor: 'bg-blue', changeIcon: getChangeIcon(data?.docCalls || 0, data?.yestReport?.docCalls || 0), changeIconColor: getChangeIconColor(data?.docCalls || 0, data?.yestReport?.docCalls || 0) },
    { title: 'Chemist Visited', total: data?.chemCalls || 0, yesterday: data?.yestReport?.chemCalls || 0, percentage: getPercentageChange(data?.chemCalls || 0, data?.yestReport?.chemCalls || 0), Icon: chemCallsSvg, bgColor: 'bg-purple', changeIcon: getChangeIcon(data?.chemCalls || 0, data?.yestReport?.chemCalls || 0), changeIconColor: getChangeIconColor(data?.chemCalls || 0, data?.yestReport?.chemCalls || 0) },
    { title: 'Stockist Visited', total: data?.stockCalls || 0, yesterday: data?.yestReport?.stockCalls || 0, percentage: getPercentageChange(data?.stockCalls || 0, data?.yestReport?.stockCalls || 0), Icon: stockCallsSvg, bgColor: 'bg-purple', changeIcon: getChangeIcon(data?.stockCalls || 0, data?.yestReport?.stockCalls || 0), changeIconColor: getChangeIconColor(data?.stockCalls || 0, data?.yestReport?.stockCalls || 0) },
    { title: 'Total POB', total: data?.pobs || 0, yesterday: data?.yestReport?.pobs || 0, percentage: getPercentageChange(data?.pobs || 0, data?.yestReport?.pobs || 0), Icon: totalPobSvg, bgColor: 'bg-darkblue', changeIcon: getChangeIcon(data?.pobs || 0, data?.yestReport?.pobs || 0), changeIconColor: getChangeIconColor(data?.pobs || 0, data?.yestReport?.pobs || 0) },
    { title: 'Total Samples', total: data?.samples || 0, yesterday: data?.yestReport?.samples || 0, percentage: getPercentageChange(data?.samples || 0, data?.yestReport?.samples || 0), Icon: totalSamplesSvg, bgColor: 'bg-blue', changeIcon: getChangeIcon(data?.samples || 0, data?.yestReport?.samples || 0), changeIconColor: getChangeIconColor(data?.samples || 0, data?.yestReport?.samples || 0) },
    { title: 'Total Gifts', total: data?.gifts || 0, yesterday: data?.yestReport?.gifts || 0, percentage: getPercentageChange(data?.gifts || 0, data?.yestReport?.gifts || 0), Icon: gifts, bgColor: 'bg-purple', changeIcon: getChangeIcon(data?.gifts || 0, data?.yestReport?.gifts || 0), changeIconColor: getChangeIconColor(data?.gifts || 0, data?.yestReport?.gifts || 0) },
  ];

  return (
    <div className='app-container'>
      <header className="header1">
        <div className="header-content">
          <div className="date-picker">
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="date-input"
            />
            <button type="button" className="refresh-button" onClick={fetchData}>Refresh</button>
            <p className="note">
              {isCurrentDate
                ? "Note: Today's reports are directly being fetched from the User Portal & can change if the user decides to delete an entry. The numbers will be fixed once the call reports are approved."
                : "Note: Reports are being fetched from approved call reports."}
            </p>
          </div>
        </div>
      </header>
      <main className="main-content">
        <div className={`dashboard ${loading ? 'loading' : ''}`}>
          {loading && (
            <div className="loading-animation">
              <div className="loading-spinner"></div>
              <div className="loading-text">Loading...</div>
            </div>
          )}
          {!loading && cards.map((card, index) => (
            <DashboardCard key={index} {...card} />
          ))}
        </div>

        <div className={`user-list ${loading ? 'loading' : ''}`}>
          {!loading && usersResponse.map((user, index) => {
            if (!user || !user.user) return null;
            const totalReports = (user.chemreports || 0) + (user.docreports || 0) + (user.stocreports || 0);
            const areaType = user.tps.length > 0 && user.tps[0].areaType ? `(${user.tps[0].areaType})` : '';
            return (
              <div key={index} className="user-card">
                <div className="user-info">
                  <span className="status-indicator"></span>
                  <span className="name">{user.user.firstName} {user.user.lastName} <span className="area-type">{areaType}</span></span>
                </div>
                {
                // <Link to="/today's-activity" className="reports-circle">
                //   {totalReports}
                // </Link>
                <Link to={isCurrentDate ? `/today's-activity` : `/utilities/call-reports`} className="reports-circle">
                  {totalReports}
                </Link>
                }
              </div>
            );
          })}
        </div>
      </main>
    </div>
  );
};

export default ConsolidateReport;
