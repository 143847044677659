import Select from "react-select";
import Table from "../../../components/Table";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addStockManagement,
  viewStockManagement,
  clearStockManagement,
} from "../../../reducers/ssg/stockManagementReducer";
import { generateSelectData } from "../../../utils/serialiseResponse";
import {
  setProductGroups,
  clearProductGroups,
} from "../../../reducers/products/groupReducer";
import {
  setProductRanges,
  clearProductRanges,
} from "../../../reducers/products/rangeReducer";
import { setDoctors, clearDoctors } from "../../../reducers/targets/doctor";
import customToast from "../../../components/CustomToast";
const dummyData = {
  columns: [
    {
      Header: "Date",
      accessor: "date",
      disableFilters: true,
      disableSortBy: false,
    },
    {
      Header: "Product Name",
      accessor: "productName",
      filter: "fuzzyText",
      disableSortBy: true,
    },
    {
      Header: "Price",
      accessor: "price",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Opening Balance",
      accessor: "openingBalance",
      disableFilters: true,
    },
    {
      Header: "Primary Sales",
      accessor: "primarySales",
      disableFilters: true,
    },
    {
      Header: "Sales Return",
      accessor: "salesReturn",
      disableFilters: true,
    },
    {
      Header: "Closing Balance",
      accessor: "closingBalance",
      disableFilters: true,
    },
    {
      Header: "Secondary Sales",
      accessor: "secondarySales",
      disableFilters: true,
    },
    {
      Header: "Chemist Returns",
      accessor: "chemistReturns",
      disableFilters: true,
    },
    {
      Header: "Transit Stock",
      accessor: "transitStock",
      disableFilters: true,
    },
  ],

  data: [
    {
      date: "1",
      productName: "ABC",
      price: 173,
      openingBalance: 109,
      primarySales: 124,
      salesReturn: 84,
      closingBalance: 300,
      secondarySales: 45,
      chemistReturns: 302,
      transitStock: 501,
    },
    {
      date: "2",
      productName: "XYZ",
      price: 173,
      openingBalance: 109,
      primarySales: 124,
      salesReturn: 84,
      closingBalance: 300,
      secondarySales: 45,
      chemistReturns: 302,
      transitStock: 501,
    },
    {
      date: "3",
      productName: "PQR",
      price: 173,
      openingBalance: 109,
      primarySales: 124,
      salesReturn: 84,
      closingBalance: 300,
      secondarySales: 45,
      chemistReturns: 302,
      transitStock: 501,
    },
    {
      date: "4",
      productName: "STU",
      price: 173,
      openingBalance: 109,
      primarySales: 124,
      salesReturn: 84,
      closingBalance: 300,
      secondarySales: 45,
      chemistReturns: 302,
      transitStock: 501,
    },
    {
      date: "5",
      productName: "ABD",
      price: 173,
      openingBalance: 109,
      primarySales: 124,
      salesReturn: 84,
      closingBalance: 300,
      secondarySales: 45,
      chemistReturns: 302,
      transitStock: 501,
    },
    {
      date: "6",
      productName: "MNO",
      price: 173,
      openingBalance: 109,
      primarySales: 124,
      salesReturn: 84,
      closingBalance: 300,
      secondarySales: 45,
      chemistReturns: 302,
      transitStock: 501,
    },
  ],
};

const dummyMonths = [
  {
    value: 1,
    label: "January",
  },
  {
    value: 2,
    label: "February",
  },
  {
    value: 3,
    label: "March",
  },
  {
    value: 4,
    label: "April",
  },
  {
    value: 5,
    label: "May",
  },
  {
    value: 6,
    label: "June",
  },
  {
    value: 7,
    label: "July",
  },
  {
    value: 8,
    label: "August",
  },
  {
    value: 9,
    label: "September",
  },
  {
    value: 10,
    label: "October",
  },
  {
    value: 11,
    label: "November",
  },
  {
    value: 12,
    label: "December",
  },
];

const dummyYears = [
  {
    value: 2021,
    label: "2021",
  },
  {
    value: 2022,
    label: "2022",
  },
];

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    border: "2px solid #36ABF9",
    borderRadius: "5px",
    color: "#9e9ad0",
    fontSize: "1.5rem",
    fontWeight: "500",

    width: "20rem",
    cursor: "pointer",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2b2b52",
    color: "#9E9AD1",
    fontFamily: "inherit",
    fontSize: "1.5rem",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
      color: "#9E9AD1",
    },
  }),
};

const Stocks = () => {
  const dispatch = useDispatch();

  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [group, setGroup] = useState();
  const [range, setRange] = useState();

  const [doctor, setDoctor] = useState();
  const [distributed, setDistributed] = useState("");

  // const stockManagementReport = useSelector(
  // ({ stockManagementReducer }) => stockManagementReducer
  // );

  const groups = useSelector(({ productGroup }) => productGroup);
  const ranges = useSelector(({ productRange }) => productRange);
  const doctors = useSelector(({ doctor }) => doctor);

  const groupOptions = useMemo(
    () => generateSelectData(groups.data, "name"),
    [groups]
  );
  const rangeOptions = useMemo(
    () => generateSelectData(ranges.data, "name"),
    [ranges]
  );

  const doctorOptions = useMemo(
    () => generateSelectData(doctors.data, "name"),
    [doctors]
  );

  useEffect(() => {
    dispatch(setProductGroups());

    return () => {
      dispatch(clearProductGroups());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!group) return;
    dispatch(setProductRanges({ group: group._id }));

    return () => {
      dispatch(clearProductRanges());
    };
  }, [dispatch, group]);

  useEffect(() => {
    dispatch(setDoctors());

    return () => {
      dispatch(clearDoctors());
    };
  }, [dispatch]);

  const resetForm = () => {
    setMonth(null);
    setYear(null);
    setGroup(null);
    setRange(null);
    setDoctor(null);
    setDistributed("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!month || !year || !group || !range || !doctor || !distributed) {
      customToast.error("Please fill all the details");
    }

    const data = {
      month,
      year,
      group,
      range,
      doctor,
      distributed,
    };

    let error = false;
    try {
      await dispatch(addStockManagement(data));
    } catch (e) {
      console.log(e);
      // customToast.error("We encountered an error");
    }

    if (!error) {
      customToast.success("Stock Report Successfully submitted");
      resetForm();
    }
  };
  return (
    <main className="main-content">
      <div className="expense">
        <div className="row">
          <h3 className="web-app__heading">Feed Stocks</h3>
          <div>
            <div className="gift__filters">
              <form className="gift__form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-3">
                    <h3 className="web-app__heading">Select Month & Year</h3>
                  </div>
                  <div className="col-lg-3">
                    <Select
                      value={month}
                      onChange={(e) => setMonth({ ...e })}
                      options={dummyMonths}
                      styles={selectStyles}
                      placeholder="Select Month"
                    />
                  </div>

                  <div className="col-lg-3">
                    <Select
                      options={dummyYears}
                      value={year}
                      onChange={(e) => setYear({ ...e })}
                      styles={selectStyles}
                      placeholder="Select Year"
                    />
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-lg-3">
                    <h3 className="web-app__heading">Select Product</h3>
                  </div>
                  <div className="col-lg-3">
                    <Select
                      value={group}
                      onChange={(e) => {
                        setGroup({ ...e });
                      }}
                      options={groupOptions}
                      styles={selectStyles}
                      placeholder="Product Group"
                    />
                  </div>

                  <div className="col-lg-3">
                    <Select
                      value={range}
                      onChange={(e) => setRange({ ...e })}
                      options={rangeOptions}
                      styles={selectStyles}
                      placeholder="Select Product"
                    />
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-lg-3">
                    <h3 className="web-app__heading">Select Doctor</h3>
                  </div>

                  <div className="col-lg-3">
                    <Select
                      value={doctor}
                      onChange={(e) => setDoctor({ ...e })}
                      options={doctorOptions}
                      styles={selectStyles}
                      placeholder="Select Doctor"
                    />
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-lg-3">
                    <h3 className="web-app__heading">Stock Distributed</h3>
                  </div>
                  <div className="col-lg-4">
                    <input
                      type="number"
                      value={distributed}
                      onChange={(e) => setDistributed(e.target.value)}
                      placeholder="Enter stock distributed"
                    />
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-lg-3">
                    <button type="submit" className="button-blue-gradient">
                      Feed Stocks Details
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div>
          <div className="expense__filters">
            <div className="multi-filters">
              <div>
                <h3 className="web-app__heading">Filters</h3>

                <form>
                  <div className="form__group">
                    <Select
                      options={dummyMonths}
                      styles={selectStyles}
                      placeholder="Select Month"
                    />
                  </div>

                  <div className="form__group">
                    <Select
                      options={dummyYears}
                      styles={selectStyles}
                      placeholder="Select Year"
                    />
                  </div>
                </form>
              </div>

              <div>
                <h3 className="web-app__heading">Select Date</h3>

                <form>
                  <div className="form__group">
                    <Select
                      options={dummyMonths}
                      styles={selectStyles}
                      placeholder="Select Date"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="filter-table">
          <Table columns={dummyData.columns} data={dummyData.data} />
        </div>
      </div>
    </main>
  );
};

export default Stocks;
