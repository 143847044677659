import {
  allotmentsForMonth,
  approveSamples,
  deleteAllotment,
  deleteSampleAllotment,
  newAllotment,
  newAllotmentV1,
  newSampleAllotmentV1,
  rejectSamples,
  viewSampleForApproval
} from "../../services/reports.js";
import { updateTrigger } from "../approveLengthReducer.js";

const initialState = {
  data :[]
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALLOT_SAMPLES":
      return action.payload;

    case "DELETE_SAMPLES":
      return action.payload;

    case "SAMPLES_FOR_MONTH":
      return action.payload;

    case "VIEW_SAMPLES_FOR_APPROVAL":
      return action.payload;

    case "CLEAR_SAMPLES":
      return initialState;

    default:
      return state;
  }
};

export const allotUserSamples = (targetData) => {
  return async (dispatch) => {
    let data;

    try {
      // data = await newAllotment("sample", targetData);
      data = await newSampleAllotmentV1(targetData);
      
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "ALLOT_SAMPLES",
      payload: data,
    });
  };
};

export const deleteUserSamples = (targetData) => {
  return async (dispatch) => {
    let data;
    try {
      // data = await deleteAllotment("sample", targetData);
      data = await deleteSampleAllotment(targetData);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "DELETE_SAMPLES",
      payload: data,
    });
  };
};

export const samplesForMonth = (type, targetData) => {
  return async (dispatch) => {
    let data;
    try {
      data = await allotmentsForMonth(type, targetData);
      
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "SAMPLES_FOR_MONTH",
      payload: data,
    });
  };
};

export const viewSamplesApproval = (details) =>{
  return async (dispatch) =>{
    let data;
    try {
      data = await viewSampleForApproval(details);
    } catch (e) {
      if(e) throw e;
    }
    dispatch({
      type: "VIEW_SAMPLES_FOR_APPROVAL",
      payload : data,
    });
    dispatch(updateTrigger({index: 11, data: data?.data}));
  };
};

export const approveSample = (id,details) =>{
  return async (dispatch) =>{
    let data;
    try {
      data = await approveSamples(id,details); 
    } catch (e) {
      if(e) throw e;
    }
    dispatch(viewSamplesApproval());

  };
};

export const rejectSample = (id, details) =>{
  return async (dispatch) =>{
    let data;
    try {
     data = await rejectSamples(id,details); 
    } catch (e) {
      if(e) throw e;
    }
    dispatch(viewSamplesApproval());
  };
};


export const clearAllotedSamples = () => {
  return {
    type: "CLEAR_SAMPLES",
  };
};

export default reducer;
