import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseUrl = `${apiURL}/client/v1/dcs`;

export const getDcsData = async(payload) => {
  const {data} = await axios.get(`${baseUrl}/geo-tagging`, {
    params: payload,
    headers: setAuth()
  });
  return data;
};

export const getDoctorDetails = async (id) => {
  const {data} = await axios.get(`${baseUrl}/doctor/${id}`, {
    headers: setAuth(),
  });
  return data ;
}
export const getStockistDetails = async (id) => {
  const {data} = await axios.get(`${baseUrl}/stockist/${id}`, {
    headers: setAuth(),
  });
  return data;
}
export const getChemistDetails = async (id) => {
  const {data} = await axios.get(`${baseUrl}/chemist/${id}`, {
    headers: setAuth(),
  });
  return data;
}