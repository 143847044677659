import { useLocation, } from "react-router-dom";
import PageTitle from "../../../../../components/PageTitle";
import { viewProduct } from "../../../../../services/products";
import { useEffect, useState } from "react";
import customToast from "../../../../../components/CustomToast";

const AdminProductsRangeDetails = () => {
  const location = useLocation();
  const [product, setProduct] = useState({});
  useEffect(()=>{
    viewProduct(location.state._id).then(({data})=>{
      console.log(data);
      setProduct(data);
    }).catch(err=> customToast.error("Sorry! failed to load product details"));
  },[location])
  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Product Details" hierarchy={true} />
      </div>
      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Name</h4>
          <p>{product?.name}</p>
        </div>

        <div className="user-detail-box">
          <h4>PTR</h4>
          <p>{product?.ptr}</p>
        </div>

        <div className="user-detail-box">
          <h4>PTS</h4>
          <p>{product?.pts}</p>
        </div>

        <div className="user-detail-box">
          <h4>MRP</h4>
          <p>{product?.mrp}</p>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-yellowDark-border">
        <div className="user-detail-box">
          <h4>Category</h4>
          <p>{product?.category?.name}</p>
        </div>

        <div className="user-detail-box">
          <h4>GST</h4>
          <p>{product?.gst}</p>
        </div>

        <div className="user-detail-box">
          <h4>Manufacturer</h4>
          <p>{product?.manufacturer}</p>
        </div>

        <div className="user-detail-box">
          <h4>Type</h4>
          <p>{product?.type?.name}</p>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Packaging</h4>
          <p>{product?.packaging}</p>
        </div>

        <div className="user-detail-box">
          <h4>Composition</h4>
          <p>{product?.composition}</p>
        </div>

        <div className="user-detail-box">
          <h4>Division</h4>
          <p>{product?.division?.name}</p>
        </div>
      </section>
    </div>
  );
};

export default AdminProductsRangeDetails;
