import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseUrl = `${apiURL}/client/reminder/`;
const baseUrlV1 = `${apiURL}/client/v1/reminder/`;

export const getReminder = async () => {
  const { data } = await axios.get(baseUrl, {
    headers: setAuth(),
  });

  return data;
};

export const getReminderForDashboard = async () => {
  const { data } = await axios.get(baseUrlV1 + "reminder-for-dashboard", {
    headers: setAuth(),
  });

  return data;
};

export const newReminder = async (reminder) => {
  const { data } = await axios.post(baseUrl, reminder, {
    headers: setAuth(),
  });

  return data;
};

export const deleteReminderById = async (reminderId) => {
  const { data } = await axios.delete(baseUrl+"delete", {
    params:{reminderId}, 
    headers: setAuth(),
  });

  return data;
};
