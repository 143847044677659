import {
  addExpense,
  editExpense,
  expenseApproval,
  getExpense,
  getExpenseForApproval,
  rejectApproval,
} from "./../../services/expenses.js";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "VIEW_EXPENSE":
      return action.payload;
    case "VIEW_DISTANCE":
      return action.payload;
    case "VIEW_APPROVED_EXPENSE":
      return action.payload;
    case "CREATE_EXPENSE":
      return [action.payload];
    case "APPROVE_EXPENSE":
      return {data:state.data};
    case "REJECT_EXPENSE":
      return {data:state.data};
    case "EDIT_EXPENSE":
      return { data:state.data };
    case "CLEAR_EXPENSE":
      return initialState;

    default:
      return state;
  }
};

export const viewExpense = (filter) => {
  return async (dispatch) => {
    const { data } = await getExpense(filter);
    
    dispatch({
      type: "VIEW_EXPENSE",
      payload: { data: data },
    });
  };
};

export const viewDistance = () => {
  return async (dispatch) => {
    const { data } = await getExpense();

    dispatch({
      type: "VIEW_DISTANCE",
      payload: { data: data },
    });
  };
};

export const setExpenses = () => {
  return async (dispatch) => {
    const { data } = await getExpenseForApproval();

    dispatch({
      type: "VIEW_APPROVED_EXPENSE",
      payload: { data: data },
    });
  };
};

export const newExpense = (data) => {
  return async (dispatch) => {
    try {
      data = await addExpense(data);
    } catch (e) {
      // if (data.error) throw data.error;
      throw e;
    }
  };
};

export const editExpenseInApproval = (id, data) => {
  return async (dispatch) => {
    try {
      data = await editExpense(id, data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "EDIT_EXPENSE",
      payload: { data: data },
    });
    dispatch(setExpenses());
  };
};

export const approveExpense = (id, data) => {
  return async (dispatch) => {
    try {
      data = await expenseApproval(id, data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "APPROVE_EXPENSE",
      payload: { data: data },
    });
    dispatch(setExpenses());
  };
};

export const rejectExpense = (id, data) => {
  return async (dispatch) => {
    try {
      data = await rejectApproval(id, data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "REJECT_EXPENSE",
      payload: { data: data },
    });
    dispatch(setExpenses());
  };
};

export const clearExpense = () => {
  return {
    type: "CLEAR_EXPENSE",
  };
};

export default reducer;
