import AdminLink from "../../Admin/AdminIndex/Link";

const CRM = () => {
  return (
    <div className="main-content">
      <div className="admin-panel_content">
        <div className="row">
          <div className="admin-panel__box-container">
            <AdminLink
              className={"admin-panel__cards"}
              title="Doctor"
              type="Doctors"
              link="doctor/business"
            />
            <AdminLink
              className={"admin-panel__cards"}
              title="report"
              type="report"
              link="report/doctor-sales"
            />
             <AdminLink
              className={"admin-panel__cards"}
              title="settings"
              type="settings"
              link="settings/chemist-sales"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CRM;
