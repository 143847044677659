
import Table from "../../../../../components/Table";
import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageTitle from "../../../../../components/PageTitle";
import {
  viewAllowances,
  clearAllowance,
  deleteAllowance,
} from "../../../../../reducers/expenses/allowance";
import serialise from "../../../../../utils/serialiseResponse";




const AdminViewAllowance = () => {
  const allowanceData = useSelector(({ allowance }) => allowance);
  const dispatch = useDispatch();
  const columnData =useMemo(
    () => [
      {
        title: "From Distance",
        field: "fromDistance",
      },
      { title: "To Distance", field: "toDistance" },
      {
        title: "Travel Allowance",
        field: "travelAllowance",
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(viewAllowances());

    return  () => {dispatch(clearAllowance());}
  }, [dispatch]);

  const tableData = useMemo(
    () => serialise(allowanceData.data.data),
    [allowanceData]
  );

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="View Allowances" />

        <section className="area-creation-content__form">


          <div className="filter-table">
          <Table
              columns={ columnData }
              data={tableData}
            />
            {/* <MaterialTable
              editable={{
                onRowDelete: (newRow, oldRow) =>
                  new Promise((resolve, reject) => {
                    dispatch(
                      deleteAllowance({ type: "exStation" }, newRow._id)
                    );
                    setTimeout(() => resolve(), 500);
                  }),
              }}
              options={{
                exportButton: true,
                emptyRowsWhenPaging: false,
                actionsColumnIndex: -1,
                toolbar: false,
                pageSizeOptions: [5, 10, 20, 50, 100],
              }}
              columns={columnData}
              data={tableData}
            /> */}
          </div>
        </section>
      </div>
    </main>
  );
};

export default AdminViewAllowance;
