import { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../../../components/PageTitle";
import {
  approveExpense,
  clearExpense,
  editExpenseInApproval,
  rejectExpense,
  setExpenses,
} from "../../../../reducers/expenses/expense";
import { BsCheck2Circle, BsXCircle } from "react-icons/bs";
import serialise from "../../../../utils/serialiseResponse";
import { RiCloseCircleLine } from "react-icons/ri";
import Table from "../../../../components/Table";
import {
  MdOutlinePendingActions,
  MdArrowForwardIos,
  MdArrowBackIos,
  MdInfoOutline,
  MdCameraAlt,
} from "react-icons/md";
import { IoMdRadioButtonOff, IoIosRadioButtonOn, IoMdEye } from "react-icons/io";
import { ImRadioUnchecked } from "react-icons/im";
import Carousel from "react-bootstrap/Carousel";
import { updateTrigger } from "../../../../reducers/approveLengthReducer";
import customToast from "../../../../components/CustomToast";
import { viewTPForDate } from "../../../../services/tourProgram";
import { viewUsersWithPendingExpense } from "../../../../services/expenses";
import { monthOptions } from "../../../../utils/helper";

const formatDate = (date) => {
  return new Date(date).toLocaleString("en-us", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

const ApprovalExpense = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const expenseData = useSelector(({ expense }) => expense);

  const [showEdit, setShowEdit] = useState(false);
  const [approvalData, setApprovalData] = useState();
  const [imageShow, setImageShow] = useState(false);
  const [image, setImage] = useState();
  const [showRemark, setShowRemark] = useState(false);
  const [remarkData, setRemarkData] = useState();
  const [toggle, setToggle] = useState(false);
  const [allusersData, setallusersData] = useState([]);


  const fetchData = useCallback(async() => {
    try {
      const res = await viewUsersWithPendingExpense();
      setallusersData(res?.data);
    } catch (error) {
      console.log(error);
      customToast.error("Error occurred !");
    }
  },[]);

  useEffect(() => {
    fetchData();
  },[]);

  const EditPopup = ({ data }) => {
    const dispatch = useDispatch();
    const [remarks, setRemarks] = useState("");
    const [tpData, setTpData] = useState();
    const [areaType, setAreaType] = useState("");
    const [travelAllowance, setTravelAllowance] = useState(0);
    const [ticketAllowance, setTicketAllowance] = useState(0);
    const [foodAllowance, setFoodAllowance] = useState(0);
    const [hotelAllowance, setHotelAllowance] = useState(0);
    const [misExpense, setMisExpense] = useState(0);
    const [gpsDistance, setGpsDistance] = useState(0) ;
    const [gpsTA, setGPSTA] = useState(0) ;
    const [distance, setDistance] = useState(0) ;
    const [useGps, setUseGps] = useState(false) ;

    useEffect(() => {
      setAreaType(data.areaType);
      setTravelAllowance(data.travelAllowance);
      setFoodAllowance(data.foodAllowance);
      setHotelAllowance(data.hotelAllowance);
      setTicketAllowance(data.ticketAllowance);
      setMisExpense(data.misExpense);
      setGPSTA(data?.gpsTA);
      setGpsDistance(data?.gpsDistance);
      viewTPForDate({ date: data.date, selectedUser: data?.submitter?._id, status:"Approved"  }).then((res) => {
        setTpData(res.data);
      });
    }, [data]);
    useEffect(()=>{
      let totalDistance = 0;
      tpData?.workAreas?.forEach((area) => {
        totalDistance += Number(area.location.distance);
      });
      setDistance(totalDistance) ;
    },[tpData]) ;

    const handleApprove = async (e) => {
      let updatedData = {
        ...data,
        areaType: areaType,
        travelAllowance: travelAllowance,
        foodAllowance: foodAllowance,
        hotelAllowance: hotelAllowance,
        ticketAllowance: ticketAllowance,
        misExpense: misExpense,
      };

      if (
        updatedData.areaType === data.areaType &&
        updatedData.travelAllowance === data.travelAllowance &&
        updatedData.foodAllowance === data.foodAllowance &&
        updatedData.hotelAllowance === data.hotelAllowance &&
        updatedData.ticketAllowance === data.ticketAllowance &&
        updatedData.misExpense === data.misExpense
      ) {
        dispatch(approveExpense(data?._id, {...data, adminRemarks:remarks}));
        setShowEdit(false);
      } else {
        if (remarks.length === 0) {
          customToast.error("Please Fill Remark");
        } else {
          // dispatch(editExpenseInApproval(updatedData?._id, {...updatedData, remarks})); no longer needed
          dispatch(approveExpense(data?._id, {...updatedData, adminRemarks:remarks}));
          setShowEdit(false);
        }
      }
    };

    const handleReject = async () => {
      dispatch(rejectExpense(data?._id, {...data, adminRemarks:remarks}));
    };

    return (
      <section
        className="edit-popup"
        onClick={(e) =>
          showEdit
            ? e.target.className === "edit-popup"
              ? setShowEdit(false)
              : null
            : null
        }
      >
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Edit Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form>
            <div className="edit-form">
              <div className="edit-form__expense">
                {areaType && areaType.toLowerCase() === "ex-station" ? (
                  <div>
                    { useGps ? <IoMdRadioButtonOff className="tp__activity-types-icon-7 cursor-pointer me-2" 
                      onClick={()=>{ setTravelAllowance(data.travelAllowance); setUseGps(false);}}
                    /> 
                      : <IoIosRadioButtonOn className="tp__activity-types-icon-9 me-2"/>
                    }
                    <label htmlFor="travel">
                      TA  &nbsp; ({distance*2}&nbsp;km)<span className="asterisk-imp ">*</span>
                    </label>
                    <br />
                    <input
                      type="text"
                      id="travel"
                      className="me-5"
                      placeholder="TA"
                      value={travelAllowance}
                      onChange={({ target }) =>
                        setTravelAllowance(Number(target.value))
                      }
                    />
                  </div>
                ) : null}
                {/* {areaType && areaType.toLowerCase() === "ex-station" ? (
                  <div>
                    <label htmlFor="travel">
                      GPS Distance 
                    </label>
                    <br />
                    <input
                      type="text"
                      id="travel"
                      className="me-5"
                      placeholder="GPS Distance"
                      value={gpsDistance}
                      disabled={true}
                    />
                  </div>
                ) : null} */}
                {areaType && areaType.toLowerCase() === "ex-station" ? (
                  <div>
                    { useGps ? <IoIosRadioButtonOn className="tp__activity-types-icon-9 me-2"/> 
                      : <IoMdRadioButtonOff className="tp__activity-types-icon-7 cursor-pointer me-2"
                        onClick={()=> { setTravelAllowance(gpsTA); setUseGps(true); }}
                      />
                    }
                    <label htmlFor="travel">
                      GPS TA &nbsp; ({gpsDistance}&nbsp;km)
                    </label>
                    <br />
                    <input
                      type="text"
                      id="travel"
                      className="me-5"
                      placeholder="GPS TA"
                      value={gpsTA}
                      disabled={true}
                    />
                  </div>
                ) : null}

                {areaType && areaType.toLowerCase() === "out-station" ? (
                  <>
                    <div>
                      <label htmlFor="food">
                        Food <span className="asterisk-imp">*</span>
                      </label>
                      <br />
                      <input
                        type="text"
                        id="food"
                        className="me-5"
                        placeholder="Food"
                        value={foodAllowance}
                        onChange={({ target }) =>
                          setFoodAllowance(Number(target.value))
                        }
                      />
                    </div>

                    <div>
                      <label htmlFor="hotel">
                        Hotel <span className="asterisk-imp">*</span>
                      </label>
                      <br />
                      <input
                        type="text"
                        id="hotel"
                        className="me-5"
                        placeholder="Hotel"
                        value={hotelAllowance}
                        onChange={({ target }) =>
                          setHotelAllowance(Number(target.value))
                        }
                      />
                    </div>

                    <div>
                      <label htmlFor="ticket">
                        Ticket <span className="asterisk-imp">*</span>
                      </label>
                      <br />
                      <input
                        type="text"
                        id="ticket"
                        className="me-5"
                        placeholder="Ticket"
                        value={ticketAllowance}
                        onChange={({ target }) =>
                          setTicketAllowance(Number(target.value))
                        }
                      />
                    </div>
                  </>
                ) : null}

                <div>
                  <label htmlFor="packaging">
                    Mis Expense <span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    id="packaging"
                    className="me-5"
                    placeholder="packaging"
                    value={misExpense}
                    onChange={({ target }) =>
                      setMisExpense(Number(target.value))
                    }
                  />
                </div>
              </div>
            </div>

            <div className="expense-remarks">
              <label htmlFor="remarks">
               Remarks <span className="text-lowercase">(remarks are only mandatory if changes are made)</span>
              </label>
              <textarea
                name="remarks"
                id="remarks"
                cols={30}
                rows={2}
                className="w-100"
                placeholder="Enter Remarks"
                value={remarks}
                onChange={({ target }) => {
                  setRemarks(target.value);
                }}
              ></textarea>
            </div>
            <div className="util-inner-info-box mt-5">
              <h3>Work Areas</h3>
              <p>
                {
                  tpData?.workAreas?.map((el) => el?.location?.label + ",  ")
                }
              </p>
            </div>
            <div className="edit-form-button-container">
              <div className="d-flex justify-content-between w-100">
                <button
                  type="submit"
                  className="button-submit-green mt-4 me-5"
                  onClick={(e) => {
                    e.preventDefault();
                    handleApprove();
                  }}
                >
                  <span className="button-submit-green__icon-container">
                    <BsCheck2Circle className="button-submit-green__icon" />
                  </span>
                  <span className="button-submit-green__text">Approve</span>
                </button>

                <button
                  type="submit"
                  className="button-delete mt-4"
                  onClick={(e) => {
                    e.preventDefault();
                    handleReject();
                    setShowEdit(false);
                  }}
                >
                  <span className="button-delete__icon-container">
                    <BsXCircle className="button-delete__icon" />
                  </span>
                  <span className="button-delete__text">Reject</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  };
  const ImagePopup = ({ data }) => {
    return (
      <section
        className="edit-popup"
        onClick={(e) =>
          imageShow
            ? e.target.className === "edit-popup"
              ? setImageShow(false)
              : null
            : null
        }
      >
        <Carousel
          slide={false}
          interval={null}
          nextIcon={<MdArrowForwardIos style={{ fontSize: "2em" }} />}
          prevIcon={<MdArrowBackIos style={{ fontSize: "2em" }} />}
        >
          {data?.files && data?.files.map((e) => {
            return (
              <Carousel.Item>
                <img src={e} alt="Expense Img" />
              </Carousel.Item>
            );
          })}
        </Carousel>
      </section>
    );
  };
  const RemarkPopup = ({ data }) => {
    return (
      <section
        className="edit-popup"
        onClick={(e) =>
          showRemark
            ? e.target.className === "edit-popup"
              ? setShowRemark(false)
              : null
            : null
        }
      >
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Remarks</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowRemark(false)}
            />
          </div>
          {data.remarks}
        </div>
      </section>
    );
  };
  const setEditForm = (data) => {
    setShowEdit(false);
    setApprovalData(data);
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 70,
      //   maxWidth: 70,
      // },
      {
        Header: "Employee Name",
        accessor: "submitter.firstName",
        // Cell:(props) => <span>{props.row.original.submitter?.firstName}</span>
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
        minWidth: 90,
        maxWidth: 90,
        disableFilters: true,
      },
      {
        Header: "Area Type",
        accessor: "areaType",
        disableSortBy: true,
      },
      {
        Header: "Travel",
        accessor: "travelAllowance",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Food",
        accessor: "foodAllowance",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => (
          <span className={props?.row?.original?.editedAllowance?.foodAllowance === true ? 'text-warning' : ""}>
            {props?.row?.original?.foodAllowance}
          </span>
        )
      },
      {
        Header: "Hotel",
        accessor: "hotelAllowance",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => (
          <span className={props?.row?.original?.editedAllowance?.hotelAllowance === true ? 'text-warning' : ""}>
            {props?.row?.original?.hotelAllowance}
          </span>
          // <div style={{ position: "", paddingRight: "" }}>
          //   <span>{props?.row?.original?.hotelAllowance}</span>
          //   {props?.row?.original?.editedAllowance?.hotelAllowance === true && (
          //     <span
          //     className="text-warning"
          //       style={{
          //         position: "absolute",
          //         top: "0",
          //         right: "0",
          //         fontSize: "0.8rem",
          //         fontWeight: "bold",
          //         padding: "0.5rem"
          //       }}
          //     >
          //       edited
          //     </span>
          //   )}
          // </div>
          
        )
      },
      {
        Header: "Ticket",
        accessor: "ticketAllowance",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Daily",
        accessor: "dailyAllowance",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Misc.",
        accessor: "misExpense",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Total",
        accessor: "totalExpense",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => (
          <span>
            {props.row.original?.travelAllowance +
              props.row.original?.misExpense +
              props.row.original?.dailyAllowance +
              props.row.original?.foodAllowance +
              props.row.original?.hotelAllowance +
              props.row.original?.ticketAllowance}
          </span>
        ),
      },
      {
        Header: "Remarks",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 100,
        Cell: (props) => {
          return (
            <button
              disabled={!props?.row?.original?.remarks?.length}
              className="react-table-view-link bg-transparent border-0"
              onClick={() => {
                setRemarkData(props?.row?.original);
                setShowRemark(true);
              }}
            >
              <MdInfoOutline
                className={`icon-color-tertiary ${
                  !props?.row?.original?.remarks?.length && "opacity-25"
                }`}
              />
            </button>
          );
        },
      },
      {
        Header: "View",
        accessor: "View",
        Cell: (props) => {
          return (
            <button
              disabled={props?.row?.original?.files?.length > 0 ? false : true}
              className="react-table-view-link bg-transparent border-0"
              onClick={() => {
                setImage(props.row.original);
                setImageShow(true);
              }}
            >
              <MdCameraAlt
                className={`icon-color-tertiary ${
                  props?.row?.original?.files?.length > 0 ? null : "opacity-25"
                }`}
              />
            </button>
          );
        },
        minWidth: 50,
        maxWidth: 50,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: (props) => {
          return (
            <span
              className="react-table-view-link"
              onClick={() => {
                setEditForm(props.row.original);
                setShowEdit(true);
              }}
            >
              <MdOutlinePendingActions className="icon-color-yellow" />
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  const columns2 = useMemo(
    () => [
      {
        Header:"Month",
        accessor:"month",
        Cell:(props) => {
          let data = props?.row?.original;
          let month = monthOptions.find((el) => el?.value === data?.month);

          return <span>{month?.label}</span>
        }
      },
      {
        Header: "Year",
        accessor:"year",
        maxWidth:"100px",
        disableSortBy: false,
        disableFilters:false
      },
      {
        Header:"Name",
        accessor:"user.firstName",
        Cell:(props) => {
          let data = props?.row?.original;;
          return <span>
            {`${data?.user?.firstName} ${data?.user?.lastName}`}
          </span>
        }
      },
      {
        Header:"Designation",
        accessor:"user.des.name",
        Cell:(props) => {
          let data = props?.row?.original;;
          return <span>
            {`${data?.user?.des?.name}`}
          </span>
        }
      },
      {
        Header:"Reporting Manager",
        accessor:"user.reportingManager.firstName",
        Cell:(props) => {
          let data = props?.row?.original;;
          return <span>
            {`${data?.user?.reportingManager?.firstName} ${data?.user?.reportingManager?.lastName}`}
          </span>
        }
      },
      {
        Header:"View",
        accessor:"",
        Cell:(props) => {
          return <button className="react-table-view-link bg-transparent border-0">
            <IoMdEye size={25} className="icon-color-tertiary" onClick={() => navigate("./monthly-view",{state: props?.row?.original})}/>
          </button>
        }
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(setExpenses());

    return () => dispatch(clearExpense());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      updateTrigger({ index: location?.state?.index, data: expenseData?.data })
    );
  }, [expenseData?.data]);

  // let tableData = useMemo(() => serialise(expenseData.data), [expenseData]);
  let tableData = expenseData.data;
  // console.log(expenseData.data)
  // console.log(tableData)
  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Approve Expense" />

        <section className="area-creation-content__info ">

          <div className="d-flex justify-content-between align-items-center">

            {toggle ? (
            allusersData?.length > 0 && (
              <h2 className="web-app__heading">
                Showing ({allusersData?.length}) Entries
              </h2>
            )
            )
            :
            (tableData.length > 0 && (
              <h2 className="web-app__heading">
                Showing ({tableData.length}) Entries
              </h2>
            ))
          }

        <div className="form__control pe-3" style={{ width: "fit-content", color:"#C7C4E9" }}>
          <label className="toggle-label2 me-3 mb-1">
            <input
              type="checkbox"
              checked={toggle}
              onClick={() => {setToggle(!toggle);
              }
              }
            />
            <span />
          </label>
          <h2 className="web-app__heading">View Monthly Expense</h2>
        </div>
        </div>
          <div className="filter-table">
            {toggle ? <Table columns={columns2} data={allusersData} /> : <Table columns={columns} data={tableData} />}
            
          </div>
          {showEdit ? <EditPopup data={approvalData} /> : null}
          {imageShow ? <ImagePopup data={image} /> : null}
          {showRemark ? <RemarkPopup data={remarkData} /> : null}
        </section>
      </div>
    </div>
  );
};

export default ApprovalExpense;
