import React, { useMemo } from 'react'
import PageTitle from '../../../../../components/PageTitle';
import { useLocation, Link } from "react-router-dom";
import Table from '../../../../../components/Table';
import { FaEye } from 'react-icons/fa';
import serialise from '../../../../../utils/serialiseResponse';

const DeletedInventory = () => {
  const location = useLocation();
  const data = location?.state?.products


  const columns = React.useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header:"Product Name",
        accessor:"product.label",
      },
      {
        Header:"Batch No",
        accessor:"batchNo",
      },
      {
        Header:"Expiry Date",
        accessor: "expiryDate",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header:"Quantity",
        accessor: "quantity",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header:"Unit Price",
        accessor: "price",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header:"Total Price",
        accessor: "totalPrice",
        disableFilters: true,
        disableSortBy: true,
      }
    ],
    [data]
  );
  const tableData = useMemo(() => serialise(data), [data]);
  return (
    <main className="main-content admin-content">
      <div className="area-creation-content ">
        <PageTitle title="Deleted Inventory" />
      </div>
      <section className=" table-container" style={{ marginRight: "2rem" }}>
        <div className="filter-table">
          {tableData?.length > 0 ? (
            <Table columns={columns} data={tableData} />
          ) : (
            <h3 className="mt-2 no-results-message">No data found</h3>
          )}
        </div>
      </section>
    </main>
  );
}

export default DeletedInventory