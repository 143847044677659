import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURL = `${apiURL}/client/leave-request`;
const baseURLV1 = `${apiURL}/client/v1/leave-request`;
// const approveURL = `${apiURL}/api/admin/leave-request`;
// const rejectURL = `${apiURL}/api/admin/leave-request/reject`;
// const approveGetURL = `${apiURL}/api/admin/leave-request/leaves-for-approval`;

export const addLeaveRequest = async (details) => {
  const { data } = await axios.post(baseURLV1, details, {
    headers: setAuth(),
  });

  return data;
};

export const approveLR = async (id, details) => {
  const { data } = await axios.post(`${baseURL}/approve/${id}`, details, {
    headers: setAuth(),
  });

  return data;
};

export const rejectLR = async (id, details) => {
  const { data } = await axios.post(`${baseURL}/reject/${id}`, details, {
    headers: setAuth(),
  });

  return data;
};

export const viewLeaves = async (filter) => {
  const { data } = await axios.get(baseURL, {
    params: filter,
    headers: setAuth(),
  });

  return data;
};

export const viewLeavesForApproval = async () => {
  const { data } = await axios.get(`${baseURL}/leaves-for-approval`, {
    headers: setAuth(),
  });

  return data;
};

// method to delete Leave Request
export const deleteLeaveRequest = async (id) => {
  const { data } = await axios.delete(`${baseURL}/${id}`, {
    headers: setAuth(),
  });

  return data;
};