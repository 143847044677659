import {
  dayPlanApproval,
  newDayPlan,
  viewDayPlan,
} from "../services/dayPlan.js";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "VIEW_DAY_PLAN":
      return action.payload;
    case "VIEW_DAY_PLAN_FOR_DCR":
      return action.payload;
    case "VIEW_DAY_PLAN_FOR_APPROVAL":
      return action.payload;
    case "CREATE_DAY_PLAN":
      return action.payload;
    case "APPROVE_DAY_PLAN":
      return {data:state.data};
    case "REJECT_DAY_PLAN":
      return {data:state.data};
    case "CLEAR_DAY_PLAN":
      return initialState;

    default:
      return state;
  }
};

export const setDayPlan = (details) => {
  return async (dispatch) => {
    const { data } = await viewDayPlan(details);

    dispatch({
      type: "VIEW_DAY_PLAN",
      payload: { data: data },
    });
  };
};

export const setDayPlanForDCR = (details) => {
  return async (dispatch) => {
    const data = await viewDayPlan("dcr", details);

    dispatch({
      type: "VIEW_DAY_PLAN_FOR_DCR",
      payload: { data: data },
    });
  };
};

export const setDayPlanForApproval = (details) => {
  return async (dispatch) => {
    const { data } = await viewDayPlan("approval");

    dispatch({
      type: "VIEW_DAY_PLAN_FOR_APPROVAL",
      payload: { data: data },
    });
  };
};

export const addDayPlan = (data) => {
  return async (dispatch) => {
    try {
      data = await newDayPlan(data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "CREATE_DAY_PLAN",
      payload: data,
    });
  };
};

export const approveDayPlan = (id, data) => {
  return async (dispatch) => {
    try {
      data = await dayPlanApproval("approve", id, data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "APPROVE_DAY_PLAN",
      payload: { data: data },
    });
    dispatch(setDayPlanForApproval());
  };
};

export const rejectDayPlan = (id, data) => {
  return async (dispatch) => {
    try {
      data = await dayPlanApproval("reject", id, data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "REJECT_DAY_PLAN",
      payload: { data: data },
    });
    dispatch(setDayPlanForApproval());
  };
};

export const clearDayPlan = () => {
  return {
    type: "CLEAR_DAY_PLAN",
  };
};

export default reducer;
