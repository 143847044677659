import { Outlet } from "react-router-dom";
import CreationNav from "../CreationSidenav";
import { title, links } from "./navData.js";

const CreationBacklogReports = () => {
  return (
    <>
      <CreationNav title={title} links={links} />

      <Outlet />
    </>
  );
};

export default CreationBacklogReports;
