import { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { adminStyles } from "../../../../public/stylesheets/selectStyles";
import serialise, {
  generateSelectData,
} from "../../../../../utils/serialiseResponse";
import {
  clearStates,
  setStates,
} from "../../../../../reducers/locations/stateReducer";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../../reducers/locations/hqReducer";
import {
  addCityForApproval,
  clearCities,
  setCities,
  setCreatedCities,
} from "../../../../../reducers/locations/cityReducer";
import customToast from "../../../../../components/CustomToast";
import PageTitle from "../../../../../components/PageTitle";
import Table from "../../../../../components/Table";

const CreationCity = () => {
  const dispatch = useDispatch();
  const states = useSelector(({ state }) => state);
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const cities = useSelector(({ city }) => city);

  const stateSelect = useMemo(
    () => generateSelectData(states.data, "name"),
    [states]
  );
  const hqSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );

  const typeSelect = [
    { value: 1, label: "City" , areaType: "city"},
    { value: 2, label: "Local Area" , areaType: "localArea"},
  ];

  const [state, setState] = useState(null);
  const [headquarter, setHeadquarter] = useState();
  const [city, setCity] = useState("");
  const [areaData, setAreaData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [type, setType] = useState(typeSelect[0]);
  
  // const tableData = useMemo(() => serialise(cities.data), [cities]);

  const tableData = useMemo(() => {
    return type.value === 1 ? serialise(cityData) : serialise(areaData)
   }, [type, areaData, cityData]);

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "City",
        accessor: "name",
      },
      {
        Header: "HQ",
        accessor: "headquarter",
      },
      {
        Header: "State",
        accessor: "state",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "isApproved",
        Cell: (props) => {
          return props.row.original?.isApproved === 1 ? (
            <span className="approved-text">Approved</span>
          ) : props.row.original?.isApproved === -1 ? (
            <span className="rejected-text">Rejected</span>
          ) : (
            <span className="pending-text">Pending</span>
          );
        },
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [cities, tableData]
  );

  const areaColumns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Local Area",
        accessor: "name",
      },
      {
        Header: "UID",
        accessor: "uid",
      },
      {
        Header: "HQ",
        accessor: "headquarter",
      },
      {
        Header: "State",
        accessor: "state",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "isApproved",
        Cell: (props) => {
          return props.row.original?.isApproved === 1 ? (
            <span className="approved-text">Approved</span>
          ) : props.row.original?.isApproved === -1 ? (
            <span className="rejected-text">Rejected</span>
          ) : (
            <span className="pending-text">Pending</span>
          );
        },
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  useEffect(() => {
    setHeadquarter(null)
  }, [state])

  useEffect(() => {
    dispatch(setStates());
    dispatch(setCities("", type.areaType, "true"));
    // dispatch(setCreatedCities( {isStatus:"true"} ));
    return () => {
      dispatch(clearStates());
      dispatch(clearCities());
    };
  }, [dispatch, state, type]);

  useEffect(() => {
    if (!state) return;

    dispatch(setHeadquarters(state.value));
    return () => {
      dispatch(clearHeadquarters());
    };
  }, [dispatch, state]);

  useEffect(() => {
    setAreaData(cities?.data?.filter((e) => e.type === "localArea"));
    setCityData(cities?.data?.filter((e) => e.type !== "localArea"));
  }, [type, cities])

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!state || !headquarter || !city)
      return customToast.error("Please fill all details");

      var existingHq = headquarters.data.findIndex(p => (p.name.toLocaleLowerCase() !== headquarter.label.toLocaleLowerCase() && p.name.toLocaleLowerCase() === city.toLocaleLowerCase()));
      
      var index = tableData.findIndex(p => (p.name).toLocaleLowerCase() === city.toLocaleLowerCase() && (p.headquarter).toLocaleLowerCase() === headquarter.label.toLocaleLowerCase() && (p.isApproved === 0 || p.isApproved === 1) );

    if(index === -1 && existingHq === -1){
      try {

        const payload = {
          name: city,
          stateId: state.value,
          headquarterId: headquarter.value,
        }

        if(type.value === 1) payload.type = "city"
        if(type.value === 2) payload.type = "localArea"

        await dispatch(
          // addCityForApproval({
          //   name: city,
          //   stateId: state.value,
          //   headquarterId: headquarter.value,
          //   type : "city"
          // })
          addCityForApproval(payload)
        );
        // customToast.success("City Successfully Created");
        customToast.success(`${type.value === 1 ? "City" : "Area"} Successfully Created`);
        setState(null);
        setHeadquarter(null);
        setCity("");
        dispatch(setCities("", type.areaType));
        setAreaData(cities?.data?.filter((e) => e.type === "localArea"));
        setCityData(cities?.data?.filter((e) => e.type !== "localArea"));
      } catch (e) {
        customToast.error("We Encountered an Error");
      }
    }
    else{

      if(existingHq !== -1){
        customToast.error("Cannot Add One Headqarter Under Another!");
      }
      else if(tableData[index].isApproved){
        // customToast.error("City Already Exists!");
        customToast.error(`${type.value === 1 ? "City" : "Area"} Already Exists!`)
      }
      else{
        // customToast.error("City Awaiting Approval");
        customToast.error(`${type.value === 1 ? "City" : "Area"} Awaiting Approval`);
      }
      
    }
    
  };
  
  
  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle isCreation title="Create City / Area" />

        <section className="area-creation-content__form">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4 mb-5">
                <label htmlFor="type">
                  Type <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="type"
                  id="type"
                  value={type}
                  options={typeSelect}
                  onChange={(e) => setType({ value: e.value, label: e.label , areaType: e.areaType})}
                  styles={adminStyles}
                  placeholder="City"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">
                  Select State <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="state"
                  id="state"
                  value={state}
                  options={stateSelect}
                  onChange={(e) => setState({ value: e.value, label: e.label })}
                  styles={adminStyles}
                  placeholder="Select State"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">
                  Select HQ <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="headquarter"
                  id="headquarter"
                  value={headquarter}
                  options={hqSelect}
                  onChange={(e) =>
                    setHeadquarter({ value: e.value, label: e.label })
                  }
                  styles={adminStyles}
                  placeholder="Select Headquarter"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="city">
                  Enter City Name <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  type="text"
                  className="me-5"
                  id="city"
                  placeholder="Enter City Name"
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-2 col-lg-2 mt-5 admin-content-submit-button-left">
              <button type="submit" className="button-blue-gradient">
                Add City
              </button>
            </div>
          </form>
        </section>

        <section className="area-creation-content__info">
        {tableData.length > 0 && (
              <h2 className="web-app__heading mb-4 ms-2">
                {/* Showing ({tableData.length}) Entries */}
                 Showing ({tableData.length}) {type.value === 1 ? "Cities" : "Local Areas"}
              </h2>
            )}
          <div className="filter-table">
            {/* <Table columns={columns} data={tableData} /> */}
            <Table columns={type.value === 1 ? columns : areaColumns} data={tableData} />
          </div>
        </section>
      </div>
    </div>
  );
};

export default CreationCity;
