import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURL = `${apiURL}/client/analytics`;
const baseURLV1 = `${apiURL}/client/v1/analytics`;

export const viewPOBReports = async (type, filter) => {
  const { data } = await axios.get(baseURLV1 + "/pob-reports/" + type, {
    params: filter,
    headers: setAuth(),
  });

  return data;
};

export const viewProdReports = async (filter) => {
  const { data } = await axios.get(baseURL + "/prod-reports", {
    params: filter,
    headers: setAuth(),
  });

  return data;
};
export const viewProdWiseSecReports = async (filter) => {
  const { data } = await axios.get(baseURL + "/prod-reports/secondary-sales", {
    params: filter,
    headers: setAuth(),
  });

  return data;
};
export const viewProdWisePrimaryReports = async (filter) => {
  const { data } = await axios.get(baseURL + "/prod-reports/primary-sales", {
    params: filter,
    headers: setAuth(),
  });

  return data;
};
export const viewUserWiseProdReports = async (filter) => {
  const { data } = await axios.get(baseURL + "/prod-reports/user", {
    params: filter,
    headers: setAuth(),
  });

  return data;
};
export const viewProdWiseReportDetails = async (filter) => {
  const { data } = await axios.get(baseURL + "/prod-reports/primary-sales/details", {
    params: filter,
    headers: setAuth(),
  });

  return data;
};
export const viewMissedReportDetails = async (docId, filter) => {
  const { data } = await axios.get(
    baseURL + "/missed-reports-detail/" + docId,
    {
      params: filter,
      headers: setAuth(),
    }
  );

  return data;
};

export const viewPobWiseReportDetails = async (type, docId, filter) => {
  const { data } = await axios.get(
    `${baseURL}/pob-reports-detail/${type}/${docId}`,
    {
      params: filter,
      headers: setAuth(),
    }
  );

  return data;
};


