import { newDCR } from '../services/reports.js';

const initialState = {
	date: null,
	visitType: null,
	visitTime: null,
	fromAreas: [],
	toAreas: [],
	doctors: [],
	focusProducts: [],
	otherProducts: [],
	pobDetails: [
		{
			pobType: null,
			priceModel: null,
			focusProduct: null,
			quantity: null,
			amount: null,
		},
	],
	workedWith: [],
	remarks: null,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_DCR':
			return action.payload;

		case 'CREATE_DCR':
			return action.payload;

		default:
			return state;
	}
};

export const addDCR = (type, report) => {
	return async (dispatch) => {
		let data;

		try {
			data = await newDCR(type, report);
		} catch (e) {
			if (data.error) throw data.error;
		}

		dispatch({
			type: 'CREATE_DCR',
			payload: data,
		});
	};
};

export default reducer;
