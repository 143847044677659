import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURL = `${apiURL}/client/dashboard`;
const baseURL2 = `${apiURL}/client/v1/dashboard`;

export const getPrimaryAmount = async (details) => {
  const { data } = await axios.get(baseURL2 + "/monthly-primary", {
    headers: setAuth(),
    params: details,
  });

  return data;
};

export const getSecondaryAmount = async (details) => {
  const { data } = await axios.get(baseURL2 + "/monthly-secondary", {
    headers: setAuth(),
    params: details,
  });

  return data;
};

export const getBirthdaysForDashboard = async () => {
  const { data } = await axios.get(baseURL2 + "/birthdays", {
    headers: setAuth(),
  });

  return data;
};

export const getAnniversariesForDashboard = async () => {
  const { data } = await axios.get(baseURL2 + "/anniversaries", {
    headers: setAuth(),
  });

  return data;
};

export const getTargetAmount = async (details) => {
  const { data } = await axios.get(baseURL2 + "/monthly-target", {
    headers: setAuth(),
    params: details,
  });
  
  return data;
};

// Reports - Productivity Graph

export const getReports = async (filter) => {
  const { data } = await axios.get(baseURL2 + "/reports", {
    params: filter,
    headers: setAuth(),
  });

  return data;
};

// Call Average

export const callAverage = async (month, year) => {
  const { data } = await axios.get(baseURL + "/call-reports", {
    params: { month, year },
    headers: setAuth(),
  });

  return data;
};

// Doc Call Average

export const docCallAverage = async (filter) => {
  const { data } = await axios.get(baseURL + "/monthly-call-averages/doctor", {
    params: filter,
    headers: setAuth(),
  });

  return data;
};

export const chemCallAverage = async (filter) => {
  const { data } = await axios.get(baseURL + "/monthly-call-averages/chemist", {
    params: filter,
    headers: setAuth(),
  });

  return data;
};

export const stkCallAverage = async (filter) => {
  const { data } = await axios.get(
    baseURL + "/monthly-call-averages/stockist",
    {
      params: filter,
      headers: setAuth(),
    }
  );

  return data;
};

export const missedReportsForDashboard = async (filter) => {
  const { data } = await axios.get(baseURL2 + "/missed-reports-for-dashboard", {
    params: filter,
    headers: setAuth(),
  });
  return data;
};


export const totalPOBStats = async (filter) => {
  const { data } = await axios.get(baseURL2 + "/total-pob-stats", {
    params: filter,
    headers: setAuth(),
  });

  return data;
};

export const getAvgDailyVisits = async(payload) => {
  const {data} = await axios.get(baseURL2 + "/daily-avg-visits",{
    params: payload,
    headers: setAuth(),
  });
  return data;
};
