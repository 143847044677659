import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationBox from "./NotificationBox";

const Notification = ({socket}) => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(({user})=>user) ;
  const notifications = useSelector(({ notification }) => notification.alerts);

 
  return (
    <div className="main-content">
      <div className="notification-content">
        <h2 className="web-app__heading mb-5">Notification</h2>

        <div className="container">
          <div className="row">
            {notifications?.map((data) => (
                <NotificationBox
                  title={data.title}
                  time={data.time.slice(0, 10)}
                  description={data.message}
                  type={data.type}
                  key={data.id}
                />
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
