import { newDCR } from '../../services/reports.js';

const initialState = {
	date: null,
	visitType: null,
	areas: [],
	remarks: null,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'CREATE_DCR':
			return action.payload;

		default:
			return state;
	}
};

export const addDCR = (type, report) => {
	return async (dispatch) => {
		let data;

		try {
			data = await newDCR(type, report);
		} catch (e) {
			if (data.error) throw data.error;
		}

		dispatch({
			type: 'CREATE_DCR',
			payload: data,
		});
	};
};

export default reducer;
