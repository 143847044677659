import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Select from "react-select";

import { useNavigate,Link } from "react-router-dom";

import { RiEdit2Fill } from "react-icons/ri";

import PageTitle from "../../../../../components/PageTitle";
import Table from "../../../../../components/Table";
import {
  clearDoctors,
  setDoctors,
} from "../../../../../reducers/targets/doctor";
import {
  clearChemists,
  setAllChemists,
} from "../../../../../reducers/targets/chemist";
import {
  clearStockists,
  setAllStockists,
} from "../../../../../reducers/targets/stockist";
import serialise from "../../../../../utils/serialiseResponse";
import { adminStyles } from "../../../../public/stylesheets/selectStyles";
import { FaEye } from "react-icons/fa";

const typeOptions = [
  { value: 1, label: "Doctor" },
  { value: 2, label: "Chemist" },
  { value: 3, label: "Stockist" },
];

const CreationViewDCS = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const doctors = useSelector(({ doctor }) => doctor);
  const chemists = useSelector(({ chemist }) => chemist);
  const stockists = useSelector(({ stockist }) => stockist);

  const [type, setType] = useState(typeOptions[0]);

  const columnData = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Degree",
        accessor: "degree",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Specialization",
        accessor: "specialization",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Mobile Number",
        accessor: "mobile",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "HQ",
        accessor: "city",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Status",
        accessor: "isApproved",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 70,
        maxWidth: 70,
        Cell: (props) =>
          props?.row?.original?.isApproved === 1 ? (
            <span className="approved-text">Approved</span>
          ) : props?.row?.original?.isApproved === 0 ? (
            <span className="pending-text">Pending</span>
          ) : (
            <span className="rejected-text">Rejected</span>
          ),
      },
      {
        Header: "View",
        accessor: "details",
        Cell: (props) => (
          <span
            className="react-table-view-link"
            onClick={() =>
              navigate("/creation-menu/dcs/view-dcs/details", {
                state: { ...props?.row?.original, type },
              })
            }
          >
            <FaEye className="icon-color-tertiary" />
          </span>
        ),
        disableFilters: true,
        disableSortBy: true,
        minWidth: 70,
        maxWidth: 70,
      },
    ],
    [type, navigate]
  );

  const chemColumnData = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Business Name",
        accessor: "businessName",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Propreitor Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Address",
        accessor: "address",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
        Cell:(props) =>{
          const address = props?.row?.original?.address;
          if(!address){
            return null;
          }
          const words = (props?.row?.original?.address).split(' ');
          const truncateWords = words.slice(0,4).join(' ');

          return truncateWords;
        }
      },
      {
        Header: "Mobile Number",
        accessor: "chemistContact",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "HQ",
        accessor: "city",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Status",
        accessor: "isApproved",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 110,
        maxWidth: 110,
        Cell: (props) =>
          props?.row?.original?.isApproved === 1 ? (
            <span className="approved-text">Approved</span>
          ) : props?.row?.original?.isApproved === 0 ? (
            <span className="pending-text">Pending</span>
          ) : (
            <span className="rejected-text">Rejected</span>
          ),
      },
      {
        Header: "View",
        accessor: "details",
        Cell: (props) => (
          <span
            className="react-table-view-link"
            onClick={() =>
              navigate("/creation-menu/dcs/view-dcs/details-chemist", {
                state: { ...props?.row?.original, type },
              })
            }
          >
            <FaEye className="icon-color-tertiary" />
          </span>
        ),
        disableFilters: true,
        disableSortBy: true,
        minWidth: 70,
        maxWidth: 70,
      },
    ],
    [type, navigate]
  );

  const stkColumnData = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Business Name",
        accessor: "businessName",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Propreitor Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Address",
        accessor: "address",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
        Cell:(props) =>{
          const address = props?.row?.original?.address;
          if(!address){
            return null;
          }
          const words = (props?.row?.original?.address).split(' ');
          const truncateWords = words.slice(0,4).join(' ');

          return truncateWords;
        }
      },
      {
        Header: "Mobile Number",
        accessor: "stockistContact",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "HQ",
        accessor: "city",
        minWidth: 85,
        maxWidth: 85,
        Cell: (props) => {
          return (
            <span>
              {props?.row?.original?.city}
            </span>
          )
        }
      },
      {
        Header: "Status",
        accessor: "isApproved",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 110,
        maxWidth: 110,
        Cell: (props) =>
          props?.row?.original?.isApproved === 1 ? (
            <span className="approved-text">Approved</span>
          ) : props?.row?.original?.isApproved === 0 ? (
            <span className="pending-text">Pending</span>
          ) : (
            <span className="rejected-text">Rejected</span>
          ),
      },
      {
        Header: "View",
        accessor: "details",
        Cell: (props) => (
          <span
            className="react-table-view-link"
            onClick={() =>
              navigate("/creation-menu/dcs/view-dcs/details-stockist", {
                state: { ...props?.row?.original, type },
              })
            }
          >
            <FaEye className="icon-color-tertiary" />
          </span>
        ),
        disableFilters: true,
        disableSortBy: true,
        minWidth: 70,
        maxWidth: 70,
      },
    ],
    [type, navigate]
  );

  useEffect(() => {
    dispatch(setDoctors({ admin: true }));
    dispatch(setAllChemists({ type: "creation", admin: true}));
    dispatch(setAllStockists({ type: "creation", admin: true }));

    return () => {
      dispatch(clearDoctors());
      dispatch(clearChemists());

      dispatch(clearStockists());
    };
  }, [dispatch]);

  const tableData = useMemo(() => {
    return type?.label?.toLowerCase() === "doctor"
      ? serialise(doctors?.data)
      : type?.label?.toLowerCase() === "chemist"
      ? serialise(chemists?.data)
      : serialise(stockists?.data);
  }, [doctors?.data, chemists?.data, stockists?.data, type]);

  

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Edit / Delete..." />

        <section className="area-creation-content__info">
        <div className="admin-panel__alert" style={{padding:"1rem" , marginTop:"0", marginBottom:"1rem"}}>
            <h2 style={{marginBottom:"0"}}>Note</h2>
            <p>
              The below list shows only those entries which are entered by the loggedIn user.
              In order to view the complete list, visit ---{'>'} Reports ---{'>'} Lists or{' '} 
             <Link to="/utilities/lists/doctors-list">
              <button style={{background: 'none', border: 'none', color:'white'}}>
                   click here !
              </button>
             </Link>
            </p>
          </div>
          <form>
            <div className="col-sm-12 col-md-3 col-lg-3">
              <label htmlFor="type" style={{marginTop:"1rem"}}>Select Type</label>
              <br />
              <Select
                name="type"
                id="type"
                value={type}
                options={typeOptions}
                onChange={(e) => setType({ ...e })}
                styles={adminStyles}
                placeholder="Doc / Chem / Stk"
              />
            </div>
          </form>
          <div className="filter-table">
            {tableData.length > 0 && (
              <h2 className="web-app__heading ms-2 mb-4">
                Showing ({tableData.length}) Entries
              </h2>
            )}
            <Table
              columns={
                type?.label?.toLowerCase() === "doctor"
                  ? columnData
                  : type?.label?.toLowerCase() === "chemist"
                  ? chemColumnData
                  : stkColumnData
              }
              data={tableData}
            />
          </div>
        </section>
      </div>
    </main>
  );
};

export default CreationViewDCS;
