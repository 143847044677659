import { useState, useMemo, useEffect } from "react";
import PageTitle from "../../../../components/PageTitle";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import Select from "react-select";
import serialise, {
  generateSelectData
} from "../../../../utils/serialiseResponse";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../components/Table";
import { clearUsers, setUsers } from "../../../../reducers/users/employeeReducer";
import { viewRouteList } from "../../../../services/utilities";

const TotalRates = () => {
    const dispatch = useDispatch();
    const users = useSelector(({employee}) => employee);
    const [user, setUser] = useState(null);
    const [routes, setRoutes] = useState([]);

    useEffect(()=>{
        dispatch(setUsers("user"));
        return ()=>dispatch(clearUsers());
    },[dispatch]);

    const userSelect = useMemo(
        () => generateSelectData(users,"fullName"),
        [users]
    );

    useEffect(()=>{
        let filter = {
            hq : user?.headquarter?._id
        }
        const fetchRoutes = async()=>{
            viewRouteList(filter).then((response) =>{
                setRoutes(response?.data);
            });
        }
        if(user)
        {
          fetchRoutes();
        }
    },[user]);

  const exstationRoutes = routes.filter((el) => el?.areatype.toLowerCase() === "ex-station");

  const tableData = useMemo(() => serialise(exstationRoutes), [exstationRoutes]);

  const columns = useMemo(
    () => [
        // {
        //     Header: "Sr. No",
        //     accessor: "sr",
        //     disableFilters: true,
        //     minWidth: 60,
        //     maxWidth: 60,
        //   },
          {
            Header: "Ex-Station",
            accessor: "",
            disableFilters: true,
            minWidth: 60,
            maxWidth: 60,
            Cell:(props) => <span>{`${props.row.original.fromCity} - ${props.row.original.toCity}`}</span>
          },
          {
            Header: "Distance",
            accessor: "distance",
            disableFilters: true,
            minWidth: 60,
            maxWidth: 60,
          },
          {
            Header: "Travel Allowance",
            accessor: "",
            disableFilters: true,
            minWidth: 60,
            maxWidth: 60,
            Cell:(props) =>{
              let result = 0;
              props?.row?.original?.ta.map((el)=>{
                if(Number(user?.des) === Number(el?.des)){
                  result = el?.TA ;
                }
              })
              return result
            }
          },
    ],
    [tableData,user]
  );  

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Total Rates" />

        <section className="area-creation-content__form">
            <div className="util-inner-details">
                <div className="col-sm-12 col-md-4 col-lg-4">
                    <label htmlFor="employee">
                        Select User &nbsp;
                        <span className="asterisk-imp">*</span>
                    </label>
                    <br />
                    <Select
                    value={user}
                    options={userSelect}
                    onChange={(e) => setUser({...e})}
                    styles={adminStyles}
                    placeholder="Select User"/>
                </div>
            </div>
        </section>
        <section className="area-creation-content__form">
        <div className="util-inner-info-box-container">
                    <div className="util-inner-info-box">
                        <h3>DA</h3>
                        <p style={{color:"white"}}>{user?.dailyAllowance}</p>
                    </div>
                    <div className="util-inner-info-box">
                        <h3>Ex-station DA</h3>
                        <p style={{color:"white"}}>{user?.exStationAllowance}</p>
                    </div>
                    <div className="util-inner-info-box">
                        <h3>Out-Station DA</h3>
                        <p style={{color:"white"}}>{user?.outStationAllowance}</p>
                    </div>
                </div>
                {user ? 
            <div className="filter-table">
            <Table data={tableData} columns={columns}/>
            </div>
              : null}
        </section>
      </div>
    </main>
  );
};

export default TotalRates;
