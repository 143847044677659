import LeaveForm from "./Form";

const LeaveRequest = () => {
  return (
    <div className="main-content">
      <LeaveForm />
    </div>
  );
};

export default LeaveRequest;
