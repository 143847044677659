import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURL = `${apiURL}/client/e-detailing`;

export const createEDetailingProduct = async (details) => {
  const { data } = await axios.post(baseURL, details, {
    headers: setAuth(),
  });

  return data;
};

export const viewProductImages = async (details) => {
  const { data } = await axios.post(baseURL + "/view-product-images", details, {
    headers: setAuth(),
  });

  return data;
};

export const viewAllProductImages = async (page) => {
  const { data } = await axios.get(baseURL + "/view-all-product-images", {
    params: page,
    headers: setAuth(),
  });

  return data;
};

export const viewDoctorWiseProductImages = async (details) => {
  const { data } = await axios.post(
    baseURL + "/view-doctor-wise-product-images",
    details,
    {
      headers: setAuth(),
    }
  );

  return data;
};
export const viewSPLWiseProductImages = async (details) => {
  const { data } = await axios.post(
    baseURL + "/view-spl-wise-product-images",
    details,
    {
      headers: setAuth(),
    }
  );

  return data;
};


export const deleteProductImage = async (prodId, imageLink) => {
  const { data } = await axios.get(
    baseURL + `/delete-product-image/${prodId}`,
    { params: imageLink, headers: setAuth() }
  );

  return data;
};
