import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseUrl = `${apiURL}/client/geolocation`;
const baseUrl2 = `${apiURL}/client/v1/geolocation`;

export const getGeoLocationApprovalData = async(flag) => {
    const {data} = await axios.post(baseUrl + '/all-TaggedData', flag,{
        headers: setAuth()
    });
    return data;
};

export const updateApprovalStatus = async(payload, type) => {
    const {data} = await axios.put(baseUrl + `/Approve-Status/${type}`,payload,{
        headers: setAuth()
    });
    return data;
};

export const viewGeoLocationData = async(details) => {
    const {data} = await axios.get(baseUrl ,{
        params: details,
        headers : setAuth()
    } );
    return data;
};

export const viewGeolocationDCS = async(payload) => {
    const {data} = await axios.get(baseUrl2, {
        headers: setAuth(),
        params: payload
    });
    return data;
};

export const viewGeolocationDataForAnalysis = async(payload) => {
    const {data} = await axios.get(baseUrl2 + "/data",{
        headers: setAuth(),
        params: payload
    });
    return data;
}

export const untagGeolocationDCS = async (payload) => {
    const {data} = await axios.get(baseUrl2 + "/untag-dcs", {
        headers: setAuth(),
        params: payload
    });
    return data;
}