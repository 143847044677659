import { useLocation } from "react-router-dom";
import PageTitle from "../../../../../../components/PageTitle";
import { formatDate } from "../../../../../../utils/helper";

const CreationViewDCSDetailsChemist = () => {
  const location = useLocation();

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title={`Chemist Details`} hierarchy={true} />
      </div>
      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Name</h4>
          <p>{location?.state?.name}</p>
        </div>
        <div className="user-detail-box">
          <h4>Business Name</h4>
          <p>{location?.state?.businessName}</p>
        </div>
        <div className="user-detail-box">
          <h4>Working Area</h4>
          <p>{location?.state?.workingArea}</p>
        </div>
        <div className="user-detail-box">
          <h4>Address</h4>
          <p>{location?.state?.address}</p>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-yellowDark-border">
        <div className="user-detail-box">
          <h4>Certification</h4>
          <p>{location?.state?.certification}</p>
        </div>

        <div className="user-detail-box">
          <h4>Chemist Contact</h4>
          <p>{location?.state?.chemistContact}</p>
        </div>

        <div className="user-detail-box">
          <h4>E-mail</h4>
          <p>{location?.state?.email}</p>
        </div>
        <div className="user-detail-box">
          <h4>Birthday</h4>
          <p>{formatDate(location?.state?.birthday)}</p>
        </div>
      </section>
    </div>
  );
};

export default CreationViewDCSDetailsChemist;
