import { useEffect, useState, useMemo } from "react";
import { MdArrowBackIosNew, MdClear, MdDoneAll, MdOutlineGroup } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { selectUserStyles } from "../../public/stylesheets/selectStyles";
import DateRange from "../../../components/DateRange";
import { addDays } from "date-fns";
import serialise, { generateSelectUser } from "../../../utils/serialiseResponse";
import { viewUsersForUtilities } from "../../../services/utilities";
import { formatDate } from "../../../utils/helper";
import Select from "react-select";
import Table from "../../../components/Table";
import { FaEye } from "react-icons/fa";
import { viewBacklogReport } from "../../../services/dcr";
const AdminBacklogReports = () => {
    const navigate = useNavigate();
    const loggedIn = useSelector(({ user }) => user);
    const [dateRange, setDateRange] = useState({
        startDate: new Date(),
        endDate: addDays(new Date(), 7),
    });

    const [employees, setEmployees] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const [backlogReport, setBacklogReport] = useState([]);
    const employeeSelect = useMemo(
        () => generateSelectUser(
            employees,
            "name"
        ), [employees]
    );
    const columns = useMemo(
        () => [
            // {
            //     Header: "Sr. No",
            //     accessor: "sr",
            //     minWidth: 60,
            //     disableFilters: true,
            //     maxWidth: 60,
            // },
            {
                Header: "Date",
                accessor: "date",
                minWidth: 130,
                maxWidth: 130,
                showTotal: true,
                placeholderTotal: "Total",
                Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
            },
            {
                Header: "Day",
                accessor: "day",
                minWidth: 130,
                maxWidth: 130,
                disableSortBy: true,
                Cell: (props) => {
                    return (<span>{new Date(props?.row?.original?.date).toLocaleString("en-us", { weekday: "long" })}</span>);
                }
            },
            {
                Header: "Name",
                accessor: "submitter",

            },
            {
                Header: "Doctors",
                accessor: "doctors.length",
                disableFilters: true,
                disableSortBy: true,
                showTotal: true,
                totalAccessor: "doctors"
            },
            {
                Header: "Chemists",
                accessor: "chemists.length",
                disableFilters: true,
                disableSortBy: true,
                showTotal: true,
                totalAccessor: "chemists"
            },
            {
                Header: "Stockists",
                accessor: "stockists.length",
                disableFilters: true,
                disableSortBy: true,
                showTotal: true,
                totalAccessor: "stockists"
            },
        ],
        [selectedUser]
    );

    let handleSubmit = async (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        let data = {
            selectedUser: selectedUser?.value,
            startDate: new Date(dateRange?.startDate).getTime(),
            endDate: new Date(dateRange?.endDate).getTime(),
            isAdmin: loggedIn?.user?.isAdmin ? true : null,
        };

        const aysncFetch = async () => {
            try {
                await viewBacklogReport(data).then((res) => {
                    setBacklogReport(res.data);
                });

            }
            catch (err) {
                console.log(err);
            }
        };

        aysncFetch();
    }, [dateRange, selectedUser]);

    const tableData = useMemo(() => serialise(backlogReport), [backlogReport]);

    useEffect(() => {
        try {
            viewUsersForUtilities().then((res) => {
                setEmployees(res?.data);
            });
        } catch (error) {
            console.log(error);
        }
    }, []);

    return (
        <div className="p-5">
            <div className="row">
                <h2 className="web-app__heading" style={{ display: "flex", alignItems: "center" }} >
                    <MdArrowBackIosNew
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(-1)}
                    />
                    <span style={{ display: "inline-block", margin: "0 1rem" }}>
                        Admin's Backlog Reports
                    </span>
                </h2>
                {loggedIn?.user?.designationPriority > 1 ||
                    loggedIn?.user?.designation?.priority > 1 ||
                    loggedIn?.user?.isAdmin ? (
                    <form onSubmit={handleSubmit}>
                        <div className="util-tp-query d-flex justify-content-between">
                            <div className="util-tp-filter w-100">
                                <p className="util-select-user__label">Select User</p>
                                <div className="d-flex align-items-center">
                                    <MdOutlineGroup className="util-select-user__icon" />
                                    <Select
                                        styles={selectUserStyles}
                                        options={employeeSelect}
                                        placeholder="Select User"
                                        className="mt-3"
                                        value={selectedUser}
                                        onChange={(e) => setSelectedUser({ ...e })}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                ) : null}

                <div className="date-range-container my-3">
                    <DateRange setDateRange={setDateRange} />
                </div>
                <div className="filter-table">
                    {backlogReport.length > 0 && (
                        <h2 className="web-app__heading mb-4">
                            Showing ({backlogReport.length}) Entries
                        </h2>
                    )}
                    {tableData.length > 0 ? (
                        <Table columns={columns} data={tableData} />
                    ) : (
                        <h3 className="mt-2 no-results-message">No results found</h3>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdminBacklogReports;
