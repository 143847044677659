export const title = "Manage Samples & Gifts";

export const links = [
  // {
  // 	name: 'Create Sample',
  // 	route: 'create-sample',
  // 	accessor: 'create-sample',
  // },
  {
    name: "Create Gift",
    route: "create-gift",
    accessor: "create-gift",
  },
  // {
  //   name: "Allot Stocks",
  //   route: "allot-stocks",
  // },
  {
    name: "Allot Samples",
    route: "allot-samples",
  },
  {
    name: "Allot Gifts",
    route: "allot-gifts",
  },
];
