import { MdDeleteOutline, MdOutlineVerified ,MdPendingActions} from "react-icons/md";
import {BsFillPatchCheckFill, BsXCircle} from "react-icons/bs";
import {AiOutlineFileDone} from "react-icons/ai";
import { deleteReminder } from "../../../../reducers/reminder";
import {useDispatch} from "react-redux";
const Reminder = ({ title, description,jointReminder, date, id }) => {
  const dispatch = useDispatch();
  return (
    <li>
        {new Date(date).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)?<div className="reminders-list-icon"><AiOutlineFileDone style={{fontSize:"2.5rem"}} /></div>:<MdPendingActions style={{fontSize:"3rem",color:"yellow",marginRight:"1.5rem"}}/>}
      <div className="reminders-list-content">
        <h3>
          <span style={{ fontWeight: "600" }}>{date} - </span>
          {title}
        </h3>
        <span className="mb-2">{jointReminder.map((val)=>{ return <span className="reminder-name-lable">{val.firstName}</span> })}</span>
        <p className="px-2">{description}</p>
      </div>
      <MdDeleteOutline onClick={()=>{dispatch(deleteReminder(id))}} style={{cursor:"pointer"}} className="button-delete__icon fs-1" />
    </li>
  );
};

export default Reminder;
