import React, { useEffect, useState } from 'react';
import customToast from '../../../components/CustomToast';
import { passwordRequest } from '../../../services/user.js';
import { ToastContainer } from 'react-toastify';

import {
	SectionLogin,
	BtnBlue,
	FormInput,
	FormLabel,
	LoginLeft,
	LoginRight,
	LoginForm,
	FormGroup,
	LoginImgInner,
	LoginEndLink,
	LoginImgOuter,
	FormEnd,
} from '../../public/stylesheets/authentication.js';
import InnerDots from '../../public/img/inner-dots.png';
import OuterDots from '../../public/img/outer-dots.png';
import { MdOutlineEmail } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const PasswordReset = ({ loggedUser }) => {
	const navigate = useNavigate();
	const [email, setEmail] = useState('');

	useEffect(() => {
		if (loggedUser) navigate('/dashboard');
	}, [loggedUser, navigate]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		let success = true;

		try {
			await passwordRequest(email);
		} catch (e) {
			success = false;
			customToast.error(e.message);
		}

		if (success) {
			customToast.success('Email Sent Successfully');
		}
	};

	return (
		<main>
			<SectionLogin>
				<div className="container">
					<div className="row" style={{ minHeight: '75rem' }}>
						<LoginLeft className="col-md-6">
							<h1>Medorn</h1>
							<h2>Password Reset</h2>
							<p>DCR | AI In the house | Cybersecurity in the house</p>

							<LoginImgInner src={InnerDots} alt="Inner Dots" />
						</LoginLeft>

						<LoginRight className="col-md-6">
							<h2>Medorn</h2>
							<p>Request a new Sign-in Password for Medorn</p>

							<LoginForm onSubmit={(e) => handleSubmit(e)}>
								<FormGroup>
									<FormLabel htmlFor="email">Email Address</FormLabel>
									<br />
									<FormInput
										type="email"
										name="email"
										placeholder="Email Address"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</FormGroup>

								<BtnBlue style={{ marginTop: '10rem' }}>
									<MdOutlineEmail />
									Get Reset Link on your Email
								</BtnBlue>

								<FormEnd style={{ marginTop: '5rem' }}>
									Go back to{' '}
									<LoginEndLink
										to="/login"
										className="section-login__end-link"
									>
										Log In
									</LoginEndLink>
								</FormEnd>
							</LoginForm>
						</LoginRight>
					</div>
				</div>
			</SectionLogin>

			<LoginImgOuter src={OuterDots} alt="Dots" />

			<ToastContainer />
		</main>
	);
};

export default PasswordReset;
