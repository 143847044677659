import { Outlet } from "react-router-dom";

import AdminNav from "../../../Admin/AdminSidenav";
import { title, links } from "./navData.js";

const ProfitCenter = () => {
  
  return (
    <>
      <AdminNav title={title} links={links} />

      <Outlet />
    </>
  );
};

export default ProfitCenter;
