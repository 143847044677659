import { getStockManagement, newStockManagement } from "../../services/ssg";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "VIEW_STOCK_MANAGEMENT":
      return action.payload;
    case "CREATE_STOCK_MANAGEMENT":
      return { data: [...state.data, action.payload.data] };
    case "CLEAR_STOCK_MANAGEMENT":
      return initialState;

    default:
      return state;
  }
};

export const viewStockManagement = () => {
  return async (dispatch) => {
    const { data } = await getStockManagement();
    dispatch({
      type: "VIEW_STOCK_MANAGEMENT",
      payload: { data: data },
    });
  };
};

export const addStockManagement = (data) => {
  return async (dispatch) => {
    try {
      data = await newStockManagement(data);
    } catch (e) {
      if (data.error) throw data.error;
    }
    dispatch({
      type: "CREATE_STOCK_MANAGEMENT",
      payload: data,
    });
  };
};

export const clearStockManagement = () => {
  return {
    type: "CLEAR_STOCK_MANAGEMENT",
  };
};

export default reducer;
