import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { registerUser } from '../../../reducers/userReducer';

import SignUpVector from '../../public/img/sign-up-vector.png';
import InnerDots from '../../public/img/inner-dots.png';
import OuterDots from '../../public/img/outer-dots.png';
import {
	LoginImgInner,
	LoginLeft,
	LoginRight,
	SectionLogin,
	LoginForm,
	FormGroup,
	FormLabel,
	FormInput,
	LoginCheckbox,
	LoginEndLink,
	LoginImgOuter,
	FormRemember,
	FormEnd,
	BtnBlue,
	RegisterImg,
} from '../../public/stylesheets/authentication';
import customToast from '../../../components/CustomToast';

const Register = ({ loggedUser }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		if (loggedUser) navigate('/dashboard');
	}, [loggedUser, navigate]);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const handleRegister = async (e) => {
		e.preventDefault();

		if (email && password && password === confirmPassword) {
			let error = null;

			try {
				await dispatch(registerUser({ email, password }));
			} catch (e) {
				error = e;
				customToast.error(e.message);
			}

			if (!error) {
				customToast.success('Signed Up Successfully');
				
				setTimeout(() => {
					navigate('/login');
				}, 5000);
			}
		}
	};

	return (
		<main>
			<SectionLogin>
				<div className="container">
					<div className="row">
						<LoginLeft className="col-md-6">
							<h1>Medorn</h1>
							<h2>Sign Up Page</h2>
							<p>DCR | AI In the house | Cybersecurity in the house</p>
							<RegisterImg src={SignUpVector} alt="..." />
							<LoginImgInner
								src={InnerDots}
								alt="Inner Dots"
								className="section-login__img-inner"
							/>
						</LoginLeft>
						<LoginRight className="col-md-6">
							<h2>Medorn</h2>
							<LoginForm action="#" onSubmit={(e) => handleRegister(e)}>
								<FormGroup>
									<FormLabel htmlFor="email">Email Address</FormLabel>
									<br />
									<FormInput
										type="email"
										id="email"
										name="email"
										placeholder="Email Address"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</FormGroup>

								<FormGroup>
									<FormLabel htmlFor="password">Password</FormLabel>
									<br />
									<FormInput
										type="password"
										id="password"
										name="password"
										placeholder="Password"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
								</FormGroup>

								<FormGroup>
									<FormLabel htmlFor="email">
										Confirm Password
									</FormLabel>
									<br />
									<FormInput
										type="password"
										id="password"
										name="password"
										placeholder="Confirm Password"
										value={confirmPassword}
										onChange={(e) =>
											setConfirmPassword(e.target.value)
										}
									/>
								</FormGroup>

								<LoginCheckbox>
									<span>
										<FormRemember
											type="checkbox"
											name="remember-me"
											id="remember-me"
										/>
										<p>Remember Me</p>
									</span>
								</LoginCheckbox>

								<BtnBlue type="submit" className="mt-5">
									Sign Up
								</BtnBlue>

								<FormEnd>
									Have an account?{' '}
									<LoginEndLink href="login">Login</LoginEndLink>
								</FormEnd>
							</LoginForm>
						</LoginRight>
					</div>
				</div>
			</SectionLogin>
			<LoginImgOuter src={OuterDots} alt="Dots" />
		</main>
	);
};

export default Register;
