import { getUserData, login, register, setToken, SuperAdminlogin } from "../services/user";
import { clearUser, retrieveUser, storeUser } from "../utils/authStorage.js";
import { setDesignations } from "./users/designationReducer";

const initialState = {
  user: null,
  jwt: {
    token: "",
    id: "",
    isAdmin: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "INIT_USER":
      return action.data;

    case "REGISTER_USER":
      return action.data;

    case "LOGIN_USER":
      return action.data;
    
    case "ACCOUNT_LOCKED":
      return {...state, user: {...state.user, isLocked: action.data}}

    case "LOGOUT_USER":
      return action.data;

    default:
      return state;
  }
};

export const initUser = () => {
  return async (dispatch) => {
    const data = retrieveUser();

    let resData;
    try {
      const response = await getUserData(data?.id);
      resData = response.data;
    } catch (e) {
      resData = null;
    }

    dispatch({
      type: "INIT_USER",
      data: {
        jwt: data ? data : initialState.jwt,
        user: resData ? resData : false,
      },
    });

    
  };
};

export const registerUser = (credentials) => {
  return async (dispatch) => {
    const data = await register(credentials);
    setToken(data.token);

    storeUser(data);

    dispatch({
      type: "REGISTER_USER",
      data,
    });
  };
};

export const loginSuperAdmin = (credentials, remember) => {
  return async (dispatch) => {
    const data = await SuperAdminlogin(credentials);
    setToken(data.jwt.token);

    if (remember) storeUser(data.jwt, 30);
    else storeUser(data.jwt, 1);

    dispatch({
      type: "LOGIN_USER",
      data,
    });
  };
};

export const loginUser = (credentials, remember) => {
  return async (dispatch) => {
    const data = await login(credentials);
    setToken(data.jwt.token);

    if (remember) storeUser(data.jwt, 30);
    else storeUser(data.jwt, 1);

    dispatch({
      type: "LOGIN_USER",
      data,
    });

    dispatch(setDesignations());
  };
};

export const lockUser = (data) => {
  return {
    type: "ACCOUNT_LOCKED",
    data,
  }
}

export const logoutUser = () => {
  clearUser();
  setToken(null);

  return {
    type: "LOGOUT_USER",
    data: { user: false, jwt: initialState.jwt },
  };
};

export default reducer;
