import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import Table from "../../../../../components/Table";
import {
  clearStates,
  setStates,
} from "../../../../../reducers/locations/stateReducer";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../../reducers/locations/hqReducer";

import serialise, {
  generateSelectData,
} from "../../../../../utils/serialiseResponse";
import { adminStyles } from "../../../../public/stylesheets/selectStyles";
import customToast from "../../../../../components/CustomToast";
import PageTitle from "../../../../../components/PageTitle";
import { BiTargetLock } from "react-icons/bi";
import { monthOptions, yearOptions } from "../../../../../utils/helper";
import { MdCheckCircleOutline, MdDelete, MdDeleteOutline } from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";
import { addHqTarget, deleteTargets } from "../../../../../reducers/reports/targetReducer";
import { fetchMonthTargets } from "../../../../../services/reports";
import { useNavigate } from "react-router-dom";

const AdminHqTarget = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const states = useSelector(({ state }) => state);
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const stateSelect = useMemo(
    () => generateSelectData(states.data, "name"),
    [states]
  );

  const [state, setState] = useState(null);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [hqData, setHqData] = useState();
  const [tableToggle, setTableToggle] = useState(false);
  const [targetData, setTargetData] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [deletePopup, setDeletePopup] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    dispatch(setStates());
    return () => {
      dispatch(clearStates());
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearHeadquarters());
    };
  }, [dispatch, state]);

  function EditPopup({ data }) {
    const [hqTarget, setHqTarget] = useState();

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!month || !year) return customToast.error(`Please select Month and Year`);

      const queryData = {
        hq: data?._id,
        month: month?.value,
        amount: hqTarget,
        year:year?.label,
      };

      try {
        dispatch(addHqTarget(queryData));
        setShowEdit(false);
        customToast.success("Target for headquarter allotted successfully")
      } catch (e) {
        customToast.error("We Encountered an Error");
      }
    };

    return (
      <section className="edit-popup edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Allot Amount</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form onSubmit={handleSubmit}>
            <label htmlFor="mobile">
              Enter Amount to be alloted <span className="asterisk-imp">*</span>
            </label>

            <input
              type="number"
              className="w-50"
              placeholder="Enter Amount"
              onChange={(e) => {
                setHqTarget(e.target.value);
              }}
            />

            <div className="edit-form-button-container">
              <button type="submit" className="button-submit-green mt-5">
                <span className="button-submit-green__icon-container">
                  <MdCheckCircleOutline className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Allot Target</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  }

  const DeletePopup = ({ data }) => {
    const dispatch = useDispatch();
    const handleDelete = async (e) => {
      e.preventDefault();
      try{
        dispatch(deleteTargets("hqTarget", {id:data?._id}))
        setIsDeleted(!isDeleted)
        
        setDeletePopup(false);
        customToast.success("Allotment deleted successfully")
        let response = await fetchMonthTargets("hqTarget",{month:month?.value})
        setTargetData(response.data);

      }
      catch{
        customToast.error("We encountered an error !")
      }
    };

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete Alloted Target</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setDeletePopup(false)}
            />
          </div>

          <form onSubmit={handleDelete}>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  This will PERMANENTLY delete the alloted target !
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-delete mt-4">
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Yes</span>
              </button>

              <button
                type="button"
                className="button-submit-green mt-4"
                onClick={() => setDeletePopup(false)}
              >
                <span className="button-submit-green__icon-container">
                  <MdCheckCircleOutline className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">No</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Headquarter",
        accessor: "name",
      //   Cell:(props) => {
      //     return <span>{props.row.original.hq[0].name}</span>
      // }

      },
      {
        Header: " State",
        accessor: "state",
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 80,
      },

      {
        Header: "Add Target",
        accessor: "details",
        Cell: (props) => (
          <span
            className="react-table-view-link"
            onClick={() => {
              setShowEdit(true);
              setHqData(props?.row?.original);
            }}
          >
            <BiTargetLock className="icon-color-green" />
          </span>
        ),
        disableFilters: true,
        disableSortBy: true,
        minWidth: 70,
        maxWidth: 70,
      },
    ],
    []
  );

  const targetColumns = useMemo(
    () => [
      {
        Header: "Headquarter",
        accessor: "hq",
        Cell:(props) => {
          return <span>{props.row.original.hq[0].name}</span>
      }
      },
      {
        Header: "Amount",
        accessor: "amount",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 80,
      },

      {
        Header: "Delete",
        accessor: "4",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
        Cell: (props) => {
          return (
            <span
              className="react-table-view-link"
              onClick={() => {
                setDeleteData(props?.row?.original)
                setDeletePopup(true);
              }}
            >
              <MdDelete className="icon-color-error" />
            </span>
          );
        },
      },
    ],
    [targetData]
  );


  const handleFilter = (e) => {
    e.preventDefault();
    // if (!state || !month || !year ) return customToast.error("Please select all the fields");

    dispatch(setHeadquarters(state?.value));
    // console.log("state val-",state.value)
    setTableToggle(false);
    try {
    } catch (e) {
      customToast.error("We Encountered an Error");
    }
  };
  
  const targetHandler = async () =>{
    setTableToggle(true);
    if(!month) return customToast.error("Please select a month")
    let data = await fetchMonthTargets("hqTarget",{month:Number(month?.value), year : new Date().getFullYear()})
    setTargetData(data);
  } 
    
  const tableData = useMemo(() => serialise(headquarters?.data),[headquarters?.data]);
  const targetTableData = useMemo(() => serialise(targetData),[targetData]);
// let targetTableData = []
  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Set Headquarter Target.." />

        <section className="area-creation-content__form">
          <form onSubmit={handleFilter}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">Select State</label>
                <br />
                <Select
                  name="state"
                  id="state"
                  value={state}
                  options={stateSelect}
                  onChange={(e) => {
                    setState({ ...e });
                  }}
                  styles={adminStyles}
                  placeholder="Select State"
                />
              </div>

              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">Select Month</label>
                <br />
                <Select
                  name="month"
                  id="month"
                  value={month}
                  options={monthOptions}
                  onChange={(e) => {
                    setMonth({ ...e });
                  }}
                  styles={adminStyles}
                  placeholder="Select Month"
                />
              </div>

              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">Select Year</label>
                <br />
                <Select
                  name="year"
                  id="year"
                  value={year}
                  options={yearOptions}
                  onChange={(e) => {
                    setYear({ ...e });
                  }}
                  styles={adminStyles}
                  placeholder="Select Year"
                />
              </div>
                     
              <div className="d-flex justify-content-between area-creation-content__form-submit">
                <button type="submit" className="button-blue-gradient" style={{marginTop :"3rem"}}>
                  Filter
                </button>

                <div className="d-flex">
                <button type="button" onClick={() => navigate("./monthly-hq-targets")} className="button-cyan-gradient ms-5" style={{marginTop :"3rem"}}>
                Monthly Targets
                </button>
                <button type="button" onClick={() => navigate(`./yearly-hq-targets`)} className="button-cyan-gradient ms-5" style={{marginTop :"3rem"}}>
                Yearly Targets
                </button>
                </div>
              </div>
            </div>
          </form>
        </section>

        <section className="area-creation-content__info py-0">
          {!tableToggle?tableData?.length > 0 && (
            <h2 className="web-app__heading mt-4">
              Showing ({tableData?.length}) Headquarters
            </h2>
          ):targetTableData?.length > 0 && (
            <h2 className="web-app__heading mt-4">
              Showing ({targetTableData?.length}) Entries
            </h2>
          )}
          <div className="filter-table">
            {!tableToggle?tableData?.length > 0 ? (
              <Table columns={columns} data={tableData} />
            ) : (
              <h3 className="mt-2 no-results-message">No results found</h3>
            ):targetTableData?.length > 0 ? (
              <Table columns={targetColumns} data={targetTableData} />
            ) : (
              <h3 className="mt-2 no-results-message">No results found</h3>
            )}
          </div>
        </section>
        {showEdit ? <EditPopup data={hqData} /> : null}
        {deletePopup ? <DeletePopup data={deleteData} /> : null}

      </div>
    </div>
  );
};

export default AdminHqTarget;
