import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseUrl = `${apiURL}/client/reports/`;
const baseUrlV1 = `${apiURL}/client/v1/reports/`;
const baseURL2 = `${apiURL}/client/dcs/`;
const newBaseUrl = `${apiURL}/client/v1/target/hq`;
const userTargetUrl = `${apiURL}/client/v1/target/user`;

export const newTarget = async (targetData) => {
  const { data } = await axios.post(baseUrlV1 + "target", targetData, {
    headers: setAuth(),
  });

  return data;
};

export const newHqTarget = async (targetData) => {
  // const { data } = await axios.post(baseUrl + "hq/target", targetData, {
  const { data } = await axios.post(baseUrlV1 + "hq/target", targetData, {
    headers: setAuth(),
  });

  return data;
};

export const fetchMonthTargets = async (type, targetData) => {
  const {data} = await axios.get(baseUrl + "month/target/" + type, {
    params:targetData,
    headers:setAuth(),
  })
  
  return data.data;
}

export const fetchYearlyTargets2 = async(targetData) =>{
  const {data} = await axios.get(baseUrlV1 + "yearly-target/target" , {
    params: targetData,
    headers: setAuth(),
  });
  return data;
}

export const fetchHqYearlyTargets = async(targetData) =>{
  const {data} = await axios.get(baseUrlV1 + "yearly-hq-target/target", {
  // const {data} = await axios.get(baseUrl + "yearly-hq-target/target", {
    params: targetData,
    headers: setAuth(),
  });
  return data;
}

export const deleteTarget = async (type, targetData) => {
  const {data} = await axios.post(baseUrlV1 + "delete/target/" + type, targetData, {
    headers:setAuth(),
  })

  return data;
}

export const viewReports = async (type, filters) => {
  const { data } = await axios.get(baseUrl + type, {
    params: filters,
    headers: setAuth(),
  });

  return data;
};

export const viewReportsMonthWise = async (type, filters) => {
  const { data } = await axios.get(baseUrl + "month-wise/" + type, {
    params: filters,
    headers: setAuth(),
  });
  return data;
};


export const viewReminderCall = async (filters) => {
  const { data } = await axios.get(baseURL2 + "reminderCall", {
    params: filters,
    headers: setAuth(),
  });
  return data;
};


export const newDCR = async (type, report) => {
  const { data } = await axios.post(baseUrl + type, report, {
    headers: setAuth(),
  });

  return data;
};

export const newAllotment = async (type, allotData) => {
  const { data } = await axios.post(baseUrl + type, allotData, {
    headers: setAuth(),
  });
  return data;
};

export const newSampleAllotmentV1 = async (allotData) => {
  const {data} = await axios.post(baseUrlV1 + "sample", allotData, {
    headers: setAuth(),
  });
  return data;
}

export const newGiftAllotment = async (allotData) => {
  const { data } = await axios.post(baseUrlV1 + "gift", allotData, {
    headers: setAuth(),
  })
  return data;
}

export const deleteGiftAllotment = async (deleteData) => {
  const { data } = await axios.post(baseUrlV1 + "gift/delete/", deleteData, {
    headers: setAuth(),
  })
  return data;
}

export const deleteAllotment = async (type, deleteData) => {
  // const { data } = await axios.post(baseUrlV1 + "delete/", deleteData, {
  const { data } = await axios.post(baseUrl + "delete/" + type, deleteData, {
    headers: setAuth(),
  });

  return data;
};

export const deleteSampleAllotment = async (deleteData) => {
  const {data} = await axios.post(baseUrlV1 + "sample/delete/", deleteData, {
    headers: setAuth()
  });

  return data;
}

export const allotmentsForMonth = async (type, query) => {
  const { data } = await axios.get(baseUrl + "allotments/month/" + type, {
    params: query,
    headers: setAuth(),
  });
  return data;
};

export const sampleAllotmentForMonth = async (query) => {
  const {data} = await axios.get(baseUrlV1 + "sample/month", {
    params: query,
    headers: setAuth(),
  })
  return data;
}

export const giftAllotmentForMonth = async (query) => {
  const { data } = await axios.get(baseUrlV1 + "gift/month", {
    params: query,
    headers: setAuth(),
  })
  return data;
}

export const getAchievedTarget = async (targetData) => {
  const {data} = await axios.get(baseUrl + "achieved-target", {
    params:targetData,
    headers:setAuth(),
  })
  
  return data;
}
export const  viewconsolidateReports = async (filter) => {
  const {data} = await axios.get(baseUrl + "consolidated-month", {
    params:filter,
    headers:setAuth(),
  })
  console.log("data going to backend - ", data);
  return data;
}

export const viewGiftsForapproval = async(details) =>{
  const {data} = await axios.get(baseUrl + "view-gifts-for-approval",{
    params:details,
    headers:setAuth(),
  });
  return data;
};

export const approveGifts = async(id,details) =>{
  const {data} = await axios.post(baseUrl + `approve-gifts/${id}`,details,{
    headers: setAuth(),
  });
  return data;
};

export const rejectGifts = async(id, details) =>{
  const {data} = await axios.post(baseUrl + `reject-gifts/${id}`,details,{
    headers: setAuth(),
  });
  return data;
};

export const viewSampleForApproval = async(details) =>{
  const {data} = await axios.get(baseUrl + "view-sample-for-approval",{
    params: details,
    headers: setAuth(),
  });
  return data;
}

export const approveSamples = async(id,details) =>{
  const {data} = await axios.post(baseUrl + `approve-sample/${id}`,details,{
    headers: setAuth(),
  });
  return data;
};

export const rejectSamples = async(id,details) =>{
  const {data} = await axios.post(baseUrl + `reject-sample/${id}`,details,{
    headers: setAuth(),
  });
  return data;
};

export const fetchMonthlyHqTargets = async(payload) => {
  const {data} = await axios.get(newBaseUrl + "/monthly-hq-target",{
    params: payload,
    headers: setAuth()
  });
  return data;
};

export const fetchUserMonthlyTargets = async(payload) => {
  const {data} = await axios.get(userTargetUrl + "/monthly-user-target",{
    params: payload,
    headers: setAuth()
  });
  return data;
}