export const title = "Lists";

export const links = [
  {
    name: "Doctors List",
    route: "doctors-list",
    accessor: "doctors-list",
  },
  {
    name: "Chemists List",
    route: "chemists-list",
    accessor: "chemists-list",
  },
  {
    name: "Stockists List",
    route: "stockists-list",
    accessor: "stockists-list",
  },
  {
    name: "HQ / City / Local Area",
    route: "hq-city-localArea-list",
    accessor: "hq/city/localArea-list",
  },
  {
    name: "Geo Fencing",
    route: "geo-fencing",
    accessor: "geo-fencing",
  },
  {
    name: "Product List",
    route: "products-list",
    accessor: "products-list",
  },
  {
    name: "Gift List",
    route: "gift-list",
    accessor: "gift-list",
  },
  {
    name: "Route List",
    route: "route-list",
    accessor: "route-list",
  },
  {
    name: "Holiday List",
    route: "holiday-list",
    accessor: "holiday-list",
  },
];
