import { Outlet } from 'react-router-dom';
import AdminNav from '../AdminSidenav';
import { title, links } from './navData.js';

const AdminDCS = () => {
	return (
		<>
			<AdminNav title={title} links={links} />

			<Outlet />
		</>
	);
};

export default AdminDCS;
