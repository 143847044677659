import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const CreationHome = () => {
  // Simplified Layout without Navigation Pane for Admin Pages

  return (
    <>
      <Outlet />
      <ToastContainer />
    </>
  );
};

export default CreationHome;
