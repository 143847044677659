import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURL = `${apiURL}/client/v1/analysis-setting`;

export const fetchAnalysisSetting = async (type, value) => {

  const { data } = await axios.get(baseURL, {
    headers: setAuth(),
  });
  return data;

}

export const toggleDetailedReportPreference = async (type, value) => {

  const { data } = await axios.put(baseURL + "/" + type, {
    value: value
  }, {
    headers: setAuth(),
  })
  return data;

}

export const toggleCustomCostPreference = async (type, value) => {

  const { data } = await axios.put(baseURL + "/" + type, {
    value: value
  }, {
    headers: setAuth(),
  })
  return data;

}