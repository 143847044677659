import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import {
  RiCapsuleFill,
  RiGiftFill,
  RiNurseFill,
  RiUser3Fill,
} from "react-icons/ri";
import Lightbox from "yet-another-react-lightbox";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, formatTime } from "../../../../utils/helper";
import { monthOptions } from "../../../../utils/helper";
import { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { viewDCScallReport } from "../../../../services/dcr";
import { setCities } from "../../../../reducers/locations/cityReducer";
import { viewGeoLocationData } from "../../../../services/geoFencing";
import DocMarker from "../../../public/img/DocMarker.png";
import StkMarker from "../../../public/img/StockMarker.png";
import ChemMarker from "../../../public/img/ChemMarker.png";
import userMarker from "../../../public/img/user_marker.png";
import { GoogleMap, Marker, Polygon, useJsApiLoader } from "@react-google-maps/api";

const TodaysActivityDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [dcsdata, setDCSdata] = useState([]);
  const [stk, setStk] = useState([]);
  const [chem, setChem] = useState([]);
  const images = [];
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const cities = useSelector(({ city }) => city);
  const [geoLocReports, setGeoLocReports] = useState([]);

  useEffect(() => {
    const da = async () => {
      try {
        const payload = {
          today: true,
          userId: params.id
        }
        let res = await viewGeoLocationData(payload);
        let doctor = await viewDCScallReport("doctor", payload);
        let stockist = await viewDCScallReport("stockist", payload);
        let chemist = await viewDCScallReport("chemist", payload);
        
        setGeoLocReports(res?.data);
        setDCSdata(doctor.data);
        setChem(chemist.data);
        setStk(stockist.data);
      } catch (error) {
        console.log(error)
      }
    };

    da();
  }, [params?.id]);
  useEffect(()=>{
    dispatch(setCities());
  },[dispatch]);
  dcsdata?.length > 0 && dcsdata?.map((item) => (
    item.files?.length > 0 && item.files?.map((pic) => (
      images.push({ name: item.target.name, pic: pic })
    ))
  ))
  chem?.length > 0 &&chem?.map((item) => (
    item.files?.length > 0 && item.files?.map((pic) => (
      images.push({ name: item.target.name, pic: pic })

    ))
  ))
  stk?.length > 0 && stk?.map((item) => (
    item.files?.length > 0 && item.files?.map((pic) => (
      images.push({ name: item.target.name, pic: pic })

    ))
  ))
  const cityName = (target)=>{
    const citi = cities?.data?.find((ele) => ele._id === target?.workingArea) ;
    return citi?.name ;
  }
 
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_API_KEY
  });

  const Map2 = ({latitude, longitude, latitude2, longitude2, type}) => {
    return isLoaded? 
    <div>
      <GoogleMap
      mapContainerStyle={{height:'300px', width:"100%"}}
      center={{lat:latitude, lng:longitude}}
      zoom={16}
      >
      <Marker
      position={{lat:latitude, lng: longitude}}
     icon={{
        url: userMarker,
        scaledSize: { width: 30, height: 40 }
      }}/> 
      
      {latitude && longitude && latitude2 && longitude2 && (
        <div>
          <Marker
          icon={{
            url: type === "doc" ? DocMarker : type === 'stk' ? StkMarker : ChemMarker,
            scaledSize: { width: 30, height: 40 }
          }}
          position={{lat:latitude2, lng: longitude2}}/> 
          <Polygon
          path={[{lat:latitude, lng:longitude},{lat:latitude2, lng: longitude2}]}
          options={{ strokeColor: "red", strokeOpacity: 1.0, strokeWeight: 2 }}/> 
          </div>
      )}
      </GoogleMap>
    </div>
    :
    null
  }

  return (
    <div className="utility-inner-tp">
      <div className="row">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
        >
          <div>
            <MdArrowBackIosNew
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <span style={{ display: "inline-block", margin: "0 1rem" }}>
              Call Details
            </span>
          </div>

        </h2>

        <div className="admin-panel__alert ps-4 ms-3" style={{ padding: "0.5rem", marginTop: "0" }}>
          <p>
            Note : The Call Reports visible here are not yet submitted by the user, and can change if the user decides to delete the reports.
          </p>
        </div>

        <div className="util-inner-details">
          <div className="util-inner-info-box-container">
            <div className="util-inner-info-box">
              <h3>Date</h3>
              <p>{formatDate(new Date())}</p>
            </div>

            <div className="util-inner-info-box">
              <h3>Month</h3>
              <p>
                {
                  monthOptions[new Date(`${new Date()}`).getMonth()]
                    ?.label
                }
              </p>
            </div>

          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div >
            <div className="doctor-call-div">
              <h2 className="web-app__heading mt-5">
                {dcsdata.length > 0 ? "Doctor Calls" : null}
              </h2>

              <Accordion className="mt-4" alwaysOpen>
                {dcsdata.length > 0 && dcsdata.map((e, i) => (
                  <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                    <Accordion.Header>
                      {" "}
                      {/* <span className="d-flex align-items-center">
                        {<RiNurseFill className="me-3 fs-2" />} {e?.target?.name}
                      </span> */}
                      <span className="d-flex align-items-start flex-column">
                        <span>{<RiNurseFill className="me-3 fs-2" />} {e?.target?.name}</span>
                        <div style={{fontSize:"1.5rem"}} className="password-icon-show">{cityName(e?.target)}</div>

                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <h5 className="web-app__heading-blue">
                        {e?.products.length > 0 ? "POB DETAILS" : null}
                      </h5>
                      {e?.products.length > 0 && e?.products.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.productName} ---{" "}
                            {elem.quantity ? elem.quantity : 0} --- ₹
                            {elem.amount ? elem.amount : 0} | Sample:{" "}
                            {elem.sampleQuantity ? elem.sampleQuantity : 0}
                          </div>
                        </div>
                      ))}

                      <h5 className="web-app__heading-blue mt-5">
                        {e?.gifts.length > 0 ? "Gift Details" : null}
                      </h5>
                      {e?.gifts?.length > 0 && e?.gifts?.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.giftName} ---
                            {elem.giftQuantity ? elem.giftQuantity : 0} ---- ₹
                            {elem.giftAmount ? elem.giftAmount : 0}
                          </div>
                        </div>
                      ))}

                      <h5 className="web-app__heading-blue mt-5">
                        {e?.workedWith?.length > 0 ? "Worked With" : null}
                      </h5>
                      {e?.workedWith?.length > 0 && e?.workedWith?.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiUser3Fill className="acc-body-card-icon acc-i-light-yellow" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.firstName} {elem?.lastName}
                          </div>
                        </div>
                      ))}

                      {e.remarks ? (
                        <>
                          <h5 className="web-app__heading-blue mt-5">Remarks</h5>
                          <h3 className="acc-body-remarks">{e?.remarks}</h3>
                        </>
                      ) : null}

                      <h5 className="web-app__heading-blue mt-5">Report Time</h5>
                      <h3 className="acc-body-remarks">{formatTime(e?.cdt)}</h3>

                      {geoLocReports && geoLocReports?.length > 0 && (() => {
                        const element = geoLocReports.find((item) => item?.target?.value === e?.target?._id);
                        return element ?
                        <div>
                          <h5 className="web-app__heading-blue mt-5">Geo-tagging</h5>
                          <Map2 latitude={element?.geolocation?.latitude} longitude={element?.geolocation?.longitude} latitude2={e?.target?.geolocation?.latitude} longitude2={e?.target?.geolocation?.longitude} type={e?.type}/> 
                        </div> 
                        : null;
                      })
                      ()
                      }

                     <h5 className="web-app__heading-blue mt-5">Doctor's Address</h5>
                     <h3 className="acc-body-remarks">{e?.target?.address}</h3>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className="chemist-call-div">
              <h2 className="web-app__heading report-heading mt-5">
                {chem.length > 0 ? "Chemist Calls" : null}
              </h2>
              <Accordion className="mt-4" alwaysOpen>
                {chem.length > 0 && chem.map((e, i) => (

                  <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                    <Accordion.Header>
                      {" "}
                      {/* <span className="d-flex align-items-center">
                        {<RiNurseFill className="me-3 fs-2" />} {e?.target?.businessName}
                      </span> */}
                      <span className="d-flex align-items-start flex-column">
                        <span>{<RiNurseFill className="me-3 fs-2" />} {e?.target?.businessName}</span>
                        <div style={{fontSize:"1.5rem"}} className="password-icon-show">{cityName(e?.target)}</div>
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <h5 className="web-app__heading-blue">
                        {e?.products?.length > 0 ? "POB DETAILS" : null}
                      </h5>
                      {e?.products?.length > 0 && e?.products.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.productName} ---{" "}
                            {elem.quantity ? elem.quantity : 0} --- ₹
                            {elem.amount ? elem.amount : 0} | Sample:{" "}
                            {elem.sampleQuantity ? elem.sampleQuantity : 0}
                          </div>
                        </div>
                      ))}

                      <h5 className="web-app__heading-blue mt-5">
                        {e?.gifts?.length > 0 ? "Gift Details" : null}
                      </h5>
                      {e?.gifts?.length > 0 && e?.gifts.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.giftName} ---
                            {elem.giftQuantity ? elem.giftQuantity : 0} ---- ₹
                            {elem.giftAmount ? elem.giftAmount : 0}
                          </div>
                        </div>
                      ))}

                      <h5 className="web-app__heading-blue mt-5">
                        {e?.workedWith?.length > 0 ? "Worked With" : null}
                      </h5>
                      {e?.workedWith?.length > 0 && e?.workedWith.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiUser3Fill className="acc-body-card-icon acc-i-light-yellow" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.firstName} {elem?.lastName}
                            {/* ({elem?.designation?.name}) */}
                          </div>
                        </div>
                      ))}

                      {e.remarks ? (
                        <>
                          <h5 className="web-app__heading-blue mt-5">Remarks</h5>
                          <h3 className="acc-body-remarks">{e?.remarks}</h3>
                        </>
                      ) : null}
                      <h5 className="web-app__heading-blue mt-5">Report Time</h5>
                      <h3 className="acc-body-remarks">{formatTime(e?.cdt)}</h3>

                      {geoLocReports && geoLocReports?.length > 0 && (() => {
                        const element = geoLocReports.find((item) => item?.target?.value === e?.target?._id);
                        return element ?
                        <div>
                          <h5 className="web-app__heading-blue mt-5">Geo-tagging</h5>
                          <Map2 latitude={element?.geolocation?.latitude} longitude={element?.geolocation?.longitude} latitude2={e?.target?.geolocation?.latitude} longitude2={e?.target?.geolocation?.longitude} type={e?.type}/> 
                        </div> 
                        : null;
                      })
                      ()
                      }

                    <h5 className="web-app__heading-blue mt-5">Chemist's Address</h5>
                    <h3 className="acc-body-remarks">{e?.target?.address}</h3>
                 
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className="stockist-call-div">
              <h2 className="web-app__heading mt-5">
                {stk?.length > 0 ? "Stockist Calls" : null}
              </h2>
              <Accordion className="mt-4" alwaysOpen>
                {stk?.length > 0 && stk?.map((e, i) => (
                  <Accordion.Item key={e._id} eventKey={i} className="mb-4">
                    <Accordion.Header>
                      {" "}
                      {/* <span className="d-flex align-items-center">
                        {<RiNurseFill className="me-3 fs-2" />} {e?.target?.businessName}
                      </span> */}
                      <span className="d-flex align-items-start flex-column">
                        <span>{<RiNurseFill className="me-3 fs-2" />} {e?.target?.businessName}</span>
                        <div style={{fontSize:"1.5rem"}} className="password-icon-show">{cityName(e?.target)}</div>

                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <h5 className="web-app__heading-blue">
                        {e?.products.length > 0 ? "POB DETAILS" : null}
                      </h5>
                      {e?.products?.length > 0 && e?.products?.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.productName} ---{" "}
                            {elem.quantity ? elem.quantity : 0} --- ₹
                            {elem.amount ? elem.amount : 0} | Sample:{" "}
                            {elem.sampleQuantity ? elem.sampleQuantity : 0}
                          </div>
                        </div>
                      ))}

                      <h5 className="web-app__heading-blue mt-5">
                        {e?.gifts?.length > 0 ? "Gift Details" : null}
                      </h5>
                      {e?.gifts?.length > 0 && e?.gifts?.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiGiftFill className="acc-body-card-icon acc-i-yellow" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.giftName} ---
                            {elem.giftQuantity ? elem.giftQuantity : 0} ---- ₹
                            {elem.giftAmount ? elem.giftAmount : 0}
                          </div>
                        </div>
                      ))}

                      <h5 className="web-app__heading-blue mt-5">
                        {e?.workedWith?.length > 0 ? "Worked With" : null}
                      </h5>
                      {e?.workedWith?.length > 0 && e?.workedWith.map((elem, i) => (
                        <div key={i} className="acc-body-card d-flex mt-4">
                          <RiUser3Fill className="acc-body-card-icon acc-i-light-yellow" />
                          <div className="acc-body-card-content d-flex align-items-center px-4">
                            {elem?.firstName} {elem?.lastName}
                            {/* ({elem?.designation?.name}) */}
                          </div>
                        </div>
                      ))}

                      {e.remarks ? (
                        <>
                          <h5 className="web-app__heading-blue mt-5">Remarks</h5>
                          <h3 className="acc-body-remarks">{e?.remarks}</h3>
                        </>
                      ) : null}
                      <h5 className="web-app__heading-blue mt-5">Report Time</h5>
                      <h3 className="acc-body-remarks">{formatTime(e?.cdt)}</h3>

                      {geoLocReports && geoLocReports?.length > 0 && (() => {
                        const element = geoLocReports.find((item) => item?.target?.value === e?.target?._id);
                        return element ?
                        <div>
                          <h5 className="web-app__heading-blue mt-5">Geo-tagging</h5>
                          <Map2 latitude={element?.geolocation?.latitude} longitude={element?.geolocation?.longitude} latitude2={e?.target?.geolocation?.latitude} longitude2={e?.target?.geolocation?.longitude} type={e?.type}/> 
                        </div> 
                        : null;
                      })
                      ()
                      }

                    <h5 className="web-app__heading-blue mt-5">Stockist's Address</h5>
                    <h3 className="acc-body-remarks">{e?.target?.address}</h3>

                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
          <div className="gallery">


            <ResponsiveMasonry
              columnsCountBreakPoints={{ 300: 2, 900: 3 }}
              className="gallery-img-container"
            >
              <Masonry>
                {(images?.length > 0) ? (
                  images?.map((e, index) => (
                    (e.name.trim() !== "" && e.pic.trim() !== "") &&
                    <div className="call-report-card"
                      key={index}
                      onClick={(e) => {
                        setIndex(index);
                        setOpen(true);
                      }}
                    >

                      <img src={e.pic} alt="Alt" loading="lazy" className="call-report-pic" />
                      <div className="img-call-wrapper d-flex justify-content-between align-items-center ">
                        <h5 className="img-name  text-white  bg-dark">{e.name}</h5>

                        {/* {loggedIn?.user?.isAdmin ? (
                          <MdDeleteOutline
                            onClick={(event) => {
                              event.stopPropagation();
                              setItemDelete({
                                prodId: e._id,
                                link: e.uri,
                                ID: e.ID,
                              });

                            // setShowEdit(true);
                            // setShowEdit(true);
                            // // handleDelete({ prodId: e._id, link: e.img,ID:e.ID })
                            //   setShowEdit(true);
                            // handleDelete({ prodId: e._id, link: e.img,ID:e.ID })
                            }}
                            style={{
                              cursor: "pointer",
                              marginTop: "-1rem",
                              fontSize: "1.4vmax",
                            }}
                            className="button-delete__icon bg-dark"
                          />
                        ) : null} */}
                      </div>
                    </div>
                  ))) : (
                  <label className="web-app__heading">No Images here </label>
                )
                }
              </Masonry>
            </ResponsiveMasonry>
            {/* // <img src={pic} alt="" className="call-report-pic" /> */}
            <Lightbox
              index={index}
              open={open}
              close={() => setOpen(false)}
              slides={images?.map(e => (e.pic.trim() !== "" && { src: e.pic }))}
            />

          </div>
        </div>
      </div>
    </div>
  );
};

export default TodaysActivityDetails;
