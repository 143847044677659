import {
  newGift,
  viewGifts,
  editProduct,
  deleteProduct,
  editGiftDetails,
  deleteGiftDetails,
} from "../../services/products.js";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_GIFTS":
      return action.payload;

    case "CREATE_GIFT":
      return { data: [...state.data, action.payload.data.data] };

    case "CLEAR_GIFTS":
      return initialState;

    case "EDIT_GIFTS":
      return { data:state.data};

    case "DELETE_GIFTS":
      return {data:state.data};

    default:
      return state;
  }
};

export const setGifts = (details) => {
  return async (dispatch) => {
    const { data } = await viewGifts(details);

    dispatch({
      type: "SET_GIFTS",
      payload: { data: data },
    });
  };
};

export const addGift = (data) => {
  return async (dispatch) => {
    try {
      data = await newGift(data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "CREATE_GIFT",
      payload: { data: data },
    });
  };
};

export const clearGifts = () => {
  return {
    type: "CLEAR_GIFTS",
  };
};

export const editGifts = (data) => {
  return async (dispatch) => {
    try {
      data = await editGiftDetails(data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "EDIT_GIFTS",
      payload: { data: data },
    });
    dispatch(setGifts());
  };
};

export const deleteGifts = (data) => {
  return async (dispatch) => {
    try {
      data = await deleteGiftDetails(data);
    } catch (e) {
      if (data.error) throw data.error;
    }
    dispatch({
      type: "DELETE_GIFTS",
      payload: { data: data },
    });
    dispatch(setGifts());
  };
};

export default reducer;
