import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURLforAdmin = `${apiURL}/client/`;
const baseURL = `${apiURL}/client/`;
const baseURLV1 = `${apiURL}/client/v1/`;

export const viewHolidays = async () => {
  const { data } = await axios.get(baseURLV1 + "holiday", {
    headers: setAuth(),
  });
  return data;
};

export const newHoliday = async (type, details) => {
  const { data } = await axios.post(baseURLV1 + type, details, {
    headers: setAuth(),
  });
  return data;
};

export const fetchDCRDates = async (req, res) => {
  const { data } = await axios.get(baseURL + "/attendance/dcr-dates", {
    headers: setAuth(),
  });

  return data;
};

export const getAttendance = async (details) => {
  const { data } = await axios.get(baseURL + "/attendance", {
    params: details,
    headers: setAuth(),
  });
  return data;
};

export const getMonthWiseAttendance = async (details) => {
  const { data } = await axios.get(baseURL + "attendance/month-attendance", {
    params: details,
    headers: setAuth(),
  });

  return data;
};

export const getAttendanceOverviewChart = async (details) =>{
  const {data} = await axios.get(baseURL + "attendance/attendance-overview-chart",{
    params:details,
    headers:setAuth()
  })
  
  return data;
}
export const getUsersAttendance = async (details) => {
  const { data } = await axios.get(baseURLforAdmin, {
    params: details,
    headers: setAuth(),
  });
  return data;
};

export const getAttendanceOverview = async () => {
  const { data } = await axios.get(baseURLforAdmin + "attendance-overview", {
    headers: setAuth(),
  });

  return data;
};

export const editAttendance = async (type, id, updatedHoliday) => {
  const { data } = await axios.put(
    baseURLV1 + `${type}/${id}`,
    updatedHoliday,
    {
      headers: setAuth(),
    }
  );

  return data;
};


export const deleteAttendance = async (type, id) => {
  await axios.post(
    baseURLV1 + `${type}/${id}`,
    { params: { id } },
    { headers: setAuth() }
  );
};

