import { useEffect, useState, useMemo } from "react";
import { viewTargets } from "../../../../../services/targets";
import serialise, {
  generateSelectData,
  generateSelectUser,
} from "../../../../../utils/serialiseResponse";
import Table from "../../../../../components/Table";
import { MdArrowBackIosNew, MdOutlineGroup, MdOutlineRefresh } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import Select from "react-select";
import { selectUserStyles } from "../../../../public/stylesheets/selectStyles";
import { viewUsersForUtilities } from "../../../../../services/utilities";
import ListExport from "../../../../../components/ListExport";

const DoctorList = () => {
  const navigate = useNavigate();
  const [docs, setDocs] = useState([]);
  const loggedIn = useSelector(({ user }) => user);
  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [showMyList, setShowMyList] = useState(false);
  const [employeeDataForExcel, setEmployeeDataForExcel] = useState();

  const employeeSelect = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );

  const employeeData = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );

  useEffect(() => {
    try {
      let tempdata = employeeData.filter((empData) => {
        if (empData.label === selectedUser.label) return true;
      })
      setEmployeeDataForExcel(tempdata[0]);
    } catch (error) {
      console.log(error);
    }
  }, [selectedUser]);

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Degree",
        accessor: "degree",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Specialization",
        accessor: "specialization",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Contact",
        accessor: "mobile",
        minWidth: 90,
        maxWidth: 90,
      },
      {
        Header: "HQ",
        accessor: "city",
        minWidth: 70,
        maxWidth: 70,
      },
      {
        Header: "Working Area",
        accessor: "workingArea",
        minWidth: 85,
        maxWidth: 85,
        wordWrap:'break-word'
      },
      {
        Header: "View",
        accessor: "",
        Cell: (props) => {
          return (
            <Link
              to={`${props?.row?.original?._id}`}
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
        minWidth: 35,
        maxWidth: 35,
        disableFilters: true,
        disableSortBy: true,
        
      },
    ],
    []
  );

  useEffect(() => {
    async function asyncFetch() {
      let data = {
        selectedUser: selectedUser?.value,
        showApproved:true,
        admin: true,
        dcsFilter: showMyList ? 'MyList' : ''
      };

      let res = await viewTargets("doctor", data);
      setDocs(res.data);
    }

    asyncFetch();
  }, [selectedUser,showMyList]);

  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
  const tableData = useMemo(() => serialise(docs), [docs]);

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <section className="admin-creation-content__heading justify-content-between py-2">
          <h2
            className="web-app__heading"
            style={{ display: "flex", alignItems: "center" }}
          >
            <MdArrowBackIosNew
              className="admin-creation-content__back-button"
              onClick={() => navigate("/utilities")}
            />
            <span style={{ display: "inline-block", margin: "0 1rem" }}>
              Doctors List
            </span>
            </h2>
          <div className="d-flex gap-4 align-items-center pt-4" >
            <div className="form__controll " style={{ width: "fit-content", color: "#c7c4e9" }}>
              <span className="">Individual List</span>
              <label className="toggle-label2 mx-3" >
                <input
                  type="checkbox"
                  checked={showMyList}
                  onClick={() => {
                    setShowMyList(!showMyList);
                    // setSelectedUser(null);
                  }}
                />
                <span />
              </label>
            </div>
          </div>
        </section>
        {loggedIn?.user?.des < 121 ||
        loggedIn?.user?.isAdmin ? (
          <div className="util-list-filters">
            <div className="util-tp-filter">
              <p className="util-select-user__label">Select User</p>
              <div className="util-select-user">
                <MdOutlineGroup className="util-select-user__icon" />
                <Select
                  styles={selectUserStyles}
                  options={employeeSelect}
                  placeholder="Select User"
                  className="mt-3"
                  value={selectedUser}
                  onChange={(e) => {
                    setSelectedUser({ ...e });
                    // setShowMyList(false);
                  }}
                />
                <MdOutlineRefresh size={25} className="icon-color-tertiary mt-4 ms-2" style={{cursor:"pointer"}} onClick={() => setSelectedUser(null)}/>
              </div>
            </div>
              {/* <section className="user-detail-container user-detail-container-green-border d-flex justify-content-between" style={{ gridColumn: 4 }}> */}
              
              {/* </section> */}
          </div>
        ) : null}
        <section
          className="area-creation-content__info"
          style={{ marginTop: "-4rem" }}
        >
          <div className="filter-table">
              {docs.length > 0 && (
                <h2 className="web-app__heading mb-4 ms-2">
                  Showing ({docs.length}) Entries
                </h2>
              )}  
            {tableData.length > 0 ? (
              <Table columns={columns} data={tableData} exportExcel={false} callback={ListExport} empl={selectedUser ? employeeDataForExcel : loggedIn?.user} fileSrc="Doctors List"/>

            ) : (
              <h3 className="mt-2 no-results-message">No results found</h3>
            )}
          </div>
        </section>
      </div>
    </main>
  );
};

export default DoctorList;
