import { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../../components/PageTitle";
import Select from "react-select";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import serialise, { addIndex } from "../../../../utils/serialiseResponse";
import { useNavigate } from "react-router-dom";
import Table from "../../../../components/Table";
import { FaEye, FaMapMarkerAlt } from "react-icons/fa"; // Import the location icon
import {
  clearStockists,
  setAllStockists,
} from "../../../../reducers/targets/stockist";
import { clearDoctors, setDoctors } from "../../../../reducers/targets/doctor";
import {
  clearChemists,
  setAllChemists,
} from "../../../../reducers/targets/chemist";
import { CiLocationOn } from "react-icons/ci";
import { BsCheck2Circle, BsXCircle } from 'react-icons/bs';
import { RiCloseCircleLine } from 'react-icons/ri';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const typeOptions = [
  { value: 1, label: "Doctor" },
  { value: 2, label: "Chemist" },
  { value: 3, label: "Stockist" },
];

const ViewDCS = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const doctors = useSelector(({ doctor }) => doctor);
  const chemists = useSelector(({ chemist }) => chemist);
  const stockists = useSelector(({ stockist }) => stockist);
  const [type, setType] = useState(typeOptions[0]);
  const [showMapPopup, setShowMapPopup] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [data, setData] = useState([]);

  const containerStyle = {
    width: '400px',
    height: '400px',
    position: 'relative',
    border: "3px solid #36ABF9"
  };

  const center = {
    lat: latitude || 0,
    lng: longitude || 0
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_API_KEY
  });


  const ViewMap = () => {
    return isLoaded ? (
      <section
        className='edit-popup'
        onClick={(e) =>
          showMap ?
            e.target.className === "edit-popup"
              ? setShowMap(false)
              : null
            :
            null
        }
      >
        <div style={{ position: "relative" }}>
          <RiCloseCircleLine
            className="map-popup__close"
            onClick={() => setShowMap(false)}
            size={25}
            style={{ cursor: 'pointer' }}
          />
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={20}
          >
            <Marker position={center} />
          </GoogleMap>
        </div>
      </section>
    ) : (
      <div style={{ color: "white" }}>
        ....Loading Map
      </div>
    );
  };


  const columnData = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "UID",
        accessor: "uid",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Degree",
        accessor: "degree",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Specialization",
        accessor: "specialization",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Mobile Number",
        accessor: "mobile",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "HQ",
        accessor: "city.name",
        minWidth: 85,
        maxWidth: 85,
      },
      // {
      //   Header: "Status",
      //   accessor: "isApproved",
      //   disableSortBy: true,
      //   minWidth: 70,
      //   maxWidth: 70,
      //   Cell: (props) =>
      //     props?.row?.original?.isApproved === 1 ? (
      //       <span className="approved-text">Approved</span>
      //     ) : props?.row?.original?.isApproved === 0 ? (
      //       <span className="pending-text">Pending</span>
      //     ) : (
      //       <span className="rejected-text">Rejected</span>
      //     ),
      // },
      {
        Header: "Geo",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 40,
        maxWidth: 40,
        Cell: (props) => {
          const geolocation = props?.row?.original?.geolocation;
          const handleClick = () => {
            if (geolocation) {
              setLatitude(props.row.original?.geolocation?.latitude);
              setLongitude(props.row.original?.geolocation?.longitude);
              setShowMap(true);
            }
          };

          return (
            <div>
              <span className="visually-hidden">{ geolocation ? "Yes" : "No"}</span>
              <CiLocationOn
                className='icon-color-tertiary'
                style={{
                  cursor: geolocation ? "pointer" : "not-allowed", // Conditionally set cursor
                  color: geolocation ? "red" : "gray", // Conditionally set color
                }}
                size={25}
                onClick={handleClick}
              />
            </div>
          );
        },
      },

      {
        Header: "View",
        accessor: "details",
        Cell: (props) => (
          <span
            className="react-table-view-link"
            onClick={() =>
              navigate("/admin-panel/dcs/view-dcs/details", {
                state: { ...props?.row?.original, type },
              })
            }
          >
            <FaEye className="icon-color-tertiary" />
          </span>
        ),
        disableFilters: true,
        disableSortBy: true,
        minWidth: 40,
        maxWidth: 40,
      },
    ],
    [type, navigate]
  );

  const chemColumnData = useMemo(
    () => [
      {
        Header: "Business Name",
        accessor: "businessName",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "UID",
        accessor: "uid",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Propreitor Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Address",
        accessor: "address",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
        Cell: (props) => {
          const address = props?.row?.original?.address;
          if (!address) {
            return null;
          }
          const words = props?.row?.original?.address.split(" ");
          const truncateWords = words.slice(0, 4).join(" ");

          return truncateWords;
        },
      },
      {
        Header: "Mobile Number",
        accessor: "chemistContact",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "HQ",
        accessor: "city.name",
        minWidth: 85,
        maxWidth: 85,
      },
      // {
      //   Header: "Status",
      //   accessor: "isApproved",
      //   disableSortBy: true,
      //   minWidth: 110,
      //   maxWidth: 110,
      //   Cell: (props) =>
      //     props?.row?.original?.isApproved === 1 ? (
      //       <span className="approved-text">Approved</span>
      //     ) : props?.row?.original?.isApproved === 0 ? (
      //       <span className="pending-text">Pending</span>
      //     ) : (
      //       <span className="rejected-text">Rejected</span>
      //     ),
      // },
      {
        Header: "Geo",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 40,
        maxWidth: 40,
        Cell: (props) => {
          const geolocation = props?.row?.original?.geolocation;
          const handleClick = () => {
            if (geolocation) {
              setLatitude(props.row.original?.geolocation?.latitude);
              setLongitude(props.row.original?.geolocation?.longitude);
              setShowMap(true);
            }
          };

          return (
            <div>
               <span className="visually-hidden">{ geolocation ? "Yes" : "No"}</span>
              <CiLocationOn
                className='icon-color-tertiary'
                style={{
                  cursor: geolocation ? "pointer" : "not-allowed", // Conditionally set cursor
                  color: geolocation ? "red" : "gray", // Conditionally set color
                }}
                size={25}
                onClick={handleClick}
              />
            </div>
          );
        },
      },

      {
        Header: "View",
        accessor: "details",
        Cell: (props) => (
          <span
            className="react-table-view-link"
            onClick={() =>
              navigate("/admin-panel/dcs/view-dcs/details-chemist", {
                state: { ...props?.row?.original, type },
              })
            }
          >
            <FaEye className="icon-color-tertiary" />
          </span>
        ),
        disableFilters: true,
        disableSortBy: true,
        minWidth: 70,
        maxWidth: 70,
      },
    ],
    [type, navigate]
  );

  const stkColumnData = useMemo(
    () => [
      {
        Header: "Business Name",
        accessor: "businessName",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "UID",
        accessor: "uid",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Propreitor Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Address",
        accessor: "address",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
        Cell: (props) => {
          const address = props?.row?.original?.address;
          if (!address) {
            return null;
          }
          const words = props?.row?.original?.address.split(" ");
          const truncateWords = words.slice(0, 4).join(" ");

          return truncateWords;
        },
      },
      {
        Header: "MobileNumber",
        accessor: "stockistContact",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "HQ",
        accessor: "city.name",
        minWidth: 85,
        maxWidth: 85,
      },
      // {
      //   Header: "Status",
      //   accessor: "isApproved",
      //   disableSortBy: true,
      //   minWidth: 110,
      //   maxWidth: 110,
      //   Cell: (props) =>
      //     props?.row?.original?.isApproved === 1 ? (
      //       <span className="approved-text">Approved</span>
      //     ) : props?.row?.original?.isApproved === 0 ? (
      //       <span className="pending-text">Pending</span>
      //     ) : (
      //       <span className="rejected-text">Rejected</span>
      //     ),
      // },
      {
        Header: "Geo",
        accessor: "geolocation",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 40,
        maxWidth: 40,
        Cell: (props) => {
          const geolocation = props?.row?.original?.geolocation;
          const handleClick = () => {
            if (geolocation) {
              setLatitude(props.row.original?.geolocation?.latitude);
              setLongitude(props.row.original?.geolocation?.longitude);
              setShowMap(true);
            }
          };

          return (
            <div>
               <span className="visually-hidden">{ geolocation ? "Yes" : "No"}</span>
              <CiLocationOn
                className='icon-color-tertiary'
                style={{
                  cursor: geolocation ? "pointer" : "not-allowed", // Conditionally set cursor
                  color: geolocation ? "red" : "gray", // Conditionally set color
                }}
                size={25}
                onClick={handleClick}
              />
            </div>
          );
        },
      },

      {
        Header: "View",
        accessor: "details",
        Cell: (props) => (
          <span
            className="react-table-view-link"
            onClick={() =>
              navigate("/admin-panel/dcs/view-dcs/details-stockist", {
                state: { ...props?.row?.original, type },
              })
            }
          >
            <FaEye className="icon-color-tertiary" />
          </span>
        ),
        disableFilters: true,
        disableSortBy: true,
        minWidth: 70,
        maxWidth: 70,
      },
    ],
    [type, navigate]
  );

  useEffect(() => {
    dispatch(setDoctors({ showApproved: true, admin: true, status: true }));
    dispatch(setAllChemists({ type: "creation", admin: true, status: true }));
    dispatch(setAllStockists({ type: "creation", admin: true, status: true }));

    return () => {
      dispatch(clearDoctors());
      dispatch(clearChemists());
      dispatch(clearStockists());
    };
  }, [dispatch]);

  const tableData = useMemo(() => {
    const filterApproved = (data) =>
      data?.filter((item) => item?.isApproved === 1);
    return type?.label?.toLowerCase() === "doctor"
      ? addIndex(filterApproved(doctors?.data))
      : type?.label?.toLowerCase() === "chemist"
        ? addIndex(filterApproved(chemists?.data))
        : addIndex(filterApproved(stockists?.data));
  }, [doctors?.data, chemists?.data, stockists?.data, type]);

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="View Doc / Chem / Stk" />

        <section className="area-creation-content__info">
          <div className="admin-panel__alert" style={{ padding: "1rem", marginTop: "0" }}>
            <h2 style={{ marginBottom: "0" }}>Note</h2>
            <p>
              The below list shows only those entries which are entered by the loggedIn user.
              In order to view the complete list, visit ---{'>'} Reports ---{'>'} Lists or{' '}
              <Link to="/utilities/lists/doctors-list">
                <button style={{ background: 'none', border: 'none', color: 'white' }}>
                  click here !
                </button>
              </Link>
            </p>
          </div>
          <form>
            <div className="col-sm-12 col-md-3 col-lg-3">
              <label htmlFor="type" style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>Select Type</label>
              <br />
              <Select
                name="type"
                id="type"
                value={type}
                options={typeOptions}
                onChange={(e) => setType({ ...e })}
                styles={adminStyles}
                placeholder="Doc / Chem / Stk"
              />
            </div>
          </form>
          <div className="filter-table">
            {tableData.length > 0 && (
              <h2 className="web-app__heading ms-2 mb-4">
                Showing ({tableData.length}) Entries
              </h2>
            )}
            <Table
              columns={
                type?.label?.toLowerCase() === "doctor"
                  ? columnData
                  : type?.label?.toLowerCase() === "chemist"
                    ? chemColumnData
                    : stkColumnData
              }
              data={tableData}
            />
          </div>
        </section>
        {showMap ? <ViewMap /> : null}
      </div>
    </main>
  );
};

export default ViewDCS;
