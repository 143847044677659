import { 
  inventoryAlerts,
  inventoryAlertsV1, 
} from "../../services/products";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ALERTS":
      return action.payload;
    case "ClEAR":
      return initialState ;
    default:
      return state;
  }
};

export const viewInventoryAlerts = (details) => {
  return async (dispatch) => {
    const { data } = await inventoryAlerts(details);
    // const { data } = await inventoryAlertsV1(details);
    dispatch({
      type: "SET_ALERTS",
      payload:{data:data},
    });
  };
};

export default reducer;
