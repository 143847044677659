const initialState = {
  socket:{
    instance:null,
    loggedIn:null
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "INIT_SOCKET":
      return { ...state, socket: action.data };
    default:
      return state;
  }
};

export const initSocket = (data) => {
  return {
    type: "INIT_SOCKET",
    data: data,
  };
};


export default reducer;
