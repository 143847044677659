import {
  newLocation,
  viewLocations,
  deleteLocation,
  editLocation,
  editLocationV1,
  deleteLocationV1,
  viewLocationsV1,
  addLocationV1,
} from "../../services/locations.js";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOCATION_STATE":
      return action.payload;

    case "CREATE_LOCATION_STATE":
      return { data: [...state.data, action.payload.data.data] };

    case "EDIT_LOCATION_STATE":
      // return { ...state, newState: action.payload };
      return {data:state.data};

    case "DELETE_LOCATION_STATE":
      // return action.payload;
      return {data:state.data};

    case "CLEAR_LOCATION_STATE":
      return initialState;

    default:
      return state;
  }
};

export const setStates = () => {
  return async (dispatch) => {
    // const { data } = await viewLocations("state");
    const {data} = await viewLocationsV1("state");

    dispatch({
      type: "SET_LOCATION_STATE",
      payload: { data: data },
    });
  };
};

export const addState = (data) => {
  
  return async (dispatch) => {
    try {
      // data = await newLocation("state", data);
      data = await addLocationV1("state", data);
      
    } catch (e) {
      console.log(e)
      if (data.error) throw data.error;
      
    }
    dispatch({
      type: "CREATE_LOCATION_STATE",
      payload: { data: data },
    });
    
  };
};

export const editState = (id, updatedLoc) => {
  return async (dispatch) => {
    try {
      // updatedLoc = await editLocation("state", id, updatedLoc);
      updatedLoc = await editLocationV1("state", id, updatedLoc);
    } catch (e) {
      throw e;
    }

    dispatch({
      type: "EDIT_LOCATION_STATE",
      payload: { data: updatedLoc },
    });
    dispatch(setStates());
  };
};

export const clearStates = () => {
  return {
    type: "CLEAR_LOCATION_STATE",
  };
};

export const deleteState = (data) => {
  return async (dispatch) => {
    try {
      // data = await deleteLocation("state", data);
      data = await deleteLocationV1("state", data);
    } catch (e) {
      if (data.error) throw data.error;
    }
    dispatch({
      type: "DELETE_LOCATION_STATE",
      payload: { data: data },
    });
    dispatch(setStates());
  };
};

export default reducer;
