import { Marker } from "@react-google-maps/api";
import { useCallback, useEffect, useState } from "react";

export const MapMarker = ({ coord, el, icon, detail, setDetail, setDcsDetailVisibility }) => {

  const [time, setTime] = useState("");

  const [selectedDcsDetail, setSelectedDcsDetail] = useState({
    name: el?.target?.label,
    address: el?.target?.address,
    type: el?.targetType,
    time: time
  });

  useEffect(() => {
    const _24hrTiming = new Date(el?.createdAt);
    setTime(_24hrTiming.getHours() + " : " + _24hrTiming.getMinutes() + " (24 Hrs)");
  }, [time]);

  useEffect(() => {
    setSelectedDcsDetail({
      name: el?.target?.label,
      address: el?.target?.address,
      type: el?.targetType,
      time: time
    })
  }, [el, time])

  const showDetails = useCallback(() => {
    if (detail.name !== selectedDcsDetail.name && detail.address !== selectedDcsDetail.address && detail.time !== selectedDcsDetail.time) {
      setDetail(selectedDcsDetail);
    }
    setDcsDetailVisibility(true);
  }, [selectedDcsDetail])

  return (
    <Marker
      clickable={true}
      position={coord}
      icon={icon}
      title={el?.target.name}
      label={el?.target.name}
      onClick={showDetails}
    />
  )

}