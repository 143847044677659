import { Outlet } from 'react-router-dom';

import AdminNav from '../AdminSidenav';
import { title, links } from './navData.js';

const AdminExpenses = () => {
	return (
		<>
			<AdminNav title={title} links={links} />

			<Outlet />
		</>
	);
};

export default AdminExpenses;
