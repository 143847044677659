import { useEffect, useState, useMemo } from "react";
import { viewProducts, viewProductV1 } from "../../../../../services/products";
import serialise, { generateSelectData } from "../../../../../utils/serialiseResponse";
import Table from "../../../../../components/Table";
import { MdArrowBackIosNew, MdOutlineRefresh } from "react-icons/md";
import { MdDoneAll } from "react-icons/md";
import { MdClear } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import ListExport from "../../../../../components/ListExport";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { clearDivisions, setDivisions } from "../../../../../reducers/users/divisionReducer";
import { adminStyles, selectUserStyles } from "../../../../public/stylesheets/selectStyles";


const ProductsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [prod, setProd] = useState([]);
  const [division, setDivision] = useState(null);
  const divisionData = useSelector(({ division }) => division);
  const loggedIn = useSelector(({ user }) => user);


  const divisionSelect = useMemo(
    () => generateSelectData(divisionData, "name"),
    [divisionData]
  );


  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Division",
        accessor: "division",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Packaging",
        accessor: "packaging",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "MRP",
        accessor: "mrp",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
      },
      {
        Header: "PTS",
        accessor: "pts",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
      },
      {
        Header: "PTR",
        accessor: "ptr",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
      },
      {
        Header: "Images",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
        Cell: (props) => (
          <span>
            {props?.row?.original?.images?.length > 0 ? (
              <MdDoneAll style={{ color: "#2ecc71" }} />
            ) : (
              <MdClear style={{ color: "#e74c3c" }} />
            )}
          </span>
        ),
      },
      {
        Header: "View",
        accessor: "",
        Cell: (props) => {
          return (
            <Link
              to={`${props?.row?.original?._id}`}
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
        minWidth: 40,
        maxWidth: 40,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(setDivisions());
    return () => {
      dispatch(clearDivisions());
    };
  }, [dispatch]);

  useEffect(() => {
    viewProductV1("").then((response) => {
      setProd(response?.data);
    });
  }, []);

  const filteredProd = useMemo(() => {
    if (!division) return prod; // If no division is selected, show all products
    return prod.filter((e) => division?.value === e?.division?._id);
  }, [prod, division]);

  const tableData = useMemo(() => serialise(filteredProd), [filteredProd]);

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <section className="admin-creation-content__heading ">
        <MdArrowBackIosNew
            className="admin-creation-content__back-button"
            onClick={() => navigate("/utilities")}
          />
          <h2 className="web-app__heading">Product List</h2>
            </section>

        {loggedIn?.user?.des === 101 &&
          <div className="util-list-filters">
            <div className="util-tp-filter">
              <p className="util-select-user__label">Select Division</p>
              <div className="util-select-user">
                {/* <MdOutlineGroup className="util-select-user__icon" /> */}
                <Select
                  styles={selectUserStyles}
                  options={divisionSelect}
                  placeholder="Select Division"
                  className="mt-3"
                  value={division}
                  onChange={({ value, label }) => setDivision({ value, label })}
                />
                <MdOutlineRefresh size={25} className="icon-color-tertiary mt-4 ms-2" style={{ cursor: "pointer" }} onClick={() => setDivision(null)} />
              </div>
            </div>
          </div>
        }

        <section
          className="area-creation-content__info"
          style={{ marginTop: "-4rem" }}
        >
          <div className="filter-table">
            {tableData.length > 0 && (
              <h2 className="web-app__heading mb-4 ms-2">
                Showing ({tableData.length}) Entries
              </h2>
            )}
            {tableData.length > 0 ? (
              <Table columns={columns} data={tableData} exportExcel={false} callback={ListExport} />
            ) : (
              <h3 className="no-results-message">No results found</h3>
            )}
          </div>
        </section>
      </div>
    </main>
  );
};

export default ProductsList;
