
import { useLocation, useNavigate,useParams } from "react-router-dom";
import { useState, useMemo, useEffect } from "react";

import Table from "../../../../../components/Table";
// import { viewPobWiseReportDetails } from "../../../../../services/analytics";
import { formatDate } from "../../../../../utils/helper";
import { FaEye } from "react-icons/fa";
import { RiCapsuleFill, RiCloseCircleLine } from "react-icons/ri";
import {
  MdArrowBackIosNew,

} from "react-icons/md";
import { viewPobWiseReportDetails } from "../../../../../services/analytics";
const PobWiseReportsDetail = () => {
  const navigate = useNavigate();
  const params = useParams()
  const location = useLocation();
  const [reports, setReports] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [popUpData,setPopUpData] = useState(null);
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => (
          <span>{props?.row?.original?.targetData?.target?.name}</span>
        ),
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: (props) => formatDate(props?.row?.original?.date),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "POB Amount",
        accessor: "pobAmount",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => props?.row?.original?.pobAmount
      },
      {
        Header: "View Details",
        accessor: "",
        Cell: (props) => {
          return (
            <span
              onClick={() => {
                setPopUpData(props?.row?.original?.targetData)
                setShowEdit(true)}}
              style={{ cursor: "pointer" }}
              className="util-table-view-details-icon"
            >
              <FaEye />
            </span>
          );
        },
        minWidth: 80,
        maxWidth: 80,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  const EditPopup = () => {
    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h5 className="web-app__heading-blue">
              {popUpData?.products.length > 0
                ? "POB DETAILS"
                : null}
            </h5>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          {popUpData?.products.map((elem, i) => {
            console.log(elem)
            return (
            <div key={i} className="acc-body-card d-flex mt-4">
              <RiCapsuleFill className="acc-body-card-icon acc-i-green" />
              {/* <div className="acc-body-card-content d-flex align-items-center px-4">
                {elem?.productName} ---{" "}
                {elem.quantity ? elem.quantity : 0} --- ₹
                {elem.amount ? elem.amount : 0} | Sample:{" "}
                {elem.sampleQuantity ? elem.sampleQuantity : 0}
              </div> */}
              <div className="acc-body-card-content d-flex align-items-center px-4 gap-5 justify-content-between">
                <span>{elem?.productName}</span>
                <span>  Quantity:{" "}{elem.quantity ? elem.quantity : 0} </span>
                <span> Amount: ₹{elem.amount ? elem.amount : 0} </span>
                <span>  Sample:{" "}{elem.sampleQuantity ? elem.sampleQuantity : 0}</span>
              </div>
            </div>
          )})}
        </div>
      </section>
    );
  };


  useEffect(() => {
    const asyncFetch = async () => {
      let data = {
        startDate : new Date(location.state?.startDate).getTime(),
        endDate: new Date(location.state?.endDate).getTime()
      }
    
      await viewPobWiseReportDetails(location.state?.type, params.docId, {
        selectedUser: location.state?.userId,
        // month: 8,
        // year: 2023,
        startDate : data.startDate,
        endDate: data.endDate
      })
        .then((resp) => {
          setReports(resp.data);
        })
        .catch((err) => console.log(err));
    };

    asyncFetch();
  }, []);

  return (
    <div className="expense" onClick={(e)=>showEdit?e.target.className==="edit-popup"?setShowEdit(false):null:null}>
      <div className="row">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MdArrowBackIosNew
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span style={{ display: "inline-block", margin: "0 1rem" }}>
            POB Details
          </span>
        </h2>

        <div className="filter-table">
          <Table columns={columns} data={reports} fileSrc="POB Report" />
        </div>
      </div>
      {showEdit ? <EditPopup /> : null}
    </div>
  );
};

export default PobWiseReportsDetail;
