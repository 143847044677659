import React,{ useState, useMemo, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import customToast from "../../../../components/CustomToast";
import { MdCheckCircleOutline , MdDelete, MdCancel, MdArrowBackIosNew, MdDeleteOutline } from "react-icons/md";
import {AiFillSave } from 'react-icons/ai';
import Table from "../../../../components/Table";
import {FaCalendar, FaCartPlus,} from "react-icons/fa" ;
import {ImLocation} from 'react-icons/im';
import {MdDownload} from "react-icons/md" ;
import {RiCloseCircleLine, RiEdit2Fill} from "react-icons/ri";
import serialise, { addIndex } from "../../../../utils/serialiseResponse";
import { useLocation, useNavigate } from "react-router-dom";
import { fileDownloader, returnMonthFromIndex } from "../../../../utils/helper";
import { editSecondarySales , deleteSecondarySales } from "../../../../services/sales";
import { hideSidenav, showSidenav, showTrimNav } from "../../../../reducers/appReducer";


const EditSecondarySales = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
 
  const [showEdit , setShowEdit] = useState(false);
  const loggedIn = useSelector(({ user }) => user);
  const [finalArray, setFinalArray] = useState([]);//this is the array that will get submitted
  const [editRow , setEditRow] = useState("");
  const [editing , setEditing] = useState(false);
  const [files,setFiles] = useState("");
  //These are the variables that do not require re-rendering
  const upOpeningBalanceQty = useRef(0) ;
  const upCustomPrice = useRef(0) ;
  const upFreeStocksRef = useRef(0);
  const upReceivedQuantity = useRef(0) ;
  const upSalesQuantity  = useRef(0) ;
  
  useEffect(() => {
    const data = addIndex(location?.state?.products);
    setFinalArray(data);
    setFiles(location?.state?.files || "");
    return () => {
      resetForm();
    };
  }, [location?.state]);
  useEffect(() => {
    dispatch(showTrimNav());

    return () => dispatch(showSidenav());
  }, [dispatch]);
  const resetForm = ()=>{
    setFinalArray([]);
    setEditRow("");
    setEditing(false);
  }
  
  const deleteHandler = (product)=>{
    setEditRow("");
    setEditing(false);
    let sr = 0 ;
    const newfinalArray = finalArray.filter((ele , idx)=>{
      if(ele?.product?._id !== product?._id){
        sr += 1 ;
        ele.sr = sr ;
        return true ;
      }else return false ;
    })
    setFinalArray(newfinalArray);
  }
  const handleEditRow = (original)=>{
    if(editing){
      customToast.error("Please save the changes you just made");
      return ;
    }
    setEditing(true);
    setEditRow(original?.sr);
    upOpeningBalanceQty.current = original?.openingBalanceQty;
    upCustomPrice.current = original?.price ;
    upFreeStocksRef.current = original?.freeStocks ;
    upReceivedQuantity.current = original?.receivedQuantity
    upSalesQuantity.current = original?.salesQuantity ;
  }
  const submitEditedRow = (sr)=>{
    const editedObj = finalArray[sr-1];
    editedObj.openingBalanceQty = Number(upOpeningBalanceQty.current) ;
    editedObj.receivedQuantity = Number(upReceivedQuantity.current) ;
    editedObj.salesQuantity= Number(upSalesQuantity.current) ;
    editedObj.freeStocks = Number(upFreeStocksRef.current);
    editedObj.price = Number(upCustomPrice.current) ;
    editedObj.totalQuantity = Number(upOpeningBalanceQty.current) + Number(upReceivedQuantity.current);
    editedObj.totalValue = Number(editedObj.totalQuantity) * Number(editedObj.price);
    editedObj.salesValue = Number(editedObj.salesQuantity) * Number(editedObj.price) ;
    editedObj.closingQuantity = Number(editedObj.totalQuantity) - Number(editedObj.salesQuantity) - Number(editedObj.freeStocks);
    editedObj.closingValue = Number(editedObj.closingQuantity) * Number(editedObj.price) ;
    setEditing(false);
    setEditRow("");
  }
  const cancelHandler = (sr)=>{
    upOpeningBalanceQty.current = 0 ;
    upCustomPrice.current = 0 ;
    upReceivedQuantity.current = 0 ;
    upSalesQuantity.current  = 0 ;
    setEditing(false);
    setEditRow("");
  }
  
  const handleSalesEdit = useCallback(
    async (e) => {
      e.preventDefault();
      if (editing) {
        customToast.error("Please save the changes you just made");
        return;
      }
      if (finalArray.length === 0) {
        customToast.error(
          "Please add atleast one product or delete the Inventory"
        );
        return;
      }
      const payload = {
        id: location?.state?._id,
        table: finalArray,
      };
      try{
        const {data} = await editSecondarySales(payload);
        customToast.success(data.message);
      }catch(err){
        const {data} = err.response ;
        customToast.error(data.message);
      }
      navigate(-1);
    },
    [finalArray, editing, navigate, location?.state]
  );

  
  const EditPopup = () => {
    const handleDelete = async(e) => {
      e.preventDefault();
      try{
        const {data} = await deleteSecondarySales({id:location?.state?._id});
        customToast.success(data.message);
        navigate(-1);
        setShowEdit(false);
      }catch(err){
        const {data} = err.response ;
        customToast.error(data.message);
      }
    };

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete Sale</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form onSubmit={handleDelete}>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  Do you want to PERMANENTLY delete this Sale?
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-delete mt-4">
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Yes</span>
              </button>

              <button
                type="button"
                className="button-submit-green mt-4"
                onClick={() => setShowEdit(false)}
              >
                <span className="button-submit-green__icon-container">
                  <MdCheckCircleOutline className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">No</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 50,
      //   disableFilters: true,
      //   maxWidth: 50,
      // },
      {
        Header: "Product Name",
        accessor: "product.name",
        disableFilters: false,
        disableSortBy: false,
        minWidth: 100,
        maxWidth: 100,
        wordWrap:"break-word",
        showTotal:true,
        placeholderTotal:"Total"
      },
      {
        Header:"Pack",
        accessor: "product.packaging",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 65,
        maxWidth: 65,
        wordWrap:"break-word",
        
      },
      {
        Header: "Unit Price",
        accessor: "product.price",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 90,
        maxWidth: 90,
        Cell: (props) =>{
            const editable = Number(props?.row?.original?.sr) === Number(editRow) ;
            return (editable) ? (
            <input 
              defaultValue={props?.row?.original?.price}
              onChange={(e)=>{
                upCustomPrice.current = e.target.value;
              }}
              type="number" className="sales-table__input" 
            />
          ) :
          (
            <span>{`₹ ${props?.row?.original?.price}`}</span> 
          )
        },
      },
      {
        Header: "Opening Balance Qty",
        accessor: "openingBalanceQty",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 100,
        showTotal:true,
        totalAccessor:'openingBalanceQty',
        Cell: (props) =>{
              const editable = Number(props?.row?.original?.sr) === Number(editRow) ;
              return editable ? (
              <input 
                defaultValue={props?.row?.original?.openingBalanceQty}
                onChange={(e)=>{
                  upOpeningBalanceQty.current = e.target.value;
                }}
                type="number" className="sales-table__input" 
              />
            ) :
            (
              <span>{props?.row?.original?.openingBalanceQty}</span> 
            )
        },
      },
      {
        Header: "Received Qty",
        accessor: "receivedQuantity",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 100,
        showTotal:true,
        totalAccessor:'receivedQuantity',
        Cell: (props) =>{
              const editable = Number(props?.row?.original?.sr) === Number(editRow) ;
              return editable ? (
              <input 
                defaultValue={props?.row?.original?.receivedQuantity}
                onChange={(e)=>{
                    upReceivedQuantity.current = e.target.value;
                }}
                type="number" className="sales-table__input" 
              />
            ) :
            (
              <span>{props?.row?.original?.receivedQuantity}</span> 
            )
        },
      },
      {
        Header:"Total Quantity", 
        accessor:"totalQuantity",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 90,
        maxWidth: 90,
        showTotal:true,
        totalAccessor:'totalQuantity',
      },
      {
        Header: "Total Value",
        accessor: "totalValue",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 90,
        maxWidth: 90,
        showTotal:true,
        totalAccessor:'totalValue',
        Cell:(props) => {
          return <div>
            {`₹ ${props?.row?.original?.totalValue}`}
          </div>
        }
      },
      {
        Header: "Sales Qty",
        accessor: "salesQuantity",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 100,
        showTotal:true,
        totalAccessor:"salesQuantity",
        Cell: (props) =>{
              const editable = Number(props?.row?.original?.sr) === Number(editRow) ;
              return editable ? (
              <input 
                  defaultValue={props?.row?.original?.salesQuantity}
                  onChange={(e)=>{
                      upSalesQuantity.current = e.target.value;
                  }}
                  type="number" className="sales-table__input" 
              />
            ) :
            (
              <span>{props?.row?.original?.salesQuantity}</span> 
            )
        },
      },
      {
        Header: "Free Stocks",
        accessor: "freeStocks",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 90,
        showTotal: true,
        totalAccessor: "freeStocks",
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          return editable ? (
            <input
              defaultValue={props?.row?.original?.freeStocks}
              onChange={(e) => {
                upFreeStocksRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input h-25"
            />
          ) : (
            <span>{props?.row?.original?.freeStocks}</span>
          );
        },
      },
      {
        Header: "Sales Value",
        accessor: "salesValue",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 90,
        maxWidth: 90,
        showTotal:true,
        totalAccessor:"salesValue",
        Cell:(props) => {
          return <div>
            {`₹ ${props?.row?.original?.salesValue}`}
          </div>
        }
      },
      {
        Header:"Closing Quantity", 
        accessor:"closingQuantity",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 90,
        maxWidth: 90,
        showTotal:true,
        totalAccessor:"closingQuantity",
      },
      {
        Header:"Closing Value", 
        accessor:"closingValue",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 90,
        maxWidth: 90,
        showTotal:true,
        totalAccessor:"closingValue",
        Cell:(props) => {
          return <div>
            {`₹ ${props?.row?.original?.closingValue}`}
          </div>
        }
      },
      {
        Header: "Actions",
        accessor: "action",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
        Cell: (props) => {
            if(loggedIn?.user?.isAdmin){
            return editing && Number(props?.row?.original?.sr) === editRow ? (
              <div className="d-flex gap-1 justify-content-center" >
                  <AiFillSave 
                      onClick={(e)=>{
                          submitEditedRow(props?.row?.original?.sr);
                      }}
                      type="button"
                      className="icon-color-green" 
                  />
                  {/* <MdDelete
                      onClick={() => {
                          deleteHandler(props?.row?.original?.product);
                      }}
                      type="button"
                      className="button-delete__icon"
                  /> */}
                  <MdCancel
                      type="button"
                      className="icon-color-yellow"
                      onClick={() => {
                          cancelHandler(props?.row?.original?.sr);
                      }}
                  />
              </div>
            ) :
            (
              <span
                className="react-table-view-link"
                onClick={() => {
                    handleEditRow(props?.row?.original);
                }}
              >
                <RiEdit2Fill className="icon-color-green" />
              </span>
            )
          }else {
            return (
              <span>Not Allowed</span>
            )
          }
        }
      },
    ],
    [finalArray , editing  ]
  );
  return (
    <main className="main-content admin-content">
      <div className="expense">
        <h3
            className="web-app__heading"
            style={{ display: "flex", alignItems: "center", justifyContent:"space-between" }}
          >
          <div>
            <MdArrowBackIosNew
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <span style={{ display: "inline-block", margin: "0 1rem" }}>
              Edit Secondary Sales
            </span>
          </div>
          {loggedIn?.user?.isAdmin?<span
              className="react-table-view-link"
              onClick={() => {
                setShowEdit(true);
              }}
            >
              <MdDelete className="icon-color-error" />
          </span>:null}
        </h3>
        <form onSubmit={handleSalesEdit}>
        <div className="util-inner-info-box-container">
            <div className="util-inner-info-box">
              <FaCalendar className="fs-2 text-light"/>
              <h3 className="d-inline ms-3">{returnMonthFromIndex(location?.state?.month)}</h3>
            </div>
            <div className="util-inner-info-box">
              <ImLocation className="fs-2 text-light" />
              <h3 className="d-inline ms-3">
                  {location?.state?.headquarter}
              </h3>
            </div>
            <div className="util-inner-info-box">
              <FaCartPlus className="fs-2 text-light"/>
              <h3 className="d-inline ms-3">
                {location?.state?.stockist}
              </h3>
            </div>
            {/* <div onClick={(e)=>fileDownloader(files)} className="util-inner-info-box cursor-pointer">
              <MdDownload className="fs-1 text-light"/>
              <h3 className="d-inline ms-3">Download</h3>
            </div> */}
            <div
              onClick={files.length === 0 ? null : (e) => fileDownloader(files)}
              className={`util-inner-info-box ${files.length === 0 ? 'disabled' : 'cursor-pointer'}`}
              style={{ filter: files.length === 0 ? 'blur(1px)' : 'none' }}
            >
              <MdDownload className="fs-1 text-light" />
              <h3 className="d-inline ms-3">Download</h3>
            </div>



          </div>
          <div className="filter-table" style={{minHeight:"150px" , overflow:"auto"}}>
            <Table columns={columns} data={finalArray} />
          </div>
          {finalArray.length > 0 && loggedIn?.user?.isAdmin && (
            <button
                type="submit"
                className="button-submit-green mt-4 mx-auto"
            >
                <span className="button-submit-green__icon-container">
                <MdCheckCircleOutline className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text ">
                    Submit Edited Data
                </span>
            </button>
          )}
        </form>
        {showEdit ? <EditPopup /> : null}
      </div>
    </main>
  );
};

export default EditSecondarySales;
