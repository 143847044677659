import { MdArrowBackIosNew , MdOutlineGroup} from "react-icons/md";
import Select from "react-select";
import { useSelector , useDispatch } from "react-redux";
import {
  dcrStyles,
} from "../../../public/stylesheets/selectStyles";
import { useCallback, useState, useMemo, useEffect } from "react";
import {
  viewUsersForUtilities,
} from "../../../../services/utilities";
import {
  generateSelectUser,
  generateSelectData
} from "../../../../utils/serialiseResponse";
import { monthOptions, yearOptions } from "../../../../utils/helper";
import { selectUserStyles } from "../../../public/stylesheets/selectStyles";
import StockistSS from "./Types/Stockist";
import HeadquarterSS from "./Types/Headquarter";
import UserSS from "./Types/User";
import StockistInventory from "./Types/Inventory";
import { useNavigate } from "react-router-dom";
import { viewHeadquarterSS , viewSTKWiseSales, viewUserWiseSales, viewSTKWiseSalesInventory } from "../../../../services/utilities";
import customToast from "../../../../components/CustomToast";
import { GiUpgrade } from "react-icons/gi";
const SSReports = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loggedIn = useSelector(({ user }) => user);
  
  // const state = useSelector((state)=>state);
  
  const [type , setType] = useState();
  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [reports, setReports] = useState([]);
  const [advance, setAdvance] = useState(false);
  const [endMonth, setEndMonth] = useState();
  const [endYear, setEndYear] = useState();
  

  
  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
  

  const typeOptions = [{label:"Stockist"} , {label:"Headquarter"} , {label:"User"}, {label:"Inventory"}] ;
  

  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      customToast.error("error occured Please Try Again");
    }
  }, []);
  const fetchStk = useCallback(
    async()=>{
      try {
        const filter = {
          month: month?.value,
          year: year?.label,
          endMonth : endMonth?.value,
          endYear : endYear?.label,
          advance : advance
        };
        const {data} = await viewSTKWiseSales(filter);
        setReports(data)
      } catch (error) {
        customToast.error("error occured Please Try Again");
      }
    },[dispatch,month,year, endMonth, endYear, advance]
  )
  const fetchStkInvent = useCallback(
    async()=>{
      try {
        const filter = {
          month: month?.value,
          year: year?.label,
          endMonth : endMonth?.value,
          endYear : endYear?.label,
          advance : advance
        };
        const {data} = await viewSTKWiseSalesInventory(filter);
        setReports(data)
      } catch (error) {
        customToast.error("error occured Please Try Again");
      }
    },[month, year, endMonth, endYear, advance]
  )
  const fetchUser = useCallback(
    async()=>{
      try {
          const filter = {
            id:selectedUser?.value,
            month: month?.value,
            year: year?.label,
            endMonth : endMonth?.value,
            endYear : endYear?.label,
            advance : advance
          };
          const {data} = await viewUserWiseSales(filter);
          
          setReports(data)
      } catch (error) {
        customToast.error("error occured Please Try Again");
      }
    },[selectedUser,month,year, endMonth, endYear, advance]
  )
  const fetchHQ = useCallback(
    async()=>{
      try {
          const filter = {
            month: month?.value,
            year: year?.label,
            endMonth : endMonth?.value,
            endYear : endYear?.label,
            advance : advance
          };
          const {data} = await viewHeadquarterSS(filter);
          setReports(data)
      } catch (error) {
        customToast.error("error occured Please Try Again");
      }
    },[month,year, endMonth, endYear, advance]
  )
  const handleSubmit = useCallback((e)=>{
    e.preventDefault();
    if(!month || !year || !type)return customToast.error("Please select all the necessary fields");
    if(advance === true && (!endYear || !endMonth))return customToast.error("Please select end month and year")
    if(type.label === 'Headquarter')fetchHQ();
    else if(type.label === "Stockist")fetchStk();
    else if(type.label === "User")fetchUser();
    else if(type.label === "Inventory")fetchStkInvent();
  },[month, year, type, endMonth, endYear, advance, fetchHQ, fetchStk,fetchUser, fetchStkInvent]);


  return (
    <div className="expense">
      
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MdArrowBackIosNew
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span style={{ display: "inline-block", margin: "0 1rem" }}>
            Secondary Sales Reports
          </span>
        </h2>
        <form onSubmit={handleSubmit}>
            <div className="util-tp-query d-flex justify-content-between flex-wrap">
                <div className="d-flex flex-wrap align-content-between secondary-sales-report-input-form">
                    <div className="util-tp-filter me-4">
                    <p>
                    {advance ? "Select Start Month" : "Select Month"} <span style={{ color: "#e74c3caa" }}>*</span>
                    </p>
                    <Select
                    styles={dcrStyles}
                    placeholder="Select Month"
                    className="mt-3"
                    options={monthOptions}
                    value={month}
                    onChange={(e) => setMonth({ ...e })}
                    />
                </div>

                <div className="util-tp-filter me-4">
                    <p>
                    {advance ? "Select Start Year" : "Select Year"} <span style={{ color: "#e74c3caa" }}>*</span>
                    </p>
                    <Select
                    styles={dcrStyles}
                    placeholder="Select Year"
                    className="mt-3"
                    options={yearOptions}
                    value={year}
                    onChange={(e) => setYear({ ...e })}
                    />
                </div>

                {advance && (
                  <div className="d-flex gap-2">
                    <div className="util-tp-filter me-4">
                    <p>
                   Select End Month <span style={{ color: "#e74c3caa" }}>*</span>
                    </p>
                    <Select
                    styles={dcrStyles}
                    placeholder="Select Year"
                    className="mt-3"
                    options={monthOptions}
                    value={endMonth}
                    onChange={(e) => setEndMonth({ ...e })}
                    />
                  </div>

                  <div className="util-tp-filter me-4">
                    <p>
                   Select End Year <span style={{ color: "#e74c3caa" }}>*</span>
                    </p>
                    <Select
                    styles={dcrStyles}
                    placeholder="Select Year"
                    className="mt-3"
                    options={yearOptions}
                    value={endYear}
                    onChange={(e) => setEndYear({ ...e })}
                    />
                  </div>    

                  </div>
                )}
                    <div className="util-tp-filter me-4">
                        <p>
                            Select Type <span style={{ color: "#e74c3caa" }}>*</span>
                        </p>
                        <Select
                            styles={dcrStyles}
                            placeholder="Select Type"
                            className="mt-3"
                            options={typeOptions}
                            value={type}
                            onChange={(e) => {
                              setType({ ...e });
                              setReports([]);
                              setSelectedUser("");
                            }}
                        />
                    </div>
                    {(type?.label === "User") && (loggedIn?.user?.des < 121 ||
                        loggedIn?.user?.designation?.priority > 1 ||
                        loggedIn?.user?.isAdmin) && (
                        <div className="util-tp-filter me-4">
                            <p >Select User</p>
                            <div className="util-select-user">
                                <MdOutlineGroup className="util-select-user__icon" />
                                <Select
                                    styles={selectUserStyles}
                                    options={employeeSelect}
                                    placeholder="Select User"
                                    className="mt-3"
                                    value={selectedUser}
                                    onChange={(e) => {
                                      setSelectedUser({ ...e });
                                      setReports([]);
                                    }}
                                />
                            </div>
                        </div>) 
                    }
                       
                </div>
                <div>
                    <div className="util-tp-filter">
                      <GiUpgrade onClick={() => setAdvance(!advance)} className="me-3 d-inline icon-color-tertiary cursor-pointer"/>
                        <button type="submit" className="button-blue-gradient mt-5 d-inline">
                            See Reports
                        </button>
                    </div>
                </div>
            </div>
        </form>
        {type?.label === "Headquarter" && <HeadquarterSS reports={reports} month={month} year={year}></HeadquarterSS>}
        {type?.label === "Stockist" && <StockistSS data={reports} month={month} year={year}></StockistSS>}
        {type?.label === "Inventory" && <StockistInventory data={reports} month={month} year={year}></StockistInventory>}
        {type?.label === "User" && <UserSS month={month} reports={reports} user={selectedUser}></UserSS>}
    </div>
  );
};

export default SSReports;
