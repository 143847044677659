import {
  newProduct,
  viewProducts,
  editProduct,
  deleteProduct,
  viewProductType,
  newProductType,
  updateProductType,
  deleteProductsType,
} from "../../services/products.js";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PRODUCT_TYPE":
      return action.payload;

    case "CREATE_PRODUCT_TYPE":
      return { data: [...state.data, action.payload.data.data] };

    case "CLEAR_PRODUCT_TYPE":
      return initialState;

    case "EDIT_PRODUCT_TYPE":
      return { data: state.data };

    case "DELETE_PRODUCT_TYPE":
      return { data: state.data };

    default:
      return state;
  }
};

export const setProductTypes = () => {
  return async (dispatch) => {
    // const { data } = await viewProducts("type");
    const { data } = await viewProductType();

    dispatch({
      type: "SET_PRODUCT_TYPE",
      payload: { data: data },
    });
  };
};

export const addProductType = (data) => {
  return async (dispatch) => {
    try {
      // data = await newProduct("type", data);
      data = await newProductType(data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "CREATE_PRODUCT_TYPE",
      payload: { data: data },
    });
    dispatch(setProductTypes());
  };
};

export const clearProductTypes = () => {
  return {
    type: "CLEAR_PRODUCT_TYPE",
  };
};

export const editProductType = (id, data) => {
  return async (dispatch) => {
    try {
      // data = await editProduct("type", id, data);
      data = await updateProductType(id, data);
    } catch (e) {
      throw e;
    }

    dispatch({
      type: "EDIT_PRODUCT_TYPE",
      payload: { data: data },
    });
    dispatch(setProductTypes());
  };
};

export const deleteProductType = (data) => {
  return async (dispatch) => {
    try {
      // data = await deleteProduct("type", data);
      data = await deleteProductsType(data)
    } catch (e) {
      if (data.error) throw data.error;
    }
    dispatch({
      type: "DELETE_PRODUCT_TYPE",
      payload: { data: data },
    });
    dispatch(setProductTypes());
  };
};

export default reducer;
