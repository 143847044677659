import { useSelector } from "react-redux";
import CreationLink from "./Link";

const CreationIndex = () => {
  const loggedIn = useSelector(({ user }) => user);
  

  return (
    <div className="main-content">
      <div className="admin-panel_content">
        <div className="row">
          <div className="web-app__heading mt-5">
            Welcome to the Creation Panel
          </div>

          <div className="admin-panel__box-container">
            <CreationLink
              className={"admin-panel__cards"}
              title="Manage Locations"
              type="Areas"
              link="locations/create-city"
            />
            <CreationLink
              title="Doctors, Stockists & Chemists"
              type="Doctors"
              link="dcs/create-doctors"
              className={"admin-panel__cards"}
            />
            <CreationLink
                title="Samples & Gifts"
                type="Sample"
                link="ssg/create-gift"
                className={"admin-panel__cards"}
              />
            {loggedIn?.user?.des !== 121 ? (
              <CreationLink
                title="Approvals"
                type="Approval"
                link="approvals/approve-call-report"
                className={"admin-panel__cards"}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreationIndex;
