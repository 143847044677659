import { ResponsiveLine } from '@nivo/line';

const OverviewGraph = (props) => {
  
	const data = [
		{
			id: 'japan',
			color: 'hsl(188, 70%, 50%)',
			data: props?.data
		},
	];

	const themeObject = {
		textColor: '#ddd',
		axis: {
			ticks: {
				line: {
					stroke: '#fff',
				},
				text: {
					fill: '#bbb',
				},
			},
		},
		grid: {
			line: {
				// stroke: '#C7C4E9',
				stroke: '#373851',
				strokeWidth: 3,
			},
		},
		labels: {
			text: {
				fill: '#fff',
			},
		},
		crosshair: {
			line: {
				stroke: '#fff',
				strokeWidth: 1.5,
				strokeOpacity: 0.35,
			},
		},
	};

	const tooltipStyle = {
		padding: '0.5rem 1.2rem',
		backgroundColor: '#353456',
		color: '#fff',
		fontSize: '1.2rem',

		borderRadius: '5px'
	};

	return (
		<ResponsiveLine
		data={data}
		margin={{ top: 50, right: 30, bottom: 35, left: 30 }}
		xScale={{ type: 'point' }}
		yScale={{
			type: 'linear',
			min: '0',
			max: '30',
			stacked: true,
			reverse: false,
		}}
		yFormat=" >-.2f"
		curve="cardinal"
		axisTop={null}
		axisRight={null}
		axisLeft={{
			orient: 'left',
			tickSize: 5,
			tickPadding: 10,
			tickRotation: 0,
			tickValues: 4,
			legend: 'count',
			legendOffset: -40,
			legendPosition: 'middle',
		}}
		enableGridY={false}
		pointSize={5}
		pointColor={{ theme: 'background' }}
		pointBorderWidth={2}
		pointBorderColor={{ from: 'serieColor' }}
		pointLabelYOffset={-12}
		enableArea={true}
		areaBaselineValue={10}
		useMesh={true}
		colors={['#35cc74']}
		colorBy="index"
		theme={themeObject}
		tooltip={(input) => (
			<div style={tooltipStyle}>
				<span>{input.point.data.x}</span>
				<br />
				<span>
					<strong>{parseInt((input.point.data.y / 30) * 100)}%</strong>
				</span>
			</div>
		)}
		defs={[
			{
				id: 'gradientC',
				type: 'linearGradient',
				colors: [
					{ offset: 0, color: '#35cc74', opacity: 100 },
					{ offset: 100, color: '#353456' },
				],
			},
		]}
		fill={[{ match: '*', id: 'gradientC' }]}
	/>
	);
};

export default OverviewGraph;
