import { deleteRequest, getAllDeletionRequest, rejectRequest } from "../../services/deletionRequest";
import { updateTrigger } from "../approveLengthReducer";

const initialState = {
  data: [],
};

const deletionRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_DELETION_REQUEST":
      return {
        data: action.payload,
      };
    case "DELETE_DELETION_REQUEST":
      return action.payload;
    case "REJECT_DELETION_REQUEST":
      return action.payload;
    default:
      return state;
  }
};

export const allDeletionRequest = () => {
    return async (dispatch) => {
      try {
        const data = await getAllDeletionRequest();
        dispatch({
          type: "FETCH_DELETION_REQUEST",
          payload: data?.data
        });
        dispatch(updateTrigger({index: 14, data: data?.data}));
      } catch (error) {
        console.error('Failed to fetch deletion request', error);
      }
    };
};

export const deleteDeletionRequest = (payload) => {
  return async (dispatch) => {
    const data = await deleteRequest(payload);
    dispatch(allDeletionRequest());
  };
};

export const rejectDeletionRequest = (payload) => {
  return async (dispatch) => {
    const data = await rejectRequest(payload);
    dispatch(allDeletionRequest());
  };
};

export default deletionRequestReducer;
