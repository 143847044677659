import { addSupplier, addSupplierV1, deleteSupplier, deleteSupplierV1, editSupplier, editSupplierV1, viewSupplier, viewSupplierV1 } from "../../services/products.js";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_SUPPLIER":
      return { ...state };

    case "SET_SUPPLIER": {
      return action.payload;
    }

    case "EDIT_SUPPLIER": {
      return {...state};
    }
    
    case "DELETE_SUPPLIER": {
      return {...state}
    }

    case "CLEAR_SUPPLIER":
      return initialState;

    default:
      return state;
  }
};

export const setProductSupplier = () => {
  return async (dispatch) => {
    // const { data } = await viewSupplier();
    const { data } = await viewSupplierV1();

    dispatch({
      type: "SET_SUPPLIER",
      payload: { data: data },
    });
  };
};

export const addProductsSupplier = (data) => {
  return async (dispatch) => {
    try {
      // data = await addSupplier(data);
      data = await addSupplierV1(data);
    } catch (e) {
      throw e;
    }

    dispatch({
      type: "ADD_SUPPLIER",
      payload: { data: data },
    });

    dispatch(setProductSupplier());
  };
};

export const editProductsSupplier = (id,data) => {
  return async (dispatch) => {
    try {
      // data = await editSupplier(id,data);
      data = await editSupplierV1(id, data);
    } catch (e) {
      throw e;
    }

    dispatch({
      type: "EDIT_SUPPLIER",
      payload: { data: data },
    });

    dispatch(setProductSupplier());
  };
};

export const deleteProductsSupplier = (data) => {
  return async (dispatch) => {
    try {
      // data = await deleteSupplier(data);
      data = await deleteSupplierV1(data);
    } catch (e) {
      throw e;
    }

    dispatch({
      type: "DELETE_SUPPLIER",
      payload: { data: data },
    });

    dispatch(setProductSupplier());
  };
};

export const clearSupplier = () => {
  return {
    type: "CLEAR_SUPPLIER",
  };
};
export default reducer;
