import { MdArrowBackIosNew } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCallback, useState, useMemo, useEffect } from "react";
import {
  addIndex,
  serialiseChemStk,
} from "../../../../../../utils/serialiseResponse";
import Table from "../../../../../../components/Table";
import { FaEye } from "react-icons/fa";
import customToast from "../../../../../../components/CustomToast";
import { viewSTKWiseSalesInventory } from "../../../../../../services/utilities";

const StockistInventory = ({data , month , year}) => {
  // const [reports, setReports] = useState([]);
  // const navigate = useNavigate();
  // const location = useLocation();
  // const id = location?.state?.data ;
  // const mon = month ? month : location?.state?.month ;
  // const yea = year ? year : location?.state?.year
  // const fetchStk = useCallback(
  //   async()=>{
  //     try {
  //       const filter = {
  //         id,
  //         month:mon?.value,
  //         year:yea?.label
  //       };
  //       const {data} = await viewSTKWiseSalesInventory(filter);
  //       setReports(data);
  //     } catch (error) {
  //       customToast.error("error occured Please Try Again");
  //     }
  //   },[id , mon,yea]
  // )

  // useEffect(()=>{
  //   fetchStk();
  // },[fetchStk]);
  // useEffect(()=>{
  //   if(id){
  //     fetchStk();
  //   }else if(data){
  //     setReports(data);
  //   }
  // },[fetchStk , id , data]);

  const columns = useMemo(
    () => [
      {
        Header: "Stockist",
        accessor: "businessName",
      },
      {
        Header: "Headquarter",
        accessor: "hqData.name",
      },
      {
        Header: "Total Quantity",
        accessor: "quantity",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"quantity"
      },
    ],
    []
  );
  
  const tableData = useMemo(() => addIndex(data), [data]);
  
  return (
    <div className={!data?"expense":""}>
      <div className="filter-table">
          {data?.length > 0 && (
              <h2 className="web-app__heading mb-4 ps-3">
                  Showing ({data?.length}) Entries
              </h2>
          )}
          {tableData.length > 0 ? (
              <Table columns={columns } data={tableData} fileSrc="Secondary Sales Reports (Inventory)" />
          ) : (
              <h3 className="mt-2 no-results-message">No results found</h3>
          )}
      </div> 
    </div>
  );
};

export default StockistInventory ;
