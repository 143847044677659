import NotificationAvatar from "../../../public/img/Notification-icon.png";
import { FaCartPlus,FaRoute,FaHome, FaRoad, FaCity,FaStethoscope,FaBriefcaseMedical } from "react-icons/fa";
import { CgPill } from "react-icons/cg";
import { GiTakeMyMoney } from "react-icons/gi";
import { MdNotificationsActive } from "react-icons/md";
const NotificationBox = ({ title, time, description, type }) => {
  
  let icon = null ;
  switch (type) {
    case "TP":
      icon = (<FaRoute />)
      break;
    case "CR":
      icon = (<FaStethoscope />)
     break;
    case "EXP":
      icon = (<FaCartPlus />)
     break;
    case "LR":
      icon = (<FaHome />)
      break;
    case "DOC":
      icon = (<FaCartPlus />)
      break;
    case "CHM":
      icon = (<CgPill />)
      break;
    case "STK":
      icon = (<FaBriefcaseMedical />)
      break;
    case "CTY":
      icon = (<FaCity />)
      break;
    case "RUT":
      icon = (<FaRoad />)
      break;
    case "PS":
      icon = (<GiTakeMyMoney />)
      break;
    case "SS":
      icon = (<GiTakeMyMoney />)
      break;
    default : {
      icon = (<MdNotificationsActive />)
    }  
  }
  return (
    <>
      <div className="notification__box">
        <div className="notification__avatar">
          {/* <img src={NotificationAvatar} alt="Notification Avatar" /> */}
          {icon}
        </div>
        <div className="notification__text">
          <div className="notification__text-top">
            <div className="notification__title">{title}</div>
            <div className="notification__time">{time}</div>
          </div>
          <div className="notification__description">{description}</div>
        </div>
      </div>
    </>
  );
};

export default NotificationBox;
