import { MdArrowBackIosNew} from "react-icons/md";
import {useLocation, useNavigate } from "react-router-dom";
import { useState, useMemo, useEffect } from "react";
import {
  serialiseChemStk,
} from "../../../../../../utils/serialiseResponse";
import Table from "../../../../../../components/Table";
import { viewProductWiseSales } from "../../../../../../services/utilities";
import customToast from "../../../../../../components/CustomToast";

const ProductSS = () => {
  const [reports, setReports] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.data ;
  const month = location?.state?.month ;//any component redirecting must pass these in state
  const year = location?.state?.year ;//these values are used in backend
  useEffect(()=>{
    const fetch  = async()=>{
      try {
        const filter = {
          id,
          month:month?.value,
          year:year?.label
        };
        const {data} = await viewProductWiseSales(filter);
        setReports(data);
      } catch (error) {
        customToast.error("error occured Please Try Again");
      }
    }
    fetch();
  },[id,month,year]);

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   minWidth:50,
      //   maxWidth:50,
      //   disableFilters:true,
      // },
      {
        Header: "Product",
        accessor: "product",
        showTotal:true,
        placeholderTotal:"Total",
      },
      {
        Header: "Total Secondary Sales",
        accessor: "salesValue",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"salesValue",
        Cell:(props) => {
          return <div>
            {`₹ ${Number(props?.row?.original?.salesValue).toFixed(2)}`}
          </div>
        }
      },
    ],
    []
  );
  const tableData = useMemo(() => serialiseChemStk(reports), [reports]);
  
  return (
    <div className="expense">
      <h2
        className="web-app__heading"
        style={{ display: "flex", alignItems: "center" }}
      >
        <MdArrowBackIosNew
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        <span style={{ display: "inline-block", margin: "0 1rem" }}>
          Product Wise Sales Reports
        </span>
      </h2>
      <div className="filter-table">
          {reports.length > 0 && (
              <h2 className="web-app__heading mb-4 ps-3">
                  Showing ({reports.length}) Entries
              </h2>
          )}
          {tableData.length > 0 ? (
              <Table columns={columns} data={tableData} />
          ) : (
              <h3 className="mt-2 no-results-message">No results found</h3>
          )}
      </div> 
    </div>
  );
};

export default ProductSS ;
