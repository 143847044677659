import React, { useEffect, useRef } from "react";
import PageTitle from "../../../../components/PageTitle";

const AdminFamily = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    canvas.width = 500;
    canvas.height = 1000;
    // Family data structure
    const familyData = {
      name: "Head of the Family",
      children: [
        {
          name: "Child 1",
          children: [
            {
              name: "Child 4",
              children: [],
            },
          ],
        },
        {
          name: "Child 2",
          children: [
            {
              name: "Child 3",
              children: [
                {
                  name: "Child 5",
                },
              ],
            },
            {
              name: "Child 3",
              children: [
                {
                  name: "Child 5",
                },
              ],
            },
          ],
        },
        {
          name: "Child 3",
          children: [
            {
              name: "Child 5",
            },
          ],
        },
        // Add more children as needed
      ],
    };

    // Set node radius and gap between nodes
    const nodeRadius = 20;
    const horizontalGap = 50;
    const verticalGap = 120; // Increase the vertical gap for better spacing
    // ctx.scale(2, 2)
    // Function to draw a family tree in breadth-first order
    function drawFamilyTreeBFS(root, startX, startY) {
      const queue = [[{ node: root, x: startX, y: startY }]]; // Use an array of queues for each level
      let maxw = 0 ;
      while (queue.length > 0) {
        const currentLevel = queue.shift();
       
        // maxw = currentLevel.length * 180  ;
        currentLevel.forEach(({ node, x, y }) => {
          // Draw the current node
          ctx.beginPath();
          ctx.arc(x, y, nodeRadius, 0, 2 * Math.PI);
          ctx.fillStyle = "lightblue";
          ctx.fill();
          ctx.stroke();
          ctx.closePath();

          // Draw text inside the node
          ctx.fillStyle = "black";
          ctx.textAlign = "center";
          ctx.fillText(node.name, x, y);

          // Calculate child node positions
          const numChildren = node.children ? node.children.length : 0;
          const startXChild = x - (numChildren - 1) * horizontalGap / 2;

          // Draw lines and add children to the queue
          if (node.children) {
            node.children.forEach((child, index) => {
              const childX = startXChild + index * horizontalGap;
              const childY = y + verticalGap;

              // Draw connecting line
              ctx.beginPath();
              ctx.moveTo(x, y + nodeRadius);
              ctx.lineTo(childX, childY - nodeRadius);
              ctx.stroke();
              ctx.closePath();

              // Add child to the next level's queue
              if (!queue[0]) {
                queue.push([]);
              }
              queue[0].push({ node: child, x: childX, y: childY });
            });
          }
        });
      }
      // canvas.width = Math.max(maxw, canvas.width);
      // canvas.height = 2*Number(canvas.width) ;
      
    }

    // Initial draw
    drawFamilyTreeBFS(familyData, canvas.width / 2, 50);
    
    
  }, []);

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="My Family" />
        <div className="family">
          <canvas ref={canvasRef} id="familyCanvas" />
        </div>
      </div>
    </div>
  );
};

export default AdminFamily;
