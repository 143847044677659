const initialState = {
    sideNavVisible: true
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'HIDE_NAV':
            return {...state, sideNavVisible: false};
        case 'SHOW_NAV':
            return {...state, sideNavVisible: true};
        default:
            return state;
    }
};

export const hideAdminNav = () => {
    return {
        type: 'HIDE_NAV'
    };
};

export const showAdminNav = () => {
    return {
        type: "SHOW_NAV"
    };
};

export default reducer;