const initialState = {
	sidenavVisible: true,
	trimSideNav : false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SHOW_SIDENAV':
			return { ...state, sidenavVisible: true, trimSideNav:false };

		case 'HIDE_SIDENAV':
			return { ...state, sidenavVisible: false, trimSideNav:false };

		case 'TRIM_SIDENAV':
			return {...state, trimSideNav: true, sidenavVisible:false};	

		default:
			return state;
	}
};

export const showSidenav = () => {
	return {
		type: 'SHOW_SIDENAV',
	};
};

export const hideSidenav = () => {
	return {
		type: 'HIDE_SIDENAV',
	};
};

export const showTrimNav = () =>{
	return {
		type: 'TRIM_SIDENAV',
	};
};

export default reducer;
