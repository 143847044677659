import DCRForm from "../../../components/DCRForm";

const Chemist = () => {
  return (
    <div className="main-content">
      <h2 className="web-app__heading dcr__heading">Chemist DCR</h2>
      <DCRForm dcrType="Chemist" />
    </div>
  );
};

export default Chemist;
