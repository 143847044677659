import React, { useEffect, useMemo, useState } from 'react'
import { MdArrowBackIosNew, MdCheckCircleOutline, MdDeleteOutline, MdOutlineDelete } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import Select from "react-select";
import { dcrStyles, selectUserStyles } from '../../../public/stylesheets/selectStyles';
import { generateSelectData } from '../../../../utils/serialiseResponse';
import { useDispatch, useSelector } from 'react-redux';
import { clearHeadquarters, setHeadquarters } from '../../../../reducers/locations/hqReducer';
import { ignoreDuplicateEntry, viewDuplicateEntries } from "../../../../services/duplicationPools";
import Table from '../../../../components/Table';
import { FaEye } from 'react-icons/fa';
import { IoIosRemoveCircle, IoMdRemoveCircle } from 'react-icons/io';
import { RiCloseCircleLine } from 'react-icons/ri';
import customToast from '../../../../components/CustomToast';

const typeOptions = [
    { value: 1, label: "Doctor" },
    { value: 2, label: "Chemist" },
    { value: 3, label: "Stockist" },
  ];

const UtilitiesDuplicateEntries = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const headquarters = useSelector(({ headquarter }) => headquarter);

    const [type, setType] = useState({ value: 1, label: "Doctor" });
    const [reports, setReports] = useState([]);
    const [showEdit,setShowEdit] = useState(false);
    const [handleIgnoreEntryId, setHandleIgnoreEntryId] = useState();

    const [headquarter, setHeadquarter] = useState("");

    const headquarterSelect = useMemo(
        () => generateSelectData(headquarters.data, "name"),
        [headquarters]
      );

    //   useEffect(() => {
    //     dispatch(setHeadquarters());
    //     return () => {
    //       dispatch(clearHeadquarters());
    //     };
    //   }, [dispatch]);

    
    useEffect(() => {
        viewDuplicateEntries({entity: type.label}).then((response) => {
            setReports(response?.data);
          });
    },[type])

      // const handleIgnore = (id) => {
      //   console.log(id)
      //   ignoreDuplicateEntry({entity: type.label, objId: id})
      // }

      const EditPopup = () => {
  

        // const handleDelete = (e) => {
        //   e.preventDefault();
          
          // deleteLeaveRequest(selectedLeaveRequest).then(()=>{
          //   customToast.success("Leave Request deleted successfully ");
           
          //   let filter = {
          //     selectedUser: selectedUser?.value,
          //     month: month?.value,
          //     year: Number(year?.label),
          //   };
        
          //   viewLeaves(filter).then((response) => setLeaveData(response.data));
          // }).catch(e=>{
          //   customToast.error("It seems we encountered and error!");
          // });
        //   setShowEdit(false);
        // };

        
        const handleIgnore = (id) => {
          ignoreDuplicateEntry({entity: type.label, objId: handleIgnoreEntryId}).then(() => {
            viewDuplicateEntries({entity: type.label}).then((response) => {
              setReports(response?.data);
            });
          }).catch(e=>{
              customToast.error("we encountered an error!");
            });

          setShowEdit(false);
        }
    
        return (
          <section className="edit-popup">
            <div className="edit-content">
              <div className="edit-popup__heading">
                <h2 className="web-app__heading">Ignore Duplicate Entry</h2>
                <RiCloseCircleLine
                  className="edit-popup__close"
                  onClick={() => setShowEdit(false)}
                />
              </div>
    
              <form 
              onSubmit={handleIgnore}
              >
                <div className="edit-form">
                  <div>
                    <label htmlFor="division">
                    Are you Sure You Want To Ignore This Entry
                    </label>
                    <br />
                  </div>
                </div>
    
                <div className="edit-form-button-container">
                  <button type="submit" className="button-delete mt-4">
                    <span className="button-delete__icon-container">
                      <MdDeleteOutline className="button-delete__icon" />
                    </span>
                    <span className="button-delete__text">Yes</span>
                  </button>
    
                  <button
                    type="button"
                    className="button-submit-green mt-4"
                    onClick={() => setShowEdit(false)}
                  >
                    <span className="button-submit-green__icon-container">
                      <MdCheckCircleOutline className="button-submit-green__icon" />
                    </span>
                    <span className="button-submit-green__text">No</span>
                  </button>
                </div>
              </form>
            </div>
          </section>
        );
      };

      const columns = useMemo(
        () => [
          {
            Header: "Entry A",
            accessor: "entityA.name",
          },
          {
            Header: "Entry B",
            accessor: "entityB.name",
          },
          {
            Header: "View",
            accessor: "",
            minWidth: 60,
            maxWidth: 60,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props) => {
              return (
                <Link
                  to="details"
                  state={ { data: props?.row?.original, type: type.label} }
                  className="util-table-view-details-link"
                >
                  <FaEye className="icon-color-tertiary" />
                </Link>
              );
            },
          },
          {
            Header: "Ignore",
            accessor: "",
            Cell: (props) => {
              return (
                <button
                  // disabled={loggedIn?.user?.isAdmin?false:true}
                  className="react-table-view-link bg-transparent border-0"
                  onClick={() =>{ 
                    setShowEdit(true);
                    setHandleIgnoreEntryId(props?.row?.original?._id)
                  }}
                >
                  <RiCloseCircleLine className={`icon-color-error`} />
                </button>
              );
            },
            minWidth: 60,
            maxWidth: 60,
            disableFilters: true,
            disableSortBy: true,
          },
        ],
        [type]
      );


    return (
        <>
        <div className="expense">
          <div className="row">
            <h2
              className="web-app__heading"
              style={{ display: "flex", alignItems: "center" }}
            >
              <MdArrowBackIosNew
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              />
              <span style={{ display: "inline-block", margin: "0 1rem" }}>
                Duplicate Entries
              </span>
            </h2>
    
            <form >
              <div className="util-tp-query">
    
                <div className="util-tp-filter">
                  <p>Select Doc / Chem / Stockist</p>
                  <Select
                    styles={dcrStyles}
                    placeholder="Select Type"
                    className="mt-3"
                    options={typeOptions}
                    value={type}
                    onChange={(e) => setType({ ...e })}
                  />
                </div>

                  {/* <div className="util-tp-filter">
                    <p className="util-select-user__label">Select Headquarter</p>
                      <Select
                        styles={selectUserStyles}
                        options={headquarterSelect}
                        placeholder="Select Headquarter"
                        className="mt-3"
                        value={headquarter}
                        onChange={(e) => setHeadquarter({ ...e })}
                      />
                  </div> */}
                
    
                {/* <div className="util-tp-filter">
                  <button type="submit" className="button-blue-gradient mt-4">
                    See Reports
                  </button>
                </div> */}
              </div>
            </form>
            <div className="filter-table">
            {reports.length > 0 && (
              <h2 className="web-app__heading mb-4">
                Showing ({reports.length}) Entries
              </h2>
            )}
              {reports.length>0?<Table columns={columns} data={reports}  fileSrc="POB Report" />:<h3 className="mt-2 no-results-message">No results found</h3>}
            </div>
          </div>
        </div>
        { showEdit && <EditPopup /> }
        </>
      );
}

export default UtilitiesDuplicateEntries