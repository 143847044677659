import { newSample, viewSample } from "../../services/products.js";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SAMPLE":
      return action.payload;

    case "CREATE_SAMPLE":
      return { data: [...state.data, action.payload.data.data] };

    case "CLEAR_SAMPLE":
      return initialState;

    default:
      return state;
  }
};

export const setSample = (details) => {
  return async (dispatch) => {
    const { data } = await viewSample(details);

    dispatch({
      type: "SET_SAMPLE",
      payload: { data: data },
    });
  };
};

export const addSample = (data) => {
  return async (dispatch) => {
    try {
      data = await newSample(data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "CREATE_SAMPLE",
      payload: { data: data },
    });
  };
};

export const clearSample = () => {
  return {
    type: "CLEAR_SAMPLE",
  };
};

export default reducer;
