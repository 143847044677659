import { useEffect, useMemo, useState} from "react";
import PageTitle from "../../../../../components/PageTitle";
import { useLocation } from "react-router-dom";
import { formatDate, formatTime } from "../../../../../utils/helper";
// import Table from "../../../../components/Table";
import "../index.css" ;
const UserDevicesDetail = () => {
 
  const location = useLocation();
  
  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title={`${location?.state?.firstName} ${location?.state?.lastName}'s Devices`} />
        <div className="p-3 d-flex flex-row flex-wrap  align-items-center justify-content-center">
          
          {location.state.devices.map((device, idx)=>
            <div className="mobile-holder mx-5" key={idx}>
              <div className="p-5 d-flex gap-5 flex-column align-items-start justify-content-evenly">
                <span className="mt-5 pt-3 details-container">Name: <span className="info-cont">{device.deviceName}</span></span>
                <span className="details-container" >Brand: <span className="info-cont">{device.brand}</span></span>
                <span className="details-container" >Manufacturer: <span className="info-cont">{device.manufacturer}</span></span>
                <span className="details-container" >Device Year Class: <span className="info-cont">{device.deviceYearClass}</span></span>
                <span className="details-container" >App Version: <span className="info-cont">{device.medorn}</span></span>
                <span className="details-container" >First Login Date: <span className="info-cont">{formatDate(new Date(device.createdAt))}</span></span>
                <span className="details-container" >Last Login Date: <span className="info-cont">{formatDate(new Date(device.updatedAt))}</span></span>
                <span className="details-container" >Last Login Time: <span className="info-cont">{formatTime(new Date(device.updatedAt))}</span></span>
              </div>
            </div>
          )}
          
        </div>
        
      </div>
    </div>
  );
};

export default UserDevicesDetail;
