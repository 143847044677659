export const title = "Manage Doctors, Stockists & Chemists";

export const links = [
  {
    name: "Create Doctors",
    route: "create-doctors",
  },
  {
    name: "Create Chemists",
    route: "create-chemists",
  },
  {
    name: "Create Stockists",
    route: "create-stockists",
  },
  {
    name: "View Doc / Chem / Stk",
    route: "view-dcs",
  },
  {
    name: "Edit / Delete",
    route: "edit-delete",
  },
  {
    name: "Upload DCS / City",
    route: "upload-dcs",
  },
  {
    name: "E-Detailing Management",
    route: "admin-e-detailing-management",
  },
  {
    name: "Upload Images",
    route: "upload-images",
  },
  {
    name: "Allot Doc / Chem / Stk",
    route: "allot-dcs",
  },
  {
    name: "De-Allot Doc / Chem / Stk",
    route: "reassign-dcs",
  },
];
