import {
  newTP,
  tpApproval,
  tpDeletion,
  tpRejection,
  viewTP,
  viewTPForMonth,
  viewTPForApproval,
  viewTPForDCR,
  viewTPWithStatus
} from "../services/tourProgram.js";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "VIEW_TP":
      return action.payload;
    case "VIEW_TP_FOR_MONTH":
      return action.payload;
    case "VIEW_TP_FOR_DCR":
      return action.payload;
    case "VIEW_TP_FOR_APPROVAL":
      return action.payload;
    case "CREATE_TP":
      return action.payload;
    case "APPROVE_TP":
      return {data:state.data};
    case "REJECT_TP":
      return {data:state.data};
    case "DELETE_TP":
      return {data:state.data};
    case "VIEW_TP_STATUS":
      return action.payload;
    case "CLEAR_TP":
      return initialState;

    default:
      return state;
  }
};

export const setTourProgram = (details) => {
  
  return async (dispatch) => {
    const { data } = await viewTP(details);
    dispatch({
      type: "VIEW_TP",
      payload: { data: data },
    });
  };
};

export const setTourProgramForStatus = (details) => {
  return async (dispatch) => {
    const { data } = await viewTPWithStatus(details);

    dispatch({
      type: "VIEW_TP_STATUS",
      payload: { data: data },
    });
  };
};

export const setTourProgramForDCR = () => {
  return async (dispatch) => {
    const data = await viewTPForDCR();

    dispatch({
      type: "VIEW_TP_FOR_DCR",
      payload: { data: data },
    });
  };
};

export const setTourProgramForApproval = (details) => {
  return async (dispatch) => {
    const { data } = await viewTPForApproval(details);
 
    dispatch({
      type: "VIEW_TP_FOR_APPROVAL",
      payload: { data: data },
    });
  };
};

export const addTP = (data) => {
  return async (dispatch) => {
    try {
      data = await newTP(data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "CREATE_TP",
      payload: data,
    });
  };
};

export const approveTP = (id, data) => {
  return async (dispatch) => {
    try {
      data = await tpApproval(id, data);
    } catch (e) {
      throw e.response ;
    }
    
    dispatch({
      type: "APPROVE_TP",
      payload: { data: data },
    });
    dispatch(setTourProgramForApproval());
  };
};

export const rejectTP = (id, data) => {
  return async (dispatch) => {
    try {
      data = await tpRejection(id, data);
    } catch (e) {
      throw e.response ;
    }

    dispatch({
      type: "REJECT_TP",
      payload: { data: data },
    });
    dispatch(setTourProgramForApproval());
  };
};

export const deleteTP = (id, data) => {
  return async (dispatch) => {
    try {
      data = await tpDeletion(id, data);
    } catch (e) {
      if (e) throw e;
    }

    dispatch({
      type: "DELETE_TP",
      payload: { data: data },
    });
  };
};
export const setTPForMonth = (details) => {
  
  return async (dispatch) => {
    const { data } = await viewTPForMonth(details);
    dispatch({
      type: "VIEW_TP_FOR_MONTH",
      payload: { data: data },
    });
  };
};
export const clearTourProgram = () => {
  return {
    type: "CLEAR_TP",
  };
};

export default reducer;
