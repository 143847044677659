import PageTitle from "../../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleAnnouncements,
  toggleHqPreference,
  toggleLockDayPreference,
  toggleStockPreference,
  toggleMinCallReportLock,
  toggleMissedDocFilter,
  toggleCompanyName,
  toggleTpDeadline,
  toggleSecondary,
  toggleTourLimit,
  toggleSundayWorking,
  togglEexpenseApproval,
  toggleViewAllHqs,
  toggleRestrictUserLock
} from "../../../../reducers/settings";
import customToast from "../../../../components/CustomToast";
import { useEffect, useState, useRef } from "react";
import { addAnnouncements } from "../../../../reducers/announcement";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { changeCompanyName } from "../../../../reducers/companyName";
import { fileToBase64 } from "../../../../utils/helper";

const AdminPreferences = ({ socket }) => {
  const dispatch = useDispatch();
  const headquarterWise = useSelector((state) => state.settings["hq-target"]);
  const minStock = useSelector((state) => state.settings["min-stock"]);
  const callReportsLock = useSelector((state) => state.settings["minCall-reports-lock"]);
  const missedDocFilter = useSelector((state) => state.settings["missed-doc-filter"]);
  const isCompanyName = useSelector((state) => state.settings["company-name"]);
  const tpDeadline = useSelector((state) => state.settings["tp-deadline"]);
  const secondary = useSelector((state) => state.settings["secondary"]);
  const tourlimit = useSelector((state) => state.settings["tourlimit"]);
  const sundayWorking = useSelector((state) => state.settings["sundayWorking"]);
  const expenseApproval = useSelector((state) => state.settings["expenseApproval"]);
  const viewAllHqs = useSelector((state) => state.settings["viewAll-hqs"]);
  const restrictUserLock = useSelector((state) => state.settings["restrictUserLock"]);

  const lockDays = useSelector(
    (state) => state.settings["lock-day-preference"]
  );
  // const updateRequest = useSelector(
  //   (state) => state.settings["appUpdateRequest"]
  // );
  const announcement = useSelector((state) => state.settings["announcement"]);
  const announcementVal = useSelector((state) => state.announcement["announcementMessage"])
  const [announcementText, setAnnouncementText] = useState("");
  const [announcementBgColor, setAnnouncementBgColor] = useState("red");
  const [announcementType, setAnnouncementType] = useState("Announcement");
  const [expireDate, setExpireDate] = useState();
  const [companyName, setCompanyName] = useState("Medorn")
  const inputRef = useRef(null);
  const [uploadData, setUploadData] = useState("");
  const [date, setDate] = useState(null);
  const [day, setDay] = useState(null);

  const dayData = [1, 2, 3, 4, 5, 6, 7, 8]

  let valueToggle = false;

  if (headquarterWise) {
    valueToggle = headquarterWise
  }

  const logState = (event) => {
    if (event.target.checked === true) {
      dispatch(toggleHqPreference("hq-target", { value: "true" }));
    } else {
      dispatch(toggleHqPreference("hq-target", { value: "false" }));
    }
  };
  const logState2 = (event) => {
    if (event.target.checked === true) {
      dispatch(toggleStockPreference("min-stock", { value: "true" }));
    } else {
      dispatch(toggleStockPreference("min-stock", { value: "false" }));
    }
  };
  const logState3 = (event) => {
    if (event.target.checked) {
      dispatch(toggleLockDayPreference("lock-day-preference", { value: 3 }));
    } else {
      dispatch(
        toggleLockDayPreference("lock-day-preference", { value: "false" })
      );
    }
  };

  const logState4 = (event) => {
    if (event.target.checked) {
      dispatch(toggleAnnouncements("announcement", { value: "true" }));
    } else {
      dispatch(toggleAnnouncements("announcement", { value: "false" }));
    }
  };

  // const logState5 = (event) => {
  //   if (event.target.checked === true) {
  //     dispatch(toggleAppUpdateRequest("appUpdateRequest", { value: "true" }));
  //   } else {
  //     dispatch(toggleAppUpdateRequest("appUpdateRequest", { value: "false" }));
  //   }
  //   // socket.emit("appUpdateRequest", event.target.checked ? true: false); //emit an event to the server

  // };
  const logState8 = (event) => {
    if (event.target.checked) {
      dispatch(toggleCompanyName("company-name", { value: "true" }));
    } else {
      dispatch(toggleCompanyName("company-name", { value: "false" }));
    }

  };

  const logState6 = (event) => {
    if (event.target.checked === true) {
      dispatch(toggleMinCallReportLock("minCall-reports-lock", { value: "true" }));
    } else {
      dispatch(toggleMinCallReportLock("minCall-reports-lock", { value: "false" }));
    }
  };
  const logState7 = (event) => {
    if (event.target.checked === true) {
      dispatch(toggleMissedDocFilter("missed-doc-filter", { value: "true" }));
    } else {
      dispatch(toggleMissedDocFilter("missed-doc-filter", { value: "false" }));
    }
  };
  const logState9 = (event) => {
    if (event.target.checked === true) {
      setDay(new Date().getDate())
      dispatch(toggleTpDeadline("tp-deadline", { value: new Date().getDate() }));
    } else {
      dispatch(toggleTpDeadline("tp-deadline", { value: "false" }));
    }
  }
  const logState10 = (event) => {
    if (event.target.checked) {
      dispatch(toggleSecondary("secondary", { value: "true" }));
    } else {
      dispatch(toggleSecondary("secondary", { value: "false" }));
    }
  };
  const logState11 = (event) => {
    if (event.target.checked) {
      dispatch(toggleTourLimit("tourlimit", { value: "true" }));
    } else {
      dispatch(toggleTourLimit("tourlimit", { value: "false" }));
    }
  };
  const logState12 = (event) => {
    if (event.target.checked) {
      dispatch(toggleSundayWorking("sundayWorking", { value: "true" }));
    } else {
      dispatch(toggleSundayWorking("sundayWorking", { value: "false" }));
    }
  };
  const logState13 = (event) => {
    if (event.target.checked) {
      dispatch(togglEexpenseApproval("expenseApproval", { value: "true" }));
    } else {
      dispatch(togglEexpenseApproval("expenseApproval", { value: "false" }));
    }
  };

  const logState14 = (event) => {
    if(event?.target?.checked){
      dispatch(toggleViewAllHqs("viewAll-hqs", {value: 'true'}));
    } else{
      dispatch(toggleViewAllHqs("viewAll-hqs", {value: 'false'}));
    }
  };

  const logState15 = (event) => {
    if(event?.target?.checked){
      dispatch(toggleRestrictUserLock("restrictUserLock", {value: 'true'}));
    } else{
      dispatch(toggleRestrictUserLock("restrictUserLock", {value: 'false'}));
    }
  };

  const dayHandler = (target) => {
    try {
      dispatch(
        toggleLockDayPreference("lock-day-preference", { value: target.value })
      );
    } catch (err) {
      customToast.error("We encountered an error");
    }
  };
  const handleColorClick = (color) => {
    setAnnouncementBgColor(color);
  };

  const handleDateChange = (date) => {
    if (date < new Date()) {
      customToast.error("Cannot select previous date");
      setExpireDate(null);
    } else {
      setExpireDate(date);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (day === null || day === "")
    // if (date === null)
      return customToast.error("Select Date");
    try {
      dispatch(
        toggleTpDeadline("tp-deadline", { value: day })
      );
      customToast.success("TP deadline has been set successfully ")
    } catch (err) {
      customToast.error("Error Occurred");
    }
  };

  const changeHandler = (e) => {
    const validFiles = Array.from(e.target.files);
    setUploadData([]);
    Promise.all(validFiles.map(fileToBase64)).then((base64Images) => {
      setUploadData(base64Images);
    })
      .catch((error) => {
        customToast.error('Error adding images');
      });
  };

  const announcementSubitHandler = useCallback(
    (e) => {
      e.preventDefault();
      try {
        dispatch(
          addAnnouncements({
            text: announcementText,
            color: announcementBgColor,
            type: announcementType,
            date: expireDate,
          })
        )
          .then(() => {
            customToast.success("Announcement added");
          })
          .catch(() => {
            customToast.error("Error in Adding announcement");
          });
      } catch (err) {
        customToast.error("We encountered an error");
      }
    },
    [announcementText, announcementBgColor, announcementType, expireDate]
  );
  const companyNameSubitHandler = useCallback(
    (e) => {
      e.preventDefault();
      const payload = {
        companyName: companyName,
        images: uploadData
      }
      try {
        // dispatch(
        //   changeCompanyName({
        //     companyName: companyName,
        //   }))
        dispatch(changeCompanyName(payload))
          .then(() => {
            customToast.success("Company Name Changed");
          })
          .catch(() => {
            customToast.error("Error in Changing Company Name");
          });
      } catch (err) {
        customToast.error("We encountered an error");
      }
    }
  );

  useEffect(() => {
    setAnnouncementText(announcementVal)
  }, [announcementVal])

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Set Preferences" />
      </div>
      <section className="user-detail-container user-detail-container-green-border d-flex justify-content-between">
        <div>
          <h1 className="web-app__heading">Allot headquarter wise targets</h1>
          <h4 className="mt-5 w-75 lh-base" style={{ color: "#999" }}>
            This will allow admins to allot targets to headquarters, therefore
            the total target on the dashboard will depend on headquarters.
          </h4>
        </div>
        <div>
          <div className="form__control " style={{ width: "fit-content" }}>
            <label className="toggle-label">
              <input
                type="checkbox"
                checked={valueToggle === "true" ? true : false}
                onClick={logState}
              />
              <span />
            </label>
          </div>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-green-border d-flex justify-content-between">
        <div>
          <h1 className="web-app__heading">
            Get notified when stocks reach minimum threshold
          </h1>
          <h4 className="mt-5 w-75 lh-base" style={{ color: "#999" }}>
            This will allows users to get notified when the products are below
            minimum quantity, minimum quantity is set according to product type
            and for individual products so the notifications will depend on
            both.
          </h4>
        </div>
        <div>
          <div className="form__control " style={{ width: "fit-content" }}>
            <label className="toggle-label">
              <input
                type="checkbox"
                checked={ minStock === "true" ? true : false }
                onClick={logState2}
              />
              <span />
            </label>
          </div>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-green-border d-flex justify-content-between">
        <div>
          <h1 className="web-app__heading">
            Lock Users based on prolonged inactivity
          </h1>
          <h4 className="mt-5 w-75 lh-base" style={{ color: "#999" }}>
            This will allow admins to set the number of days of inactivity, if
            MR's are found inactive (not adding call reports) for that many days
            in a row, then the MR's account will be locked and they will not be
            able to login. <br />
            {lockDays ? (
              lockDays !== "false" ? (
                <div className="d-flex justify-content-between">
                  {dayData.map((e) => (
                    <div key={e} className="d-flex align-items-center gap-3">
                      <input
                        type="radio"
                        name="days"
                        value={e}
                        checked={e == lockDays}
                        onChange={({ target }) => dayHandler(target)}
                      />
                      <label className="text-nowrap mt-4">{e} Days</label>
                    </div>
                  ))}
                </div>
              ) : null
            ) : null}
          </h4>
        </div>
        <div>
          <div className="form__control " style={{ width: "fit-content" }}>
            <label className="toggle-label">
              <input
                type="checkbox"
                checked={
                  lockDays ? (lockDays !== "false" ? true : false) : null
                }
                onClick={logState3}
              />
              <span />
            </label>
          </div>
        </div>
      </section>

      <section className="user-detail-container user-detail-container-green-border d-flex justify-content-between">
        <div>
          <h1 className="web-app__heading">
            Filter Out the available doctor for DCR
          </h1>
          <h4 className="mt-5 w-75 lh-base" style={{ color: "#999" }}>
            This will allow admins to filter out the  doctor 's available for the call report
            according to their category. If the number of visits to the doctor exceeds the
            number of visists according to doctor's category, the doctor will not be available
            for doctor call.   <br />
          </h4>
        </div>
        <div>
          <div className="form__control " style={{ width: "fit-content" }}>
            <label className="toggle-label">
              <input
                type="checkbox"
                checked={missedDocFilter === "true"}
                onClick={logState7}
              />
              <span />
            </label>
          </div>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-green-border d-flex justify-content-between">
        <div>
        {/* <h1 className="web-app__heading">
            Lock Users based on minimum call reports
          </h1>
          <h4 className="mt-5 w-75 lh-base" style={{ color: "#999" }}>
            This will allow admins to set the minimum number of call reports to be submitted daily, if
            Users are found inactive (not adding minimum call reports) for a day
            , then the Users account will be locked and they will not be
            able to login. <br /> */}
          <h1 className="web-app__heading">
            Restrict minimum call reports
          </h1>
          <h4 className="mt-5 w-75 lh-base" style={{ color: "#999" }}>
            This will allow admins to set the minimum number of call reports to be submitted daily, if
            users has not added the desired number of call reports than users will not be able to submit final call reports. <br />
            {callReportsLock === "true" ? (
              <><div className="mt-3 text-primary text-info">
                By default the minimum number of call reports for all the designations is 0
                <Link
                  className="ps-3 text-danger"
                  to='./min-call-report'
                >Edit Here</Link>
              </div>
                <section className="user-detail-container mt-5 d-flex justify-content-between" style={{backgroundColor: "#25253d"}}>
                  <div>
                    <h1 className="web-app__heading">
                      Lock User
                    </h1>
                    <h4 className="mt-5 w-75 lh-base" style={{ color: "#999" }}>
                      If Users are found inactive or have not submitted the call report for a day
                      , then the Users account will be locked and they will not be able to log in the next day.       </h4>
                  </div>
                  <div>
                    <div className="form__control " style={{ width: "fit-content" }}>
                      <label className="toggle-label">
                        <input
                          type="checkbox"
                          checked={restrictUserLock === "true" ? true : false}
                          onClick={logState15}
                        />
                        <span />
                      </label>
                    </div>
                  </div>
                </section>
              </>
            ) : null
            }
          </h4>
        </div>
        <div>
          <div className="form__control " style={{ width: "fit-content" }}>
            <label className="toggle-label">
              <input
                type="checkbox"
                checked={callReportsLock === "true"}
                onClick={logState6}
              />
              <span />
            </label>
          </div>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-green-border d-flex justify-content-between">
        <div>
          <h1 className="web-app__heading">
            Limit the number of visits for a user
          </h1>
          <h4 className="mt-5 w-75 lh-base" style={{ color: "#999" }}>
            This will allow admins to set the maximum number of visits for any ex-station or out-station
            for an employee. <br />
            {tourlimit === "true" ? (
              <div className="mt-3 text-primary text-info">
                By default the maximum number of visits is unlimited
                <Link
                  className="ps-3 text-danger"
                  to='./max-tour-limit'
                >Edit Here</Link>
              </div>
            ) : null
            }
          </h4>
        </div>
        <div>
          <div className="form__control " style={{ width: "fit-content" }}>
            <label className="toggle-label">
              <input
                type="checkbox"
                checked={tourlimit === "true"}
                onClick={logState11}
              />
              <span />
            </label>
          </div>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-green-border d-flex justify-content-between">
        <div className="w-100">
          <h1 className="web-app__heading">Announcement</h1>
          <h4 className="mt-5 w-75 lh-base" style={{ color: "#999" }}>
            Announcement feature can be used to deliver a message to your entire working force. You can also customize the colour of the text and add an expiry date to the announcement. This feature can be used to mass spread important information like "Last to add your Tour Program is 25th" or to distribute greetings like "Happy Diwali".
          </h4>
          {announcement ? (
            announcement !== "false" ? (
              <form onSubmit={announcementSubitHandler}>
                <h4 className="mt-5 w-75 lh-base" style={{ color: "#999" }}>
                  Enter text here:
                  <br />
                  <div className="mt-3">
                    <div className="d-flex justify-content-between">
                      <textarea
                        className="text-white w-100 border border-success bg-transparent"
                        rows="2"
                        maxlength="150"
                        value={announcementText}
                        onChange={(e) => setAnnouncementText(e.target.value)}
                      />
                    </div>
                    <p
                      className={`py-2 ${announcementText?.length < 120
                        ? "text-success"
                        : "text-danger"
                        }`}
                    >
                      {announcementText?.length}/150
                    </p>
                  </div>
                </h4>
                <div className="mt-3 web-app__heading d-flex justify-content-between w-75">
                  <div className="">
                    <p>Announcement Type:</p>
                    <select
                      className="border border-success"
                      value={announcementType}
                      onChange={(e) => setAnnouncementType(e.target.value)}
                    >
                      <option value="Announcement">Announcement</option>
                      <option value="Info">Information</option>
                      <option value="Warning">Warning</option>
                      <option value="Success">Success</option>
                    </select>
                  </div>
                  <div className="mx-4">
                    <p>Background:</p>
                    <div className="announcement-bar">
                      <div
                        onClick={() => handleColorClick("red")}
                        className="button red"
                      ></div>
                      <div
                        onClick={() => handleColorClick("blue")}
                        className="button announcement"
                      ></div>
                      <div
                        onClick={() => handleColorClick("yellow")}
                        className="button warning"
                      ></div>
                      <div
                        onClick={() => handleColorClick("green")}
                        className="button success"
                      ></div>
                    </div>
                    <p className="py-2">Selected: {announcementBgColor}</p>
                  </div>
                  <div>
                    <p>Expire Date:</p>
                    <input
                      className="p-1"
                      type="date"
                      onChange={(e) =>
                        handleDateChange(new Date(e.target.value))
                      }
                    />
                  </div>
                </div>
                <button type="submit" className="button-blue-gradient">
                  {" "}
                  Submit{" "}
                </button>
              </form>
            ) : null
          ) : null}
        </div>
        <div>
          <div className="form__control " style={{ width: "fit-content" }}>
            <label className="toggle-label">
              <input
                type="checkbox"
                checked={announcement === "true" ? true : false}
                onClick={logState4}
              />
              <span />
            </label>
          </div>
        </div>
      </section>

      {/* <section className="user-detail-container user-detail-container-green-border d-flex justify-content-between">
        <div>
          <h1 className="web-app__heading">
            Send app update request to all users in the organization.
          </h1>
          <h4 className="mt-5 w-75 lh-base" style={{ color: "#999" }}>
            This will allow medorn admin to send app update request to all users
            in the organization.
          </h4>
        </div>
        <div>
          <div className="form__control " style={{ width: "fit-content" }}>
            <label className="toggle-label">
              <input
                type="checkbox"
                defaultChecked={updateRequest === "true" ? true : false}
                onClick={logState5}
              />
              <span />
            </label>
          </div>
        </div>
      </section> */}
      <section className="user-detail-container user-detail-container-green-border d-flex justify-content-between">
        <div>
          <h1 className="web-app__heading">
            Set Organization name.
          </h1>
          <h4 className="mt-5 mb-3 w-75 lh-base" style={{ color: "#999" }}>
            This will allow medorn admin to change organization name on header.
          </h4>
          {isCompanyName === "true" && <div>
            <textarea
              className="text-white w-100 border border-success bg-transparent"
              rows="1"
              maxlength="30"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <button onClick={companyNameSubitHandler} className="button-blue-gradient">Submit</button>
          </div>}
        </div>
        <div className="util-tp-filter" style={{ marginTop: "5rem" }}>
          <p className="mb-2">
            Upload File
          </p>
          <input
            ref={inputRef}
            type="file"
            placeholder="you can upload image, excel or pdf"
            onChange={changeHandler}
          />
        </div>
        <div>
          <div className="form__control " style={{ width: "fit-content" }}>
            <label className="toggle-label">
              <input
                type="checkbox"
                checked={isCompanyName === "true" ? true : false}
                onClick={logState8}
              />
              <span />
            </label>
          </div>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-green-border d-flex justify-content-between">
        <div>
          <h1 className="web-app__heading">
            Deadline to add Tour Programs for approval.
          </h1>
          <h4 className="mt-5 w-75 lh-base" style={{ color: "#999" }}>
            For the companies that want their users to send the "Tour Program" of the upcoming month before the desired date of the current month, can you use this functionality to lock the "Create Tour Program" feature on the mobile app on the desired date? For example, you can set it to the 28th and all the users will have their create tour program feature locked on the said date until unlocked by the admin.
          </h4>
          {tpDeadline !== "false" && <div>
            <div className="util-tp-filter">
              <p className="mb-2">
                Day <span className="asterisk-imp">*</span>
              </p>
              <input
                type="number"
                id="day"
                name="day"
                min="1"
                max="30"
                placeholder="Enter a day between 1st and 30th"
                style={{ border: "2px solid", borderColor: "white", width: "310px" }}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);
                  if (value >= 1 && value <= 30) {
                    setDay(value); // Set day only if within range
                  } else if (!e.target.value) {
                    setDay(""); // Allow clearing the field
                  }
                }}
                value={day}
              />
              {/* <input
                type="date"
                className=""
                id="date-input"
                placeholder={date}
                style={{ border: "2px solid", borderColor: "white", width: "50%" }}
                // value={date}
                onChange={(e) => { setDate(new Date(e.target.value)) }}
              /> */}
            </div>
            <button onClick={handleSubmit} className="button-blue-gradient" style={{ marginTop: "2rem" }}>Submit</button>
          </div>}
        </div>
        <div>
          <div className="form__control " style={{ width: "fit-content" }}>
            <label className="toggle-label">
              <input
                type="checkbox"
                checked={tpDeadline !== "false" ? true : false}
                onClick={logState9}
              />
              <span />
            </label>
          </div>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-green-border d-flex justify-content-between">
        <div>
          <h1 className="web-app__heading">
            Allow Employees to add Secondary Sales in the middle of month
          </h1>
          <h4 className="mt-5 w-75 lh-base" style={{ color: "#999" }}>
            This will allow admins and other employess to add secondary sales in the middle of month   <br />
          </h4>
        </div>
        <div>
          <div className="form__control " style={{ width: "fit-content" }}>
            <label className="toggle-label">
              <input
                type="checkbox"
                checked={secondary === "true" ? true : false}
                onClick={logState10}
              />
              <span />
            </label>
          </div>
        </div>
      </section>

      <section className="user-detail-container user-detail-container-green-border d-flex justify-content-between">
        <div>
          <h1 className="web-app__heading">
            ALLOW SUNDAY WORKING IN TOUR PROGRAM
          </h1>
          <h4 className="mt-5 w-75 lh-base" style={{ color: "#999" }}>
            This feature will allow the users to add the tour program on Sunday as well. When the toggle is off "Sundays" are locked by default. Switching "ON" the toggle will unlock the Sundays for all users in Tour Program.    <br />
          </h4>
        </div>
        <div>
          <div className="form__control " style={{ width: "fit-content" }}>
            <label className="toggle-label">
              <input
                type="checkbox"
                checked={sundayWorking === "true" ? true : false}
                onClick={logState12}
              />
              <span />
            </label>
          </div>
        </div>
      </section>

      <section className="user-detail-container user-detail-container-green-border d-flex justify-content-between">
        <div>
          <h1 className="web-app__heading">
            Automatic Expense with Pending Status 
          </h1>
          <h4 className="mt-5 w-75 lh-base" style={{ color: "#999" }}>
           This feature only works when a user have "CALL REPORT MANDATORY FOR EXPENSE" turn "ON". With this feature turned "ON" the Expenses will be sent with "Pending Status" instead of Approved Status.   <br />
          </h4>
        </div>
        <div>
          <div className="form__control " style={{ width: "fit-content" }}>
            <label className="toggle-label">
              <input
                type="checkbox"
                checked={expenseApproval === "true" ? true : false}
                onClick={logState13}
              />
              <span />
            </label>
          </div>
        </div>
      </section>

      <section className="user-detail-container user-detail-container-green-border d-flex justify-content-between">
        <div>
          <h1 className="web-app__heading">
          Restrict the user from seeing the HQs and cities based on their designation.
          </h1>
          <h4 className="mt-5 w-75 lh-base" style={{ color: "#999" }}>
          This feature allows the company to restrict their users to see HQs and Cities based on their designation. This means MRs will only be able to see the HQ they are allotted to, managers on the other hand will be able to see those HQs where he is allotted to and where their subordinates (MRs) are allotted to.          </h4>
        </div>
        <div>
          <div className="form__control " style={{ width: "fit-content" }}>
            <label className="toggle-label">
              <input
                type="checkbox"
                checked={viewAllHqs === "true" ? true : false}
                onClick={logState14}
              />
              <span />
            </label>
          </div>
        </div>
      </section>

      {/* <section className="user-detail-container user-detail-container-green-border d-flex justify-content-between">
        <div>
          <h1 className="web-app__heading">
          Restrict the user lock for minimum call reports.
          </h1>
          <h4 className="mt-5 w-75 lh-base" style={{ color: "#999" }}>
          This setting prevents users from being locked out of their accounts based on failure to meet the minimum daily call report submissions.         </h4>
        </div>
        <div>
          <div className="form__control " style={{ width: "fit-content" }}>
            <label className="toggle-label">
              <input
                type="checkbox"
                checked={restrictUserLock === "true" ? true : false}
                onClick={logState15}
              />
              <span />
            </label>
          </div>
        </div>
      </section> */}
    </main>
  );
};

export default AdminPreferences;
