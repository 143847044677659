import { useEffect, useState, useMemo } from "react";

import serialise, {
  generateSelectData,
  generateSelectUser,
} from "../../../../../utils/serialiseResponse";
import Table from "../../../../../components/Table";
import { MdArrowBackIosNew, MdOutlineGroup, MdOutlineRefresh } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  viewRouteList,
  viewUsersForUtilities,
} from "../../../../../services/utilities";
import Select from "react-select";
import { selectUserStyles } from "../../../../public/stylesheets/selectStyles";

import { useDispatch, useSelector } from "react-redux";
import { clearHeadquarters, setHeadquarters } from "../../../../../reducers/locations/hqReducer";

const RouteList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [routes, setRoutes] = useState([]);
  // const [employees, setEmployees] = useState([]);
  const [hq, setHq] = useState([]);
  const headquarters = useSelector(({ headquarter }) => headquarter);

  useEffect(() => {
    dispatch(setHeadquarters());
    return ()=> {dispatch(clearHeadquarters());}
  }, [dispatch]);

  const loggedIn = useSelector(({ user }) => user);

  // const employeeSelect = useMemo(
  //   () => generateSelectUser(employees, "fullName"),
  //   [employees]
  // );

  const hqSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );

  

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "From",
        accessor: "fromCity",
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "To",
        accessor: "toCity",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
        wordWrap:'break-word'
      },
      {
        Header: "Headquarter",
        accessor: "headquarter",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
        wordWrap:'break-word'
      },
      {
        Header: "Area Type",
        accessor: "areatype",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Distance",
        accessor: "distance",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
    ],
    []
  );
  // useEffect(() => {
  //   try {
  //     viewUsersForUtilities().then((res) => {
  //       setEmployees(res?.data);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);

  useEffect(() => {
    async function asyncFetch() {
      let filter = {
        hq: hq?.value,
      };
      viewRouteList(filter).then((response) => {
        setRoutes(response?.data);
      });
    }

    asyncFetch()
  }, [hq]);

  const tableData = useMemo(() => serialise(routes), [routes]);


  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <section className="admin-creation-content__heading">
          <MdArrowBackIosNew
            className="admin-creation-content__back-button"
            onClick={() => navigate("/utilities")}
          />
          <h2 className="web-app__heading">Route List</h2>
        </section>
        {loggedIn?.user?.des < 121||
        loggedIn?.user?.isAdmin ? (
          <div className="util-list-filters">
            <div className="util-tp-filter">
              <p className="util-select-user__label">Select Headquarter</p>
              <div className="util-select-user">
                <MdOutlineGroup className="util-select-user__icon" />
                <Select
                  styles={selectUserStyles}
                  options={hqSelect}
                  placeholder="Select Headquarter"
                  className="mt-3"
                  // value={headquarters?.value}
                  value={hq}
                  onChange={(e) => setHq({ ...e })}
                />
                <MdOutlineRefresh size={25} className="icon-color-tertiary mt-4 ms-2" style={{cursor:"pointer"}} onClick={() => setHq(null)}/>
              </div>
            </div>
          </div>
        ) : null}
        <section
          className="area-creation-content__info"
          style={{ marginTop: "-4rem" }}
        >
          <div className="filter-table">
            {tableData.length > 0 && (
              <h2 className="web-app__heading mb-4 ms-2">
                Showing ({tableData.length}) Entries
              </h2>
            )}
            {tableData.length > 0 ? (
              <Table columns={columns} data={tableData} />
            ) : (
              <h3 className="no-results-message">No results found</h3>
            )}
          </div>
        </section>
      </div>
    </main>
  );
};

export default RouteList;
