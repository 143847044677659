import { viewHeadquarterSS , viewSTKWiseSales } from "../../services/utilities";

const initialState = {
  HQSalesData: [],
  STKSalesData :[],
  message:""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_HEADQUARTER_SALES":
      return {...state , HQSalesData: action.payload} ;
    case "SET_STOCKIST_SALES":
      return {...state , STKSalesData: action.payload};
    case "SET_MESSAGE":
        return {...state , message:action.payload};
    case "ClEAR_SSREPORTS":
        return initialState;
    default:
      return state;
  }
};

export const setHeadquarterSales = (payload) => {
  return async (dispatch) => {
    try {
      const data  = await viewHeadquarterSS(payload);
      dispatch({
        type: "SET_HEADQUARTER_SALES",
        payload: data.data,
      });
      dispatch({
        type:"SET_MESSAGE",
        payload:data.message
      })
    } catch (err) {
      dispatch({
        type: "SET_MESSAGE",
        payload: err.response.data.message,
      });
    }
  };
};
export const setStockistSales = (payload) =>{
    return async(dispatch)=>{
        try {
            const data  = await viewSTKWiseSales(payload);
            dispatch({
              type: "SET_STOCKIST_SALES",
              payload: data.data,
            });
            dispatch({
              type:"SET_MESSAGE",
              payload:data.message
            })
        } catch (err) {
        dispatch({
            type: "SET_MESSAGE",
            payload: err.response.data.message,
        });
        }
    }
}

export default reducer;
