import { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../../components/PageTitle";
import customToast from "../../../../components/CustomToast";
import { approvePrimarySales, rejectPrimarySales } from "../../../../services/sales";
import { formatDate } from "../../../../utils/helper";
import { BsCheck2Circle, BsXCircle } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import serialise from "../../../../utils/serialiseResponse";
import { RiCloseCircleLine } from "react-icons/ri";
import Table from "../../../../components/Table";
import { MdOutlinePendingActions } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { updateTrigger } from "../../../../reducers/approveLengthReducer";
import { viewPSForApproval } from "../../../../services/sales";

const ApprovalPS = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [tableData , setTableData] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [approvalData, setApprovalData] = useState();
  
  const fetch = useCallback(
    async() => {
        try{
            const {data} = await viewPSForApproval();
            setTableData(serialise(data));
        }catch(err){
            const {data} = err?.response ;
        }
    },
    []
  );
  const handleApprove = useCallback(
    async()=>{
        try{
            const {data} = await approvePrimarySales({id:approvalData});
            customToast.success("Primary sales approved successfully");
        }catch(err){
            const {data} = err.response ;
            customToast.error(data.message);
        }
        fetch();
        setShowEdit(false);   
    },
    [approvalData,fetch]
  )
  const handleReject = useCallback(
    async()=>{
        try{
            const {data} = await rejectPrimarySales({id:approvalData});
            customToast.success("Primary sales rejected successfully");
        }catch(err){
            const {data} = err.response ;
            customToast.error(data.message);
        }
        fetch();
        setShowEdit(false);
    },
    [approvalData,fetch]
  )
  useEffect(() => {
    fetch();
  }, [dispatch,fetch]);

  const EditPopup = () => {
    return (
      <section className="edit-popup" onClick={(e) =>
        showEdit
          ? e.target.className === "edit-popup"
            ? setShowEdit(false)
            : null
          : null
      }>
      {/* <section className="edit-popup"> */}
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Approve / Reject</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  Do you want to approve this Sale?
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button
                type="submit"
                className="button-submit-green mt-4"
                onClick={(e) =>{
                  e.preventDefault();
                   handleApprove()
                }}
              >
                <span className="button-submit-green__icon-container">
                  <BsCheck2Circle className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Approve</span>
              </button>

              <button
                type="submit"
                className="button-delete mt-4"
                onClick={(e) => {
                  e.preventDefault();
                  handleReject();
                }}
              >
                <span className="button-delete__icon-container">
                  <BsXCircle className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Reject</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const setEditForm = (data) => {
    setApprovalData(data);
  };
  const columns = useMemo(
    () => [
        // {
        // Header: "Sr. No",
        // accessor: "sr",
        // disableFilters: true,
        // minWidth: 50,
        // maxWidth: 50,
        // },
        {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        showTotal: true,
        placeholderTotal: "Total",
        Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
        },
        {
        Header: "Created By",
        accessor: "submitter",

        Cell: (props) => {
          return <span>{props?.row?.original?.submitter.firstName + " " + props?.row?.original?.submitter.lastName}</span>
        }
        },
        {
        Header: "Stockist",
        accessor: "stockist",
        disableSortBy: true,
        },
        {
        Header: "Headquarter",
        accessor: "headquarter",
        disableSortBy: true,
        },
        {
        Header: "Units",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            props?.row?.original?.products.length
          );
        },
        },
        {
        Header: "Quantity",
        accessor: "quantity",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "quantity",
        },
        {
        Header: "Free Stock",
        accessor: "freeStocks",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "freeStocks",
        },
        {
        Header: "Total Quantity",
        accessor: "totalQuantity",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "totalQuantity",
        },
        {
        Header: "Final Price",
        accessor: "salesQuantity",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "salesQuantity",
        },
      {
        Header: "View",
        accessor: "details",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 60,
        maxWidth: 60,
        Cell: (props) => {
          return (
            <Link
              to={"/admin-panel/approvals/approve-primary-sale/details"}
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: (props) => {
          return (
            <span
              className="react-table-view-link"
              onClick={() => {
                setEditForm(props.row.original._id);
                setShowEdit(true);
              }}
            >
              <MdOutlinePendingActions className="icon-color-yellow"/>
            </span>
          );
        },
        minWidth: 80,
        maxWidth: 80,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );


  useEffect(() => {
    dispatch( updateTrigger({index:location?.state?.index,data:tableData}))

  }, [tableData]);
  
  
   

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Approve Primary Sales" />

        <section className="area-creation-content__info">
        {tableData.length > 0 && (
            <h2 className="web-app__heading mb-4 ms-2">
              Showing ({tableData.length}) Entries
            </h2>
          )}
          <div className="filter-table">
          
            <Table columns={columns} data={tableData} />
          </div>
          {showEdit ? <EditPopup data={approvalData} /> : null}
        </section>
      </div>
    </div>
  );
};

export default ApprovalPS;
