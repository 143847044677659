import { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";
import { MdOutlineSettingsBackupRestore } from "react-icons/md";
import serialise, {
  generateSelectData,
} from "../../../../utils/serialiseResponse";
import Select from "react-select";
import { dcrStyles } from "../../../public/stylesheets/selectStyles";
import {
  getDeletedExpense,
  restoreDeltedExpense,
} from "../../../../services/expenses";
import {
  restoreDeltedProduct,
  viewDeletedInventory,
  viewDeletedProducts,
} from "../../../../services/products";
import {
  restoreDeltedTargets,
  viewDeltedTargets,
} from "../../../../services/targets";
import {
  restoreDeltedLocations,
  viewDeltedLocations,
} from "../../../../services/locations";
import { viewUsersForUtilities } from "../../../../services/utilities";
import moment from "moment-timezone";
import { viewDeletedPrimarySales, viewDeletedSecondarySales } from "../../../../services/sales";
import { formatDate } from "../../../../utils/helper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";

const RestoreDeleted = () => {
  const [type, setType] = useState();
  const [data, setData] = useState();
  const loggedIn =
    useSelector(({ user }) => user) || JSON.parse(localStorage.getItem("user"));
  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const employeeSelect = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );

  const typeOptions = [
    { label: "Doctors", value: "doctor" },
    { label: "Chemist", value: "chemist" },
    { label: "Stockist", value: "stockist" },
    { label: "Products", value: "product" },
    { label: "Expense", value: "expense" },
    { label: "States", value: "state" },
    { label: "Headquaters", value: "hq" },
    { label: "Cities/LoacalAreas", value: "city" },
    { label: "Routes", value: "route" },
    { label: "Inventory", value: "inventory" },
    { label: "Secondary Sales", value: "ss" },
    { label: "Primary Sales", value: "ps" },
  ];

  const handleSubmit = async (type) => {
    // e.preventDefault();
    if (!type) {
      customToast.error("Please select type");
      return;
    }
    switch (type?.value) {
      case "doctor":
        const doctorData = await viewDeltedTargets("doctor");
        if (doctorData) {
          setData(doctorData.data);
        }
        break;
      case "chemist":
        const chemistData = await viewDeltedTargets("chemist");
        if (chemistData) {
          setData(chemistData.data);
        }
        break;
      case "stockist":
        const stockistData = await viewDeltedTargets("stockist");
        if (stockistData) {
          setData(stockistData.data);
        }
        break;
      case "product":
        const productData = await viewDeletedProducts();
        if (productData) {
          setData(productData.data);
        }
        break;
      case "expense":
        const expenseData = await getDeletedExpense();
        if (data) {
          setData(expenseData.data);
        }
        break;
      case "city":
        const cityData = await viewDeltedLocations("city");
        if (cityData) {
          setData(cityData.data);
        }
        break;
      case "route":
        const routeData = await viewDeltedLocations("route");
        if (routeData) {
          setData(routeData.data);
        }
        break;
      case "ss":
        const ssData = await viewDeletedSecondarySales();
        if (ssData) {
          setData(ssData.data);
        }
        break;
      case "ps":
        const psData = await viewDeletedPrimarySales();
        if (psData) {
          setData(psData.data);
        }
        break;
      case "inventory":
        const inventoryData = await viewDeletedInventory();
        if (inventoryData) {
          setData(inventoryData.data);
        }
        break;
      case "state":
        const stateData = await viewDeltedLocations("state");
        if (stateData) {
          setData(stateData.data);
        }
        break;
      case "hq":
        const hqData = await viewDeltedLocations("headquarter");
        if (hqData) {
          setData(hqData.data);
        }
        break;
    }
  };

  const handleRestore = async (e, id) => {
    e.preventDefault();

    switch (type?.value) {
      case "doctor":
        const doctorData = await restoreDeltedTargets("doctor", id);
        if (doctorData?.data?.modifiedCount === 1) {
          customToast.success("Doctor restored successfully");
          handleSubmit(type)
        } else {
          customToast.error("Something went wrong");
        }
        break;
      case "chemist":
        const chemistData = await restoreDeltedTargets("chemist", id);
        if (chemistData?.data?.modifiedCount === 1) {
          customToast.success("Chemist restored successfully");
          handleSubmit(type)
        } else {
          customToast.error("Something went wrong");
        }
        break;
      case "stockist":
        const stockistData = await restoreDeltedTargets("stockist", id);
        if (stockistData?.data?.modifiedCount === 1) {
          customToast.success("Stockist restored successfully");
          handleSubmit(type)
        } else {
          customToast.error("Something went wrong");
        }
        break;
      case "product":
        const productData = await restoreDeltedProduct(id);
        if (productData?.data?.modifiedCount === 1) {
          customToast.success("Product restored successfully");
          handleSubmit(type)
        } else {
          customToast.error("Something went wrong");
        }
        break;
      case "expense":
        const expenseData = await restoreDeltedExpense(id);
        if (expenseData?.data?.modifiedCount === 1) {
          customToast.success("Expense restored successfully");
          handleSubmit(type)
        } else {
          customToast.error("Something went wrong");
        }
        break;
      case "city":
        const cityData = await restoreDeltedLocations("city", id);
        if (cityData?.data?.modifiedCount === 1) {
          customToast.success("City restored successfully");
          handleSubmit(type)
        } else {
          customToast.error("Something went wrong");
        }
        break;
      case "route":
        const routeData = await restoreDeltedLocations("route", id);
        if (routeData?.data?.modifiedCount === 1) {
          customToast.success("Route restored successfully");
          handleSubmit(type)
        } else {
          customToast.error("Something went wrong");
        }
        break;
        case "state":
        const stateData = await restoreDeltedLocations("state", id);
        if (stateData?.data?.modifiedCount === 1) {
          customToast.success("State restored successfully");
          handleSubmit(type)
        } else {
          customToast.error("Something went wrong");
        }
        break;
        case "hq":
        const hqData = await restoreDeltedLocations("headquarter", id);
        if (hqData?.data?.modifiedCount === 1) {
          customToast.success("Headquarter restored successfully");
          handleSubmit(type)
        } else {
          customToast.error("Something went wrong");
        }
        break;
    }
  };

  const columns = useMemo(() => {
    const columns = [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Name",
        accessor: "",
        Cell: (props) => {
          return (
            <p>
              {type?.value === "expense"
                ? props?.row?.original?.submitter?.fullName
                : type?.value === "route"
                ? props?.row?.original?.fromCity[0]?.name +
                  " - " +
                  props?.row?.original?.toCity[0]?.name
                : props?.row?.original?.name}{" "}
            </p>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Restore",
        accessor: "",
        Cell: (props) => {
          return (
            <MdOutlineSettingsBackupRestore
              style={{ fontSize: "3rem", color: "red", cursor: "pointer" }}
              //   onClick={(e) => handleRestore(e, props?.row?.original._id, type)}
              onClick={async(e) => {
                 await handleRestore(e, props?.row?.original._id, type);
              }}
            />
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
    ];
    if (type?.value === "expense") {
      columns.splice(1, 0, {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        minWidth: 100,
        maxWidth: 100,
      });
    }

    if (type?.value !== "expense") {
      columns.splice(1, 0, {
        Header: "Deleted At",
        accessor: "",
        Cell: (props) => {
          return (
            <p>
              {moment(props?.row?.original?.deletedAt).format("D MMM YYYY")}
            </p>
          );
        },
        disableFilters: true,
        minWidth: 100,
        maxWidth: 100,
      });
    }

    return columns;
  }, [type]);

  const intColumns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        showTotal: true,
        placeholderTotal: "Total",
        Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
      },
      {
        Header: "Supplier",
        accessor: "supplier",
        disableSortBy: true,
      },
      {
        Header: "Total",
        accessor: "total",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "total",
      },
      {
        Header: "View",
        accessor: "vi",
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <Link
              to={`inventory/${props?.row?.original?._id}`}
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    [type]
  ); 

  const SScolums = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 40,
      //   maxWidth: 40,
      //   disableFilters: true,
      // },
      {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        showTotal: true,
        placeholderTotal: "Total",
        Cell: (props) => <span>{formatDate(props?.row?.original?.date ? props?.row?.original?.date : props?.row?.original?.createdAt)}</span>,
      },
      {
        Header: "Stockist",
        accessor: "stockist",
        disableSortBy: true,
      },
      {
        Header: "Headquarter",
        accessor: "headquarter",
        disableSortBy: true,
      },
      {
        Header: "Total Quantity",
        accessor: "products[0].totalQuantity",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "products[0].totalQuantity",
      },
      {
        Header: "Sales Quantity",
        accessor: "products[0].salesQuantity",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "products[0].salesQuantity",
      },
      {
        Header: "View",
        accessor: "vi",
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) =>{
          return (
            <Link
              to={`secondary-sales/${props?.row?.original?._id}`}
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      }
    ],
    [type]
  );

  const PScolumns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 40,
      //   disableFilters: true,
      //   maxWidth: 40,
      // },
      {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        showTotal: true,
        placeholderTotal: "Total",
        Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
      },
      {
        Header: "Stockist",
        accessor: "stockist",
        disableSortBy: true,
      },
      {
        Header: "Headquarter",
        accessor: "headquarter",
        disableSortBy: true,
      },
      {
        Header: "Total",
        accessor: "products[0].finalPrice",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "total",
      },
      {
        Header: "View",
        accessor: "vi",
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) =>{
          return (
            <Link
              to={`primary-sales/${props?.row?.original?._id}`}
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      }
    ],
    [type]
  );
  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, [selectedUser]);

  const tableData = useMemo(() => serialise(data), [data]);

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content ">
        <PageTitle title="Restore Deleted" />
        <section className="">
          <form onSubmit={handleSubmit}>
            <div className="util-list-filters d-flex justify-content-between">
              <div className="d-flex flex-column">
                <div className="admin-panel__alert my-3">
                  <h2>PLEASE NOTE</h2>
                  <p>
                  MR gps tracking data will be available for 3 months. After that, it will be permanently deleted. 
                  Any deleted data will be completely removed from our system after 6 months.
                  Please make sure to save any important information within these times.
                  </p>
                </div>
                <div className="d-flex">
                  <div className="util-tp-filter me-5">
                    <p className="mb-3">
                      Select Type <span className="asterisk-imp">*</span>
                    </p>
                    <Select
                      styles={dcrStyles}
                      placeholder="Select Type"
                      options={typeOptions}
                      value={type}
                      onChange={(selectedOption) => {
                        setType(selectedOption)
                        setData([]);
                        handleSubmit(selectedOption)
                      }}
                    />
                  </div>
                  {/* {(type?.value === "expense" ||
                    type?.value === "doctor" ||
                    type?.value === "chemist" ||
                    type?.value === "stockist") && (
                    <div>
                      <p className="util-select-user__label">Select User</p>
                      <div className="util-select-user">
                        <MdOutlineGroup className="util-select-user__icon" />
                        <Select
                          styles={selectUserStyles}
                          options={employeeSelect}
                          placeholder="Select User"
                          value={selectedUser}
                          onChange={(e) => setSelectedUser({ ...e })}
                        />
                      </div>
                    </div>    
                  )} */}
                </div>
              </div>
              {/* <div className="d-flex">
                <div className="util-tp-filter">
                  <button type="submit" className="button-blue-gradient mt-5">
                    Show Deleted
                  </button>
                </div>
              </div> */}
            </div>
          </form>
        </section>
      </div>
      <section className=" table-container" style={{ marginRight: "2rem" }}>
        <div className="filter-table">
          {data?.length > 0 && (
            <h2 className="web-app__heading mb-4 ps-3">
              Showing ({data?.length}) Entries
            </h2>)
          }
          {(data?.length > 0 && ["inventory", "ss", "ps"].includes(type?.value)) && (
            <div className="d-flex bg-danger mb-4 p-1 rounded ps-3 gap-3">
              <p style={{fontWeight:700}}>Note:</p>
              <p className="">
                These bills cannot be restored as deleting the bill increases or decreases the quantity of the stock, and restoring the bill will lead to incorrect stock quantity in the inventory.
              </p>
            </div>
          )}
          {tableData?.length > 0 ? (
            <Table columns={type.value === "ps" ? PScolumns : type.value === "ss" ? SScolums : type.value === "inventory" ? intColumns : columns} data={tableData} />
          ) : (
            <h3 className="mt-2 no-results-message">No data results found</h3>
          )}
        </div>
      </section>
    </main>
  );
};

export default RestoreDeleted;
