export const title = "Manage Report";

export const links = [

  {
    name: "Doctor Sales",
    route: "doctor-sales",
    accessor: "doctor-sales",
  },
  {
    name: "Payment Report",
    route: "payment-report",
    accessor: "payment-report",
  },
];
