import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { MdNotificationsActive, MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {useState, useEffect } from "react";
import { addNotification } from "../../reducers/notifications";

const NotifyToast = ({socket}) => {
  const dispatch = useDispatch();

  const [activeRem, setActiveRem] = useState([]);
  
  const loggedIn = useSelector(({ user }) => user);
  

  useEffect(()=>{
   let timeoutId = null ;
    socket.on("new-tp-approval", (data) => {
      if (loggedIn?.jwt?.id === data?.target) {
        setActiveRem([data.alert])
        dispatch(addNotification(data.alert));
        clearTimeout(timeoutId) ;
        timeoutId = setTimeout(()=>{
          setActiveRem([]);
        },10000)
      }
    });
    return ()=>{
      clearTimeout(timeoutId) ;
    }
  },[loggedIn,socket,dispatch]);

  let toastCloseHandler = (id) => {
    setActiveRem(activeRem.filter((e) => e._id !== id));
  };
  
  return (
    <>
      {loggedIn.user ? (
        <ToastContainer
          position="top-end"
          className="position-fixed p-4"
          style={{ zIndex: "99" }}
        >
          {activeRem.map((e,idx) => (
            <Toast key={idx} autohide>
              <Toast.Header className="notification_header" closeButton={false}>
                <MdNotificationsActive className="fs-2 me-4 icon-color-yellow" />
                <strong className="me-auto fs-3 ">{e?.title}</strong>
                <MdClose onClick={() => toastCloseHandler(e?._id)}
                className="fs-2 me-2 icon-color-error cursor-pointer"/>
              </Toast.Header>
              
              <Toast.Body className="fs-4 notification_message">{e?.message}</Toast.Body>
            </Toast>
          ))}
        </ToastContainer>
      ) : null}
    </>

  );
};

export default NotifyToast;
