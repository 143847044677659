export const title = "Targets";

export const links = [
  {
    name: "Target",
    route: "user-target",
    accessor: "user-target",
  },
  {
    name: "Allotted Sample",
    route: "allotted-samples",
    accessor: "allotted-samples",
  },
  {
    name: "Allotted Gifts",
    route: "allotted-gifts",
    accessor: "allotted-gifts",
  },
  {
    name: "Achieved Target",
    route: "achieved-target",
    accessor: "achieved-target",
  },
];
