import { Outlet } from "react-router-dom";
import ListsNav from "./UtilListsSideNav";
import { title, links } from "./navData.js";

const UtilLists = () => {
  return (
    <div className="content">
      <ListsNav title={title} links={links} />

      <Outlet />
    </div>
  );
};

export default UtilLists;
