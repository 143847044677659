import { deleteAllotment, newAllotment, viewGiftsForapproval, approveGifts, rejectGifts, newGiftAllotment } from "../../services/reports.js";
import { updateTrigger } from "../approveLengthReducer.js";

const initialState = {
  data : []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALLOT_GIFTS":
      return action.payload;

    case "DELETE_GIFTS":
      return action.payload;

    case "VIEW_GIFTS_FOR_APPROVAL":
      return action.payload;

    case "CLEAR_GIFTS":
      return initialState;

    default:
      return state;
  }
};

export const allotUserGifts = (targetData) => {
  return async (dispatch) => {
    let data;

    try {
      // data = await newAllotment('gift',targetData);
      data = await newGiftAllotment(targetData);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "ALLOT_GIFTS",
      payload: data,
    });
  };
};

export const deleteUserGifts = (targetData) => {
  return async (dispatch) => {
    let data;
    try {
      data = await deleteAllotment("gift", targetData);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "DELETE_GIFTS",
      payload: data,
    });
    // dispatch(setHoliday());
  };
};

export const viewGiftApproval = (details) =>{
  return async (dispatch) =>{
    let data;
    try {
      data = await viewGiftsForapproval(details);
    } catch (e) {
      if(e) throw e;
    }
    
    dispatch({
      type: "VIEW_GIFTS_FOR_APPROVAL",
      payload : data,
    });
    dispatch(updateTrigger({index: 12, data: data?.data}));
  };
};

export const approveGift = (id,details) =>{
  return async (dispatch) =>{
    let data;
    try {
      data = await approveGifts(id,details); 
    } catch (e) {
      if(e) throw e;
    }
    dispatch(viewGiftApproval());
  };
};

export const rejectGift = (id, details) =>{
  return async (dispatch) =>{
    let data;
    try {
     data = await rejectGifts(id,details); 
    } catch (e) {
      if(e) throw e;
    }
    dispatch(viewGiftApproval());
  };
};


export const clearAllotedGifts = () => {
  return {
    type: "CLEAR_GIFTS",
  };
};

export default reducer;
