import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const payURL = `${apiURL}/client/subscription`;

export const getPaymentStatus = async (value) => {
  const { data } = await axios.get(payURL + "/paymentStatus", {
    params: value,
    headers: setAuth(),
  });
  return data;
};

export const createPayment = async (value) => {
  const { data } = await axios.get(payURL + "/createPayment", {
    params: value,
    headers: setAuth(),
  });
  return data;
};
export const verifyPayment = async (details) => {
  const { data } = await axios.post(payURL + "/verifyPayment", details,{
    headers: setAuth(),
  });
  return data;
};
export const viewBill = async (filter) => {
  const { data } = await axios.get(payURL + "/bill",{
    params:filter,
    headers: setAuth(),
  });
  return data;
};

export const getInvoices = async (filter) => {
  const { data } = await axios.get(payURL + "/invoices",{
    params:filter,
    headers: setAuth(),
  });
  return data;
};
export const getAccessStatus = async (value) => {
  const { data } = await axios.get(payURL + "/access", {
    params: value,
    headers: setAuth(),
  });
  return data;
};

export const getSubscriptionDetails = async() =>{
  const {data} = await axios.get(payURL + "/",{
    headers : setAuth(),
  });
  return data;
}