import { addInventory, 
  viewInventory, 
  editInventory,
  deleteInventory,
  viewInventoryV1,
  addInventoryV1,
  editInventoryV1,
  deleteInventoryV1
} from "../../services/products";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_INVENTORY":
      return { ...state };

    case "SET_INVENTORY":
      return action.payload;
    
    case "ClEAR":
      return initialState ;
    default:
      return state;
  }
};

export const addProductInventory = (data) => {
  return async (dispatch) => {
    try {
      // data = await addInventory(data);
      data = await addInventoryV1(data);
    } catch (e) {
      console.log(e);
    }

    dispatch({
      type: "ADD_INVENTORY",
      payload: { data: data },
    });
  };
};
export const editProductInventory = (data) => {
  return async (dispatch) => {
    try {
      // data = await editInventory(data);
      data = await editInventoryV1(data);
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: "ClEAR"
    }); 
  };
};
export const deleteProductInventory = (data) => {
  return async (dispatch) => {
    try {
      // data = await deleteInventory(data);
      data = await deleteInventoryV1(data);
    } catch (e) {
      console.log(e);
    }
    dispatch({
      type: "ClEAR"
    }); 
  };
};

export const viewProductInventory = (details) => {
  return async (dispatch) => {
    // const { data } = await viewInventory(details);
    const { data } = await viewInventoryV1(details);

    console.log('data', data);

    dispatch({
      type: "SET_INVENTORY",
      payload: { data: data },
    });
  };
};
export const clearProductInventory = ()=>{
  return {
    type:"ClEAR"
  }
}
export default reducer;
