import { useDispatch } from "react-redux";
import AdminLink from "../../Admin/AdminIndex/Link";
import { useEffect } from "react";
import { fetchAnalysisReportSettings } from "../../../reducers/analysisSetting";

const PFALIndex = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAnalysisReportSettings())
  }, [dispatch])

  return (
    <div className="main-content">
      <div className="admin-panel_content">
        <div className="row">

          <div className="admin-panel__box-container">
            <AdminLink
              className={"admin-panel__cards"}
              title="Cost Center"
              type="costCenter"
              link="cost-center/employee"
            />
            <AdminLink
              className={"admin-panel__cards"}
              title="Profit Center"
              type="profitCenter"
              link="profit-center/products"
            />
             <AdminLink
              className={"admin-panel__cards"}
              title="settings"
              type="settings"
              link="settings"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PFALIndex;
