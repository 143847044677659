import { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  adminStyles,
} from "../../../public/stylesheets/selectStyles";
import serialise, {
} from "../../../../utils/serialiseResponse";
import {
  formatDate,
  monthOptions,
  yearOptions,
  returnMonthFromIndex,
} from "../../../../utils/helper";
import Table from "../../../../components/Table";
import { FaEye } from "react-icons/fa";
import {AiFillFolder} from "react-icons/ai" ;
import {MdArrowBackIosNew} from "react-icons/md" ;
import customToast from "../../../../components/CustomToast";
import { viewPrimarySale } from "../../../../reducers/Sales/primarySales";
import { useDispatch, useSelector } from "react-redux";

const AllPrimarySales = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const primarySales = useSelector(({primarySales})=>primarySales);
  const [month, setMonth] = useState({
    value: new Date().getMonth(),
    label: returnMonthFromIndex(new Date().getMonth()),
  });
  const [year, setYear] = useState(yearOptions[2]);
  
  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 40,
      //   disableFilters: true,
      //   maxWidth: 40,
      // },
      {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        showTotal: true,
        placeholderTotal: "Total",
        Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
      },
      {
        Header: "Stockist",
        accessor: "stockist",
        disableSortBy: true,
      },
      {
        Header: "Headquarter",
        accessor: "headquarter",
        disableSortBy: true,
      },
      {
        Header: "Total",
        accessor: "total",
        disableFilters: true,
        disableSortBy: false,
        showTotal: true,
        totalAccessor: "total",
        Cell:(props) => {
          return <div>
            {`₹ ${props?.row?.original?.total}`}
          </div>
        }
      },
      {
        Header: "View",
        accessor: "vi",
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) =>{
          return (
            <Link
              to={`${props?.row?.original?._id}`}
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      }
    ],
    []
  );
   
  const handleSubmit = (e) => {
    e.preventDefault();
    if(!month || !year) return customToast.error("Please select month and year")
    dispatch(viewPrimarySale({month:month.value, year:year.label}));
};
 
  const tableData = useMemo(()=>serialise(primarySales?.data),[primarySales]);

  return (
    <main className="main-content admin-content">
      <div className="expense">
        <h3
            className="web-app__heading"
            style={{ display: "flex", alignItems: "center", justifyContent:"space-between" }}
          >
          <div>
            <MdArrowBackIosNew
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <span style={{ display: "inline-block", margin: "0 1rem" }}>
              All Primary Sales
            </span>
          </div>
        </h3>
        <div className="admin-panel__alert" style={{padding:"1rem" , marginTop:"0"}}>
            <h2 style={{marginBottom:"0"}}>Note</h2>
            <p>
              The below list shows only those Primary Sales which are submitted by the loggedIn user.
              In order to view the complete list, visit ---{'>'} Reports ---{'>'} Primary Sales Reports or {' '} 
             <Link to="/utilities/primary-sales-reports">
              <button style={{background: 'none', border: 'none', color:'white'}}>
                   CLICK HERE !
              </button>
             </Link>
            </p>
          </div>
        <form onSubmit={handleSubmit}>
          <div className="primarysales-filter">
            <div className="util-tp-filter">
              <p className="mb-2">
                Select Month <span className="asterisk-imp">*</span>
              </p>
              <Select
                styles={adminStyles}
                placeholder="Select Month"
                className="mt-3"
                options={monthOptions}
                value={month}
                onChange={(e) => setMonth({ ...e })}
              />
            </div>
            <div className="util-tp-filter">
              <p className="mb-2">
                Select Year <span className="asterisk-imp">*</span>
              </p>
              <Select
                styles={adminStyles}
                placeholder="Select Year"
                className="mt-3"
                options={yearOptions}
                value={year}
                onChange={(e) => setYear({ ...e })}
              />
            </div>
            <div className="util-tp-filter">
              <div className="mb-2">
                <button type="submit" className="button-blue-gradient mt-5">Submit</button>
              </div>
            </div>
            <div className="util-tp-filter">
              <div className="mb-2">
                <button 
                  className="button-submit-green mt-5 ms-auto"
                  onClick={()=>{navigate(-1)}}
                >
                  <span className="button-submit-green__icon-container">
                    <AiFillFolder className="button-submit-green__icon" />
                  </span>
                  <span className="button-submit-green__text ">
                    Close Primary Sales
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="filter-table" style={{minHeight:"150px" , overflow:"auto"}}>
          <Table columns={columns} data={tableData} />
        </div>
      </div>
    </main>
  );
};

export default AllPrimarySales;
