import { Outlet } from "react-router-dom";
import AdminLink from "./Link";

const AllSalesIndex = () => {
  return (
    <div className="main-content">
      <div className="admin-panel_content">
        <div className="row">
          <div className="web-app__heading mt-5">
            Sales
          </div>

          <div className="admin-panel__box-container">
            <AdminLink
              title="Primary Sales"
              type="sales"
              link="primarysales"
              className={"admin-panel__cards"}
            />
            <AdminLink
              title="Gross Primary Sales"
              type="sales"
              link="gross-primarysales"
              className={"admin-panel__cards"}
            />
            <AdminLink
              title="Secondary Sales"
              type="sales"
              link="secondarysales"
              className={"admin-panel__cards"}
            />
            <AdminLink
              title="Gross Secondary Sales"
              type="sales"
              link="gross-secondarysales"
              className={"admin-panel__cards"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllSalesIndex;
