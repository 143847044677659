export const title = "Manage Users";

export const links = [
  {
    name: "Create User",
    route: "create-user",
    accessor: "create-user",
  },
  {
    name: "Create Admin",
    route: "create-admin",
    accessor: "create-admin",
  },
  {
    name: "User Info",
    route: "user-info",
    accessor: "user-info",
  },
  {
    name: "Edit / Delete",
    route: "edit-user",
    accessor: "edit-user",
  },
  {
    name: "Admin Info",
    route: "admin-info",
    accessor: "admin-info",
  },
  {
    name: "Manage Divisions",
    route: "division",
    accessor: "division",
  },
  {
    name: "Manage Designations",
    route: "designation",
    accessor: "designation",
  },
  {
    name: "Set User Target",
    route: "set-user-target",
    accessor: "set-user-target",
  },
  {
    name: "Set Headquarter Target",
    route: "set-headquarter-target",
    accessor: "set-headquarter-target",
  },
  {
    name: "Upload Target",
    route: "upload-target",
    accessor: "upload-target",
  },
  {
    name: "Access Control",
    route: "access-control",
    accessor: "acccess-control",
  },
  {
    name: "user Devices",
    route: "user-devices",
    accessor: "user-devices",
  },
  {
    name: "Billing",
    route: "billing",
    accessor: "billing",
  },
];
