import { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../../components/PageTitle";
import Select from "react-select";
import { getAccessStatus } from "../../../../services/payment";
import Table from "../../../../components/Table";
import {
  clearDivisions,
  setDivisions,
} from "../../../../reducers/users/divisionReducer";
import serialise, {
  generateSelectData,
} from "../../../../utils/serialiseResponse";
import {
  clearStates,
  setStates,
} from "../../../../reducers/locations/stateReducer";
import {
  clearUsers,
  setUsers,
} from "../../../../reducers/users/employeeReducer";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../reducers/locations/hqReducer";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import customToast from "../../../../components/CustomToast";
import { toggleUserActivation } from "../../../../services/users";
import { useNavigate } from "react-router-dom";

const ActiveControl = ({ socket }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const divisionData = useSelector(({ division }) => division);
  const states = useSelector(({ state }) => state);
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const userData = useSelector(({ employee }) => employee);

  const divisionSelect = useMemo(
    () => generateSelectData(divisionData, "name"),
    [divisionData]
  );
  const stateSelect = useMemo(
    () => generateSelectData(states.data, "name"),
    [states]
  );
  const headquarterSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );

  const [state, setState] = useState(null);
  const [headquarter, setHeadquarter] = useState(null);
  const [division, setDivision] = useState(null);
  const [accessStatus , setAccessStatus] = useState(null);
  const [showPopup , setShowPopUP] = useState(false);

//   const Popup = ()=>{
//     return (
//       <div className="edit-popup">
//         <div className="edit-content d-flex flex-column align-items-center">
//           <h2 className="web-app__heading lh-base">
//             Your Account has been <span className="text-danger fw-bold">LOCKED</span>, Please Pay your bills
//           </h2>

//           <div className="edit-form-button-container mt-5">
//             <button onClick={(e)=>{
//               e.preventDefault();
//               navigate("../billing");
//             }} className="button-blue-gradient">
//               Pay Now
//             </button>
//           </div>
//         </div>
//       </div>
//     );
//   };
  
  useEffect(() => {
    dispatch(setUsers("user"));
    dispatch(setDivisions());
    dispatch(setStates());
    return () => {
      dispatch(clearUsers());
      dispatch(clearDivisions());
      dispatch(clearStates());
      dispatch(clearUsers());
    };
  }, [dispatch]);
  
//   useEffect(()=>{
//     const fetch = async()=>{
//       try{
//       const {data} = await getAccessStatus();
//       setAccessStatus(data);
//       }catch(err){
//         // console.log(err);
//       }
//     }
//     fetch();
//   },[]);


  useEffect(() => {
    if (!state) return;

    dispatch(setHeadquarters(state.value));
    return () => {
      dispatch(clearHeadquarters());
    };
  }, [dispatch, state]);

  const handleFilter = (e) => {
    e.preventDefault();

    const data = {
      hqId: headquarter?.value,
      divisionId: division?.value,
    };

    try {
      dispatch(setUsers("user", data));
    } catch (e) {
      customToast.error("We Encountered an Error");
    }
  };

  const onToggle =  async (id, value) => {
    try {
      let value1 = value === true ? false : true;
      let {data} = await toggleUserActivation(id, value1);
      dispatch(setUsers("user"));
    } catch (e) {
      console.log(e);
      return customToast.error("Sorry, we encountered some error");
    }
  } ;

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Name",
        accessor: "name",
      },
      
      {
        Header: "De-Activate User",
        accessor: "actions",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const deActivateDate = props?.row?.original?.deactivateDate
            ? new Date(props?.row?.original?.deactivateDate)
            : undefined;
          let isDisabled = false;
          let next30DaysDate = new Date();

          if (deActivateDate) {
             next30DaysDate = new Date(deActivateDate);
            next30DaysDate.setDate(deActivateDate.getDate() + 30);

            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            next30DaysDate.setHours(0, 0, 0, 0);

            isDisabled = currentDate.getTime() < next30DaysDate.getTime();
          }

          const handleClick = (e) => {
            if (isDisabled) {
              e.preventDefault();
              customToast.error(`Not Allowed to change before --> ${next30DaysDate.toDateString()}!`);
            } else {
              onToggle(props?.row?.original?._id, e.target.checked);
            }
          };

          return (
            <label className="toggle-label">
              <input
                type="checkbox"
                defaultChecked={props?.row?.original?.isActive === false}
                onClick={handleClick}
              />
              <span />
            </label>
          );
        },
      },
      {
        Header:"De-activated Date",
        accessor:"",
        Cell:(props) => {
          let data = props?.row?.original;
          let date = String(data?.deactivateDate);
          let formateDate = date.slice(0,10);

          if(date === "undefined") return <div> </div> 

          return <div>{props?.row?.original?.isActive === false ? "De-activated On :- " : "Activated On :- "}  {`${formateDate}`}</div>
        }
      },
    ],
    [accessStatus]
  );

  const tableData = useMemo(() => serialise(userData), [userData]);

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Activation Control" hierarchy={true}/>

        {/* <section className="area-creation-content__form">
          <form onSubmit={handleFilter}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">Select State</label>
                <br />
                <Select
                  name="state"
                  id="state"
                  value={state}
                  options={stateSelect}
                  onChange={({ value, label }) => setState({ value, label })}
                  styles={adminStyles}
                  placeholder="Select State"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">Select HQ</label>
                <br />
                <Select
                  name="headquarter"
                  id="headquarter"
                  value={headquarter}
                  options={headquarterSelect}
                  onChange={({ value, label }) =>
                    setHeadquarter({ value, label })
                  }
                  styles={adminStyles}
                  placeholder={
                    state ? "Select Headquarter" : "Select State First"
                  }
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">Select Division</label>
                <br />
                <Select
                  name="division"
                  id="division"
                  value={division}
                  options={divisionSelect}
                  onChange={({ value, label }) => setDivision({ value, label })}
                  styles={adminStyles}
                  placeholder="Select Division"
                />
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-sm-12 col-md-4 col-lg-4 area-creation-content__form-submit">
                <button type="submit" className="button-blue-gradient">
                  Filter Users
                </button>
              </div>
            </div>
          </form>
        </section> */}

        <div className="admin-panel__alert m-5">
          <h2>Note</h2>
          <p>Using this feature the admin can de-activate any user’s account. The Deactivated account user cannot login into the account but the admin and other managers will still be able to access his data. 
          The de-activated accounts will not charged by Medorn, however, the admin cannot activate an account within <b>30 days before the de-activation date.</b> 
          </p>
        </div>

        <section className="area-creation-content__info">
          {tableData.length > 0 && (
            <h2 className="web-app__heading mb-4 ms-2">
              Showing ({tableData.length}) Entries
            </h2>
          )}
          <div className="filter-table">
            <Table columns={columns} data={tableData} />
          </div>
        </section>
      </div>
      {/* {showPopup && <Popup />} */}
    </div>
  );
};

export default ActiveControl;
