import { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "../../../../../../../components/Table";
import { viewUsersForUtilities } from "../../../../../../../services/utilities";
import serialise from "../../../../../../../utils/serialiseResponse";
import customToast from "../../../../../../../components/CustomToast";
import { MdArrowBackIosNew } from "react-icons/md";

const HqUserTarget = ({ data }) => {
  const [reports, setReports] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const hq = location?.state?.data;

  const fetchUser = useCallback(async () => {
    try {
      const { data } = await viewUsersForUtilities();
      setReports(data);
    } catch (error) {
      customToast.error("An error occurred. Please try again.");
    }
  }, []);

  useEffect(() => {
    fetchUser();
  }, []);

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   minWidth: 100,
      //   maxWidth: 100,
      //   disableFilters: true,
      // },
      {
        Header: "User",
        accessor: "name",
        minWidth: 900,
      },
      {
        Header: "Designation",
        accessor: "designation",
        disableFilters: true,
      },
    ],
    [hq]
  );

  const filterReportsByHq = (reports, hq) => {
    if (!hq) {
      return [];
    }
    return reports.filter((report) => report.headquarter.name === hq);
  };

  const filteredReports = filterReportsByHq(reports, hq);
  const tableData = useMemo(() => serialise(filteredReports), [filteredReports]);

  return (
    <div className={!data ? "expense" : ""}>
      {!data && (<h2
        className="web-app__heading"
        style={{ display: "flex", alignItems: "center" }}
      >
        <MdArrowBackIosNew
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        <span style={{ display: "inline-block", margin: "0 1rem" }}>
          Headquarter targets
        </span>
      </h2>)}
      <div className="filter-table">
        {filteredReports.length > 0 && (
          <h2 className="web-app__heading mb-4 ps-3">
            Showing ({filteredReports.length}) Entries
          </h2>
        )}
        {tableData.length > 0 ? (
          <Table columns={columns} data={tableData} fileSrc="Target Report" />
        ) : (
          <h3 className="mt-2 no-results-message">No User found</h3>
        )}
      </div>
    </div>
  );
};

export default HqUserTarget;
