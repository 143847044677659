import React, { useMemo } from 'react'
import PageTitle from '../../../../../components/PageTitle';
import { useLocation, Link } from "react-router-dom";
import Table from '../../../../../components/Table';
import { FaEye } from 'react-icons/fa';
import serialise from '../../../../../utils/serialiseResponse';

const DeletedPrimary = () => {
  const location = useLocation();
  const data = location?.state?.products

  const columns = React.useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Product Name",
        accessor: "product.label",
      }, {
        Header: "Price",
        accessor: "price",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Free Stocks",
        accessor: "freeStocks",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Discount",
        accessor: "discount",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Final Price",
        accessor: "finalPrice",
        disableFilters: true,
        disableSortBy: true,
      },


    ],
    [data]
  );
  const tableData = useMemo(() => serialise(data), [data]);
  return (
    <main className="main-content admin-content">
      <div className="area-creation-content ">
        <PageTitle title="Deleted Inventory" />
      </div>
      <section className=" table-container" style={{ marginRight: "2rem" }}>
        <div className="filter-table">
          {tableData?.length > 0 ? (
            <Table columns={columns} data={tableData} />
          ) : (
            <h3 className="mt-2 no-results-message">No data found</h3>
          )}
        </div>
      </section>
    </main>
  );
}

export default DeletedPrimary