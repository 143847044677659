import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import {RiEdit2Fill} from "react-icons/ri" ;
import { AiFillSave } from "react-icons/ai";
import { viewProductsCost, putProductsCost } from "../../../../services/profitCenter";
import Table from "../../../../components/Table";
import { viewProducts, viewProductV1 } from "../../../../services/products";
import { useNavigate } from "react-router-dom";
import { FaSyncAlt } from "react-icons/fa";
import  serialise from "../../../../utils/serialiseResponse";

const CustomCost = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [editRow, setEditRow] = useState(null);
  const [editing, setEditing] = useState(false);
  const [rowEditing, setRowEditing] = useState(false);
  
  const upCustomOneRef = useRef(0) ;
  const upCustomTwoRef =useRef(0);
  const upCustomThreeRef = useRef(0);

  
  useEffect(()=>{
    viewProductsCost().then(({data})=>{
      if(data.submitter){
        setProducts(data.table);
        setEditing(data._id);
      }else{
        setProducts(data);
        setEditing(false);
      }
    }).catch((err)=>{
      customToast.error("Sorry Something went wrong!")
    }) ;
  },[]) ;

  const handleEditRow = useCallback((row)=>{
    if(rowEditing)return customToast.error("Please Save Your Changes");
    upCustomOneRef.current = row?.cus1 || 0 ;
    upCustomTwoRef.current = row?.cus2 || 0;
    upCustomThreeRef.current = row?.cus3 || 0;
    
    setEditRow(row?.sr);
    setRowEditing(true);

  },[rowEditing]);
  const submitEditedRow = useCallback((sr)=>{
    
    const editedObj = products[sr-1];
    editedObj.cus1 = Number(upCustomOneRef.current) ;
    editedObj.cus2 = Number(upCustomTwoRef.current) ;
    editedObj.cus3 = Number(upCustomThreeRef.current) ;
    const newarr = [...products];
    newarr[sr-1] = {...editedObj} ;
    setProducts(newarr);
    setEditRow(-1);
    setRowEditing(false);
  },[products]);

  const submitBill = useCallback((e)=>{
    e.preventDefault();
    if(rowEditing)return customToast.info("Please Save Your Changes!");
    putProductsCost({
      payload:products, 
    }).then((data)=>{
      customToast.success("Bill Saved Successfully");
    }).catch((err)=>{
      customToast.error("Sorry Something Went Wrong!");
    })
    setProducts([]);
    
  },[products, rowEditing]);

  const handleAutoFill = useCallback(()=>{
    viewProductV1("").then(({data}) => {
      const notPresent = data.filter((el, idx)=>{
        const index = products.findIndex((elem, idx)=> elem.prodId === el._id);
        return index === -1 ;
      }) ;
      const newArr = [];
      notPresent.map((el, idx)=> newArr.push({
        _id:1,
        name: el.name,
        prodId: el._id, 
        mrp: el.mrp,
        ptr: el.ptr, 
        pts: el.pts,
        cus1: 0,
        cus2: 0,
        cus3: 0
      }))
      setProducts([...newArr, ...products]);
    }).catch((err)=>
      customToast.error("Sorry Something went wrong!")
    );
  },[products]);

  const columns = useMemo(
    () => [
      {
        Header: "Product",
        accessor: "name",
        disableSortBy: true,
        disableFilters:false,
      },
      {
        Header: "PTS",
        accessor: "pts",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "PTR",
        accessor: "ptr",
        disableSortBy: true,
        disableFilters:true,
      },
      {
        Header: "MRP",
        accessor: "mrp",
        disableSortBy: true,
        disableFilters:true,
      },
      {
        Header: "Price One",
        accessor: "cus1",
        disableSortBy: true,
        disableFilters:true,
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          return editable ? (
            <input
              defaultValue={props?.row?.original?.cus1}
              onChange={(e) => {
                upCustomOneRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input h-25"
            />
          ) : (
            <span>{props?.row?.original?.cus1}</span>
          );
        },
      },
      {
        Header: "Price Two",
        accessor: "cus2",
        disableSortBy: true,
        disableFilters:true,
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          return editable ? (
            <input
              defaultValue={props?.row?.original?.cus2}
              onChange={(e) => {
                upCustomTwoRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input h-25"
            />
          ) : (
            <span>{props?.row?.original?.cus2}</span>
          );
        },
      },
      {
        Header: "Price Three",
        accessor: "cus3",
        disableSortBy: true,
        disableFilters:true,
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          return editable ? (
            <input
              defaultValue={props?.row?.original?.cus3}
              onChange={(e) => {
                upCustomThreeRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input h-25"
            />
          ) : (
            <span>{props?.row?.original?.cus3}</span>
          );
        },
      },
      {
        Header: "Edit",
        accessor: "edit",
        disableSortBy: true,
        disableFilters:true,
        minWidth:70,
        maxWidth:70,
        Cell: (props)=>{
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          return editable ? (
            <AiFillSave
              onClick={(e) => {
                e.preventDefault();
                submitEditedRow(props?.row?.original?.sr);
              }}
              type="button"
              className="icon-color-green"
            />
          ) : (
            <span
              className="react-table-view-link"
              onClick={(e) => {
                e.preventDefault();
                handleEditRow(props?.row?.original);
              }}
            >
              <RiEdit2Fill className="icon-color-green" />
          </span>
          );
        }
      }
    ],
    [editRow, handleEditRow, submitEditedRow]
  );
  const tableData = useMemo(()=>serialise(products),[products]);
  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Custom Cost" hierarchy={true} />
          
        <section className="area-creation-content__form">
          {/* <form >
            <div className="d-flex flex-wrap gap-5 justify-content-start ">
              <div className="d-flex flex-wrap gap-5 ">
                <div className="util-tp-filter">
                  <p>
                    Select Month <span className="asterisk-imp">*</span>
                  </p>
                  <Select
                    styles={selectStyles}
                    placeholder="Select Month"
                    className="mt-3"
                    options={monthOptions}
                    value={month}
                    onChange={(e) => setMonth({ ...e })}
                  />
                </div>
                <div className="util-tp-filter">
                  <p>
                    Select Year <span className="asterisk-imp">*</span>
                  </p>
                  <Select
                    styles={selectStyles}
                    placeholder="Select Year"
                    className="mt-3"
                    options={yearOptions}
                    value={year}
                    onChange={(e) => setYear({ ...e })}
                  />
                </div>
                <div className="util-tp-filter">
                  <p>
                    Select Headquarter <span className="asterisk-imp">*</span>
                  </p>
                  <Select
                    styles={selectStyles}
                    placeholder="Select HQ"
                    className="mt-3"
                    options={headquarterSelect}
                    value={hq}
                    onChange={(e) => setHq({ ...e })}
                  />
                </div>
              </div>
              <ProfitAnalysisBox row1={`${employee.length} EMPLOYEES`} 
                row2={`Period: ${month?.label} ${year?.label} | ${new Date(year?.label, Number(month?.value)+1, 0).getDate()} Days`} 
                row3={`Rs ${expense}`} row4="TOTAL EXPENSE"
              />
            </div>
          </form> */}
          <div className="admin-panel__alert">
            <h2>NOTE</h2>
            <p>
              You can add up to three custom costs of your products here, these custom costs can be used to create different kinds of reports and can help you to 
              see profit at different margin levels.
            </p>
          </div>
          <div className="util-tp-filter">
            <div className="mb-2">
              <button
                type="button"
                onClick={handleAutoFill}
                className="button-blue-gradient  mt-5"
              >
                <FaSyncAlt className="me-2"/>
                Sync
              </button>
            </div>
          </div>
        </section>
      </div>
      
      <div className="filter-table pe-4">
        <Table columns={columns} data={tableData} />
      <button type="button" className="button-submit" onClick={submitBill}>
        Submit
      </button>
      </div>
    </main>
  );
};

export default CustomCost;
