import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { clearUsers, setUsers } from '../../../reducers/users/employeeReducer';
import { generateSelectData } from '../../../utils/serialiseResponse';
import { selectStyles } from '../../public/stylesheets/selectStyles';
import moment from 'moment-timezone';
import { viewGeoLocationData } from '../../../services/geoFencing';
import { GoogleMap, Marker, useJsApiLoader, Polyline } from '@react-google-maps/api';
import { database } from "../../../firebase";
import { onValue, ref } from 'firebase/database';
import ChemMarker from "../../public/img/ChemMarker.png";
import DocMarker from "../../public/img/DocMarker.png";
import StkMarker from "../../public/img/StockMarker.png";
import { MapMarker } from '../../../components/MapMarker'; 

const distanceParameteres = [
  { value: 100000, label: "Off" },
  { value: 10, label: "10 m" },
  { value: 50, label: "50 m" },
  { value: 100, label: "100 m" },
  { value: 250, label: "250 m" },
  { value: 500, label: "500 m" },
  { value: 1000, label: "1 Km" },
  { value: 2000, label: "2 Km" },
];

const haversineDistance = (coords1, coords2) => {
  function toRad(x) {
    return (x * Math.PI) / 180;
  }

  const R = 6371; // Earth radius in km
  const dLat = toRad(coords2.lat - coords1.lat);
  const dLon = toRad(coords2.lng - coords1.lng);
  const lat1 = toRad(coords1.lat);
  const lat2 = toRad(coords2.lat);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c * 1000; // Distance in meters
  return d;
};

const NavigateTracker = () => {

  const dispatch = useDispatch();
  const users = useSelector(({ employee }) => employee);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [disParameter, setDisParameter] = useState(undefined);
  const [date, setDate] = useState(undefined);
  const [taggedData, setTaggedData] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [firebaseData, setFirebaseData] = useState([]);
  const [filteredPathArray, setFilteredPathArray] = useState([]);

  const [dcsDetailVisibility, setDcsDetailVisibility] = useState(false);
  const [selectedDcsDetail, setSelectedDcsDetail] = useState({
    name: "",
    address: "",
    type: "",
    time: "",
  });

  const containerStyle = {
    width: '1120px',
    height: '470px'
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_API_KEY
  });

  useEffect(() => {
    if (selectedUser && disParameter && date) {
      setShowMap(true);
    }
  }, [selectedUser, disParameter, date])

  useEffect(() => {
    dispatch(setUsers('user'));

    return () => {
      dispatch(clearUsers());
    }
  }, [dispatch]);

  const employeeSelect = useMemo(
    () => generateSelectData(users, "fullName"),
    [users]
  );

  const formattedDate = (date) => {
    return moment.tz(date, "Asia/Kolkata").format().toString().substring(0, 10);
  };

  const fetchData = useCallback(async () => {
    try {

      if (selectedUser && date) {
        const tempDate = formattedDate(date);

        const payload = {
          month: new Date(date).getMonth(),
          year: new Date(date).getFullYear(),
          date: tempDate,
          userId: selectedUser?.value
        };

        const res = await viewGeoLocationData(payload);
        setTaggedData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [selectedUser, date]);

  useEffect(() => {
    if (selectedUser && date) {
      fetchData();
    }
  }, [selectedUser, date, fetchData]);

  useEffect(() => {
    if (selectedUser && date) {
      const dataRef = ref(database, `users/${selectedUser?.value}/${date}`);
      const fetchData = onValue(dataRef, (snapshot) => {
        const data = snapshot?.val();
        const formattedData = data ? Object.values(data) : [];
        setFirebaseData(formattedData);
      });

      return () => fetchData();
    }
  }, [selectedUser, date]);

  const path = useMemo(() =>
    firebaseData.map((data) => ({
      lat: data?.latitude,
      lng: data?.longitude
    })), [firebaseData]
  );

  useEffect(() => {

    const filteredPath = [];

    if (path.length > 0) {
      filteredPath.push(path[0]); // Always add the first point
      let lastPoint = path[0];

      for (let i = 1; i < path.length; i++) {
        const distance = haversineDistance(lastPoint, path[i]);
        if (distance >= disParameter?.value) {
          filteredPath.push(path[i]);
          lastPoint = path[i];
        }
      }
    }

    setFilteredPathArray(filteredPath);

  }, [path, disParameter]);

  const [zoom, setZoom] = useState(12);

  const ViewMap = () => {

    return isLoaded ?
      <section>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={path[path?.length - 1]}
          zoom={zoom}
          onZoomChanged={() => setZoom(zoom)}
        >


          {filteredPathArray.map((coordinate, index) => (
            <Marker
              icon="https://img.icons8.com/?size=40&id=98455&format=png&color=20C997A1"
              key={index}
              position={coordinate}
              label={`${index + 1}`}
            />
          ))
          }

          {taggedData?.length > 0 && (
            taggedData?.map((el, index) => {
              let coord = {
                lat: el?.geolocation?.latitude,
                lng: el?.geolocation?.longitude,
              };
              return (
                <MapMarker key={index} coord={coord} el={el} icon={{
                  url: el?.targetType === "doctor" ? DocMarker : el?.targetType === 'stockist' ? StkMarker : ChemMarker,
                  scaledSize: { width: 30, height: 40 }
                }} detail={selectedDcsDetail} setDetail={setSelectedDcsDetail}
                setDcsDetailVisibility={setDcsDetailVisibility}
                />
              )
            })
          )}

          <Polyline path={filteredPathArray} options={{ strokeColor: "red", strokeOpacity: 1.0, strokeWeight: 2 }} />
        </GoogleMap>
      </section>
      :
      <div style={{ color: "white" }}>
        ....Loading Map
      </div>
  }


  return (
    <div className='main-content admin-content'>
      <div className='expense'>
        <div className='row'>
          <h3 className='web-app__heading'>
            Navigate To Tracker
          </h3>

          <div >
            <div className="util-tp-query d-flex justify-content-between">
              <div className="w-100 d-flex justify-content-between">

                <div className='d-flex w-100'>
                  <div className="util-tp-filter me-5">
                    <p>
                      Select User <span style={{ color: "#e74c3caa" }}>*</span>
                    </p>
                    <Select
                      options={employeeSelect}
                      styles={selectStyles}
                      placeholder="Select User"
                      onChange={(e) => setSelectedUser(e)}
                      className='mt-3'
                    />
                  </div>

                  <div className="util-tp-filter me-5">
                    <p>
                      Select Distance <span style={{ color: "#e74c3caa" }}>*</span>
                    </p>
                    <Select
                      styles={selectStyles}
                      className='mt-3'
                      options={distanceParameteres}
                      onChange={(e) => setDisParameter(e)}
                    />
                  </div>

                  <div className="util-tp-filter">
                    <p>
                      Select Date <span style={{ color: "#e74c3caa" }}>*</span>
                    </p>
                    <input
                      type="date"
                      className="mt-3"
                      id="date-input"
                      placeholder="Date"
                      style={{ border: "1.7px solid #36abf9", borderRadius: "5px", padding: "5.5px" }}
                      onChange={(e) => setDate(e?.target?.value)}
                    />
                  </div>

                </div>


                {
                  dcsDetailVisibility ?
                  <div className="admin-panel__alert w-100 my-0 mx-5 p-3 d-flex flex-column justify-content-center align-items-start">
                    <h2>
                      Name: {selectedDcsDetail.name}
                    </h2>
                    <div className='d-flex w-100 justify-content-start p-0 m-0 h-25'>
                      <p className='px-2 d-flex'>
                        Type:
                        <b className='text-capitalize px-2'>{selectedDcsDetail.type}</b>
                      </p>
                      <p className='mx-5 d-flex'>
                        Time:
                        <b className='px-2'>{selectedDcsDetail.time}</b>
                      </p>
                    </div>
                    <p className='px-2 d-flex'>
                      Address:
                      <b className='px-2'>{selectedDcsDetail.address}</b>
                    </p>
                  </div>
                  :
                  null
                }

              </div>
            </div>
          </div>
        </div>
        {showMap ?
          <div className='mt-5 d-flex justify-content-center' style={{ border: "15px solid #2b2c47", borderRadius: "10px" }}>
            <ViewMap />
          </div>
          :
          null}

      </div>
    </div>
  )
}

export default NavigateTracker;