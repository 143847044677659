import React, { useCallback } from "react";
import { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";
import {
  addDesignation,
  clearDesignations,
  deleteDesignation,
  editDesignation,
  setDesignations,
} from "../../../../reducers/users/designationReducer";
import addIndex from "../../../../utils/serialiseResponse";
import { MdCancel, MdDelete,MdCheckCircleOutline } from "react-icons/md";
import Select from "react-select";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import { RiCloseCircleLine, RiEdit2Fill } from "react-icons/ri";
import { AiFillSave } from "react-icons/ai";
import { getMinCallReport, getMinCallReportV1, postMinCallReport, postMinCallReportV1 } from "../../../../services/settings";


const MinCallReport = () => {
  const dispatch = useDispatch();

  const [editRow, setEditRow] = useState("");
  const [editing, setEditing] = useState(false);
  const [minCallArr, setMinCallArr] = useState([]);

  const upDoctorRef = useRef(0);
  const upStockistRef = useRef(0);
  const upChemistRef = useRef(0);

  const submitEditedRow = (sr) => {

    const editedObj = minCallArr[sr - 1];
    editedObj.doctor = Number(upDoctorRef.current);
    editedObj.stockist = Number(upStockistRef.current);
    editedObj.chemist = Number(upChemistRef.current);
    const newarr = [...minCallArr];
    newarr[sr-1] = {...editedObj} ;
    setMinCallArr(newarr);
    setEditing(false);
    setEditRow("");
  };
  
  const handleEditRow = (original) => {
    if (editing) {
      customToast.error("Please save the changes you just made");
      return;
    }
    setEditing(true);
    setEditRow(original?.sr);
    upDoctorRef.current = original?.doctor ;
    upChemistRef.current = original?.chemist ;
    upStockistRef.current = original?.stockist ;
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Designation",
        accessor: "name",
        minWidth: 100,
        maxWidth: 100,
      },
      {
        Header: "Doctor CR",
        accessor: "doctor",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          return editable ? (
            <input
              defaultValue={props?.row?.original?.doctor}
              onChange={(e) => {
                upDoctorRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input"
            />
          ) : (
            <span>{props?.row?.original?.doctor}</span>
          );
        },
      },
      {
        Header: "Chemist CR",
        accessor: "Chemist",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          return editable ? (
            <input
              defaultValue={props?.row?.original?.chemist}
              onChange={(e) => {
                upChemistRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input"
            />
          ) : (
            <span>{props?.row?.original?.chemist}</span>
          );
        },
      },
      {
        Header: "Stockist CR",
        accessor: "stockist",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 100,
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          return editable ? (
            <input
              defaultValue={props?.row?.original?.stockist}
              onChange={(e) => {
                upStockistRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input"
            />
          ) : (
            <span>{props?.row?.original?.stockist}</span>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "action",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 90,
        Cell: (props) => {
          return editing && Number(props?.row?.original?.sr) === editRow ? (
            <React.Fragment>
              <AiFillSave
                onClick={(e) => {
                  submitEditedRow(props?.row?.original?.sr);
                }}
                type="button"
                className="icon-color-green"
              />
              <MdCancel
                type="button"
                className="icon-color-yellow"
                onClick={() => {
                  cancelHandler(props?.row?.original?.sr);
                }}
              />
            </React.Fragment>
          ) : (
            <span
              className="react-table-view-link"
              onClick={() => {
                handleEditRow(props?.row?.original);
              }}
            >
              <RiEdit2Fill className="icon-color-green" />
            </span>
          );
        },
      },
      
    ],
    [editing,editRow]
  );

  
  useEffect(() =>{
    const fetch = async()=>{
      const {data} = await getMinCallReport();
      // const {data} = await getMinCallReportV1();
      setMinCallArr(data);
    }
    fetch();
  },[]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(editing)return customToast.error("Please save the changes");
    try{
      const res = await postMinCallReport({payload:minCallArr});
      // const res = await postMinCallReportV1({payload:minCallArr});
      customToast.success("Data successfully updated");
    }catch(err){
      customToast.error("We encountered an error");
    }
    
  };
  
  const cancelHandler = (sr) => {
    setEditing(false);
    setEditRow("");
  };
  const tableData = useMemo(() => addIndex(minCallArr), [minCallArr]);
  
  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Minimum Call Reports For Desigantions" hierarchy={true}/>

        <section className="area-creation-content__info">
          {tableData.length > 0 && (
            <h2 className="web-app__heading mb-4 ms-2">
              Showing ({tableData.length}) Entries
            </h2>
          )}
          <div className="filter-table">
            <Table columns={columns} data={tableData} />
          </div>
          <button
            className="button-submit-green mt-4 mx-auto"
            onClick={handleSubmit}
          >
            <span className="button-submit-green__icon-container">
            <MdCheckCircleOutline className="button-submit-green__icon" />
            </span>
            <span className="button-submit-green__text ">
              Submit
            </span>
          </button>
        </section>
      </div>
    </div>
  );
};

export default MinCallReport;
