import {
  addAllowance,
  addOutStationAllowance,
  deleteAllowances,
  editAllowance,
  getAllowance,
  getOSAllowances,
} from "./../../services/allowance.js";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_ALLOWANCE":
      return { ...state };

    case "CREATE_OUT_STATION_ALLOWANCE":
      return { ...state };

    case "VIEW_ALLOWANCE":
      return action.payload;

    case "VIEW_OS_ALLOWANCE":
      return action.payload;

    case "EDIT_ALLOWANCE":
      return { data:state.data};

    case "DELETE_ALLOWANCE":
      return {data:state.data};

    case "CLEAR_ALLOWANCE":
      return initialState;

    default:
      return state;
  }
};

export const viewAllowances = () => {
  return async (dispatch) => {
    const data = await getAllowance();

    dispatch({
      type: "VIEW_ALLOWANCE",
      payload: { data: data },
    });
  };
};

export const viewOutStationAllowances = () => {
  return async (dispatch) => {
    const data = await getOSAllowances();

    dispatch({
      type: "VIEW_OS_ALLOWANCE",
      payload: { data: data },
    });
  };
};

export const newAllowance = (data) => {
  return async (dispatch) => {
    try {
      data = await addAllowance(data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "CREATE_ALLOWANCE",
      payload: data,
    });
    dispatch(viewAllowances());
  };
};

export const newOutStationAllowance = (data) => {
  return async (dispatch) => {
    try {
      data = await addOutStationAllowance(data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "CREATE_OUT_STATION_ALLOWANCE",
      payload: data,
    });
    dispatch(viewOutStationAllowances());
  };
};

export const clearAllowance = () => {
  return {
    type: "CLEAR_ALLOWANCE",
  };
};

export const editAllowances = (id, updatedAllowance) => {
  return async (dispatch) => {
    try {
      updatedAllowance = await editAllowance(id, updatedAllowance);
    } catch (e) {
      if (updatedAllowance.error) throw updatedAllowance.error;
    }

    dispatch({
      type: "EDIT_ALLOWANCE",
      payload: { data: updatedAllowance },
    });
    dispatch(viewAllowances());
  };
};

export const deleteAllowance = (type, data) => {
  return async (dispatch) => {
    try {
      
      data = await deleteAllowances(type, data);
    } catch (e) {
      if (data.error) throw data.error;
    }
    dispatch({
      type: "DELETE_ALLOWANCE",
      payload: { data: data },
    });
    if(type === "outStation"){
      dispatch(viewOutStationAllowances());
    }
    else{

      dispatch(viewAllowances());
    }
  };
};

export default reducer;
