import { lazy, Suspense, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import serialise, { generateSelectData } from "../../../../utils/serialiseResponse";
import Table from "../../../../components/Table";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import { ViewLinkedCD, viewPayment, viewProductSaleReport, viewTargets } from "../../../../services/targets";
import { items } from "../../../../utils/helper";
import { returnMonthFromIndex } from "../../../../utils/helper";
// import CascadingMenu from "../../CascadingMenu";
import styles from "./Business.module.css"
import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
const CascadingMenu = lazy(() => import('../../CascadingMenu'));

const Business = () => {

  const [tdata, setTdata] = useState([]);
  const [doctor, setDoctor] = useState("");
  const [docs, setDocs] = useState([]);
  const doctorSelect = useMemo(() => generateSelectData(docs, "name"), [docs]);
  const [fromMonth, setFromMonth] = useState("");
  const [toMonth, setToMonth] = useState("");
  const [fromYear, setFromYear] = useState("");
  const [toYear, setToYear] = useState("");
  const [selectedRange, setSelectedRange] = useState({})
  const [totalRevenue, setTotalRevenue] = useState(0)
  const [totalRevenueShare, setTotalRevenueShare] = useState(0)
  const [paymentData, setPaymentData] = useState([])
  const [paidAmount, setPaidAmount] = useState(0)
  const [pendingAmount, setPendingAmount] = useState(0)
  // const [chemDocList, setChemDocList] = useState([]);


  const handleMenuClick1 = ({ keyPath }) => {
    // console.log(keyPath)
    // Assuming keyPath is ['0-2024']
    const [monthYearString] = keyPath;
    // Splitting '0-2024' into month and year
    const [month, year] = monthYearString.split('-');
    setFromMonth(`${month}`);
    setFromYear(`${year}`);
    setSelectedRange({ ...selectedRange, fromMonth: month, fromYear: year })
  };
  const handleMenuClick2 = ({ keyPath }) => {
    // console.log(keyPath)
    const [monthYearString] = keyPath;
    const [month, year] = monthYearString.split('-');
    setToMonth(`${month}`);
    setToYear(`${year}`);
    setSelectedRange({ ...selectedRange, toMonth: month, toYear: year })
  };

  useEffect(() => {
    // viewTargets("doctor", { showApproved: true }).then((response) => {
    //   setDocs(response?.data);
    // }).catch(e => console.log(e));
    ViewLinkedCD().then((response) => {
      // setChemDocList(response.data);
       // Extract docArr from each item and merge into docs state
       const allDocs = response.data.flatMap(item => item.docArr);
        // Filter unique doctors
      const uniqueDocs = allDocs.reduce((acc, doc) => {
        if (!acc.some(d => d._id === doc._id)) {
          acc.push(doc);
        }
        return acc;
      }, []);
    
    setDocs(uniqueDocs);
      // console.log(allDocs)
  });
  }, []);

  useEffect(() => {
    if (tdata.length > 0) {
      let totalRev = 0;
      let totalRevShare = 0;
      // console.log(tdata)
      tdata.forEach(report => {
        report.products.forEach(product => {
          totalRev += Number(product.finalPrice);
          totalRevShare += Number(product.revenueShare);
        });
      });
      setTotalRevenue(totalRev.toFixed(2));
      setTotalRevenueShare(totalRevShare.toFixed(2));
    }
  }, [tdata]);

  const flattenedData = tdata.flatMap(report =>
    report.products.map(product => ({
      _id: report._id,
      month: returnMonthFromIndex(Number(report.month)),
      year: report.year,
      stockist: report.stockist.businessName,
      chemist: report.chemist.businessName,
      doctor: report.doctor.name,
      product: product, // Include all properties of the product here
      price: product.price,
      quantity: product.quantity,
      finalPrice: product.finalPrice,
      revenueShare: product.revenueShare
    }))
  );

  // console.log(flattenedData)

  const columns = useMemo(
    () => [
      {
        Header: "Stockist",
        accessor: "stockist",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Chemist",
        accessor: "chemist",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Doctor",
        accessor: "doctor",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Product Name",
        accessor: "product",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        placeholderTotal: "Total",
        Cell: (props) => {
          // console.log(props?.row?.original?.product?.product);
          return (
            props?.row?.original?.product?.product.isFixed ?
            <span>
              {props?.row?.original?.product?.product.name} <br />
              [{props?.row?.original?.product?.product.fixedAmount}]
            </span> :
            <span>
              {props?.row?.original?.product?.product.name} <br />
              [{props?.row?.original?.product?.product.percentage}%]
            </span>
          )
        },
      },
      // {
      //   Header: "Percentage",
      //   accessor: "product.product.percentage",
      //   disableFilters: true,
      //   disableSortBy: true,
      // },
      {
        Header: "Month",
        accessor: "month",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Price",
        accessor: "product.price",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "price",
      },
      {
        Header: "Quantity",
        accessor: "product.quantity",
        disableSortBy: true,
        disableFilters: true,
        showTotal: true,
        totalAccessor: "quantity",
      },
      {
        Header: "Final Price",
        accessor: "product.finalPrice",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "finalPrice",
      },
      {
        Header: "Revenue Share",
        accessor: "product.revenueShare",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "revenueShare",
      },
    ],
    []
  );

  // const tableData = useMemo(() => serialise(tdata), [tdata]);


  const SubmitHandler = async (e) => {
    try {
      e.preventDefault();

      if (doctor === "" || fromMonth === "" || toMonth === "") {
        return customToast.error("Please fill all the fields");
      }
      // get data
      viewProductSaleReport({docId: doctor._id, range:selectedRange}).then((response) => {
        setTdata(response?.data);
      }).catch(e => {
        console.log(e)
        return customToast.error("No result found")
      });
      viewPayment({docId: doctor._id, range:selectedRange}).then((response) => {
        setPaymentData(response?.data);
      }).catch(e => console.log(e));
    } catch (err) {
      return customToast.error("An unexpected error occured");
    }
  };

  // useEffect(() => {
  //   console.log(paymentData); // Log paymentData here
  // }, [paymentData]);

  useEffect(() => {
    const calculateTotalPayments = (data) => {
      return data.reduce((total, payment) => {
        return total + (payment.amount || 0);
      }, 0);
    };
      let total = calculateTotalPayments(paymentData);
      setPaidAmount(total.toFixed(2))
      let totalPendingAmount = totalRevenueShare - total;
      setPendingAmount((totalPendingAmount).toFixed(2))
  }, [paymentData, totalRevenueShare]);

  const navigate = useNavigate()

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
      <section className="admin-creation-content__heading">
          <MdArrowBackIosNew
          style={{cursor:"pointer"}}
          onClick={() => navigate("/crm")}/>
          <h2 className="web-app__heading">Doctor Revenue Share</h2>
          <div style={{color:"var(--color-tertiary)", marginLeft:"auto", cursor:"pointer"}} >
           <a href="https://youtu.be/HMa_5DZUVvs" style={{color:"var(--color-tertiary)",cursor:"pointer", textDecoration: "none"}} target="_blank"> How does it works ?</a>
            </div>
        </section>
        {/* <PageTitle title="Doctor Revenue Share" isCRM={true} isCRMGuide={true} /> */}
        {/* <div style={{width: "100%", textAlign : "end" , paddingTop: "1rem" ,paddingRight: "4rem"}}>
             <a href="https://www.youtube.com/" target="_blank">How does it works ? </a>
          </div> */}
        <section className="area-creation-content__info">
          <form onSubmit={SubmitHandler}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-3">
                <label htmlFor="stockist">From Month</label>
                <br />
                <Suspense>
                  <CascadingMenu items={items}
                    title="Select Month"
                    month={fromMonth}
                    year={fromYear}
                    onClick={handleMenuClick1}
                  />
                </Suspense>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3">
                <label htmlFor="stockist">To Month</label>
                <br />
                <Suspense>
                  <CascadingMenu items={items}
                    title="Select Month"
                    month={toMonth}
                    year={toYear}
                    onClick={handleMenuClick2}
                  />
                </Suspense>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3">
                <label htmlFor="doctor">Select Doctor</label>
                <br />
                <Select
                  name="doctor"
                  id="doctor"
                  value={doctor}
                  options={doctorSelect}
                  onChange={(e) => setDoctor({ ...e })}
                  styles={adminStyles}
                  placeholder="Select Doctor"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-4 admin-creation-content__form-submit mt-5">
                <button type="submit" className="button-blue-gradient">
                  Submit
                </button>
              </div>
            </div>
          </form>

          {
            tdata.length > 0 ?
              (
                <div className="my-4">
                  {/* <h4 className="my-4">Docter's report For {month} 2024</h4> */}
                  <div className={styles.final_report_boxes}>

                    <div className={styles.final_report_box1}>
                      <div className={styles.row1}>{doctor.name}</div>

                      <div className={styles.row2}>
                        <div className={styles.left}>
                          <div className={styles.rowBold} >Rs. <br/> {totalRevenue}</div>
                          <div>TOTAL REVENUE</div>
                        </div>
                        <div className={styles.right}>
                          <div className={styles.rowBold} >Rs. <br/> {totalRevenueShare}</div>
                          <div>REVENUE SHARE</div>
                        </div>
                      </div>

                    </div>
                    <div className={`${styles.final_report_box1} ${styles.final_report_box2}`}>
                      <div className={styles.row1}>Payment History</div>

                      <div className={styles.row2}>
                        <div className={styles.left}>
                          <div className={styles.rowBold} >Rs. <br/> {paidAmount}</div>
                          <div>PAID AMOUNT</div>
                        </div>
                        <div className={styles.right}>
                          <div className={styles.rowBold} >Rs. <br/> {pendingAmount}</div>
                          <div>PENDING AMOUNT</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="filter-table pe-4">
                    {flattenedData?.length > 0 && (
                      <h2 className="web-app__heading mb-4 ms-2">
                        Showing ({flattenedData?.length}) Entries
                      </h2>
                    )}
                    <Table columns={columns} data={flattenedData} />
                  </div>
                </div>
              ) :
              null
          }

        </section>
        {/* {showEdit ? <EditPopup /> : null} */}
      </div>
    </main>
  )

}

export default Business;