import { useLocation } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { viewUsersWithTps } from "../../../services/utilities";
import { FaUser } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaBoxesStacked } from "react-icons/fa6";
import { GiMedicines } from "react-icons/gi";
import { Link } from "react-router-dom";
// import { ReactComponent as DoctorIcon } from '../../public/img/SVG/Detailspng/DoctorIcon.svg';
// import { ReactComponent as ChemistIcon } from '../../public/img/SVG/DetailsSvg/ChemistIcon.svg';
// import { ReactComponent as StockIcon } from '../../public/img/SVG/DetailsSvg/StockIcon.svg';
import DoctorIcon from '../../public/img/SVG/DetailsPng/DoctorIcon.png'
import ChemistIcon from '../../public/img/SVG/DetailsPng/ChemistIcon.png'
import StockIcon from '../../public/img/SVG/DetailsPng/StockIcon.png'


const TourDetails = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    const da = async () => {
      let res = await viewUsersWithTps();
      setData(res?.data);
    };
    da();
  }, []);

  return (
    <div className="utility-inner-tp">
      <div className="row">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
        >
          <div>
            <MdArrowBackIosNew
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <span style={{ display: "inline-block", margin: "0 1rem" }}>
              Today's Activity
            </span>
          </div>
        </h2>

        <div className="util-inner-details" style={{ marginTop: "3rem" }}>
          <div className="util-inner-info-box-container" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
            {data.map((el) => (
              <div className="util-inner-info-box" key={el?.user?._id}>
                <Link to={`./${el?.user?._id}`} style={{ textDecoration: "none", width: "100%" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <FaLocationDot className="icon-color-error" style={{ marginRight: "1rem" }} />
                        <h3 style={{ marginRight: "1rem" }}>{`${el?.user?.firstName} ${el?.user?.lastName}`}</h3>
                      </div>
                      {el?.tps.length > 0 && (
                        <div style={{ color: "white" }}>
                          {`(${el?.tps[0]?.areaType})`}
                        </div>
                      )}
                    </div>
                    <div style={{ marginTop: "1rem", display: "flex", flexDirection: "column", gap: "1rem" }}>
                      {el?.tps.length > 0 ? (
                        <p style={{ color: "yellow" }}>
                          {el?.tps.map((it) => (
                            it?.workAreas.map((z) => (
                              <div key={z?.location?.label}>
                                {z?.location?.label}
                              </div>
                            ))
                          ))}
                        </p>
                      ) : el?.isLeave ? (
                        <p style={{ color: "skyBlue" }}>Leave: {el?.leaveReason}</p>
                      ) : (
                        <p style={{ color: "red" }}>No TP found</p>
                      )}
                      {el?.tps.length > 0 && (
                        <div className="report-container">
                          <div className="report-box">
                            <img style={{ width: "3rem", height: "3rem" }} src={DoctorIcon} alt="Doctor Icon" />
                            <span className="report-count">{el?.docreports}</span>
                          </div>
                          <div className="report-box">
                            <img style={{ width: "3rem", height: "3rem" }} src={ChemistIcon} alt="Chemist Icon" />

                            <span className="report-count">{el?.chemreports}</span>
                          </div>
                          <div className="report-box">
                            <img style={{ width: "3rem", height: "3rem" }} src={StockIcon} alt="Stock Icon" />

                            <span className="report-count">{el?.stocreports}</span>
                          </div>
                        </div>

                      )}
                      {el?.tps.length > 0 && el.tps.map(tpsItem => (
                        tpsItem.workedWith && tpsItem.workedWith.length > 0 ? (
                          <div key={tpsItem._id}>
                            <p>Worked with:</p>
                            <ul>
                              {tpsItem.workedWith.map(workedWithItem => (
                                <li key={workedWithItem._id} style={{ color: "whitesmoke" }}>
                                  {workedWithItem.firstName}
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : null
                      ))}
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourDetails;

//back to this 2