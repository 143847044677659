import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import { useLocation,useNavigate } from "react-router-dom";
import { RiCloseCircleLine } from "react-icons/ri";
import { BsCheck2Circle, BsXCircle } from "react-icons/bs";
import customToast from "../../../../../../components/CustomToast";
import PageTitle from "../../../../../../components/PageTitle";
import { setCities } from "../../../../../../reducers/locations/cityReducer";
import { clearHeadquarters, setHeadquarters } from "../../../../../../reducers/locations/hqReducer";
import { generateSelectData } from "../../../../../../utils/serialiseResponse";
import { deleteStockist, editStockist } from "../../../../../../reducers/targets/stockist";
import { formatDate } from "../../../../../../utils/helper";
import { adminStyles } from "../../../../../public/stylesheets/selectStyles";

const CreationEditStockist = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const cities = useSelector(({ city }) => city);

  const [businessName, setBusinessName] = useState("");
  const [name, setName] = useState("");
  const [certification, setCertification] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [drugLicenseNumber, setDrugLicenseNumber] = useState("");
  const [drugExpiryNumber, setDrugExpiryNumber] = useState("");
  const [birthday, setBirthday] = useState("");
  const [stockistContact, setStockistContact] = useState("");
  const [address, setAddress] = useState("");
  const [headquarter, setHeadquarter] = useState();
  const [city, setCity] = useState();
  const [showEdit,setShowEdit] = useState(false);
  useEffect(() => {
    setBusinessName(location.state?.businessName);
    setName(location.state?.name);
    setCertification(location.state?.certification);
    setGstNumber(location.state?.gstNumber);
    setDrugLicenseNumber(location.state?.drugLicenseNumber);
    setDrugExpiryNumber(location.state?.drugExpiryNumber);
    setBirthday(location.state?.establishmentDate);
    setStockistContact(location.state?.stockistContact);
    setAddress(location.state?.address);
    setHeadquarter({value:location?.state?.city?._id, label: location?.state?.city?.name});
    setCity({value:location?.state?.workingArea?._id, label: location?.state?.workingArea?.name});
    // setHeadquarter({value:headquarter?.value, label: headquarter?.label});
    // setCity({value:city?.value, label: city?.label});
  }, []);

  useEffect(() => {
    dispatch(setHeadquarters());
    return ()=> {dispatch(clearHeadquarters());}
  }, [dispatch]);

  useEffect(() => {
    if (!headquarter) return;

    dispatch(setCities(headquarter?.value));
  }, [dispatch, headquarter]);

  const hqSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );
  const citySelect = useMemo(
    () => generateSelectData(cities.data, "name"),
    [cities]
  );
  const handleSubmit = async (e) => {
    e.preventDefault();

    let error = false;
    try {

      if (
        !name ||
        !businessName ||
        !stockistContact ||
        !address ||
        !headquarter ||
        !city
      )
      return customToast.error("Please fill all the required details");
console.log(name, businessName,stockistContact , address, headquarter, city)

      let data = {
        businessName,
        name,
        certification,
        gstNumber,
        drugLicenseNumber,
        drugExpiryNumber,
        establishmentDate: birthday,
        stockistContact: stockistContact,
        address: address,
        city: headquarter?.value,
        workingArea: city?.value,
      };

      await dispatch(editStockist(location.state?._id, data));
    } catch (e) {
      error = true;
      customToast.error("We Encountered an Error");
    }

    if (!error) {
      customToast.success("Stockist Edited Successfully");

      setBusinessName("");
      setName("");
      setCertification("");
      setGstNumber("");
      setDrugLicenseNumber("");
      setDrugExpiryNumber("");
      setBirthday("");
      setStockistContact("");
      setAddress("");
      setHeadquarter({value:headquarter?.value, label: headquarter?.label});
      setCity({value:city?.value, label: city?.label});
      navigate(-1);
    }
  };
  const EditPopup = () => {
    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  Are you sure you want to delete?
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button
              
                type="button"
                onClick={() => {
                  dispatch(deleteStockist(location?.state?._id));
                  navigate(-1);
                }}
                className="button-delete mt-4"
              >
                <span className="button-delete__icon-container">
                  <BsXCircle className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>
              <button
                type="button"
                onClick={() => setShowEdit(false)}
                className="button-submit-green mt-4"
              >
                <span className="button-submit-green__icon-container">
                  <BsCheck2Circle className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Cancel</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };
  return (
    <main className="main-content admin-content">
      <div className="admin-creation-content">
        <PageTitle title={`Edit Stockist ${location?.state?.businessName}`} hierarchy={true}/>

        <section className="admin-creation-content__form">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="businessName">
                  Business Name <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  type="text"
                  className="me-5"
                  id="businessName"
                  placeholder="Enter Stockist's Name"
                  name="businessName"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="name">
                  Name <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  className="me-5"
                  id="name"
                  placeholder="Enter Stockist's Name"
                  name="name"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="certifications">
                  Certifications / Trademarks{" "}
                </label>
                <br />
                <input
                  value={certification}
                  onChange={(e) => setCertification(e.target.value)}
                  type="text"
                  className="me-5"
                  id="certifications"
                  placeholder="Enter Certification"
                  name="certifications"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                <label htmlFor="gst">GST Number </label>
                <br />
                <input
                  value={gstNumber}
                  onChange={(e) => setGstNumber(e.target.value)}
                  type="text"
                  className="me-5"
                  id="gst"
                  placeholder="Enter GST"
                  name="gst"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                <label htmlFor="drugLicenseNumber">Drug License Number </label>
                <br />
                <input
                  value={drugLicenseNumber}
                  onChange={(e) => setDrugLicenseNumber(e.target.value)}
                  type="text"
                  className="me-5"
                  id="drugLicenseNumber"
                  placeholder="Enter Drug License Number"
                  name="drugLicenseNumber"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                <label htmlFor="drugExpiry">Drug Expiry Number </label>
                <br />
                <input
                  value={drugExpiryNumber}
                  onChange={(e) => setDrugExpiryNumber(e.target.value)}
                  type="text"
                  className="me-5"
                  id="drugExpiry"
                  placeholder="Enter Drug Expiry"
                  name="drugExpiry"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                <label htmlFor="birthday">Establishment Date <span className="admin-highlighted-span">
                    {formatDate(location?.state?.establishmentDate)}
                  </span></label>
                <br />
                <input
                  type="date"
                  className="me-5"
                  id="birthday"
                  onChange={(e) => setBirthday(e.target.value)}
                  value={birthday}
                  name="birthday"
                />{" "}
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                <label htmlFor="contact">
                  Stockist's Contact Number{" "}
                  <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  value={stockistContact}
                  onChange={(e) => setStockistContact(e.target.value)}
                  type="phone"
                  maxlength="10"
                  pattern="[0-9]*"
                  className="me-5"
                  id="contact"
                  placeholder="Enter Alternate Number"
                  name="clinicContact"
                />
              </div>
              {/* {loggedIn?.user?.des < 121 ||
              loggedIn?.user?.isAdmin ? (
                <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                  <label htmlFor="mrAllot">
                    Select MR to Allot <span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <Select
                    name="mrAllot"
                    id="mrAllot"
                    value={selectedUser}
                    options={employeeSelect}
                    onChange={(e) => setSelectedUser({ ...e })}
                    styles={adminStyles}
                    placeholder="Select MR"
                  />
                </div>
              ) : null} */}
              <div className="col-sm-12 col-md-12 col-lg-12 mt-5">
                <label htmlFor="address">
                  Stockist's Address <span className="asterisk-imp">*</span>
                </label>
                <br />
                <textarea
                  name="address"
                  id="address"
                  cols="30"
                  rows="5"
                  placeholder="Enter Clinic Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                ></textarea>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                  <label htmlFor="hq">
                    Select HQ <span className="asterisk-imp">*</span> :{" "}
                    <span className="admin-highlighted-span">
                      {location.state?.city?.name}
                    </span>
                  </label>
                  <br />
                  <Select
                    name="headquarter"
                    id="headquarter"
                    value={headquarter}
                    options={hqSelect}
                    onChange={(e) =>
                      setHeadquarter({ value: e.value, label: e.label })
                    }
                    styles={adminStyles}
                    placeholder={`${location.state?.city?.name}`}
                  />
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                  <label htmlFor="city">
                    Select Working Area <span className="asterisk-imp">*</span> :{" "}
                    <span className="admin-highlighted-span">
                      {location.state?.workingArea?.name}
                    </span>
                  </label>
                  <br />
                  <Select
                    name="city"
                    id="city"
                    value={city}
                    options={citySelect}
                    onChange={(e) =>
                      setCity({ value: e.value, label: e.label })
                    }
                    styles={adminStyles}
                    placeholder={`${location.state?.workingArea?.name}`}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between flex-wrap mt-4 admin-creation-content__form-submit d-flex flex-wrap">
                <button type="submit" className="button-submit-green mt-5">
                  <span className="button-submit-green__icon-container">
                    <BsCheck2Circle className="button-submit-green__icon" />
                  </span>
                  <span className="button-submit-green__text">Edit</span>
                </button>
                
              </div>
            </div>
          </form>
        </section>
        {showEdit?<EditPopup/>:null}
      </div>
    </main>
  );
};

export default CreationEditStockist;
