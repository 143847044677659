import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../reducers/userReducer";
import customToast from "../CustomToast";

const LockPopup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clickHandler = () =>{
    try{
        dispatch(logoutUser())
        navigate("/login")
    }
    catch(err){
        customToast.error("An error occured")
    }
  }
  return (
    <section className="edit-popup">
      <div className="edit-content d-flex flex-column align-items-center">
        <h2 className="web-app__heading lh-base">
          Your Account has been <span className="text-danger fw-bold">LOCKED</span>, please contact your admin
        </h2>

        <div className="edit-form-button-container mt-5">
          <button onClick={clickHandler} className="button-blue-gradient">
            Logout
          </button>
        </div>
      </div>
    </section>
  );
};

export default LockPopup;
