export const title = "Manage Expenses";

export const links = [
  {
    name: "Travel Allowance",
    route: "travel-allowances",
    accessor: "travel-allowances",
  },
  // {
  //   name: "View Travel Allowance",
  //   route: "view-ta",
  //   accessor: "view-ta",
  // },
  {
    name: "Out Station Allowance",
    route: "out-station-allowances",
    accessor: "out-station-allowances",
  },
  {
    name: "Rates",
    route: "rates",
    accessor: "rates",
  },
  // {
  //   name: "View Out Station Allowance",
  //   route: "view-os-allowances",
  //   accessor: "view-os-allowances",
  // },
];
