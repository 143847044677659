import { MdArrowBackIosNew, MdOutlineGroup } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import react, { useMemo } from "react";
import serialise from "../../../../../../../utils/serialiseResponse";
import Table from "../../../../../../../components/Table";
import { FaEye } from "react-icons/fa";
import { addIndex } from "../../../../../../../utils/serialiseResponse";
import { viewUtilTargets } from "../../../../../../../services/utilities";

const UserTarget = ({ employeeDataForExcel, user, reports, month, year, setReports, setSelectedUser, setreportData }) => {
  
  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   minWidth: 50,
      //   maxWidth: 50,
      //   disableFilters: true,
      // },
      {
        Header: "Name",
        accessor: "user.firstName"
      },
      {
        Header: "Target type",
        accessor: "targetType.label"
      },
      {
        Header: "LumpSum Target",
        accessor: "lumpAmount"
      },
      {
        Header: "Products Target",
        accessor: "totalAmount"
      },
      {
        Header: "View",
        accessor: "4",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
        Cell: (props) => {
          return (
            <button
              hidden={props?.row?.original?.lumpAmount!='NA'}
              onClick={(e) => { createSelectedUserObject(props.row.original) }}
              className="react-table-view-link bg-transparent border-0"
            >
              <FaEye className="icon-color-tertiary" />
            </button>
          );
        },
      },
    ],
    [month, year, user, ]
  );
  const column2 = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   minWidth: 50,
      //   maxWidth: 50,
      //   disableFilters: true,
      // },
      {
        Header: "Product",
        accessor: "productName",
        disableSortBy: true,
        showTotal: true,
        placeholderTotal: "Total",
      },
      {
        Header: "Amount",
        accessor: "amount",
        disableFilters: true,
        showTotal: true,
        totalAccessor: "amount",
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        disableFilters: true,
        showTotal: true,
        totalAccessor: "quantity",
      },
    ],
    [month, year, user]
  );

  const createSelectedUserObject = async (userData) => {
    // console.log(userData)
    if (!userData?.user) {
      return;
    }
    const selectedUserObject = {
      label: userData.user.firstName,
      value: userData.user._id,
      designation: userData.user.des,
    };
    setSelectedUser(selectedUserObject);

    const filter = {
      userId: userData?.user._id,
      month: month?.value || userData.month,
      year: year?.label || userData.year,
    };
// console.log(filter)
    await viewUtilTargets("user", filter).then((res) => {
      if (res.data) {
        setreportData(res.data);
        setReports(res?.data[0]?.products);
      }
    });
  };
  
  const tableData = useMemo(() => addIndex(reports), [reports]);
  return (
    <div className="filter-table">
      {reports?.length > 0 && (
        <h2 className="web-app__heading mb-4 ps-3">
          Showing ({reports?.length}) Entries
        </h2>
      )}
      {tableData?.length > 0 ? (
        <Table columns={user ? column2 : columns} data={tableData} fileSrc="Target Report" empl={employeeDataForExcel} month={month} />
      ) : (
        <h3 className="mt-2 no-results-message">No Product-Wise targets found</h3>
      )}
    </div>

  );
};

export default UserTarget;