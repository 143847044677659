import {
  deleteProduct,
  deleteProductCategory,
  editProduct,
  editProductCategory,
  newProduct,
  newProductCategory,
  viewProductCategory,
  viewProducts,
} from "../../services/products.js";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PRODUCT_CATEGORY":
      return action.payload;

    case "CREATE_PRODUCT_CATEGORY":
      return { data: [...state.data, action.payload.data.data] };

    case "CLEAR_PRODUCT_CATEGORY":
      return initialState;

    case "EDIT_PRODUCT_CATEGORY":
      // return { ...state, newState: action.payload };
      return { data: state.data };

    case "DELETE_PRODUCT_CATEGORY":
      // return action.payload;
      return { data: state.data };

    default:
      return state;
  }
};

export const setProductCategories = () => {
  return async (dispatch) => {
    // const { data } = await viewProducts("category");
    const { data } = await viewProductCategory();

    dispatch({
      type: "SET_PRODUCT_CATEGORY",
      payload: { data: data },
    });
  };
};

export const addProductCategory = (data) => {
  return async (dispatch) => {
    try {
      // data = await newProduct("category", data);
      data = await newProductCategory(data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "CREATE_PRODUCT_CATEGORY",
      payload: { data: data },
    });
    dispatch(setProductCategories());
  };
};

export const clearProductCategories = () => {
  return {
    type: "CLEAR_PRODUCT_CATEGORY",
  };
};

export const editProductCategories = (id, data) => {
  return async (dispatch) => {
    try {
      data = await editProductCategory(id, data);
      // data = await editProduct("category", id, data);
    } catch (e) {
      throw e;
    }

    dispatch({
      type: "EDIT_PRODUCT_CATEGORY",
      payload: { data: data },
    });
    dispatch(setProductCategories());
  };
};

export const deleteProductCategories = (data) => {
  return async (dispatch) => {
    try {
      // data = await deleteProduct("category", data);
      data = await deleteProductCategory(data);
    } catch (e) {
      if (data.error) throw data.error;
    }
    dispatch({
      type: "DELETE_PRODUCT_CATEGORY",
      payload: { data: data },
    });
    dispatch(setProductCategories());
  };
};

export default reducer;
