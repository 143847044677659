import { useLocation } from "react-router-dom";
import PageTitle from "../../../../../components/PageTitle";
import { formatDate } from "../../../../../utils/helper";
import { getDesignation } from "../../../../../utils/serialiseResponse";
import { useState, useEffect } from "react";
import { viewSpecificUser } from "../../../../../services/users";
import customToast from "../../../../../components/CustomToast";

const AdminUserInfoDetail = () => {
  const location = useLocation();
  const [employee, setEmployee] = useState({});

  useEffect(() => {
    viewSpecificUser(location?.state._id)
      .then(({ data }) => {
        setEmployee(data);
        console.log(data);
      })
      .catch((err) => customToast.error("Sorry! failed to load user details"));
  }, [location]);

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="User Details" hierarchy={true} />
      </div>
      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>First Name</h4>
          <p>{employee?.firstName}</p>
        </div>

        <div className="user-detail-box">
          <h4>Middle Name</h4>
          <p>{employee?.middleName}</p>
        </div>

        <div className="user-detail-box">
          <h4>Last Name</h4>
          <p>{employee?.lastName}</p>
        </div>

        <div className="user-detail-box">
          <h4>Email Address</h4>
          <p>{employee?.email}</p>
        </div>

        <div className="user-detail-box">
          <h4>Phone Number</h4>
          <p>{employee?.phone}</p>
        </div>
      </section>

      <section className="user-detail-container user-detail-container-yellowDark-border">
        <div className="user-detail-box">
          <h4>Date of Birth</h4>
          <p>{formatDate(employee?.birthDate)}</p>
        </div>

        <div className="user-detail-box">
          <h4>Date of Joining</h4>
          <p>{formatDate(employee?.joiningDate)}</p>
        </div>

        <div className="user-detail-box">
          <h4>Headquarter</h4>
          <p>{employee?.headquarter?.name}</p>
        </div>

        <div className="user-detail-box">
          <h4>Division</h4>
          <p>{employee?.division?.name}</p>
        </div>

        <div className="user-detail-box">
          <h4>employee? ID</h4>
          <p>{employee?.employeeId}</p>
        </div>

        <div className="user-detail-box">
          <h4>Designation</h4>
          <p>{getDesignation(employee?.des)}</p>
        </div>

        <div className="user-detail-box">
          <h4>Reporting Manager</h4>
          <p>
          {employee?.reportingManager?.firstName ? employee?.reportingManager?.firstName + " " + employee?.reportingManager?.lastName : "Admin"}
          </p>
        </div>

        <div className="user-detail-box">
          <h4>Aadhaar Number</h4>
          <p>{employee?.aadharNumber}</p>
        </div>

        <div className="user-detail-box">
          <h4>PAN Number</h4>
          <p>{employee?.panNumber}</p>
        </div>
        <div className="user-detail-box">
          <h4>Daily Allowance</h4>
          <p>{employee?.dailyAllowance}</p>
        </div>
        <div className="user-detail-box">
          <h4>EX Allowance</h4>
          <p>{employee?.exStationAllowance}</p>
        </div>
        <div className="user-detail-box">
          <h4>OUT Allowance</h4>
          <p>{employee?.outStationAllowance}</p>
        </div>
      </section>

      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Address</h4>
          <p>
            {employee?.address?.street1
              ? employee?.address?.street1 + ", "
              : null}
            {employee?.address?.street2}
          </p>
        </div>
        <br />

        <div className="user-detail-box">
          <h4>City</h4>
          <p>{employee?.address?.city}</p>
        </div>

        <div className="user-detail-box">
          <h4>State</h4>
          <p>{employee?.address?.state}</p>
        </div>
      </section>
    </div>
  );
};

export default AdminUserInfoDetail;
