import { Outlet } from "react-router-dom";
import AdminNav from "../AdminSidenav";
import { title, links } from "./navData.js";

import "../../public/stylesheets/admin-locations.css";
import { viewCallReport, viewCallReportForApproval } from "../../../services/dcr";
import { viewTargetsForApproval } from "../../../services/targets";
import { viewLocationsForApproval } from "../../../services/locations";
import { getExpenseForApproval } from "../../../services/expenses";
import { viewLeavesForApproval } from "../../../services/leaveRequest";
import { viewTPForApproval, viewTPWithStatus } from "../../../services/tourProgram";
import { viewSSForApproval, viewPSForApproval } from "../../../services/sales";
import { useState, useEffect } from "react";
import {useSelector} from "react-redux";
import { viewGiftsForapproval, viewSampleForApproval } from "../../../services/reports.js";
import { getAllDeletionRequest } from "../../../services/deletionRequest.js";
import { getGeoLocationApprovalData } from "../../../services/geoFencing.js";
const Approvals = () => {
  const loggedIn = useSelector(({ user }) => user);
  const [data, setData] = useState([]);

  let approvalLength = useSelector(state=>state.approvalLength.trigger)
  const callReports = async () => {
    return await viewCallReportForApproval({ viewForApproval: true });
  };
  const chemists = async () => {
    return await viewTargetsForApproval("chemist");
  };
  const city = async () => {
    return await viewLocationsForApproval("city");
  };
  const doctors = async () => {
    return await viewTargetsForApproval("doctor");
  };
  const expense = async () => {
    return await getExpenseForApproval();
  };
  const leaveRequests = async () => {
    return await viewLeavesForApproval();
  };
  const route = async () => {
    return viewLocationsForApproval("route");
  };
  const stockists = async () => {
    return await viewTargetsForApproval("stockist");
  };
  const tp = async () => {
    return await viewTPForApproval();
  };
  const secondarySalesForApproval = async ()=>{
    return await viewSSForApproval();
  }
  const primarySalesForApproval = async ()=>{
    return await viewPSForApproval();
  };
  const giftsApproval = async ()=>{
    return await viewGiftsForapproval();
  };
  const samplesApproval = async ()=>{
    return await viewSampleForApproval();
  };
  const deletionRequest = async ()=>{
    return await getAllDeletionRequest();
  }
  const geoFencingApproval = async ()=>{
    let flag = {
      submitterId: loggedIn?.user?._id,
      viewForPending: true,
    };
    return await getGeoLocationApprovalData({ flag: flag });
  }
  let apiArray = [
    callReports,
    tp,
    expense,
    leaveRequests,
    doctors,
    chemists,
    stockists,
    city,
    route,
    secondarySalesForApproval,
    primarySalesForApproval,
    samplesApproval,
    giftsApproval,
    geoFencingApproval,
    deletionRequest,
  ];
  const parallelApiCall = async () => {
    let response = await Promise.all([
      apiArray[0](),
      apiArray[1](),
      apiArray[2](),
      apiArray[3](),
      apiArray[4](),
      apiArray[5](),
      apiArray[6](),
      apiArray[7](),
      apiArray[8](),
      apiArray[9](),
      apiArray[10](),
      apiArray[11](),
      apiArray[12](),
      apiArray[13](),
      apiArray[14](),
    ]);
    setData(response);
  };
  
  const updateApprovalLength = () =>{
   let updatedArray = data.map((e,i)=>{
    if(i === approvalLength.index){
      return {...e,data:approvalLength.data};
    }
    return e;
   })
   setData(updatedArray)
  }

 
  useEffect(() => {
   updateApprovalLength() 
  }, [approvalLength]);
  

  useEffect(() => {
    parallelApiCall();
  }, []);

  return (
    <>
      <AdminNav title={title} links={links} response={data}/>

      <Outlet />
    </>
  );
};

export default Approvals;
