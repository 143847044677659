export const title = "Manage CRM";

export const links = [

  {
    name: "Business",
    route: "business",
    accessor: "business",
  },
  {
    name: "Payments",
    route: "payments",
    accessor: "payments",
  },
];
