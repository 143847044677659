import React, { useState } from 'react';
import queryString from 'query-string';
import { passwordReset } from '../../../services/user.js';

import {
	SectionLogin,
	BtnBlue,
	FormInput,
	FormLabel,
	LoginLeft,
	LoginRight,
	LoginForm,
	FormGroup,
	LoginImgInner,
	LoginImgOuter,
	AuthError,
} from '../../public/stylesheets/authentication.js';
import InnerDots from '../../public/img/inner-dots.png';
import OuterDots from '../../public/img/outer-dots.png';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import customToast from '../../../components/CustomToast/index.js';

const PasswordReset = ({ loggedUser }) => {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (loggedUser) navigate('/dashboard');
	}, [loggedUser, navigate]);

	const [token, setToken] = useState(null);
	const [id, setId] = useState(null);

	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const [passwordsMatch, setPasswordsMatch] = useState(null);

	useEffect(() => {
		const parsed = queryString.parse(location.search);

		setToken(parsed.token);
		setId(parsed.id);
	}, [location.search]);

	const handleBlur = async (e) => {
		if (!confirmPassword) return;

		if (password !== confirmPassword) setPasswordsMatch(false);
		else setPasswordsMatch(true);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (passwordsMatch) {
			let error = null;

			try {
				passwordReset(token, id, password);
			} catch (e) {
				error = e;
				customToast.error(e.message);
			}

			if (!error) {
				customToast.success('Password Changed Successfully');
				setTimeout(() => {
					navigate('/login');
				}, 5000);
			}
		}
	};

	const renderPass = () => (
		<LoginForm
			id="reset-form"
			className="section-login__form"
			onSubmit={(e) => handleSubmit(e)}
		>
			<FormGroup>
				<FormLabel htmlFor="password">New Password</FormLabel>
				<br />
				<FormInput
					type="password"
					id="password"
					name="password"
					placeholder="New Password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
			</FormGroup>

			<FormGroup>
				<FormLabel htmlFor="email">Confirm New Password</FormLabel>
				<br />
				<FormInput
					type="password"
					id="confirmPassword"
					name="confirmPassword"
					placeholder="Confirm New Password"
					value={confirmPassword}
					onChange={(e) => setConfirmPassword(e.target.value)}
					onBlur={handleBlur}
				/>
			</FormGroup>

			{passwordsMatch === false && (
				<AuthError>The Passwords don't match</AuthError>
			)}

			<BtnBlue type="submit" className="mt-5">
				Reset Password
			</BtnBlue>
		</LoginForm>
	);

	return (
		<main>
			<SectionLogin>
				<div className="container">
					<div className="row" style={{ minHeight: '75rem' }}>
						<LoginLeft className="col-md-6">
							<h1>Medorn</h1>
							<h2>Password Reset</h2>
							<p>DCR | AI In the house | Cybersecurity in the house</p>

							<LoginImgInner src={InnerDots} alt="Inner Dots" />
						</LoginLeft>

						<LoginRight className="col-md-6">
							<h2>Medorn</h2>
							<p>Reset your Medorn log-in password</p>

							{renderPass()}
						</LoginRight>
					</div>
				</div>
			</SectionLogin>

			<LoginImgOuter src={OuterDots} alt="Dots" />
		</main>
	);
};

export default PasswordReset;
