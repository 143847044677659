import { lazy, Suspense, useEffect, useMemo, useState } from "react";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";
import { viewAllPayment} from "../../../../services/targets";
import { items } from "../../../../utils/helper";
import { FiDownload } from "react-icons/fi";
// import CascadingMenu from "../../CascadingMenu";
const CascadingMenu = lazy(() => import('../../CascadingMenu'));

const PaymentReport = () => {
  const [fromMonth, setFromMonth] = useState("");
  const [toMonth, setToMonth] = useState("");
  const [fromYear, setFromYear] = useState("");
  const [toYear, setToYear] = useState("");
  const [selectedRange, setSelectedRange] = useState({})
  const [paymentData, setPaymentData] = useState([])

  const handleMenuClick1 = ({ keyPath }) => {
    // console.log(keyPath)
    const [monthYearString] = keyPath;
    const [month, year] = monthYearString.split('-');
    setFromMonth(`${month}`);
    setFromYear(`${year}`);
    setSelectedRange({...selectedRange, fromMonth:month, fromYear:year})
  };
  const handleMenuClick2 = ({ keyPath }) => {
    // console.log(keyPath)
    const [monthYearString] = keyPath;
    const [month, year] = monthYearString.split('-');
    setToMonth(`${month}`);
    setToYear(`${year}`);
    setSelectedRange({...selectedRange, toMonth:month, toYear:year})
  };

    const tdata = paymentData.flatMap(doctor => {
        return doctor.payment.map(payment => {
          return {
            doctorId: doctor._id,
            doctorName: doctor.name,
            paymentId: payment._id,
            paymentDate: new Date(payment.date).toLocaleDateString('en-GB'),
            paymentAmount: payment.amount,
            paymentRemark: payment.remark || "--",
            paymentReceipt : payment.image[0]
          };
        });
      });

  const columns = useMemo(
    () => [
      {
        Header: "Doctor",
        accessor: "doctorName",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Payment Date",
        accessor: "paymentDate",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Paid Amount",
        accessor: "paymentAmount",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Remark",
        accessor: "paymentRemark",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Attachment",
        accessor: "paymentReceipt",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
            // console.log(props?.row?.original.paymentReceipt)
            return <a href={props?.row?.original.paymentReceipt} download>
                <FiDownload />
            </a>
           
        }
      },
    ],
    []
  );

  // const tableData = useMemo(() => serialise(tdata), [tdata]);


  const SubmitHandler = async (e) => {
    try {
      e.preventDefault();

      if (fromMonth === "") {
        return customToast.error("Select the starting month");
      } else if (toMonth === "") {
        return customToast.error("Select the ending month");
      }
      viewAllPayment({range:selectedRange}).then((response) => {
        setPaymentData(response?.data);
        // console.log(response?.data)
      }).catch(e => console.log(e));
    } catch (err) {
      return customToast.error("An unexpected error occured");
    }
  };


  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Payment Report" isCRM={true} />

        <section className="area-creation-content__info">
          <form onSubmit={SubmitHandler}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-3">
                <label htmlFor="stockist">From Month</label>
                <br />
                <Suspense>
                  <CascadingMenu items={items}
                    title="Select Month"
                    month={fromMonth}
                    year={fromYear}
                    onClick={handleMenuClick1}
                  />
                </Suspense>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3">
                <label htmlFor="stockist">To Month</label>
                <br />
                <Suspense>
                  <CascadingMenu items={items}
                    title="Select Month"
                    month={toMonth}
                    year={toYear}
                    onClick={handleMenuClick2}
                  />
                </Suspense>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-4 admin-creation-content__form-submit mt-5">
                <button type="submit" className="button-blue-gradient">
                  Submit
                </button>
              </div>
            </div>
          </form>

          {
            tdata.length > 0 ?
              (
                <div className="my-4">
                  {/* <h4 className="my-4">Docter's report For {month} 2024</h4> */}
                  <div className="filter-table pe-4">
                    {tdata?.length > 0 && (
                      <h2 className="web-app__heading mb-4 ms-2">
                        Showing ({tdata?.length}) Entries
                      </h2>
                    )}
                    <Table columns={columns} data={tdata} />
                  </div>
                  
                </div>
              ) :
              null
          }

        </section>
        {/* {showEdit ? <EditPopup /> : null} */}
      </div>
    </main>
  )

}

export default PaymentReport;