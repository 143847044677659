import { useState, useMemo, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import StyledCalendar from "../../../../components/Calendar";
import ButtonGroup from "../../../../components/ActivityGroup";

import { MdOutlineAddCircleOutline } from "react-icons/md";
import { dcrStyles } from "../../../public/stylesheets/selectStyles.js";
import { generateSelectData } from "../../../../utils/serialiseResponse";
import Table from "../../../../components/Table";
import {
  setAreas,
  clearAreas,
} from "../../../../reducers/locations/areaReducer";
import customToast from "../../../../components/CustomToast";
import { addDCR } from "../../../../reducers/reports/extraReducer";
import {
  clearCities,
  setCities as setCityData,
} from "../../../../reducers/locations/cityReducer";

const ExtraForm = () => {
  const dispatch = useDispatch();
  const cityData = useSelector(({ city }) => city);
  const allAreas = useSelector(({ area }) => area);

  const areaColumns = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   width: 30,
      // },
      {
        Header: "From Area",
        accessor: "fromLabel",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "To Area",
        accessor: "toLabel",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  const citySelect = useMemo(
    () => generateSelectData(cityData.data, "name"),
    [cityData]
  );
  const areaSelect = useMemo(
    () => generateSelectData(allAreas.data, "name"),
    [allAreas]
  );

  const [serial, setSerial] = useState(1);

  const [fromArea, setFromArea] = useState();
  const [toArea, setToArea] = useState();

  const [cities, setCities] = useState("");
  const [date, setDate] = useState(new Date());
  const [activeButton, setActiveButton] = useState(0);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [remarks, setRemarks] = useState("");

  useEffect(() => {
    dispatch(setCityData());

    return () => {
      dispatch(clearCities());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(setAreas(cities.value));
    return () => {
      dispatch(clearAreas());
    };
  }, [dispatch, cities]);

  const resetForm = () => {
    setDate(new Date());
    setActiveButton(0);

    setFromArea(null);
    setToArea(null);

    setRemarks("");
  };

  const handleAdd = () => {
    if (!fromArea || !toArea) {
      customToast.error("Please Select From and To Areas");
      return;
    }

    const newArea = {
      sr: serial,
      fromLabel: fromArea.label,
      fromArea: fromArea.value,
      toLabel: toArea.label,
      toArea: toArea.value,
    };

    setSelectedAreas((oldAreas) => [...oldAreas, newArea]);

    setSerial((oldSerial) => oldSerial + 1);
    setFromArea(null);
    setToArea(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      date: date,
      activity: activeButton,
      areas: selectedAreas,
      remarks: remarks,
    };


    if (!activeButton) return customToast.error(`Activity is Required.`);
    else if (!selectedAreas.length)
      return customToast.error(`Please Select at 1 Area.`);

    let error = false;
    try {
      await dispatch(addDCR("extra", data));
    } catch (e) {
      error = true;
      customToast.error("We Encountered an Error");
    }

    if (!error)
      customToast.success("Extra Activity DCR Submitted Successfully");
    resetForm();
  };

  return (
    <form className="tp__content" onSubmit={handleSubmit}>
      <div className="row">
        <div className="calendar-container col-lg-5">
          <StyledCalendar date={date} onChange={setDate} />
        </div>

        <div className="col-lg-7">
          <ButtonGroup activity={activeButton} setActivity={setActiveButton} />
        </div>
      </div>

      <div className="row row-bg">
        <div className="form__group">
          <label htmlFor="from-area">SELECT CITY</label>
          <Select
            value={cities}
            options={citySelect}
            onChange={(e) => setCities({ value: e.value, label: e.label })}
            styles={dcrStyles}
            placeholder="Select Cities"
          />
        </div>
        <div className="dcr__area">
          <div className="form__group">
            <label htmlFor="from-area">
              From Area <span className="asterisk-imp">*</span>
            </label>
            <Select
              value={fromArea}
              options={areaSelect}
              onChange={(e) => setFromArea({ value: e.value, label: e.label })}
              styles={dcrStyles}
              placeholder="Select From Area"
            />
          </div>
          <div className="form__group">
            <label htmlFor="to-area">
              To Area <span className="asterisk-imp">*</span>
            </label>
            <Select
              value={toArea}
              options={areaSelect}
              onChange={(e) => setToArea({ value: e.value, label: e.label })}
              styles={dcrStyles}
              placeholder="Select To Area"
            />
          </div>
        </div>
        <div className="dcr__button text-center">
          <hr className="dcr__button-hr dcr__button-hr-first" />
          <button
            type="button"
            className="dcr__btn-green-outlined"
            onClick={handleAdd}
          >
            <MdOutlineAddCircleOutline />
          </button>
          <hr className="dcr__button-hr dcr__button-hr-second" />
        </div>
        <div className="dcr-table">
          <Table columns={areaColumns} data={selectedAreas} />
        </div>
      </div>

      <div className="row row-bg">
        <div className="dcr__remarks">
          <label htmlFor="from-area">
            Remarks <span className="asterisk-imp">*</span>
          </label>
          <textarea
            className="form__txt"
            rows="5"
            placeholder="Enter Remarks"
            maxLength={80}
            value={remarks}
            onChange={({ target }) => setRemarks(target.value)}
          />
        </div>
      </div>

      <button className="button-submit" type="submit">
        Submit
      </button>
    </form>
  );
};

export default ExtraForm;
