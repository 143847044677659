import {useState } from "react";
import customToast from "../../../../components/CustomToast";
import { editPassword} from "../../../../services/users";

const ProfileBox = () => {

  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);

  const resetForm = () =>{
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
  }

  const handleEditPassword = async(e) =>{
    e.preventDefault();

    if(!newPassword || !oldPassword || !confirmPassword){
      return customToast.error("All fields are mandatory !");
    }

    if(newPassword !== confirmPassword){
      return customToast.error("Confirm password does not match");
    }

    let payload = {
      oldPassword : oldPassword,
      newPassword : newPassword,
      confirmPassword : confirmPassword
    };

    try {
      const payload = {
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword
      };
  
      await editPassword(payload);
      customToast.success('Password Edited successfully');
      resetForm();
    } catch (error) {
      customToast.error('Something Went Wrong!');
    }
  };
 

  return (
    <div className="main-content admin-content w-100">
      <div className="area-creation-content">

        <section className="area-creation-content__form">
          <form > 

            <div className="row d-flex flex-column" >
            <div className="col-sm-12 col-md-4 col-lg-4 mb-5">
                <label htmlFor="fName">
                  Current password 
                <span className="ml-4 asterisk-imp">
                  *
                </span>
                </label>
                <br />
                <input
                  type="password"
                  className="me-5"
                  id="fName"
                  value={oldPassword}
                  placeholder="Enter current password"
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </div>

              <div className="col-sm-12 col-md-4 col-lg-4 mb-5">
                <label htmlFor="fName">
                  New password 
                  <span className="ml-4 asterisk-imp">
                  *
                </span>
                </label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="fName"
                  value={newPassword}
                  placeholder="Enter new password"
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>

              <div className="col-sm-12 col-md-4 col-lg-4 mb-5">
                <label htmlFor="fName mr-4">
                  Confirm new password 
                  <span className="ml-4 asterisk-imp">
                  *
                </span>
                </label>
                <br />
                <input
                  type="password"
                  className="me-5"
                  id="fName"
                  value={confirmPassword}
                  placeholder="Enter confirm password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>

            <div>
              <button className="button-blue-gradient"
              onClick={(e) => handleEditPassword(e)}>
                  Change password
              </button>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export default ProfileBox;
