import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUsers,
  setUsers,
} from "../../../../reducers/users/employeeReducer";
import { generateSelectData } from "../../../../utils/serialiseResponse";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import PageTitle from "../../../../components/PageTitle";
import customToast from "../../../../components/CustomToast";
import { addComment, viewComments } from "../../../../services/tickets";
import "../index.css";
const TicketDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [ticket, setTicket] = useState(null);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const loggedIn = useSelector(({ user }) => user);
  useEffect(() => {
    const fetch = () => {
      viewComments({ page: 1, limit: 10, ticketId: ticket._id })
      .then((data) => {
        console.log(data);
        setComments(data.data);
        customToast.success(data.message || "comments fetched successfully");
      })
      .catch((err) => {
        customToast.error("Sorry failed to load comments");
      });
    };
    if(ticket)fetch();
    return () => {
      dispatch(clearUsers());
    };
  }, [ticket, dispatch]);
  useEffect(() => {
    setTicket(location?.state);
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!comment) return customToast.error("comment can not be empty!");
    setLoading(true);

    try {
      const data = await addComment({
        ticketId: ticket._id,
        message: comment,
        userName: `${loggedIn.user.firstName} ${loggedIn.user.lastName}`,
      });
      setComments([data.data, ...comments]);
      customToast.success(data.message || "comment added successfully.");
    } catch (err) {
      console.log(err);
      customToast.error("Sorry something went wrong!");
    } finally {
      setLoading(false);
      setComment("");
    }
  };

  return (
    <div className="ps-4 w-100">
      <div className="main-content admin-content">
        <div
          className="area-creation-content"
          style={{
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
        >
          <PageTitle title="Ticket Details" hierarchy={true} />

          <section className="area-creation-content__form">
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="row w-100">
                <h3 className="web-app__heading">{ticket?.title}</h3>
                <div className="col-12 mt-2">
                  <label htmlFor="city">
                    Description <span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <p className="w-100 accordion-body p-0" id="city">
                    {ticket?.description}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 col-lg-8">
                  <label htmlFor="state">
                    Enter Comment <span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <input
                    value={comment}
                    onChange={({ target }) => setComment(target.value)}
                    type="text"
                    className="me-5"
                    id="state"
                    placeholder="Add New Comment"
                  />
                </div>

                <div className="col-md-4 col-lg-4 admin-content-submit-button">
                  <button
                    type="submit"
                    className="button-blue-gradient"
                    disabled={loading}
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </section>
        </div>
        <section
          style={{
            height: "600px",
            overflowY: "auto",
            overflowx: "hidden",
            width: "100%",
            // border:"1px solid #c7c4e9",
          }}
          className="coments-container"
        >
          {comments.map((el, idx) => (
            <div className="w-100">
            <div
              className={`p-3 ${
                el.userName === "Super Admin" && "ms-auto commentBox-dark-bg"
              } commentBox`}
            >
              <span className="web-app__heading">{el.userName}</span>
              <p>{el.text}</p>
            </div>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export default TicketDetails;
