import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { viewReminder, clearReminder } from "../../../reducers/reminder";
import ProfileBox from "./ProfileBox";

const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector(({user}) => user);

  useEffect(() => {
   
  }, [dispatch]);

  return (
    <div className="profile-page">
      <div className="profile-content">
        <h2 className="web-app__heading mb-5">Edit password</h2>
        <ProfileBox/>     
      </div>
    </div>
  );
};

export default Profile;
