import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURL = `${apiURL}/client/expenses`;
const distanceURL = `${apiURL}/client/expenses/view-distance-for-dcr`;
const distanceURLV1 = `${apiURL}/client/v1/expenses/view-distance-for-dcr`;
// const approveURL = `${apiURL}/api/admin/expenses`;
// const rejectURL = `${apiURL}/api/admin/expenses/reject`;
// const approveGetURL = `${apiURL}/api/admin/expenses/expenses-for-approval`;

const baseURL2 = `${apiURL}/client/v1/expenses`;

export const getExpense = async (filter) => {
  const data = await axios.get(baseURL2, {
  // const { data } = await axios.get(baseURL, {
    params: filter,
    headers: setAuth(),
  });
  // return data; // return only data while using baseURL
  return data.data;
};
export const getDeletedExpense = async (filter) => {
  const { data } = await axios.get(`${baseURL}/view-deleted-expense`, {
    params: filter,
    headers: setAuth(),
  });
  return data;
};

export const restoreDeltedExpense = async (id) => {
  const { data } = await axios.put(`${baseURL}/update-deleted-expense/${id}`, {id: id} , {
    headers: setAuth(),
  });
  return data;
};


export const getDistance = async (date) => {
  const { data } = await axios.get(distanceURLV1, {
  // const { data } = await axios.get(distanceURL, {
    params: date,
    headers: setAuth(),
  });

  return data;
};

export const getExpenseForApproval = async () => {
  const { data } = await axios.get(`${baseURL}/expenses-for-approval`, {
    headers: setAuth(),
  });

  return data;
};

export const getExpenseForToday = async (details) => {
  const { data } = await axios.get(`${baseURL2}/today`, {
    params: details,
    headers: setAuth(),
  });

  return data;
};

export const getTotalMonthlyExpense = async (details) => {
  const { data } = await axios.get(`${baseURL2}/view-total-expense-for-month`, {
  // const { data } = await axios.get(`${baseURL}/view-total-expense-for-month`, {
    params: details,
    headers: setAuth(),
  });

  return data;
};

export const expenseApproval = async (id, details) => {
  const { data } = await axios.post(`${baseURL}/approve/${id}`, details, {
    headers: setAuth(),
  });

  return data;
};

export const rejectApproval = async (id, details) => {
  const { data } = await axios.post(`${baseURL}/reject/${id}`, details, {
    headers: setAuth(),
  });

  return data;
};

export const addExpense = async (details) => {
  const { data } = await axios.post(baseURL2, details, {
    headers: setAuth(),
  });

  return data;
};

export const editExpense = async (id, updatedExpense) => {
  const { data } = await axios.put(`${baseURL}/edit/${id}`, updatedExpense, {
    headers: setAuth(),
  });

  return data;
};
export const deleteExpense = async(id)=>{
  const {data} = await axios.delete(baseURL2+`/${id}` , {
    headers:setAuth()
  }) ;
  
  return data ;
}

export const viewUsersWithPendingExpense = async() => {
  const { data } = await axios.get(baseURL + "/users-pending-expense",{
    headers: setAuth()
  });

  return data;
}

export const viewMonthlyExpenseReport = async (payload) => {
  const { data } = await axios.get(baseURL2 + "/monthly-expense",{
    params : payload,
    headers : setAuth()
  });
  return data;
}