import React, { useCallback } from "react";
import { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";
import {  MdOutlineEdit } from "react-icons/md";
import serialise from "../../../../utils/serialiseResponse";
import { RiCloseCircleLine, RiEdit2Fill } from "react-icons/ri";
import { setCreatedRoutes } from "../../../../reducers/locations/routeReducer";
import { editRoute } from "../../../../reducers/locations/routeReducer";

const MaxTourLimit = () => {
  const dispatch = useDispatch();
  
  const routes = useSelector(({ route }) => route);
  const [showEdit, setShowEdit] = useState(false);
  const [routeData, setRouteData] = useState();

  const EditPopup = ({ data }) => {
    const dispatch = useDispatch();
    const [visits, setVisists] = useState(0);

    let updatedData = { ...data, visits, 'tourlimit' : true };
    const handleEdit = async (e) => {
      e.preventDefault();
      await dispatch(editRoute(updatedData?._id, updatedData));
      setShowEdit(false);
    };

    useEffect(() => {
      setVisists(data?.visits || 0);
    }, [data]);

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Edit Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form onSubmit={handleEdit}>
            <div className="edit-form">
              <div>
                <label htmlFor="distance">
                  Do you  want to edit this route's Maximum Visits : 
                  <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="number"
                  id="distance"
                  className="me-5"
                  placeholder="Enter distance"
                  value={visits}
                  onChange={({ target }) => setVisists(target.value)}
                />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button
                type="submit"
                
                className="button-submit-green mt-4"
              >
                <span className="button-submit-green__icon-container">
                  <MdOutlineEdit className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Edit</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };
  useEffect(()=>{
    dispatch(setCreatedRoutes()) ;
  },[dispatch]);
  const setEditForm = (data) => {
    setShowEdit(false);
    setRouteData(data);
  };
  const columns = useMemo(
    () => [
      {
        Header: "Area Type",
        accessor: "areatype",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "From City",
        accessor: "fromCity",
      },
      {
        Header: "To City",
        accessor: "toCity",
      },
      {
        Header: "HQ",
        accessor: "headquarter",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "State",
        accessor: "state",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Visits",
        accessor: "distance",
        Cell: (props) =>{
          return <span>{props?.row?.original?.visits || 0 } </span>
        }
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: (props) => {
          return (
            <span
              className="react-table-view-link"
              onClick={() => {
                setEditForm(props.row.original);
                setShowEdit(true);
              }}
            >
              <RiEdit2Fill className="icon-color-green" />
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );
  
  const tableData = useMemo(() => serialise(routes.data), [routes]);
  
  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Set Maximum Visits For Routes" hierarchy={true}/>

        <section className="area-creation-content__info">
          {tableData.length > 0 && (
            <h2 className="web-app__heading mb-4 ms-2">
              Showing ({tableData.length}) Entries
            </h2>
          )}
          <div className="filter-table">
            <Table columns={columns} data={tableData} />
          </div>
        </section>
        {showEdit ? <EditPopup data={routeData} /> : null}
      </div>
    </div>
  );
};

export default MaxTourLimit;
