import { useEffect, useState, useMemo , useCallback} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PageTitle from "../../../../../components/PageTitle";
import {
  approveLeave,
  clearLeaves,
  rejectLeave,
  setApprovedLeaves,
} from "../../../../../reducers/leaveReducer";
import { BsCheck2Circle, BsXCircle } from "react-icons/bs";
import serialise from "../../../../../utils/serialiseResponse";
import Table from "../../../../../components/Table";
import { RiCloseCircleLine } from "react-icons/ri";
import { MdOutlinePendingActions } from "react-icons/md";
import { updateTrigger } from "../../../../../reducers/approveLengthReducer";

import {ImCheckboxUnchecked, ImCheckboxChecked} from "react-icons/im" ;
import { MdClear, MdDoneAll, MdCheckBoxOutlineBlank } from "react-icons/md";
import customToast from "../../../../../components/CustomToast";

const formatDate = (date) => {
  return new Date(date).toLocaleString("en-IN", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

const ApprovalLR = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const leaveInfo = useSelector(({ leave }) => leave);

  const [showEdit, setShowEdit] = useState(false);
  const [approvalData, setApprovalData] = useState();
  const [allSelected, setAllSelected] = useState(false);

  const SelectColumnFilter = useCallback(
    ({ column, columns }) =>{
      return (
        <div className="search-filter__container" onClick={(e)=>{e.stopPropagation()}}>
          {<span className="d-flex gap-3 align-items-center">{column.render("Header")}
            {allSelected ? (
                <ImCheckboxChecked onClick={(e) => {
                  e.preventDefault();
                  column?.filteredRows?.forEach((el,idx)=>{
                    el.original.isSelected = false ;
                  });
                  setAllSelected(false);
                }}/>
              ):(
                <ImCheckboxUnchecked onClick={(e) => {
                  e.preventDefault();
                  column?.filteredRows?.forEach((el,idx)=>{
                    el.original.isSelected = true ;
                  });
                  setAllSelected(true);
                }}/>
              )
              }
          </span>
          }
        </div>
    );},[allSelected])

    const handleApprove = async (data) => {
      if(data.length === 0)return customToast.error("Please select a Leave Request");
      const payload = {ids:data, type:"bulk"};
      await dispatch(approveLeave(data?._id, payload));
      setAllSelected(false);
      setShowEdit(false);
    };

    const handleReject = async (data) => {
      if(data.length === 0)return customToast.error("Please select a Leave Request");
      const payload = {ids:data, type:"bulk"};
      await dispatch(rejectLeave(data?._id, payload));
      setAllSelected(false);
      setShowEdit(false);
    };
    const tableData = useMemo(() => serialise(leaveInfo.data), [leaveInfo]);  
  const EditPopup = () => {
    // const dispatch = useDispatch();
    const data = [];
    tableData.forEach((el,idx)=>{
      if(el?.isSelected){
        data.push(el?._id);
      }
    })
    return (
      <section className="edit-popup" onClick={(e) =>
        showEdit
          ? e.target.className === "edit-popup"
            ? setShowEdit(false)
            : null
          : null
      }>
      {/* <section className="edit-popup"> */}
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Edit Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  Do you want to approve the leave?
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button
                type="button"
                className="button-submit-green mt-4"
                onClick={() => handleApprove(data)}
              >
                <span className="button-submit-green__icon-container">
                  <BsCheck2Circle className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Approve</span>
              </button>

              <button
                type="button"
                className="button-delete mt-4"
                onClick={() => handleReject(data)}
              >
                <span className="button-delete__icon-container">
                  <BsXCircle className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Reject</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  // const setEditForm = (data) => {
  //   setShowEdit(false);
  //   setApprovalData(data);
  // };

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Start Date",
        accessor: "startDate",
        Cell: (props) => (
          <span>{formatDate(props?.row?.original?.startDate)}</span>
        ),
      },
      {
        Header: "End Date",
        accessor: "endDate",
        Cell: (props) => (
          <span>{formatDate(props?.row?.original?.endDate)}</span>
        ),
      },
      {
        Header: "Employee Name",
        accessor: "",
        Cell: (props) => (
          props?.row?.original?.submitter?.firstName ? 
          <span>  
            {props?.row?.original?.submitter?.firstName}{" "}
            {props?.row?.original?.submitter?.lastName}
          </span> : 
          <span>  
            {props?.row?.original?.submitter?.submitter}
          </span>
        ),
      },
      {
        Header: "Reason For Leave",
        accessor: "leaveReason",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Select",
        accessor: "select",
        Cell: (props) => {
          const [local, setLocal] = useState(props?.row?.original?.isSelected || false);
          return (
            <span
              className="react-table-view-link display-6"
            >    
              {local ? (
                <ImCheckboxChecked onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = false ;
                  setLocal(false); 
                }}/>
              ):(
                <MdCheckBoxOutlineBlank onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = true ;
                  setLocal(true);
                }}/>
              )
              }
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: false,
        disableSortBy: true,
        Filter:SelectColumnFilter
      },
    ],
    [SelectColumnFilter,tableData]
  );

  useEffect(() => {
    dispatch(setApprovedLeaves());

    return () => {
      dispatch(clearLeaves());
    };
  }, [dispatch]);
  
  useEffect(() => {
    dispatch( updateTrigger({index:location?.state?.index,data:leaveInfo?.data}))

  }, [leaveInfo?.data]);

  // let tableData = useMemo(() => serialise(leaveInfo.data), [leaveInfo]);
  // console.log(tableData)
  

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Approve Leave Request" />

        <section className="area-creation-content__info">
        <div className="d-flex justify-content-end gap-5 pe-2">
          <button
            type="submit"
            className="button-submit-green"
            onClick={(e) => {
              e.preventDefault();
              setShowEdit(true);
            }}
          >
            <span className="button-submit-green__icon-container">
              <BsCheck2Circle className="button-submit-green__icon" />
            </span>
            <span className="button-submit-green__text">Actions</span>
          </button>
        </div>
        {tableData.length > 0 && (
            <h2 className="web-app__heading mb-4 ms-2">
              Showing ({tableData.length}) Entries
            </h2>
          )}
          <div className="filter-table">
            <Table columns={columns} data={tableData} />
          </div>
          {showEdit ? <EditPopup data={approvalData} /> : null}
        </section>
      </div>
    </div>
  );
};

export default ApprovalLR;
