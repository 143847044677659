import React from 'react';
import { Provider } from 'react-redux';
import "../node_modules/yet-another-react-lightbox/dist/styles.css";

import store from './store.js';
import App from './components/App.jsx';
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); 
root.render(
	<Provider store={store}>
		<Router>
			<App tab="home"/>
		</Router>
	</Provider>
);

