import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import { RiEdit2Fill } from "react-icons/ri";

import PageTitle from "../../../../../components/PageTitle";
import Table from "../../../../../components/Table";
import { adminStyles } from "../../../../public/stylesheets/selectStyles";

import serialise, { addIndex } from "../../../../../utils/serialiseResponse";

import { viewTargets } from "../../../../../services/targets";

const typeOptions = [
  { value: 1, label: "Doctor" },
  { value: 2, label: "Chemist" },
  { value: 3, label: "Stockist" },
];

const CreationEditDeleteDCS = () => {
  const navigate = useNavigate();

  const [type, setType] = useState(typeOptions[0]);
  const [dcsData, setDcsData] = useState([]);

  const columnData = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Degree",
        accessor: "degree",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Specialization",
        accessor: "specialization",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Mobile Number",
        accessor: "mobile",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "HQ",
        accessor: "city.name",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: (props) => {
          return (
            <span
              className="react-table-view-link"
              onClick={() => {
                navigate(
                  `/creation-menu/dcs/edit-delete/${type?.label?.toLowerCase()}`,
                  { state: props?.row?.original }
                );
              }}
            >
              <RiEdit2Fill className="icon-color-green" />
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [type, navigate]
  );

  const chemColumnData = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Business Name",
        accessor: "businessName",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Propreitor Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Address",
        accessor: "address",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Mobile Number",
        accessor: "chemistContact",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "HQ",
        accessor: "city.name",
        maxWidth: 85,
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: (props) => {
          return (
            <span
              className="react-table-view-link"
              onClick={() => {
                navigate(
                  `/creation-menu/dcs/edit-delete/${type?.label?.toLowerCase()}`,
                  { state: props?.row?.original }
                );
              }}
            >
              <RiEdit2Fill className="icon-color-green" />
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [type, navigate]
  );

  const stkColumnData = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Business Name",
        accessor: "businessName",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Propreitor Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Address",
        accessor: "address",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Mobile Number",
        accessor: "stockistContact",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "HQ",
        accessor: "city.name",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: (props) => {
          return (
            <span
              className="react-table-view-link"
              onClick={() => {
                navigate(
                  `/creation-menu/dcs/edit-delete/${type?.label?.toLowerCase()}`,
                  { state: props?.row?.original }
                );
              }}
            >
              <RiEdit2Fill className="icon-color-green" />
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [type, navigate]
  );

  useEffect(() => {
    const asyncFetch = async () => {
      await viewTargets(type?.label?.toLowerCase(), {
        dcr: true,
        showApproved: true
      }).then((res) => setDcsData(res.data));
    };

    asyncFetch();
  }, [type]);
  const tableData = useMemo(() => addIndex(dcsData), [dcsData]);

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Edit / Delete" />

        <section className="area-creation-content__info">
          <form>
            <div className="row">
              <div className="col-sm-12 col-md-3 col-lg-3">
                <label htmlFor="type">Select Type</label>
                <br />
                <Select
                  name="type"
                  id="type"
                  value={type}
                  options={typeOptions}
                  onChange={(e) => setType({ ...e })}
                  styles={adminStyles}
                  placeholder="Doc / Chem / Stk"
                />
              </div>
            </div>
          </form>

          <div className="filter-table">
            {/* <MaterialTable
              editable={{
                onRowDelete: () => {},
              }}
              actions={[
                {
                  icon: () => (
                    <AiOutlineEdit className="mui-table-approve-button" />
                  ),
                  onClick: (e, data) =>
                    navigate(
                      `/creation-menu/dcs/edit-delete/${type?.label?.toLowerCase()}`,
                      {
                        state: data,
                      }
                    ),
                },
              ]}
              options={{
                exportButton: true,
                emptyRowsWhenPaging: false,
                actionsColumnIndex: -1,
                pageSizeOptions: [5, 10, 20, 50, 100],
              }}
              columns={
                type?.label?.toLowerCase() === "doctor"
                  ? columnData
                  : type?.label?.toLowerCase() === "chemist"
                  ? chemColumnData
                  : stkColumnData
              }
              data={dcsData}
              title="Doctors"
            /> */}
            {tableData.length > 0 && (
              <h2 className="web-app__heading mb-4 ms-2">
                Showing ({tableData.length}) Entries
              </h2>
            )}

            {tableData.length > 0 ? (
              <Table
                columns={
                  type?.label?.toLowerCase() === "doctor"
                    ? columnData
                    : type?.label?.toLowerCase() === "chemist"
                    ? chemColumnData
                    : stkColumnData
                }
                data={tableData}
              />
            ) : (
              <h3 className="mt-2 no-results-message">No results found</h3>
            )}
          </div>
        </section>
      </div>
    </main>
  );
};

export default CreationEditDeleteDCS;
