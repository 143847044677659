import { getReminder, newReminder, deleteReminderById } from "../services/reminder";

const initialState = {
  date: null,
  title: "",
  description: "",
  time: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "VIEW_REMINDER":
      return action.payload;
    case "CREATE_REMINDER":
      return action.payload;
    case "CLEAR_REMINDER":
      return initialState;
    case "DELETE_REMINDER":
      return initialState;

    default:
      return state;
  }
};

export const viewReminder = () => {
  return async (dispatch) => {
    const { data } = await getReminder();
    
    dispatch({
      type: "VIEW_REMINDER",
      payload: { data: data },
    });
  };
};

export const addReminder = (data) => {
  return async (dispatch) => {
    try {
      data = await newReminder(data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    // dispatch({
    //   type: "CREATE_REMINDER",
    //   payload: data,
    // });
    dispatch(viewReminder());
  };
};

export const deleteReminder = (data) => {
  return async (dispatch) => {
    try {
      data = await deleteReminderById(data);
    } catch (e) {
      if (data.error) throw data.error;
    }
    dispatch({
      type: "DELETE_REMINDER",
      payload: { data: data },
    });
    dispatch(viewReminder());
  };
};

export const clearReminder = () => {
  return {
    type: "CLEAR_REMINDER",
  };
};

export default reducer;
