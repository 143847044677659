import {
	MdOutlineEvent,
	MdOutlineGroups,
	MdOutlineHome,
	MdOutlineSmartphone,
	MdOutlineTransitEnterexit,
	MdOutlineVideocam,
	MdOutlineWork,
} from 'react-icons/md';

const ButtonGroup = ({ activity, setActivity }) => {
	// console.log(activity);

	return (
		<div className="tp__activity text-center">
			<div className="web-app__heading">Activity</div>

			<div className="tp__grid">
				<button
					className={`tp__activity-types ${
						activity === 'Working' ? 'tp__activity-types___active' : ''
					}`}
					onClick={() => setActivity('Working')}
					type="button"
				>
					<div className="tp__activity-types-icon">
						<MdOutlineWork className="tp__activity-types-icon-1" />
					</div>
					<div className="tp__activity-types-text">
						<p>Working</p>
					</div>
				</button>

				<button
					className={`tp__activity-types ${
						activity === 'HD Leave' ? 'tp__activity-types___active' : ''
					}`}
					onClick={() => setActivity('HD Leave')}
					type="button"
				>
					<div className="tp__activity-types-icon">
						<MdOutlineWork className="tp__activity-types-icon-2" />
					</div>
					<div className="tp__activity-types-text">
						<p>HD Leave</p>
					</div>
				</button>

				<button
					className={`tp__activity-types ${
						activity === 'HD Camp' ? 'tp__activity-types___active' : ''
					}`}
					onClick={() => setActivity('HD Camp')}
					type="button"
				>
					<div className="tp__activity-types-icon">
						<MdOutlineWork className="tp__activity-types-icon-3" />
					</div>
					<div className="tp__activity-types-text">
						<p>HD Camp</p>
					</div>
				</button>
			</div>

			<div className="tp__grid">
				<button
					className={`tp__activity-types ${
						activity === 'Weekends' ? 'tp__activity-types___active' : ''
					}`}
					onClick={() => setActivity('Weekends')}
					type="button"
				>
					<div className="tp__activity-types-icon">
						<MdOutlineEvent className="tp__activity-types-icon-4" />
					</div>
					<div className="tp__activity-types-text">
						<p>Weekends</p>
					</div>
				</button>

				<button
					className={`tp__activity-types ${
						activity === 'WFH' ? 'tp__activity-types___active' : ''
					}`}
					onClick={() => setActivity('WFH')}
					type="button"
				>
					<div className="tp__activity-types-icon">
						<MdOutlineSmartphone className="tp__activity-types-icon-5" />
					</div>
					<div className="tp__activity-types-text">
						<p>WFH</p>
					</div>
				</button>

				<button
					className={`tp__activity-types ${
						activity === 'Leave' ? 'tp__activity-types___active' : ''
					}`}
					onClick={() => setActivity('Leave')}
					type="button"
				>
					<div className="tp__activity-types-icon">
						<MdOutlineHome className="tp__activity-types-icon-6" />
					</div>
					<div className="tp__activity-types-text">
						<p>Leave</p>
					</div>
				</button>
			</div>

			<div className="tp__grid">
				<button
					className={`tp__activity-types ${
						activity === 'Meeting' ? 'tp__activity-types___active' : ''
					}`}
					onClick={() => setActivity('Meeting')}
					type="button"
				>
					<div className="tp__activity-types-icon">
						<MdOutlineVideocam className="tp__activity-types-icon-7" />
					</div>
					<div className="tp__activity-types-text">
						<p>Meeting</p>
					</div>
				</button>

				<button
					className={`tp__activity-types ${
						activity === 'Conference' ? 'tp__activity-types___active' : ''
					}`}
					onClick={() => setActivity('Conference')}
					type="button"
				>
					<div className="tp__activity-types-icon">
						<MdOutlineGroups className="tp__activity-types-icon-8" />
					</div>
					<div className="tp__activity-types-text">
						<p>Conference</p>
					</div>
				</button>

				<button
					className={`tp__activity-types ${
						activity === 'Closing' ? 'tp__activity-types___active' : ''
					}`}
					onClick={() => setActivity('Closing')}
					type="button"
				>
					<div className="tp__activity-types-icon">
						<MdOutlineTransitEnterexit className="tp__activity-types-icon-9" />
					</div>
					<div className="tp__activity-types-text">
						<p>Closing</p>
					</div>
				</button>
			</div>
		</div>
	);
};

export default ButtonGroup;
