import { MdArrowBackIosNew, MdOutlineGroup, MdOutlineRefresh } from "react-icons/md";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-select";
import { monthOptions, yearOptions } from "../../../../utils/helper";
import {
  dcrStyles,
  selectUserStyles,
} from "../../../public/stylesheets/selectStyles";
import { useCallback, useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { generateSelectData, generateSelectUser } from "../../../../utils/serialiseResponse";
import { viewUsersForUtilities } from "../../../../services/utilities";
import { viewPOBReports } from "../../../../services/analytics";
import Table from "../../../../components/Table";
import { FaEye } from "react-icons/fa";
import { addDays } from "date-fns";
import DateRange from "../../../../components/DateRange";

const typeOptions = [
  { value: 1, label: "Doctor" },
  { value: 2, label: "Chemist" },
  { value: 3, label: "Stockist" },
];

const PobWiseReports = () => {
  const navigate = useNavigate();
  const loggedIn = useSelector(({ user }) => user);

  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [reports, setReports] = useState([]);
  const [type, setType] = useState({ value: 1, label: "Doctor" });
  const [employeeDataForExcel, setEmployeeDataForExcel] = useState();

  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
  });

  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );

  const employeeData = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "",
        showTotal:true,
        placeholderTotal:"Total",
        Cell : (props) =>{
          return( type?.value === 1 ? <p>{props?.row?.original?.targetData?.target?.name} </p> :
          <p> {props?.row?.original?.targetData?.target?.businessName} </p>
          )
        }
      },
      {
        Header: "POB Amount",
        accessor: "amount",
        showTotal:true,
        totalAccessor:"amount"
      },
      {
        Header: "View",
        accessor: "",
        Cell: (props) => {
          return (
            <Link
              to={`${props?.row?.original?.targetData?.target._id}`}
              state={{
                month: Number(month?.value),
                year: Number(year?.label),
                startDate: dateRange.startDate.getTime(),
                endDate:dateRange.endDate.getTime(),
                type: type?.label?.toLowerCase(),
                userId: selectedUser ? selectedUser?.value : loggedIn?.jwt?.id,
                data: props.row.original,
              }}
              className="util-table-view-details-icon"
            >
              <FaEye />
            </Link>
          );
        },
        minWidth: 80,
        maxWidth: 80,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [month, year, dateRange, loggedIn, selectedUser, type]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      let data = {
        selectedUser: selectedUser?.value,
        month: month?.value,
        year: Number(year?.label),
      };

      await viewPOBReports(type?.label?.toLowerCase(), data).then((res) =>{
        setReports(res.data)
      }
      );
    },
    [month, year, type, selectedUser]
  );

  useEffect(() => {
    
    
    let data = {
      selectedUser: selectedUser?.value,
      // month:8,
      // year:2023,
      startDate: new Date(dateRange?.startDate).getTime(),
      endDate: addDays(new Date(dateRange?.endDate), 1).getTime(),
      betweenDates:true
    };

    const aysncFetch = async () => {
      try{
        await viewPOBReports(type?.label?.toLowerCase(), data).then((res) =>
        {
          setReports(res.data)
        }
         );
      }
      catch(err){
      console.log(err);
      }
    };
   
    aysncFetch();
  }, [dateRange, selectedUser, type]);
   
  useEffect(() => {
    try {
      viewUsersForUtilities({ dcr: true }).then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    try {
      let tempdata = employeeData.filter((empData) => {
        if (empData.label === selectedUser.label) return true;
      })
      setEmployeeDataForExcel(tempdata[0]);
    } catch (error) {
      console.log(error);
    }
  }, [selectedUser]);

  return (
    <div className="expense">
      <div className="row">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MdArrowBackIosNew
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span style={{ display: "inline-block", margin: "0 1rem" }}>
            POB Reports
          </span>
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="util-tp-query">
            {/* <div className="util-tp-filter">
              <p>Select Month</p>
              <Select
                styles={dcrStyles}
                placeholder="Select Month"
                className="mt-3"
                options={monthOptions}
                value={month}
                onChange={(e) => setMonth({ ...e })}
              />
            </div> */}

            {/* <div className="util-tp-filter">
              <p>Select Year</p>
              <Select
                styles={dcrStyles}
                placeholder="Select Year"
                className="mt-3"
                options={yearOptions}
                value={year}
                onChange={(e) => setYear({ ...e })}
              />
            </div> */}

            <div className="util-tp-filter">
              <p>Select Doc / Chem / Stockist</p>
              <Select
                styles={dcrStyles}
                placeholder="Select Type"
                className="mt-3"
                options={typeOptions}
                value={type}
                onChange={(e) => setType({ ...e })}
              />
            </div>

            {loggedIn?.user?.des < 121 ||
            loggedIn?.user?.designation?.priority > 1 ||
            loggedIn?.user?.isAdmin ? (
              <div className="util-tp-filter">
                <p className="util-select-user__label">Select User</p>
                <div className="util-select-user">
                  <MdOutlineGroup className="util-select-user__icon" />
                  <Select
                    styles={selectUserStyles}
                    options={employeeSelect}
                    placeholder="Select User"
                    className="mt-3"
                    value={selectedUser}
                    onChange={(e) => setSelectedUser({ ...e })}
                  />
                  <MdOutlineRefresh size={25} className="icon-color-tertiary mt-4 ms-2" style={{ cursor: "pointer" }} onClick={() => {
                      setSelectedUser(null)
                    }} />
                </div>
              </div>
            ) : null}

            {/* <div className="util-tp-filter">
              <button type="submit" className="button-blue-gradient mt-4">
                See Reports
              </button>
            </div> */}
          </div>
        </form>

        <div className="date-range-container my-3">
          <DateRange setDateRange={setDateRange} />
        </div>
        <div className="filter-table">
        {reports.length > 0 && (
          <h2 className="web-app__heading mb-4">
            Showing ({reports.length}) Entries
          </h2>
        )}
          {reports.length>0?<Table columns={columns} data={reports} empl={selectedUser ? employeeDataForExcel : loggedIn?.user} month={monthOptions.at(dateRange.startDate.getMonth())} fileSrc="POB Report" />:<h3 className="mt-2 no-results-message">No results found</h3>}
        </div>
      </div>
    </div>
  );
};

export default PobWiseReports;
