import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURL = `${apiURL}/api/day-plan/`;

export const viewDayPlan = async (type, details) => {
  const { data } = await axios.get(baseURL + type, {
    params: details,
    headers: setAuth(),
  });

  return data;
};

export const newDayPlan = async (planData) => {
  const { data } = await axios.post(baseURL, planData, {
    headers: setAuth(),
  });

  return data;
};

export const dayPlanApproval = async (type, id, details) => {
  const { data } = await axios.post(
    `${baseURL}approval/${type}/${id}`,
    details,
    {
      headers: setAuth(),
    }
  );

  return data;
};
