import { useEffect, useState, useMemo } from "react";
import { viewHolidays } from "../../../../../services/attendance";
import { formatDate } from "../../../../../utils/helper";
import serialise from "../../../../../utils/serialiseResponse";
import Table from "../../../../../components/Table";
import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const HolidayList = () => {
  const navigate = useNavigate();
  const [holidays, setHolidays] = useState([]);
  
  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        minWidth: 80,
        maxWidth: 80,
        Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
      },
     
      {
        Header: "Holiday",
        accessor: "holidayTitle",
        minWidth: 85,
        maxWidth: 85,
      },
    ],
    []
  );

  useEffect(() => {
    viewHolidays().then((response) => {
      setHolidays([...response?.data]);
    });
  }, []);
  const tableData = useMemo(() => serialise(holidays), [holidays]);
  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <section className="admin-creation-content__heading">
          <MdArrowBackIosNew
            className="admin-creation-content__back-button"
            onClick={() => navigate("/utilities")}
          />
          <h2 className="web-app__heading">Holiday List</h2>
        </section>

        <section className="area-creation-content__info" style={{ marginTop: "-4rem" }}>
          <div className="filter-table">
          {tableData.length > 0 && (
              <h2 className="web-app__heading mb-4 ms-2">
                Showing ({tableData.length}) Entries
              </h2>
            )}
            {tableData.length > 0 ? (
              <Table columns={columns} data={tableData} />
            ) : (
              <h3 className="no-results-message">No results found</h3>
            )}
          </div>
        </section>
      </div>
    </main>
  );
};

export default HolidayList;
