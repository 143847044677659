import { useLocation } from "react-router-dom";
import PageTitle from "../../../../../components/PageTitle";
import { formatDate } from "../../../../../utils/helper";
import { useState } from "react";
import { Carousel } from "react-bootstrap";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

const AdminDocApprovalDetail = () => {
  const location = useLocation();
  const [imageShow, setImageShow] = useState(false);
  const [image, setImage] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const ImagePopup = ({ data, activeIndex }) => {
    return (
      <section className="edit-popup" onClick={(e) =>
        imageShow
          ? e.target.className === "edit-popup"
            ? setImageShow(false)
            : null
          : null
      }>
        <Carousel
          slide={false}
          interval={null}
          activeIndex={activeIndex}
          onSelect={(selectedIndex) => setActiveIndex(selectedIndex)}
          nextIcon={<MdArrowForwardIos style={{ fontSize: "2em" }} />}
          prevIcon={<MdArrowBackIos style={{ fontSize: "2em" }} />}
        >
          {data?.files && data?.files.map((e) => {
            return (
              <Carousel.Item>
                <img src={e} alt="Expense Img" />
              </Carousel.Item>
            );
          })}
        </Carousel>
      </section>
    );
  };
  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="User Details" hierarchy={true}/>
      </div>
      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Business Name</h4>
          <p>{location.state?.businessName}</p>
        </div>

        <div className="user-detail-box">
          <h4>Propreitor Name</h4>
          <p>{location.state?.name}</p>
        </div>

        <div className="user-detail-box">
          <h4>Certification / Trademark</h4>
          <p>{location.state?.certification}</p>
        </div>

        <div className="user-detail-box">
          <h4>Date of Birth</h4>
          <p>{formatDate(location.state?.birthday)}</p>
        </div>
      </section>

      <section className="user-detail-container user-detail-container-yellowDark-border">
        <div className="user-detail-box">
          <h4>Contact Number</h4>
          <p>{location.state?.chemistContact}</p>
        </div>

        <div className="user-detail-box">
          <h4>HeadQuarter</h4>
          <p>{location.state?.city}</p>
        </div>

        <div className="user-detail-box">
          <h4>Working Area</h4>
          <p>{location.state?.workingArea}</p>
        </div>
      </section>

      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Email</h4>
          <p>{location.state?.email}</p>
        </div>

        <div className="user-detail-box">
          <h4>Address</h4>
          <p>{location.state?.address}</p>
        </div>

        <div className="user-detail-box">
          <h4>Extra Info</h4>
          <p>{location.state?.extraInfo}</p>
        </div>
      </section>

      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Images</h4>
          <div className="d-flex gap-4 mt-4">
          {location?.state?.files && location?.state?.files.map((url, index) => (
            <div key={index} style={{height: "12rem", width: "10rem", overflow: "hidden", borderRadius: "5px", cursor: "pointer"}} onClick={() => {
              setImage(location?.state);
              setImageShow(true);
              setActiveIndex(index);
            }}>
              <img style={{height: "100%", width: "100%", objectFit: "cover"}} key={index} src={url} alt={`File ${index + 1}`} />
            </div>
          ))}
          </div>
        </div>
      </section>
      {imageShow ? <ImagePopup data={image}  activeIndex={activeIndex} /> : null}
    </div>
  );
};

export default AdminDocApprovalDetail;
