import { useEffect, useState, useMemo,useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../../components/PageTitle";
import customToast from "../../../../components/CustomToast";
import { BsCheck2Circle, BsXCircle } from "react-icons/bs";
import {
  approveChemist,
  clearChemists,
  rejectChemist,
  setChemistForApproval,
} from "../../../../reducers/targets/chemist";
import { Link,useLocation } from "react-router-dom";
import serialise from "../../../../utils/serialiseResponse";
import { RiCloseCircleLine } from "react-icons/ri";
import Table from "../../../../components/Table";
import { FaEye } from "react-icons/fa";
import { MdClear, MdDoneAll, MdCheckBoxOutlineBlank } from "react-icons/md";
import {ImCheckboxUnchecked, ImCheckboxChecked} from "react-icons/im" ;
import { MdOutlinePendingActions } from "react-icons/md";
import { updateTrigger } from "../../../../reducers/approveLengthReducer";

const ApprovalChemists = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const chemists = useSelector(({ chemist }) => chemist);
  const [allSelected, setAllSelected] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const SelectColumnFilter = useCallback(
    ({ column, columns }) =>{
      return (
        <div className="search-filter__container" onClick={(e)=>{e.stopPropagation()}}>
          {<span className="d-flex gap-3 align-items-center">{column.render("Header")}
            {allSelected ? (
                <ImCheckboxChecked onClick={(e) => {
                  e.preventDefault();
                  column?.filteredRows?.forEach((el,idx)=>{
                    el.original.isSelected = false ;
                  });
                  setAllSelected(false);
                }}/>
              ):(
                <ImCheckboxUnchecked onClick={(e) => {
                  e.preventDefault();
                  column?.filteredRows?.forEach((el,idx)=>{
                    el.original.isSelected = true ;
                  });
                  setAllSelected(true);
                }}/>
              )
              }
          </span>
          }
        </div>
  );},[allSelected])
  const handleApprove = async (data) => {
    if(data.length === 0)return customToast.error("Please select a Chemist");
    const payload = {ids:data , type:"bulk"} ;
    await dispatch(approveChemist(1, payload));
    setShowEdit(false);
    setAllSelected(false);
  };
  const handleReject = async (data) => {
    if(data.length === 0)return customToast.error("Please select a Chemist");
    const payload = {ids:data , type:"bulk"} ;
    await dispatch(rejectChemist(1, payload));
    setShowEdit(false);
    setAllSelected(false);
  };
  let tableData = useMemo(() => serialise(chemists.data), [chemists]);
  const EditPopup = () => {
    const data = [];
    tableData.forEach((el,idx)=>{
      if(el?.isSelected){
        data.push(el?._id);
      }
    })
    return (
      <section className="edit-popup" onClick={(e) =>
        showEdit
          ? e.target.className === "edit-popup"
            ? setShowEdit(false)
            : null
          : null
      }>
      {/* <section className="edit-popup"> */}
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Confirm Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              setShowEdit(false);
            }}
          >
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  {`Do you want to approve ${data.length} chemists?`}
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button
                type="submit"
                className="button-submit-green mt-4"
                onClick={() => handleApprove(data)}
              >
                <span className="button-submit-green__icon-container">
                  <BsCheck2Circle className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Approve</span>
              </button>

              <button
                type="submit"
                className="button-delete mt-4"
                onClick={() => handleReject(data)}
              >
                <span className="button-delete__icon-container">
                  <BsXCircle className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Reject</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 60,
      //   maxWidth: 60,
      // },
      {
        Header: "Created By",
        accessor: "submitter",
        Cell:(props) => <span>{props.row.original.submitter?.firstName}</span>
      },
      {
        Header: "Business Name",
        accessor: "businessName",
      },
      {
        Header: "Propreitor Name",
        accessor: "name",
      },
      // {
      //   Header: "Mobile Number",
      //   accessor: "chemistContact",
      //   disableSortBy: true,
      // },
      {
        Header: "Address",
        accessor: "address",
        disableSortBy: true,
      },
      {
        Header: "HQ",
        accessor: "city",
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "",
        disableFilters: true,
        Cell: (props) => props?.row?.original?.createdAt ? new Date(props?.row?.original?.createdAt).getTime() === new Date(props?.row?.original?.updatedAt).getTime() ? <span className="text-info">New</span> : <span className="text-warning">Edited</span>:<span>N/A</span>
      },
      {
        Header: "View",
        accessor: "details",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 60,
        maxWidth: 60,
        Cell: (props) => {
          return (
            <Link
              to={"/admin-panel/approvals/approve-chemists/details"}
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
      {
        Header: "Select",
        accessor: "none",
        Cell: (props) => {
          const [local,setLocal] = useState(props?.row?.original?.isSelected || false);
          return (
            <span
              className="react-table-view-link display-6"
            >    
              {local ? (
                <ImCheckboxChecked onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = false ;
                  setLocal(false); 
                }}/>
              ):(
                <MdCheckBoxOutlineBlank onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = true ;
                  setLocal(true);
                }}/>
              )
              }
            </span>
          );
        },
        minWidth: 80,
        maxWidth: 80,
        disableFilters: false,
        disableSortBy: true,
        Filter:SelectColumnFilter
      },
    ],
    [SelectColumnFilter,tableData]
  );

  useEffect(() => {
    dispatch(setChemistForApproval({showForApproval: true}));

    return () => {
      dispatch(clearChemists());
    };
  }, [dispatch]);
 
  useEffect(() => {
    dispatch( updateTrigger({index:location?.state?.index,data:chemists?.data}))

  }, [chemists?.data]);
  
  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Approve Chemists" />

        <section className="area-creation-content__info">
        <div className="d-flex justify-content-end gap-5 pe-2">
          <button
            type="submit"
            className="button-submit-green"
            onClick={(e) => {
              e.preventDefault();
              setShowEdit(true);
            }}
          >
            <span className="button-submit-green__icon-container">
              <BsCheck2Circle className="button-submit-green__icon" />
            </span>
            <span className="button-submit-green__text">Actions</span>
          </button>
        </div>
        {tableData.length > 0 && (
            <h2 className="web-app__heading mb-4 ms-2">
              Showing ({tableData.length}) Entries
            </h2>
          )}
          <div className="filter-table">
          
            <Table columns={columns} data={tableData} />
          </div>
          {showEdit ? <EditPopup /> : null}
        </section>
      </div>
    </div>
  );
};

export default ApprovalChemists;
