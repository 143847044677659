import { populateDesignation } from "../../constants/enums.js";
import {
  deleteUserInfo,
  modifyUserInfo,
  newDesignation,
  viewDesignationForUtil,
  viewDesignations,
  viewDesignationsV1,
} from "../../services/users.js";

const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DESIGNATIONS":
      return action.payload.data;

    case "SET_DESIGNATIONS_UTIL":
      return action.payload.data;

    case "CREATE_DESIGNATION":
      return [...state, action.payload.data];

    case "CLEAR_DESIGNATION":
      return initialState;

    case "EDIT_DESIGNATION":
      // return { ...state, newState: action.payload };
      return [...state];

    case "DELETE_DESIGNATION":
      // return action.payload;
      return [...state];

    default:
      return state;
  }
};

export const setDesignations = () => {
  return async (dispatch) => {
    // const { data } = await viewDesignations();
    const {data} = await viewDesignationsV1();
    dispatch({
      type: "SET_DESIGNATIONS",
      payload: { data: data },
    });
    populateDesignation(data);
  };
};

export const setDesignationForUtilities = () => {
  return async (dispatch) => {
    const { data } = await viewDesignationForUtil();

    dispatch({
      type: "SET_DESIGNATIONS_UTIL",
      payload: { data: data },
    });
  };
};

export const addDesignation = (data) => {
  return async (dispatch) => {
    let response;

    try {
      response = await newDesignation(data);
    } catch (e) {
      if (response.error) throw response.error;
    }


    dispatch({
      type: "CREATE_DESIGNATION",
      payload: { data: response?.data },
    });
  };
};

export const clearDesignations = () => {
  return {
    type: "CLEAR_DESIGNATION",
  };
};

export const editDesignation = (id, data) => {
  return async (dispatch) => {
    try {
      data = await modifyUserInfo("designation", id, data);
    } catch (e) {
      throw e;
    }

    dispatch({
      type: "EDIT_DESIGNATION",
      payload: { data: data },
    });
    dispatch(setDesignations());
  };
};

export const deleteDesignation = (data) => {
  return async (dispatch) => {
    try {
      data = await deleteUserInfo("designation", data);
    } catch (e) {
      if (data.error) throw data.error;
    }
    dispatch({
      type: "DELETE_DESIGNATION",
      payload: { data: data },
    });
    dispatch(setDesignations());
  };
};

export default reducer;
