import TourForm from "./Form";

const TourProgram = () => {
  return (
    <div className="main-content">
      <h2 className="web-app__heading dcr__heading">Tour Program</h2>
      <TourForm />
    </div>
  );
};

export default TourProgram;
