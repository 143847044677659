import { addPrimarySales, addGrossPrimarySales, viewGrossPrimarySales, deletePrimarySales, editPrimarySales, viewPrimarySales } from "../../services/sales";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "VIEW_PRIMARY_SALES":
      return action.payload;
    case "VIEW_GROSS_PRIMARY_SALES":
      return action.payload;
    case "DELETE_PRIMARY_SALES":
      return initialState;
    case "ERROR_OCCURED":
      return { error: action.payload };
    case "CLEAR_ERROR":
      return { ...state, error: null };
    case "CLEAR_PRIMARY_SALE":
      return initialState;

    default:
      return state;
  }
};

export const viewPrimarySale = (payload) => {
  return async (dispatch) => {
    try {
      const { data } = await viewPrimarySales(payload);
      dispatch({
        type: "VIEW_PRIMARY_SALES",
        payload: { data: data },
      });
    } catch (err) {
      dispatch({
        type: "ERROR_OCCURED",
        payload: { data: err.response.data.message },
      });
    }
  };
};

export const viewGrossPrimarySale = (payload) => {
  return async (dispatch) => {
    try {
      const { data } = await viewGrossPrimarySales(payload);
      dispatch({
        type: "VIEW_GROSS_PRIMARY_SALES",
        payload: { data: data },
      });
    } catch (err) {
      dispatch({
        type: "ERROR_OCCURED",
        payload: { data: err.response.data.message },
      });
    }
  };
};

export const addPrimarySale = (payload) => {
  return async (dispatch) => {
    try {
      await addPrimarySales(payload);
    } catch (err) {
      dispatch({
        type: "ERROR_OCCURED",
        payload: err.response.data.message,
      });
    }
  };
};

export const addGrossPrimarySale = (payload) => {
  return async (dispatch) => {
    try {
      await addGrossPrimarySales(payload);
    } catch (err) {
      dispatch({
        type: "ERROR_OCCURED",
        payload: err.response.data.message,
      });
    }
  };
};

export const editPrimarySale = (payload) => {
  return async (dispatch) => {
    try {
      await editPrimarySales(payload);
    } catch (err) {
      dispatch({
        type: "ERROR_OCCURED",
        payload: err.response.data.message,
      });
    }
  };
};

export const deletePrimarySale = (payload) => {
  return async (dispatch) => {
    try {
      await deletePrimarySales(payload);
    } catch (err) {
      dispatch({
        type: "ERROR_OCCURED",
        payload: err.response.data.message,
      });
    }
  };
};

export const clearPrimarySale = () => {
  return {
    type: "CLEAR_PRIMARY_SALE",
  };
};

export const clearPrimarySalesError = () => {
  return {
    type: "CLEAR_ERROR",
  };
};
export default reducer;
