import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { viewPunchingReportDetails } from "../../../../../services/utilities";
import { formatDate, formatTime } from "../../../../../utils/helper";
import styles from "./details.module.css";
import { MdArrowBackIosNew } from "react-icons/md";

const PunchingDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activities, setActivities] = useState([]);
  
  useEffect(() => {
    try {
      const filters = {
        date: location?.state?.date,
        selectedUser: location?.state?.user,
      };
      viewPunchingReportDetails(filters).then((res) => {
        setActivities([...res.data]);
      });
    } catch (error) {
      console.log(error);
    }
  }, [location]);

  return (
    <main className={styles.main}>
      <div className="expense">
    <div className="row">
      <h2
        className="web-app__heading"
        style={{ display: "flex", alignItems: "center" }}
      >
        <MdArrowBackIosNew
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        <span style={{ display: "inline-block", margin: "0 1rem" }}>
          Report Details
        </span>
      </h2>
      <div className={styles.activities} style={{display: "flex", alignItems:"center", justifyContent:"space-between"}}>
        <h4 className="d-flex gap-2">
          <span>{location?.state?.selectedUser?.label}</span>
          {/* <span>{activities[0]?.user?.firstName}</span>
          <span>{activities[0]?.user?.lastName}</span> */}
        </h4>
        <h4>
          {/* <span>{formatDate(activities[0]?.date)}</span> */}
          <span>{formatDate(location?.state?.date)}</span>
        </h4>
      </div>
      <div className="tp-tags d-flex mt-4 gap-md-3 gap-lg-5">
              <h4>
                <span className="tp-indicator-9 me-2"></span>Check-In
              </h4>
              <h4>
                <span className="tp-indicator-10 me-2"></span>Check-Out
              </h4>
      </div>
      {
        activities.length > 0 &&
      <div className={styles.activities}>
      {activities?.map((activity, index) => (
        <ActivityNotification
          key={activity._id}
          date={activity.date}
          checkInTime={activity.checkInTime}
          checkOutTime={activity.checkOutTime}
          isFirst={index === 0}
        />
      ))}
    </div>
      }
    </div>
    </div>
    </main>
  )
};

const ActivityNotification = ({ isFirst, checkInTime, checkOutTime }) => {
  return (
    <div className={styles.flexBox}>
      <div className={styles.flexContainer}>
        {!isFirst && <div className={styles.verticalBorder}></div>}
      <div className={styles.checkInContainer}>
      <div className={styles.checkInTime}>
        {formatTime(checkInTime)} <span className={styles.greenDot}></span>
      </div>
      </div>
      { checkOutTime && <div className={styles.verticalBorder}></div> }
      { checkOutTime && <div className={styles.checkOutContainer}>
      <div className={styles.checkOutTime}>
        <span className={styles.redDot}></span> {formatTime(checkOutTime)}
      </div>
      </div>}
      </div>
    </div>
  );
};


export default PunchingDetails;
