import {
	buildStyles,
	CircularProgressbarWithChildren,
} from 'react-circular-progressbar';

const ProgressAverage = ({ value, text, fontSize }) => (
	<CircularProgressbarWithChildren
		value={value}
		className="progress-circle"
		strokeWidth={3}
		styles={buildStyles({
			textSize: '1.1rem',
			pathColor: `rgba(30, 135, 206, ${value / 100})`,
			trailColor: '#353456',
			textColor: '#B6B3D4',
		})}
	>
		<div
			style={{
				color: '#eee',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<span style={{ fontSize: fontSize || '4rem' }}><strong>{text}</strong></span>
		</div>
	</CircularProgressbarWithChildren>
);

export default ProgressAverage;
