import React, { useEffect, useMemo, useState } from 'react'
import { MdArrowBackIosNew, MdDeleteOutline } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDoctorDetails, getChemistDetails, getStockistDetails } from '../../../../../services/doctor';
import { RiCloseCircleLine } from 'react-icons/ri';
import Table from '../../../../../components/Table';
import { formatDate } from '../../../../../utils/helper';
import { deleteTarget } from '../../../../../services/targets';
import customToast from '../../../../../components/CustomToast';
import { ignoreDuplicateEntry } from '../../../../../services/duplicationPools';


const EntityDetails = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [entryA, setEntryA] = useState({});
    const [entryB, setEntryB] = useState({});
    const [reports, setReports] = useState([]);
    const [showEdit,setShowEdit] = useState(false);

    const fetchEntityDetails = async (type, entityAId, entityBId) => {
        let responseA, responseB;
    
        if (type === "Doctor") {
          responseA = await getDoctorDetails(entityAId);
          responseB = await getDoctorDetails(entityBId);
        } else if (type === "Chemist") {
          responseA = await getChemistDetails(entityAId);
          responseB = await getChemistDetails(entityBId);
        } else if (type === "Stockist") {
          responseA = await getStockistDetails(entityAId);
          responseB = await getStockistDetails(entityBId);
        }
    
        setEntryA(responseA?.data);
        setEntryB(responseB?.data);
      };

    useEffect(() => {
        const { data, type } = location?.state || {};
        if (data && type) {
          fetchEntityDetails(type, data?.entityA?.oid, data?.entityB?.oid);
        }
      }, [location]);

      useEffect(() => {
        const tranformedResult = [];
        let fieldsToInclude = []
        const doc = [
            'name', 'degree', 'specialization', 'mobile',
            'birthday', 'anniversary',  'address',
            'city', 'workingArea', 'extraInfo'
        ];
        const chem = [
            'name', 'businessName', 'certification', 'chemistContact',
            'birthday', 'email',  'address',
            'city', 'workingArea', 'extraInfo'
        ];
        const stock = [
            'name', 'businessName', 'certification', 'stockistContact', 
            'establishmentDate', 'email', 'address','gstNumber', 
            'city', 'workingArea','drugLicenseNumber', 'drugExpiryNumber',
            'extraInfo'
        ];

        if(location?.state?.type === "Doctor") fieldsToInclude = [...doc]
        else if(location?.state?.type === "Chemist") fieldsToInclude = [...chem]
        else if(location?.state?.type === "Stockist") fieldsToInclude = [...stock]
    
        for (const key of fieldsToInclude) {
            if (entryA.hasOwnProperty(key)) { 
                if(key === "birthday" || key === "anniversary" || key === "establishmentDate"){
                    tranformedResult.push({
                        label:key
                        .replace(/([A-Z][a-z]+)/g, " $1")
                        .replace(/^\s/, '')              
                        .replace(/\b\w/g, char => char.toUpperCase()) 
                        .trim(),
                        entityA: formatDate(entryA[key]) || "-",
                        entityB: formatDate(entryB[key]) || "-",
                    });
                    continue;
                }
                tranformedResult.push({
                    label:key
                    .replace(/([A-Z][a-z]+)/g, " $1")
                    .replace(/^\s/, '')              
                    .replace(/\b\w/g, char => char.toUpperCase()) 
                    .trim(),
                    entityA: entryA[key] || "-",
                    entityB: entryB[key] || "-",
                });
            }
        }
    
        setReports(tranformedResult); // Update the reports with the transformed result
    }, [entryA, entryB]);

    const EditPopup = () => {
  

      const handleDelete = (entry) => {
        
        deleteTarget(location?.state?.type,entry?._id ).then(()=>{
          ignoreDuplicateEntry({entity: location?.state?.type, objId: location?.state?.data?._id})
          customToast.success(`Entry Deleted Successfully`);
          navigate(-1);
        }).catch(e=>{
          customToast.error("It seems we encountered and error!");
        });
        setShowEdit(false);
      };
  
      return (
        <section className="edit-popup">
          <div className="edit-content">
            <div className="edit-popup__heading">
              <h2 className="web-app__heading">Delete Duplicate Entry</h2>
              <RiCloseCircleLine
                className="edit-popup__close"
                onClick={() => setShowEdit(false)}
              />
            </div>
  
            <form 
            // onSubmit={handleDelete}
            >
              <div className="edit-form">
                <div>
                  <label htmlFor="division">
                  Are you Sure You Want To Delete Entry A or Entry B ?
                  </label>
                  <br />
                </div>
              </div>
  
              <div className="edit-form-button-container">
                <button type="submit" className="button-delete mt-4" onClick={() => handleDelete(entryA)}>
                  <span className="button-delete__icon-container">
                    <MdDeleteOutline className="button-delete__icon" />
                  </span>
                  <span className="button-delete__text">Entry A</span>
                </button>
  
                <button type="submit" className="button-delete mt-4" onClick={(e) => handleDelete(entryB)}>
                  <span className="button-delete__icon-container">
                    <MdDeleteOutline className="button-delete__icon" />
                  </span>
                  <span className="button-delete__text">Entry B</span>
                </button>
  
                {/* <button
                  type="button"
                  className="button-submit-green mt-4"
                  onClick={() => setShowEdit(false)}
                >
                  <span className="button-submit-green__icon-container">
                    <MdCheckCircleOutline className="button-submit-green__icon" />
                  </span>
                  <span className="button-submit-green__text">No</span>
                </button> */}
              </div>
            </form>
          </div>
        </section>
      );
    };

    const columns = useMemo(
        () => [
          {
            Header: "Label",
            accessor: "label",
            disableFilters: true,
            disableSortBy: true,
            minWidth: 100,
            maxWidth: 100,
            Cell: ({value}) => {
              return (
                 <>
                 { value === "City" ? "Headquarter" : (value === "WorkingArea" ? "City" : value)}
                 </>
              );
          },
          },
          {
            Header: "Entry A",
            accessor: "entityA",
            disableFilters: true,
            disableSortBy: true,
            Cell: ({value}) => {
                  return (
                    typeof value === "object" ? value?.name : value
                  );
              },
          },
          {
            Header: "Entry B",
            accessor: "entityB",
            disableFilters: true,
            disableSortBy: true,
            Cell: ({value}) => {
                return (
                    typeof value === "object" ? value?.name : value
                );
            },
          },
        //   {
        //     Header: "View",
        //     accessor: "",
        //     minWidth: 60,
        //     maxWidth: 60,
        //     disableFilters: true,
        //     disableSortBy: true,
        //     Cell: (props) => {
        //       return (
        //         <Link
        //           to="details"
        //           state={ { data: props?.row?.original, type: type.label} }
        //           className="util-table-view-details-link"
        //         >
        //           <FaEye className="icon-color-tertiary" />
        //         </Link>
        //       );
        //     },
        //   },
        //   {
        //     Header: "Ignore",
        //     accessor: "",
        //     Cell: (props) => {
        //       return (
        //         <button
        //           // disabled={loggedIn?.user?.isAdmin?false:true}
        //           className="react-table-view-link bg-transparent border-0"
        //           // onClick={handleIgnore(props?.row?.original?._id)}
        //         >
        //           <RiCloseCircleLine className={`icon-color-error`} />
        //         </button>
        //       );
        //     },
        //     minWidth: 60,
        //     maxWidth: 60,
        //     disableFilters: true,
        //     disableSortBy: true,
        //   },
        ],
        []
      );

    return (
        <>
        <div className="expense">
          <div className="row">
            
            <div className='d-flex justify-content-between'>
            <h2
              className="web-app__heading"
              style={{ display: "flex", alignItems: "center" }}
            >
              <MdArrowBackIosNew
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              />
              <span style={{ display: "inline-block", margin: "0 1rem" }}>
                Duplicate Entries Details
              </span>
            </h2>

            <MdDeleteOutline className="button-delete__icon cursor-pointer" onClick={() => setShowEdit(true)} />
            </div>

            <div className="filter-table">
            {/* {reports.length > 0 && (
              <h2 className="web-app__heading mb-4">
                Showing ({reports.length}) Entries
              </h2>
            )} */}
              {reports.length>0?<Table defaultPageSize={25} columns={columns} data={reports} />:<h3 className="mt-2 no-results-message">No results found</h3>}
            </div>
          </div>
        </div>
        {showEdit && <EditPopup />}
        </>
      );
}

export default EntityDetails
