export const title = "Manage CRM ";

export const links = [
  {
    name: "Chemist Sales",
    route: "chemist-sales",
    accessor: "chemist-sales",
  },
  {
    name: "Doctor - product",
    route: "doctor-product",
    accessor: "doctor-product",
  },
  {
    name: "Chemist - doctor",
    route: "chemist-doctor",
    accessor: "chemist-doctor",
  },
  {
    name: "Stokist - chemist",
    route: "stokist-chemist",
    accessor: "stokist-chemist",
  },
];
