import DCRForm from "../../../components/DCRForm";

const Doctor = () => {
  return (
    <div className="main-content">
      <h2 className="web-app__heading dcr__heading">Doctor DCR</h2>
      <DCRForm dcrType="Doctor" />
    </div>
  );
};

export default Doctor;
