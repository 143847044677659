import { MdArrowBackIosNew, MdOutlineGroup } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { monthOptions, yearOptions } from "../../../../utils/helper";
import {
  dcrStyles,
  selectUserStyles,
} from "../../../public/stylesheets/selectStyles";
import { useCallback, useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  viewStockistWiseSales,
  viewUsersForUtilities,
} from "../../../../services/utilities";
import {
  generateSelectUser,
  serialiseChemStk,
} from "../../../../utils/serialiseResponse";
import Table from "../../../../components/Table";
import { FaEye } from "react-icons/fa";

const StkWiseSales = () => {
  const navigate = useNavigate();
  const loggedIn = useSelector(({ user }) => user);

  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [reports, setReports] = useState([]);

  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
  const columns = useMemo(
    () => [
      {
        Header: "Stockist",
        accessor: "stk",
        showTotal:true,
        placeholderTotal:"Total"
      },
      {
        Header: "Primary Qty",
        accessor: "primaryQty",
        showTotal:true,
        totalAccessor:"primaryQty"
      },
      {
        Header: "Primary Value",
        accessor: "primaryValue",
        disableFilters: true,
        disableSortBy: true,
        showTotal:true,
        totalAccessor:"primaryValue"
      },
      {
        Header: "Sales Return",
        accessor: "salesReturn",
        disableFilters: true,
        disableSortBy: true,
        showTotal:true,
        totalAccessor:"salesReturn"
      },
      {
        Header: "Sales Return Value",
        accessor: "salesReturnValue",
        disableFilters: true,
        disableSortBy: true,
        showTotal:true,
        totalAccessor:"salesReturnValue"
      },
      {
        Header: "Secondary Qty",
        accessor: "secondaryQty",
        disableFilters: true,
        disableSortBy: true,
        showTotal:true,
        totalAccessor:"secondaryQty"
      },
      {
        Header: "Secondary Value",
        accessor: "secondaryValue",
        disableFilters: true,
        disableSortBy: true,
        showTotal:true,
        totalAccessor:"secondaryValue"
      },
      {
        Header: "Closing Qty",
        accessor: "closingQty",
        disableFilters: true,
        disableSortBy: true,
        showTotal:true,
        totalAccessor:"closingQty"
      },
      {
        Header: "Closing Value",
        accessor: "closingValue",
        disableFilters: true,
        disableSortBy: true,
        showTotal:true,
        totalAccessor:"closingValue"
      },
      {
        Header: "View",
        accessor: "details",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          
          return (
            <Link
              to={`/utilities/stockist-wise-sales/${props?.row?.original?.id}`}
              state={{ data: props?.row?.original, month: month, year: year }}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    [month, year]
  );
  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      let data = {
        month: month?.value,
        year: Number(year?.label),
        selectedUser:selectedUser.value
      };

      await viewStockistWiseSales(data).then((res) =>
        setReports([...res.data])
      );
    },
    [month, year,selectedUser]
  );
  const tableData = useMemo(() => serialiseChemStk(reports), [reports]);
  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <div className="expense">
      <div className="row">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MdArrowBackIosNew
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span style={{ display: "inline-block", margin: "0 1rem" }}>
            Stockist Wise Sales
          </span>
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="util-tp-query d-flex justify-content-between">
            <div className="d-flex">
              <div className="util-tp-filter me-5">
                <p>
                  Select Month <span style={{ color: "#e74c3caa" }}>*</span>
                </p>
                <Select
                  styles={dcrStyles}
                  placeholder="Select Month"
                  className="mt-3"
                  options={monthOptions}
                  value={month}
                  onChange={(e) => setMonth({ ...e })}
                />
              </div>

              <div className="util-tp-filter">
                <p>
                  Select Year <span style={{ color: "#e74c3caa" }}>*</span>
                </p>
                <Select
                  styles={dcrStyles}
                  placeholder="Select Year"
                  className="mt-3"
                  options={yearOptions}
                  value={year}
                  onChange={(e) => setYear({ ...e })}
                />
              </div>
            </div>
            <div className="d-flex">
              {loggedIn?.user?.des < 121 ||
              loggedIn?.user?.designation?.priority > 1 ||
              loggedIn?.user?.isAdmin ? (
                <div className="util-tp-filter me-5">
                  <p className="util-select-user__label">Select User</p>
                  <div className="util-select-user">
                    <MdOutlineGroup className="util-select-user__icon" />
                    <Select
                      styles={selectUserStyles}
                      options={employeeSelect}
                      placeholder="Select User"
                      className="mt-3"
                      value={selectedUser}
                      onChange={(e) => setSelectedUser({ ...e })}
                    />
                  </div>
                </div>
              ) : null}

              <div className="util-tp-filter">
                <button type="submit" className="button-blue-gradient mt-5">
                  See Reports
                </button>
              </div>
            </div>
          </div>
        </form>

        <div className="filter-table">
          {reports.length > 0 && (
            <h2 className="web-app__heading mb-4">
              Showing ({reports.length}) Entries
            </h2>
          )}
          {tableData.length > 0 ? (
            <Table columns={columns} data={tableData} />
          ) : (
            <h3 className="mt-2 no-results-message">No results found</h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default StkWiseSales;
