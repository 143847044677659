export const title = "Manage Cost Center";

export const links = [
  {
    name: "Employee",
    route: "employee",
    accessor: "employee",
  },
  {
    name: "Doctor",
    route: "doctor",
    accessor: "doctor",
  },
  {
    name: "Stockist",
    route: "stockist",
    accessor: "stockist",
  },
  {
    name: "Chemist",
    route: "chemist",
    accessor: "chemist",
  },
  {
    name: "Miscellaneous",
    route: "miscellaneous",
    accessor: "miscellaneous",
  },
  {
    name: "Total Expenses",
    route: "total-expenditure",
    accessor: "total-expenditure",
  },
];
