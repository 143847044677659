import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDcot2LXIl_XXCyBXT4a3IHHA9hFTGawLo",
  authDomain: "medorn-erp.firebaseapp.com",
  databaseURL:
    "https://medorn-erp-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "medorn-erp",
  storageBucket: "medorn-erp.appspot.com",
  messagingSenderId: "190242836966",
  appId: "1:190242836966:web:959c7d8eba91112c5a0627",
  measurementId: "G-WZT8NMGWNV",
};

export const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
// For more information on how to access Firebase in your project,
// see the Firebase documentation: https://firebase.google.com/docs/web/setup#access-firebase
