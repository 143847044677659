import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { fileDownloader, formatDate } from "../../../../../utils/helper";
import customToast from "../../../../../components/CustomToast";
import serialise from "../../../../../utils/serialiseResponse";
import {
  MdCheckCircleOutline,
  MdDelete,
  MdCancel,
  MdDownload,
  MdDeleteOutline,
  MdOutlineCalendarViewMonth,
  MdOutlineCalendarViewDay,
  MdCalendarToday,
  MdCalendarViewMonth,
} from "react-icons/md";
import Table from "../../../../../components/Table";
import { RiCloseCircleLine, RiEdit2Fill } from "react-icons/ri";
import { AiFillSave } from "react-icons/ai";
import { FaCalendar, FaCartPlus } from "react-icons/fa";
import {
  clearProductInventory,
  editProductInventory,
  deleteProductInventory,
} from "../../../../../reducers/products/inventoryReducer";
import PageTitle from "../../../../../components/PageTitle";
import { viewInventoryAlerts } from "../../../../../reducers/products/MinimumStockReducer";
import { get } from "react-hook-form";

const EditInventory = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(({ user }) => user);
  const navigate = useNavigate();
  const location = useLocation();
  const [finalArray, setFinalArray] = useState([]); //this is the array that will get submitted
  const [editRow, setEditRow] = useState("");
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null); // for download file input
  const [showEdit, setShowEdit] = useState(false);
  const [files, setFiles] = useState("");

  // want to log inventory ID
  // console.log("location?.state?.products", location?.state?.products);

  useEffect(() => {
    const data = serialise(location?.state?.products);
    
    setFinalArray(data);
    setFiles(location?.state?.files || "");
    return () => {
      resetForm();
    };
  }, [location?.state]);

  const upQuantityRef = useRef(0);
  const upPriceRef = useRef(0);

  const resetForm = () => {
    setFinalArray([]);
    setEditing(false);
    setEditRow("");
  };

  const deleteHandler = (product) => {
    setEditRow("");
    setEditing(false);
    let sr = 0;
    const newfinalArray = finalArray.filter((ele, idx) => {
      if (ele?.product?._id !== product?._id) {
        sr += 1;
        ele.sr = sr;
        return true;
      } else return false;
    });
    setFinalArray(newfinalArray);
  };
  const handleEditRow = (sr) => {
    if (editing) {
      customToast.error("Please save the changes you just made");
      return;
    }
    setEditing(true);
    setEditRow(sr);
  };
  const submitEditedRow = (sr) => {
    const editedObj = finalArray[sr - 1];
    editedObj.quantity = upQuantityRef.current;
    editedObj.price = upPriceRef.current;
    editedObj.totalPrice =
      Number(upQuantityRef.current) * Number(upPriceRef.current);
    setEditing(false);
    setEditRow("");
  };
  const cancelHandler = (sr) => {
    setEditing(false);
    setEditRow("");
  };

  const handleInventoryEdit = useCallback(
    (e) => {
      e.preventDefault();
      if (editing) {
        customToast.error("Please save the changes you just made");
        return;
      }
      if (finalArray.length === 0) {
        customToast.error(
          "Please add atleast one product or delete the Inventory"
        );
        return;
      }
      const payload = {
        id: location?.state?._id,
        table: finalArray,
      };
      customToast.success("Edit Successful");
      dispatch(editProductInventory(payload));
      dispatch(clearProductInventory());
      dispatch(viewInventoryAlerts());

      navigate(-1);
    },
    [finalArray, editing, dispatch, navigate, location?.state]
  );

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 50,
      //   maxWidth: 50,
      // },
      {
        Header: "Product",
        accessor: "product.name",
        disableSortBy: true,
        minWidth: 160,
        maxWidth: 160,
        showTotal: true,
        placeholderTotal: "Total",
      },
      {
        Header: "Unit Price",
        accessor: "product.mrp",
        disableFilters: true,
        minWidth: 100,
        maxWidth: 100,
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          if (editable) upPriceRef.current = props?.row?.original?.price;
          return editable ? (
            <input
              defaultValue={props?.row?.original?.price}
              onChange={(e) => {
                upPriceRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input"
            />
          ) : (
            <span>{props?.row?.original?.price}</span>
          );
        },
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 100,
        showTotal: true,
        totalAccessor: "quantity",
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          if (editable) upQuantityRef.current = props?.row?.original?.quantity;
          return editable ? (
            <input
              defaultValue={props?.row?.original?.quantity}
              onChange={(e) => {
                upQuantityRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input"
            />
          ) : (
            <span>{props?.row?.original?.quantity}</span>
          );
        },
      },
      {
        Header: "Total Price",
        accessor: "totalPrice",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 90,
        showTotal: true,
        totalAccessor: "totalPrice",
      },

      {
        Header: "Batch Number",
        accessor: "batchNo",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 100,
        showTotal: false,
        totalAccessor: "batchNumber",
      },
      {
        Header: "Expiry",
        accessor: "expiryDate",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 100,
        showTotal: false,
        totalAccessor: "expiry",
      },
      {
        Header: "Actions",
        accessor: "action",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 90,
        Cell: (props) => {
          return editing && Number(props?.row?.original?.sr) === editRow ? (
            <div className="d-flex gap-2 justify-content-center">
              <AiFillSave
                onClick={(e) => {
                  submitEditedRow(props?.row?.original?.sr);
                }}
                type="button"
                className="icon-color-green"
              />
              {/* <MdDelete
                onClick={() => {
                  deleteHandler(props?.row?.original?.product);
                }}
                type="button"
                className="button-delete__icon"
              /> */}
              <MdCancel
                type="button"
                className="icon-color-yellow"
                onClick={() => {
                  cancelHandler(props?.row?.original?.sr);
                }}
              />
            </div>
          ) : (
            <span
              className="react-table-view-link"
              onClick={() => {
                handleEditRow(props?.row?.original?.sr);
              }}
            >
              <RiEdit2Fill className="icon-color-green" />
            </span>
          );
        },
      },
    ],
    [finalArray, editing]
  );

  const EditPopup = () => {
    const dispatch = useDispatch();

    const handleDelete = (e) => {
      e.preventDefault();
      try {
        dispatch(deleteProductInventory({ id: location?.state?._id }));
        navigate(-1);
        setShowEdit(false);
        customToast.success("Deleted successfully");
      } catch (err) {
        customToast.error("We encountered and error");
      }
    };

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete Inventory</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form onSubmit={handleDelete}>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  Do you want to PERMANENTLY delete this inventory?
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-delete mt-4">
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Yes</span>
              </button>

              <button
                type="button"
                className="button-submit-green mt-4"
                onClick={() => setShowEdit(false)}
              >
                <span className="button-submit-green__icon-container">
                  <MdCheckCircleOutline className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">No</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };
  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle
          title="Edit Inventory"
          isDelete={true}
          toggle={setShowEdit}
          hierarchy={true}
        />

        <div className="util-inner-details p-3 ps-5">
          <div className="util-inner-info-box-container">
            <div className="util-inner-info-box">
              <FaCalendar className="fs-2 text-light" />
              <h3 className="d-inline ms-3">
                {formatDate(location?.state?.date)}
              </h3>
            </div>
            <div className="util-inner-info-box">
              <FaCartPlus className="fs-2 text-light" />
              <h3 className="d-inline ms-3">{location?.state?.supplier}</h3>
            </div>
            {/* <div className="util-inner-info-box">
              <MdDownload className="fs-1 text-light"/>
              <h3 className="d-inline ms-3">Download</h3>
            </div> */}
            <div
              onClick={files.length === 0 ? null : (e) => fileDownloader(files)}
              className={`util-inner-info-box ${
                files.length === 0 ? "disabled" : "cursor-pointer"
              }`}
              style={{ filter: files.length === 0 ? "blur(1px)" : "none" }}
            >
              <MdDownload className="fs-1 text-light" />
              <h3 className="d-inline ms-3">Download</h3>
            </div>
          </div>
        </div>
        <section className="area-creation-content__form">
          <div className="filter-table" style={{ minHeight: "300px" }}>
            <Table columns={columns} data={finalArray} />
          </div>
          <form onSubmit={handleInventoryEdit}>
            {finalArray.length > 0 && (
              <button
                type="submit"
                className="button-submit-green mt-4 mx-auto"
              >
                <span className="button-submit-green__icon-container">
                  <MdCheckCircleOutline className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text ">
                  Submit Edited Data
                </span>
              </button>
            )}
          </form>
        </section>
        {showEdit ? <EditPopup /> : null}
      </div>
    </div>
  );
};

export default EditInventory;
