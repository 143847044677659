import { useLocation } from "react-router-dom";
import PageTitle from "../../../../../../components/PageTitle";
import { formatDate } from "../../../../../../utils/helper";

const CreationViewDCSDetailsStockist = () => {
  const location = useLocation();

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title={`Stockist Details`} hierarchy={true} />
      </div>
      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Name</h4>
          <p>{location?.state?.name}</p>
        </div>

        <div className="user-detail-box">
          <h4>Business Name</h4>
          <p>{location?.state?.businessName}</p>
        </div>

        <div className="user-detail-box">
          <h4>City</h4>
          <p>{location?.state?.city}</p>
        </div>

        <div className="user-detail-box">
          <h4>Working Area</h4>
          <p>{location?.state?.workingArea}</p>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-yelloDark-border">
        <div className="user-detail-box">
          <h4>Certification</h4>
          <p>{location?.state?.certification}</p>
        </div>

        <div className="user-detail-box">
          <h4>Stockist Contact</h4>
          <p>{location?.state?.stockistContact}</p>
        </div>

        <div className="user-detail-box">
          <h4>E-mail</h4>
          <p>{location?.state?.email}</p>
        </div>

        <div className="user-detail-box">
          <h4>Establishment Date</h4>
          <p>{formatDate(location?.state?.establishmentDate)}</p>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Drug Expiry Number</h4>
          <p>{location?.state?.drugExpiryNumber}</p>
        </div>

        <div className="user-detail-box">
          <h4>Drug License Number</h4>
          <p>{location?.state?.drugLicenseNumber}</p>
        </div>

        <div className="user-detail-box">
          <h4>GST Number</h4>
          <p>{location?.state?.gstNumber}</p>
        </div>
      </section>
    </div>
  );
};

export default CreationViewDCSDetailsStockist;
