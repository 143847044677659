import { newDCR, viewReports } from "../../services/reports.js";

const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DCRS":
      return action.data;

    case "CREATE_DCR":
      return action.payload;

    case "CLEAR_DCRS":
      return initialState;

    default:
      return state;
  }
};

export const setDCRs = (type, filters) => {
  return async (dispatch) => {
    const { data } = await viewReports(type, filters);

    dispatch({
      type: "SET_DCRS",
      data: data,
    });
  };
};

export const addDCR = (type, report) => {
  return async (dispatch) => {
    let data;

    try {
      data = await newDCR(type, report);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "CREATE_DCR",
      payload: data,
    });
  };
};

export const clearDCRs = () => {
  return {
    type: "CLEAR_DCRS",
  };
};

export default reducer;
