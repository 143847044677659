import { useEffect, useMemo, useRef, useState } from "react";
import Select from "react-select";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import serialise, { generateSelectData } from "../../../../utils/serialiseResponse";
import Table from "../../../../components/Table";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import { AddPayment, ViewLinkedCD, viewTargets } from "../../../../services/targets";
import { fileToBase64, items } from "../../../../utils/helper";
import { returnMonthFromIndex } from "../../../../utils/helper";

const Payments = () => {

  const [doctor, setDoctor] = useState("");
  const [docs, setDocs] = useState([]);
  const doctorSelect = useMemo(() => generateSelectData(docs, "name"), [docs]);
  const [totalRevenue, setTotalRevenue] = useState(0)
  const [totalRevenueShare, setTotalRevenueShare] = useState(0)
  const [date, setDate] = useState("")
  const [remark, setRemark] = useState("")
  const inputRef = useRef(null); // for upload file input
  const [uploadData, setUploadData] = useState(""); //this will be used to upload the image file
  const [amount, setAmount] = useState(0)
  let changeHandler = (e) => {
    const validFiles = Array.from(e.target.files);
    setUploadData([]);
    Promise.all(validFiles.map(fileToBase64))
      .then((base64Images) => {
        setUploadData(base64Images);
      })
      .catch((error) => {
        customToast.error('Error adding images');
      });
  };

  useEffect(() => {
    // viewTargets("doctor", { showApproved: true }).then((response) => {
    //   setDocs(response?.data);
    // }).catch(e => console.log(e));
    
    ViewLinkedCD().then((response) => {
      // setChemDocList(response.data);
       // Extract docArr from each item and merge into docs state
       const allDocs = response.data.flatMap(item => item.docArr);
        // Filter unique doctors
      const uniqueDocs = allDocs.reduce((acc, doc) => {
        if (!acc.some(d => d._id === doc._id)) {
          acc.push(doc);
        }
        return acc;
      }, []);
    
    setDocs(uniqueDocs);
      // console.log(allDocs)
  });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Stockist",
        accessor: "stockist",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Chemist",
        accessor: "chemist",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Doctor",
        accessor: "doctor",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Product Name",
        accessor: "product",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        placeholderTotal: "Total",
        Cell: (props) => {
          // console.log(props?.row?.original?.product.price);
          return (
            <span>
              {props?.row?.original?.product?.product.name} <br />
              [{props?.row?.original?.product?.product.percentage}%]
            </span>
          )
        },
      },
      // {
      //   Header: "Percentage",
      //   accessor: "product.product.percentage",
      //   disableFilters: true,
      //   disableSortBy: true,
      // },
      {
        Header: "Month",
        accessor: "month",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Price",
        accessor: "product.price",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "price",
      },
      {
        Header: "Quantity",
        accessor: "product.quantity",
        disableSortBy: true,
        disableFilters: true,
        showTotal: true,
        totalAccessor: "quantity",
      },
      {
        Header: "Final Price",
        accessor: "product.finalPrice",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "finalPrice",
      },
      {
        Header: "Revenue Share",
        accessor: "product.revenueShare",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "revenueShare",
      },
    ],
    []
  );

  const resetForm = () => {
    setDate("");
    setUploadData("");
    setDoctor("");
    setAmount(0)
    setRemark("")
    if (inputRef.current) {
      inputRef.current.value = ""; // Clear the file input field
    }
  };

  const SubmitHandler = async (e) => {
    e.preventDefault();

    try {
      if (!date || !doctor || !amount ) {
        return customToast.error("Please fill all the fields");
      }
      // console.log(doctor)
      const payload = {
        docId: doctor._id,
        date,
        amount,
        remark,
        image: uploadData
      };
      //send data to backend here
      let data = await AddPayment(payload)
      if (data) {
        resetForm();
        return customToast.success("Payment successfully added")
      }
    }
    catch (err) {
      return err.response.status === 404
        ? customToast.error("Doctor does not exists")
        : customToast.error("An unexpected error occured");
    }
  };

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Payment Details" isCRM={true} />

        <section className="area-creation-content__info">
          <form onSubmit={SubmitHandler}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-3">
                <label htmlFor="date-input">Select Date</label>
                <br />
                <input
                  type="date"
                  className=""
                  id="date-input"
                  placeholder="Date"
                  style={adminStyles}
                  value={date}
                  onChange={({ target }) => setDate(target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3">
                <label htmlFor="doctor">Select Doctor</label>
                <br />
                <Select
                  name="doctor"
                  id="doctor"
                  value={doctor}
                  options={doctorSelect}
                  onChange={(e) => setDoctor({ ...e })}
                  styles={adminStyles}
                  placeholder="Select Doctor"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3">
                <label htmlFor="amount">Amount</label>
                <br />
                <input
                  type="number"
                  id="amount"
                  name="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Enter Amount"
                  className=""
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3">
                <label htmlFor="file">Upload File</label>
                <input
                  ref={inputRef}
                  id="file"
                  type="file"
                  placeholder="you can upload image, excel or pdf"
                  onChange={changeHandler}
                />
              </div>

              <div className="col-sm-12 col-md-4 col-lg-6">
                <label htmlFor="remark">Enter Remark </label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="remark"
                  placeholder="Enter Remark Here"
                  style={adminStyles}
                  value={remark}
                  onChange={({ target }) => setRemark(target.value)}
                />
              </div>

              <div className="col-sm-12 col-md-4 col-lg-4 mt-4 admin-creation-content__form-submit mt-5">
                <button type="submit" className="button-blue-gradient">
                  Submit
                </button>
              </div>
            </div>
          </form>

        </section>
        {/* {showEdit ? <EditPopup /> : null} */}
      </div>
    </main>
  )

}

export default Payments;