import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURL = `${apiURL}/client/allowances`;
const baseURLV1 = `${apiURL}/client/v1/allowances`;

export const getAllowance = async () => {
  const { data } = await axios.get(baseURLV1, {
    headers: setAuth(),
  });

  return data;
};

export const getOSAllowances = async () => {
  const { data } = await axios.get(baseURLV1 + "/out-station", {
    headers: setAuth(),
  });

  return data;
};

export const addAllowance = async (details) => {
  const data = await axios.post(baseURLV1, details, {
    headers: setAuth(),
  });

  return data;
};

export const addOutStationAllowance = async (details) => {
  const data = await axios.post(baseURLV1 + "/out-station", details, {
    headers: setAuth(),
  });

  return data;
};

export const editAllowance = async (id, updatedAllowance) => {
  const { data } = await axios.put(`${baseURL}/${id}`, updatedAllowance, {
    headers: setAuth(),
  });

  return data;
};

export const deleteAllowances = async (type, id) => {
  const { data } = await axios.post(`${baseURLV1}/delete/${id}`, "", {
    params: { type },
    headers: setAuth(),
  });

  return data;
};
