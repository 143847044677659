import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { MdOutlineAddCircleOutline, MdOutlineSchedule } from "react-icons/md";

import StyledCalendar from "../Calendar";
import customToast from "../CustomToast";
import Table from "../Table";

import {
  setAreas as setAreasData,
  clearAreas,
} from "../../reducers/locations/areaReducer";
import {
  setDoctors as setDoctorsData,
  clearDoctors,
} from "../../reducers/targets/doctor";
import {
  setChemists as setChemistsData,
  clearChemists,
} from "../../reducers/targets/chemist";
import {
  setStockists as setStockistsData,
  clearStockists,
} from "../../reducers/targets/stockist";
import {
  setProductRanges,
  clearProductRanges,
} from "../../reducers/products/rangeReducer";

import { dcrStyles } from "../../views/public/stylesheets/selectStyles.js";
import { addDCR } from "../../reducers/dcrReducer";
import { generateSelectData, getDesignation } from "../../utils/serialiseResponse";
import { clearUsers, setUsers } from "../../reducers/users/employeeReducer";
import {
  clearCities,
  setCities as setCityData,
} from "../../reducers/locations/cityReducer";

const DCRForm = ({ dcrType }) => {
  const dispatch = useDispatch();

  const cityData = useSelector(({ city }) => city);
  const areasData = useSelector(({ area }) => area);
  const targetData = useSelector(({ doctor, chemist, stockist }) => {
    if (dcrType === "Doctor") return doctor;
    else if (dcrType === "Chemist") return chemist;
    else return stockist;
  });
  const rangesData = useSelector(({ productRange }) => productRange);
  const userData = useSelector(({ employee }) => employee);

  useEffect(() => {
    dispatch(setCityData());
    dispatch(setAreasData());
    dispatch(setProductRanges());
    dispatch(setUsers({ user: true }));

    if (dcrType === "Doctor") dispatch(setDoctorsData());
    else if (dcrType === "Chemist") dispatch(setChemistsData());
    else dispatch(setStockistsData());
    return () => {
      dispatch(clearCities());
      dispatch(clearAreas());
      dispatch(clearProductRanges());
      dispatch(clearUsers());

      dispatch(clearDoctors());
      dispatch(clearChemists());
      dispatch(clearStockists());
    };
  }, [dispatch, dcrType]);

  const citySelect = useMemo(
    () => generateSelectData(cityData.data, "name"),
    [cityData]
  );

  const areaSelect = useMemo(
    () => generateSelectData(areasData.data, "name"),
    [areasData]
  );
  const targetSelect = useMemo(
    () => generateSelectData(targetData.data, "name"),
    [targetData]
  );
  const rangeSelect = useMemo(
    () => generateSelectData(rangesData.data, "name"),
    [rangesData]
  );
  const employeeSelect = useMemo(
    () => generateSelectData(userData, "name"),
    [userData]
  );

  const areaColumns = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "id",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   width: 30,
      // },
      {
        Header: "From Area",
        accessor: "fromAreaName",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "To Area",
        accessor: "toAreaName",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  const doctorColumns = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "id",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   width: 30,
      // },
      {
        Header: `${dcrType} Name`,
        accessor: "doctorName",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: dcrType === "Doctor" ? "Specialization" : "Certification",
        accessor: dcrType === "Doctor" ? "specialization" : "certification",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [dcrType]
  );

  const productColumns = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "id",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   width: 30,
      // },
      {
        Header: "Product Name",
        accessor: "productName",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  const pobColumns = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "id",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   width: 30,
      // },
      {
        Header: "Product Name",
        accessor: "productName",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Amount",
        accessor: "amount",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  const employeeColumns = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "id",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   width: 30,
      // },
      {
        Header: "Employee Name",
        accessor: "employeeName",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Designation",
        accessor: "designation",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return <span>{getDesignation(props?.row?.original?.des)}</span>;
        },
      },
    ],
    []
  );

  const [date, setDate] = useState(new Date());
  const [visitType, setVisitType] = useState("");
  const [visitTime, setVisitTime] = useState("");

  const [cities, setCities] = useState("");
  const [areas, setAreas] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [focusProducts, setFocusProducts] = useState([]);
  const [otherProducts, setOtherProducts] = useState([]);
  const [preOrderBookings, setPreOrderBookings] = useState([]);
  const [workedWithArr, setWorkedWithArr] = useState([]);
  const [remarks, setRemarks] = useState("");

  const [workAreaIds, setWorkAreaIds] = useState({
    fromAreas: [],
    toAreas: [],
  });
  const [doctorIds, setDoctorIds] = useState([]);
  const [focusProductIds, setFocusProductIds] = useState([]);
  const [otherProductIds, setOtherProductIds] = useState([]);
  const [workedWithIds, setWorkedWithIds] = useState([]);

  const [fromArea, setFromArea] = useState("");
  const [toArea, setToArea] = useState("");

  const [target, setTarget] = useState("");

  const [focusProduct, setFocusProduct] = useState("");
  const [otherProduct, setOtherProduct] = useState("");
  const [pobFocus, setPobFocus] = useState("");
  const [quantity, setQuantity] = useState("");
  const [amount, setAmount] = useState("");

  const [workedWith, setWorkedWith] = useState("");

  const [areaSr, setAreaSr] = useState(1);
  const [targetSr, setTargetSr] = useState(1);
  const [focusSr, setFocusSr] = useState(1);
  const [otherSr, setOtherSr] = useState(1);
  const [pobSr, setPobSr] = useState(1);
  const [workedWithSr, setWorkedWithSr] = useState(1);

  useEffect(() => {
    dispatch(setAreasData(cities.value));
    return () => {
      dispatch(clearAreas());
    };
  }, [dispatch, cities]);

  const resetForm = () => {
    setDate(new Date());
    setVisitType("");
    setVisitTime("");
    setAreas([]);
    setDoctors([]);
    setFocusProducts([]);
    setOtherProducts([]);
    setPreOrderBookings([]);
    setWorkedWithArr([]);
    setRemarks("");

    setWorkAreaIds({});
    setDoctorIds([]);
    setFocusProductIds([]);
    setOtherProductIds([]);
    setWorkedWithIds([]);

    setFromArea("");
    setToArea("");
    setTarget("");
    setFocusProduct("");
    setOtherProduct("");
    setPobFocus("");
    setQuantity("");
    setAmount("");
    setWorkedWith("");

    setAreaSr(1);
    setTargetSr(1);
    setFocusSr(1);
    setOtherSr(1);
    setPobSr(1);
    setWorkedWithSr(1);
  };

  const handleAreaAdd = () => {
    if (!fromArea || !toArea) {
      customToast.error("Please Select Both Areas.");
      return;
    }

    let newAreas = [...areas];
    newAreas.push({
      id: areaSr,
      fromArea: fromArea.value,
      toArea: toArea.value,
      fromAreaName: fromArea.label,
      toAreaName: toArea.label,
    });

    const fromIds = workAreaIds.fromAreas;
    const toIds = workAreaIds.toAreas;
    fromIds.push(fromArea.value);
    toIds.push(toArea.value);

    setFromArea(null);
    setToArea(null);
    setWorkAreaIds({ fromAreas: fromIds, toAreas: toIds });

    setAreaSr((oldSr) => oldSr + 1);

    setAreas(newAreas);
  };

  const handleDoctorAdd = () => {
    if (!target) {
      customToast.error(`Please Select a ${dcrType}.`);
      return;
    }

    let newState = [...doctors];
    newState.push({
      id: targetSr,
      doctor: target.value,
      doctorName: target.label,
      specialization: target.specialization,
      certification: target.certification,
    });

    setTarget(null);
    setDoctorIds((old) => [...old, target.value]);
    setTargetSr((oldSr) => oldSr + 1);

    setDoctors(newState);
  };

  const handleFocusAdd = () => {
    if (!focusProduct) {
      customToast.error("Please Select a Focus Product.");
      return;
    }

    let newFocusProducts = [...focusProducts];
    newFocusProducts.push({
      id: focusSr,
      focusProduct: focusProduct.value,
      productName: focusProduct.label,
    });

    setFocusProduct(null);
    setFocusProductIds((old) => [...old, focusProduct.value]);
    setFocusSr((oldSr) => oldSr + 1);

    setFocusProducts(newFocusProducts);
  };

  const handleOtherAdd = () => {
    if (!otherProduct) {
      customToast.erro("Please Select an Other Product.");
      return;
    }

    let newOtherProducts = [...otherProducts];
    newOtherProducts.push({
      id: otherSr,
      otherProduct: otherProduct.value,
      productName: otherProduct.label,
    });

    setOtherProduct(null);
    setOtherProductIds((old) => [...old, otherProduct.value]);
    setOtherSr((oldSr) => oldSr + 1);

    setOtherProducts(newOtherProducts);
  };

  const handlePobAdd = () => {
    if (!pobFocus || !quantity || !amount) {
      customToast.error("Please Fill All POB Details.");
      return;
    }

    let newPobs = [...preOrderBookings];
    newPobs.push({
      id: pobSr,
      focusProduct: pobFocus.value,
      productName: pobFocus.label,
      quantity: quantity,
      amount: amount,
    });

    setPobFocus(null);
    setQuantity("");
    setAmount("");
    setPobSr((oldSr) => oldSr + 1);

    setPreOrderBookings(newPobs);
  };

  const handleWorkedWithAdd = () => {
    if (!workedWith) {
      customToast.error("Please Fill Worked With Field.");
      return;
    }

    let newWorkedWith = [...workedWithArr];
    newWorkedWith.push({
      id: workedWithSr,
      workedWith: workedWith.value,
      employeeName: workedWith.label,
      designation: "MR",
    });

    setWorkedWith(null);
    setWorkedWithIds((old) => [...old, workedWith.value]);
    setWorkedWithSr((oldSr) => oldSr + 1);

    setWorkedWithArr(newWorkedWith);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      date: date,
      visitType: visitType,
      visitTime: visitTime,

      workAreas: workAreaIds,
      doctors: doctorIds,
      focusProducts: focusProductIds,
      otherProducts: otherProductIds,
      pobDetails: preOrderBookings,
      workedWith: workedWithIds,
      remarks: remarks,
    };

    if (!visitType || !visitTime)
      return customToast.error(`Please Fill all DCR Details.`);
    else if (!areas.length)
      return customToast.error(`Please Select at least 1 Area.`);
    else if (!doctors.length)
      return customToast.error(`Please Select at least 1 ${dcrType}.`);

    let error = false;
    try {
      await dispatch(addDCR(dcrType, data));
    } catch (e) {
      error = true;
      customToast.error("We Encountered an Error");
    }

    if (!error) customToast.success(`${dcrType} DCR Submitted Successfully`);
    resetForm();
  };

  return (
    <form className="doctor-dcr__content" onSubmit={handleSubmit}>
      <div className="row">
        <div className="calendar-container col-lg-5">
          <StyledCalendar date={date} onChange={setDate} />
        </div>

        <div className="col-lg-6 doctor-dcr__content-visit-details">
          <h3 className="web-app__heading text-center mt-3">
            Visit Details <span className="asterisk-imp">*</span>
          </h3>

          <div className="doctor-dcr__content-visit-details-container">
            <div className="doctor-dcr__content-visit-details-call">
              <div>
                <input
                  type="radio"
                  name="visitType"
                  value="DCR"
                  id="dcr"
                  onChange={({ target }) => setVisitType(target.value)}
                />
                <label htmlFor="dcr">Daily Call Report</label>
              </div>

              <div>
                <input
                  type="radio"
                  name="visitType"
                  value="Reminder"
                  id="reminder"
                  onChange={({ target }) => setVisitType(target.value)}
                />
                <label htmlFor="reminder">Reminder</label>
              </div>
            </div>

            <div className="doctor-dcr__content-visit-details-time">
              <label htmlFor="time">Select Time</label>
              <div>
                <input
                  value={visitTime}
                  onChange={({ target }) => setVisitTime(target.value)}
                  name="time"
                  type="time"
                />
                <span>
                  <MdOutlineSchedule />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row row-bg">
        <div className="form__group">
          <label htmlFor="from-area">SELECT CITY</label>
          <Select
            value={cities}
            options={citySelect}
            onChange={(e) => setCities({ value: e.value, label: e.label })}
            styles={dcrStyles}
            placeholder="Select Cities"
          />
        </div>
        <div className="dcr__area">
          <div className="form__group">
            <label htmlFor="from-area">
              From Area <span className="asterisk-imp">*</span>
            </label>
            <Select
              value={fromArea}
              options={areaSelect}
              onChange={(e) => setFromArea({ value: e.value, label: e.label })}
              styles={dcrStyles}
              placeholder="Select From Area"
            />
          </div>
          <div className="form__group">
            <label htmlFor="to-area">
              To Area <span className="asterisk-imp">*</span>
            </label>
            <Select
              value={toArea}
              options={areaSelect}
              onChange={(e) => setToArea({ value: e.value, label: e.label })}
              styles={dcrStyles}
              placeholder="Select To Area"
            />
          </div>
        </div>
        <div className="dcr__button text-center">
          <hr className="dcr__button-hr dcr__button-hr-first" />
          <button
            type="button"
            className="dcr__btn-green-outlined"
            onClick={handleAreaAdd}
          >
            <MdOutlineAddCircleOutline />
          </button>
          <hr className="dcr__button-hr dcr__button-hr-second" />
        </div>
        <div className="dcr-table">
          <Table className="border-none" columns={areaColumns} data={areas} />
        </div>
      </div>

      <div className="row row-bg">
        <div className="dcr__center">
          <div className="form__group">
            <label htmlFor="doctor-name">
              {dcrType} Name <span className="asterisk-imp">*</span>
            </label>
            <Select
              id="doctor-name"
              value={target}
              options={targetSelect}
              onChange={(e) =>
                setTarget({
                  value: e.value,
                  label: e.label,
                  specialization: e.specialization,
                  certification: e.certification,
                })
              }
              styles={dcrStyles}
              placeholder={`Select ${dcrType}`}
            />
          </div>
        </div>
        <div className="dcr__button text-center">
          <hr className="dcr__button-hr dcr__button-hr-first" />
          <button
            type="button"
            className="dcr__btn-green-outlined"
            onClick={handleDoctorAdd}
          >
            <MdOutlineAddCircleOutline />
          </button>
          <hr className="dcr__button-hr dcr__button-hr-second" />
        </div>
        <div className="dcr-table">
          <Table
            className="border-none"
            columns={doctorColumns}
            data={doctors}
          />
        </div>
      </div>

      <div className="row row-bg">
        <div className="dcr__center">
          <div className="form__group">
            <label htmlFor="focus-product-name">
              Focus Product <span className="asterisk-imp">*</span>
            </label>
            <Select
              id="focus-product-name"
              value={focusProduct}
              options={rangeSelect}
              onChange={(e) =>
                setFocusProduct({ value: e.value, label: e.label })
              }
              styles={dcrStyles}
              placeholder="Select Product"
            />
          </div>
        </div>

        <div className="dcr__button text-center">
          <hr className="dcr__button-hr dcr__button-hr-first" />
          <button
            type="button"
            className="dcr__btn-green-outlined"
            onClick={handleFocusAdd}
          >
            <MdOutlineAddCircleOutline />
          </button>
          <hr className="dcr__button-hr dcr__button-hr-second" />
        </div>
        <div className="dcr-table">
          <Table
            className="border-none"
            columns={productColumns}
            data={focusProducts}
          />
        </div>
      </div>

      <div className="row row-bg">
        <div className="dcr__center">
          <div className="form__group">
            <label htmlFor="other-product-name">Other Product</label>
            <Select
              id="other-product-name"
              value={otherProduct}
              options={rangeSelect}
              onChange={(e) =>
                setOtherProduct({ value: e.value, label: e.label })
              }
              styles={dcrStyles}
              placeholder="Select Product"
            />
          </div>
        </div>
        <div className="dcr__button text-center">
          <hr className="dcr__button-hr dcr__button-hr-first" />
          <button
            type="button"
            className="dcr__btn-green-outlined"
            onClick={handleOtherAdd}
          >
            <MdOutlineAddCircleOutline />
          </button>
          <hr className="dcr__button-hr dcr__button-hr-second" />
        </div>
        <div className="dcr-table">
          <Table columns={productColumns} data={otherProducts} />
        </div>
      </div>

      <div className="row row-bg dcr__pob">
        <h3 className="web-app__heading mt-3 mb-5">Pre-Order Booking (POB) </h3>

        {/* <div className="dcr__radio">
          <div>
            <input
              type="radio"
              name="pobType"
              value="quantity"
              id="pob-type-qty"
              onChange={({ target }) => setPobType(target.value)}
            />
            <label htmlFor="pob-type-qty">Quantity Wise</label>
          </div>

          <div>
            <input
              type="radio"
              name="pobType"
              value="lumpsum"
              id="pob-type-lumpsum"
              onChange={({ target }) => setPobType(target.value)}
            />
            <label htmlFor="pob-type-lumpsum">Lumpsum Wise</label>
          </div>
        </div> */}

        <div className="dcr__area">
          <div className="form__group">
            <label htmlFor="pob-focus-product">
              Focus Product <span className="asterisk-imp">*</span>
            </label>
            <Select
              id="pob-focus-product"
              value={pobFocus}
              options={rangeSelect}
              onChange={(e) => setPobFocus({ value: e.value, label: e.label })}
              styles={dcrStyles}
              placeholder="Select Product"
            />
          </div>

          <div className="form__group">
            <label htmlFor="pob-qty">
              Quantity <span className="asterisk-imp">*</span>
            </label>
            <input
              id="pob-qty"
              type="text"
              className="form__txt"
              placeholder="Enter Quantity"
              value={quantity}
              onChange={({ target }) => setQuantity(target.value)}
            />
          </div>
        </div>

        <div className="dcr__area">
          <div className="form__group">
            <label htmlFor="pob-amt">
              Price to Stockist <span className="asterisk-imp">*</span>
            </label>
            <input
              id="pob-amt"
              type="text"
              className="form__txt"
              placeholder="Enter PTS"
              value={amount}
              onChange={({ target }) => setAmount(quantity * 10)}
            />
          </div>
        </div>

        <div className="dcr__button text-center">
          <hr className="dcr__button-hr dcr__button-hr-first" />
          <button
            type="button"
            className="dcr__btn-green-outlined"
            onClick={handlePobAdd}
          >
            <MdOutlineAddCircleOutline />
          </button>
          <hr className="dcr__button-hr dcr__button-hr-second" />
        </div>
        <div className="dcr-table">
          <Table
            className="border-none"
            columns={pobColumns}
            data={preOrderBookings}
          />
        </div>
      </div>

      <div className="row row-bg">
        <div className="dcr__center">
          <div className="form__group">
            <label htmlFor="employee-name">Worked With</label>
            <Select
              id="employee-name"
              value={workedWith}
              options={employeeSelect}
              onChange={(e) =>
                setWorkedWith({ value: e.value, label: e.label })
              }
              styles={dcrStyles}
              placeholder="Select Employees"
            />
          </div>
        </div>

        <div className="dcr__button text-center">
          <hr className="dcr__button-hr dcr__button-hr-first" />
          <button
            type="button"
            className="dcr__btn-green-outlined"
            onClick={handleWorkedWithAdd}
          >
            <MdOutlineAddCircleOutline />
          </button>
          <hr className="dcr__button-hr dcr__button-hr-second" />
        </div>
        <div className="dcr-table">
          <Table
            className="border-none"
            columns={employeeColumns}
            data={workedWithArr}
          />
        </div>
      </div>

      <div className="row row-bg">
        <div className="dcr__remarks">
          <label htmlFor="from-area">Remarks</label>
          <textarea
            className="form__txt"
            rows="5"
            placeholder="Enter Remarks"
            maxLength={80}
            value={remarks}
            onChange={({ target }) => setRemarks(target.value)}
          />
        </div>
      </div>

      <button className="button-submit">Submit</button>
    </form>
  );
};

export default DCRForm;
