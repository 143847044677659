import { Outlet } from "react-router-dom";
import ListsNav from "./../UtilLists/UtilListsSideNav";
import { title, links } from "./navData.js";

const UtilTargets = () => {
  return (
    <div className="content">
      <ListsNav title={title} links={links} />

      <Outlet />
    </div>
  );
};

export default UtilTargets;
