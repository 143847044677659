export const title = "Manage Profit Center";

export const links = [
  {
    name: "Products",
    route: "products",
    accessor: "products",
  },
  {
    name: "ROI",
    route: "roi",
    accessor: "roi",
  },
];
