import {
  MdOutlineAccountCircle,
  MdOutlineInventory2,
  MdOutlineLocationCity,
  MdOutlineMedication,
  MdOutlineMonetizationOn,
} from "react-icons/md";
import { RiStethoscopeLine } from "react-icons/ri";
import { BsCheck2Circle, BsFillCalendar2CheckFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { AiOutlineSetting } from "react-icons/ai";

const CreationLink = ({ title, type, link, className }) => {
  const renderIcon = (type) => {
    if (type === "Areas") return <MdOutlineLocationCity />;
    else if (type === "Users") return <MdOutlineAccountCircle />;
    else if (type === "Products") return <MdOutlineMedication />;
    else if (type === "Expenses") return <MdOutlineMonetizationOn />;
    else if (type === "Doctors") return <RiStethoscopeLine />;
    else if (type === "Sample") return <MdOutlineInventory2 />;
    else if (type === "Approval") return <BsCheck2Circle />;
    else if (type === "Attendance") return <BsFillCalendar2CheckFill />;
    else if (type === "Settings") return <AiOutlineSetting/>
  };

  return (
    <Link className="admin-panel__cards-link" to={`${link}`}>
      <div className={className}>
        {renderIcon(type)}

        <h3 className="web-app__heading">{title}</h3>
      </div>
    </Link>
  );
};

export default CreationLink;
