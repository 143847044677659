import { applyMiddleware, combineReducers, createStore } from "redux";

import thunk from "redux-thunk";

import {
  appReducer,
  userReducer,
  targetReportReducer,
  leaveReducer,
  reminderReducer,
  tpReducer,
  holidayReducer,
  dayPlanReducer,
  approveLengthReducer,
  socketReducer,
  SettingsReducer,
  adminSideNav,
  analysisSettingReducer,
} from "./reducers";

import {
  stateReducer,
  hqReducer,
  cityReducer,
  routeReducer,
} from "./reducers/locations";

import { allowanceReducer, expenseReducer } from "./reducers/expenses";
import {
  categoryReducer,
  groupReducer,
  typeReducer,
  rangeReducer,
  giftReducer,
  sampleReducer,
  supplierReducer,
  inventoryReducer,
  minStockReducer,
} from "./reducers/products";

import {
  doctorReducer,
  chemistReducer,
  stockistReducer,
} from "./reducers/targets";

import {
  designationReducer,
  divisionReducer,
  employeeReducer,
} from "./reducers/users";

import {
  giftManagementReducer,
  sampleManagementReducer,
  stockManagementReducer,
} from "./reducers/ssg";

import { primarySalesReducer } from "./reducers/Sales/index";
import { secondarySalesReducer } from "./reducers/reports/index";
import { PrimarySalesReportsReducer } from "./reducers/reports/index";
import { GiftAllotmentReducer } from "./reducers/reports/index";
import { SampleAllotmentReducer } from "./reducers/reports/index";
import { TargetReducer } from "./reducers/reports/index";

import announcementReducer from "./reducers/announcement";
import companyNameReducer from "./reducers/companyName"; 
import paymentStatusReducer from "./reducers/payment";
import NotificationReducer from "./reducers/notifications";
import deletionRequestReducer from "./reducers/deletion request/deletionRequestReducer";

const reducer = combineReducers({
  app: appReducer,
  adminNav : adminSideNav,
  user: userReducer,
  dcr: targetReportReducer,
  tp: tpReducer,
  dayPlan: dayPlanReducer,
  approvalLength: approveLengthReducer,
  settings: SettingsReducer,
  announcement: announcementReducer,
  companyName: companyNameReducer,
  // Locations
  state: stateReducer,
  headquarter: hqReducer,
  city: cityReducer,
  route: routeReducer,

  // Products
  productCategory: categoryReducer,
  productGroup: groupReducer,
  productType: typeReducer,
  productRange: rangeReducer,
  paymentStatus: paymentStatusReducer,
  gift: giftReducer,
  sample: sampleReducer,
  //Supplier and Inventory
  productsSupplier: supplierReducer,
  inventory: inventoryReducer,
  inventoryAlerts: minStockReducer,

  // Targets
  doctor: doctorReducer,
  chemist: chemistReducer,
  stockist: stockistReducer,

  // Users
  division: divisionReducer,
  designation: designationReducer,
  employee: employeeReducer,
  holiday: holidayReducer,
  leave: leaveReducer,
  reminder: reminderReducer,
  allowance: allowanceReducer,
  expense: expenseReducer,
  giftManagementReport: giftManagementReducer,
  sampleManagementReport: sampleManagementReducer,
  stockManagementReport: stockManagementReducer,

  // Analysis Setting
  analysisSetting: analysisSettingReducer,

  //Socket
  socket: socketReducer,
  secondarySalesReport: secondarySalesReducer,

  //sales-v2
  primarySales: primarySalesReducer,
  primarySalesReports: PrimarySalesReportsReducer,
  giftsAllotment : GiftAllotmentReducer,
  samplesAllotment : SampleAllotmentReducer,
  targetReducer : TargetReducer,
  notification:NotificationReducer,

  // deletion request
  deletionRequest: deletionRequestReducer,
});

const store = createStore(reducer, applyMiddleware(thunk));

export default store;
