import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURL = `${apiURL}/client/dcs/`;

export const allotDcs = async (details, type) => {
    const { data } = await axios.post(baseURL + `${type.toLowerCase()}/allot`, details, {
      headers: setAuth(),
    });
  
    return data;
  };

  export const deallotDCS = async (details, type) => {
    const { data } = await axios.post(baseURL + `${type.toLowerCase()}/deallot` , details, {
      headers: setAuth(),
    });
  
    return data;
  };
  