import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURL = `${apiURL}/client/v1`;

export const viewDuplicateEntries= async (entity) => {
    try {
      const { data } = await axios.get(`${baseURL}/duplication`, {
        params: entity,
        headers: setAuth(),
      });
      return data;
    } catch (e) {
      throw e;
    }
  };

export const ignoreDuplicateEntry= async (details) => {
    try {
      const { data } = await axios.get(`${baseURL}/duplication/ignore`, {
        params: details,
        headers: setAuth(),
      });
      return data;
    } catch (e) {
      throw e;
    }
  };