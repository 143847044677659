import { Outlet } from "react-router-dom";

import AdminNav from "../AdminSidenav";
import { title, links } from "./navData.js";
import { useDispatch, useSelector } from "react-redux";
import { getPreference } from "../../../reducers/settings";

const AdminUsers = () => {
  const headquarterWise = useSelector((state) => state?.settings["hq-target"]);
  const { settings } = useSelector(state => state)
  let valueToggle = "false";

  if(headquarterWise === "true"){
    valueToggle = "true"
    // console.log(valueToggle === "false")

  }
  
  let activeLinks = [...links];

  
  if(valueToggle === "true") activeLinks.splice(7,1)
	else activeLinks.splice(8,1)
	
  return (
    <>
      <AdminNav title={title} links={activeLinks} />

      <Outlet />
    </>
  );
};

export default AdminUsers;
