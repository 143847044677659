import { useLocation } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../../../utils/helper";
import { MdShareLocation } from "react-icons/md";
import { monthOptions } from "../../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  MdDeleteOutline,
  MdCheckCircleOutline,
  MdDelete,
} from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";
import { useState } from "react";
import { deleteTP } from "../../../../../reducers/tpReducer";
import customToast from "../../../../../components/CustomToast";
const TourDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const loggedIn = useSelector(({ user }) => user);
  const [showEdit, setShowEdit] = useState(false);

  const EditPopup = () => {
    const dispatch = useDispatch();

    const handleDelete = (e) => {
      e.preventDefault();
      try{
        dispatch(deleteTP(location?.state?._id))
        navigate(-1);
        setShowEdit(false);
        customToast.success("Deleted successfully")
      }
      catch(err){
        customToast.error("We encountered and error")
      }
      
    };

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete Tour</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form onSubmit={handleDelete}>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  Do you really want to delete this Tour?
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-delete mt-4">
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Yes</span>
              </button>

              <button
                type="button"
                className="button-submit-green mt-4"
                onClick={() => setShowEdit(false)}
              >
                <span className="button-submit-green__icon-container">
                  <MdCheckCircleOutline className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">No</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  return (
    <div className="utility-inner-tp">
      <div className="row">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center", justifyContent:"space-between" }}
        >
          <div>
            <MdArrowBackIosNew
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <span style={{ display: "inline-block", margin: "0 1rem" }}>
              Tour Program Details
            </span>
          </div>
          {loggedIn?.user?.isAdmin?<span
              className="react-table-view-link"
              onClick={() => {
                setShowEdit(true);
              }}
            >
              <MdDelete className="icon-color-error" />
          </span>:null}
        </h2>

        <div className="util-inner-details">
          <div className="util-inner-info-box-container">
            <div className="util-inner-info-box">
              <h3>Date</h3>
              <p>{formatDate(location?.state?.date)}</p>
            </div>

            <div className="util-inner-info-box">
              <h3>Month</h3>
              <p>
                {
                  monthOptions[new Date(`${location?.state?.date}`).getMonth()]
                    ?.label
                }
              </p>
            </div>

            <div className="util-inner-info-box">
              <h3>Deviated</h3>
              <p>{location?.state?.deviated === true ? "Yes" : "No"}</p>
            </div>

            <div className="util-inner-info-box">
              <h3>Area Type</h3>
              <p>{location?.state?.areaType}</p>
            </div>
          </div>
        </div>

        <div className="util-inner-location-details">
          {location?.state?.workAreas?.map((el) => (
            <div className="util-inner-location-box">
              <MdShareLocation className="util-inner-location-box-icon" />
              <p>{el?.location?.label}</p>
            </div>
          ))}
        </div>

        {/* <h2 className="web-app__heading mt-5">Doctors</h2>
        <div className="util-inner-tp-types">
          {location?.state?.doctors?.map((el) => (
            <div className="util-inner-types-detail util-inner-types-detail__doc">
              <RiStethoscopeFill className="util-inner-location-types-icon util-inner-location-types-icon__doc" />
              <p>{el?.name}</p>
            </div>
          ))}
        </div>

        <h2 className="web-app__heading mt-5">Chemists</h2>
        <div className="util-inner-tp-types">
          {location?.state?.chemists?.map((el) => (
            <div className="util-inner-types-detail util-inner-types-detail__chem">
              <RiCapsuleFill className="util-inner-location-types-icon util-inner-location-types-icon__chem" />
              <p>{el?.name}</p>
            </div>
          ))}
        </div>

        <h2 className="web-app__heading mt-5">Stockists</h2>
        <div className="util-inner-tp-types">
          {location?.state?.stockists?.map((el) => (
            <div className="util-inner-types-detail util-inner-types-detail__stk">
              <MdMedicalServices className="util-inner-location-types-icon util-inner-location-types-icon__stk" />
              <p>{el?.name}</p>
            </div>
          ))}
        </div>

        <h2 className="web-app__heading mt-5">Worked With</h2>
        <div className="util-inner-workedWith">
          {location?.state?.workedWith?.map((el) => (
            <p className="util-inner-remarks-para">{el?.name}</p>
          ))}
        </div> */}

        {/* <h2 className="web-app__heading mt-5">Remarks</h2> */}
        <div className="d-flex flex-row">
          <h4 style={{color:"white", margin:"1.5rem"}}>Remarks : </h4>
          <p className="util-inner-remarks-para">{location?.state?.remarks}</p>
        </div>
      </div>
      {showEdit ? <EditPopup  /> : null}
    </div>
  );
};

export default TourDetails;
