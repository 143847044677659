import {
  newProduct,
  viewProducts,
  editProduct,
  deleteProduct,
  uploadProduct,
  newProductV1,
  viewProductV1,
  updateProductRange,
  deleteProductRangeV1,
  uploadProductV1,
} from "../../services/products.js";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PRODUCT_RANGE":
      return action.payload;

    case "CREATE_PRODUCT_RANGE":
      return { ...state };

    case "UPLOAD_PRODUCT_RANGE":
      return { ...state };

    case "CLEAR_PRODUCT_RANGE":
      return initialState;

    case "EDIT_PRODUCT_RANGE":
      return { ...state, newState: action.payload };

    case "DELETE_PRODUCT_RANGE":
      return initialState;

    default:
      return state;
  }
};

export const setProductRanges = (details) => {
  return async (dispatch) => {
    // const { data } = await viewProducts("", details);
    const { data } = await viewProductV1(details);
    dispatch({
      type: "SET_PRODUCT_RANGE",
      payload: { data: data },
    });
  };
};

export const addProductRange = (data) => {
  return async (dispatch) => {
    try {
      // data = await newProduct("", data);
      data = await newProductV1(data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "CREATE_PRODUCT_RANGE",
      payload: { data: data },
    });
    dispatch(setProductRanges("", data));
  };
};

export const uploadProductRange = (data) => {
  return async (dispatch) => {
    try {
      // data = await uploadProduct(data);
      data = await uploadProductV1(data);
    } catch (e) {
      throw e.response;
    }

    dispatch({
      type: "UPLOAD_PRODUCT_RANGE",
      payload: { data: data },
    });
    return data ;
  };
};

export const clearProductRanges = () => {
  return {
    type: "CLEAR_PRODUCT_RANGE",
  };
};

export const editProductRange = (id, data) => {
  return async (dispatch) => {
    try {
      // data = await editProduct("range", id, data);
      data = await updateProductRange(id, data);
    } catch (e) {
       throw e;
    }

    dispatch({
      type: "EDIT_PRODUCT_RANGE",
      payload: { data: data },
    });
    dispatch(setProductRanges());
  };
};

export const deleteProductRange = (id, data) => {
  return async (dispatch) => {
    try {
      // data = await deleteProduct("range",id, data);
      data = await deleteProductRangeV1(id, data);
    } catch (e) {
      if (data.error) throw data.error;
    }
    dispatch({
      type: "DELETE_PRODUCT_RANGE",
      payload: { data: data },
    });
    dispatch(setProductRanges());
  };
};

export default reducer;
