import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURL = `${apiURL}/client/v1/settings`;
// const baseURLV1 = `${apiURL}/client/v1/settings`;

export const toggleSetting = async (type, value) => {
  const { data } = await axios.get(baseURL + "/toggle/" + type, {
    params: value,
    headers: setAuth(),
  });

  return data;
};

// export const toggleSettingV1 = async (type, value) => {
//   const { data } = await axios.get(baseURLV1 + "/toggle/" + type, {
//     params: value,
//     headers: setAuth(),
//   });

//   return data;
// };

export const getSettingPreference = async (type, value) => {
  const { data } = await axios.get(baseURL + "/" + type, {
    params: value,
    headers: setAuth(),
  });

  return data;
};
// export const getSettingPreferenceV1 = async (type, value) => {
//   const { data } = await axios.get(baseURLV1 + "/" + type, {
//     params: value,
//     headers: setAuth(),
//   });

//   return data;
// };

export const addAnnouncement = async (value) => {
  const { data } = await axios.post(baseURL + "/announcement", value, {
    headers: setAuth(),
  });
  return data;
}
// export const addAnnouncementV1 = async (value) => {
//   const { data } = await axios.post(baseURLV1 + "/announcement", value, {
//     headers: setAuth(),
//   });
//   return data;
// }

// export const getAnnouncementV1 = async (type, value) => {
//   const { data } = await axios.get(baseURLV1 + "/announcement", {
//     params: value,
//     headers: setAuth(),
//   });
//   return data;
// }
export const getAnnouncement = async (type, value) => {
  const { data } = await axios.get(baseURL + "/announcement", {
    params: value,
    headers: setAuth(),
  });
  return data;
}

export const addCompanyName = async (value) => {
  const { data } = await axios.post(baseURL + "/companyName", value, {
    headers: setAuth(),
  });
  return data;
}
// export const addCompanyNameV1 = async (value) => {
//   const { data } = await axios.post(baseURLV1 + "/companyName", value, {
//     headers: setAuth(),
//   });
//   return data;
// }
// export const getCompanyNameV1 = async (type, value) => {
//   const { data } = await axios.get(baseURLV1 + "/companyName", {
//     params: value,
//     headers: setAuth(),
//   });
//   return data;
// }
export const getCompanyName = async (type, value) => {
  const { data } = await axios.get(baseURL + "/companyName", {
    params: value,
    headers: setAuth(),
  });
  return data;
}

export const getMinCallReport = async (par) =>{
  const {data} = await axios.get(baseURL + "/minCallReports",{
    params:par,
    headers:setAuth(),
  })
  return data ;
}
// export const getMinCallReportV1 = async (par) =>{
//   const {data} = await axios.get(baseURLV1 + "/minCallReports",{
//     params:par,
//     headers:setAuth(),
//   })
//   return data ;
// }

export const postMinCallReport = async (value) => {
  const { data } = await axios.post(baseURL + "/minCallReports", value, {
    headers: setAuth(),
  });
  return data;
}
// export const postMinCallReportV1 = async (value) => {
//   const { data } = await axios.post(baseURLV1 + "/minCallReports", value, {
//     headers: setAuth(),
//   });
//   return data;
// }

export const userPreferences = async(details) =>{
  const {data} = await axios.get(baseURL + "/preferences", {
    params:details,
    headers:setAuth() 
  })
  return data ;
}

// export const userPreferencesV1 = async(details) =>{
//   const {data} = await axios.get(baseURLV1 + "/preferences", {
//     params:details,
//     headers:setAuth() 
//   })
//   return data ;
// }

export const updateUserPreferences = async(details)=>{
  const {data} = await axios.post(baseURL + "/preferences", details, {
    headers:setAuth()
  })
  return data ;
}

// export const updateUserPreferencesV1 = async(details)=>{
//   const {data} = await axios.post(baseURLV1 + "/preferences", details, {
//     headers:setAuth()
//   })
//   return data ;
// }
export const updateViewAllHqsToggle = async(details) => {
  const {data} = await axios.post(baseURL + "/viewAll-hqs", details, {
    headers: setAuth()
  });
  return data;
}