import { FaEye } from "react-icons/fa";
import { MdArrowBackIosNew } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import DateRange from "../../../../components/DateRange";
import Table from "../../../../components/Table";

const dummyData = {
  columns: [
    {
      Header: "Date",
      accessor: "date",
      disableFilters: true,
    },
    {
      Header: "From Area",
      accessor: "fromArea",
      filter: "fuzzyText",
      disableSortBy: true,
    },
    {
      Header: "To Area",
      accessor: "toArea",
      filter: "fuzzyText",
      disableSortBy: true,
    },
    {
      Header: "Stockists",
      accessor: "stockist",
      filter: "fuzzyText",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "View Details",
      accessor: "details",
      Cell: (props) => (
        <Link className="table-link" to={props.value}>
          <FaEye className="icon-color-tertiary" />
        </Link>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
  ],
  data: [
    {
      date: "Feb 7, 2022",
      fromArea: "Area A",
      toArea: "Area P",
      stockist: "Stockist 1",
      details: "1",
    },
    {
      date: "Feb 8, 2022",
      fromArea: "Area B",
      toArea: "Area Q",
      stockist: "Stockist 2",
      details: "2",
    },
    {
      date: "Feb 9, 2022",
      fromArea: "Area C",
      toArea: "Area R",
      stockist: "Stockist 3",
      details: "3",
    },
    {
      date: "Feb 10, 2022",
      fromArea: "Area D",
      toArea: "Area S",
      doctors: "Doctor 4",
      stockist: "Stockist 4",
      details: "4",
    },
  ],
};

const UtiltiesTargetVsAchievements = () => {
  const navigate = useNavigate();

  return (
    <div className="expense" onClick={() => navigate("/targets-achievements")}>
      <div className="row">
        <h2
          className="web-app__heading2"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MdArrowBackIosNew
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span style={{ display: "inline-block", margin: "0 1rem" }}>
            Target Vs Achievements
          </span>
        </h2>

        <div className="date-range-container my-5">
          <DateRange />
        </div>

        <div className="filter-table">
          <Table columns={dummyData.columns} data={dummyData.data} />
        </div>
      </div>
    </div>
  );
};

export default UtiltiesTargetVsAchievements;
