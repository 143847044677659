import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import customToast from "../../../../components/CustomToast";
import ProfitAnalysisBox from "../../../../components/ProfitAnalysisBox";
import PageTitle from "../../../../components/PageTitle";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../reducers/locations/hqReducer";
import serialise, { generateSelectData } from "../../../../utils/serialiseResponse";
import { monthOptions, yearOptions } from "../../../../utils/helper";
import { viewTotalCost } from "../../../../services/profitCenter";
import Table from "../../../../components/Table";

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    border: "2px solid #36ABF9",
    borderRadius: "5px",
    color: "#9e9ad0",
    fontSize: "1.5rem",
    fontWeight: "500",

    width: "20rem",
    cursor: "pointer",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",
    fontFamily: "inherit",
    fontSize: "1.5rem",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
      color: "#9E9AD1",
    },
  }),
};

const TotalCost = () => {
  const dispatch = useDispatch();
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const [month, setMonth] = useState(null);
  const [tdata, setTdata] = useState([]);
  const [year, setYear] = useState(yearOptions[yearOptions.length-1]);
  const [hq, setHq] = useState(null);
  const [expense, setExpense] = useState(0);
  const [employeeCount, setEmployeeCount] = useState(0);

  const headquarterSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );
  
  useEffect(()=>{
    let e = 0, c = 0;
    for(const x of tdata){
      e += x?.total || 0 ;
      c += x?.quantity || 0;
    }
    setExpense(e);
    setEmployeeCount(c);
  },[tdata]);

  useEffect(() => {
    dispatch(setHeadquarters());
    return () => {
      dispatch(clearHeadquarters());
    };
  }, [dispatch]);

  useEffect(()=>{
    if(!month || !year || !hq)return ;
    viewTotalCost({month:month?.value, year:year?.label, hq:hq?.value}).then(({data})=>{
      setTdata(data);
    }).catch((err)=>{
      customToast.error("Sorry Something went wrong!")
    }) ;
  },[month, year, hq]) ;
  
  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   minWidth: 50,
      //   disableFilters: true,
      //   maxWidth: 50,
      // },
      {
        Header: "Item",
        accessor: "item",
        disableSortBy: true,
        disableFilters:true,
        showTotal:true,
        placeholderTotal:"Total"
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        disableSortBy: true,
        disableFilters:true,
        showTotal:true,
        totalAccessor:"quantity"
      },
      {
        Header: "Total",
        accessor: "total",
        disableSortBy: true,
        disableFilters:true,
        showTotal:true,
        totalAccessor:"total"
      },
    ],
    []
  );
  const tableData = useMemo(()=>serialise(tdata),[tdata]);

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Total Expenses" previousUrl="/profit-analysis"/>
        <section className="area-creation-content__form">
          <form >
            <div className="d-flex flex-wrap gap-5 justify-content-start ">
              <div className="d-flex flex-wrap gap-5 ">
                <div className="util-tp-filter">
                  <p>
                    Select Month <span className="asterisk-imp">*</span>
                  </p>
                  <Select
                    styles={selectStyles}
                    placeholder="Select Month"
                    className="mt-3"
                    options={monthOptions}
                    value={month}
                    onChange={(e) => setMonth({ ...e })}
                  />
                </div>
                <div className="util-tp-filter">
                  <p>
                    Select Year <span className="asterisk-imp">*</span>
                  </p>
                  <Select
                    styles={selectStyles}
                    placeholder="Select Year"
                    className="mt-3"
                    options={yearOptions}
                    value={year}
                    onChange={(e) => setYear({ ...e })}
                  />
                </div>
                <div className="util-tp-filter">
                  <p>
                    Select Headquarter <span className="asterisk-imp">*</span>
                  </p>
                  <Select
                    styles={selectStyles}
                    placeholder="Select HQ"
                    className="mt-3"
                    options={headquarterSelect}
                    value={hq}
                    onChange={(e) => setHq({ ...e })}
                  />
                </div>
              </div>
              <ProfitAnalysisBox row1={`${employeeCount} EMPLOYEES`} 
                row2={`Period: ${month?.label ? month?.label : ""} ${year?.label} ${new Date(year?.label, Number(month?.value)+1, 0).getDate() ? " | "+new Date(year?.label, Number(month?.value)+1, 0).getDate()+" Days" : ""}`} 
                row3={`Rs ${expense}`} row4="TOTAL EXPENSE"
              />
            </div>
          </form>
        </section>
      </div>
      <div className="filter-table pe-4">
        <Table columns={columns} data={tableData} />
      </div>
    </main>
  );
};

export default TotalCost;
