import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { viewSales } from "../../../../../services/sales";
import Table from "../../../../../components/Table";

const columnData = [
  {
    title: "Product",
    field: "",
    render: (rowData) => `${rowData?.product?.name}`,
  },
  {
    title: "PTR",
    field: "",
    render: (rowData) => `${rowData?.product?.ptr}`,
  },
  {
    title: "PTS",
    field: "",
    render: (rowData) => `${rowData?.product?.pts}`,
  },
  { title: "Opening Balance", field: "openingBalance" },
  { title: "Received", field: "received" },
  { title: "Total Qty", field: "totalQty" },
  { title: "Primary Qty", field: "primaryQty" },
  { title: "Primary Value", field: "primaryValue" },
  { title: "Sales Return", field: "salesReturn" },
  { title: "Sales Return Value", field: "salesReturnValue" },
  { title: "Secondary Qty", field: "secondaryQty" },
  { title: "Secondary Value", field: "secondaryValue" },
  { title: "Closing Qty", field: "closingQty" },
  { title: "Closing Value", field: "closingValue" },
  { title: "Transit", field: "transit" },
];

const StkWiseSalesDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [reports, setReports] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: "Product Name",
        accessor: "product.name",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "PTS",
        accessor: "product.pts",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "PTR",
        accessor: "product.ptr",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Primary Qty",
        accessor: "primaryQty",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Primary Value",
        accessor: "primaryValue",
        disableFilters: true,
        disableSortBy: true,
      },

      {
        Header: "Sales Return",
        accessor: "salesReturn",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Sales Return Value",
        accessor: "salesReturnValue",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Secondary Qty",
        accessor: "secondaryQty",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Secondary Value",
        accessor: "secondaryValue",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Closing Qty",
        accessor: "closingQty",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Closing Value",
        accessor: "closingValue",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Transit",
        accessor: "transit",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  useEffect(() => {
    requestSalesData();
  }, []);

  async function requestSalesData() {
    await viewSales({
      month: location.state?.month?.value,
      year: Number(location.state?.year.label),
      stk: location.state?.data?.id,
    }).then((res) => setReports([...res.data]));
  }

  return (
    <div className="expense">
      <div className="row">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MdArrowBackIosNew
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span style={{ display: "inline-block", margin: "0 1rem" }}>
            Sales Reports
          </span>
        </h2>

        <div className="filter-table">
          {/* <MaterialTable
            options={{
              exportButton: true,
              emptyRowsWhenPaging: false,
              actionsColumnIndex: -1,
              pageSizeOptions: [5, 10, 20, 50, 100],
            }}
            columns={columnData}
            data={reports}
            title="Sales Analysis"
          /> */}

          <Table columns={columns} data={reports} />
        </div>
      </div>
    </div>
  );
};

export default StkWiseSalesDetail;
