import { useEffect, useState, useMemo } from "react";
import PageTitle from "../../../../components/PageTitle";
import Select from "react-select";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import serialise, { generateSelectData } from "../../../../utils/serialiseResponse";
import { LinkCD, ViewLinkedCD, cdLink, deleteLinkedCD, updateLinkedCD, viewTargets } from "../../../../services/targets";
import Table from "../../../../components/Table";
import { RiCloseCircleLine, RiEdit2Fill } from "react-icons/ri";
import customToast from "../../../../components/CustomToast";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";

const ChemistDoctor = () => {
  const [docs, setDocs] = useState([]);
  const [chemists, setChemists] = useState([]);
  const [selectedChemist, setSelectedChemist] = useState();
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [editDoctors, setEditDoctors] = useState([]);
  const [editChemDoc, setEditChemDoc] = useState(null);
  const [isEdited, setIsEdited] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [chemDocList, setChemDocList] = useState([]);

  useEffect(() => {
    viewTargets("doctor", { showApproved: true }).then((response) => {
      setDocs(response?.data);
    }).catch(e => console.log(e));

    viewTargets("chemist", { showApproved: true }).then((response) => {
      setChemists(response?.data);
    }).catch(e => console.log(e));

  }, []);

  useEffect(() => {
    ViewLinkedCD().then((response) => {
      setChemDocList(response.data);
    });
  }, [isEdited, isCreated, isDeleted]);

  const EditPopup = ({ data }) => {
    const handleEdit = async (e) => {
      try {
        e.preventDefault();
        if (editDoctors.length === 0)
          return customToast.error("Field cannot be empty");
        let doctorIds = [];

        editDoctors.forEach((el) => {
          doctorIds.push(el._id);
        });
        let data = await updateLinkedCD({ doctorIds, chemDocId: editChemDoc });
        customToast.success("Doctors updated successfully");
        setIsEdited(!isEdited);
        setShowEdit(false);
      } catch (err) {
        customToast.error("Sorry, Unable to update details");
      }
    };
    const handleDelete = async () => {
      try {
        await deleteLinkedCD({ chemDocId: editChemDoc });
        setShowEdit(false);
        customToast.success("Deleted successfully");
        setIsDeleted(!isDeleted);
      } catch (err) {
        customToast.error("Sorry, Unable to delete");
      }
    };
    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Edit Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form onSubmit={handleEdit}>
            <div className="edit-form">
              <div>
                <label htmlFor="state">
                  Edit Doctors <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  isMulti
                  name="doctors"
                  id="doctors"
                  value={editDoctors}
                  options={doctorSelect}
                  onChange={(e) => setEditDoctors([...e])}
                  styles={{ ...adminStyles }}
                  placeholder="Select Products"
                  className="multi-select-dropdown w-100"
                />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-submit-green mt-4">
                <span className="button-submit-green__icon-container">
                  <MdOutlineEdit className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Edit</span>
              </button>

              <button
                type="button"
                className="button-delete mt-4"
                onClick={handleDelete}
              >
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Chemist",
        accessor: "name",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Doctors",
        accessor: "docArr",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 140,
        maxWidth: 140,
        Cell: (props) => {
          // console.log(props?.row?.original);
          return props?.row?.original?.docArr?.map((e) => (
            <span key={e._id}>
              {e.name} <br />
            </span>
          ));
        },
      },
      // {
      //   Header: "Percentage",
      //   accessor: "percentage",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Actions",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 40,
        maxWidth: 40,
        Cell: (props) => (
          <span
            onClick={() => {
              setEditDoctors(
                props?.row?.original?.docArr?.map((e) => ({
                  ...e,
                  label: e.name,
                  value: e._id,
                }))
              );
              setEditChemDoc(props?.row?.original._id);
              setShowEdit(true);
            }}
            className="react-table-view-link"
          >
            <RiEdit2Fill className="icon-color-green" />
          </span>
        ),
      },
    ],
    []
  );

  const doctorSelect = useMemo(() => generateSelectData(docs, "name"), [docs]);
  const chemistSelect = useMemo(() => generateSelectData(chemists, "businessName"), [chemists]);

  const SubmitHandler = async (e) => {
    try {
      e.preventDefault();
      if (!selectedChemist || selectedDoctors.length === 0)
        return customToast.error("Please fill all the fields");
      let data = await LinkCD({ chemist: selectedChemist._id, doctors: selectedDoctors });
      if (data) {
        setIsCreated(!isCreated);
        setSelectedChemist(null);
        setSelectedDoctors([]);
        return customToast.success("List successfully added");
      }
    } catch (err) {
      return err.response.status === 403
      ? customToast.error("Chemist already exists!")
      : customToast.error("An unexpected error occured");
    }
  };

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Chemist Doctors List" isCRM={true} />

        <section className="area-creation-content__info">
          <form onSubmit={SubmitHandler}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-3">
                <label htmlFor="chemist">Select Chemist</label>
                <br />
                <Select
                  name="chemist"
                  id="chemist"
                  value={selectedChemist}
                  options={chemistSelect}
                  onChange={(e) => setSelectedChemist({ ...e })}
                  styles={adminStyles}
                  placeholder="Select Chemist"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3">
                <label htmlFor="doctors">Select Doctors</label>
                <br />
                <Select
                  isMulti
                  name="doctors"
                  id="doctors"
                  value={selectedDoctors}
                  options={doctorSelect}
                  onChange={(e) => setSelectedDoctors([...e])}
                  styles={adminStyles}
                  placeholder="Select Doctors"
                  className=""
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 area-creation-content__form-submit">
                <button type="submit" className="button-blue-gradient">
                ADD
                </button>
              </div>
            </div>
            {/* <div className="col-sm-12 col-md-4 col-lg-4 mt-4 admin-creation-content__form-submit mt-5">
              <button type="submit" className="button-blue-gradient">
                ADD
              </button>
            </div> */}
          </form>

          <div className="filter-table">
            {chemDocList?.length > 0 && (
              <h2 className="web-app__heading mb-4 ms-2">
                Showing ({chemDocList?.length}) Entries
              </h2>
            )}
            <Table columns={columns} data={chemDocList} />
          </div>
        </section>
        {showEdit ? <EditPopup /> : null}
      </div>
    </main>
  );
};

export default ChemistDoctor;
