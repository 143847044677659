import { useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import * as XLSX from "xlsx";
import DoctorListFormat from "./../../../../assets/DoctorListFormat.xlsx";
import ChemistListFormat from "./../../../../assets/ChemistListFormat.xlsx";
import StockistListFormat from "./../../../../assets/StockistListFormat.xlsx";
import CityListFormat from "./../../../../assets/CityListFormat.xlsx" ;
import { uploadDoctors } from "../../../../reducers/targets/doctor";
import { uploadChemist } from "../../../../reducers/targets/chemist";
import { uploadStockist } from "../../../../reducers/targets/stockist";
import { uploadTarget} from "../../../../services/targets" ;
import { doctorCategoryOptions } from "../../../../utils/helper";


const typeOptions = [
  {
    value: 1,
    label: "Doctor",
  },
  {
    value: 2,
    label: "Chemist",
  },
  {
    value: 3,
    label: "Stockist",
  },
  {
    value:4,
    label: "CityOrArea"
  }
];

const AdminUploadDCS = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(({ user }) => user);
  // const headquarters = useSelector(({ headquarter }) => headquarter);
  // const employees = useSelector(({ employee }) => employee);
  // const cities = useSelector(({ city }) => city);

  const [type, setType] = useState(null);
  // const [headquarter, setHeadquarter] = useState(null);
  // const [workingArea, setWorkingArea] = useState(null);
  // const [employee, setEmployee] = useState(null);
  const [uploadData, setUploadData] = useState([]);
  const inputRef = useRef(null); // for excel file input
 

  const resetForm = () => {
    setType(null);
    setUploadData([]);
  };
  const dateFormatter = useCallback((date)=>{
    if(!date)return null ;
    const splits = date.split("/") ;
    const newDate = splits[1]+"/"+splits[0]+"/"+splits[2] ;
    
    return new Date(newDate)?.toISOString() ;
  },[])
  const handleSubmit =  (e) => {
    e.preventDefault();
    
    if (
      !type ||
      uploadData.length === 0
    )
      return customToast.error("Please fill all the fields");
    if (type?.value === 1 && !uploadData[0].hasOwnProperty("degree")) {
      return customToast.error("Invalid excel format");
    } else if (
      type?.value === 2 &&
      !uploadData[0].hasOwnProperty("chemistContact")
    ) {
      return customToast.error("Invalid excel format");
    } else if (
      type?.value === 3 &&
      !uploadData[0].hasOwnProperty("stockistContact")
    ) {
      return customToast.error("stockistContact required");
    }else if (
      type?.value === 4 &&
      !uploadData[0].hasOwnProperty("type")
    ) {
      return customToast.error("Invalid excel format");
    }

    let finalData = [];
    uploadData.forEach((el) => {
      if (type?.value === 1) {
        finalData.push({
          ...el,
          submitter: loggedIn?.jwt?.id, 
          birthday: dateFormatter(el?.birthday),
          anniversary: dateFormatter(el?.anniversary),
          category:
            el.DocCategory === "Non-Core (1 Visit/Month)"
              ? doctorCategoryOptions[0]
              : el.DocCategory === "Core (2 Visits/Month)"
              ? doctorCategoryOptions[1]
              : el.DocCategory === "SuperCore (3 Visits/Month)"
              ? doctorCategoryOptions[2]
              : doctorCategoryOptions[3], // if no category is specified keep it in ultra core category
        });
      } else if (type?.value === 2) {
        finalData.push({
          ...el,
          submitter: loggedIn?.jwt?.id,
          birthday: dateFormatter(el?.birthday)
        });
      } else if (type?.value === 4) {
        finalData.push({
          ...el,
        });
      } else {
        finalData.push({
          ...el,
          submitter: loggedIn?.jwt?.id,
          establishmentDate: dateFormatter(el?.birthday)
        });
      }
    });
    if(type?.value === 1){
      dispatch(uploadDoctors(finalData)).then((data)=>{
        exportEXCEL(data?.data);
        customToast.success(`Please open the excel file just downloaded`);
      }).catch((err)=>{
        if(err?.status === 500)customToast.error(err?.data?.message);
        else customToast.error("Error occured");
      })
    }else if(type?.value === 2){
      dispatch(uploadChemist(finalData)).then((data)=>{
        exportEXCEL(data?.data);
        customToast.success(`Please open the excel file just downloaded`);
      }).catch((err)=>{
        if(err?.status === 500)customToast.error(err?.data?.message);
        else customToast.error("Error occured");
      })
    }else if(type?.value === 3){
      dispatch(uploadStockist(finalData)).then((data)=>{
        exportEXCEL(data?.data);
        customToast.success(`Please open the excel file just downloaded`);
      }).catch((err)=>{
        if(err?.status === 500)customToast.error(err?.data?.message);
        else customToast.error("Error occured");
      })
    }else if(type?.value === 4){
      uploadTarget("city", finalData).then((data)=>{
        exportEXCEL(data?.data);
        customToast.success(`Please open the excel file just downloaded`);
      }).catch((err)=>{
        if(err?.status === 500)customToast.error(err?.data?.message);
        else customToast.error("Error occured");
      })
    }
    inputRef.current.value = null; // resetting the excel input field
    resetForm();
  };
  const exportEXCEL = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Unsuccessful.xlsx");
  }
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      if (file) fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const workbook = XLSX.read(bufferArray, { type: "buffer" });
        const wsName = workbook.SheetNames[0];
        const ws = workbook.Sheets[wsName];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (err) => {
        reject(err);
      };
    });

    promise.then((d) => {
      if (d.length === 0) {
        inputRef.current.value = null;
        return customToast.error("Please upload a filled excel file");
      }

      setUploadData(d);
    });
    promise.catch((err) => {
      return customToast.error("Encountered an error");
    });
  };

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Upload Doctor / Chemist / Stockist / City Or Area" />

        <section className="area-creation-content__form">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="type">
                  Select Type <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="type"
                  id="type"
                  value={type}
                  options={typeOptions}
                  onChange={(e) => setType({ ...e })}
                  styles={adminStyles}
                  placeholder="Select Doc / Chem / Stk"
                />
              </div>

              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="employee">
                  Upload Excel <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="file"
                  placeholder="Upload File"
                  ref={inputRef}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    readExcel(file);
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap mt-5">
              <div className="area-creation-content__form-submit">
                <button type="submit" className="button-blue-gradient">
                  Upload List
                </button>
              </div>
              <div className="area-creation-content__form-submit">
                <a
                  href={
                    type?.value === 1
                      ? DoctorListFormat
                      : type?.value === 2
                      ? ChemistListFormat
                      : type?.value === 3
                      ? StockistListFormat
                      : type?.value === 4
                      ? CityListFormat
                      : null
                  }
                  download={
                    type?.value === 1
                      ? "DoctorListFormat"
                      : type?.value === 2
                      ? "ChemistListFormat"
                      : type?.value === 3
                      ? "StockistListFormat"
                      : type?.value === 4
                      ? "CityListFormat"
                      : null
                  }
                  rel="noreferrer"
                  target={type?.value ? "_blank" : null}
                >
                  <button
                    type="button"
                    onClick={
                      type?.value
                        ? null
                        : () => {
                            customToast.error("Please select a type");
                          }
                    }
                    className="button-blue-gradient"
                  >
                    Download Format
                  </button>
                </a>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export default AdminUploadDCS;
