import React, { useEffect, useMemo, useState,useCallback } from 'react'
import PageTitle from '../../../../../components/PageTitle'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { approveTP, rejectTP, setTPForMonth } from '../../../../../reducers/tpReducer'
import Table from '../../../../../components/Table';
import { AiOutlineStop } from "react-icons/ai";
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import { MdCheckBoxOutlineBlank , MdClear} from 'react-icons/md';
import { IoMdDoneAll } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { BsCheck2Circle, BsXCircle } from 'react-icons/bs';
import { RiCloseCircleLine } from 'react-icons/ri'
import customToast from '../../../../../components/CustomToast'
import { tpApproval, tpRejection, viewUsersWithPendingTP } from '../../../../../services/tourProgram'
import { formatDate } from '../../../../../utils/helper'

const ViewUserTPCreationMenu = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    const tpData = useSelector(({ tp }) => tp);
    const [allSelected, setAllSelected] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [tpToggle, setTpToggle] = useState(true);


    useEffect(() => {
        let payload = {
            month : location?.state?.month,
            year : location?.state?.year,
            userId : location?.state?.user?._id
        };

        dispatch(setTPForMonth(payload));
    },[tpToggle]);

    const SearchColumnFilter = useCallback(
        ({ column, columns }) =>{
          return (
            <div className="search-filter__container" onClick={(e)=>{e.stopPropagation()}}>
              {<span className="d-flex gap-3 align-items-center">{column.render("Header")}
                {allSelected ? (
                    <ImCheckboxChecked onClick={(e) => {
                      e.preventDefault();
                      column?.filteredRows?.forEach((el,idx)=>{
                        if(el?.original?.isApproved === 0){
                          el.original.isSelected = false ;
                        }
                      });
                      setAllSelected(false);
                    }}/>
                  ):(
                    <ImCheckboxUnchecked onClick={(e) => {
                      e.preventDefault();
                      column?.filteredRows?.forEach((el,idx)=>{
                        if(el?.original?.isApproved === 0){
                          el.original.isSelected = true ;
                        }
                      });
                      setAllSelected(true);
                    }}/>
                  )
                  }
              </span>
              }
            </div>
      );},[allSelected]);
 

    const columns = useMemo(
        () => [
            {
             Header: "Date",
             accessor:"dateString", 
             maxWidth:"100px",
             Cell:(props) => {
                let day = new Date(props?.row?.original?.dateString).toLocaleString("en-us", {weekday:"long"});
                if(day === "Sunday" && !props?.row?.original?._id){
                    return <span className='opacity-25'>{formatDate(props?.row?.original?.dateString)}</span>
                }
                return formatDate(props?.row?.original?.dateString);
             }
            },
            {
             Header: "Day",
             accessor:"", 
             maxWidth:"100px",
             Cell:(props) => {
                let day = new Date(props?.row?.original?.dateString).toLocaleString("en-us", {weekday:"long"});
                if(day === "Sunday" && !props?.row?.original?._id){
                    return <span className='opacity-25'>
                        {day}
                    </span>
                }
                return day;
             }
            },
            {
             Header: "Area Type",
             accessor:"areaType",
            //  minWidth:"100px",
             maxWidth:"100px", 
             Cell:(props) => {
                let day = new Date(props?.row?.original?.dateString).toLocaleString("en-us", {weekday:"long"});
                if(day === "Sunday" && !props?.row?.original?._id){
                    return <span className='opacity-25'></span>
                }
                return props?.row?.original?.areaType;
             }
            },
            {
             Header: "Work Areas",
             accessor:"", 
             minWidth: "200px",
             maxWidth: "500px",
             Cell:(props) => {
                let workAreas = props?.row?.original?.workAreas;
                return <div>
                    {workAreas?.map((el) => (
                        <ul>
                            <li>
                            {el?.location?.label}
                            </li>
                        </ul>
                    ))}
                </div>
             }
            },
            // {
            //  Header: "Edited",
            //  accessor:"", 
            // },
            // {
            //  Header: "Remark",
            //  accessor:"", 
            // },
            {
                Header: "Status",
                accessor:"", 
                Cell:(props) => {
                   let tp = props?.row?.original;
                   let day = new Date(tp?.dateString).toLocaleString("en-us", {weekday:"long"});
                   if(day === "Sunday" && !props?.row?.original?._id){
                       return <span className='opacity-25'>
                           <AiOutlineStop size={20} cursor={"not allowed"}/>
                       </span>
                   }
                   return  tp?.isApproved === 1 ? <div style={{ color: "var(--color-success)"}}>Approved</div>
                   :
                   tp?.isApproved === -1 ? <div style={{color:"var(--color-error)"}}>Rejected</div>
                   :
                   tp?.isApproved === 0 ? <div style={{color:"var(--color-yellow)"}}>Pending</div> 
                   :
                   <div style={{color:"var(--color-secondary)"}}>No TP</div> 
                }
               },
               {
                Header: "Select",
                accessor:"select",
                Cell: (props) => {
                  const [local,setLocal] = useState(props?.row?.original?.isSelected || false);
                  let tp = props?.row?.original;
                  let day = new Date(tp?.dateString).toLocaleString("en-us", {weekday:"long"});
                   if(day === "Sunday" && !props?.row?.original?._id){
                       return <span className='opacity-25'>
                           <AiOutlineStop size={20} cursor={"not allowed"}/>
                       </span>
                   }
                  if(tp?.isApproved === 1){
                    return <div>
                        <IoMdDoneAll size={20} style={{color:"#2ecc71"}}/>
                    </div>
                  }
                  else if(tp?.isApproved === -1){
                    return <div>
                        <MdClear style={{ color: "#e74c3c" }} size={20}/>
                    </div>
                  }
                  else if(tp?.isApproved === undefined){
                    return <div>
                        <AiOutlineStop size={20}/>
                    </div>;
                  }
                  return (
                    <span
                      className="react-table-view-link display-6"
                    >    
                      {local ? (
                        <ImCheckboxChecked onClick={() => {
                          // handleSelect(props?.row?.original?.sr);
                          tp.isSelected = false ;
                          setLocal(false); 
                        }}/>
                      ):(
                        <MdCheckBoxOutlineBlank onClick={() => {
                          // handleSelect(props?.row?.original?.sr);
                          tp.isSelected = true ;
                          setLocal(true);
                        }}/>
                      )
                      }
                    </span>
                  );
                },
                minWidth: 70,
                maxWidth: 70,
                disableFilters: false,
                disableSortBy: true,
                Filter:SearchColumnFilter
              },
         ],
    [SearchColumnFilter]
    );

    const EditPopup = () => {
        const data = [];
        tpData?.data?.forEach((el,idx)=>{
            if(el?.isSelected){
            data.push(el?._id);
            }
        })
        return (
            <section className="edit-popup" onClick={(e) =>
            showEdit
                ? e.target.className === "edit-popup"
                ? setShowEdit(false)
                : null
                : null
            }>
            {/* <section className="edit-popup"> */}
            <div className="edit-content">
                <div className="edit-popup__heading">
                <h2 className="web-app__heading">Confirm Details</h2>
                <RiCloseCircleLine
                    className="edit-popup__close"
                    onClick={() => setShowEdit(false)}
                />
                </div>
    
                <form
                onSubmit={(e) => {
                    e.preventDefault();
                    setShowEdit(false);
                }}
                >
                <div className="edit-form">
                    <div>
                    <label htmlFor="division">
                        {`Do you want to approve the ${data.length} tour programs?`}
                    </label>
                    <br />
                    </div>
                </div>
    
                <div className="edit-form-button-container">
                    <button
                    type="submit"
                    className="button-submit-green mt-4"
                    onClick={() => handleApprove(data)}
                    >
                    <span className="button-submit-green__icon-container">
                        <BsCheck2Circle className="button-submit-green__icon" />
                    </span>
                    <span className="button-submit-green__text">Approve</span>
                    </button>
    
                    <button
                    type="submit"
                    className="button-delete mt-4"
                    onClick={() => handleReject(data)}
                    >
                    <span className="button-delete__icon-container">
                        <BsXCircle className="button-delete__icon" />
                    </span>
                    <span className="button-delete__text">Reject</span>
                    </button>
                </div>
                </form>
            </div>
            </section>
        );
        };

    const handleApprove = async(data) => {
        if(data?.length === 0)  return customToast.error("Select tour program for approval");
        const payload = {
            ids : data,
            type: "bulk"
        };
        // await dispatch(approveTP(data?._id,payload))
        await tpApproval(data?._id,payload);
        customToast.success("TP approved !");
        setTpToggle(!tpToggle);
        setShowEdit(false);
        setAllSelected(false);
    };

    const handleReject = async(data) => {
        if(data?.length === 0)  return customToast.error("Select tour program for rejection");
        const payload = {
            ids : data,
            type: "bulk"
        };
        // await dispatch(rejectTP(data?._id,payload));
        await tpRejection(data?._id,payload);
        customToast.success("TP rejected !");
        setTpToggle(!tpToggle);
        setShowEdit(false);
        setAllSelected(false);
    };


  return (
    <div className="main-content admin-content">
        <div className="area-creation-content">
            <PageTitle title={`Monthly Tour Program Details: ${location?.state?.user?.firstName} ${location?.state?.user?.lastName}`}/>
            
            <section className='area-creation-content__info'> 

            <div className="d-flex justify-content-end gap-5 pe-2">
            <button
              type="submit"
              className="button-submit-green"
              onClick={(e) => {
                e.preventDefault();
                setShowEdit(true);
              }}
            >
              <span className="button-submit-green__icon-container">
                <BsCheck2Circle className="button-submit-green__icon" />
              </span>
              <span className="button-submit-green__text">Actions</span>
            </button>
          </div>

            <div className="tp-tags d-flex mt-4 gap-md-3 gap-lg-5">
                <h4>
                <span className="tp-indicator-9 me-2"></span>Approved
                </h4>
                <h4>
                <span className="tp-indicator-10 me-2"></span>Rejected
                </h4>
                <h4>
                <span className="tp-indicator-5 me-2"></span>Pending
                </h4>
                <h4>
                <span className="tp-indicator-12 me-2"></span>No Tour Program
                </h4>
            </div>

                <div className='filter-table'>
                    <Table data={tpData?.data} columns={columns} defaultPageSize={50}/>
                </div>
                {showEdit ? <EditPopup/> : null}
            </section>
        </div>
    </div>
  )
}

export default ViewUserTPCreationMenu;