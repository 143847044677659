import React from 'react';
import { getDesignation } from '../../utils/serialiseResponse';
import { fetchLocationForId } from '../../services/locations';
import { getUserData } from '../../services/user';

function generateRows(entry, rowType) {
  return entry[rowType.toLowerCase() + "s"]
    ? entry[rowType.toLowerCase() + "s"].map((elm) => {
        const reportingTime = new Date(elm?.cdt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) || '';
        const targetName = elm?.target?.name || '';
        const productDetails = elm?.products
          ? elm?.products.map((product) => `${product?.productName}: ${product?.quantity}`).join(', ')
          : '';
          const sampleDetails = elm?.products
          ? elm?.products
              .filter((product) => product?.sampleQuantity !== "" && product?.sampleQuantity !== 0)
              .map((product) => `${product?.productName}: ${product?.sampleQuantity}`)
              .join(', ')
          : '';        
        const workAreasCount = entry?.workAreas ? entry?.workAreas.map((el) => el?.location?.label).join(', ') : '';
        const workedWith = elm?.workedWith ? elm?.workedWith?.map((user) => user?.firstName).join(', ') : ' ';
        const giftsDetails = elm?.products
          ? elm?.gifts.map((product) => `${product?.giftName}: ${product?.giftQuantity}`).join(', ')
          : '';

          
        return `${entry?.sr},"${entry?.date}","${reportingTime}","${entry?.submitter?.firstName} ${entry?.submitter.lastName}","${entry?.activity}","${entry?.areaType}","${rowType}","${targetName}","${productDetails}","${sampleDetails}",${giftsDetails},"${elm?.remarks}","${workAreasCount}","${workedWith}", ${elm?.target?.address}`;
      })
    : [];
}
const ExcelExportButton = ({ tableData, empl, fileSrc, month }) => {
  try {
    
    const exportToExcel = async () => {

      let hq;
      if(empl?.headquarter){
        try {
          const res = await fetchLocationForId(empl?.headquarter, 'headquarter');
          hq = res?.data;
        } catch (error) {
          console.log(error);
        }
      }

      let manager = null;
      if (empl?.reportingManager) {
        try {
          const res = await getUserData(empl?.reportingManager);
          manager = res?.data;
        } catch (error) {
          console.log(error);
        }
      }

      const csvContent =
      "data:text/csv;charset=utf-8," +
      "Sr,Date,Time,Submitter,Activity Type,Area Type,Type,Name,POB,Sample,Gifts,Remarks,Work Areas,Worked With,Address\n" +
      tableData.flatMap((entry) => {
        
        const submitterName = entry?.submitter ? `${entry?.submitter?.firstName} ${entry?.submitter?.lastName}` : '';
        
        const doctorsRows = generateRows(entry, "Doctor");
        const chemistsRows = generateRows(entry, "Chemist");
        const stockistsRows = generateRows(entry, "Stockist");

        const mainRow = `"${entry?.sr}","${entry?.date}"," ","${submitterName}","${entry?.activity}","${entry?.areaType}"," "," "," "," "," ","${entry?.remarks}"," ", " ", " "`;

        if (entry?.activity !== 'working') {
          return [mainRow, ...doctorsRows, ...chemistsRows, ...stockistsRows];
        } else {
          return [...doctorsRows, ...chemistsRows, ...stockistsRows];
        }
      }).join('\n') +
      `\n \n` +
      `Report Name: ${fileSrc} \n` +
      `Report Month: ${month?.label} \n` +
      `Employee Name: ${empl?.firstName} ${empl?.lastName} \n`	+
      `Employee Des: ${getDesignation(empl?.des)} \n` +
      `Headquarter: ${hq?.name} \n` +
      `Reporting Manager: ${manager?.firstName} ${manager?.lastName} \n` +
      `Date of File Download: ${new Date().toDateString()} \n`

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className='my-3' >
      <button className='button-blue-gradient' onClick={exportToExcel}>
        Export to Excel
      </button>
    </div>
  );
}
 catch(error){
  console.log(error);
 }
};

export default ExcelExportButton;
