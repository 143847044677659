import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURL = `${apiURL}/client/user`;
const baseURL2 = `${apiURL}/client/v1/user` ;
export const newUser = async (details) => {
  try{
    const response  = await axios.post(baseURL, details, {
      headers: setAuth(),
    });
    return response ;
  }catch(error){
    throw error.response ;
  }
};

export const newUserV1 = async (details) => {
  try{
    const response  = await axios.post(baseURL2, details, {
      headers: setAuth(),
    });
    return response ;
  }catch(error){
    throw error.response ;
  }
};
export const editUser = async (id, details) => {
  const { data } = await axios.post(baseURL + "/edit", details, {
    params: id,
    headers: setAuth(),
  });

  return data;
};

export const editUserV1 = async (id, details) => {
  const {data} = await axios.post(baseURL2 + "/edit", details, {
    params: id,
    headers: setAuth(),
  })
  return data;
}
export const deleteUser = async (id) => {
  const { data } = await axios.get(baseURL + "/delete", {
    params: id,
    headers: setAuth(),
  });
  return data;
};
export const deleteUserV1 = async (id) => {
  const { data } = await axios.get(baseURL2 + "/delete", {
    params: id,
    headers: setAuth(),
  });
  return data;
};
export const getUserDevices = async (id) => {
  const { data } = await axios.get(baseURL2 + "/user-devices", {
    params: id,
    headers: setAuth(),
  });
  return data;
};

export const newAdmin = async (details) => {
  const { data } = await axios.post(baseURL + "/admin", details, {
    headers: setAuth(),
  });

  return data;
};

export const newAdminV1 = async (details) => {
  const {data} = await axios.post(baseURL2 + "/admin", details, {
    headers: setAuth(),
  })
  return data;
}

export const viewUser = async () => {
  const { data } = await axios.get(`${baseURL}/info`, {
    headers: setAuth(),
  });

  return data;
};
export const viewUserCount =  async ()=>{
  const { data } = await axios.get(baseURL + "/usercount",{
    headers: setAuth(),
  });
  return data ;
}

export const viewUsers = async (type, details) => {
  const { data } = await axios.get(baseURL + "/" + type, {
    params: details,
    headers: setAuth(),
  });

  return data;
};

export const viewUsersV1 = async (type, details) => {
  const { data } = await axios.get(baseURL2 + "/" + type, {
    params: details,
    headers: setAuth(),
  });

  return data;
};

export const viewUsersForUtil = async (type) => {
  const { data } = await axios.get(baseURL + type, {
    headers: setAuth(),
  });

  return data;
};

export const viewUsersForCreation = async (details) => {
  const { data } = await axios.get(baseURL + "/priority-user", {
    params: details,
    headers: setAuth(),
  });

  return data;
};

export const viewUsersForCreationV1 = async (details) => {
  const {data} = await axios.get(baseURL2 + "/priority-user", {
    params: details,
    headers: setAuth(),
  })
  return data;
}

export const viewSpecificUser = async (id) => {
  const { data } = await axios.get(`${baseURL2}/user-info/${id}`, {
    headers: setAuth(),
  });

  return data;
};

export const viewCoworkers = async () => {
  const { data } = await axios.get(baseURL + "coworkers", {
    headers: setAuth(),
  });

  return data;
};

export const viewDesignations = async () => {
  const { data } = await axios.get(baseURL + "/designation", {
    headers: setAuth(),
  });

  return data;
};

export const viewDesignationsV1 = async () => {
  const { data } = await axios.get(baseURL2 + "/designation", {
    headers: setAuth(),
  });

  return data;
};

export const viewDesignationForUtil = async () => {
  const { data } = await axios.get(baseURL + "designation-for-utilities", {
    headers: setAuth(),
  });

  return data;
};

export const viewDivisions = async () => {
  const { data } = await axios.get(baseURL + "/division", {
    headers: setAuth(),
  });

  return data;
};

export const viewDivisionsV1 = async () => {
  const {data} = await axios.get(baseURL2 + "/division", {
    headers: setAuth(),
  })
  return data;
}

export const newDesignation = async (details) => {
  const { data } = await axios.post(baseURL2 + "/designation", details, {
    headers: setAuth(),
  });

  return data;
};

export const newDivision = async (details) => {
  const { data } = await axios.post(baseURL + "/division", details, {
    headers: setAuth(),
  });

  return data?.data;
};

export const newDivisionV1 = async (details) => {
  const {data} = await axios.post(baseURL2 + "/division", details, {
    headers: setAuth(),
  })
  return data?.data;
}

export const modifyUserInfo = async (type, id, updatedDivision) => {
 
  const { data } = await axios.put(baseURL2 + `/${type}/${id}`, updatedDivision, {
    headers: setAuth(),
  });
 
  return data;
};

export const modifyUserInfoV1 = async (type, id, updatedDivision) => {
 
  const { data } = await axios.put(baseURL2 + `/${type}/${id}`, updatedDivision, {
    headers: setAuth(),
  });
 
  return data;
};

export const updateUserInfo = async (type, id, updatedInfo) => {
  
  const { data } = await axios.put(baseURL + `/${type}/${id}`, updatedInfo, {
    headers: setAuth(),
  });

  return data;
};


export const deleteUserInfo = async (type, id) => {
  await axios.post(
    baseURL2 + `/${type}/${id}`,
    { params: { id } },
    {
      headers: setAuth(),
    }
  );
};

export const deleteUserInfoV1 = async (type, id) => {
  await axios.post(
    baseURL2 + `/${type}/${id}`,
    { params: { id } },
    {
      headers: setAuth(),
    }
  );
};

export const toggleUserAccess = async (userId, value) => {
  const { data } = await axios.get(baseURL2 + "/toggle-access", {
    params: { userId, value },
    headers: setAuth(),
  })

  return data;
}

export const toggleUserActivation = async (userId, value) => {
  const { data } = await axios.post(baseURL + `/updateActiveStatus/${userId}`, {isActive:value}, {
    headers: setAuth(),
  })

  return data;
}
export const toggleGpsAccess = async (details) => {
 const {data} =  await axios.post(baseURL + "/toggle-gps-access",details, {
    headers: setAuth(),
  })
  return data;
}

export const editPassword = async(payload) => {
  const {data} = await axios.post(baseURL + "/edit-password",payload,{
    headers: setAuth()
  })
  return data;  
}

export const viewUsersInHeirarchy = async (userId) => {
  console.log(userId)
  const { data } = await axios.get(baseURL2 + "/heirarchy", {
    params: userId,
    headers: setAuth(),
  });

  return data;
};
