import { getGiftManagement, newGiftManagement } from "../../services/ssg";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "VIEW_GIFT_MANAGEMENT":
      return action.payload;
    case "CREATE_GIFT_MANAGEMENT":
      return action.payload;
    case "CLEAR_GIFT_MANAGEMENT":
      return initialState;

    default:
      return state;
  }
};

export const viewGiftManagement = () => {
  return async (dispatch) => {
    const { data } = await getGiftManagement();

    dispatch({
      type: "VIEW_GIFT_MANAGEMENT",
      payload: { data: data },
    });
  };
};

export const addGiftManagement = (data) => {
  return async (dispatch) => {
    try {
      data = await newGiftManagement(data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "CREATE_GIFT_MANAGEMENT",
      payload: data,
    });
  };
};

export const clearGiftManagement = () => {
  return {
    type: "CLEAR_GIFT_MANAGEMENT",
  };
};

export default reducer;
