import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Table from "../../../../components/Table";
import {
  clearStates,
  setStates,
} from "../../../../reducers/locations/stateReducer";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../reducers/locations/hqReducer";
import {
  clearDivisions,
  setDivisions,
} from "../../../../reducers/users/divisionReducer";
import serialise, {
  generateSelectData, getDesignation,
} from "../../../../utils/serialiseResponse";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import customToast from "../../../../components/CustomToast";
import {
  clearUsers,
  setUsers,
} from "../../../../reducers/users/employeeReducer";
import PageTitle from "../../../../components/PageTitle";
import { RiEdit2Fill } from "react-icons/ri";
import { addIndex } from "../../../../utils/serialiseResponse";

const AdminUserEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const divisionData = useSelector(({ division }) => division);
  const states = useSelector(({ state }) => state);
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const userData = useSelector(({ employee }) => employee);

  const divisionSelect = useMemo(
    () => generateSelectData(divisionData, "name"),
    [divisionData]
  );
  const stateSelect = useMemo(
    () => generateSelectData(states.data, "name"),
    [states]
  );
  const headquarterSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );

  const [state, setState] = useState(null);
  const [headquarter, setHeadquarter] = useState(null);
  const [division, setDivision] = useState(null);

  useEffect(() => {
    dispatch(setDivisions());
    dispatch(setStates());
    return () => {
      dispatch(clearDivisions());
      dispatch(clearStates());
      dispatch(clearUsers());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!state) return;

    dispatch(setHeadquarters(state.value));
    return () => {
      dispatch(clearHeadquarters());
    };
  }, [dispatch, state]);

  const redirectToEdit = (element) => {
    navigate("/admin-panel/users/edit-user/edit", { state: element });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: (props) => {
          return <span>
            {props?.row?.original?.firstName+" "+props?.row?.original?.middleName+" "+props?.row?.original?.lastName}
          </span>
        }
      },
      {
        Header: "Designation",
        accessor: "des",
        // disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 80,
        Cell: (props) => {
          return <span>{getDesignation(props?.row?.original?.des)}</span>;
        },
      },
      {
        Header: "Headquarter",
        accessor: "headquarter.name",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Division",
        accessor: "division.name",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Edit User",
        accessor: "details",
        // Cell: (props) => {
        //   console.log(props.row.original);
        //   return (
        //     <Link
        //       className="table-link"
        //       to={{
        //         pathname: "/admin-panel/users/edit-user/edit",
        //         state: props.row.original,
        //       }}
        //     >
        //       Edit Info
        //     </Link>
        //   );
        // },
        Cell: (props) => {
         return <span
            className="react-table-view-link"
            onClick={() => redirectToEdit(props.row.original)}
          >
            <RiEdit2Fill className="icon-color-green" />
          </span>}
        ,
        disableFilters: true,
        disableSortBy: true,
        minWidth: 70,
        maxWidth: 70,
      },
    ],
    []
  );
  const tableData = useMemo(() => addIndex(userData), [userData]);

  const handleFilter = (e) => {
    e.preventDefault();

   
    const data = {
      hqId: headquarter?.value,
      divisionId: division?.value,
    };

    try {
      dispatch(setUsers("user", data));
    } catch (e) {
      customToast.error("We Encountered an Error");
    }
  };

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Edit User" />

        <section className="area-creation-content__form py-0">
          <form onSubmit={handleFilter}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">Select State</label>
                <br />
                <Select
                  name="state"
                  id="state"
                  value={state}
                  options={stateSelect}
                  onChange={({ value, label }) => setState({ value, label })}
                  styles={adminStyles}
                  placeholder="Select State"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">Select HQ</label>
                <br />
                <Select
                  name="headquarter"
                  id="headquarter"
                  value={headquarter}
                  options={headquarterSelect}
                  onChange={({ value, label }) =>
                    setHeadquarter({ value, label })
                  }
                  styles={adminStyles}
                  placeholder={
                    state ? "Select Headquarter" : "Select State First"
                  }
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">Select Division</label>
                <br />
                <Select
                  name="division"
                  id="division"
                  value={division}
                  options={divisionSelect}
                  onChange={({ value, label }) => setDivision({ value, label })}
                  styles={adminStyles}
                  placeholder="Select Division"
                />
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-sm-12 col-md-4 col-lg-4 area-creation-content__form-submit">
                <button type="submit" className="button-blue-gradient">
                  Filter Users
                </button>
              </div>
            </div>
          </form>
          <div className="filter-table">
            {userData.length > 0 && (
              <h2 className="web-app__heading my-3 ms-2">
                Showing ({userData.length}) Entries
              </h2>
            )}
              {tableData.length > 0 ? (
            <Table columns={columns} data={tableData} />
          ) : (
            <h3 className="mt-2 no-results-message">No results found</h3>
          )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default AdminUserEdit;
