import Cookies from 'js-cookie';

export const storeUser = (user, expires) => {
	Cookies.set('loggedUser', JSON.stringify(user), { expires });
};

export const retrieveUser = () => {
	const storedJSON = Cookies.get('loggedUser');
	const loggedUser = storedJSON ? JSON.parse(storedJSON) : null;

	return loggedUser;
};

export const retrieveToken = () => {
	const storedJSON = Cookies.get('loggedUser');
	const loggedUser = storedJSON ? JSON.parse(storedJSON) : null;

	if (loggedUser) return loggedUser.token;
	else return null;
};

export const clearUser = () => {
	Cookies.remove('loggedUser');
};

export const setUserDivision = (divisonId) =>{
	localStorage.setItem("division", JSON.stringify(divisonId))
}

export const getUserDivision = () => {
	const savedItem = localStorage.getItem("division");
	return savedItem ? JSON.parse(savedItem) : null 
}

export const deleteUserDivision = ()=>{
	localStorage.removeItem('division');
}