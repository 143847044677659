import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import Table from "../../../../components/Table";
import {
  clearStates,
  setStates,
} from "../../../../reducers/locations/stateReducer";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../reducers/locations/hqReducer";
import {
  clearDivisions,
  setDivisions,
} from "../../../../reducers/users/divisionReducer";
import serialise, {
  generateSelectData,
} from "../../../../utils/serialiseResponse";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import customToast from "../../../../components/CustomToast";
import {
  clearUsers,
  setUsers,
} from "../../../../reducers/users/employeeReducer";
import PageTitle from "../../../../components/PageTitle";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";

const AdminInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const states = useSelector(({ state }) => state);
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const userData = useSelector(({ employee }) => employee);

  const stateSelect = useMemo(
    () => generateSelectData(states.data, "name"),
    [states]
  );
  const headquarterSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );

  const [state, setState] = useState(null);
  const [headquarter, setHeadquarter] = useState(null);

  useEffect(() => {
    dispatch(setDivisions());
    dispatch(setStates());
    return () => {
      dispatch(clearDivisions());
      dispatch(clearStates());
      dispatch(clearUsers());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!state) return;

    dispatch(setHeadquarters(state.value));
    return () => {
      dispatch(clearHeadquarters());
    };
  }, [dispatch, state]);

  const redirectToEdit = (element) => {
    navigate("/admin-panel/users/admin-info/details", { state: element });
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Name",
        accessor: "name",
        Cell: (props) => {
          return <span>
            {props?.row?.original?.firstName+" "+props?.row?.original?.middleName+" "+props?.row?.original?.lastName}
          </span>
        }
      },
      {
        Header: "Headquarter",
        accessor: "headquarter",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Division",
        accessor: "division",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "View",
        accessor: "details",
        Cell: (props) => (
          <span
            className="react-table-view-link"
            onClick={() => redirectToEdit(props.row.original)}
          >
            <FaEye className="icon-color-tertiary" />
          </span>
        ),
        disableFilters: true,
        disableSortBy: true,
        minWidth: 40,
        maxWidth: 40,
      },
    ],
    []
  );
  const tableData = useMemo(() => serialise(userData), [userData]);

  const handleFilter = (e) => {
    e.preventDefault();

   
    const data = {
      hqId: headquarter?.value,
    };

    try {
      dispatch(setUsers("admin", data));
    } catch (e) {
      customToast.error("We Encountered an Error");
    }
  };

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="User Detail" />

        <section className="area-creation-content__form">
          <form onSubmit={handleFilter}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">Select State</label>
                <br />
                <Select
                  name="state"
                  id="state"
                  value={state}
                  options={stateSelect}
                  onChange={({ value, label }) => setState({ value, label })}
                  styles={adminStyles}
                  placeholder="Select State"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">Select HQ</label>
                <br />
                <Select
                  name="headquarter"
                  id="headquarter"
                  value={headquarter}
                  options={headquarterSelect}
                  onChange={({ value, label }) =>
                    setHeadquarter({ value, label })
                  }
                  styles={adminStyles}
                  placeholder={
                    state ? "Select Headquarter" : "Select State First"
                  }
                />
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-sm-12 col-md-4 col-lg-4 area-creation-content__form-submit">
                <button type="submit" className="button-blue-gradient">
                  Filter Users
                </button>
              </div>
            </div>
          </form>
        </section>

        <section className="area-creation-content__info py-0">
        {tableData.length > 0 && (
            <h2 className="web-app__heading mt-4 ms-2">
              Showing ({tableData.length}) Entries
            </h2>
          )}
          <div className="filter-table">
          {tableData.length > 0 ? (
            <Table columns={columns} data={tableData} />
          ) : (
            <h3 className=" no-results-message">No results found</h3>
          )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default AdminInfo;
