// import { Modal, ModalClose, ModalDialog } from '@mui/joy'
// import React from 'react'
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import { FaYoutube } from 'react-icons/fa';

// const rows = [
//   {
//     title: "How to check attendance",
//     hindi: "/",
//     english: "/",
//     status: "new"
//   },
//   {
//     title: "How to create city",
//     hindi: "/",
//     english: "/",
//     status: "must watch"
//   },
//   {
//     title: "How to add expense",
//     hindi: "/",
//     english: "/",
//     status: ""
//   },
//   {
//     title: "How to create route",
//     hindi: "/",
//     english: "/",
//     status: ""
//   },
//   {
//     title: "How to add leave request",
//     hindi: "/",
//     english: "/",
//     status: "new"
//   },
//   {
//     title: "How to add reminders",
//     hindi: "/",
//     english: "/",
//     status: ""
//   },
//   {
//     title: "How to create a new chemist in medorn",
//     hindi: "/",
//     english: "/",
//     status: ""
//   },
//   {
//     title: "How to create a new stockist",
//     hindi: "/",
//     english: "/",
//     status: ""
//   },
//   {
//     title: "How to create a new doctor in medorn",
//     hindi: "/",
//     english: "/",
//     status: ""
//   },
//   {
//     title: "How to add tour program",
//     hindi: "/",
//     english: "/",
//     status: "",
//   },
//   {
//     title: "How to add day plan",
//     hindi: "/",
//     english: "/",
//     status: "new",
//   },
//   {
//     title: "How to add use geo tagging",
//     hindi: "/",
//     english: "/",
//     status: "",
//   },
//   {
//     title: "How to add stockist DCR (Daily Call Report).",
//     hindi: "/",
//     english: "/",
//     status: "",
//   },
//   {
//     title: "How to submit call report in medorn ERP",
//     hindi: "/",
//     english: "/",
//     status: "",
//   },
//   {
//     title: "stockist wise reports in medorn ERP",
//     hindi: "/",
//     english: "/",
//     status: "",
//   },
//   {
//     title: "sample management in medorn",
//     hindi: "/",
//     english: "/",
//     status: "",
//   },
// ];

// const Faq = ({ setVariant, variant }) => {
//   return (
//     <Modal open={!!variant} onClose={() => setVariant(undefined)}>
//       <ModalDialog variant={variant} style={{
//         width: "70vw",
//         height: "80vh",
//         overflowY: "auto"
//       }}>
//         <h1 style={{ padding: "5px 0px" }}>Tutorial Videos</h1>
//         <ModalClose />
//         <TableContainer component={Paper}>
//           <Table sx={{ minWidth: 650 }} aria-label="simple table">
//             <TableHead>
//               <TableRow>
//                 <TableCell style={{ backgroundColor: "white", borderBottom: "2px solid royalblue" }} >Questions</TableCell>
//                 <TableCell style={{ backgroundColor: "white", borderBottom: "2px solid royalblue" }} align="left">Hindi</TableCell>
//                 <TableCell style={{ backgroundColor: "white", borderBottom: "2px solid royalblue" }} align="left">English</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {rows.map((row) => (
//                 <TableRow
//                   key={row.title}
//                   sx={{
//                     '&:last-child td, &:last-child th': { border: "none" },
//                     backgroundColor: 'white' // Setting background color to white
//                   }}
//                 >
//                   <TableCell style={{ backgroundColor: "white", textTransform: "capitalize" }} scope="row">
//                     <div style={{ display: "flex", width: "100%", justifyContent: "start", gap: "6px", alignItems: "center" }}>
//                       {row.title}
//                       {row.status && (
//                         <div style={{
//                           borderRadius: "4px",
//                           fontSize: "10px",
//                           padding: "2px 6px",
//                           backgroundColor: row.status === "new" ? "#2bcf36" : row.status === "must watch" ? "royalblue" : "",
//                           color: "white"
//                         }}>{row.status}</div>
//                       )}
//                     </div>
//                   </TableCell>
//                   <TableCell style={{ backgroundColor: "white", cursor: "pointer" }} align="left">
//                     <a href={row.hindi} style={{ textDecoration: "none" }}>
//                       <FaYoutube className='icon-color-error' /> Watch
//                     </a>
//                   </TableCell>
//                   <TableCell style={{ backgroundColor: "white", cursor: "pointer" }} align="left">
//                     <a href={row.english} style={{ textDecoration: "none" }}>
//                       <FaYoutube className='icon-color-error' /> Watch
//                     </a>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </ModalDialog>
//     </Modal>
//   )
// }

// export default Faq

import React from 'react'

const Faq = () => {
  return (
    null 
  )
}

export default Faq