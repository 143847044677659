import React, { useEffect, useMemo, useState } from 'react'
import PageTitle from '../../../../components/PageTitle';
import Table from '../../../../components/Table';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { approveExpense, clearExpense, rejectExpense, viewExpense } from '../../../../reducers/expenses/expense';
import { formatDate } from '../../../../utils/helper';
import { MdOutlinePendingActions, MdCameraAlt, MdInfoOutline ,MdArrowForwardIos, MdDeleteOutline, MdCheckCircleOutline, MdArrowBackIos} from 'react-icons/md';
import { IoNotificationsCircleOutline } from 'react-icons/io5';
import { showSidenav, showTrimNav } from '../../../../reducers/appReducer';
import { yearOptions } from '../../../../utils/helper';
import { monthOptions } from '../../../../utils/helper';
import { Carousel } from 'react-bootstrap';
import { RiCloseCircleLine, RiCheckDoubleLine } from 'react-icons/ri';
import {expenseApproval, getTotalMonthlyExpense, rejectApproval } from '../../../../services/expenses';
import customToast from '../../../../components/CustomToast';
import { viewTPForDate } from '../../../../services/tourProgram';
import { IoMdRadioButtonOff, IoIosRadioButtonOn } from 'react-icons/io';
import { BsXCircle, BsCheck2Circle } from 'react-icons/bs';
import { TiCancel } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";
import { hideAdminNav, showAdminNav } from '../../../../reducers/adminSideNavReducer';

const MonthlyExpenseApproval = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const loggedIn = useSelector(({ user }) => user);
    const expense = useSelector(({expense}) => expense);
    const [date, setDate] = useState(new Date());
    const [travelAllowance, setTravelAllowance] = useState(0);
    const [ticketAllowance, setTicketAllowance] = useState(0);
    const [dailyAllowance, setDailyAllowance] = useState(0);
    const [foodAllowance, setFoodAllowance] = useState(0);
    const [hotelAllowance, setHotelAllowance] = useState(0);
    const [misExpense, setMisExpense] = useState(0);
    const [remarks, setRemarks] = useState("");
    const [employees, setEmployees] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const [tpData, setTpData] = useState({});
    const [totalExpense, setTotalExpense] = useState({});
    const [found, setFound] = useState(false);
    const [showEdit, setShowEdit]=useState(false);
    const [imageShow, setImageShow] = useState(false);
    const [image, setImage] = useState();
    const [showRemark,setShowRemark] = useState(false);
    const [remarkData,setRemarkData] = useState();
    const [popupType, setPopupType] = useState();
    const [approvalData, setApprovalData] = useState();
    const [expenseToggle, setExpenseToggle] = useState(true);
    const sundayWorking = useSelector((state) => state.settings["sundayWorking"]);

    useEffect(() => {
      dispatch(hideAdminNav());

      return () => {
        dispatch(showAdminNav());
      };
    },[dispatch]);
    

    useEffect(() => {
        let payload = {
            selectedUser: location?.state?.user?._id,
            month: location?.state?.month,
            year: location?.state?.year,
        };
        dispatch(viewExpense(payload));

        return () => {
            clearExpense();
        };
        
    },[dispatch,expenseToggle]);

    const ImagePopup = ({ data }) => {
        return (
          <section className="edit-popup" onClick={(e) =>
            imageShow
              ? e.target.className === "edit-popup"
                ? setImageShow(false)
                : null
              : null
          }>
            <Carousel
              slide={false}
              interval={null}
              nextIcon={<MdArrowForwardIos style={{ fontSize: "2em" }} />}
              prevIcon={<MdArrowBackIos style={{ fontSize: "2em" }} />}
            >
              {data?.files && data?.files.map((e) => {
                return (
                  <Carousel.Item>
                    <img src={e} alt="Expense Img" />
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </section>
        );
      };
      const RemarkPopup = ({ data, type }) => {
        return (
          <section className="edit-popup" onClick={(e) =>
            showRemark
              ? e.target.className === "edit-popup"
                ? setShowRemark(false)
                : null
              : null
          }>
            <div className="edit-content">
              <div className="edit-popup__heading">
                <h2 className="web-app__heading">{type}</h2>
                <RiCloseCircleLine
                  className="edit-popup__close"
                  onClick={() => setShowRemark(false)}
                />
              </div>
              {type === "remark" && (
                <>
                  User remark : {data?.remarks}
                  <br />
                  {data.adminRemark && (
                    <span>Admin/Manager remark : {data?.adminRemark}</span>
                  )}
                </>
              )}
              {type === "route" && (
                <>
                  <br />
                  <h3>Work Areas:</h3>
                  {data?.workAreas?.length === 0 ? (
                    <p> Local</p>
                  ) : (
                    <ul>
                      {data?.workAreas?.map((workArea, index) => (
                        <li key={index}>{workArea?.location?.label}</li>
                      ))}
                    </ul>
                  )}
                </>
              )}
    
            </div>
          </section>
        );
      };
      const EditPopup = ({ data }) => {
        const dispatch = useDispatch();
        const [remarks, setRemarks] = useState("");
        const [tpData, setTpData] = useState();
        const [areaType, setAreaType] = useState("");
        const [travelAllowance, setTravelAllowance] = useState(0);
        const [ticketAllowance, setTicketAllowance] = useState(0);
        const [foodAllowance, setFoodAllowance] = useState(0);
        const [hotelAllowance, setHotelAllowance] = useState(0);
        const [misExpense, setMisExpense] = useState(0);
        const [gpsDistance, setGpsDistance] = useState(0) ;
        const [gpsTA, setGPSTA] = useState(0) ;
        const [distance, setDistance] = useState(0) ;
        const [useGps, setUseGps] = useState(false) ;
    
        useEffect(() => {
          setAreaType(data.areaType);
          setTravelAllowance(data.travelAllowance);
          setFoodAllowance(data.foodAllowance);
          setHotelAllowance(data.hotelAllowance);
          setTicketAllowance(data.ticketAllowance);
          setMisExpense(data.misExpense);
          setGPSTA(data?.gpsTA);
          setGpsDistance(data?.gpsDistance);
          viewTPForDate({ date: data.date, selectedUser: data?.submitter?._id, status:"Approved"  }).then((res) => {
            setTpData(res.data);
          });
        }, [data]);
        useEffect(()=>{
          let totalDistance = 0;
          tpData?.workAreas?.forEach((area) => {
            totalDistance += Number(area.location.distance);
          });
          setDistance(totalDistance) ;
        },[tpData]) ;
    
        const handleApprove = async (e) => {
          let updatedData = {
            ...data,
            areaType: areaType,
            travelAllowance: travelAllowance,
            foodAllowance: foodAllowance,
            hotelAllowance: hotelAllowance,
            ticketAllowance: ticketAllowance,
            misExpense: misExpense,
          };
    
          if (
            updatedData.areaType === data.areaType &&
            updatedData.travelAllowance === data.travelAllowance &&
            updatedData.foodAllowance === data.foodAllowance &&
            updatedData.hotelAllowance === data.hotelAllowance &&
            updatedData.ticketAllowance === data.ticketAllowance &&
            updatedData.misExpense === data.misExpense
          ) {
            // dispatch(approveExpense(data?._id, {...data, adminRemarks:remarks}));
            await expenseApproval(data?._id, {...data, adminRemarks:remarks});
            customToast.success("Expense approved !");
            setExpenseToggle(!expenseToggle);
            setShowEdit(false);
          } else {
            if (remarks.length === 0) {
              customToast.error("Please Fill Remark");
            } else {
              // dispatch(editExpenseInApproval(updatedData?._id, {...updatedData, remarks})); no longer needed
              // dispatch(approveExpense(data?._id, {...updatedData, adminRemarks:remarks}));
              await expenseApproval(data?._id, {...updatedData, adminRemarks:remarks});
              customToast.success("Expense approved !");
              setExpenseToggle(!expenseToggle);
              setShowEdit(false);
            }
          }
        };
    
        const handleReject = async () => {
          // dispatch(rejectExpense(data?._id, {...data, adminRemarks:remarks}));
          await rejectApproval(data?._id, {...data, adminRemarks:remarks});
          customToast.success("Expense rejected !");
          setExpenseToggle(!expenseToggle);
        };
    
        return (
          <section
            className="edit-popup"
            onClick={(e) =>
              showEdit
                ? e.target.className === "edit-popup"
                  ? setShowEdit(false)
                  : null
                : null
            }
          >
            <div className="edit-content">
              <div className="edit-popup__heading">
                <h2 className="web-app__heading">Edit Details</h2>
                <RiCloseCircleLine
                  className="edit-popup__close"
                  onClick={() => setShowEdit(false)}
                />
              </div>
    
              <form>
                <div className="edit-form">
                  <div className="edit-form__expense">
                    {areaType && areaType.toLowerCase() === "ex-station" ? (
                      <div>
                        { useGps ? <IoMdRadioButtonOff className="tp__activity-types-icon-7 cursor-pointer me-2" 
                          onClick={()=>{ setTravelAllowance(data.travelAllowance); setUseGps(false);}}
                        /> 
                          : <IoIosRadioButtonOn className="tp__activity-types-icon-9 me-2"/>
                        }
                        <label htmlFor="travel">
                          TA  &nbsp; ({distance*2}&nbsp;km)<span className="asterisk-imp ">*</span>
                        </label>
                        <br />
                        <input
                          type="text"
                          id="travel"
                          className="me-5"
                          placeholder="TA"
                          value={travelAllowance}
                          onChange={({ target }) =>
                            setTravelAllowance(Number(target.value))
                          }
                        />
                      </div>
                    ) : null}
                    {/* {areaType && areaType.toLowerCase() === "ex-station" ? (
                      <div>
                        <label htmlFor="travel">
                          GPS Distance 
                        </label>
                        <br />
                        <input
                          type="text"
                          id="travel"
                          className="me-5"
                          placeholder="GPS Distance"
                          value={gpsDistance}
                          disabled={true}
                        />
                      </div>
                    ) : null} */}
                    {/* {areaType && areaType.toLowerCase() === "ex-station" ? (
                      <div>
                        { useGps ? <IoIosRadioButtonOn className="tp__activity-types-icon-9 me-2"/> 
                          : <IoMdRadioButtonOff className="tp__activity-types-icon-7 cursor-pointer me-2"
                            onClick={()=> { setTravelAllowance(gpsTA); setUseGps(true); }}
                          />
                        }
                        <label htmlFor="travel">
                          GPS TA &nbsp; ({gpsDistance}&nbsp;km)
                        </label>
                        <br />
                        <input
                          type="text"
                          id="travel"
                          className="me-5"
                          placeholder="GPS TA"
                          value={gpsTA}
                          disabled={true}
                        />
                      </div>
                    ) : null} */}
    
                    {areaType && areaType.toLowerCase() === "out-station" ? (
                      <>
                        <div>
                          <label htmlFor="food">
                            Food <span className="asterisk-imp">*</span>
                          </label>
                          <br />
                          <input
                            type="text"
                            id="food"
                            className="me-5"
                            placeholder="Food"
                            value={foodAllowance}
                            onChange={({ target }) =>
                              setFoodAllowance(Number(target.value))
                            }
                          />
                        </div>
    
                        <div>
                          <label htmlFor="hotel">
                            Hotel <span className="asterisk-imp">*</span>
                          </label>
                          <br />
                          <input
                            type="text"
                            id="hotel"
                            className="me-5"
                            placeholder="Hotel"
                            value={hotelAllowance}
                            onChange={({ target }) =>
                              setHotelAllowance(Number(target.value))
                            }
                          />
                        </div>
    
                        <div>
                          <label htmlFor="ticket">
                            Ticket <span className="asterisk-imp">*</span>
                          </label>
                          <br />
                          <input
                            type="text"
                            id="ticket"
                            className="me-5"
                            placeholder="Ticket"
                            value={ticketAllowance}
                            onChange={({ target }) =>
                              setTicketAllowance(Number(target.value))
                            }
                          />
                        </div>
                      </>
                    ) : null}
    
                    <div>
                      <label htmlFor="packaging">
                        Mis Expense <span className="asterisk-imp">*</span>
                      </label>
                      <br />
                      <input
                        type="text"
                        id="packaging"
                        className="me-5"
                        placeholder="packaging"
                        value={misExpense}
                        onChange={({ target }) =>
                          setMisExpense(Number(target.value))
                        }
                      />
                    </div>
                  </div>
                </div>
    
                <div className="expense-remarks">
                  <label htmlFor="remarks">
                   Remarks <span className="text-lowercase">(remarks are only mandatory if changes are made)</span>
                  </label>
                  <textarea
                    name="remarks"
                    id="remarks"
                    cols={30}
                    rows={2}
                    className="w-100"
                    placeholder="Enter Remarks"
                    value={remarks}
                    onChange={({ target }) => {
                      setRemarks(target.value);
                    }}
                  ></textarea>
                </div>
                <div className="util-inner-info-box mt-5">
                  <h3>Work Areas</h3>
                  <p>
                    {
                      tpData?.workAreas?.map((el) => el?.location?.label + ",  ")
                    }
                  </p>
                </div>
                <div className="edit-form-button-container">
                  <div className="d-flex justify-content-between w-100">
                    <button
                      type="submit"
                      className="button-submit-green mt-4 me-5"
                      onClick={(e) => {
                        e.preventDefault();
                        handleApprove();
                      }}
                    >
                      <span className="button-submit-green__icon-container">
                        <BsCheck2Circle className="button-submit-green__icon" />
                      </span>
                      <span className="button-submit-green__text">Approve</span>
                    </button>
    
                    <button
                      type="submit"
                      className="button-delete mt-4"
                      onClick={(e) => {
                        e.preventDefault();
                        handleReject();
                        setShowEdit(false);
                      }}
                    >
                      <span className="button-delete__icon-container">
                        <BsXCircle className="button-delete__icon" />
                      </span>
                      <span className="button-delete__text">Reject</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </section>
        );
      };

      const setEditForm = (data) => {
        setShowEdit(false);
        setApprovalData(data);
      };  

      let i = 0

    const columns = useMemo(
        () => [
          {
            Header: "Date",
            accessor: "date",
            showTotal: true,
            minWidth: 90,
            maxWidth: 90,
            placeholderTotal: "Total",
            disableFilters: true,
            Cell: (props) => {
              console.log(props?.row?.original)
              console.log(i++)
              let day = new Date(props?.row?.original?.date).toLocaleString('en-us', { weekday: 'short' });
              // if(day === "Sun") return <span className="opacity-25">{formatDate(props.row.original?.date)}</span>
              return props.row.original?.isApproved === 1 ? (
                <span className="approved-text fw-bold">{formatDate(props.row.original?.date)}</span>
              ) : props.row.original?.isApproved === 0 ? (
                <span className="pending-text fw-bold">{formatDate(props.row.original?.date)}</span>
              ) : props.row.original?.isApproved === -1 ? (
                <span className="rejected-text fw-bold">{formatDate(props.row.original?.date)}</span>
              ) : day === "Sun" ? (
                  props?.row?.original._id ? <span className="notsubmitted-text fw-bold">{formatDate(props.row.original?.date)}</span> 
                : <span className="opacity-25">{formatDate(props.row.original?.date)}</span>
              ) 
                : <span className="notsubmitted-text fw-bold">{formatDate(props.row.original?.date)}</span>
    
            },
          },
          {
            Header: "Day",
            accessor: "day",
            disableSortBy: true,
            disableFilters: true,
            wordWrap: "break-word",
            minWidth: 70,
            maxWidth: 70,
            Cell: (props) => {
              let day = new Date(props?.row?.original?.date).toLocaleString('en-us', { weekday: 'short' });
              if(day === "Sun" ) return props?.row?.original._id ? <span>{day}</span>
              : <span className="opacity-25">{day}</span>

              return <span>{day}</span>
            },
          },
          {
            Header: "Area Type",
            accessor: "areaType",
            // disableFilters: true,
            disableSortBy: true,
            Cell: (props) => {
              return (
    
                // <button
                //   className="react-table-view-link bg-transparent border-0"
                //   onClick={() => {
                //     setRemarkData(props.row.original)
                //     setPopupType("route")
                //     setShowRemark(true);
                //   }}
                // >
                //   { props?.row?.original.areaType}
                // </button>
                <span> {props?.row?.original.areaType}</span>
              );
            },
          },
          {
            Header: "Working Type",
            accessor:"",
            accessor: (row) => {
              let day = new Date(row?.date).toLocaleString('en-us', { weekday: 'short' });
              if(day === "Sun") return  <span></span>
              if (row.holiday) return "Holiday";
              if (row.leave || row.activity === 'leave') return "Leave";
              if (row.activity) return row.activity.charAt(0).toUpperCase() + row.activity.slice(1);
              return "Working";
            },
            // disableFilters: true,
            disableSortBy: true,
            filter:'text',
            Cell: ({value}) => {
              // if (props?.row?.original?.holiday) {
              //   return <span>Holiday</span>;
              // }
              // else if (props?.row?.original?.leave || props?.row?.original?.activity == 'leave') {
              //   return <span>Leave</span>;
              // }
              // else if (props?.row?.original?.activity) {
              //   return <span style={{textTransform:"capitalize"}}>{props?.row?.original?.activity}</span>;
              // }
              // else {
                return <span>{value}</span>
              // }
            }
          },
          {
            Header: "Total Calls",
            accessor: "totalCalls",
            disableFilters: true,
            disableSortBy: true,
            Cell:(props) => {
                // console.log("props -------->", props?.row?.original);
              let day = new Date(props?.row?.original?.date).toLocaleString('en-us', { weekday: 'short' });
              if(day === "Sun") return props?.row?.original._id ?  (props?.row?.original?.totalCalls) 
                : <span></span>
              return (props?.row?.original?.totalCalls);
            }
          },
          {
            Header: "Work Areas",
            accessor: "workAreas",
            // disableFilters: true,
            disableSortBy: true,
            Cell: (props) => {
              const workAreas = props?.row?.original?.workAreas;
              return (
                <div>
                  {(workAreas?.length === 0) ? (
                    <p>Local</p>
                  ) : (
                    <ul>
                      {workAreas?.map((workArea, index) => (
                        <li key={index}>{workArea?.location?.label}</li>
                      ))}
                    </ul>
                  )}
                </div>
              );
            },
          },
          {
            Header: "Travel",
            accessor: "travelAllowance",
            disableFilters: true,
            disableSortBy: true,
            showTotal: true,
            totalAccessor: "travelAllowance",
            Cell:(props) => {
                let data = props?.row?.original;

                let day = new Date(data?.date).toLocaleString('en-us', { weekday: 'short' });

                if(!data?.submitter || (data?.isApproved !== 0 && data?.isApproved !== 1 && data?.isApproved !== -1))    return <span></span>;

                let value = Number(data?.travelAllowance);
                if(!value) return <span>--</span>
                if(day === "Sun") return props?.row?.original._id ? value.toFixed(2) : <span></span>
                return value.toFixed(2);
            },
          },
          {
            Header: "Food",
            accessor: "foodAllowance",
            disableFilters: true,
            disableSortBy: true,
            showTotal: true,
            totalAccessor: "foodAllowance",
            Cell:(props) => {
                let data = props?.row?.original;

                let day = new Date(data?.date).toLocaleString('en-us', { weekday: 'short' });
                // if(day === "Sun") return <span></span>

                if(!data?.submitter || (data?.isApproved !== 0 && data?.isApproved !== 1 && data?.isApproved !== -1))    return <span></span>;

                let value = Number(data?.foodAllowance);
                if(!value) return <span>--</span>
                if(day === "Sun") return props?.row?.original._id ? value.toFixed(2) : <span></span>
                return <span className={props?.row?.original?.editedAllowance?.foodAllowance === true ? "text-warning" : ""}>{value.toFixed(2)}</span>;
            },
          },
          {
            Header: "Hotel",
            accessor: "hotelAllowance",
            disableFilters: true,
            disableSortBy: true,
            showTotal: true,
            totalAccessor: "hotelAllowance",
            Cell:(props) => {
                let data = props?.row?.original;

                let day = new Date(data?.date).toLocaleString('en-us', { weekday: 'short' });
                // if(day === "Sun") return <span></span>

                if(!data?.submitter || (data?.isApproved !== 0 && data?.isApproved !== 1 && data?.isApproved !== -1))    return <span></span>;

                let value = Number(data?.hotelAllowance);
                if(!value) return <span>--</span>
                if(day === "Sun") return props?.row?.original._id ? value.toFixed(2) : <span></span>
                return <span className={props?.row?.original?.editedAllowance?.hotelAllowance === true ? "text-warning" : ""}>{value.toFixed(2)}</span>;
            },
          },
          {
            Header: "Ticket",
            accessor: "ticketAllowance",
            disableFilters: true,
            disableSortBy: true,
            showTotal: true,
            totalAccessor: "ticketAllowance",
            Cell:(props) => {
                let data = props?.row?.original;

                let day = new Date(data?.date).toLocaleString('en-us', { weekday: 'short' });
                // if(day === "Sun") return <span></span>

                if(!data?.submitter || (data?.isApproved !== 0 && data?.isApproved !== 1 && data?.isApproved !== -1))    return <span></span>;
                let value = Number(data?.ticketAllowance);
                if(!value) return <span>--</span>
                if(day === "Sun") return props?.row?.original._id ? value.toFixed(2) : <span></span>
                return value.toFixed(2);
            },
          },
          {
            Header: "Daily",
            accessor: "dailyAllowance",
            disableFilters: true,
            disableSortBy: true,
            showTotal: true,
            totalAccessor: "dailyAllowance",
            Cell:(props) => {
                let data = props?.row?.original;

                let day = new Date(data?.date).toLocaleString('en-us', { weekday: 'short' });
                // if(day === "Sun") return <span></span>

                if(!data?.submitter || (data?.isApproved !== 0 && data?.isApproved !== 1 && data?.isApproved !== -1))    return <span></span>;

                let value = Number(data?.dailyAllowance);
                if(!value) return <span>--</span>
                if(day === "Sun") return props?.row?.original._id ? value.toFixed(2) : <span></span>
                return value.toFixed(2);
            },
          },
          {
            Header: "Misc.",
            accessor: "misExpense",
            disableFilters: true,
            disableSortBy: true,
            showTotal: true,
            totalAccessor: "misExpense",
            Cell:(props) => {
                let data = props?.row?.original;

                let day = new Date(data?.date).toLocaleString('en-us', { weekday: 'short' });
                // if(day === "Sun") return <span></span>

                if(!data?.submitter || (data?.isApproved !== 0 && data?.isApproved !== 1 && data?.isApproved !== -1))    return <span></span>;

                let value = Number(data?.misExpense);
                if(!value) return <span>--</span>
                if(day === "Sun") return props?.row?.original._id ? value.toFixed(2) : <span></span>
                return value.toFixed(2);
            },
          },
          {
            Header: "Total",
            accessor: "",
            disableFilters: true,
            Cell: (props) => {
                let data = props?.row?.original;
                let day = new Date(data?.date).toLocaleString('en-us', { weekday: 'short' });
                // if(day === "Sun") return <span></span>;

                if(!data?.submitter || (data?.isApproved !== 0 && data?.isApproved !== 1 && data?.isApproved !== -1))    return <span></span>;

                let value = Number(data?.travelAllowance +
                    Number(data?.misExpense) +
                    data?.dailyAllowance +
                    data?.foodAllowance +
                    data?.hotelAllowance +
                    Number(data?.ticketAllowance));

                if(!value)  return <span>--</span>    
                if(day === "Sun") return props?.row?.original._id ? value.toFixed(2) : <span></span>
              return value.toFixed(2);
            },
          },
          {
            Header: "Remarks",
            accessor: "",
            disableFilters: true,
            disableSortBy: true,
            Cell: (props) => {
                let day = new Date(props?.row?.original?.date).toLocaleString('en-us', { weekday: 'short' });
                if(day === "Sun") return props?.row?.original._id ? (
                  <button
                    disabled={!props?.row?.original?.remarks?.length && !props?.row?.original?.adminRemark?.length}
                    className="react-table-view-link bg-transparent border-0"
                    onClick={() => {
                      setRemarkData(props.row.original);
                      setPopupType("remark")
                      setShowRemark(true);
                    }}
                  >
                    {(props?.row?.original?.adminRemark?.length > 0) ?
                      <IoNotificationsCircleOutline className={`icon-color-yellow ${!props?.row?.original?.adminRemark?.length && "opacity-25"}`} />
                      :
                      <MdInfoOutline className={`icon-color-tertiary ${!props?.row?.original?.remarks?.length && "opacity-25"}`} />
                    }
                  </button>
                )
                : <span></span>

                if(!props?.row?.original?.submitter)    return <span></span>;

              return (
                <button
                  disabled={!props?.row?.original?.remarks?.length && !props?.row?.original?.adminRemark?.length}
                  className="react-table-view-link bg-transparent border-0"
                  onClick={() => {
                    setRemarkData(props.row.original);
                    setPopupType("remark")
                    setShowRemark(true);
                  }}
                >
                  {(props?.row?.original?.adminRemark?.length > 0) ?
                    <IoNotificationsCircleOutline className={`icon-color-yellow ${!props?.row?.original?.adminRemark?.length && "opacity-25"}`} />
                    :
                    <MdInfoOutline className={`icon-color-tertiary ${!props?.row?.original?.remarks?.length && "opacity-25"}`} />
                  }
                </button>
              );
            },
          },
          {
            Header: "View",
            accessor: "View",
            Cell: (props) => {
                let day = new Date(props?.row?.original?.date).toLocaleString('en-us', { weekday: 'short' });
                if(day === "Sun") return props?.row?.original._id ? 
                (
                  <button
                    disabled={props?.row?.original?.files?.length > 0 ? false : true}
                    className="react-table-view-link bg-transparent border-0"
                    onClick={() => {
                      setImage(props.row.original);
                      setImageShow(true);
                    }}
                  >
                    <MdCameraAlt className={`icon-color-tertiary ${props?.row?.original?.files?.length > 0 ? null : "opacity-25"}`} />
                  </button>
                ) : <span></span>
              
                if(!props?.row?.original?.submitter)    return <span></span>;

              return (
                <button
                  disabled={props?.row?.original?.files?.length > 0 ? false : true}
                  className="react-table-view-link bg-transparent border-0"
                  onClick={() => {
                    setImage(props.row.original);
                    setImageShow(true);
                  }}
                >
                  <MdCameraAlt className={`icon-color-tertiary ${props?.row?.original?.files?.length > 0 ? null : "opacity-25"}`} />
                </button>
              );
            },
            minWidth: 60,
            maxWidth: 60,
            disableFilters: true,
            disableSortBy: true,
          },
        {
            Header: "Actions",
            accessor: "",
            Cell: (props) => {
                let data = props?.row?.original;

                return data?.isApproved === 1 ? 
                     <span>
                        <RiCheckDoubleLine size={25} className='icon-color-green'/>
                    </span>
                    :
                    data?.isApproved === -1 ? 
                    <span>
                        <RxCross2 size={25} className='icon-color-error'/>
                     </span>
                     :
                     data?.isApproved === 0 ? 
                     <span
                  className="react-table-view-link"
                  onClick={() => {
                    setEditForm(props.row.original);
                    setShowEdit(true);
                  }}
                >
                  <MdOutlinePendingActions className="icon-color-yellow" />
                </span>
                :
                <span className='opacity-25'>
                    <TiCancel size={25}/>
                </span>
            },
            minWidth: 70,
            maxWidth: 70,
            disableFilters: true,
            disableSortBy: true,
          },
        ],
        []
      );

  return (
    <div className="main-content admin-content">
        <div className="area-creation-content">
            <PageTitle title={`Monthly Expense view : ${location?.state?.user?.firstName} ${location?.state?.user?.lastName}`} hierarchy={true}/>

            <section className='area-creation-content__info'>   
            <div className="tp-tags d-flex mt-4 gap-md-3 gap-lg-5">
              <h4>
                <span className="tp-indicator-9 me-2"></span>Approved
              </h4>
              <h4>
                <span className="tp-indicator-10 me-2"></span>Rejected
              </h4>
              <h4>
                <span className="tp-indicator-5 me-2"></span>Pending
              </h4>
              <h4>
                <span className="tp-indicator-12 me-2"></span>Not Submitted
              </h4>
            </div>

            <div className='filter-table'>
                <Table data={expense?.data} columns={columns} defaultPageSize={50}/>
            </div>
            </section>
            {imageShow ? <ImagePopup data={image} /> : null}
            {showRemark ? <RemarkPopup data={remarkData} type={popupType} /> : null}
            {showEdit && <EditPopup data={approvalData}/>};
        </div>
    </div>
  )
}

export default MonthlyExpenseApproval;