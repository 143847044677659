import { useEffect, useState, useMemo,useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import customToast from "../../../../components/CustomToast";
import {
  clearProductTypes,
  setProductTypes,
} from "../../../../reducers/products/typeReducer";
import Select from "react-select";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import { setProductRanges, clearProductRanges } from "../../../../reducers/products/rangeReducer";
import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";
import serialise from "../../../../utils/serialiseResponse";
import { RiEdit2Fill } from "react-icons/ri";
import {MdOutlineDownloadDone} from "react-icons/md";
import { updateMinInventory } from "../../../../services/products";
import { viewInventoryAlerts } from "../../../../reducers/products/MinimumStockReducer";

const AdminMinStock = () => {
  const dispatch = useDispatch();
  const types = useSelector(({ productType }) => productType);
  const products = useSelector(({ productRange }) => productRange);
  
  const [category, setCategory] = useState({
    label: "Product Type",
  });
  const [editRow, setEditRow] = useState();
  const categoryOptionsSelect = useMemo(
    () => [
      {label: "Product Type", },
      {label: "Products", },
    ],
    []
  );
  const inputRef = useRef(0);
  const tableData = useMemo(() => serialise(types.data), [types]);
  const tableData2 = useMemo(() => serialise(products.data), [products]);
  
  useEffect(() => {
    dispatch(setProductTypes());
    dispatch(setProductRanges());
    return ()=>{ dispatch(clearProductTypes());
      dispatch(clearProductRanges());
    }
  }, [dispatch]);

  const handleEditSubmit = useCallback((e)=>{
    e.preventDefault();
    if(!inputRef.current)return customToast.error("You might want to keep value 0");
    let obj = tableData[Number(editRow)-1];
    if(category.label === "Products")obj = tableData2[Number(editRow)-1];
    obj.minStock = inputRef.current ;
    setEditRow("");
  },[editRow,tableData]);

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr no.",
      //   accessor: "sr",
      //   disableFilters:true,
      //   minWidth:40,
      //   maxWidth:40
      // },
      {
        Header: "Product Type",
        accessor: "name",
        disableSortBy:true,
      },
      {
        Header: "Minimum Stock",
        accessor: "",
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          return editable ? (
            <span
              className="react-table-view-link"
            >
              <MdOutlineDownloadDone className="icon-color-yellow me-4" onClick={(e) => {
                handleEditSubmit(e);
              }}
              />
              <input
                defaultValue={props?.row?.original?.minStock}
                onChange={(e) => {
                  inputRef.current = e.target.value ;
                }}
                onKeyDown={(e) =>{
                  if(e.key === "Enter")handleEditSubmit(e);
                }}
                autoFocus={true}
                type="number"
                className="sales-table__input d-inline-block w-75"
              />
            </span>
          ): (
            <span>
              <span
                className="react-table-view-link me-4"
                onClick={() => {
                  setEditRow(props?.row?.original?.sr);
                  inputRef.current = props?.row?.original?.minStock || 0;
                }}
              >
                <RiEdit2Fill className="icon-color-green" />
              </span>
            {props?.row?.original?.minStock}</span>
          );
        },
        minWidth:70,
        maxWidth:70,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [editRow]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    updateMinInventory(category.label,category.label === "Products" ? tableData2 : tableData).then((data)=>{
      customToast.success("Minimum Inventory Updated Successfully")
      dispatch(setProductTypes());
      dispatch(setProductRanges());
      dispatch(viewInventoryAlerts());
    });
  };

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Manage Minimum Stocks" />

        <section className="area-creation-content__form">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-8">
                <label htmlFor="prod-type">
                  Filter <span className="asterisk-imp">*</span>
                  <Select
                    value={category}
                    options={categoryOptionsSelect}
                    onChange={(e) =>
                      setCategory({...e})
                    }
                    styles={adminStyles}
                    placeholder="Select Product Supplier"
                  />
                </label>
                <br />
                
              </div>

              <div className="col-sm-6 col-md-6 col-lg-4 admin-creation-content__submit">
                <button type="submit" className="button-blue-gradient">
                  Submit MIN Stock
                </button>
              </div>
            </div>
          </form>
        </section>

        <section className="area-creation-content__info">
          <h2 className="web-app__heading">Min Stocks</h2>
          <div className="filter-table">
            <Table columns={columns} data={category.label === "Products" ? tableData2 : tableData} />
          </div>
        </section>
      </div>
    </main>
  );
};

export default AdminMinStock;
