export const title = "Manage Products";

export const links = [
  {
    name: "Product Category",
    route: "category",
    accessor: "category",
  },
  {
    name: "Product Type",
    route: "type",
    accessor: "type",
  },
  {
    name: "Product",
    route: "range",
    accessor: "range",
  },
  {
    name: "Upload Product",
    route: "upload-products",
    accessor: "upload-products",
  },
  {
    name:"Product Supplier",
    route:"add-supplier",
    accessor:"add-supplier"
  },
  {
    name:"Inventory",
    route:"inventory",
    accessor:"inventory"
  },
  {
    name:"Minimum Stock",
    route:"min-stock",
    accessor:"minStock"
  }
];
