import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";

import Table from "../../../../../components/Table";
import {
  clearStates,
  setStates,
} from "../../../../../reducers/locations/stateReducer";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../../reducers/locations/hqReducer";
import {
  clearDivisions,
  setDivisions,
} from "../../../../../reducers/users/divisionReducer";
import serialise, {
  generateSelectData, getDesignation,
} from "../../../../../utils/serialiseResponse";
import { adminStyles } from "../../../../public/stylesheets/selectStyles";
import customToast from "../../../../../components/CustomToast";
import {
  clearUsers,
  setUsers,
} from "../../../../../reducers/users/employeeReducer";
import PageTitle from "../../../../../components/PageTitle";
import { BiTargetLock } from "react-icons/bi";
import {
  deleteTargets,
  monthTargets,
} from "../../../../../reducers/reports/targetReducer";
import {
  monthOptions,
  yearOptions,
  returnMonthFromIndex,
} from "../../../../../utils/helper";
import { fetchMonthTargets } from "../../../../../services/reports";
import { RiCloseCircleLine } from "react-icons/ri";
import {
  MdCheckCircleOutline,
  MdClear,
  MdDelete,
  MdDeleteOutline,
  MdDoneAll,
} from "react-icons/md";
import { setDesignations } from "../../../../../reducers/users/designationReducer";

const AdminUserTarget = () => {
  const dispatch = useDispatch();
  const states = useSelector(({ state }) => state);
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const userData = useSelector(({ employee }) => employee);
  const navigate = useNavigate();
  const stateSelect = useMemo(
    () => generateSelectData(states.data, "name"),
    [states]
  );
  const headquarterSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );

  const [state, setState] = useState(null);
  const [headquarter, setHeadquarter] = useState(null);
  const [month, setMonth] = useState({
    value: new Date().getMonth(),
    label: returnMonthFromIndex(new Date().getMonth()),
  });
  const [year, setYear] = useState("")
  const [showEdit, setShowEdit] = useState(false);
  const [tableToggle, setTableToggle] = useState(false);
  const [alreadyAlloted, setAlreadyAlloted] = useState();
  const [deleteData, setDeleteData] = useState();
  const [deletePopup, setDeletePopup] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  

  useEffect(() => {
    dispatch(setDesignations);
    dispatch(setDivisions());
    dispatch(setStates());
    return () => {
      dispatch(clearDivisions());
      dispatch(clearStates());
      dispatch(clearUsers());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!state) return;

    dispatch(setHeadquarters(state.value));
    return () => {
      dispatch(clearHeadquarters());
    };
  }, [dispatch, state]);
  const redirectToEdit = (userId, element) => {
    navigate(`/admin-panel/users/set-user-target/${userId}`, {
      state: element,
    });
  };

  const EditPopup = ({ data }) => {
    const queryHandler = async () => {
      setShowEdit(false);
      setTableToggle(true);
      let data = await fetchMonthTargets("userTarget", {
        month: month?.value,
        year : Number(year?.label),
      });
      setAlreadyAlloted(data);
    };

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Select Month</h2>
            <h2 className="web-app__heading">Select Year</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-6">
              <br />
              <Select
                name="month"
                id="month"
                value={month}
                options={monthOptions}
                onChange={(e) => setMonth({ ...e })}
                styles={adminStyles}
                placeholder="Select Month"
              />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-6">
              <br />
              <Select
                name="month"
                id="month"
                value={year}
                options={yearOptions}
                onChange={(e) => setYear({ ...e })}
                styles={adminStyles}
                placeholder="Select Year"
              />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-5 area-creation-content__form-submit" style={{marginTop: "2%"}}>
              <button
                type="button"
                className="button-blue-gradient"
                onClick={queryHandler}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const DeletePopup = ({ data }) => {
    const dispatch = useDispatch();
    const handleDelete = async (e) => {
      e.preventDefault();
      try {
       await dispatch(deleteTargets("userTarget", { id: data?._id }));
        setIsDeleted(!isDeleted);

        setDeletePopup(false);
        customToast.success("Allotment deleted successfully");
        let response = await fetchMonthTargets("userTarget", {
          month: month?.value,
        });
        setAlreadyAlloted(response.data);
      } catch {
        customToast.error("We encountered an error !");
      }
    };

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete Alloted Target</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setDeletePopup(false)}
            />
          </div>

          <form onSubmit={handleDelete}>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  This will PERMANENTLY delete the alloted target !
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-delete mt-4">
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Yes</span>
              </button>

              <button
                type="button"
                className="button-submit-green mt-4"
                onClick={() => setDeletePopup(false)}
              >
                <span className="button-submit-green__icon-container">
                  <MdCheckCircleOutline className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">No</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };
  const columns = useMemo(
    () => [
      
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Designation",
        accessor: "",
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 80,
        Cell:(props) =>{
          return <span>{getDesignation(props?.row?.original?.des)}</span>
        },
      },
      {
        Header: "Headquarter",
        accessor: "headquarter",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Division",
        accessor: "division",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Add Target",
        accessor: "details",
        Cell: (props) => (
          <span
            className="react-table-view-link"
            onClick={() => {
              redirectToEdit(props?.row?.original?._id, props?.row?.original);
            }}
          >
            <BiTargetLock className="icon-color-green" />
          </span>
        ),
        disableFilters: true,
        disableSortBy: true,
        minWidth: 70,
        maxWidth: 70,
      },
    ],
    []
  );

  const targetColumns = useMemo(
    () => [
      
      {
        Header: "Alloted To",
        accessor: "user",
        Cell:  (props) => {
          return <span>{props?.row?.original?.user?.firstName + " " + props?.row?.original?.user?.lastName}</span>
        }
      },
      {
        Header: "Lump Amount",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 80,
        Cell: (props) => {
          return (
            <span>
              {props?.row?.original?.lumpAmount > 0 ? (
                props?.row?.original?.lumpAmount
              ) : (
                <MdClear style={{ color: "#e74c3c" }} />
              )}
            </span>
          );
        },
      },
      {
        Header: "Product Amount",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 80,
        Cell: (props) => {
          return (
            <span>
              {props?.row?.original?.products?.length > 0 ? (
                props?.row?.original?.products?.reduce(
                  (acc, a) => acc + a.amount,
                  0
                )
              ) : (
                <MdClear style={{ color: "#e74c3c" }} />
              )}
            </span>
          );
        },
      },

      {
        Header: "Delete",
        accessor: "4",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 50,
        Cell: (props) => {
          return (
            <span
              className="react-table-view-link"
              onClick={() => {
                setDeleteData(props?.row?.original);
                setDeletePopup(true);
              }}
            >
              <MdDelete className="icon-color-error" />
            </span>
          );
        },
      },
    ],
    []
  );

  const handleFilter = (e) => {
    e.preventDefault();
    const data = {
      hqId: headquarter?.value,
    };

    try {
      dispatch(setUsers("user", data));
      setTableToggle(false);
    } catch (e) {
      customToast.error("We Encountered an Error");
    }
  };

  const targetHandler = async () => {
    setTableToggle(true);
    setShowEdit(true);
  };

  const tableData = useMemo(() => serialise(userData), [userData]);
  const targetTableData = useMemo(() => serialise(alreadyAlloted),[alreadyAlloted]);
  
  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Set User Target" />

        <section className="area-creation-content__form">
          <form onSubmit={handleFilter}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="col-sm-12 col-md-4 col-lg-4 me-3">
                <label htmlFor="state">Select State</label>
                <br />
                <Select
                  name="state"
                  id="state"
                  value={state}
                  options={stateSelect}
                  onChange={(e) => setState({ ...e })}
                  styles={adminStyles}
                  placeholder="Select State"
                />
              </div>

              <div className="col-sm-12 col-md-4 col-lg-4 me-3">
                <label htmlFor="state">Select HQ</label>
                <br />
                <Select
                  name="headquarter"
                  id="headquarter"
                  value={headquarter}
                  options={headquarterSelect}
                  onChange={(e) => setHeadquarter({ ...e })}
                  styles={adminStyles}
                  placeholder="Select Headquarter"
                />
              </div>

              <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
              <button type="submit" className="button-blue-gradient mt-3">
                  Filter
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-3 area-creation-content__form-submit mt-5">
                <div className="d-flex">
                <button
                  type="button"
                  // onClick={targetHandler}
                  onClick={() => navigate("./monthly-user-target")}
                  className="button-cyan-gradient"
                >
                  Monthly Targets
                </button>
                <button
                  type="button"
                  onClick={() => navigate(`./yearly-targets`)}
                  className="button-cyan-gradient ms-5"
                >
                  Yearly Targets
                </button>
                </div>
              </div>
          </form>
        </section>

        <section className="area-creation-content__info py-0">
          {!tableToggle
            ? tableData?.length > 0 && (
                <h2 className="web-app__heading mt-4">
                  Showing ({tableData?.length}) Targets
                </h2>
              )
            : targetTableData?.length > 0 && (
                <h2 className="web-app__heading mt-4">
                  Showing ({targetTableData?.length}) Entries
                </h2>
              )}
          <div className="filter-table">
            {!tableToggle ? (
              tableData?.length > 0 ? (
                <Table columns={columns} data={tableData} />
              ) : (
                <h3 className="mt-2 no-results-message">No results found</h3>
              )
            ) : targetTableData?.length > 0 ? (
              <Table columns={targetColumns} data={targetTableData} />
            ) : (
              <h3 className="mt-2 no-results-message">No results found</h3>
            )}
          </div>
        </section>
        {showEdit ? <EditPopup /> : null}
        {deletePopup ? <DeletePopup data={deleteData} /> : null}
      </div>
    </div>
  );
};

export default AdminUserTarget;
