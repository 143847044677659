import Table from "../../../../components/Table";

import React, { useEffect, useMemo, useState } from "react";
import { MdArrowBackIosNew } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { viewSampleGiftDetails } from "../../../../services/utilities";
import { formatDate } from "../../../../utils/helper";
import { addIndex } from "../../../../utils/serialiseResponse";

const SampleDetails = () => {
  const { SampleDetails } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [reports, setReports] = useState([]);
  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   disableSortBy: true,
      // },
      {
        Header: "Name",
        accessor: "target.name",
        disableSortBy: true,
        minWidth: 300,
        maxWidth: 300,
        // Cell:(props)=>{props?.row?.original?.pobDetails.map((e)=>(e.doctorId.name))}
      },
      {
        Header: "Type",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 200,
        maxWidth: 300,
        Cell:(props)=>{return props?.row?.original?.target.accessDoctor?"Doctor":props?.row?.original?.target.accessChemist?"Chemist":null}

      },
      {
        Header: "Distributed Quantity",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 200,
        maxWidth: 300,
        Cell: (props) => {
          let filteredSample=props?.row?.original?.products.find((e)=>e.focusProduct.toString()===SampleDetails.toString())
          return filteredSample.sampleQuantity?filteredSample.sampleQuantity:0
        },
      },
      {
        Header: "Date",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 200,
        maxWidth: 300,
        Cell: (props) =>
          formatDate(new Date(props?.row?.original?.cdt).toLocaleDateString()),
      },
    ],
    []
  );

  const fetchSampleGiftDetails = async () => {
    let data = {
      month: location?.state?.month,
      year: Number(location?.state?.year),
      selectedUser: location?.state?.user._id,
      productId: SampleDetails,
    };
    let result = await viewSampleGiftDetails("sample", data);
    setReports(result.data.data);
  };


  useEffect(() => {
    fetchSampleGiftDetails();
  }, [location.state]);
  const tableData = useMemo(() => addIndex(reports), [reports]);

  return (
    <div className="expense">
      <div className="row">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MdArrowBackIosNew
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span style={{ display: "inline-block", margin: "0 1rem" }}>
            Sample Details
          </span>
        </h2>

        <div className="filter-table">
          {reports.length > 0 ? (
            <>
            <h2 className="web-app__heading mb-4 ms-2">
              Showing ({reports.length}) Entries
            </h2>
          <Table columns={columns} data={tableData} />

            </>
            
          ): (
            <h3 className=" no-results-message">No results found</h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default SampleDetails;
