import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseUrl = `${apiURL}/client/dcsr/`;
const baseUrlV1 = `${apiURL}/client/v1/dcsr/`;

export const viewCallReport = async (filters) => {
  const { data } = await axios.get(baseUrlV1, {
    params: filters,
    headers: setAuth(),
  });

  return data;
};

export const viewCallReportForApproval = async (filters) => {
  const { data } = await axios.get(baseUrl, {
    params: filters,
    headers: setAuth(),
  });

  return data;
};

export const viewDCScallReport = async(type,filters)=>{
  const {data} = await axios.get(baseUrl + "dcs/" + type,{
    params:filters,
    headers:setAuth(),
  });
  return data;
};

export const viewBacklogReport = async (filters) => {
  const { data } = await axios.get(baseUrl + "backlog", {
    params: filters,
    headers: setAuth(),
  });

  return data;
};
export const viewWorkedWithReport = async (filters) => {
  const { data } = await axios.get(baseUrl + "worked-with", {
    params: filters,
    headers: setAuth(),
  });

  return data;
};

export const approveCallReport = async (filters) => {
  const { data } = await axios.post(baseUrl + "approve", filters, {
    headers: setAuth(),
  });

  return data;
};

export const rejectCallReport = async (filters) => {
  const { data } = await axios.post(baseUrl + "reject", filters, {
    headers: setAuth(),
  });

  return data;
};

export const deleteCallReport = async (id) => {
  const { data } = await axios.post(baseUrl + `delete/${id}`, {}, {
    headers: setAuth(),
  });

  return data;
};
// export const viewBacklogReport = async (id) => {
//   const { data } = await axios.get(baseUrl , {}, {
//     headers: setAuth(),
//   });

//   return data;
// };

export const viewLastCallReport = async(id) =>{
  const {data} = await axios.get(baseUrl + `view-last-callReport-ofuser`,{
    params: id,
    headers: setAuth()
  });
  return data;
}
