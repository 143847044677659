import { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../../components/PageTitle";
import Select from "react-select";
import { getAccessStatus } from "../../../../services/payment";
import Table from "../../../../components/Table";
import {
  clearDivisions,
  setDivisions,
} from "../../../../reducers/users/divisionReducer";
import serialise, {
  generateSelectData,
} from "../../../../utils/serialiseResponse";
import {
  clearStates,
  setStates,
} from "../../../../reducers/locations/stateReducer";
import {
  clearUsers,
  setUsers,
} from "../../../../reducers/users/employeeReducer";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../reducers/locations/hqReducer";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import customToast from "../../../../components/CustomToast";
import { toggleUserAccess } from "../../../../services/users";
import { useNavigate } from "react-router-dom";

const AccessControl = ({ socket }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const divisionData = useSelector(({ division }) => division);
  const states = useSelector(({ state }) => state);
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const userData = useSelector(({ employee }) => employee);

  const divisionSelect = useMemo(
    () => generateSelectData(divisionData, "name"),
    [divisionData]
  );
  const stateSelect = useMemo(
    () => generateSelectData(states.data, "name"),
    [states]
  );
  const headquarterSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );

  const [state, setState] = useState(null);
  const [headquarter, setHeadquarter] = useState(null);
  const [division, setDivision] = useState(null);
  const [accessStatus , setAccessStatus] = useState(null);
  const [showPopup , setShowPopUP] = useState(false);

  const Popup = ()=>{
    return (
      <div className="edit-popup">
        <div className="edit-content d-flex flex-column align-items-center">
          <h2 className="web-app__heading lh-base">
            Your Account has been <span className="text-danger fw-bold">LOCKED</span>, Please Pay your bills
          </h2>

          <div className="edit-form-button-container mt-5">
            <button onClick={(e)=>{
              e.preventDefault();
              navigate("../billing");
            }} className="button-blue-gradient">
              Pay Now
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  useEffect(() => {
    dispatch(setDivisions());
    dispatch(setStates());
    return () => {
      dispatch(clearDivisions());
      dispatch(clearStates());
      dispatch(clearUsers());
    };
  }, [dispatch]);
  
  useEffect(()=>{
    const fetch = async()=>{
      try{
      const {data} = await getAccessStatus();
      setAccessStatus(data);
      }catch(err){
        // console.log(err);
      }
    }
    fetch();
  },[]);


  useEffect(() => {
    if (!state) return;

    dispatch(setHeadquarters(state.value));
    return () => {
      dispatch(clearHeadquarters());
    };
  }, [dispatch, state]);

  const handleFilter = (e) => {
    e.preventDefault();

    const data = {
      hqId: headquarter?.value,
      divisionId: division?.value,
    };

    try {
      dispatch(setUsers("user", data));
    } catch (e) {
      customToast.error("We Encountered an Error");
    }
  };

  const onToggle =  async (userId, value) => {
    try {
      if(accessStatus?.status === 'denied'){
        setShowPopUP(true);
        return customToast.error("Your bill is pending");
      }
      let {data} = await toggleUserAccess(userId, value);
      socket.emit("lock-user", {userId, value}); //data is locked value, that is true or false

    } catch (e) {
      return customToast.error("Sorry, we encountered some error");
    }
  } ;

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Reason",
        accessor: "lockReason",
      },
      {
        Header: "Lock User",
        accessor: "actions",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <label className="toggle-label error">
              <input
                type="checkbox"
                defaultChecked={props?.row?.original?.isLocked}
                onClick={({target}) => onToggle(props?.row?.original?._id, target.checked)}
              />
              <span/>
            </label>
          );
        },
      },
    ],
    [accessStatus]
  );
  const tableData = useMemo(() => serialise(userData), [userData]);

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Access Control" />

        <section className="area-creation-content__form">
          <form onSubmit={handleFilter}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">Select State</label>
                <br />
                <Select
                  name="state"
                  id="state"
                  value={state}
                  options={stateSelect}
                  onChange={({ value, label }) => setState({ value, label })}
                  styles={adminStyles}
                  placeholder="Select State"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">Select HQ</label>
                <br />
                <Select
                  name="headquarter"
                  id="headquarter"
                  value={headquarter}
                  options={headquarterSelect}
                  onChange={({ value, label }) =>
                    setHeadquarter({ value, label })
                  }
                  styles={adminStyles}
                  placeholder={
                    state ? "Select Headquarter" : "Select State First"
                  }
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">Select Division</label>
                <br />
                <Select
                  name="division"
                  id="division"
                  value={division}
                  options={divisionSelect}
                  onChange={({ value, label }) => setDivision({ value, label })}
                  styles={adminStyles}
                  placeholder="Select Division"
                />
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-sm-12 col-md-4 col-lg-4 area-creation-content__form-submit">
                <button type="submit" className="button-blue-gradient">
                  Filter Users
                </button>
              </div>
            </div>
          </form>
        </section>

        <section className="area-creation-content__info">
          {tableData.length > 0 && (
            <h2 className="web-app__heading mb-4 ms-2">
              Showing ({tableData.length}) Entries
            </h2>
          )}
          <div className="filter-table">
            <Table columns={columns} data={tableData} />
          </div>
        </section>
      </div>
      {showPopup && <Popup />}
    </div>
  );
};

export default AccessControl;
