import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import { generateSelectData } from "../../../../utils/serialiseResponse";
import { viewProducts, viewProductV1 } from "../../../../services/products";
import { MdCheckCircleOutline, MdDelete, MdCancel } from "react-icons/md";
import Table from "../../../../components/Table";
import {
  clearProductRanges,
  setProductRanges,
} from "../../../../reducers/products/rangeReducer";
import { RiEdit2Fill } from "react-icons/ri";
import { AiFillSave, AiFillFolderOpen } from "react-icons/ai";
import { addProductInventory } from "../../../../reducers/products/inventoryReducer";
import {
  clearSupplier,
  setProductSupplier,
} from "../../../../reducers/products/supplierReducer";
import { viewInventoryAlerts } from "../../../../reducers/products/MinimumStockReducer";
import { fileToBase64 } from "../../../../utils/helper";

const AdminProductsInventory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedIn = useSelector(({ user }) => user);
  const productSupplier = useSelector(
    ({ productsSupplier }) => productsSupplier
  );
  //   const supplier = useSelector(({ productSupplier }) => productSupplier);

  const inputRef = useRef(null); // for upload file input
  const [date, setDate] = useState("");
  const [supplier, setSupplier] = useState("");
  const [uploadData, setUploadData] = useState(""); //this will be used to upload the image file

  // below states are for providing select product option and then add it to list
  const [productOptions, setProductOptions] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");

  // copied from primary sales
  const [finalArray, setFinalArray] = useState([]); //this is the array that will get submitted
  const [editRow, setEditRow] = useState("");
  const [editing, setEditing] = useState(false);

  
  let changeHandler = (e) => {
    const validFiles = Array.from(e.target.files);
    setUploadData([]);
    Promise.all(validFiles.map(fileToBase64))
      .then((base64Images) => {
        setUploadData(base64Images);
      })
      .catch((error) => {
        customToast.error("Error adding images");
      });
  };

  const supplierSelect = useMemo(
    () => generateSelectData(productSupplier.data, "name"),
    [productSupplier]
  );

  const upQuantityRef = useRef(0);
  const upPriceRef = useRef(0);
  const batchRef = useRef("");
  const expiryRef = useRef("");

  const resetForm = () => {
    setDate("");
    setSupplier("");
    setUploadData("");
    setFinalArray([]);
    setEditing(false);
    setEditRow("");

    upQuantityRef.current = 0;
    upPriceRef.current = 0;
    batchRef.current = "";
    expiryRef.current = "";
  };

  useEffect(() => {
    dispatch(setProductRanges());
    dispatch(setProductSupplier());
    return () => {
      dispatch(clearProductRanges());
      dispatch(clearSupplier());
    };
  }, [dispatch]); 

  const deleteHandler = (product) => {
    setEditRow("");
    setEditing(false);
    let sr = 0;
    const newfinalArray = finalArray.filter((ele, idx) => {
      if (ele?.product?._id !== product?._id) {
        sr += 1;
        ele.sr = sr;
        return true;
      } else return false;
    });
    setFinalArray(newfinalArray);
  };

  const handleEditRow = (sr) => {
    if (editing) {
      customToast.error("Please save the changes you just made");
      return;
    }
    setEditing(true);
    setEditRow(sr);
  };
  const submitEditedRow = (sr) => {
    const editedObj = finalArray[sr - 1];
    editedObj.quantity = upQuantityRef.current;
    editedObj.price = upPriceRef.current;
    editedObj.totalPrice =
      Number(upQuantityRef.current) * Number(upPriceRef.current);
    editedObj.batchNo = batchRef.current;
    editedObj.expiryDate = expiryRef.current;
    setEditing(false);
    setEditRow("");
  };
  const cancelHandler = (sr) => {
    setEditing(false);
    setEditRow("");
  };

  const handleProductAdd = () => {
    if (!selectedProduct) {
      setSelectedProduct("");
      customToast.error("Please select a product");
      return;
    }

    if (editing) {
      customToast.error("Please save the changes before adding new product");
      setSelectedProduct("");
      return;
    }
    if ( finalArray.filter((ele) => ele.product?._id === selectedProduct?._id).length > 0) {
      customToast.error("Product already present in the table");
      setSelectedProduct("");
      return;
    }
    const nextsr = finalArray.length + 1;
    const newObj = {
      sr: nextsr,
      product: selectedProduct,
      quantity: 0,
      price: Number(selectedProduct?.mrp),
      totalPrice: 0,
      batchNo: "",
      expiryDate: "",
    };
    setSelectedProduct("");
    setFinalArray([...finalArray, newObj]);
  };
  // we can add a check inside this effect if products  are present in store or not
  useEffect(() => {
    // viewProducts("").then((response) => {
    viewProductV1().then((response) => {
      setProductOptions(response?.data);
    });
    dispatch(viewInventoryAlerts());
  }, [dispatch]);

  const targetSelectProduct = useMemo(
    () => generateSelectData(productOptions, "name"),
    [productOptions]
  );

  const handleInventorySubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (editing) {
        customToast.error("Please save the changes you just made !!");
        return;
      }
      if (!date || !supplier) {
        return customToast.error("Please select date and supplier");
      }
      if (finalArray.length === 0) {
        customToast.error("Please add atleast one product");
        return;
      }
      const payload = {
        table: finalArray,
        supplier: supplier?.value,
        date: date,
        images: uploadData,
      };
      try {
        // sending data to backend
        dispatch(addProductInventory(payload));
        dispatch(viewInventoryAlerts());
        resetForm();
        return customToast.success("Inventory added successfully");
      } catch (err) {
        return customToast.error("Sorry ! We encountered an error");
      }
    },
    [finalArray, date, supplier, editing, dispatch, uploadData]
  );

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 50,
      //   maxWidth: 50,
      // },
      {
        Header: "Product",
        accessor: "product.name",
        disableSortBy: true,
        minWidth: 150,
        maxWidth: 150,
        showTotal: true,
        placeholderTotal: "Total",
      },
      {
        Header: "Unit Price",
        accessor: "product.mrp",
        disableFilters: true,
        minWidth: 100,
        maxWidth: 100,
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          if (editable) upPriceRef.current = props?.row?.original?.price;
          return editable ? (
            <input
              defaultValue={props?.row?.original?.price}
              onChange={(e) => {
                upPriceRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input"
            />
          ) : (
            <span>{props?.row?.original?.price}</span>
          );
        },
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 100,
        showTotal: true,
        totalAccessor: "quantity",
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          if (editable) upQuantityRef.current = props?.row?.original?.quantity;
          return editable ? (
            <input
              defaultValue={props?.row?.original?.quantity}
              onChange={(e) => {
                upQuantityRef.current = e.target.value;
              }}
              type="number"
              className="sales-table__input"
            />
          ) : (
            <span>{props?.row?.original?.quantity}</span>
          );
        },
      },
      {
        Header: "Total Price ",
        accessor: "totalPrice",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 90,
        showTotal: true,
        totalAccessor: "totalPrice",
      },

      {
        Header: "Batch Number",
        accessor: "batchNo",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 90,
        showTotal: false,
        totalAccessor: "batchNo",
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          if (editable) batchRef.current = props?.row?.original?.batchNo;
          return editable ? (
            <input
              defaultValue={props?.row?.original?.batchNo}
              onChange={(e) => {
                batchRef.current = e.target.value;
              }}
              type="text"
              className="sales-table__input"
            />
          ) : (
            <span>{props?.row?.original?.batchNo}</span>
          );
        },
      },
      {
        Header: "Expiry Date",
        accessor: "expiryDate",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 90,
        showTotal: false,
        totalAccessor: "expiryDate",
        Cell: (props) => {
          const editable = Number(props?.row?.original?.sr) === Number(editRow);
          if (editable) expiryRef.current = props?.row?.original?.expiryDate;
          return editable ? (
            <input
              defaultValue={props?.row?.original?.expiryDate}
              onChange={(e) => {
                expiryRef.current = e.target.value;
              }}
              type="date"
              className="sales-table__input"
            />
          ) : (
            <span>{props?.row?.original?.expiryDate}</span>
          );
        },
      },

      {
        Header: "Actions",
        accessor: "action",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 90,
        Cell: (props) => {
          return editing && Number(props?.row?.original?.sr) === editRow ? (
            <div className="d-flex gap-2 justify-content-center">
              <AiFillSave
                onClick={(e) => {
                  submitEditedRow(props?.row?.original?.sr);
                }}
                type="button"
                className="icon-color-green"
              />
              <MdDelete
                onClick={() => {
                  deleteHandler(props?.row?.original?.product);
                }}
                type="button"
                className="button-delete__icon"
              />
              <MdCancel
                type="button"
                className="icon-color-yellow"
                onClick={() => {
                  cancelHandler(props?.row?.original?.sr);
                }}
              />
            </div>
          ) : (
            <span
              className="react-table-view-link"
              onClick={() => {
                handleEditRow(props?.row?.original?.sr);
              }}
            >
              <RiEdit2Fill className="icon-color-green" />
            </span>
          );
        },
      },
    ],
    [finalArray, editing]
  );
  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Inventory" />
        <section className="area-creation-content__form">
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="date-input">Select Date</label>
              <span className="asterisk-imp">*</span>
              <br />
              <input
                type="date"
                className="me-5"
                id="date-input"
                placeholder="Date"
                value={date}
                onChange={({ target }) => setDate(target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="product-supplier">Select Product Supplier</label>
              <span className="asterisk-imp">*</span>
              <br />
              <Select
                name="supplier"
                id="supplier"
                value={supplier}
                options={supplierSelect}
                onChange={(e) => setSupplier({ ...e })}
                styles={adminStyles}
                placeholder="Select Product Supplier"
              />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="employee">Upload file</label>
              <br />
              <input
                ref={inputRef}
                type="file"
                placeholder="you can upload image, excel or pdf"
                onChange={changeHandler}
              />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="employee">Select Product</label>
              <span className="asterisk-imp">*</span>
              <br />
              <Select
                styles={adminStyles}
                options={targetSelectProduct}
                placeholder="Select Product"
                value={selectedProduct}
                onChange={(e) => setSelectedProduct({ ...e })}
              />
            </div>

            <div className="col-sm-12 col-md-4 col-lg-4">
              <div className="mt-2 pt-2">
                <button
                  type="button"
                  onClick={handleProductAdd}
                  className="button-blue-gradient  mt-5"
                >
                  Add Product
                </button>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <div className="mt-2 pt-2">
                <button
                  type="button"
                  className="button-submit-green mt-5 ms-auto"
                  onClick={() => navigate(`./full`)}
                >
                  <span className="button-submit-green__icon-container">
                    <AiFillFolderOpen className="button-submit-green__icon" />
                  </span>
                  <span className="button-submit-green__text ">
                    See Full Inventory
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className="filter-table" style={{ minHeight: "300px" }}>
            <Table columns={columns} data={finalArray} />
          </div>
          {finalArray.length > 0 && (
            <button
              onClick={handleInventorySubmit}
              className="button-submit-green mt-4 mx-auto"
            >
              <span className="button-submit-green__icon-container">
                <MdCheckCircleOutline className="button-submit-green__icon" />
              </span>
              <span className="button-submit-green__text ">
                Submit Inventory Data
              </span>
            </button>
          )}
        </section>
      </div>
    </div>
  );
};

export default AdminProductsInventory;
