import { useMemo, useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import PageTitle from "../../../../components/PageTitle";
import serialise from "../../../../utils/serialiseResponse";
// below are the lines from sales import
import {
  formatDate,
  monthOptions,
  returnMonthFromIndex,
} from "../../../../utils/helper";
import Table from "../../../../components/Table";
import {
  viewProductInventory,
  clearProductInventory,
} from "../../../../reducers/products/inventoryReducer";
import { FaEye } from "react-icons/fa";
import { AiFillFolder } from "react-icons/ai";

const AllInventory = () => {
  const dispatch = useDispatch();
  const inventory = useSelector(({ inventory }) => inventory);
  const navigate = useNavigate();
  //   creating allinventory
  const [month, setMonth] = useState({
    value: new Date().getMonth(),
    label: returnMonthFromIndex(new Date().getMonth()),
  });
  const inputRef = useRef(null); // for download file input

  useEffect(() => {
    return () => {
      dispatch(clearProductInventory());
    };
  }, [dispatch]);
  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        showTotal: true,
        placeholderTotal: "Total",
        Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
      },
      {
        Header: "Supplier",
        accessor: "supplier",
        disableSortBy: true,
      },
      {
        Header: "Total",
        accessor: "total",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "total",
      },

      
      {
        Header: "View",
        accessor: "vi",
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          // props.row.original.selectedUser = selectedUser?.value
          return (
            <Link
              to={`${props?.row?.original?._id}`}
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    [inventory]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(viewProductInventory({ month: month?.value }));
    // put some checks and fetch data for particular month
  };

  const tableData = useMemo(() => serialise(inventory.data), [inventory]);
  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="All Inventory" hierarchy={true} />
        <section className="area-creation-content__form">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="date-input">Select Month</label>
                <span className="asterisk-imp">*</span>
                <br />
                <Select
                  name="month"
                  id="month"
                  value={month}
                  options={monthOptions}
                  onChange={(e) => setMonth({ ...e })}
                  styles={adminStyles}
                  placeholder="Select Month"
                />
              </div>

              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="mt-2 pt-2">
                  <button className="button-blue-gradient  mt-5">Submit</button>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 ms-auto">
                <div className="mt-2 pt-2">
                  <button
                    type="button"
                    className="button-submit-green mt-5 ms-auto"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <span className="button-submit-green__icon-container">
                      <AiFillFolder className="button-submit-green__icon" />
                    </span>
                    <span className="button-submit-green__text ">
                      Close Full Inventory
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </section>

        <section className="area-creation-content__info">
          <h2 className="web-app__heading">Full Inventory</h2>
          <div className="filter-table">
            <Table columns={columns} data={tableData} />
          </div>
        </section>
      </div>
    </main>
  );
};

export default AllInventory;
