import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react'
import styles from './style.module.css'
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { getPaymentStatus } from '../../../../../services/payment';
import { useSelector } from 'react-redux';
import { IoWarning } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
// const images = [...Array(46).keys()].slice(1).reduce((acc, curr) => {
//     acc[curr] = require(`../../../../../views/public/img/45_days/${curr}.png`);
//     return acc;
// }, {});

const PaymentAlertPopUp = () => {

        const calculateDaysToshowForPaymentPopup = async () => {

        // In getPaymentStatus and dueDate calculation ( months are zero-indexed)    
        let currentDate = moment.tz(new Date(), "Asia/Kolkata");
        let currentMonth = currentDate.format("M") - 1;
        let currentYear = currentDate.format("YYYY");
    
        let previousMonthDate = currentDate.clone().subtract(2, 'month');
        let previousMonth = previousMonthDate.format("M");
        let previousYear = previousMonthDate.format("YYYY");
    
        const previousMonthStatus = await getPaymentStatus({ month: previousMonth, year: previousYear });
    
        if (previousMonthStatus?.data?.status === "pending") {
            const dueDate = moment.tz({ year: previousYear, month: previousMonth, day: 1 }, "Asia/Kolkata");
            const calculateDays = currentDate.diff(dueDate, "days");
            const daysLeft = 45 - calculateDays;
    
            if (calculateDays > 45) {
                // console.log("Account Locked. Please contact support.");
                return {
                    status: -1,
                }
            } else {
                // console.log(`You have ${daysLeft} days left to pay the bill for month ${dueDate.format('MMMM')}.`);
                if (daysLeft < 15) {
                    return {
                        status: 1,
                        remainingDays: daysLeft,
                        amount: previousMonthStatus?.data?.amount,
                        month: dueDate.format('MMMM'),
                        lockPopup: true
                    }
                } else {
                    return {
                        status: 1,
                        remainingDays: daysLeft,
                        amount: previousMonthStatus?.data?.amount,
                        month: dueDate.format('MMMM'),
                        lockPopup: false
                    }
                }
            }
        }
        else {
            const currentMonthStatus = await getPaymentStatus({ month: currentMonth, year: currentYear });
            if (currentMonthStatus?.data?.status === "pending") {
                const dueDate = moment.tz({ year: currentYear, month: currentMonth, day: 1 }, "Asia/Kolkata");
                const calculateDays = currentDate.diff(dueDate, "days");
                const daysLeft = 45 - calculateDays;
    
                if (calculateDays > 45) {
                    // console.log("Account Locked. Please contact support.");
                    return {
                        status: -1,
                    }
                } else {
                    // console.log(`You have ${daysLeft} days left to pay the bill for month ${dueDate.format('MMMM')}.`);
                    if (daysLeft < 15) {
                        return {
                            status: 1,
                            remainingDays: daysLeft,
                            amount: currentMonthStatus?.data?.amount,
                            month: dueDate.format('MMMM'),
                            lockPopup: true
                        }
                    }
                    else {
                        return {
                            status: 1,
                            remainingDays: daysLeft,
                            amount: currentMonthStatus?.data?.amount,
                            month: dueDate.format('MMMM'),
                            lockPopup: false
                        }
                    }
                }
            }
            else {
                // console.log("No pending bills found.");
            }
        }
    };

    const user = useSelector(({ user }) => user);
    const [paymentDetails, setpaymentDetails] = useState(null)
    const [isVisible, setIsVisible] = useState(true);
    const [countdown, setCountdown] = useState(5); 
    const navigate = useNavigate();

    const handleClose = () => {
        setIsVisible(false);
      };

    useEffect(()=> {
        const fetchPaymentDetails = async () => {
            const result = await calculateDaysToshowForPaymentPopup();
            setpaymentDetails(result);
          };
      
          if(user.user.des === 101) fetchPaymentDetails();
    },[user])

    // Countdown effect when remainingDays are less than 15
    useEffect(() => {
        // if (paymentDetails?.remainingDays < 15) {
        if (paymentDetails?.lockPopup) {
            const intervalId = setInterval(() => {
                setCountdown(prev => prev - 1);
            }, 1000);

            // Clear interval when countdown reaches 0
            if (countdown === 0) {
                clearInterval(intervalId);
                // setIsVisible(false)
            }

            return () => clearInterval(intervalId); // Cleanup interval on unmount
        }
    }, [paymentDetails, countdown]);

    const handlePayNowClick = () => {
        setIsVisible(false)
        navigate('/admin-panel/users/billing');
    };

    return (
        paymentDetails?.status === 1 && isVisible && (
            <div className={styles.overlay}>
                <div className={styles.popup}>
                    <div className={styles.paymentReminder}>
                        <div className={styles.closeBox}>
                        <div className={styles.close}>
                            { paymentDetails?.lockPopup && countdown > 0 ? <span className={styles.month}>close in ({countdown})s</span> :
                                <IoIosCloseCircleOutline onClick={handleClose} style={{
                                    cursor: "pointer",
                                    color: "#FF3A3A",
                                    fontWeight: 500
                                }} size={24} />}
                        </div>
                        </div>
                        <div className={styles.textDiv}>
                            <div className={styles.daysLeft}>
                                {/* <img src={images[paymentDetails?.remainingDays]} alt="Warning" className={styles.dayImage} /> */}
                                <img src={`https://testing-expense.s3.ap-south-1.amazonaws.com/static_hosting/${paymentDetails?.remainingDays}.png`} alt="Warning" className={styles.dayImage} />
                            </div>
                            <div className={styles.message}>
                                <h2 className={styles.daysText}>DAYS LEFT</h2>
                                <p className={styles.paymentText}>
                                    PLEASE COMPLETE YOUR PAYMENT FOR THE MONTH OF <span className={styles.month}>{paymentDetails?.month}</span> OF <span className={styles.amount}>RS {paymentDetails?.amount}</span> TO AVOID SERVICE DISRUPTION
                                </p>
                                {
                                    (paymentDetails?.remainingDays <= 5) && <p className={styles.blockText}> <IoWarning style={{
                                        color: "#F7CD29",
                                    }} size={20} /><span>YOUR ACCOUNT WILL BE LOCKED WITHIN {paymentDetails?.remainingDays} DAYS</span></p>
                                }
                                {/* <div style={{height:"10px"}}>
                       { countdown > 0 && <span>Close in {countdown} seconds.</span> }
                    </div> */}
                                 <button className={styles.paynow} onClick={handlePayNowClick}>Pay Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
        
      )
}

export default PaymentAlertPopUp
