// import { MdArrowBackIosNew, MdOutlineGroup } from "react-icons/md";
// import { Link, useNavigate } from "react-router-dom";
// import Select from "react-select";
// import MaterialTable from "material-table";
// import { monthOptions, yearOptions } from "../../../../utils/helper";
// import { dcrStyles, selectUserStyles } from "../../../public/stylesheets/selectStyles";
// import { useCallback, useState, useMemo, useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { generateSelectData, generateSelectUser } from "../../../../utils/serialiseResponse";
// import {
//   clearStockists,
//   setStockists,
// } from "../../../../reducers/targets/stockist";
// import { viewSales } from "../../../../services/sales";
// import { viewUsersForUtilities } from "../../../../services/utilities";

// const columnData = [
//   {
//     title: "Product",
//     field: "",
//     render: (rowData) => `${rowData?.product?.name}`,
//   },
//   {
//     title: "PTR",
//     field: "",
//     render: (rowData) => `${rowData?.product?.ptr}`,
//   },
//   {
//     title: "PTS",
//     field: "",
//     render: (rowData) => `${rowData?.product?.pts}`,
//   },
//   { title: "Opening Balance", field: "openingBalance" },
//   { title: "Received", field: "received" },
//   { title: "Total Qty", field: "totalQty" },
//   { title: "Primary Qty", field: "primaryQty" },
//   { title: "Primary Value", field: "primaryValue" },
//   { title: "Sales Return", field: "salesReturn" },
//   { title: "Sales Return Value", field: "salesReturnValue" },
//   { title: "Secondary Qty", field: "secondaryQty" },
//   { title: "Secondary Value", field: "secondaryValue" },
//   { title: "Closing Qty", field: "closingQty" },
//   { title: "Closing Value", field: "closingValue" },
//   { title: "Transit", field: "transit" },
// ];

// const SalesUtility = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [employees, setEmployees] = useState([]);
//   const [selectedUser, setSelectedUser] = useState();
//   const loggedIn = useSelector(({ user }) => user);

//   const stockistData = useSelector(({ stockist }) => stockist);

//   const [month, setMonth] = useState();
//   const [year, setYear] = useState();
//   const [stk, setStk] = useState();
//   const [reports, setReports] = useState([]);
//   const employeeSelect = useMemo(
//     () => generateSelectUser(employees, "fullName"),
//     [employees]
//   );
//   useEffect(() => {
//     dispatch(setStockists());

//     return () => {
//       dispatch(clearStockists());
//     };
//   }, [dispatch]);
//   useEffect(() => {
//     try {
//       viewUsersForUtilities().then((res) => {
//         setEmployees([...res.data]);
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   }, []);
//   const targetSelect = useMemo(
//     () => generateSelectData(stockistData.data, "name"),
//     [stockistData]
//   );

//   // const handleSubmit = useCallback(
//   //   async (e) => {
//   //     e.preventDefault();

//   //     let data = {
//   //       month: month?.value,
//   //       year: Number(year?.label),
//   //       stk: stk?.value,
//   //     };

//   //     console.log("exe");

//   //     await viewSales(data).then((res) => setReports([...res.data]));
//   //     setMonth(null);
//   //     setYear(null);
//   //     setStk(null);
//   //   },
//   //   [month, year]
//   // );

//   // useEffect(() => {
//   //   let data = {
//   //     month: month?.value,
//   //     year: Number(year?.label),
//   //     stk: stk?.value,
//   //   };

//   //   console.log("exe");

//   //   (async function () {
//   //     await viewSales(data).then((res) => setReports([...res.data]));
//   //   })();
//   // }, [month, year, stk]);

//   // console.log(reports);

//   return (
//     <div className="expense">
//       <div className="row">
//         <h2
//           className="web-app__heading"
//           style={{ display: "flex", alignItems: "center" }}
//         >
//           <MdArrowBackIosNew
//             style={{ cursor: "pointer" }}
//             onClick={() => navigate(-1)}
//           />
//           <span style={{ display: "inline-block", margin: "0 1rem" }}>
//             Sales Reports
//           </span>
//         </h2>

//         <form>
//           <div className="util-tp-query">
//           {loggedIn?.user?.des < 121 ||
//             loggedIn?.user?.designation?.priority > 1 ||
//             loggedIn?.user?.isAdmin ? (
//                 <div className="util-tp-filter">
//                 <p className="util-select-user__label">Select User</p>
//                 <div className="util-select-user">
//                   <MdOutlineGroup className="util-select-user__icon" />
//                   <Select
//                     styles={selectUserStyles}
//                     options={employeeSelect}
//                     placeholder="Select User"
//                     className="mt-3"
//                     value={selectedUser}
//                     onChange={(e) => setSelectedUser({ ...e })}
//                   />
//                 </div>
//               </div>
//             ) : null}

//             <div className="util-tp-filter">
//               <p>Select Month</p>
//               <Select
//                 styles={dcrStyles}
//                 placeholder="Select Month"
//                 className="mt-3"
//                 options={monthOptions}
//                 value={month}
//                 onChange={(e) => setMonth({ ...e })}
//               />
//             </div>

//             <div className="util-tp-filter">
//               <p>Select Year</p>
//               <Select
//                 styles={dcrStyles}
//                 placeholder="Select Year"
//                 className="mt-3"
//                 options={yearOptions}
//                 value={year}
//                 onChange={(e) => setYear({ ...e })}
//               />
//             </div>

//             <div className="util-tp-filter">
//               <p>Select Stockist</p>
//               <Select
//                 styles={dcrStyles}
//                 placeholder="Select Stockist"
//                 className="mt-3"
//                 options={targetSelect}
//                 value={stk}
//                 onChange={(e) => setStk({ ...e })}
//               />
//             </div>

//             <div className="util-tp-filter">
//               <button type="submit" className="button-blue-gradient mt-5">
//                 See Reports
//               </button>
//             </div>
//           </div>
//         </form>

//         <div className="filter-table">
//           <MaterialTable
//             options={{
//               exportButton: true,
//               emptyRowsWhenPaging: false,
//               actionsColumnIndex: -1,
//               pageSizeOptions: [5, 10, 20, 50, 100],
//             }}
//             columns={columnData}
//             data={reports}
//             title="Sales Analysis"
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SalesUtility;
import React from 'react'

const index = () => {
  return (
    <div>index</div>
  )
}

export default index
