import { AdvancedMarker } from "@vis.gl/react-google-maps"
import ChemMarker from "../../views/public/img/ChemMarker.png"
import DocMarker from "../../views/public/img/DocMarker.png";
import StkMarker from "../../views/public/img/StockMarker.png";
import { useState } from "react";

export const MapAdvanceMarker = ({ location, typeParameter, time }) => {

  const pos = {
    lat: location.latitude,
    lng: location.longitude
  }

  const [detailsVisible, setDetailsVisible] = useState(false);

  return (
    <>
      <AdvancedMarker
        position={pos}
        title={location.name}
        clickable={true}
        onClick={() => setDetailsVisible(visibility => !visibility)}
      >
        <div className='d-flex flex-column justify-content-center align-items-center'>
          {
            detailsVisible && time ?
              <div className='text-primary d-flex p-2 bg-white border border-primary'>
                <div className="d-flex flex-column" >
                  <div className="d-flex">
                    <h3 className="text-danger m-1">Name:</h3>
                    <h3 className="text-primary m-1">{location.name}</h3>
                  </div>
                  <div className="d-flex">
                    <h4 className="text-danger m-1">Address:</h4>
                    <h4 className="text-primary m-1">{location.address}</h4>
                  </div>
                  <div>
                    <h4 className="text-danger m-1">Time:</h4>
                    <h4 className="text-primary m-1">{time}</h4>
                  </div>
                </div>
              </div> :
              detailsVisible ?
                <div className='text-primary d-flex p-2 bg-white border border-primary'>
                  <div className="d-flex flex-column" >
                    <div className="d-flex">
                      <h3 className="text-danger m-1">Name:</h3>
                      <h3 className="text-primary m-1">{location.name}</h3>
                    </div>
                    <div className="d-flex">
                      <h4 className="text-danger m-1">Address:</h4>
                      <h4 className="text-primary m-1">{location.address}</h4>
                    </div>
                  </div>
                </div> :
                null
          }
          <img
            src={typeParameter?.value === "doctor" ? DocMarker : typeParameter?.value === "chemist" ? ChemMarker : StkMarker}
            height={45}
          />
        </div>
      </AdvancedMarker>
    </>
  )
}