import { useEffect, useMemo, useState, useRef } from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
import { matchSorter } from "match-sorter";
import * as XLSX from "xlsx";
import Select from "react-select";
import { ReactComponent as SearchIcon } from "../../views/public/img/SVG/magnifying-glass.svg";
import { BsArrowDown } from "react-icons/bs";
import { BsArrowUp } from "react-icons/bs";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import { getDesignation } from "../../utils/serialiseResponse";
import { getUserData } from "../../services/user";
import { useCallback } from "react";
import { fetchLocationForId } from "../../services/locations";

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    border: "2px solid #36ABF9",
    borderRadius: "5px",
    color: "#9e9ad0",
    fontSize: "1.5rem",
    fontWeight: "500",

    width: "20rem",
    cursor: "pointer",

    margin: "1rem 0",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",
    fontFamily: "inherit",
    fontSize: "1.5rem",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
    textAlign: "left",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#353456",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
      color: "#9E9AD1",
    },
  }),
};
const renderFilterButton = ({ isSorted, isSortedDesc }) => {
  return (
    <div
    // className="sort-filter__container"
    >
      {/* <MdOutlineFilterAlt className="sort-filter__icon" /> */}
      {isSortedDesc ? (
        <BsArrowDown className="fs-4" />
      ) : (
        <BsArrowUp className="fs-4" />
      )}
    </div>
  );
};
// Filter with Select Dropdown
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  const selectOptions = options.map((o) => ({ label: o, value: o }));

  return (
    <Select
      isMulti={true}
      options={selectOptions}
      onChange={(options) => {
        const allValues = [...options].map((o) => o.value).filter(Boolean);

        setFilter(allValues && allValues.length ? allValues : undefined);
      }}
      styles={selectStyles}
    />
  );
}

export function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}
fuzzyTextFilterFn.autoRemove = (val) => !val;

const Table = ({ columns, data, defaultPageSize, exportExcel = true, callback, empl, fileSrc, selectedUser, month }) => {
  const [columnTotals, setColumnTotals] = useState({});
  const tableRef = useRef(null);
  const searchFilterRef = useRef({});
 
  useEffect(() => {
    const newColumnTotals = {};
    columns.forEach((column) => {
      if (column.showTotal) {
        const total = data.reduce((sum, row) => {
          if (Array.isArray(row[column.totalAccessor] || 0)) {
            return sum + (parseFloat(row[column.totalAccessor].length) || 0);
          }
          return sum + (parseFloat(row[column.totalAccessor]) || 0);
        }, 0);
        newColumnTotals[column.totalAccessor] = total.toFixed(2);
      }
    });
    setColumnTotals(newColumnTotals);
  }, [data, columns]);

  const SearchColumnFilter = (every)=> {
    const {column, data} = every;
    const [inpu, setInpu] = useState("");
    const [searchActive, setSearchActive] = useState(false);
    const magnifyingRef = useRef(null);
    useEffect(() => {
      if (magnifyingRef.current) {
        const titleTag = magnifyingRef.current.querySelector('title');
        if (titleTag) {
          titleTag.textContent = '';
        }
      }
    }, [magnifyingRef]);
    useEffect(()=>{
      if(inpu.length > 0)
        column.setFilter(inpu || undefined);
      // if(inpu.length > 0)setSearchActive(true);
    },[data]);
    
    
    return (
      <div
        className="search-filter__container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {searchActive && (
          <input
            value={inpu}
            onChange={(e) => {
              column.setFilter(e.target.value || undefined);
              setInpu(e.target.value || "");

              const key = column.Header ;
              searchFilterRef.current = {...searchFilterRef.current, [key]: e.target.value }
            }}
            onFocus={() => setSearchActive(true)}
            onBlur={() => setSearchActive(false)}
            placeholder={`Search`}
            className="search-filter"
            autoFocus={true}
          />
        )}
        {!searchActive ? (
          <SearchIcon
            onClick={() => setSearchActive(!searchActive)}
            className={searchActive ? "search-filter__icon" : "me-1"}
            style={{
              fill: searchActive || (inpu.length > 0) ? "#36abf9" : "#9e9ad1",
            }}
            ref={magnifyingRef}
          />
        ) : (
          <AiOutlineClose
            className="search-filter__icon icon-color-error"
            onClick={() => setSearchActive(false)}
          />
        )}
        {!searchActive && (
          <span className="d-flex gap-1 align-items-center">
            {column.render("Header")}
            {column.canSort
              ? renderFilterButton({
                isSorted: column.isSorted,
                isSortedDesc: column.isSortedDesc,
              })
              : null}
          </span>
        )}
      </div>
    );
  }
  const SequenceCellRenderer = (props) => {
    const { index, pageIndex, pageSize } = props;
    const serialNumber = pageIndex * pageSize + index + 1;
    return <div>{serialNumber}</div>;
  };

  const SequenceHeaderRenderer = ({ getToggleAllPageRowsSelectedProps }) => {
    return <span >Sr no.</span>;
  };

  const useSequenceColumn = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      {
        id: "sequence",
        Header: SequenceHeaderRenderer,
        Cell: SequenceCellRenderer,
        minWidth: 50,
        maxWidth: 50
      },
      ...columns,
    ]);
  };

  const exportEXCEL = async () => {
    try {
      // Extract Data (create a workbook object from the table)
      var worksheet = XLSX.utils.table_to_book(tableRef.current);
      var editSheet = worksheet.Sheets[worksheet.SheetNames[0]];

      const range = XLSX.utils.decode_range(editSheet['!ref']);
      const numberOfRows = range.e.r + 1;

      let arr = [];

      let hq;
      if(empl?.headquarter){
        try {
          const res = await fetchLocationForId(empl?.headquarter, 'headquarter');
          hq = res?.data;
        } catch (error) {
          console.log(error);
        }
      }

      let manager = null;
      if (empl?.reportingManager) {
        try {
          const res = await getUserData(empl?.reportingManager);
          manager = res?.data;
        } catch (error) {
          console.log(error);
        }
      }

      if (fileSrc) {
        arr.push([`Report Name : ${fileSrc}`]);
      }

      if (empl && empl?.isAdmin !== true) {
        if (month?.label) arr.push([`Report Month : ${month?.label}`])
        arr.push([`Employee Name : ${empl?.firstName} ${empl?.lastName}`]);
        arr.push([`Employee Des : ${getDesignation(empl?.des)}`]);
        arr.push([`Headquarter : ${hq?.name}`]);  
        arr.push([`Reporting Manager : ${manager?.firstName} ${manager?.lastName}`]);
      };


      arr.push([`Date of File Download : ${new Date().toDateString()}`])

      XLSX.utils.sheet_add_aoa(editSheet, arr, { origin: `A${numberOfRows + 2}` });

      XLSX.utils.sheet_add_aoa(editSheet, [['For Office Work Only --->'], ['Reviewed By'],['Approved By']], {origin:`A${numberOfRows + 10}`});

      // Package and Release Data (`writeFile` tries to write and save an XLSX file)
      XLSX.writeFile(worksheet, `${selectedUser ? selectedUser.label : 'report'}.xlsx`);

    } catch (error) {
      console.log(error);
    }
  };

  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      multiple: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined ? filterValue.includes(rowValue) : true;
        });
      },
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = useMemo(() => ({ Filter: SearchColumnFilter }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setPageSize,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,

      // initialState: { sortBy: [{ id: 'date', desc: true }] },
    },
    useFilters,
    useSortBy,
    usePagination,
    useSequenceColumn
  );
  //adding this effect so that components can provide default page size if they want
  useEffect(() => {
    if (defaultPageSize) {
      setPageSize(defaultPageSize);
    }
  }, [defaultPageSize, setPageSize]);

  return (
    <>
      <table ref={tableRef} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} style={{
              position: 'sticky',
              top: -2,
              left: 0,
            }}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(
                    {
                      style: {
                        width: column.width,
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                      },
                    },
                    column.getSortByToggleProps()
                  )}
                  onClick={() =>
                    column.canSort && column.toggleSortBy(!column.isSortedDesc)
                  }
                >
                  <div className="th-icon-container">
                    {column.canFilter ? (
                      column.render("Filter")
                    ) : (
                      <div className="d-flex gap-2">
                        <span>{column.render("Header")}</span>
                        <span>
                          {column.canSort
                            ? renderFilterButton({
                              isSorted: column.isSorted,
                              isSortedDesc: column.isSortedDesc,
                            })
                            : null}
                        </span>
                      </div>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {

            // The following algorithm is performed to make all the values, titled as name or items into PascalCase for better user interface
            let rowDetails = row;
            let rowOrgDetails = row.values;
            for (let key in rowOrgDetails) {
              if (rowOrgDetails.hasOwnProperty(key) && (key === "name" || key === "item")) {
                rowOrgDetails[key] = String(rowOrgDetails[key]).charAt(0).toUpperCase() + String(rowOrgDetails[key]).slice(1);
              }
            }
             // The following algorithm is performed to make all the values, titled as state into PascalCase for better user interface
            for (let key in rowOrgDetails) {
              if (rowOrgDetails.hasOwnProperty(key) && (key === "state" || key === "headquarter" || key === "fromCity" || key === "toCity")) {
                rowOrgDetails[key] = String(rowOrgDetails[key])
                  .split(" ")
                  .map(word => 
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  )
                  .join(" ");
              }
            }
            for (let key in rowDetails){
              if (rowDetails.hasOwnProperty(key) && key === "values"){
                rowDetails[key] = rowOrgDetails
              }
            }

            prepareRow(rowDetails);
            return (
              <tr {...rowDetails.getRowProps()}>
                {rowDetails.cells.map((cell) => (
                  <td
                    {...cell.getCellProps({
                      style: {
                        minWidth: cell.column.minWidth,
                        maxWidth: cell.column.maxWidth,
                        width: cell.column.width,
                        wordWrap: cell.column.wordWrap,
                      },
                    })}
                  >
                    {cell.render("Cell", { index: index, pageIndex, pageSize })}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>

        {data.length > 0 && (
          <tfoot >
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <td
                    {...column.getHeaderProps({
                      style: {
                        width: column.width,
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                      },
                    })}
                  >
                    <div className="th-icon-container">
                      {column.showTotal && (
                        <div
                          className="th-icon-container fw-bold"
                          style={{ color: "#3498db" }}
                        >
                          {!column.placeholderTotal ? (
                            columnTotals[column.totalAccessor]
                          ) : (
                            <span>{column.placeholderTotal}</span>
                          )}
                        </div>
                      )}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>

      <div className="react-table-pagination">
        <div className="arrow" >
          <button type='button' disabled={!canPreviousPage} onClick={() => previousPage()}>
            <MdArrowBackIos /> Prev
          </button>
          <span className="mx-5">
            Page {pageIndex + 1} of {pageOptions.length}
          </span>
          <button type='button' disabled={!canNextPage} onClick={() => nextPage()}>
            Next <MdArrowForwardIos />{" "}
          </button>
        </div>

        <div className="d-flex">
          <button
            className="export-button d-flex align-items-center gap-2"
            onClick={() => {
              if (exportExcel) {
                exportEXCEL();
              } else {
                callback({ data, empl, fileSrc });
              }
            }}
          >
            <RiFileExcel2Fill className="fs-3" />
            <span>Export</span>
            <div className="export-partition ms-4"></div>
          </button>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 25, 50, 100, 200, 500, 1000].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default Table;
