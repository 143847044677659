import { useLocation } from "react-router-dom";
import PageTitle from "../../../../../../components/PageTitle";
const ProductListDetails = () => {
  const location = useLocation();
  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Product Details" hierarchy={true} />
      </div>
      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Name</h4>
          <p>{location?.state?.name}</p>
        </div>

        <div className="user-detail-box">
          <h4>PTR</h4>
          <p>{location?.state?.ptr}</p>
        </div>

        <div className="user-detail-box">
          <h4>PTS</h4>
          <p>{location?.state?.pts}</p>
        </div>

        <div className="user-detail-box">
          <h4>MRP</h4>
          <p>{location?.state?.mrp}</p>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-yellowDark-border">
        <div className="user-detail-box">
          <h4>Category</h4>
          <p>{location?.state?.category?.name}</p>
        </div>

        <div className="user-detail-box">
          <h4>GST</h4>
          <p>{location?.state?.gst}</p>
        </div>

        <div className="user-detail-box">
          <h4>Manufacturer</h4>
          <p>{location?.state?.manufacturer}</p>
        </div>

        <div className="user-detail-box">
          <h4>Type</h4>
          <p>{location?.state?.type?.name}</p>
        </div>
      </section>
      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Packaging</h4>
          <p>{location?.state?.packaging}</p>
        </div>

        <div className="user-detail-box">
          <h4>Composition</h4>
          <p>{location?.state?.composition}</p>
        </div>
        <div className="user-detail-box">
          <h4>Division</h4>
          <p>{location?.state?.division}</p>
        </div>
      </section>
    </div>
  );
};

export default ProductListDetails;
