import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../../components/PageTitle";
import customToast from "../../../../components/CustomToast";
import serialise from "../../../../utils/serialiseResponse";
import {
  setProductGroups,
  clearProductGroups,
} from "../../../../reducers/products/groupReducer";
import {
  setGifts,
  clearGifts,
  addGift,
  editGifts,
  deleteGifts,
} from "../../../../reducers/products/giftReducer";
import Table from "../../../../components/Table";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { RiCloseCircleLine, RiEdit2Fill } from "react-icons/ri";

const AdminCategory = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(({user}) => user);
  const gifts = useSelector(({ gift }) => gift);

  const [giftName, setGiftName] = useState("");
  const [packaging, setPackaging] = useState("");
  const [price, setPrice] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [giftData, setGiftData] = useState();

  const EditPopup = ({ data }) => {
    const dispatch = useDispatch();
    const [gift, setGift] = useState("");
    const [packaging, setPackaging] = useState();
    const [price, setPrice] = useState();

    let updatedData = {
      ...data,
      gift: gift,
      packaging: packaging,
      price: price,
    };
    const handleEdit = async (e) => {
      e.preventDefault();
      dispatch(editGifts(updatedData));
      setShowEdit(false);
    };

    const handleDelete = () => {
      dispatch(deleteGifts(updatedData?._id));
      setShowEdit(false);
    };

    useEffect(() => {
      setGift(data.gift);
      setPackaging(data.packaging);
      setPrice(data.price);
    }, [data]);

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Edit Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form onSubmit={handleEdit}>
            <div className="edit-form__gift">
              <div>
                <label htmlFor="gift">
                  Edit Gift <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="gift"
                  className="me-5"
                  placeholder="Enter gift"
                  value={gift}
                  onChange={({ target }) => setGift(target.value)}
                />
              </div>

              <div>
                <label htmlFor="packaging">
                  Packaging <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="packaging"
                  className="me-5"
                  placeholder="Enter packaging"
                  value={packaging}
                  onChange={({ target }) => setPackaging(target.value)}
                />
              </div>

              <div>
                <label htmlFor="price">
                  Price <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="number"
                  id="price"
                  className="me-5"
                  placeholder="Enter price"
                  value={price}
                  onChange={({ target }) => setPrice(target.value)}
                />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-submit-green mt-4">
                <span className="button-submit-green__icon-container">
                  <MdOutlineEdit className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Edit</span>
              </button>

              <button
                type="submit"
                className="button-delete mt-4"
                onClick={() => handleDelete()}
              >
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const setEditForm = (data) => {
    setShowEdit(false);
    setGiftName(data?.name);
    setPackaging(data?.packaging);
    setPrice(data?.price);
    setGiftData(data);
  };
   
  const resetForm = () => {
    setGiftName("");
    setPackaging("");
    setPrice("");
  }

  const columns2 = useMemo(() => {
    if(loggedIn?.user?.des === 101){
      return [
        // {
        //   Header: "Sr. No",
        //   accessor: "sr",
        //   disableFilters: true,
        //   minWidth: 40,
        //   maxWidth: 40,
        // },
        {
          Header: "Gift",
          accessor: "gift",
        },
        {
          Header: "Packaging",
          accessor: "packaging",
          disableSortBy: true,
        },
        {
          Header: "Price",
          accessor: "price",
        },
        {
          Header: "Actions",
          accessor: "",
          Cell: (props) => {
            return (
              <span
                className="react-table-view-link"
                onClick={() => {
                  setEditForm(props.row.original);
                  setShowEdit(true);
                }}
              >
                <RiEdit2Fill className="icon-color-green" />
              </span>
            );
          },
          minWidth: 70,
          maxWidth: 70,
          disableFilters: true,
          disableSortBy: true,
        },
      ]
    }
    else{
      return [
        // {
        //   Header: "Sr. No",
        //   accessor: "sr",
        //   disableFilters: true,
        //   minWidth: 40,
        //   maxWidth: 40,
        // },
        {
          Header: "Gift",
          accessor: "gift",
        },
        {
          Header: "Packaging",
          accessor: "packaging",
          disableSortBy: true,
        },
        {
          Header: "Price",
          accessor: "price",
        },
      ]
    }
  })

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Gift",
        accessor: "gift",
      },
      {
        Header: "Packaging",
        accessor: "packaging",
        disableSortBy: true,
      },
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: (props) => {
          return (
            <span
              className="react-table-view-link"
              onClick={() => {
                setEditForm(props.row.original);
                setShowEdit(true);
              }}
            >
              <RiEdit2Fill className="icon-color-green" />
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(setGifts());
    // dispatch(setProductGroups());

    return () => {
      dispatch(clearGifts());
      // dispatch(clearProductGroups());
    };
  }, [dispatch]);

  const tableData = useMemo(() => serialise(gifts.data), [gifts]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!giftName || !packaging || !price)
      return customToast.error("Please Fill all Gift Details");
      var index = tableData.findIndex(p => (p.gift).toLocaleLowerCase() == giftName.toLocaleLowerCase());
    
   
    if(index === -1){
    const data = {
      giftName: giftName,
      packaging: packaging,
      price: price,
    };

    try {
      dispatch(addGift(data));
      customToast.success("Gift added successfully");
      resetForm();
    } catch (e) {
      customToast.error("We Encountered an Error");
    }
   }
   else{
    customToast.error("Gift Already Exists");
   }
    
  };

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Create Gift" />

        <section className="area-creation-content__form">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="gift-name">
                  Gift Name <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="gift-name"
                  placeholder="Enter Gift Name"
                  value={giftName}
                  onChange={(e) => setGiftName(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="gift-pack">
                  Gift Packaging <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="gift-pack"
                  placeholder="Enter Gift Packaging Details"
                  value={packaging}
                  onChange={(e) => setPackaging(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="Gift-price">
                  Gift Price <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="number"
                  className="me-5"
                  id="Gift-price"
                  placeholder="Enter Gift Price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4 mt-4 admin-creation-content__form-submit mt-5">
                <button type="submit" className="button-blue-gradient">
                  Add Gift
                </button>
              </div>
            </div>
          </form>
        </section>

        <section className="area-creation-content__info">
          <h2 className="web-app__heading">Present Gifts</h2>
          <div className="filter-table">
            {/* <MaterialTable
              editable={{
                onRowUpdate: (newRow, oldRow) =>
                  new Promise((resolve, reject) => {
                    dispatch(editGifts(newRow._id, newRow));
                    setTimeout(() => resolve(), 500);
                  }),
                onRowDelete: (newRow, oldRow) =>
                  new Promise((resolve, reject) => {
                    dispatch(deleteGifts(newRow._id, newRow));
                    setTimeout(() => resolve(), 500);
                  }),
              }}
              options={{
                exportButton: true,
                emptyRowsWhenPaging: false,
                actionsColumnIndex: -1,
                pageSizeOptions: [5, 10, 20, 50, 100],
              }}
              columns={columnData}
              data={tableData}
              title="Present Divisions"
            /> */}
            <Table columns={columns2} data={tableData} />
          </div>
          {showEdit ? <EditPopup data={giftData} /> : null}
        </section>
      </div>
    </main>
  );
};

export default AdminCategory;
