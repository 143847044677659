import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { hideSidenav, showSidenav } from "../../../../../reducers/appReducer";

const UtilListsNav = ({ title, links }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hideSidenav());

    return () => dispatch(showSidenav());
  }, [dispatch]);

  return (
    <nav className="sidebar-inner">
      <div className="sidebar-inner-heading">
        <h2 className="web-app__heading">{title}</h2>
      </div>

      <ul className="side-nav">
        {links.map((link, idx) => (
          <li
            className={`side-nav__item ${
              pathname.includes(link.route) && "inner-nav__active"
            }`}
            key={idx}
          >
            <Link to={link.route} className="side-nav__link">
              <span>{link.name}</span>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default UtilListsNav;
