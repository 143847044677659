import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import customToast from "../../../../components/CustomToast";

// import reducers for the suppliers here
import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";
import serialise from "../../../../utils/serialiseResponse";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { RiCloseCircleLine, RiEdit2Fill } from "react-icons/ri";
import {
  addProductsSupplier,
  deleteProductsSupplier,
  editProductsSupplier,
  setProductSupplier,
} from "../../../../reducers/products/supplierReducer";

const AdminProductsSupplier = () => {
  const dispatch = useDispatch();
  const suppliers = useSelector(({ productsSupplier }) => productsSupplier);

  const [supplier, setSupplier] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [supplierData, setSupplierData] = useState();

  useEffect(() => {
    dispatch(setProductSupplier());
    // return dispatch(clearProductsSuppliers());
  }, [dispatch]);

  const setEditForm = (data) => {
    setShowEdit(false);
    setSupplier(data?.name);
    setSupplierData(data);
  };

  const EditPopup = ({ data }) => {
    const dispatch = useDispatch();
    const [editSupplier, setEditSupplier] = useState(supplier);

    let updatedData = { ...data, name: editSupplier };
    const handleEdit = async (e) => {
      e.preventDefault();
      try {
        await dispatch(editProductsSupplier(updatedData?._id, updatedData));
        setShowEdit(false);
        customToast.success("Product supplier edited successfully");
        setSupplier("")
      } catch (error) {
        setShowEdit(false);
        setSupplier("")
        customToast.error(error?.response?.data?.message || "Something went wrong !")
      }
    };

    const handleDelete = () => {
      dispatch(deleteProductsSupplier(updatedData?._id));
      setShowEdit(false);
      setSupplier("")
    };

    // useEffect(() => {
    //   setSupplier(data.name);
    // }, [data]);

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Edit Details of Supplier</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => {
                setShowEdit(false)
                setSupplier("")
              }}
            />
          </div>

          <form onSubmit={handleEdit}>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  Enter Name of Supplier <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="division"
                  className="me-5"
                  placeholder="Enter Supplier"
                  value={editSupplier}
                  onChange={({ target }) => setEditSupplier(target.value)}
                />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-submit-green mt-4">
                <span className="button-submit-green__icon-container">
                  <MdOutlineEdit className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Edit</span>
              </button>

              <button
                type="submit"
                className="button-delete mt-4"
                onClick={() => handleDelete()}
              >
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete Supplier</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Product Supplier",
        accessor: "name",
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: (props) => {
          return (
            <span
              className="react-table-view-link"
              onClick={() => {
                setEditForm(props.row.original);
                setShowEdit(true);
              }}
            >
              <RiEdit2Fill className="icon-color-green" />
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!supplier) return customToast.error("Please Enter Supplier Name");

    try {
      await dispatch(addProductsSupplier({ name: supplier }));
      setSupplier("")
      return customToast.success("Product Supplier Successfully Added");
    } catch (e) {
      return customToast.error(e?.response?.data?.data || "Something went wrong!");
    }
  };
  const tableData = useMemo(() => serialise(suppliers?.data), [suppliers]);

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Create Product Supplier" />

        <section className="area-creation-content__form">
          <form onSubmit={handleSubmit} className="admin-form">
            <div>
              <label htmlFor="prod-supplier">
                Enter Product Supplier <span className="asterisk-imp">*</span>
              </label>
              <br />
              <input
                type="text"
                id="prod-supplier"
                className="me-5"
                placeholder="Enter Product Supplier"
                value={supplier}
                onChange={({ target }) => setSupplier(target.value)}
              />
            </div>

            <div className="mt-2">
              <button type="submit" className="button-blue-gradient mt-5">
                Add Supplier
              </button>
            </div>
          </form>
        </section>

        <section className="area-creation-content__info">
          <div className="filter-table">
            {tableData && <Table columns={columns} data={tableData} />}
          </div>
          {showEdit ? <EditPopup data={supplierData} /> : null}
        </section>
      </div>
    </main>
  );
};

export default AdminProductsSupplier;
