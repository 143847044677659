import AdminLink from "./Link";

const AdminIndex = () => {
  return (
    <div className="main-content">
      <div className="admin-panel_content">
        <div className="row">
          <div className="web-app__heading mt-5">
            Welcome to the Admin Panel
          </div>
          <div className="admin-panel__alert">
            <h2>Edit without hesitation</h2>
            <p>
              Admins sometime refuse editing, deleting or making some changes in
              the software. You can always reach out to us in case anything goes
              wrong. Anything that you edit or modify can be rolled back to the
              previous version.
            </p>
          </div>

          <div className="admin-panel__box-container">
            <AdminLink
              className={"admin-panel__cards"}
              title="Manage Locations"
              type="Areas"
              link="locations/create-state"
            />
            <AdminLink
              className={"admin-panel__cards"}
              title="Manage Users"
              type="Users"
              link="users/create-user"
            />
            <AdminLink
              className={"admin-panel__cards"}
              title="Manage Products"
              type="Products"
              link="products/category"
            />
            <AdminLink
              className={"admin-panel__cards"}
              title="Allowances"
              type="Expenses"
              link="expenses/travel-allowances"
            />

            <AdminLink
              title="Doctors, Stockists & Chemists"
              type="Doctors"
              link="dcs/create-doctors"
              className={"admin-panel__cards"}
            />
            <AdminLink
              title="Samples & Gifts"
              type="Sample"
              link="ssg/create-gift"
              className={"admin-panel__cards"}
            />
            <AdminLink
              title="Approvals"
              type="Approval"
              link="approvals/approve-call-report"
              className={"admin-panel__cards"}
            />
            <AdminLink
              title="Settings"
              type="Extra"
              link="holidays/create"
              className={"admin-panel__cards"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminIndex;
