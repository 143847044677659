import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";

import "./styles.css";
import "./default.css";

const DateRange = ({ dateRange, setDateRange }) => {

  const initialDate = dateRange ? dateRange : {
      startDate: new Date().getTime(),
      endDate: addDays(new Date(), 7).getTime(),
      key: "selection",
    }
  const [state, setState] = useState([initialDate]);

  const handleDateChange = (item) => {
    const adjustedEndDate = new Date(item.selection.endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);
    item.selection.endDate = adjustedEndDate;
    setState([item.selection]);
    setDateRange(item.selection);
  };

  return (
    <DateRangePicker
      onChange={(item) => handleDateChange(item)}
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      months={2}
      ranges={state}
      direction="horizontal"
    />
  );
};

export default DateRange;
