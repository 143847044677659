import React, { useEffect, useMemo, useState } from 'react'
import { MdArrowBackIosNew } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select';
import { dcrStyles } from '../../../public/stylesheets/selectStyles';
import { useDispatch, useSelector } from 'react-redux';
import { clearUsers, setUsers } from '../../../../reducers/users/employeeReducer';
import { generateSelectData } from '../../../../utils/serialiseResponse';
import { label } from 'yet-another-react-lightbox';
import { viewGeolocationDCS, viewGeolocationDataForAnalysis } from '../../../../services/geoFencing';
import { GoogleMap, InfoWindowF, Marker, useJsApiLoader } from '@react-google-maps/api';
import { viewSpecificDCS } from "../../../../services/targets";
import DocMarker from "../../../public/img/DocMarker.png";
import StkMarker from "../../../public/img/StockMarker.png";
import ChemMarker from "../../../public/img/ChemMarker.png";

const targetTypeOptions = [
  {value : 1, label : "Current Month"},
  {value : 2, label : "Last 3 Months"},
  {value : 3, label : "Last 6 Months"},
  {value : 4, label : "Year till date"},
  {value : 5, label : "All time"},
];

const dcsOptions = [
  {value: 'doctor', label : "Doctor"},
  {value: 'stockist', label : "Stockist"},
  {value: 'chemist', label : "Chemist"},
];


const GeoLocationReports = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const employees = useSelector(({employee}) => employee);
  const [selectedUser, setSelectedUser] = useState();
  const [durationType, setDurationType] = useState(targetTypeOptions[0]);
  const [selectDCS, setSelectDCS] = useState(dcsOptions[0]);
  const [dcsData, setDcsData] = useState([]);
  const [selectTarget, setSelectTarget] = useState();
  const [analysisData, setAnalysisData] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [geoTagLoc, setGeoTagLoc] = useState({});

  useEffect(() => {
    dispatch(setUsers('user'));

    return () => {
      dispatch(clearUsers());
    }
  },[]);

  useEffect(() => {
    const fetchData = async() => {
      try {
        const payload = {
          type: selectDCS?.value,
          userId: selectedUser?.value
        };
        const res = await viewGeolocationDCS(payload);
        setDcsData(res?.data);
      } catch (error) {
        console.log(error);
      }
    };
    if(selectedUser){
      fetchData();
    }
  },[selectDCS, selectedUser]);
  

  useEffect(() => {
    const fetchData = async() => {
      try {
        let payload = {
          userId: selectedUser?.value,
          target : selectTarget?.value,
          duration : durationType?.value,
        };
        const res = await viewGeolocationDataForAnalysis(payload);
        setAnalysisData(res?.data);
      } catch (error) {
        console.log(error)
      }
    };

    if(selectedUser && selectTarget && durationType){
      fetchData();
    }
  },[selectedUser, selectTarget, durationType, selectDCS]);

  useEffect(() => {
    const fetchData = async() => {
      try {
        const payload = {
          id: selectTarget?.value
        };

        const res = await viewSpecificDCS("doctor", payload);
        if (!res?.data?.geolocation) setShowMap(false);
        setGeoTagLoc(res?.data?.geolocation);
      } catch (error) {
        console.log(error)
      }
    };

    if(selectTarget){
      fetchData();
    }

  },[selectTarget]);


  useEffect(() => {
    setSelectTarget(null);
  },[selectedUser, selectDCS])

  const docOptions = useMemo(
   () =>  dcsData?.map((item) => item?.target),
    [dcsData]
  );
  
  const employeeSelect = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );

  const allCorrd = useMemo(
  () =>
    analysisData
      ?.filter((item) => item?.geolocation?.latitude !== 0 && item?.geolocation?.longitude !== 0)
      ?.map((item) => ({
        lat: item?.geolocation?.latitude,
        lng: item?.geolocation?.longitude,
        date : item?.date
      })),
  [analysisData]
);


  const containerStyle = {
    width: '1120px',
    height: '470px'
  };

  const {isLoaded} = useJsApiLoader({
    id:'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_API_KEY
  });

  const ViewMap = () => {

    const [showInfo, setShowInfo] = useState(false);
    const [markerId, setMarkerId] = useState();

    return (isLoaded && allCorrd && allCorrd?.length > 0) ? 
    <div className='mt-5 ' style={{border: "12px solid #2b2c47", borderRadius: "10px"}}>
      <GoogleMap
      mapContainerStyle={containerStyle}
      center={allCorrd[allCorrd?.length-1]}
      zoom={10}
      >
        {allCorrd?.map((el, idx) => (
          <Marker
          clickable={true}
          onClick={() => {
            setMarkerId(idx)
            setShowInfo(true)}}
          position={el}
          label={`${idx + 1}`}
          icon="https://img.icons8.com/?size=40&id=98455&format=png&color=20C997A1">

           {(showInfo && markerId === idx) ?
            <InfoWindowF
           position={el}
           onCloseClick={() => setShowInfo(false)}>
           <div style={{color:"black", height:"20px", paddingBottom:"20px"}}>
            {`Date : ${el?.date}`}
            </div>
            </InfoWindowF>
            :
            null
            }   
          
          </Marker>
        ))}

        {geoTagLoc && geoTagLoc?.latitude && geoTagLoc?.longitude && (
          <Marker
          position={{lat: geoTagLoc?.latitude, lng: geoTagLoc?.longitude}}
          icon={{
            url: selectDCS?.value === 'doctor' ? DocMarker 
               : selectDCS?.value === 'stockist' ? StkMarker
               : selectDCS?.value === 'chemist' ? ChemMarker
               : null,
            scaledSize: { width: 30, height: 40 }    
          }}/>
        )}
        
      </GoogleMap>
    </div>
    :
    <div>
      No data available
    </div>
  }

  return (
    <div className='expense'>
      <div className='row'>
        <h2 className='web-app__heading d-flex align-items-center'>
          <MdArrowBackIosNew onClick={() => navigate(-1)} style={{cursor:"pointer"}}/>
          <span className='d-block mx-2 my-2'>
            Geo Location Analysis
          </span>
        </h2>

        <div>
          <div className='util-tp-query d-flex justify-content-between'>
            <div className='d-flex'>
              <div className='util-tp-filter me-5'>
                <p>Select User</p>
                <Select
                styles={dcrStyles}
                placeholder="Select User"
                className='mt-3'
                options={employeeSelect}
                onChange={(e) => setSelectedUser(e)}/>
              </div>

              <div className='util-tp-filter me-5'>
                <p>Select Doc/Stk/Chem</p>
                <Select
                styles={dcrStyles}
                options={dcsOptions}
                placeholder="Select dcs"
                className='mt-3'
                value={selectDCS}
                onChange={({value, label}) => setSelectDCS({value, label})}/>
              </div>

              <div className='util-tp-filter me-5'>
                <p>Select Target</p>
                <Select
                styles={dcrStyles}
                options={docOptions}
                placeholder="Select dcs"
                className='mt-3'
                value={selectTarget}
                onChange={(e) => setSelectTarget(e)}/>
              </div>

              <div className='util-tp-filter me-5'>
                <p>Select Duration</p>
                <Select
                styles={dcrStyles}
                placeholder="select target"
                className='mt-3'
                options={targetTypeOptions}
                value={durationType}
                onChange={({value, label}) => setDurationType({value, label})}/>
              </div>

              <div className='util-tp-filter mt-5'>
                <button className='button-blue-gradient' onClick={() => setShowMap(true)}>
                  Show Map
                </button>
              </div>
            </div>
          </div>
        </div>
        
      {showMap ? <ViewMap/> : null}

      </div>
    </div>
  )
}

export default GeoLocationReports