import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AdminNav from '../AdminSidenav';
import { title, links } from './navData.js';

const AdminProducts = () => {
	const minStock = useSelector((state)=>state.settings["min-stock"]);

  
  let activeLinks = [...links];
  
  if(minStock === "true") activeLinks.splice(7,1)
	else activeLinks.splice(6,1)
	return (
		<>
			<AdminNav title={title} links={activeLinks} />

			<Outlet />
		</>
	);
};

export default AdminProducts;
