import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { MdCheckCircleOutline, MdOutlineEdit } from "react-icons/md";

import PageTitle from "../../../../../components/PageTitle";
import customToast from "../../../../../components/CustomToast";

import { adminStyles } from "../../../../public/stylesheets/selectStyles";
import {
  clearProductRanges,
  setProductRanges,
} from "../../../../../reducers/products/rangeReducer";
import { addUserTarget } from "../../../../../reducers/reports/targetReducer";
import { useLocation, useParams } from "react-router-dom";
import { monthOptions, yearOptions } from "../../../../../utils/helper";
import { viewProductV1 } from "../../../../../services/products";
import { RiCloseCircleLine} from "react-icons/ri";
import Table from "../../../../../components/Table";



const targetTypeOptions = [
  { value: 1, label: "Qty * Amount" },
  { value: 2, label: "Lump-Sum" },
];



const dropdownOptions =
  [{ label: "MRP", value: "mrp" }, { label: "PTS", value: "pts" }, { label: "PTR", value: "ptr" }, { label: "CUSTOM", value: "custom" }]

const headerSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "none",
    color: "#9e9ad0",
    fontSize: "1.5rem",
    fontWeight: "500",
    width: "100%",
    cursor: "pointer",
    outline: "none",
    border: "none"
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",
    fontFamily: "inherit",
    fontSize: "1.5rem",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",

  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",

  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
    outline: "none",
    border: "none",

  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",
    "&:hover": {
      color: "#9E9AD1",
    },
    backgroundColor: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "5px",
    height: "5px",
    padding: "0",
    "&:first-child": {
      width: "100% !important",
      height: "100% !important"
    }
  }),
};

const AdminUserTarget = () => {
  const totalQt = useRef(0);
  const customValue = useRef(0);
  const nameRef = useRef("");
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const [editing, setEditing] = useState(false)
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [targetType, setTargetType] = useState();
  const [products, setProducts] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [prodId, setProdId] = useState("");

  const [prodArray, setProdArray] = useState([]);
  const [serial, setSerial] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [lumpAmount, setLumpAmount] = useState("");
  const [priceHeader, setPriceHeader] = useState({ label: "PTR", value: "ptr" });
  const [customPrice, setCustomPrice] = useState(0)
  const [edit, setEdit] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      userId: params.userID,
      month: month?.value,
      year: Number(year?.label),
      targetType: targetType,
      products: products,
      lumpAmount: lumpAmount,
    };
    if (!month) return customToast.error(`Month can't be Empty.`);
    if (!year) return customToast.error(`Year can't be Empty.`);
    else if (!targetType) return customToast.error(`Target Type is Required.`);

    try {
      dispatch(addUserTarget(data));
      setShowEdit(false);
      setMonth(null);
      setTargetType(null);
      setLumpAmount("");
      setProducts([]);
      setSerial(1);
      setQuantity("");
      customToast.success("User Target Successfully Set");
    } catch (e) {
      customToast.error("We Encountered an Error");
    }
  };

  const previewColumn = useMemo(
    () => [
      {
        Header:"Product Name",
        accessor: "productName",
        showTotal: true,
        placeholderTotal: "Total"
      },
      {
        Header:"Quantity",
        accessor:"quantity",
        showTotal:true,
        totalAccessor : "quantity"
      },
      {
        Header:"Amount",
        accessor:"amount",
        showTotal: true,
        totalAccessor:"amount"
      }
    ],[products]
  );

  
  const EditPopup = ({ data }) => {
    return (
      <section className="edit-popup edit-popup__sales">
        <div className="edit-content__sales">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Target Data</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form onSubmit={handleSubmit}>
            <div className="filter-table" style={{minHeight:"150px",maxHeight:"350px" , overflow:"auto"}}>
            <Table columns={previewColumn} data={products}/>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-submit-green mt-4">
                <span className="button-submit-green__icon-container">
                  <MdCheckCircleOutline className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Allot Target</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  // useEffect(() => {
  //   dispatch(setProductRanges("range"));

  //   return () => {
  //     dispatch(clearProductRanges());
  //   };
  // }, [dispatch]);
  

  useEffect(() => {
    viewProductV1("").then((response) => {
      setProdArray(response?.data);
    });
  }, []);

  const handlePriceHeaderChange = (e)=>{
    setPriceHeader(e);
  };

  const handleEditRow = (value) =>{
    nameRef.current = value;
    setEdit(true);
    setEditing(!editing);
  };

  const handleAddProduct = async(productInfo) => {
    
    if(Number(totalQt.current) === 0  )return customToast.error("quantity cannot be zero");
    if(priceHeader?.label === "CUSTOM" && Number(customValue.current) === 0)return customToast.error("value cannot be zero");
    setEdit(false);
    setEditing(!editing);
    let oldProducts = products;
    const newProduct = {
      serial,
      productName: productInfo?.name,
      range: productInfo?._id,
      quantity: Number(totalQt.current),
      amount:
        priceHeader?.label === "PTS"
          ? productInfo?.pts * Number(totalQt.current)
          : priceHeader?.label === "MRP"
          ? productInfo?.mrp * Number(totalQt.current)
          : priceHeader?.label === "PTR"
          ? productInfo?.ptr * Number(totalQt.current)
          : priceHeader?.label === "CUSTOM"
          ? Number(customValue.current) * Number(totalQt.current)
          : 0,
    };
  
    setSerial((old) => old + 1);
    setProdId("");
    setProducts([...oldProducts, newProduct]);
    setProdArray(prodArray.filter((el) => el._id !== productInfo._id));
    setQuantity(0);
    setCustomPrice(0);
    setShowEdit(false);
    totalQt.current = 0 ;
  };
  
  const columns = useMemo(
    () => [
      {
        Header: "Product Name",
        accessor:"name",
      },
      {
        Header: (
          <Select 
          styles={headerSelectStyles}
          options={dropdownOptions}
          placeholder={priceHeader?.label}
          onChange={handlePriceHeaderChange}
          components={{IndicatorSeparator : () => null}}/>
        ),
        accessor:"mrp",
        disableFilters: true,
        disableSortBy: true,
        Cell:(props) =>{
         if(priceHeader?.label === "PTR")
         {
          return <div>
            {props?.row?.original?.ptr}
          </div>
         }
         else if(priceHeader?.label === "PTS")
         {
          return <div>
            {props?.row?.original?.pts}
          </div>
         }
         else if(priceHeader?.label === "MRP")
         {
          return <div>
            {props?.row?.original?.mrp}
          </div>
         }
         else {
          return <input
          type="number" className="sales-table__input"
          onChange={(e) => {customValue.current = e.target.value}}
          />;
         }
      },
    },
      {
        Header: "Quantity",
        accessor:"",
        Cell : (props) =>{
          const editable = String(nameRef.current) === String(props?.row?.original?.name);
          return (editable) ? 
          <input
          type="number"
          defaultValue={0}
          className="sales-table__input"
          onChange={(e) =>{
            totalQt.current = e.target.value;
          }}/>
          : 0
        }
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: (props) => {
          const editable = String(nameRef.current) === String(props?.row?.original?.name);
          return (edit && editable) ? (
            <button
              onClick={() => {
                handleAddProduct(props?.row?.original);
                
              }}>
              Save
            </button>
          ) : (
            <div className="d-flex gap-1 justify-content-center">
              <MdOutlineEdit type="button" onClick={() => {
                handleEditRow(props?.row?.original?.name)}} />
            </div>
          );
        },
      }
    ],[prodArray,priceHeader,editing]
  );

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle
          title={`Set Target for ${location.state?.firstName} ${location.state?.lastName} `}
          hierarchy={true}
        />
        <section className="area-creation-content__form">
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">Select Month</label>
                <br />
                <Select
                  name="month"
                  id="month"
                  value={month}
                  options={monthOptions}
                  onChange={(e) => setMonth({ ...e })}
                  styles={adminStyles}
                  placeholder="Select Month"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">Select Year</label>
                <br />
                <Select
                  name="year"
                  id="year"
                  value={year}
                  options={yearOptions}
                  onChange={(e) => {
                    setYear({ ...e });
                  }}
                  styles={adminStyles}
                  placeholder="Select Year"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">Select Target Type</label>
                <br />
                <Select
                  options={targetTypeOptions}
                  value={targetType}
                  onChange={(e) => setTargetType({ ...e })}
                  styles={adminStyles}
                />
              </div>
            </div>
            <div className="row row-bg" style={{ padding: "2rem 0" }}>
              {targetType?.value === 1 ? (
                <>
                  <h2 className="web-app__heading mt-5 mb-5">
                    Product Details
                  </h2>
                   <div className="filter-table" style={{minHeight:"150px" , overflow:"auto"}}>
                        <Table columns={columns} data={prodArray}/>
                      </div>
                </>
              ) : null}

              {targetType?.value === 2 ? (
                <div className="admin-form">
                  <div>
                    <label htmlFor="to-area">Lump-Sum Amount</label>
                    <br />
                    <input
                      type="number"
                      placeholder="Enter Amount"
                      value={lumpAmount}
                      onChange={({ target }) => setLumpAmount(target.value)}
                    />
                  </div>
                </div>
              ) : null}
            </div>
        </section> 

         <section
          className="area-creation-content__info"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {targetType?.value === 2 ? (
            <>
              {" "}
              <button
                type="btn"
                onClick={handleSubmit}
                className="button-submit-green mt-4"
              >
                <span className="button-submit-green__icon-container">
                  <MdCheckCircleOutline className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Allot Target</span>
              </button>
            </>
          ) : targetType && (
            <>
              <button
                type="button"
                className="button-blue-gradient"
                onClick={() => setShowEdit(true)}
              >
                Preview
              </button>
            </>
          )}
          {showEdit ? <EditPopup data={products} /> : null}
        </section>
      </div>
    </div>
  );
};

export default AdminUserTarget;
