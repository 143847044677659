import styled from 'styled-components';

export const FormLabel = styled.label`
	color: #777;
	font-size: 1.4rem;
	font-weight: 600;
	margin-bottom: 1rem;
`;

export const FormInput = styled.input`
	background-color: rgba(52, 152, 219, 0.1);
	border: none;
	border-bottom: 2.5px solid transparent;
	color: #000;
	font-family: inherit;
	font-weight: 500;
	padding: 1rem 2rem;
	width: 80%;
	transition: al 0.3s;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.08);

   &:focus {
        border-bottom: 2.5px solid #2ecc71;
        box-shadow: 0 1rem 2rem rgba(#000, .1);
        outline: none;
        transition: all .3s;

        &:invalid {
            border-bottom: 2.5px solid #e74c3c;
        }
`;

export const BtnBlue = styled.button`
	width: 40%;
	background-color: #36abf9;
	border: 2px;
	border-radius: 30px;
	color: #fff;
	font-size: 1.6rem;
	font-weight: 600;
	padding: 1rem 0;
	transition: all 0.3s;
  text-transform:uppercase;
	&:hover {
		background-color: #3498db;
	}

	display: flex;
	align-items: center;
	justify-content: space-evenly;
`;

// export const AuthBody = styled.main`
// 	background-color: #25253d;
// 	position: relative;
// `;

export const LoginLeft = styled.div`
		padding: 5rem;
		position: relative;

		h1 {
			color: #514f7b;
			font-size: 3.5rem;
			font-weight: 700;
			text-transform: uppercase;
		}

		h2 {
			color: #fff;
			font-size: 2rem;
			font-weight: 700;
			text-transform: uppercase;
			margin-top: 1rem;
		}

		p {
			color: #36ABF9;
			font-size: 1.3rem;
			font-weight: 700;
			text-transform: uppercase;
			margin-top: 2rem;
		}
	}
`;

export const LoginRight = styled.div`
	background-color: #fff;
	padding: 5rem;
  height: 80vh;
	h2 {
		font-size: 3rem;
		font-weight: 700;
		text-transform: uppercase;
	}

	p {
		color: #777;
		font-size: 1.5rem;
		font-weight: 500;
	}
`;

export const FormGroup = styled.div`
	flex: 1;
`;
export const FormRemember = styled.input``;
export const FormEnd = styled.p``;

export const LoginForm = styled.form`
	margin-top: 5rem;
	height: 100%;

	${FormGroup} {
		margin-bottom: 3rem;
	}

	${FormRemember} {
		margin-right: 0.5rem;

		&:active,
		&:focus {
			outline: none;
		}
	}

	${FormEnd} {
		margin-top: 7rem;
	}
`;

export const LoginCheckbox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 80%;

	a {
		color: #777;
		font-weight: 500;
		font-size: 1.5rem;
		text-decoration: none;
		transition: all 0.3s;

		&:hover {
			color: #000;
		}
	}

	span {
		display: flex;
		align-items: center;

		p {
			margin: 0;
		}
	}
`;

export const LoginImgInner = styled.img`
	height: 15rem;
	position: absolute;
	top: 0;
	left: 5%;
`;

export const LoginImgOuter = styled.img`
	height: 40rem;
	position: absolute;
	top: 50%;
	left: 5%;
`;

export const LoginEndLink = styled.a`
	color: #36abf9;
	font-weight: 600;
	text-decoration: none;
`;

export const SectionLogin = styled.section`
	background-color: #2b2c47;
	border-radius: 1rem;
	margin-top:5rem;
	margin-left:auto;
	margin-right:auto;
	width: 70%;
	height: 80vh;
	overflow: hidden;
`;

export const SignUp = styled.div`
	.sign-up {
		&__img {
			height: 20rem;
			width: auto;
			margin: 4rem 0;
		}
	}
`;

export const RegisterImg = styled.img`
	height: 20rem;
	width: auto;
	margin: 4rem 0;
`;

export const OTPForm = styled.form`
	font-size: 1.6rem;
	padding: 1rem 0;
`;

export const AuthError = styled.div`
	color: red;
`;
