import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../reducers/locations/hqReducer";
import {
  clearDesignations,
  setDesignations,
} from "../../../../reducers/users/designationReducer";
import {
  clearDivisions,
  setDivisions,
} from "../../../../reducers/users/divisionReducer";
import { newAdmin, newAdminV1 } from "../../../../services/users";
import serialise, { generateSelectData } from "../../../../utils/serialiseResponse";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { clearUsers, setUsers } from "../../../../reducers/users/employeeReducer";

const genderOptions = [
  { value: 0, label: "Male" },
  { value: 1, label: "Female" },
];

const AdminCreate = () => {
  const dispatch = useDispatch();
  const divisionData = useSelector(({ division }) => division);
  const headquarters = useSelector(({ headquarter }) => headquarter);

  const divisionSelect = useMemo(
    () => generateSelectData(divisionData, "name"),
    [divisionData]
  );
  const headquarterSelect = useMemo(
    () => generateSelectData(headquarters.data, "name"),
    [headquarters]
  );

    const userData = useSelector(({ employee }) => employee);

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const [dob, setDob] = useState("");
  const [doj, setDoj] = useState("");

  const [headquarter, setHeadquarter] = useState(null);
  const [division, setDivision] = useState(null);
  const [employeeId, setEmployeeId] = useState("");

  const [aadharNumber, setAadharNumber] = useState("");
  const [panNumber, setPanNumber] = useState("");

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");

  useEffect(() => {
    dispatch(setDivisions());
    dispatch(setDesignations());
    dispatch(setHeadquarters());
    return () => {
      dispatch(clearDivisions());
      dispatch(clearDesignations());
      dispatch(clearHeadquarters());
    };
  }, [dispatch]);

  const resetForm = () => {
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setGender("");

    setEmail("");
    setPhone("");
    setPassword("");

    setDob("");
    setDoj("");

    setHeadquarter(null);
    setDivision(null);
    setEmployeeId("");

    setAadharNumber("");
    setPanNumber("");

    setAddress1("");
    setAddress2("");
    setCity("");
    setState("");
  };

  const [data, setdata] = useState({
    hqId: headquarter?.value,
      divisionId: headquarter?.value
  })

  useEffect(() => {
   
    dispatch(setUsers("user", data));
  
    return () => {
      dispatch(clearUsers());
    }
  }, [])

  const tableData = useMemo(() => serialise(userData), [userData]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!firstName || !lastName)
      return customToast.error("Please Enter Full Name");
    else if (!email || !password)
      return customToast.error("Please Enter Credentials");
    else if (!headquarter)
      return customToast.error("Please Select a Headquarter");
    else if (!division) return customToast.error("Please Select a Division");

    let index = tableData.findIndex((p) => (p.email)?.toLocaleLowerCase() === email?.toLocaleLowerCase());

    if(index === -1){
      const data = {
        firstName,
        middleName,
        lastName,
        gender: gender?.label,
        email,
        phone,
        password,
        dob,
        doj,
        headquarterId: headquarter?.value,
        divisionId: division?.value,
        employeeId,
        aadharNumber,
        panNumber,
        address1,
        address2,
        city,
        state,
      };
  
      let error = false;
      try {
        // newAdmin(data);
        newAdminV1(data);
      } catch (e) {
        error = true;
        customToast.error("We Encountered an Error");
      }
  
      if (!error) {
        customToast.success("User Successfully Created");
        resetForm();
      }
    }
    else{
      customToast.error("User with Same Email Alreay Exists!");
    }

    
  };

  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Create User" />

        <section className="area-creation-content__form">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="fName">
                  First Name <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="fName"
                  placeholder="Enter First Name"
                  value={firstName}
                  onChange={({ target }) => setFirstName(target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="mName">Middle Name</label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="mName"
                  placeholder="Enter Middle Name"
                  value={middleName}
                  onChange={({ target }) => setMiddleName(target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="lName">
                  Last Name <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="lName"
                  placeholder="Enter Last Name"
                  value={lastName}
                  onChange={({ target }) => setLastName(target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                <label htmlFor="lName">
                  Gender <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  options={genderOptions}
                  styles={adminStyles}
                  value={gender}
                  onChange={(e) => setGender({ ...e })}
                />
              </div>
            </div>

            <div className="row create-user__form-row">
              <h2 className="web-app__heading py-5">Login Credentials</h2>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="email">
                  Email <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="email"
                  className="me-5"
                  id="email"
                  placeholder="Enter Email Address"
                  value={email}
                  onChange={({ target }) => setEmail(target.value.toLowerCase())}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="mobile">
                  Phone <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="phone"
                  maxLength={10}
                  pattern="[0-9]*"
                  className="me-5"
                  id="mobile"
                  placeholder="Enter Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 password-input">
                <label htmlFor="password">
                  Password <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type={passwordType}
                  className="me-5"
                  id="password"
                  placeholder="Enter Password"
                  value={password}
                  onChange={({ target }) => setPassword(target.value)}
                />
                <button
                  type="button"
                  className="show-password-button"
                  onClick={togglePassword}
                >
                  {passwordType === "password" ? (
                    <AiFillEyeInvisible className="password-icon password-icon-show" />
                  ) : (
                    <AiFillEye className="password-icon password-icon-hide" />
                  )}
                </button>
              </div>
            </div>

            <div className="row">
              <h2 className="web-app__heading py-5">Employee Details</h2>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="dob">DOB</label>
                <br />
                <input
                  type="date"
                  className="me-5"
                  id="dob"
                  placeholder="Date of Birth"
                  value={dob}
                  onChange={({ target }) => setDob(target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="doj">
                  DOJ <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="date"
                  className="me-5"
                  id="doj"
                  placeholder="Date of Joining"
                  value={doj}
                  onChange={({ target }) => setDoj(target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="hq">
                  Headquarter <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="headquarter"
                  id="headquarter"
                  value={headquarter}
                  options={headquarterSelect}
                  onChange={({ value, label }) =>
                    setHeadquarter({ value, label })
                  }
                  styles={adminStyles}
                  placeholder="Select Headquarter"
                />
              </div>
            </div>

            <div className="row py-5">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="division">
                  Division <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="division"
                  id="division"
                  value={division}
                  options={divisionSelect}
                  onChange={({ value, label }) => setDivision({ value, label })}
                  styles={adminStyles}
                  placeholder="Select Division"
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="employee-id">Employee ID</label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="employee-id"
                  placeholder="Enter ID"
                  value={employeeId}
                  onChange={({ target }) => setEmployeeId(target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="aadhaar">Aadhar Number</label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="aadhaar"
                  placeholder="Enter Aadhar Number"
                  value={aadharNumber}
                  maxLength={12}
                  onChange={({ target }) => setAadharNumber(target.value)}
                />
              </div>
            </div>

            <div className="row create-user__form-row-two">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="pan">Pan Number</label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="pan"
                  placeholder="Enter PAN Number"
                  value={panNumber}
                  maxLength={10}
                  onChange={({ target }) => setPanNumber(target.value)}
                />
              </div>
            </div>

            <div className="row">
              <h2 className="web-app__heading py-5">Address</h2>
              <div className="col-sm-12 col-md-8 col-lg-8">
                <label htmlFor="addr">Street Address 1</label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="addr1"
                  placeholder="Enter Street Address"
                  value={address1}
                  onChange={({ target }) => setAddress1(target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="city">City</label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="city"
                  placeholder="Enter City Name"
                  value={city}
                  onChange={({ target }) => setCity(target.value)}
                />
              </div>
            </div>

            <div className="row py-5">
              <div className="col-sm-12 col-md-8 col-lg-8">
                <label htmlFor="addr">Street Address 2</label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="addr2"
                  placeholder="Enter Street Address"
                  value={address2}
                  onChange={({ target }) => setAddress2(target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="state">State</label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="state"
                  placeholder="Enter State"
                  value={state}
                  onChange={({ target }) => setState(target.value)}
                />
              </div>
            </div>

            <div className="col-sm-2 col-md-4 col-lg-2 admin-content-submit-button-left">
              <button type="submit" className="button-blue-gradient">
                Submit
              </button>
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};

export default AdminCreate;
