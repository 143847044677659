import { addCompanyName, addCompanyNameV1, getCompanyName, getCompanyNameV1 } from "../services/settings";

const initialState = {
    companyName: null
};


const companyNameReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'ADD_COMPANY_NAME':
        return {
          ...state, 
          companyName: action.payload.companyName,
        };
      case 'FETCH_COMPANY_NAME':
        return {
          ...state,
          companyName: action.payload.companyName,
        };
      default:
        return state;
    }
  };
  

export const changeCompanyName = (values) => {
    return async (dispatch) => {
        try {
            const {data} = await addCompanyName(values);
            // const {data} = await addCompanyNameV1(values);
            dispatch({
                type: 'ADD_COMPANY_NAME',
                payload: data,
            });
        } catch (error) {
            console.error('Failed to add announcement', error);
        }
    };
};

export const fetchCompanyName = () => {
    return async (dispatch) => {
        try {
            const data = await getCompanyName();
            // const data = await getCompanyNameV1();
            dispatch({
                type: 'FETCH_COMPANY_NAME',
                payload: data.data.res
            });
        } catch (error) {
            console.error('Failed to fetch announcements', error);
        }
    };
};

export default companyNameReducer;
