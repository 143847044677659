import React from 'react';
import * as XLSX from 'xlsx';
import { getUserData } from '../../services/user';
import { getDesignation } from '../../utils/serialiseResponse';
import { fetchLocationForId } from '../../services/locations';

const ListExport = async({ data, empl, fileSrc }) => {
    let type = '';
    const getTypeFromUID = (uid) => {
        // this function will return the type of the user using the uid
        if (uid.startsWith('DOC')) {
            type = 'Doctor';
            return 'doctor';
        } else if (uid.startsWith('CHM')) {
            type = 'Chemist';
            return 'chemist';
        } else if (uid.startsWith('STK')) {
            type = 'Stockist';
            return 'stockist';
        } else if (uid.startsWith('PDT')) {
            type = 'Product';
            return 'product';
        }
        return 'unknown';
    };
    const exportData = data?.map((entry) => {
        console.log('Entry:', entry); // Log to check entry data
        const type = getTypeFromUID(entry.uid);
        console.log('Type:', type); // Log to check type
        switch (type) {
            case 'doctor':
                return {
                    Sr: entry.sr || "-",
                    Name: entry.name || "-",
                    Degree: entry.degree || "-",
                    Specialization: entry.specialization || "-",
                    Birthday: entry.birthday || "-",
                    Anniversary: entry.anniversary || "-",
                    Email: entry.email || "-",
                    Mobile: entry.mobile || "-",
                    "Clinic Contact": entry.clinicContact || "-",
                    "Doctor Code": entry.doctorCode || "-",
                    Address: entry.address || "-",
                    Category: entry.category?.label || "-",
                    UID: entry.uid || "-",
                    Contact: entry.contact || "-",
                    "Update At": entry.updateAt || "-",
                    "Extra Information": entry.extraInfo || "-",
                    Headquarter: entry.city || "-",
                    "Working Area": entry.workingArea || "-",
                };
            case 'chemist':
                return {
                    Sr: entry.sr || "-",
                    Name: entry.name || "-",
                    "Business Name": entry.businessName || "-",
                    Certification: entry.certification || "-",
                    Birthday: entry.birthday || "-",
                    Email: entry.email || "-",
                    "Chemist Contact": entry.chemistContact || "-",
                    Address: entry.address || "-",
                    City: entry.city || "-",
                    "Working Area": entry.workingArea || "-",
                    UID: entry.uid || "-",
                };
            case 'stockist':
                return {
                    Sr: entry.sr || "-",
                    Name: entry.name || "-",
                    "Business Name": entry.businessName || "-",
                    Certification: entry.certification || "-",
                    "GST Number": entry.gstNumber || "-",
                    "Drug License Number": entry.drugLicenseNumber || "-",
                    "Drug Expiry Number": entry.drugExpiryNumber || "-",
                    "Establishment Date": entry.establishmentDate || "-",
                    "Stockist Contact": entry.stockistContact || "-",
                    Address: entry.address || "-",
                    City: entry.city || "-",
                    "Working Area": entry.workingArea || "-",
                    UID: entry.uid || "-",
                    "Updated At": entry.updatedAt || "-",
                    "Extra Information": entry.extraInfo || "-",
                };
                case 'product':
                    return {
                        Sr: entry.sr || "-",
                        Name: entry.name || "-",
                        Division: entry.division || "-",
                        Packaging: entry.packaging || "-",
                        MRP: entry.mrp || "-",
                        PTS: entry.pts || "-",
                        PTR: entry.ptr || "-",
                        Composition: entry.composition || "-",
                        Manufacturer: entry.manufacturer || "-",
                        GST: entry.gst || "-",
                        UID: entry.uid || "-",
                        Stock: entry.stock || "-",
                        MinStock: entry.minStock || "-",
                    };
            default:
                return {};
        }
    });

    console.log('Export Data:', exportData); // Check the final export data

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    console.log('Workbook:', wb);

    let arr = [];

    var editSheet = wb.Sheets['Sheet1'];
    const range = XLSX.utils.decode_range(editSheet[`!ref`]);
    const numberOfRows = range.e.r + 1;

    let manager = null;
    if(empl?.reportingManager){
        try {
            const res = await getUserData(empl?.reportingManager);
            manager = res?.data;
        } catch (error) {
            console.log(error);
        }
    };

    let hq;
      if(empl?.headquarter){
        try {
          const res = await fetchLocationForId(empl?.headquarter, 'headquarter');
          hq = res?.data;
        } catch (error) {
          console.log(error);
        }
      }

    if(fileSrc){
        arr.push([`Report From : ${fileSrc}`]);
    }

    if(empl && empl?.isAdmin !== true){
        arr.push([`Employee Name : ${empl?.firstName} ${empl?.lastName}`]);
        // console.log("employe name",empl)
        arr.push([`Employee Des : ${getDesignation(empl?.des)}`]);
        arr.push([`Headquarter : ${hq?.name}`]);  
        arr.push([`Reporting Manager : ${manager?.firstName} ${manager?.lastName}`]);
    }

    arr.push([`Date of File Download : ${new Date().toDateString()}`])

    XLSX.utils.sheet_add_aoa(editSheet,arr,{origin : `A${numberOfRows + 2}`});

    XLSX.writeFile(wb, `${type} List.xlsx`);
};

export default ListExport;