import { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../../../../components/PageTitle";
import Table from "../../../../../components/Table";
import { monthOptions, yearOptions } from "../../../../../utils/helper";
import { adminStyles } from "../../../../public/stylesheets/selectStyles";
import Select from "react-select";
import {deleteTargets } from "../../../../../reducers/reports/targetReducer";
import { fetchUserMonthlyTargets } from "../../../../../services/reports";
import customToast from "../../../../../components/CustomToast";
import { MdCheckCircleOutline, MdDelete, MdDeleteOutline } from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";

const MonthlyUserTarget = () => {
  const [year, setYear] = useState(yearOptions[2]);
  const [targetData, setTargetData] = useState([]);
  const [month, setMonth] = useState();
  const [isDeleted, setIsDeleted] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteData, setDeleteData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let payload = {
          month: month?.value,
          year: year?.label
        };
        const res = await fetchUserMonthlyTargets(payload);
        setTargetData(res?.data);
      } catch (error) {
        customToast.error("Error occurred !");
        console.log(error);
      }
    };

    if(month && year){
      fetchData();
    }

  },[month, year, isDeleted]);

  const DeletePopup = ({ data }) => {
    const dispatch = useDispatch();
    const handleDelete = async (e) => {
      e.preventDefault();
      try {
       await dispatch(deleteTargets("userTarget", { id: data?._id }));
        setIsDeleted(!isDeleted);
        setDeletePopup(false);
        customToast.success("Allotment deleted successfully");
      } catch {
        customToast.error("We encountered an error !");
      }
    };

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete Alloted Target</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setDeletePopup(false)}
            />
          </div>

          <form onSubmit={handleDelete}>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  This will PERMANENTLY delete the alloted target !
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-delete mt-4">
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Yes</span>
              </button>

              <button
                type="button"
                className="button-submit-green mt-4"
                onClick={() => setDeletePopup(false)}
              >
                <span className="button-submit-green__icon-container">
                  <MdCheckCircleOutline className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">No</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Employee",
        accessor: "name"
      },
      {
        Header: "Lump Amount",
        accessor: "lump"
      },
      {
        Header: "Product Amount",
        accessor: "prodAmount"
      },
      {
        Header: "Delete",
        accessor: "",
        Cell:(props) => {
          let data = props?.row?.original;
          return data?._id ? 
          <div>
            <MdDelete className="icon-color-error cursor-pointer"
             onClick={() => {
              setDeleteData(props?.row?.original);
              setDeletePopup(true);
            }}/>
          </div>
          :
          <div>
            <MdDelete style={{cursor:"not-allowed"}}/>
          </div>
        }
      },
    ],
    []
  );

  return (
    <div className="main-content admin-content w-75">
      <div className="area-creation-content">
        <PageTitle title="Monthly Targets" />

        <section className="area-creation-content__info">
          <div className="primarysales-filter"
            style={{ marginTop: "0", padding: "0", marginBottom: "2rem" }}>

            <div className="util-tp-filter">
              <p className="mb-2">
                Select Month
                <span className="asterisk-imp">*</span>
              </p>
              <Select
              options={monthOptions}
              onChange={(e) => setMonth(e)}
              styles={adminStyles}
              value={month}
              className="mt-3"
              placeholder="Select Month"/>
            </div>

            <div className="util-tp-filter">
              <p className="mb-2">
                Select Year <span className="asterisk-imp">*</span>
              </p>
              <Select
                styles={adminStyles}
                placeholder="Select Month"
                className="mt-3"
                options={yearOptions}
                value={year}
                onChange={(e) => setYear(e)}
              />
            </div>
          </div>
          <h2 className="web-app__heading mb-4 ms-2">
            Showing ({targetData?.length}) Entries
          </h2>
          <div className="filter-table">
            <Table columns={columns} data={targetData} />
          </div>
        </section>
        {deletePopup ? <DeletePopup data={deleteData}/> : null}
      </div>
    </div>
  );
};

export default MonthlyUserTarget;
