import { MdArrowBackIosNew } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import Table from "../../../../../components/Table";
import { viewMissedReportDetails } from "../../../../../services/analytics";
import { formatDate } from "../../../../../utils/helper";
import { viewMissedReports } from "../../../../../services/utilities";
import { addDays } from "date-fns";

const MissedDocReportsDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
 
  const [reports, setReports] = useState([location.state?.data?.doctorId]);
  const [previousReports, setpreviousReports] = useState([location.state?.data?.doctorId]);
  const [selectedUser, setSelectedUser] = useState(location.state?.userId);

  const [fetchPrevious, setfetchPrevious] = useState(false)

  const [type, setType] = useState( { value: 1, label: "Doctor" });
  const currentDate = new Date(location.state?.data?.doctorId.date);
  // const [startDate, setstartDate] = useState(new Date(currentDate.getFullYear(), Number(currentDate.toISOString().substring(6,7))-1, 1));
  const [startDate, setstartDate] = useState(new Date(currentDate))
  const [endDate, setendDate] = useState( addDays(startDate, 60))
  const [allReports, setallReports] = useState(location.state?.allReports)
  const [allCurrentUser, setallCurrentUser] = useState([...allReports])


  const columns = useMemo(
    () => [
      {
        Header: "Doctor Name",
        accessor: "doc.doctorId.target.name",
        // Cell: () => location.state?.data?.doctorId?.target?.name,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Date",
        accessor: "doc.doctorId.date",
        // Cell: (props) => formatDate(props.row.original?.date),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Time",
        accessor: "time",
        Cell:(props) =>(
         new Date(Number(props?.row?.original?.doc?.doctorId?.cdt)).toLocaleTimeString()
        ),
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  const chemStkColData = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "el.businessName",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Met / Missed",
        accessor: "status",
        Cell: (props) => {
          return props.row.original?.status === 1 ? (
            <span className="approved-text">Met</span>
          ) : (
            <span className="rejected-text">Missed</span>
          );
        },
        disableFilters: true,
        disableSortBy: true,
        minWidth: 70,
        maxWidth: 70,
      },
    ],
    []
  );

  const columns2 = useMemo(
    () => [
      {
        Header: "Month",
        accessor: "a",
        Cell: (props) => {
          const monthNames = ["January", "February", "March", "April", "May", "June",
                              "July", "August", "September", "October", "November", "December"
                              ];
         return monthNames[props.row.original?.doc?.doctorId?.month]
        },
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Doctor Name",
        accessor: "doc.doctorId.target.name",
      },
      {
        Header: "Degree",
        accessor: "doc.doctorId.target.degree",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Category",
        accessor: "doc.doctorId.target.category.label",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Expected Visit",
        accessor: "",
        Cell: (props) => {
          if (
            props?.row?.original?.doc?.doctorId?.target?.category?.label &&
            props?.row?.original?.doc?.doctorId?.target?.category?.label
              .toLowerCase()
              .substring(0, 1) === "s"
          ) {
            return <span>3</span>;
          } else if (
            props?.row?.original?.doc?.doctorId?.target?.category?.label &&
            props?.row?.original?.doc?.doctorId?.target?.category?.label
              .toLowerCase()
              .substring(0, 1) === "c"
          ) {
            return <span>2</span>;
          } else {
            return <span>1</span>;
          }
        },
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Actual Visits",
        accessor: "count",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: (props) => formatDate(props.row.original?.doc?.doctorId?.date),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Time",
        accessor: "time",
        // Cell: (props) => String(props.row.original?.cdt).substring(11, 16),
        Cell:(props) =>new Date(Number(props?.row?.original?.doc?.doctorId?.cdt)).toLocaleTimeString(),
        disableFilters: true,
        disableSortBy: true,
      },
     
    ],
    [selectedUser]
  );

  useEffect(() => {
    let data = allCurrentUser.filter((user) => user.doc.doctorId.target._id ===  location.state.data.doctorId.target._id)
    setallCurrentUser(data);
    return () => {
     
    }
  }, [allReports])
  


  useEffect(() => {
    
    const asyncFetch = async () => {
      viewMissedReports(type?.label?.toLowerCase(), {
        selectedUser: selectedUser,
        startDate:startDate.getTime(),
        endDate:endDate.getTime(),
        fetchPrev:true,
        docId:location.state.data.doctorId._id
      })
        .then((resp) => {
        
          setpreviousReports(resp.data);
        
        })
        .catch((err) => {
          setpreviousReports([]);
          console.log(err);
        });
    };

    asyncFetch();
  }, [fetchPrevious]);

  // useEffect(() => {
  //   const asyncFetch = async () => {
  //     await viewMissedReportDetails(params.docId, {
  //       userId: location.state?.userId,
  //       month: location.state?.month,
  //       year: location.state?.year,
  //     })
  //       .then((resp) => {
  //         setReports(resp.data);
  //       })
  //       .catch((err) => console.log(err));
  //   };

  //   asyncFetch();
  // }, []);

  

  return (
    <div className="expense">
      <div className="row">
        <h2
          className="web-app__heading"
          style={{ display: "flex", alignItems: "center" }}
        >
          <MdArrowBackIosNew
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <span style={{ display: "inline-block", margin: "0 1rem" }}>
            Visit Details
          </span>
        </h2>

        <div className="filter-table">
          <Table columns={columns} data={allCurrentUser} />
        </div>

        <div className="react-table-pagination">
          <button style={{
            backgroundColor: "#2B2C47",
            color: "#C7C4E9",
            fontFamily: "inherit",
            fontSize: "1.5rem",
            padding:".5rem",
            borderRadius: "5px",
            border: "1px solid #36ABF9",
            
          }}
          onClick={()=>setfetchPrevious(!fetchPrevious)}
          >{fetchPrevious ? "Hide last 10 visits" :"Show last 10 visits"}</button>

        
      </div>
      {fetchPrevious && 
       <div className="filter-table">
       {previousReports.length > 0 && (
         <h2 className="web-app__heading mb-4">
           Showing ({previousReports.length}) Entries
         </h2>
       )}
       {previousReports.length > 0 ? (
         <Table
           columns={
             type?.label?.toLowerCase() === "doctor"
               ? columns2
               : chemStkColData
           }
           data={previousReports}
         />
       ) : (
         <h3 className="mt-2 no-results-message">No results found</h3>
       )}
     </div>
      }
     
      </div>
    </div>
  );
};

export default MissedDocReportsDetails;
