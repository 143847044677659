import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import serialise, { generateSelectData } from "../../../../../../utils/serialiseResponse";
import Table from "../../../../../../components/Table";
import { viewUsersForUtilities } from "../../../../../../services/utilities";

const UserSS = ({month, reports , user}) => {

  const [employees, setEmployees] = useState([]);
  const [employeeDataForExcel, setEmployeeDataForExcel] = useState();

  const employeeData = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );

  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    try {
      let tempdata = employeeData.filter((empData) => {
        if (empData.label === user.label) return true;
      })
      setEmployeeDataForExcel(tempdata[0]);
    } catch (error) {
      console.log(error);
    }
  }, [user]);
  
  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   minWidth:50,
      //   maxWidth:50,
      //   disableSortBy:true,
      // },
      {
        Header: "Submitted By",
        accessor: "user",
        showTotal:true,
        placeholderTotal:"Total",
      },
      {
        Header: "Stockist",
        accessor: "stockist",
      },
      {
        Header: "Headquarter",
        accessor: "headquarter",
      },
      {
        Header: "Total Secondary Sales",
        accessor: "salesValue",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"salesValue",
        Cell:(props) => {
          return <div>
            {`₹ ${Number(props?.row?.original?.salesValue).toFixed(2)}`}
          </div>
        }
      },
    ],
    []
  );
  const columns2 = useMemo(
    () => [
      // {
      //   Header: "Sr No.",
      //   accessor: "sr",
      //   showTotal:true,
      //   placeholderTotal:"Total",
      //   minWidth:50,
      //   maxWidth:50,
      //   disableFilters:true,
      // },
      {
        Header: "Employee Name",
        accessor: "user",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Total Secondary Sales",
        accessor: "salesValue",
        disableFilters: true,
        showTotal:true,
        totalAccessor:"salesValue",
        Cell:(props) => {
          return <div>
            {`₹ ${Number(props?.row?.original?.salesValue).toFixed(2)}`}
          </div>
        }
      },
    ],
    []
  );
  const tableData = useMemo(() => serialise(reports), [reports]);
  return (
    <div className="filter-table">
        {reports.length > 0 && (
            <h2 className="web-app__heading mb-4 ps-3">
                Showing ({reports.length}) Entries
            </h2>
        )} 
        {tableData.length > 0 ? (
            <Table columns={user ? columns : columns2} data={tableData} empl={employeeDataForExcel} month={month} fileSrc="Secondary Sales Reports (User)" />
        ) : (
            <h3 className="mt-2 no-results-message">No results found</h3>
        )}
    </div> 
  );
};
export default UserSS ;