import { useLocation } from "react-router-dom";
import PageTitle from "../../../../../components/PageTitle";
import { formatDate } from "../../../../../utils/helper";

const AdminInfoDetails = () => {
  const location = useLocation();

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="User Details" hierarchy={true} />
      </div>
      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>First Name</h4>
          <p>{location.state?.firstName}</p>
        </div>

        <div className="user-detail-box">
          <h4>Middle Name</h4>
          <p>{location.state?.middleName}</p>
        </div>

        <div className="user-detail-box">
          <h4>Last Name</h4>
          <p>{location.state?.lastName}</p>
        </div>

        <div className="user-detail-box">
          <h4>Email Address</h4>
          <p>{location.state?.email}</p>
        </div>

        <div className="user-detail-box">
          <h4>Phone Number</h4>
          <p>{location.state?.phone}</p>
        </div>
      </section>

      <section className="user-detail-container user-detail-container-yellowDark-border">
        <div className="user-detail-box">
          <h4>Date of Birth</h4>
          <p>{formatDate(location.state?.birthDate)}</p>
        </div>

        <div className="user-detail-box">
          <h4>Date of Joining</h4>
          <p>{formatDate(location.state?.joiningDate)}</p>
        </div>

        <div className="user-detail-box">
          <h4>Headquarter</h4>
          <p>{location.state?.headquarter}</p>
        </div>

        <div className="user-detail-box">
          <h4>Division</h4>
          <p>{location.state?.division}</p>
        </div>

        <div className="user-detail-box">
          <h4>Employee ID</h4>
          <p>{location.state?.employeeId}</p>
        </div>

        <div className="user-detail-box">
          <h4>Designation</h4>
          <p>Admin</p>
        </div>

        <div className="user-detail-box">
          <h4>Aadhaar Number</h4>
          <p>{location.state?.aadharNumber}</p>
        </div>

        <div className="user-detail-box">
          <h4>PAN Number</h4>
          <p>{location.state?.panNumber}</p>
        </div>
      </section>

      <section className="user-detail-container user-detail-container-green-border">
        <div className="user-detail-box">
          <h4>Address</h4>
          <p>
            {location.state?.address.street1
              ? location.state?.address.street1 + ", "
              : null}
            {location.state?.address.street2}
          </p>
        </div>
        <br />

        <div className="user-detail-box">
          <h4>City</h4>
          <p>{location.state?.address.city}</p>
        </div>

        <div className="user-detail-box">
          <h4>State</h4>
          <p>{location.state?.address.state}</p>
        </div>
      </section>
    </div>
  );
};

export default AdminInfoDetails;
