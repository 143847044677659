import DCRForm from "../../../components/DCRForm";

const Stockist = () => {
  return (
    <div className="main-content">
      <h2 className="web-app__heading dcr__heading">Stockist DCR</h2>
      <DCRForm dcrType="Stockist" />
    </div>
  );
};

export default Stockist;
